<template>
  <div class="cask">
    <section>
      <div class="container my-3">
        <div class="row justify-content-center">
          <div class="col-lg-10" style="line-height:200%">
            <h3 class="d-flex justify-content-center" style="font-weight:600">1:1 문의하기</h3>

            <div class="row justify-content-center mb-3">
              <div style="min-height: auto" class="col-12 col-md-8 my-3 text-center">
                <p class="head">
                  ※ 안녕하세요 고객님! 궁금하신 것이 있으신가요?<br>
                  담당 직원이 확인하여 곧 답변을 드리겠습니다.<br>
                  카카오톡 플러스 친구로 문의를 주시면, 더욱 빠른 답변을 받아보실 수 있습니다.
                </p>        
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-12 col-md-8">
                <div class="box">
                  <p class="head mx-4">문의확인</p>
                  <p class="cinfo mx-4">
                    <strong>[마이 페이지 > 1:1문의]</strong> 에서 확인할 수 있습니다.
                  </p>
                  <hr>
                  <div class="my-4">
                    <!-- 문의유형 -->
                    <div class="row">
                      <div class="col-12 row mb-1">
                        <div class="col-12 gx-3 form-group">
                          <!-- <label for="budget" class="tit col-form-label mx-3 my-1">문의유형 <font class="text-danger">*</font></label> -->
                          <div class="col-12">
                            <div class="input-group mb-3" >
                              <select class="form-select mx-3 form-select-sm text-success" aria-label=".form-select-sm" ref="sel_selCat" v-model="this.reqInfo.selCat">
                                <option :value="noVal" disabled>-- 문의유형(필수선택) --</option>
                                <option :value="cl.baseCode" :key="i" v-for="(cl, i) in inquiryCatList">
                                  {{ cl.codeNameKor }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- 제목 -->
                    <div class="row mb-3">
                      <div class="col-12 gx-5 form-group">
                        <input type="text" maxlength="22" class="form-control form-select-sm" placeholder="*제목(필수입력)" ref="txt_reqTitle" 
                          v-model="this.reqInfo.reqTitle">
                      </div>
                    </div>

                    <!-- 이름,여행지역 -->
                    <div class="row mb-3">
                      <div class="col-md-6 mb-3 gx-5 form-group">
                        <input type="text" maxlength="12" class="form-control form-select-sm" placeholder="*성명(필수입력)" ref="txt_reqPersonName"
                          v-model="this.reqName">
                      </div>
                      <div class="col-md-6 gx-5 form-group">
                        <select class="form-select form-select-sm text-success" aria-label=".form-select-sm" ref="sel_Nat" v-model="this.reqInfo.selNat">
                          <option :value="noVal" disabled>-- 여행지역 --</option>
                          <option :value="nl.baseCode" :key="i" v-for="(nl, i) in nationList">
                            {{ nl.codeNameKor }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- 이메일, 전화번호 -->
                    <div class="row mb-3">
                      <div class="col-md-6 mb-3 gx-5 form-group">
                        <input type="email" maxlength="40" class="form-control form-select-sm" ref="txt_cEmail" placeholder="*이메일(필수입력)" v-model="this.reqInfo.reqEmail">
                      </div>
                      <div class="col-md-6 gx-5 form-group">
                        <input type="text" maxlength="11" id="reqTel" placeholder="*연락처(숫자만, 필수입력)" class="form-control form-select-sm" ref="txt_cTel" v-model="this.reqTel">
                      </div>
                    </div>

                    <!-- 문의내용 -->
                    <div class="row">
                      <div class="col-md-12 gx-5 form-group">
                        <textarea class="form-control form-select-sm" maxlength="248"  placeholder="문의내용(250자 이내, 필수입력)" ref="txt_reqSpRemarks" id="floatingTextarea" style="height:180px" v-model="this.reqInfo.reqSpRemarks"></textarea>
                      </div>
                    </div>       

                    <!-- 약관동의 -->
                    <div class="row mt-2 px-4">
                      <div class="form-check">
                        <input class="form-check-input mt-2" type="checkbox" v-model="ruleAgree" ref="chb_ruleAgree">
                        <label class="form-check-label" for="defaultCheck3">
                          <span class="text-danger">[필수]</span> 개인정보 수집 및 이용동의 <button class="totalsee" id="button2" data-toggle="modal" data-target="#popup_box" @click="isModalOpen = true">전체보기</button>
                        </label>
                      </div>

                      <div v-if="isModalOpen" class="modal">
                        <div class="modal-content">
                          <div class="row main-fix">
                            <div class="col-8">
                              <span>개인정보 수집 및 이용 동의</span>
                            </div>
                            <div class="col-4 text-end">
                              <span class="close" @click="isModalOpen = false">&times;</span>
                            </div>
                          </div>
                          <hr>
                          <p>
                            엔타비 글로벌 (이하 '엔타비')는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.<br>
        
                            1. 개인정보 수집 및 이용목적<br>
                            엔타비는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.<br>
                            &nbsp; 1) 홈페이지 회원가입 및 관리<br>
                            &nbsp; 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.<br>
                            &nbsp; 2) 서비스 제공<br>
                            &nbsp;  물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산 등을 목적으로 개인정보를 처리합니다.<br>
                            &nbsp; 3) 마케팅 및 광고에의 활용<br>
                            &nbsp; 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.<br>
                            <br>
                            2. 개인정보 수집 항목<br>
                            엔타비는 다음의 개인정보 항목을 처리하고 있습니다.<br>
                            &nbsp; 1) 홈페이지 회원가입 및 관리/마케팅 정보 제공의 목적 (회원탈퇴 시 까지)<br>
                            &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름<br>
                            &nbsp; 2) 상담 및 해외 및 국내 여행 예약의 목적 (신용카드정보, 은행계좌정보)<br>
                            &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 여권사본, 법정대리인 이름, 법정대리인 자택 전화번호, 법정대리인 자택 주소, 법정대리인 휴대전화번호<br>
                            &nbsp; 3) 여행자 보험가입의 목적 (신용카드정보, 은행계좌정보)<br>
                            &nbsp;  이메일, 휴대전화번호, 자택주소, 성별, 생년월일, 이름<br>
                            &nbsp; 4) 대금결제의 목적 (이용목적이 달성되면 파기)<br>
                            &nbsp;  신용카드정보, 은행계좌정보<br>
                              <br>
                            3. 개인정보 수집 항목 보유 및 이용기간<br>
                            &nbsp; 1) 엔타비는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.<br>
                            &nbsp; 2) 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
                            &nbsp;  ① 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년<br>
                            &nbsp;  ② 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br>
                            &nbsp;  ③ 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br>
                            &nbsp;  ④ 계약 또는 청약철회 등에 관한 기록 : 5년<br>
                            &nbsp;  ⑤ 표시/광고에 관한 기록 : 6개월<br>
                            </p>
                        </div>
                      </div>
                    </div>

                    <!-- 문의버튼 -->
                    <div class="row justify-content-center mt-5 mx-3">
                      <button class="btn cxl col-5 col-sm-4 mx-2" @click="goToHome()">
                        취소
                      </button>
                      <button class="btn go col-5 col-sm-4 mx-2" @click="requestInsert();">
                        등록
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import moment from 'moment';
export default {
  data() {
    return {
      isModalOpen: false,       //모달컨트롤

      ruleAgree: "N",
      nationList: [], //국가리스트
      inquiryCatList: [], //질의영역분류
      reqInfo: []
    };
  },
  created(){
    //console.log("userInfo:",this.$store.state.user);


    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }

    //카카오알림용 인적정보
    if(this.user.joinFlag === "K" || this.user.joinFlag === "E" || this.user.joinFlag === "H")
    {
      this.k_name = (!this.user.nickname) ? "" : this.user.nickname;

      let kakaoPhone = ""
      if(this.user.alimCallC != undefined &&this.user.alimCallC.charAt(0) === '+')
      {
        //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
        kakaoPhone = this.user.alimCallC.replace("+82 ", "0")
      }
      else
      {
        kakaoPhone = this.user.alimCallC
      }
      this.k_tel = (!this.user.alimCallC) ? "" : kakaoPhone.replace(/-/g,""); //-제거       
    }
    else
    {
      this.k_name = this.user.name;
      this.k_tel = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
    }
    console.log("k_name:",this.k_name, "k_tel:",this.k_tel)
    this.reqName = this.k_name
    this.reqTel = this.k_tel

    this.getInquiryCatList();
    this.getNationList();
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      //this.$router.push({path: '/'});
      location.replace("/");
    }
    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/b2CLogin', query:{st: "Y"}});
    }    
  },  
  methods: {
    async getNationList() {
      let nationList = await this.$api("/api/searchBasecode", {param:['ntCd']});
      if (nationList.length > 0)
      {
        this.nationList = nationList;
      }
    },
    async getInquiryCatList() {
      let inquiryCatList = await this.$api("/api/searchBasecode", {param:['iqCd']});
      // let inquiryCatList = inquiryCatList_tmp.filter((item) => {return item.uFlag === 'Y'})
      if (inquiryCatList.length > 0)
      {
        this.inquiryCatList = inquiryCatList;
      }
    },
    async requestInsert() {
      let date = new Date(+new Date() + 3240 * 10000).toISOString().split("T")[0]
      //const time = new Date().toTimeString().split(" ")[0];
      let today = new Date()
      let timeStamp = today.getTime()
      //let currentDate = date + ' ' + time;
      let askCode = 'CA-'+date+timeStamp
      //console.log("askCode:", askCode)

      // console.log("today:", date + ' ' + time);
      if(this.ruleAgree == "" || !this.ruleAgree || this.ruleAgree == 'N') { 
        this.$refs.chb_ruleAgree.focus();
        this.$swal.fire("","진행을 위해서 필수확인사항에 동의를 해주십시오.", "warning");
        return false;
      }
      if(this.reqInfo.selCat == "noVal" || this.reqInfo.selCat == undefined) 
      { 
        this.$refs.sel_selCat.focus();
        this.$swal.fire("","문의영역을 선택해주세요.", "warning");
        return false;
      }
      if(!this.reqInfo.reqTitle) { 
        this.$refs.txt_reqTitle.focus();
        return this.$swal.fire("","제목을 입력해주세요.", "warning");
      }
      if(!this.reqName) {
        this.$refs.txt_reqPersonName.focus();
        this.$swal.fire("","성명을 입력해주세요.", "warning");
        return false;
      }
      // if(this.reqInfo.selNat == "noVal" || this.reqInfo.selNat == undefined) 
      // { 
      //   this.$refs.sel_Nat.focus();
      //   this.$swal.fire("","여행지역을 선택해주세요.", "warning");
      //   return false;
      // }
      if(!this.reqInfo.reqEmail) {
        this.$refs.txt_cEmail.focus();
        this.$swal.fire("","이메일을 입력해주세요", "warning");
        return false;
      }
      if(!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]+$/.test(this.reqInfo.reqEmail)) {
        this.$refs.txt_cEmail.focus();
        this.$swal.fire('','이메일 양식이 불명확합니다', 'question');
        return false;
      }
      if(!this.reqTel) {
        this.$refs.txt_cTel.focus();
        this.$swal.fire("","연락처를 입력해주세요", "warning");
        return false;
      }
      if(!/^[0-9]{2,3}[0-9]{3,4}[0-9]{4}/.test(this.reqTel)) {
        this.$refs.txt_cTel.focus();
        this.$swal.fire('','연락처가 불명확합니다', 'question');
        return false;
      }
      if(!this.reqInfo.reqSpRemarks) {
        this.$refs.txt_reqSpRemarks.focus();
        this.$swal.fire("","문의내용이 없습니다", "warning");
        return false;
      }
      this.reqInfo.insDate = today;
      this.reqInfo.reqStatus = "IQ";

      //1:1 문의등록
      let resIns_Request = await this.$api("/api/ins_customerAsk", 
        {param: [this.reqInfo.selCat,this.reqInfo.reqTitle,this.reqName,this.reqInfo.selNat,this.reqInfo.reqEmail,this.reqTel,this.reqInfo.reqSpRemarks,this.ruleAgree,this.reqInfo.reqStatus,this.user.email,askCode]});
      if(Object.keys(resIns_Request)[0] === 'error') {
        this.$swal.fire('','문의등록 중 오류가 발생했습니다.<br>연속오류 발생 시 연락처<br> (1660-4602)', 'error')
      }
      else {
        this.$swal.fire('','감사합니다<br>빠른 시간안에 확인연락을 드리겠습니다.', 'success');
        this.$router.push({path: '/'});
      }
      //console.log("resIns_Request:",resIns_Request);
    },
    goToHome() 
    {
      this.$router.push({path: '/myPage'});
    }
  }
}
</script>


<style>
.cask{
  margin:4rem 0
}

.cask .box {
  text-align:left
}
.cask .head{
  font-size:.8rem;
  margin-bottom:0;
  line-height:25px
}
.cask.cinfo{
  font-size:.8rem;
  margin-bottom:15px;
  line-height:20px
}
.cask .box hr{
  margin:.5rem 0
}
.cask .box .tit{
  font-size:.9rem;
  font-weight:bold
}
.cask .box .form-select,
.cask .box .form-select-sm{
  border-radius:0 !important;
  padding: 13px 0 13px 10px
}
.cask .box .ask-btn{
  margin-top:3rem;
}

.cask .go {
  padding:15px 0;
  background-color: #222;
  color:#fff;
  border-radius:0;
}
.cask .cxl{
  padding:15px 0;
  background-color: #eee;
  border-radius:0;
}
.cask .go:hover{
  background-color: #444;
}
.cask .cxl:hover{
  border:1px solid #777
}


/* 약관 동의 */
.cask .form-check-label{
  font-size:.95rem
}
.cask .form-check-label .totalsee{
  font-size:.8rem;
  font-weight: 600;
  color:#333;
  background-color: #fff;
  border:transparent;
  text-decoration: underline;
  text-underline-position:under;
}

.cask .modal-content{
  padding-top:1rem
}
.cask .modal-content span{
  padding-left:1rem;
  font-size:1.2rem;
  font-weight: 600;
  color:#000
}
.cask .modal-content{
  width:50%;
  height:65%;
  overflow-y: auto;  
}
.cask .modal-content .close{
  padding-right:10px;
}
.cask .modal-content p{
  padding:.5rem 1rem;
  font-size:.9rem;
  line-height:25px
}

@media (max-width:992px){
  .cask .modal-content{
    width:80%;
    height:65%;
  }
}
</style>
