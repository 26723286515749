<template>
  <div>
    <section id="location">
      <div class="container my-3">
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <h3 class="d-flex justify-content-center" style="font-weight:600">오시는 길</h3>
              
            <hr>
            <div class="map-container" style="position: relative; width: 100%; height: 400px;">
              <GoogleMap
                :api-key="apiKey"
                :center="center"
                :zoom="15"
                style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
              >
                <Marker :options="{ position: center }" />
              </GoogleMap>
            </div>
            <hr class="mt-5">
            
            <div class="subway text-start">
              <p class="title">지하철</p>
              <div class="info">
                <span class="line">1</span>
                <span style="font-size:.87rem; color:#666">1호선 초량역 12번 출구로 나와 오른쪽 방향으로 이동 후 센트럴베동원로듀크비스타 방향으로 도보 약 2분 거리 </span>
              </div>
            </div>

            <hr style="margin:50px 0;">
            
            <div class="bus text-start">
              <p class="title">버스</p>
              <div class="info">
                <strong style="color:#666; font-size:.95rem;">경남여중입구 하차</strong><br>
                <ul>
                  <li>
                    <span class="bline1">일반</span>
                    <span style="font-size:.87rem; color:#777">17, 22, 26, 27, 40, 41, 43, 52, 59, 61, 66, 67, 81, 82, 85, 88, 101, 103, 134, 167</span>
                  </li>
                  <li>
                    <span class="bline2">급행</span>
                    <span style="font-size:.87rem; color:#777">1000, 1001, 1003, 1004</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { GoogleMap, Marker } from 'vue3-google-map'

export default {
  components: {
    GoogleMap,
    Marker
  },
  data() {
    return {
      apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
      //apiKey: "xxxxxxx",
      center: { 
        lat: 35.121308,
        lng: 129.044658
      }
    }
  }
}
</script>

<style>
#location{
  margin:4rem 0
}

#location .title{
  font-size:1.5rem;
  font-weight:600
}

#location .bus ul{
  margin-top:8px;
  margin-left:0;
  padding-left:0;
}
#location .bus ul li{
  list-style:none;
  line-height:20px;
  padding:7px 0;
}


.map-container {
  position: relative;
  overflow: hidden;
}


@media (min-width: 1000px) {
  #location .info{
    margin:-50px 0 0 150px
  }
}

.line{
  display: inline-block;
  width: 23px;
  height: 23px;
  line-height: 23px;
  border-radius: 23px;
  font-size: 15px;
  color: #fff;
  text-align: center;
  background:#faaf3a;
  margin-right:10px;
}
.bline1{
  display: inline-block;
  line-height: 20px;
  padding: 2px 7px;
  font-size: 12px;
  color: #fff;
  background:#87c700;
  margin-right:10px;
}
.bline2{
  display: inline-block;
  line-height: 20px;
  padding: 2px 7px;
  font-size: 12px;
  color: #fff;
  background:#fb5852;
  margin-right:10px;
}
</style>
