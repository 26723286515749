<template>
  <main>
    <div id="reserdetail">
      <section class="py-1">
        <div class="container my-3">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-11">
              <h2 class="d-flex justify-content-center m-title" v-if="ctCode==='N'">엔버스 상세 내역</h2>
              <h2 class="d-flex justify-content-center m-title" v-else>예약 상세 내역</h2>

              <!-- 엔버스상품안내 -->
              <div class="r-name container">
                <div class="row col-12"  v-if="ctCode === 'N'">
                  <div class="name col-lg-12 text-start d-flex flex-column justify-content-between align-items-start">
                    <p class="nnum fs-5">엔버스 번호 : <b class="text-success">{{ this.reserveInfo.inqCode }}</b></p>
                    <div class="row col-12">
                      <div class="col-lg-6 gx-1 text-center">
                        <p class="nadd" style="cursor: pointer;" @click="goToCustomerAsk()">
                          <small>문의 하기</small>
                        </p>
                      </div>
                      <div class="col-lg-6 gx-1 text-center">
                        <p class="nadd" style="cursor: pointer;" @click="toReview(this.reqRsvCode)">
                          <small>후기 작성</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 그 외 일반예약 -->
                <div class="row col-12" v-else>
                  <div class="col-lg-6">
                    <div class="d-flex flex-column align-items-start">
                      <img v-if="this.productImage.imgName != undefined" :src="`/download/${this.productId}/${this.productImage.imgName}`" class="d-block w-100" alt="...">
                      <img v-else :src="`/download/noImage/noImage.jpg`" class="d-block w-100" alt="...">
                    </div>
                  </div>
                  <div class="name col-lg-6 text-start d-flex flex-column justify-content-between align-items-start">
                    <p class="code">상품번호 : {{ this.productInfo.pdtCode }}</p>
                    <p>{{ this.productInfo.pdtNameKor }}</p>
                    <p class="nnum">예약 번호 : <b class="text-success">{{ this.reserveInfo.reqReserveCode }}</b></p>
                    <div class="row col-12">
                      <div class="col-lg-4 gx-1 text-center">
                        <p class="nadd" style="cursor: pointer;" @click="goToProductDetail(this.productInfo.pdtCode)">
                          <small>상품 보기</small>
                        </p>
                      </div>
                      <div class="col-lg-4 gx-1 text-center">
                        <p class="nadd" style="cursor: pointer;" @click="goToCustomerAsk">
                          <small>문의 하기</small>
                        </p>
                      </div>
                      <div class="col-lg-4 gx-1 text-center">
                        <p class="nadd" style="cursor: pointer;" @click="toReview(this.reqRsvCode)">
                          <small>후기 작성</small>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>                
              </div>

              <!-- 여행 일정 정보 -->
              <div class="traval">

                <!-- 엔버스예약 -->
                <div class="container" v-if="ctCode === 'N'">
                  <div class="title text-start">
                    <p>엔버스 일정/경로</p>
                  </div>

                  <!-- pc -->
                  <div class="text-start pc">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-2">출발일자 (이용일자)</th>
                          <td class="col-4">
                            {{ this.getDateFormat(reserveInfo.rentStartDate) }} ({{ reserveInfo.rentTotDays }}일간)
                          </td>

                          <th class="ta-name col-2">인원/가이드/버스</th>
                          <td class="col-4">{{this.reserveInfo.personCnt}}인 / {{ reserveInfo.guideLv }} / {{ reserveInfo.busLv }} ({{ reserveInfo.busDetail }})</td>
                        </tr>
                        <tr>
                          <th class="ta-name">일정/경로</th>
                          <td colspan="3">
                            <div :key="i" v-for="(rd, i) in exist_nbRoute">
                              <div class="input-group mt-1">
                                <span class="input-group-text input-group-sm" style="font-size: small;background-color:gray;color: white;">{{ i+1 }}일차</span>
                                <input type="hidden" class="form-control form-control-sm" style="font-size: small;" ref="iSubNo" v-model="rd.iSubNo">
                                <input type="text" class="form-control form-control-sm" style="font-size: small; color:blue;" ref="deptPoint" v-model="rd.deptPoint" disabled>
                                <input type="text" class="form-control form-control-sm" style="font-size: small; color:green;" ref="viaPoint" v-model="rd.viaPoint" disabled>
                                <input type="text" class="form-control form-control-sm" style="font-size: small; color:red;" ref="arrPoint" v-model="rd.arrPoint" disabled>
                              </div>
                              <div class="input-group mt-1 mb-4">
                                <span class="input-group-text input-group-sm" style="font-size: small;background-color:gray;color: white;">{{ i+1 }}일차 시작시간</span>
                                <input type="time" class="form-control form-control-sm" style="font-size: small; color:blue;" ref="rentStartTime" v-model="rd.rentStartTime" disabled>
                                <span class="input-group-text input-group-sm" style="font-size: small;background-color:gray;color: white;">{{ i+1 }}일차 종료시간</span>
                                <input type="time" class="form-control form-control-sm" style="font-size: small; color:blue;" ref="rentCloseTime" v-model="rd.rentCloseTime" disabled>
                              </div>
                            </div>                            
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">담당자 안내사항</th>
                          <td class="col-md-4">
                            <textarea class="form-control" ref="addComment" id="floatingTextarea" style="height:100px; font-size:smaller;" v-model="reserveInfo.addComment" maxlength="1000" disabled></textarea>
                          </td>
                          <th class="ta-name">
                            예약상태
                            <p class="mb-0" style="font-size:small;" v-if="this.rpCode === 'nonWP'">(가상계좌)</p>
                          </th>
                          <td class="payStatus">
                            <div v-if="this.rpCode === 'nonWP'">
                              {{ this.accPayStatus }}
                            </div>
                            <div v-else>
                              <div class="text-danger" v-if="this.payStyle === 'f'">
                                전액결제완료 (담당매니저 확인 중)
                              </div>
                              <div class="text-warning" v-else-if="this.payStyle === 'p'">
                                부분결제완료 (담당매니저 확인 중)
                              </div>
                              <div class="text-danger" v-if="this.reserveInfo.inqStatus === 'RP' && this.paidFlag == 'Y'">
                                결제완료 (담당매니저 확인 중)
                              </div>
                              <div class="text-primary" v-else-if="this.reserveInfo.inqStatus === 'RP' && this.paidFlag != 'Y'">
                                결제요청
                              </div>
                              <div class="text-danger" v-else-if="this.reserveInfo.inqStatus === 'PY' &&  this.reserveInfo.firstPayment == null">
                                결제완료 (담당매니저 확인 중)
                              </div>
                              <div v-else-if="this.reserveInfo.reqStatus === 'CX'">
                                <s>{{ this.reserveInfo.procName }}</s>
                              </div>
                              <div v-else-if="this.payStyle != 'f' && this.payStyle != 'p'">
                                {{ this.reserveInfo.procName }}
                              </div>
                            </div>
                            <span>
                              <div v-if="this.cxlFlag === 'Y'">
                                <span class="text-danger">[취소요청중]</span>
                              </div>
                              <!-- <div v-else>
                                <button type="button" class="cxlBtn mt-sm-1" @click="reqCxl_nbus(this.reserveInfo.inqCode)">취소요청</button>
                              </div> -->
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">예약안내사항</th>
                          <td class="col-md-4">
                            <textarea class="form-control" ref="addComment" id="floatingTextarea" style="height:100px; font-size:smaller;" v-model="reserveInfo.supPrecaution" maxlength="5000" disabled></textarea>
                          </td>
                          <th class="ta-name">취소안내사항</th>
                          <td class="col-md-4">
                            <textarea class="form-control" ref="addComment" id="floatingTextarea" style="height:100px; font-size:smaller;" v-model="reserveInfo.supCancellation" maxlength="5000" disabled></textarea>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-4">출발일자</th>
                          <td class="col-8">{{ this.getDateFormat(reserveInfo.rentStartDate) }} ({{ reserveInfo.rentTotDays }}일간)</td>
                        </tr>
                        
                        <tr>
                          <th class="ta-name" style="font-size:small;">인원/가이드/버스</th>
                          <td style="font-size:small;">{{this.reserveInfo.personCnt}}인 / {{ reserveInfo.guideLv }} / {{ reserveInfo.busLv }} ({{ reserveInfo.busDetail }})</td>
                        </tr>
                        <tr>
                          <th class="ta-name">일정/경로</th>
                          <td>
                            <div :key="i" v-for="(rd, i) in exist_nbRoute">
                              <span style="font-weight: 600;font-size:small;text-align: start;">{{ i + 1 }} 일차</span>
                              <table class="table table-bordered" style="font-size:small;border-color:lightgray; border-radius: 10px;">
                                <tr>
                                  <th style="width:25%;">출발지</th>
                                  <td style="width:75%;text-align: start;padding:0; padding-left:5px;">{{ rd.deptPoint }}</td>
                                </tr>
                                <tr>
                                  <th style="width:25%;">경유지</th>
                                  <td style="width:75%;text-align: start;padding:0; padding-left:5px;">{{ rd.viaPoint }}</td>
                                </tr>
                                <tr>
                                  <th style="width:25%;">도착지</th>
                                  <td style="width:75%;text-align: start;padding:0; padding-left:5px;">{{ rd.arrPoint }}</td>
                                </tr>
                                <tr>
                                  <th style="width:25%;">시작</th>
                                  <td style="width:75%;text-align: start;padding:0; padding-left:5px;">{{ rd.rentStartTime }}</td>
                                </tr>
                                <tr>
                                  <th style="width:25%;">종료</th>
                                  <td style="width:75%;text-align: start;padding:0; padding-left:5px;">{{ rd.rentCloseTime }}</td>
                                </tr>
                              </table>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">
                            예약상태
                            <p class="mt-0" style="font-size:small;" v-if="this.rpCode === 'nonWP'">(가상계좌)</p>
                          </th>
                          <td class="payStatus mb-0" style="display: flex; align-items: bottom; text-align: center;">
                            <div class="col-md-9 text-danger" v-if="this.payStyle === 'f'">
                              전액결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 text-warning" v-else-if="this.payStyle === 'p'">
                              부분결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 text-danger" v-if="this.reserveInfo.inqStatus === 'RP' && this.paidFlag == 'Y'">
                              결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 text-primary" v-else-if="this.reserveInfo.inqStatus === 'RP' && this.paidFlag != 'Y'">
                              결제요청
                            </div>
                            <div class="col-md-9 text-danger" v-else-if="this.reserveInfo.inqStatus === 'PY' &&  this.reserveInfo.firstPayment == null">
                              결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 " v-else-if="this.reserveInfo.inqStatus === 'CX'">
                              <s>{{ this.reserveInfo.procName }}</s>
                            </div>
                            <div class="col-md-9 " v-else-if="this.payStyle != 'f' && this.payStyle != 'p'">
                              {{ this.reserveInfo.procName }}
                            </div>

                            <div v-if="this.cxlFlag === 'Y'">
                              &nbsp; <span class="text-danger">[취소요청중]</span>
                            </div>
                            <div v-else>
                              &nbsp; <button type="button" class="cxlBtn mt-sm-1" @click="reqCxl_nbus(this.reserveInfo.reqReserveCode)">취소요청</button>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">담당자 안내사항</th>
                          <td colspan="3">
                            <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px;font-size:small;" v-model="reserveInfo.addComment" readonly></textarea>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">예약안내사항</th>
                          <td colspan="3">
                            <textarea class="form-control" ref="addComment" id="floatingTextarea" style="height:100px; font-size:smaller;" v-model="reserveInfo.supPrecaution" maxlength="5000" readonly></textarea>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">취소안내사항</th>
                          <td colspan="3">
                            <textarea class="form-control" ref="addComment" id="floatingTextarea" style="height:100px; font-size:smaller;" v-model="reserveInfo.supCancellation" maxlength="5000" readonly></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>


                <!-- 일반예약 -->
                <div class="container" v-else>
                  <div class="title text-start">
                    <p>여행 일정 정보</p>
                  </div>

                  <!-- pc -->
                  <div class="text-start pc">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-2" v-if="productInfo.salesRoute === 'F'">출발일자</th>
                          <th class="ta-name col-2" v-else>여행기간</th>

                          <td class="col-4"  v-if="productInfo.salesRoute === 'F'">
                            {{this.reserveInfo.reqDptDate}}
                          </td>
                          <td class="col-4" v-else>
                            {{this.reserveInfo.reqDptDate}} ~ {{this.reserveInfo.reqRtnDate}}
                          </td>

                          <th class="ta-name col-2">예약일</th>
                          <td class="col-4">{{this.reserveInfo.reqDate}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">여행지역</th>
                          <td> {{this.productInfo.ntName}} / {{this.productInfo.arName}}</td>
                          <th class="ta-name">예약상태</th>
                          <td>
                            <div class="text-danger" v-if="this.payStyle === 'f' && (this.reserveInfo.reqStatus != 'CO' && this.reserveInfo.reqStatus != 'CX')">
                              전액결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="text-warning" v-else-if="this.payStyle === 'p' && (this.reserveInfo.reqStatus != 'CO' && this.reserveInfo.reqStatus != 'CX')">
                              부분결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="text-danger" v-else-if="this.reserveInfo.reqStatus === 'RP' && this.paidFlag == 'Y'">
                              결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="text-primary" v-else-if="this.reserveInfo.reqStatus === 'RP' && this.paidFlag != 'Y'">
                              결제요청
                            </div>
                            <div class="text-danger" v-else-if="this.reserveInfo.reqStatus === 'PY' && (this.reserveInfo.cstPrice_new === this.reserveInfo.firstPayment || this.reserveInfo.firstPayment == null)">
                              결제완료 (담당매니저 확인 중)
                            </div>
                            <div v-else-if="this.reserveInfo.reqStatus === 'CX'">
                              <s>{{ this.reserveInfo.procName }}</s>
                            </div>
                            <div v-else>
                              {{ this.reserveInfo.procName }}
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">출발 → 도착</th>
                          <td v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'F'">
                            한국&nbsp;{{this.pdtTransInfo1.ddCodeName}} → &nbsp;{{this.productInfo.ntName}}&nbsp;{{this.pdtTransInfo1.daCodeName}}
                          </td>
                          <td v-else-if="productInfo.salesRoute == 'L' || productInfo.salesRoute == 'E'">현지일정</td>
                          <td v-else></td>
                          <th class="ta-name">출발편</th>
                          <td class="trans-info">
                            {{this.pdtTransInfo1.dmobileCodeName}}
                            <small v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'F'">
                              ({{this.pdtTransInfo1.dmobileCode}}{{this.pdtTransInfo1.dpTpCode}},&nbsp;{{this.pdtTransInfo1.d_dpTime}} → {{this.pdtTransInfo1.d_arTime}})
                            </small>
                          </td>
                        </tr>
                        
                        <tr>
                          <th class="ta-name">귀국 → 도착</th>
                          <td v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'F'">
                            {{this.productInfo.ntName}}&nbsp;{{this.pdtTransInfo1.rdCodeName}} → &nbsp;한국&nbsp;{{this.pdtTransInfo1.raCodeName}} 
                          </td>
                          <td v-else-if="productInfo.salesRoute == 'L' || productInfo.salesRoute == 'E'">현지일정</td>
                          <td v-else></td>
                          <th class="ta-name" v-if="productInfo.salesRoute == 'T'">귀국편</th>
                          <th class="ta-name" v-else>리턴편</th>
                          <td class="trans-info">
                            {{this.pdtTransInfo1.rmobileCodeName}}
                            <small v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'F'">
                              ({{this.pdtTransInfo1.rmobileCode}}{{this.pdtTransInfo1.rtTpcode}},&nbsp;{{this.pdtTransInfo1.r_dpTime}} → {{this.pdtTransInfo1.r_arTime}})
                            </small>  
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">선택옵션</th>
                          <td>
                            <div>
                              {{ optName }}
                            </div>
                            <!-- <div v-if="this.selOptions.length <=0">선택된 옵션이 없습니다.</div>
                            <div v-else :key=i v-for="(eo, i) in this.selOptions">
                              {{eo.optPdtName}}
                            </div> -->
                          </td>
                          <th class="ta-name">선택추가상품</th>
                          <td>
                            <div v-if="this.selAddPdt.length <=0">선택된 추가상품이 없습니다.</div>
                            <div v-else :key=i v-for="(ea, i) in this.selAddPdt">
                              {{ea.addPdtName}} : <i></i>&nbsp;<span style="color:darkgray">
                                &nbsp;{{ ea.qty_addPdt }} 개 
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile">
                    <table class="table">
                      <tbody>
                        <tr v-if="productInfo.salesRoute === 'F'">
                          <th class="ta-name col-4">출발일자</th>
                          <td class="col-8">{{this.reserveInfo.reqDptDate}}</td>
                        </tr>
                        <tr v-else>
                          <th class="ta-name col-4">여행기간</th>
                          <td class="col-8">{{this.reserveInfo.reqDptDate}} ~ {{this.reserveInfo.reqRtnDate}}</td>
                        </tr>

                        <tr>
                          <th class="ta-name">예약일</th>
                          <td>{{this.reserveInfo.reqDate}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">여행지역</th>
                          <td> {{this.productInfo.ntName}} / {{this.productInfo.arName}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">예약상태</th>
                          <td>
                            <div class="col-md-9 text-danger" v-if="this.payStyle === 'f' && (this.reserveInfo.reqStatus != 'CO' && this.reserveInfo.reqStatus != 'CX')">
                              전액결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 text-warning" v-else-if="this.payStyle === 'p' && (this.reserveInfo.reqStatus != 'CO' && this.reserveInfo.reqStatus != 'CX')">
                              부분결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 text-danger" v-else-if="this.reserveInfo.reqStatus === 'RP' && this.paidFlag == 'Y'">
                              결제완료 (담당매니저 확인 중)
                            </div>
                            <div class="col-md-9 text-primary" v-else-if="this.reserveInfo.reqStatus === 'RP' && this.paidFlag != 'Y'">
                              결제요청
                            </div>
                            <div class="col-md-9 text-danger" v-else-if="this.reserveInfo.reqStatus === 'PY' && (this.reserveInfo.cstPrice_new === this.reserveInfo.firstPayment || this.reserveInfo.firstPayment == null)">
                              결제완료 (담당매니저 확인 중)
                            </div>
                            <!-- <div class="col-md-9 text-primary" v-else-if="this.reserveInfo.reqStatus === 'PY' && (this.reserveInfo.cstPrice_new != this.reserveInfo.firstPayment && this.reserveInfo.firstPayment != null)">
                              부분결제 (담당매니저 확인 중)
                            </div> -->
                            <div class="col-md-9" v-else-if="this.reserveInfo.reqStatus === 'CX'">
                              <s>{{ this.reserveInfo.procName }}</s>
                            </div>
                            <div class="col-md-9" v-else>
                              {{ this.reserveInfo.procName }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name">출발 → 도착</th>
                          <td v-if="productInfo.salesRoute == 'T'">
                            한국&nbsp;{{this.pdtTransInfo1.ddCodeName}} → &nbsp;{{this.productInfo.ntName}}&nbsp;{{this.pdtTransInfo1.daCodeName}}
                          </td>
                          <td v-else-if="productInfo.salesRoute == 'F'">
                            <p class="mb-0">한국&nbsp;{{this.pdtTransInfo1.ddCodeName}} → </p>
                            <p class="mt-0">{{this.productInfo.ntName}}&nbsp;{{this.pdtTransInfo1.daCodeName}}</p>
                          </td>
                          <td v-else-if="productInfo.salesRoute == 'L' || productInfo.salesRoute == 'E'">현지일정</td>
                          <td v-else></td>
                        </tr>
                        <tr>
                          <th class="ta-name">출발편</th>
                          <td>
                            {{this.pdtTransInfo1.dmobileCodeName}}
                            <small v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'F'">
                              ({{this.pdtTransInfo1.dmobileCode}}{{this.pdtTransInfo1.dpTpCode}},&nbsp;{{this.pdtTransInfo1.d_dpTime}} → {{this.pdtTransInfo1.d_arTime}})
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name">귀국 → 도착</th>
                          <td v-if="productInfo.salesRoute == 'T'">
                            {{this.productInfo.ntName}}&nbsp;{{this.pdtTransInfo1.rdCodeName}} → &nbsp;한국&nbsp;{{this.pdtTransInfo1.raCodeName}} 
                          </td>
                          <td v-if="productInfo.salesRoute == 'F'">
                            <p class="mb-0">{{this.productInfo.ntName}}&nbsp;{{this.pdtTransInfo1.rdCodeName}} → </p>
                            <p class="mt-0">한국&nbsp;{{this.pdtTransInfo1.raCodeName}}</p>
                          </td>
                          <td v-else-if="productInfo.salesRoute == 'L' || productInfo.salesRoute == 'E'">현지일정</td>
                          <td v-else></td>
                        </tr>       
                        <tr>
                          <th class="ta-name" v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'F'">귀국편</th>
                          <th class="ta-name" v-else>리턴편</th>
                          <td>
                            {{this.pdtTransInfo1.rmobileCodeName}}
                            <small v-if="productInfo.salesRoute == 'T'">
                              ({{this.pdtTransInfo1.rmobileCode}}{{this.pdtTransInfo1.rtTpcode}},&nbsp;{{this.pdtTransInfo1.r_dpTime}} → {{this.pdtTransInfo1.r_arTime}})
                            </small>  
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name">선택옵션</th>
                          <td>
                            <div>
                              {{ optName }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name">선택추가상품</th>
                          <td>
                            <div v-if="this.selAddPdt.length <=0 ">선택된 추가상품이 없습니다.</div>
                            <div v-else :key=i v-for="(ea, i) in this.selAddPdt">
                              {{ea.addPdtName}} : <i></i>&nbsp;<span style="color:darkgray">
                                &nbsp;{{ ea.qty_addPdt }} 개 
                                <!-- ( <b class="text-info">{{ this.getCurrencyFormat((ea.aduPdtPrice * ea.qty_addPdt)) }}</b> 원 ) -->
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              
              <!-- 예약자 정보 -->
              <div class="reser">
                <!-- 엔버스예약 -->
                <div class="container" v-if="ctCode === 'N'">
                  <div class="title text-start">
                    <p>예약자 정보</p>
                  </div>
                  <!-- pc -->
                  <div class="text-start pc">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-2">성명</th>
                          <td class="col-4">{{this.reserveInfo.inqName}}</td>
                          <th class="ta-name col-2">연락처</th>
                          <td  class="col-4">{{this.reserveInfo.inqTel}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">문의사항</th>
                          <td colspan="3">
                            <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px;font-size:small;" v-model="reserveInfo.etcInquiry" readonly></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-4">성명</th>
                          <td class="col-8">{{this.reserveInfo.inqName}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">연락처</th>
                          <td>{{this.reserveInfo.inqTel}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">문의사항</th>
                          <td colspan="3">
                            <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px;font-size:small;" v-model="reserveInfo.etcInquiry" readonly></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- 일반예약 -->
                <div class="container" v-else>
                  <div class="title text-start">
                    <p>
                      예약자 정보 <span v-if="reserveInfo.memLevel =='P'" style="color:goldenrod; font-size:1rem;">(프리미엄 멤버십)</span>
                      <span v-if="reserveInfo.memLevel =='S'" style="color:royalblue; font-size:1rem;">(스탠다드 멤버십)</span>
                    </p>
                  </div>

                  <!-- pc -->
                  <div class="text-start pc">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-2">이름</th>
                          <td class="col-4">{{this.reserveInfo.reqName}}</td>
                          <th class="ta-name col-2">연락처</th>
                          <td  class="col-4">{{this.reserveInfo.reqTelNo}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">이메일</th>
                          <td>{{this.email}}</td>
                          <th class="ta-name">여행인원</th>
                          <td>
                            {{this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1}} 명
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name">문의사항</th>
                          <td colspan="3">
                            <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="reserveInfo.reqRemarks" maxlength="200" readonly></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-4">이름</th>
                          <td class="col-8">{{this.reserveInfo.reqName}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">연락처</th>
                          <td>{{this.reserveInfo.reqTelNo}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">이메일</th>
                          <td>{{this.email}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">여행인원</th>
                          <td>
                            {{this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1}} 명
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name">문의사항</th>
                          <td colspan="3">
                            <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px; font-size:smaller;" v-model="reserveInfo.reqRemarks" maxlength="200" readonly></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>                
              </div>

              <!-- 대표 여행자 정보 -->
              <div class="tourist" v-if="this.reserveInfo.myselfFlag === 'N'">
                <div class="container">
                  <div class="title text-start">
                    <p>대표여행자정보</p>
                  </div>

                  <!-- pc -->
                  <div class="text-start pc" v-if="this.reserveInfo.myselfFlag === 'N'">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-2">이름</th>
                          <td class="col-4">{{this.reserveInfo.tripName}}</td>
                          <th class="ta-name col-2">연락처</th>
                          <td class="col-4">{{this.reserveInfo.tripCall}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">이메일</th>
                          <td>{{this.reserveInfo.tripEmail}}</td>
                          <th class="ta-name">여행인원</th>
                          <td>
                            {{this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1}} 명
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile" v-if="this.reserveInfo.myselfFlag === 'N'">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-4">이름</th>
                          <td class="col-8">{{this.reserveInfo.tripName}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">연락처</th>
                          <td>{{this.reserveInfo.tripCall}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">이메일</th>
                          <td>{{this.reserveInfo.tripEmail}}</td>
                        </tr>
                        <tr>
                          <th class="ta-name">여행인원</th>
                          <td>
                            {{this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1}} 명
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- 결제 정보 -->
              <!-- 엔버스 결제 정보 -->
              <div class="payment" v-if="ctCode === 'N'">
                <div class="container">
                  <div class="title text-start">
                    <p>결제정보</p>
                  </div>

                  <!-- pc -->
                  <div class="text-start pc">
                    <table class="table">
                      <tbody>
                        <tr> 
                          <th class="ta-name col-3">할인금액</th>
                          <td class="col-9" colspan="2">
                            <div class="text-start text-primary">
                              {{this.getCurrencyFormat(sumPrice_dc)}}원
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name col-3"><b>총결제금액</b></th>
                          <td class="total text-end">
                            <div>
                              {{ this.getCurrencyFormat(this.nbusPriceTot) }} 원
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay <= 0">
                              <hr>
                              <p style="padding-left: 2rem;">- 결제액 ({{ this.rtnRPflag_tmp.fName }}): &nbsp;{{ getCurrencyFormat(this.rtnRPflag_tmp.actualPayAmount) }}
                              </p>
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay > 0">
                              <hr>
                              <p style="padding-left: 2rem;" v-if="fPayFlag === 'N' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 예약금결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <span v-if="p1PayDate != ''" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
                              <p style="padding-left: 2rem;" v-else-if="fPayFlag === 'Y' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 전액결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <span v-if="p1PayDate != ''" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
          
                              <p style="padding-left: 2rem;" v-if="payPointAmt != '' && payPointAmt > 0"> 
                                <i class="bi bi-stop-fill text-success"></i> 포인트결제: {{ getCurrencyFormat(payPointAmt) }}
                                <span v-if="payPointDate != ''" style="font-size:small;"> ( {{ payPointDate }} )</span>
                                <span v-else></span>
                              </p>
          
                              <p style="padding-left: 2rem;" v-if="p2PayAmt != '' && p2PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-danger"></i> 잔여금결제: {{ getCurrencyFormat(p2PayAmt) }}
                                <span v-if="p2PayDate != ''" style="font-size:small;"> ( {{ p2PayDate }} )</span>
                                <span v-else></span>
                              </p>
                            </div>
                          </td>

                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- 엔버스 모바일 -->
                  <div class="text-start mobile">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-4">할인금액</th>
                          <td class="col-8">
                            <div class="text-center text-primary">
                              {{this.getCurrencyFormat(sumPrice_dc)}}원
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th rowspan="2" class="ta-name"><b>총결제금액</b></th>
                          <td>
                            <div style="font-size:small; font-weight: 600">
                              <p v-if="this.reserveInfo.reqAdultCnt>0">
                                성인 {{ getCurrencyFormat(this.reserveInfo.reqAdultPrice) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqAdultCnt }} 명
                              </p>
                              <p v-if="this.reserveInfo.reqChildCnt>0">
                                소아 {{ getCurrencyFormat(this.reserveInfo.reqChildPrice) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqChildCnt }} 명
                              </p>
                              <p v-if="this.reserveInfo.reqInfCnt>0">
                                유아 {{ getCurrencyFormat(this.reserveInfo.reqInfPrice * this.reserveInfo.reqInfCnt) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqInfCnt }} 명
                              </p>
                            </div>
                            <div v-if="this.selAddPdt.length > 0">
                              <hr>
                              <div class="text-start mb-1" style="font-size:small;"><i class="bi bi-check-lg text-primary"><b>선택옵션</b></i>
                              </div>
                              <div>
                                {{ this.optName }}
                                <span> : {{ getCurrencyFormat(selOptPriceTot ) }} 원</span>
                              </div>
                            </div>
                            <div v-if="this.selAddPdt.length > 0">
                              <hr>
                              <div class="text-start mb-1" style="font-size:small;"><i class="bi bi-check-lg text-info"><b>선택추가상품</b></i></div>
                              <div class="text-start mt-0 mb-0" style="font-size:small;" :key=i v-for="(ea, i) in this.selAddPdt">
                                {{ea.addPdtName}}<i class="bi bi-x-lg"></i><span style="color:darkgray">&nbsp;{{ ea.qty_addPdt }}개
                                  <p>( <b class="text-info">{{ this.getCurrencyFormat((ea.aduPdtPrice * ea.qty_addPdt)) }}</b> 원 )</p>
                                </span>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <td colspan="2" class="total text-end">
                            <div>
                              {{ this.getCurrencyFormat(this.nbusPriceTot) }} 원
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay <= 0">
                              <hr>
                              <p style="padding-left: 2rem;">- 결제액 ({{ this.rtnRPflag_tmp.fName }}): &nbsp;{{ getCurrencyFormat(this.rtnRPflag_tmp.actualPayAmount) }}
                              </p>
                              <!-- <p style="padding-left: 2rem;">- 포인트결제: &nbsp;{{ getCurrencyFormat(this.payPointAmt) }}</p> -->
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay > 0">
                              <hr>
                              <p style="padding-left: 2rem;" v-if="fPayFlag === 'N' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 예약금결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <br>
                                <span v-if="p1PayDate != ''"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
                              <p style="padding-left: 2rem;" v-else-if="fPayFlag === 'Y' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 전액결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <br>
                                <span v-if="p1PayDate != ''"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
          
                              <p style="padding-left: 2rem;" v-if="payPointAmt != '' && payPointAmt > 0"> 
                                <i class="bi bi-stop-fill text-success"></i> 포인트결제: {{ getCurrencyFormat(payPointAmt) }}
                                <br>
                                <span v-if="payPointDate != ''"> ( {{ payPointDate }} )</span>
                                <span v-else></span>
                              </p>
          
                              <p style="padding-left: 2rem;" v-if="p2PayAmt != '' && p2PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-danger"></i> 잔여금결제: {{ getCurrencyFormat(p2PayAmt) }}
                                <br>
                                <span v-if="p2PayDate != ''"> ( {{ p2PayDate }} )</span>
                                <span v-else></span>
                              </p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div class="col d-grid text-end" v-if="this.reserveInfo.inqStatus === 'RP' && this.paidFlag != 'Y'">
                    <div v-if="this.reserveInfo.nbusPriceTot >= 200">
                      <button type="button" class="btn ht-pay btn-primary" @click="pgPay();">
                        <b class="fs-5">온라인결제</b>
                      </button>
                    </div>
                    <div v-else-if="this.reserveInfo.nbusPriceTot == 0">
                      <button type="button" class="btn ht-pay btn-success" @click="freePay(this.reqRsvCode);">
                        <b class="fs-5">무료결제</b>
                      </button>
                    </div>
                    <div id="payment-method" v-show="showFlag" style="height:100%"></div>
                    <div>
                      <button @click="requestPayment(this.reqRsvCode)" class="btn btn-primary" id="payment-button" v-show="showFlag" style="width:100%">
                        <b class="fs-6">결제하기</b>
                      </button>
                    </div>
                  </div>

                  <!-- 부분결제청구: 엔버스부분결제 없음 -->
                  <!-- <div class="col d-grid text-end" v-if="this.rtnRPflag_tmp.reqPayBalance === 'Y'">
                    <div v-if="this.balance >= 200">
                      <button type="button" class="btn ht-pay btn-primary" @click="pgPay('p2');">
                        <b class="fs-5">온라인 잔액결제 ({{ this.getCurrencyFormat(this.balance) }}원)</b>
                      </button>
                    </div>
                    <div v-else-if="this.reserveInfo.cstPrice_new == 0">
                      <button type="button" class="btn ht-pay btn-success" @click="freePay(this.reqRsvCode, 'p2');">
                        <b class="fs-5">무료잔액결제</b>
                      </button>
                    </div>
                    <div id="payment-method" v-show="showFlag" style="height:100%"></div>
                    <div>
                      <button @click="requestPayment(this.reqRsvCode, 'p2')" class="btn btn-primary" id="payment-button" v-show="showFlag" style="width:100%">
                        <b class="fs-6">잔액결제</b>
                      </button>
                    </div>
                  </div> -->

                  <div class="col d-grid text-end">
                    <div class="mt-3 ht-ex">
                      <p>
                        <i class="bi bi-exclamation-triangle-fill text-danger"></i>&nbsp;
                        결제금액이 <b>200원 미만</b> 또는 온라인 결제가 어려운 경우, <span class="mobile-new-line">아래 계좌로 송금을 하실 수 있습니다.</span>
                      </p>
                      <p class="ht-big">
                        <b class="text-success">우리은행 1006-901-267484 &nbsp;예금주: 엔타비글로벌</b>
                      </p>
                      <p>(송금 후 <b>1660-4602</b> 또는 <b>카카오톡</b>으로 연락을 주시면 빠른 확인이 가능합니다)</p>
                      <p>※ 엔타비글로벌은 엔타비글로벌 법인명의계좌 이외로는 송금받지 않습니다</p>
                    </div>
                  </div>
                </div>
              </div>
 
              <!-- 일반상품예약 -->
              <div class="payment" v-else>
                <div class="container">
                  <div class="title text-start">
                    <p>결제정보</p>
                  </div>

                  <!-- pc -->
                  <div class="text-start pc">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-2">
                            <div v-if="this.canPayPointFlag == 'N'">
                              <b>포인트결제가 제한되는 상품</b>
                            </div>
                            <div v-else>
                              가용포인트 (<b class="text-primary">{{ this.getCurrencyFormat(this.availPoint) }}</b>)
                            </div>
                          </th>
                          <td class="col-4">
                            <!-- 포인트결제불가 대응 -->
                            <div class="input-group justify-content-end" v-if="this.canPayPointFlag === 'N'">
                              <input type="number" class="form-control-sm text-end col-9" style="color:darkgray;" ref="txt_payPoint" min="0" v-model="this.pointForPay" disabled>
                              <button type="button" class="btn btn-secondary btn-sm col-3"><i class="bi bi-sign-no-parking-fill text-dark"></i></button>
                            </div>

                            <div class="input-group justify-content-end" v-else>
                              <input type="number" class="form-control-sm text-end col-9" ref="txt_payPoint" min="0" v-model="this.pointForPay" :disabled="pPointFixFlag === 'Y'">

                              <button type="button" class="btn btn-primary btn-sm col-3" @click="payByPoint(this.pointForPay);" v-if="((this.reserveInfo.reqStatus === 'RP' && this.paidFlag != 'Y') || this.rtnRPflag_tmp.reqPayBalance === 'Y') && this.pPointFixFlag === 'N'">사용</button>

                              <button type="button" class="btn btn-warning btn-sm col-3" @click="makeDefpPoint();" v-else>취소</button>
                            </div>                            
                          </td>
                          <th class="ta-name col-2">특별할인금액</th>
                          <td class="col-4">
                            <div class="text-center text-primary" v-if="this.reserveInfo.optDcPrice > 0">
                              {{this.getCurrencyFormat(this.reserveInfo.optDcPrice)}}원
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th class="ta-name"><b>총결제금액</b></th>
                          <td colspan="2">
                            <div style="font-size:small; font-weight: 600">
                              <p v-if="this.reserveInfo.reqAdultCnt>0">
                                성인 {{ getCurrencyFormat(this.reserveInfo.reqAdultPrice) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqAdultCnt }} 명
                              </p>
                              <p v-if="this.reserveInfo.reqChildCnt>0">
                                소아 {{ getCurrencyFormat(this.reserveInfo.reqChildPrice) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqChildCnt }} 명
                              </p>
                              <p v-if="this.reserveInfo.reqInfCnt>0">
                                유아 {{ getCurrencyFormat(this.reserveInfo.reqInfPrice * this.reserveInfo.reqInfCnt) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqInfCnt }} 명
                              </p>
                            </div>

                            <div class="" v-if="this.selOptions.length > 0">
                              <hr>
                              <div><i class="bi bi-check-lg text-primary"><b>선택옵션</b></i>
                              </div>
                              <div>
                                {{ this.optName }}
                                <span> : {{ getCurrencyFormat(selOptPriceTot) }} 원</span>
                              </div>
                            </div>
                            <div class="" v-if="this.selAddPdt.length > 0">
                              <hr>
                              <div><i class="bi bi-check-lg text-info"><b>선택추가상품</b></i></div>
                              <div :key=i v-for="(ea, i) in this.selAddPdt">
                                {{ea.addPdtName}}<i class="bi bi-x-lg"></i><span style="color:darkgray">
                                  &nbsp;{{ ea.qty_addPdt }}개&nbsp;&nbsp;( <b class="text-info">{{ this.getCurrencyFormat((ea.aduPdtPrice * ea.qty_addPdt)) }}</b> 원 )
                                </span>
                              </div>
                            </div>
                          </td>
                          <td class="total text-end">
                            <!-- paidFlag: {{ this.paidFlag }} / {{ this.totalPay }} / 전액결제 {{ this.fPayFlag }} -->
                            <div>
                              {{ this.getCurrencyFormat(this.reserveInfo.cstPrice_new) }} 원
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && this.totalPay <= 0">
                              <hr>
                              <p style="padding-left: 2rem;">- 결제액 ({{ this.rtnRPflag_tmp.fName }}): &nbsp;{{ getCurrencyFormat(this.rtnRPflag_tmp.actualPayAmount) }}
                              </p>
                              <p style="padding-left: 2rem;">- 포인트결제: &nbsp;{{ getCurrencyFormat(this.payPointAmt) }}</p>
                              <p style="padding-left: 2rem;">- 쿠폰결제: &nbsp;{{ getCurrencyFormat(this.payCouponAmt) }}</p>
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay > 0">
                              <hr>
                              <p style="padding-left: 2rem;" v-if="fPayFlag === 'N' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 예약금결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <span v-if="p1PayDate != undefined" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
                              <p style="padding-left: 2rem;" v-else-if="fPayFlag === 'Y' && (p1PayAmt > 0)"> 
                                <i class="bi bi-stop-fill text-warning"></i> 전액결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <span v-if="p1PayDate != undefined" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
 
                              <div v-if="fPayFlag === 'Y'">
                                <p style="padding-left: 2rem;" v-if="payPointAmt != undefined && payPointAmt > 0"> 
                                  <i class="bi bi-stop-fill text-warning"></i> 포인트결제: {{ getCurrencyFormat(payPointAmt) }}
                                  <span v-if="payPointDate != undefined" style="font-size:small;"> ( {{ payPointDate }} )</span>
                                  <span v-else></span>
                                </p>
                                <p style="padding-left: 2rem;" v-if="payCouponAmt != undefined && payCouponAmt > 0"> 
                                  <i class="bi bi-stop-fill text-warning"></i> 쿠폰결제: {{ getCurrencyFormat(payCouponAmt) }}
                                  <span v-if="payCouponDate != undefined" style="font-size:small;"> ( {{ payCouponDate }} )</span>
                                  <span v-else></span>
                                </p>
                              </div>
                              <div v-else>
                                <p style="padding-left: 2rem;" v-if="payPointAmt != undefined && payPointAmt > 0"> 
                                  <i class="bi bi-stop-fill text-success"></i> 예약금 포인트결제: {{ getCurrencyFormat(payPointAmt) }}
                                  <span v-if="payPointDate != undefined" style="font-size:small;"> ( {{ payPointDate }} )</span>
                                  <span v-else></span>
                                </p>
                                <p style="padding-left: 2rem;" v-if="payCouponAmt != undefined && payCouponAmt > 0"> 
                                  <i class="bi bi-stop-fill text-success"></i> 예약금 쿠폰결제: {{ getCurrencyFormat(payCouponAmt) }}
                                  <span v-if="payCouponDate != undefined" style="font-size:small;"> ( {{ payCouponDate }} )</span>
                                  <span v-else></span>
                                </p>
                              </div>

                              <p style="padding-left: 2rem;" v-if="p2PayAmt != '' && p2PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-danger"></i> 잔여금결제: {{ getCurrencyFormat(p2PayAmt) }}
                                <span v-if="p2PayDate != undefined" style="font-size:small;"> ( {{ p2PayDate }} )</span>
                                <span v-else></span>
                              </p>

                              <p style="padding-left: 2rem;" v-if="payPointAmt2 != undefined && payPointAmt2 > 0"> 
                                <i class="bi bi-stop-fill text-success"></i> 잔여금 포인트결제: {{ getCurrencyFormat(payPointAmt2) }}
                                <span v-if="payPointDate2 != undefined" style="font-size:small;"> ( {{ payPointDate2 }} )</span>
                                <span v-else></span>
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr v-if="(reserveInfo.memLevel === 'P' || reserveInfo.memLevel === 'S') && (reserveInfo.dispFlag === 'c2' || reserveInfo.dispFlag === 'c4' || reserveInfo.dispFlag === 'c5')">
                          <th class="ta-name" v-if="reserveInfo.memLevel === 'P'" style="font-weight:normal;">
                            <div style="vertical-align:middle;">
                              <b>프리미엄멤버십 할인액</b>
                              <p style="font-size: small;color:darkgray;">(선택옵션 할인포함)</p>  
                            </div>
                          </th>
                          <th class="ta-name" v-if="reserveInfo.memLevel === 'S'" style="font-weight:normal;">
                            <div style="vertical-align:middle;">
                              <b>스탠다드멤버십 할인액</b>
                              <p style="font-size: small;color:darkgray;">(선택옵션 할인포함)</p>
                            </div>
                          </th>
                          <td colspan="2">
                            <div class="text-start"> <span class="text-danger">※</span> 환급 또는 환불은 선택된 결제수단으로 진행됩니다</div>
                            <div style="font-size:small; font-weight: normal">
                              <p v-if="this.reserveInfo.reqAdultCnt>0">
                                {{ getCurrencyFormat(this.reserveInfo_detail.reqAdultMemP + this.selOPtPriceDCTot_self) }} 원
                              </p>
                            </div>
                          </td>
                          <td class="total text-end fs-6">
                            <span v-if="reserveInfo.memLevel === 'P'" style="color:goldenrod;">{{ this.getCurrencyFormat(this.totRefundAmt + this.selOPtPriceDCTot_self) }} 원</span>
                            <span v-if="reserveInfo.memLevel === 'S'" style="color:royalblue;">{{ this.getCurrencyFormat(this.totRefundAmt + this.selOPtPriceDCTot_self) }} 원</span>
                          </td>
                        </tr>

                        <tr v-if="reserveInfo.tot_comDC > 0">
                          <th class="ta-name" style="font-weight:normal;"><b>멤버십 동반자할인액</b></th>
                          <td colspan="2">
                            <div class="text-start"> <span class="text-danger">※</span> 환급 또는 환불은 선택된 결제수단으로 진행됩니다</div>
                            <div style="font-size:small; font-weight: normal">
                              <!-- <p>
                                {{ getCurrencyFormat(this.reserveInfo.tot_comDC + this.selOPtPriceDCTot_exSelf) }} 원
                              </p> -->
                            </div>
                          </td>
                          <td class="total text-end fs-6">
                            <p style="color:black;">{{ this.getCurrencyFormat(this.reserveInfo.tot_comDC + this.selOPtPriceDCTot_exSelf) }} 원</p>
                          </td>
                        </tr>

                        <tr v-if="(reserveInfo.memLevel === 'P' || reserveInfo.memLevel === 'S') && (reserveInfo.dispFlag === 'c1' || reserveInfo.dispFlag === 'c3')">
                          <th class="ta-name" v-if="reserveInfo.memLevel === 'P'"><b>프리미엄멤버십 환급액</b></th>
                          <th class="ta-name" v-if="reserveInfo.memLevel === 'S'"><b>스탠다드멤버십 환급액</b></th>
                          <td colspan="2">
                            <div class="text-start"> <span class="text-danger">※</span> 환급 또는 환불은 선택된 결제수단으로 진행됩니다</div>
                            <div style="font-size:small; font-weight:normal">
                              <!-- <p v-if="this.reserveInfo.reqAdultCnt>0">
                                {{ getCurrencyFormat(this.reserveInfo_detail.reqAdultMemP) }} 원
                              </p> -->
                            </div>
                          </td>
                          <td class="total text-end fs-4">
                            <span v-if="reserveInfo.memLevel === 'P'" style="color:goldenrod;">{{ this.getCurrencyFormat(this.totRefundAmt + selOptPriceRef_tot) }} 원</span>
                            <span v-if="reserveInfo.memLevel === 'S'" style="color:slategray;">{{ this.getCurrencyFormat(this.totRefundAmt + selOptPriceRef_tot) }} 원</span>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                  <!-- 모바일 -->
                  <div class="text-start mobile">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="ta-name col-4">
                            가용포인트 (<b class="text-primary">{{ this.getCurrencyFormat(this.availPoint) }}</b>)
                          </th>
                          <td class="col-8">
                            <div class="input-group">
                              <input type="number" class="form-control-sm text-end col-9" ref="txt_payPoint" min="0" v-model="this.pointForPay" :disabled="pPointFixFlag === 'Y'">

                              <button type="button" class="btn btn-primary btn-sm col-3" @click="payByPoint(this.pointForPay);" v-if="((this.reserveInfo.reqStatus === 'RP' && this.paidFlag != 'Y') || this.rtnRPflag_tmp.reqPayBalance === 'Y') && this.pPointFixFlag === 'N'">사용</button>

                              <button type="button" class="btn btn-warning btn-sm col-3" @click="makeDefpPoint();" v-else>취소</button>                              
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th class="ta-name col-4">특별할인금액</th>
                          <td class="col-8">
                            <div class="text-center text-primary">
                              {{this.getCurrencyFormat(this.reserveInfo.optDcPrice)}}원
                            </div>
                          </td>
                        </tr>

                        <tr>
                          <th rowspan="2" class="ta-name"><b>총결제금액</b></th>
                          <td>
                            <div style="font-size:small; font-weight: 600">
                              <p v-if="this.reserveInfo.reqAdultCnt>0">
                                성인 {{ getCurrencyFormat(this.reserveInfo.reqAdultPrice) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqAdultCnt }} 명
                              </p>
                              <p v-if="this.reserveInfo.reqChildCnt>0">
                                소아 {{ getCurrencyFormat(this.reserveInfo.reqChildPrice) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqChildCnt }} 명
                              </p>
                              <p v-if="this.reserveInfo.reqInfCnt>0">
                                유아 {{ getCurrencyFormat(this.reserveInfo.reqInfPrice * this.reserveInfo.reqInfCnt) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqInfCnt }} 명
                              </p>
                            </div>

                            <div v-if="this.selOptions.length > 0">
                              <hr>
                              <div class="text-start mb-1" style="font-size:small;"><i class="bi bi-check-lg text-primary"><b>선택옵션</b></i>
                              </div>
                              <div>
                                {{ this.optName }}
                                <span> : {{ getCurrencyFormat(selOptPriceTot ) }} 원</span>
                              </div>
                              <!-- <div class="text-start mt-0 mb-0" style="font-size:small;" :key=i v-for="(eo, i) in this.selOptions">
                                {{eo.optPdtName}}
                                <span> : {{ getCurrencyFormat(selOptPriceTot[i]) }} 원</span>
                              </div> -->
                            </div>
                            <div v-if="this.selAddPdt.length > 0">
                              <hr>
                              <div class="text-start mb-1" style="font-size:small;"><i class="bi bi-check-lg text-info"><b>선택추가상품</b></i></div>
                              <div class="text-start mt-0 mb-0" style="font-size:small;" :key=i v-for="(ea, i) in this.selAddPdt">
                                {{ea.addPdtName}}<i class="bi bi-x-lg"></i><span style="color:darkgray">&nbsp;{{ ea.qty_addPdt }}개
                                  <p>( <b class="text-info">{{ this.getCurrencyFormat((ea.aduPdtPrice * ea.qty_addPdt)) }}</b> 원 )</p>
                                </span>
                              </div>
                            </div>

                          </td>
                        </tr>
                        <tr>
                          <td colspan="2" class="total text-end">
                            <div>
                              {{ this.getCurrencyFormat(this.reserveInfo.cstPrice_new) }} 원
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay <= 0">
                              <hr>
                              <p style="padding-left: 2rem;">- 결제액 ({{ this.rtnRPflag_tmp.fName }}): &nbsp;{{ getCurrencyFormat(this.rtnRPflag_tmp.actualPayAmount) }}
                              </p>
                              <p style="padding-left: 2rem;">- 포인트결제: &nbsp;{{ getCurrencyFormat(this.payPointAmt) }}</p>
                            </div>

                            <div class="addpay" v-if="this.paidFlag === 'Y' && totalPay > 0">
                              <hr>
                              <p style="padding-left: 2rem;" v-if="fPayFlag === 'N' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 예약금결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <br>
                                <span v-if="p1PayDate != undefined"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>
                              <p style="padding-left: 2rem;" v-else-if="fPayFlag === 'Y' && p1PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-warning"></i> 전액결제: {{ getCurrencyFormat(p1PayAmt) }}
                                <br>
                                <span v-if="p1PayDate != undefined"> ( {{ p1PayDate }} )</span>
                                <span v-else></span>
                              </p>

                              <div v-if="fPayFlag === 'Y'">
                                <p style="padding-left: 2rem;" v-if="payPointAmt != undefined && payPointAmt > 0"> 
                                  <i class="bi bi-stop-fill text-warning"></i> 포인트결제: {{ getCurrencyFormat(payPointAmt) }}
                                  <br>
                                  <span v-if="payPointDate != undefined"> ( {{ payPointDate }} )</span>
                                  <span v-else></span>
                                </p>
                                <p style="padding-left: 2rem;" v-if="payCouponAmt != undefined && payCouponAmt > 0"> 
                                  <i class="bi bi-stop-fill text-warning"></i> 포인트결제: {{ getCurrencyFormat(payCouponAmt) }}
                                  <br>
                                  <span v-if="payCouponDate != undefined"> ( {{ payCouponDate }} )</span>
                                  <span v-else></span>
                                </p>
                              </div>
                              <div v-else>
                                <p style="padding-left: 2rem;" v-if="payPointAmt != undefined && payPointAmt > 0"> 
                                  <i class="bi bi-stop-fill text-success"></i> 포인트결제: {{ getCurrencyFormat(payPointAmt) }}
                                  <br>
                                  <span v-if="payPointDate != undefined"> ( {{ payPointDate }} )</span>
                                  <span v-else></span>
                                </p>
                                <p style="padding-left: 2rem;" v-if="payCouponAmt != undefined && payCouponAmt > 0"> 
                                  <i class="bi bi-stop-fill text-success"></i> 포인트결제: {{ getCurrencyFormat(payCouponAmt) }}
                                  <br>
                                  <span v-if="payCouponDate != undefined"> ( {{ payCouponDate }} )</span>
                                  <span v-else></span>
                                </p>
                              </div>
          
                              <p style="padding-left: 2rem;" v-if="p2PayAmt != '' && p2PayAmt > 0"> 
                                <i class="bi bi-stop-fill text-danger"></i> 잔여금결제: {{ getCurrencyFormat(p2PayAmt) }}
                                <br>
                                <span v-if="p2PayDate != undefined"> ( {{ p2PayDate }} )</span>
                                <span v-else></span>
                              </p>

                              <p style="padding-left: 2rem;" v-if="payPointAmt2 != undefined && payPointAmt2 > 0"> 
                                <i class="bi bi-stop-fill text-success"></i> 잔여금 포인트결제: {{ getCurrencyFormat(payPointAmt2) }}
                                <br>
                                <span v-if="payPointDate2 != undefined"> ( {{ payPointDate2 }} )</span>
                                <span v-else></span>
                              </p>
                            </div>
                          </td>
                        </tr>

                        <tr v-if="(reserveInfo.memLevel === 'P' || reserveInfo.memLevel === 'S') && (reserveInfo.dispFlag === 'c1' || reserveInfo.dispFlag === 'c3')">
                          <th rowspan="2" class="ta-name">
                            <b v-if="reserveInfo.memLevel === 'P'" style="font-weight:normal;">프리미엄환급액</b>
                            <b v-else-if="reserveInfo.memLevel === 'S'" style="font-weight:normal;">스탠다드환급액</b>
                            <div class="text-center" style="font-size: 0.6rem;">(환급 또는 환불은 선택된 결제수단으로 진행됩니다)</div>
                          </th>
                          <td>
                            <div style="font-size:small; font-weight: normal">
                              <p v-if="this.reserveInfo.reqAdultCnt>0">
                                성인 {{ getCurrencyFormat(this.reserveInfo_detail.reqAdultMemP + selOptPriceRef_tot) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqAdultCnt }} 명
                              </p>
                              <!-- <p v-if="this.reserveInfo.reqChildCnt>0">
                                소아 {{ getCurrencyFormat(this.reserveInfo_detail.reqChildMemP) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqChildCnt }} 명
                              </p>
                              <p v-if="this.reserveInfo.reqInfCnt>0">
                                유아 {{ getCurrencyFormat(this.reserveInfo_detail.reqInfMemP) }} 원 <i class="bi bi-x-lg"></i>  {{ this.reserveInfo.reqInfCnt }} 명
                              </p> -->
                            </div>
                          </td>
                        </tr>
                        <tr v-if="(reserveInfo.memLevel === 'P' || reserveInfo.memLevel === 'S') && (reserveInfo.dispFlag === 'c1' || reserveInfo.dispFlag === 'c3')">
                          <td colspan="2" class="total text-end fs-6" style="color:goldenrod; font-weight:normal;" v-if="reserveInfo.memLevel === 'P'">
                            {{ this.getCurrencyFormat(this.totRefundAmt + selOptPriceRef_tot) }} 원
                          </td>
                          <td colspan="2" class="total text-end fs-6" style="color:slategray; font-weight:normal;" v-else-if="reserveInfo.memLevel === 'S'">
                            {{ this.getCurrencyFormat(this.totRefundAmt + selOptPriceRef_tot) }} 원
                          </td>                          
                        </tr>

                        <tr  v-if="(reserveInfo.memLevel === 'P' || reserveInfo.memLevel === 'S') && (reserveInfo.dispFlag === 'c2' || reserveInfo.dispFlag === 'c4' || reserveInfo.dispFlag === 'c5')">
                          <th class="ta-name">
                            <b v-if="reserveInfo.memLevel === 'P'" style="font-weight:normal;">
                              프리미엄할인액
                              <p style="font-size: small;color:darkgray;">(옵션할인포함)</p>
                            </b>
                            <b v-else-if="reserveInfo.memLevel === 'S'" style="font-weight:normal;">
                              스탠다드할인액
                              <p style="font-size: small;color:darkgray;">(옵션할인포함)</p>
                            </b>
                            <div class="text-center" style="font-size: 0.6rem;">(환급 또는 환불은 선택된 결제수단으로 진행됩니다)</div>
                          </th>
                          <td>
                            <div style="font-size:small; font-weight: normal">
                              <span v-if="reserveInfo.memLevel === 'P'" style="color:goldenrod;">{{ this.getCurrencyFormat(this.totRefundAmt + this.selOPtPriceDCTot_self) }} 원</span>
                              <span v-if="reserveInfo.memLevel === 'S'" style="color:royalblue;">{{ this.getCurrencyFormat(this.totRefundAmt + this.selOPtPriceDCTot_self) }} 원</span>
                            </div>
                          </td>                          
                        </tr>
                        <tr v-if="reserveInfo.tot_comDC > 0">
                          <th class="ta-name" style="font-weight:normal;">
                            멤버십<br>동반자할인액
                            <div class="text-center" style="font-size: 0.6rem;">(환급 또는 환불은 선택된 결제수단으로 진행됩니다)</div>
                          </th>
                          <td colspan="2">
                            <div style="font-size:small; font-weight: normal">
                              <p>
                                {{ getCurrencyFormat(this.reserveInfo.tot_comDC + this.selOPtPriceDCTot_exSelf) }} 원
                              </p>
                            </div>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>

                  <!-- 최초 온라인결제 -->
                  <div class="col d-grid text-end" v-if="this.reserveInfo.reqStatus === 'RP' && this.paidFlag != 'Y'">
                    <div v-if="this.reserveInfo.cstPrice_new >= 200">
                      <button type="button" class="btn ht-pay btn-primary" @click="pgPay();">
                        <b class="fs-5">온라인결제</b>
                      </button>
                    </div>
                    <div v-else-if="this.reserveInfo.cstPrice_new == 0">
                      <button type="button" class="btn ht-pay btn-success" @click="freePay(this.reqRsvCode);">
                        <b class="fs-5">무료결제</b>
                      </button>
                    </div>
                    <div id="payment-method" v-show="showFlag" style="height:100%"></div>
                    <div>
                      <button @click="requestPayment(this.reqRsvCode)" class="btn btn-primary" id="payment-button" v-show="showFlag" style="width:100%">
                        <b class="fs-6">결제하기</b>
                      </button>
                    </div>
                  </div>

                  <!-- 부분결제청구 -->
                  <div class="col d-grid text-end" v-if="this.rtnRPflag_tmp.reqPayBalance === 'Y'">
                    <div v-if="this.balance >= 200">
                      <button type="button" class="btn ht-pay btn-primary" @click="pgPay('p2');">
                        <b class="fs-5">온라인 잔액결제 ({{ this.getCurrencyFormat(this.balance) }}원)</b>
                      </button>
                    </div>
                    <div v-else-if="this.reserveInfo.cstPrice_new == 0">
                      <button type="button" class="btn ht-pay btn-success" @click="freePay(this.reqRsvCode, 'p2');">
                        <b class="fs-5">무료잔액결제</b>
                      </button>
                    </div>

                    <div id="payment-method" v-show="showFlag" style="height:100%"></div>
                    
                    <div>
                      <button @click="requestPayment(this.reqRsvCode, 'p2')" class="btn btn-primary" id="payment-button" v-show="showFlag" style="width:100%">
                        <b class="fs-6">잔액결제</b>
                      </button>
                    </div>
                  </div>

                  <div class="col d-grid text-end">
                    <div class="mt-3 ht-ex">
                      <p>
                        <i class="bi bi-exclamation-triangle-fill text-danger"></i>&nbsp;
                        결제금액이 <b>200원 미만</b> 또는 온라인 결제가 어려운 경우, <span class="mobile-new-line">아래 계좌로 송금을 하실 수 있습니다.</span>
                      </p>
                      <p class="ht-big">
                        <b class="text-success">우리은행 1006-901-267484 &nbsp;예금주: 엔타비글로벌</b>
                      </p>
                      <p >(송금 후 <b>1660-4602</b> 또는 <b>카카오톡</b>으로 연락을 주시면 빠른 확인이 가능합니다)</p>
                      <p >※ 엔타비글로벌은 엔타비글로벌 법인명의계좌 이외로는 송금받지 않습니다</p>
                    </div>
                  </div>

                </div>
              </div>

              <!-- 마이페이지 버튼 -->
              <div class="row justify-content-center mb-0">
                <div class="col-lg-4 d-grid">
                  <button type="button" class="btn go btn-md mt-0" @click="goToMypage()">마이페이지로</button>
                </div>&nbsp;&nbsp;&nbsp;
                <div class="col-lg-4 d-grid" v-if="reserveInfo.reqStatus != 'CX'">
                  <div v-if="ctCode==='N'">
                    <button type="button" class="btn go btn-md mt-0" style="background-color:brown;" @click="reqCxl_nbus(this.reserveInfo.inqCode)" v-if="res_checkReqCxl.length <= 0">취소요청하기</button>
                    <button type="button" class="btn go btn-md mt-0" style="background-color:brown;" v-else disabled>취소요청 중</button>
                  </div>
                  <div v-else>
                    <button type="button" class="btn go btn-md mt-0" style="background-color:brown;" @click="reqCxl(this.reqRsvCode, this.productInfo.pdtCode)" v-if="res_checkReqCxl.length <= 0">취소요청하기</button>
                    <button type="button" class="btn go btn-md mt-0" style="background-color:brown;" v-else disabled>취소요청 중</button>
                  </div>
                </div>
                <div class="col-lg-4 d-grid" v-else-if="reserveInfo.reqStatus == 'CX'">
                  <button type="button" class="btn go btn-md mt-0" style="background-color:brown;" disabled>취소완료</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";
import axios from 'axios';
import { getAvailPoint } from '../assets/js/commonFunctions.js';
import { handlerInfo } from '../assets/js/commonFunctions'

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      showFlag: false,        //결제창 표시여부

      //토스페이결제용
      paymentWidget: null,
      paymentMethodWidget: null,
      //clientKey: "test_ck_YyZqmkKeP8gG2Klanln8bQRxB9lG",
      clientKey: "live_ck_jZ61JOxRQVEZXa1aoR0rW0X9bAqw",
      customerKey: "Ntabi-1936=@",


      isReviewFlag: "",         //기존리뷰 존재여부
      aPromoFlag: "N",          //적용프로모션 존재여부
      reserveInfo: [],          //예약정보
      reserveInfo_detail: [],   //예약디테일(금액정보)
      selEctOptList: [],        //예약된 선택옵션
      productCode: "",          //상품코드
      email: "",                //예약자이메일(원본 또는 수정)

      //userPayInfo: [],          //결제내역정보
      
      availPoint: 0,            //가용포인트

      productInfo: [],          //상품정보

      paidPoint: 0,             //사용포인트
      payPointFlag: "N",        //포인트사용여부

      pointForPay: 0,           //결제에 사용할 포인트

      payByPointFlag: "N",      //포인트사용 플래그
      pointForPay_tmp: 0,       //포인트 복구용 직전 사용예정 포인트
      rtnRPflag_tmp: [],        //결제 후 포인트와 결제정보

      rtnRPflag: "N",           //결제요청여부
      paidFlag: "N",            //결제여부

      productTrans: [],
      pdtTransInfo1: [],        //기존교통정보
      pdtTransInfo: [],
      pdtPromoInfo: [],         //프로모션정보

      pdtFlag: "",              //상품종류

      productImage: [],         //상품이미지

      selectedProcCode: "",     //예약처리상태

      sRoute: [],               //상품구분

      reqMarginRate: 0,         //당해예약마진
      reqMarginMethod: "P",     //당해예약마진계산방법(P/A)

      productPromotion: [],
      choicePromotion: [],      //선택된 프로모션정보
      promoList: [],            //해당상품의 프로모션목록

      optFlag: "",              //옵션선택플래그

      pdtcode: "",

      productPrice: [],

      promoStart: "",
      promoEnd: "",

      res_checkReqCxl: [],    //취소요청여부

      totRefundAmt: 0,        //환급액전액

      // 구매된 옵션 & 추가상품
      selOptFlag: "N",              //선택옵션유무
      selAddFlag: "N",              //선택추상유무
      pdtOptInfo: [],               //상품선택옵션정보
      pdtOptFlag: "N",              //선택옵션유무
      calOptFlag: "N",              //선택옵션유무(예약용)
      selOptions: [],               //선택된 옵션정보
      tot_selOptPrice:  0,          //선택된 옵션총액
      selOptNames: [],              //선택옵션명

      addPdtInfo: [],               //추가상품정보
      addPdtFlag: "N",              //추가상품유무
      calAddFlag: "N",              //추가상품유무(예약용)
      selAddPdt: [],                //선택된 추가상품정보
      tot_selAddPdtPrice:  0,       //선택된 추상총액
      selAddPdtNames: [],           //선택추상명

      balance: 0,                   //잔여대금
      payStyle: "",                 //전액f, 부분p
      pPoint: 0,                    //결제하려는 포인트액

      /////결제히스토리용 정보
      userPayInfo: [],              //히스토리용 결제내역(향후 내역은 이 배열을 이용)
      p1PayAmt: 0, 
      p1PayDate: "",
      p2PayAmt: 0, 
      p2PayDate:"",
      payPointDate: "",
      payPointDate2: "",
      fPayFlag: "",                //예약금결제N, 전액결제Y
      totalPay: 0,                 //히스토리합산
      payPointAmt: 0,             //p1포인트결제액
      payPointAmt2: 0,            //p2포인트결제액

      payCouponDate: "",
      payCouponDate2: "",
      payCouponAmt: 0,             //p1쿠폰결제액
      payCouponAmt2: 0,            //p2쿠폰결제액


      optName: "",                //선택된 옵션명 
      selOptPriceTot: [],         //선택옵션별 총액
      selOPtPriceDCTot_self: 0,   //선택옵션 본인할인액
      selOPtPriceDCTot_exSelf: 0, //선택옵션 동반할인액(본인제외)
      selOptPriceRef_tot: 0,      //선택옵션 환급총액
      paymentInFull: "N",         //완납여부

      virAccInfo: [],             //가상계좌결제정보
      accPayStatus: "",           //가상계좌결제상태

      //엔버스 정보
      nbusInfo: [],               //엔버스 마스타정보
      exist_nbRoute: [],          //엔버스 경로정보   
      
      sumPrice_dc: 0,             //엔버스할인총액
      nbusPriceTot: 0,            //엔버스결제총액

      handTel: [],                //매니저연락처
      handTelCnt: 0,              //수신처갯수

      cxlFlag: "N",               //취소요청상태

      pPointFixFlag: "N",         //포인트사용 고정처리
      balance_org: 0,             //포인트사용 수정으로 초기화대응
      cstPrice_new_org: 0,
      canPayPointFlag: "Y",       //포인트결제가능 플래그

    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.reqRsvCode = this.$route.query.seqReq;
    this.productId = this.$route.query.pCode;
    this.ctCode = this.$route.query.cCode;                 //N:엔버스 Y:그 외
    this.salesRoute = this.$route.query.salesRoute;
    this.ntCd = this.$route.query.ntCd;
 
    //console.log("USER:",this.user);

    this.getReserInfo();

    if(this.ctCode === "Y")     //상품예약인 경우
    {
      this.getProductInfo();
      this.getProductImage();
      //적용가능한 프로모션
      this.getPdtPromotion_apply();
      this.getProductPromotion(); //상품프로모션정보
      this.getPromotionList();
    }

    this.getReqProcLevel()
    this.getHandlerInfo()                   //알림톡 발송대상 체크
  },
  mounted() {
    // if(document.referrer == "")
    // {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    
    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined)
    {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    //mixin함수
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    //한국표준시로 변경
    getKorDateFormat(date) {
      return this.$korDateFormat(date)
    },
    //원단위절사
    getTruncateToWon(value){
      return this.$truncateToWon(value)
    },

    //엔버스가격구성
    async getNbusPrice(nbCode){
      //할인총액
      let pmFlag = "M"
      let nUseFlag = "Y"
      let nbusPriceSum = await this.$api("/api/nbusPriceSum", {param: [ nbCode, pmFlag, nUseFlag ]})
      //console.log("nbusPriceSum:", nbusPriceSum)
      if(nbusPriceSum.length > 0)
      {
        this.sumPrice_dc = (nbusPriceSum[0].sumPrice < 0) ? 0 : nbusPriceSum[0].sumPrice
      }
      //console.log("sumPrice_dc:", this.sumPrice_dc)

      let nbusPriceTot = await this.$api("/api/resNbusPrice", {param: [ nbCode, "Y"]})
      this.nbusPriceTot = (nbusPriceTot[0].result === undefined || !nbusPriceTot[0].result) ? 0 : nbusPriceTot[0].result
      //console.log("nbusPriceTot:", this.nbusPriceTot)
    },
    
    async getResCxlCheck(rCode){
      let cxlStatus = await this.$api("/api/checkReqCxl", {param: [rCode]});

      if(cxlStatus.length > 0)
      {
        this.cxlFlag = "Y"
      }

      //console.log("cxlFlag:", this.cxlFlag)
    },

    async getReserInfo()
    {
      //예약 또는 엔버스 정보마스터
      let reserveInfo = []

      //엔버스 루트
      let exist_nbRoute = []

      if(this.ctCode === "N")
      {
        reserveInfo = await this.$api("/api/reqResConsultDetail_nbus", {param: [this.reqRsvCode]});
        //엔버스문의 디테일(일정루트)
        exist_nbRoute = await this.$api("/api/nbusInqInfo_d", {param: [this.reqRsvCode]});

        if(reserveInfo.length > 0)
        {
          this.reserveInfo = reserveInfo[0]
          //이용시작일, 이용종료일
          this.reserveInfo.rentStartDate = this.getDateFormat(this.reserveInfo.rentStartDate);
          this.reserveInfo.insDate = this.getDateFormat(this.reserveInfo.insDate);
        }
        //console.log("reserveInfo:",this.reserveInfo)

        if(exist_nbRoute.length > 0)
        {
          this.exist_nbRoute = exist_nbRoute
        }
        //console.log("exist_nbRoute:",this.exist_nbRoute)
      }      
      
      else
      {
        reserveInfo = await this.$api("/api/resConsultDetail", {param: [this.reqRsvCode]});
        let reserveInfo_detail = await this.$api("/api/reservePriceInfo", {param: [this.reqRsvCode]});

        if(reserveInfo.length > 0)
        {
          this.reserveInfo = reserveInfo[0];
          this.productCode = reserveInfo[0].reqPdtCode;
          this.reserveInfo.optDcPrice = (this.reserveInfo.optDcPrice == null) ? 0 : this.reserveInfo.optDcPrice
          
          this.email = (this.reserveInfo.conEmail == undefined || this.reserveInfo.conEmail == null) ? this.reserveInfo.reqEmail : this.reserveInfo.conEmail
          
          //출발예정일-리턴예정일
          this.reserveInfo.reqDptDate = this.getDateFormat(this.reserveInfo.reqDptDate);
          this.reserveInfo.reqRtnDate = this.getDateFormat(this.reserveInfo.reqRtnDate);
          this.reserveInfo.reqDate = this.getDateFormat(this.reserveInfo.reqDate); //insDate 예약일
          //this.pdtFlag = this.pdtCode.substring(0,2)
          this.pdtFlag = (this.productId.substring(0,2) === 'MX') ? "MX" : "SG"

          this.reqMarginRate = (this.reserveInfo.reqMarginRate == null) ? this.productInfo.basicMargin : this.reserveInfo.reqMarginRate;
          this.reqMarginMethod = (this.reserveInfo.reqMarginMethod == null) ? this.productInfo.marginMethod : this.reserveInfo.reqMarginMethod;
        }
        if(reserveInfo_detail.length > 0)
        {
          //환급은 당사자만 대상
          this.totRefundAmt = reserveInfo_detail[0].reqAdultMemP * 1
          this.reserveInfo_detail = reserveInfo_detail[0]
        }
        //console.log("reserInfo:>>>>", this.reserveInfo, ", reserveInfo_detail:::::", this.reserveInfo_detail, ", totRefundAmt:::::", this.totRefundAmt)
      }

        //가용포인트 체크
      this.getAvailPoint();
      
      //결제요청 여부
      this.getReqPayInfo(this.reqRsvCode);
      //리뷰작성가능체크
      this.checkReviewFlag();

      //취소요청여부체크
      this.getCheckReqCxl(this.reqRsvCode)

      if(this.ctCode === "Y")
      {
        // 선택된 옵션
        this.getSelOptions_new(this.reqRsvCode)

        // 선택된 추가상품
        this.getSelAddProductions(this.reqRsvCode)
      }

      //엔버스인 경우 가격정보
      if(this.ctCode === "N")
      {
        this.getNbusPrice(this.reqRsvCode)
      }

      //취소요청여부 체크
      this.getResCxlCheck(this.reqRsvCode)      
    },


    //선택옵션획득(선택옵션 중복선택방지 반영처리: 20240730)
    async getSelOptions_new(resCode) {
      let selOptions = []
      let useFlag = "Y"
      //let selOptNames_tmp = []

      //selOptions = await this.$api("/api/getSelOptions", {param: [resCode, useFlag]});
      selOptions = await this.$api("/api/getSelOptions_new", {param: [resCode, useFlag]});
      //console.log("selOptions:",selOptions);

      if(selOptions.length > 0)
      {
        this.selOptions = selOptions
        this.selOptFlag = "Y"

        let optName = ""                     //선택옵션명 
        let selOptPriceSelf = 0              //선택옵션 본인
        let selOptPriceRef  = 0              //선택옵션 환급액
        let selOptPriceAdu  = 0
        let selOptPriceTot_exSelf  = 0       //본인제외 동반옵션액(할인포함)
        let selOptPriceTot  = 0              //본인포함 동반옵션액(할인포함)
        let selOPtPriceDCTot_self = 0        //옵션할인본인
        let selOPtPriceDCTot_exSelf = 0      //옵션할인동반인(본인제외)
        let selOptPriceRef_tot = 0           //환급액총액
        // let selOptPriceChi  = []
        // let selOptPriceInf  = []

        // for(let i=0; i<selOptions.length; i++)
        // {
          optName = selOptions[0].optPdtName
          selOptPriceSelf =  selOptions[0].optPrice - selOptions[0].optPriceDCForStd
          selOptPriceRef  = (selOptions[0].optPriceRef_adu == undefined) ? 0 : selOptions[0].optPriceRef_adu
          selOptPriceAdu  = (selOptions[0].cntAdu > 1) ? (selOptions[0].optPrice - selOptions[0].optPriceDC_adu) : 0
          
          selOptPriceTot_exSelf  = selOptPriceAdu*(selOptions[0].cntAdu-1) + ((selOptions[0].optPrice_chi-selOptions[0].optPriceDC_chi)*selOptions[0].cntChi) + ((selOptions[0].optPrice_inf-selOptions[0].optPriceDC_inf)*selOptions[0].cntInf)

          selOptPriceTot = (selOptPriceRef > 0) ? (selOptPriceRef+selOptPriceTot_exSelf) : (selOptPriceSelf+selOptPriceTot_exSelf)
          selOPtPriceDCTot_self = (selOptions[0].optPrice - selOptPriceSelf)

          selOPtPriceDCTot_exSelf = (selOptions[0].optPriceDC_adu*(selOptions[0].cntAdu-1)) + ((selOptions[0].optPriceDC_chi)*selOptions[0].cntChi) + ((selOptions[0].optPriceDC_inf)*selOptions[0].cntInf)

          selOptPriceRef_tot = selOptPriceRef 

          //console.log(">>>>>",selOptPriceSelf, selOptPriceRef, selOptPriceAdu, selOptPriceTot_exSelf, selOptPriceTot, selOPtPriceDCTot_self, selOPtPriceDCTot_exSelf, selOptPriceRef_tot)
        // }
        this.optName = optName
        this.selOptPriceTot = selOptPriceTot
        this.selOPtPriceDCTot_self = selOPtPriceDCTot_self
        this.selOPtPriceDCTot_exSelf = selOPtPriceDCTot_exSelf
        this.selOptPriceRef_tot = selOptPriceRef_tot
        //console.log("this.selOptPriceTot:", this.selOptPriceTot, this.optName,">>>>", this.selOPtPriceDCTot_self, this.selOptPriceRef_tot)

      }
      else
      {
        this.selOptFlag  = "N"
        this.optName = "선택된 옵션이 없습니다"
      }
      //console.log(selOptPriceSelf)
      // console.log("selOptNames:", this.selOptNames)
    },


    //선택추가상품획득
    async getSelAddProductions(resCode) {
      let selAddPdt = []
      let useFlag = "Y"
      selAddPdt = await this.$api("/api/getSelAddProductions", {param: [resCode, useFlag]});

      if(selAddPdt.length > 0)
      {
        this.selAddPdt = selAddPdt
        this.selAddFlag = "Y"
        
        //선택추상가격*수량
        selAddPdt.forEach(sa => {
          this.tot_selAddPdtPrice += (sa.addPdtPrice * sa.qty_addPdt)
          this.selAddPdtNames.push(sa.addPdtName)
        });
      }
      else
      {
        this.selAddFlag = "N"
        this.selAddPdtNames = "없음"
      }
      // console.log("selAddPdtNames:", this.selAddPdtNames)
      //console.log("selAddPdt:",this.selAddPdt, "tot_selAddPdtPrice:", this.tot_selAddPdtPrice);
    },    


    async getProductInfo()
    {
      this.pdtFlag = (this.productId.substring(0,2) === 'MX') ? "MX" : "SG"
      let productInfo = {};
      if(this.pdtFlag === "SG")
      {
        productInfo = await this.$api("/api/pdtInfo", {param: [this.productId]});
      }
      else if(this.pdtFlag === "MX")
      {
        productInfo = await this.$api("/api/pdtInfo_mix", {param: [this.productId]});
      }
      else
      {
        return this.$swal.fire("","상품이 검색되지 않습니다<br>재확인 후 시도해 주세요.","error");
      }
      this.productInfo = productInfo[0]
      this.productInfo.tripStart = this.getDateFormat(this.productInfo.tripStart);
      this.productInfo.tripEnd = this.getDateFormat(this.productInfo.tripEnd);
      this.productInfo.salesStart = this.getDateFormat(this.productInfo.salesStart);
      this.productInfo.salesEnd = this.getDateFormat(this.productInfo.salesEnd);
      //console.log("productInfo:",this.productInfo);

      this.getProductTransInfoExited(); //기존교통정보

      // //포인트결제 가능여부 체크 => 포인트 결제제한 해제(2025-03-18)
      // if(this.productInfo.memLevel_P == "Y" || this.productInfo.memLevel_S == "Y" )
      // {
      //   this.canPayPointFlag = "N"
      // }
    },

    async getProductImage() {
      // 상세페이지용 카로우셀 이미지
      let productImage = await this.$api("/api/productImage_new", {param: [this.productId,'T']});
      //this.productImage = (productImage.length > 0) ? productImage : "";
      if(productImage.length > 0)
      {
        this.productImage = productImage[0]
      }
      //console.log("productImage:",this.productImage)
    },
    //기존상품 프로모션정보
    async getPdtPromotion() {
      let pdtPromoInfo = await this.$api("/api/pdtPromo_existed", {param: [this.productId]});
      if(pdtPromoInfo.length > 0) {
        this.pdtPromoInfo = pdtPromoInfo[0];
        if(this.pdtPromoInfo.promoStart != undefined){
          this.pdtPromoInfo.promoStart = this.getDateFormat(this.pdtPromoInfo.promoStart);
        }
        if(this.pdtPromoInfo.promoEnd != undefined) {
          this.pdtPromoInfo.promoEnd = this.getDateFormat(this.pdtPromoInfo.promoEnd);
        }
      }
      //console.log("pdtPromoInfo:",this.pdtPromoInfo);
    },

    //선택된 기타옵션 적용일(기존)
    // async getSelectedEtcOptionDate() {
    //   let selectedEtcOptApplyDate = await this.$api("/api/selectedEtcOptApplyDate", {param: [this.reqRsvCode]});
    //   if(selectedEtcOptApplyDate.length > 0) {
    //     this.selectedEtcOptApplyDate = selectedEtcOptApplyDate.map((item) => this.getDateFormat(item.applyDate));
    //     //선택된 기타옵션의 적용일이 존재
    //     this.applyDateFlag = "Y";
    //   }
    // },

    /** 카카오페이 처리로직 */
    async kPay()
    {
      //포인트처리
      let payByPoint = await this.$api("/api/payByPoint", {param: [
        this.reserveInfo.reqEmail,this.paidPoint,'S',this.reqRsvCode,'사용대기'
      ]});
      if(payByPoint.affectedRows < 0) 
      {
        this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다(코드: payByPoint)<br>포인트 복구문의: 1660-4602', 'error');
        return false;
      }
      else
      {
        // 성공 URL(핸들러등록)
        // const successUrl = `${window.location.protocol}//${window.location.host}/success_kPay&${this.reserveInfo.reqReserveCode}`;
        const successUrl = `${window.location.protocol}//${window.location.host}/success_kPay`;
        // 실패 or 취소 URL(핸들러등록)
        const failUrl = `${window.location.protocol}//${window.location.host}/fail_kPay`;

        let kakaoData = {
          'cid': '',
          'partner_order_id': this.reserveInfo.reqReserveCode,
          'partner_user_id': 'ntabi',
          'item_name': this.productInfo.pdtNameKor,
          'item_code': this.productId,
          'quantity': 1,
          'total_amount': this.reserveInfo.cstPrice_new,
          'vat_amount': this.reserveInfo.reqVatPrice,
          'tax_free_amount': this.reserveInfo.cstPrice_new - this.reserveInfo.reqVatPrice,
          'approval_url': successUrl,
          'fail_url': failUrl,
          'cancel_url': failUrl
        }

        //결제승인처리를 Success_kPay로 이동(20230321)
        try
        {
          //const response = await this.$api("api/kakaoPay", { param: kakaoData });
          const response = await axios.post('/api/kakaoPay', { param: kakaoData });
          const tid = response.data.tid;

          localStorage.setItem('tid', tid)
          localStorage.setItem('partner_order_id',kakaoData.partner_order_id)
          localStorage.setItem('total_amount',kakaoData.total_amount)

          const nextUrl = response.data.next_redirect_pc_url;
          window.location.href = nextUrl;
        }
        catch (error)
        {
          console.log(error);
        }
      }
    },

    //포인트 사용 초기화
    makeDefpPoint(){
      this.pPointFixFlag = "N"
      this.pointForPay = 0
      if(this.payStyle === "p")
      {
        this.balance = this.balance_org
      }
      else if(this.payStyle != "p")
      {
        this.reserveInfo.cstPrice_new = this.cstPrice_new_org
      }
    },

    // 가용포인트 사용처리
    payByPoint(pPt)            //pPoint = pointForPay
    {
      if(pPt < 0)
      {
        this.pointForPay = 0
        this.$swal.fire("","사용 포인트는 0이상이어야 합니다.", "warning")
        return false
      }
      else if(pPt > this.availPoint)
      {
        this.pointForPay = 0
        this.$swal.fire("","사용포인트는 가용포인트보다 클 수 없습니다.", "warning")
        return false 
      }
      else
      {
        if(this.payStyle === "p")
        {
          if(pPt > this.balance)
          {
            this.pointForPay = 0
            this.$swal.fire("","사용하려는 포인트는 결제금액보다 클 수 없습니다.","warning")
            return false
          }
          else if(pPt == this.balance)
          {
            // this.$swal.fire("","포인트로 전액결제를 하시겠습니까?", "question")
            // return false
            this.$swal.fire({
              icon: "question",
              html: "포인트로 전액결제를 하시겠습니다?",
              showCancelButton: true,
              confirmButtonText: '네, 그렇습니다',
              cancelButtonText: '아니요',
            }).then(async (result) => {
              if(result.isConfirmed)
              {
                //alert("전액결제")
                //전액을 포인트로 결제
                this.payByPointOnly(this.reqRsvCode, "Y", this.payStyle, 0, pPt)
              }
              else
              {
                location.reload(true)
              }
            })
          }
          else
          {
            //수정대응
            this.balance_org = this.balance
            this.balance = this.balance - pPt
          }
        }
        else if(this.payStyle != "p")
        {
          if(pPt > this.reserveInfo.cstPrice_new)
          {
            this.pointForPay = 0
            this.$swal.fire("","사용하려는 포인트는 결제금액보다 클 수 없습니다.","warning")
            return false
          }
          else
          {
            this.cstPrice_new_org = this.reserveInfo.cstPrice_new 
            this.reserveInfo.cstPrice_new = this.reserveInfo.cstPrice_new - pPt
          }
        }
      }
      this.pointForPay = pPt           //최종 사용할 포인트
      this.paidPoint = pPt
      this.pPointFixFlag = "Y"
    },

    //전액포인트처리(p2)
    async payByPointOnly(rCode, mFlag, pFlag, pgPayment, pPoint){
      //console.log(rCode, mFlag, pFlag, pgPayment, pPoint)

      let resCodeForVarify = rCode + "-1"
      //결제액을 포인트로 결제
      let payByPoint = await this.$api("/api/payByPoint", {param: [
        this.user.email, pPoint, 'S', rCode, '사용대기', 'Y'
      ]});
      if(payByPoint.affectedRows < 0 || payByPoint.affectedRows == undefined)
      {
        this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다.<br>포인트 복구문의: 1660-4602', 'error');
        return false;
      }
      //결제포인트가 반영되도록 검증데이타 업데이트
      else
      {
        // let updVerifyData = []
        // let resCode = ""

        //p2 검증데이타 생성
        let veriSign = "N"
        let insVeriData = []
        let payAmount = pgPayment
        let payPointAmt = pPoint

        if(pFlag === "p")
        {
          let flag = "p2"
          payAmount = this.balance
          insVeriData = await this.$api("/api/proc_setVeriData_1", {param:
            [rCode+"-2", payAmount, veriSign, flag, payPointAmt ]
          });
        }
        else
        {
          payAmount = this.reserveInfo.cstPrice_new
          insVeriData = await this.$api("/api/proc_setVeriData", {param:
            [rCode, payAmount, veriSign, payPointAmt ]
          });
        }

        // updVerifyData = await this.$api("/api/updVerifyData", {param:
        //     [ pgPayment, this.pPoint, resCode ]
        //   });

        if(insVeriData.affectedRows < 0)
        {
          this.$swal.fire("","검증데이타가 생성되지 않았습니다. 재시도해주세요", "warning")
          return false;
        }
        else
        {
          //결제요청정보저장(시스템 내부처리용)
          let updReqPayForPoint = []
          let reqPayStatus = "PY"
          let paidId = this.email
          let actualPayAmount = 0
          let payFlag = ""

          if(pFlag == "p")
          {
            payFlag = "p2"
            updReqPayForPoint = await this.$api("/api/updReqPayForPoint_p2", {param:
              [ reqPayStatus, paidId, pgPayment, pPoint, actualPayAmount, payFlag, resCodeForVarify ]
            });
          }
          else
          {
            updReqPayForPoint = await this.$api("/api/updReqPayForPoint", {param:
              [ reqPayStatus, paidId, pgPayment, pPoint, actualPayAmount, rCode ]
            });
          }
          if(updReqPayForPoint.affectedRows<0)
          {
            this.$swal.fire("", "죄송합니다. 결제정보 처리 중 오류가 발생했습니다. 다시 진행을 해주십시오.", "warning")
            return false;
          }
          else
          {
            //예약정보 완료처리
            let updResveStat = []
            let balace = 0
            let firstPayment = this.reserveInfo.firstPayment
            let completeFlag = "Y"
            let partFlag = ""
            
            if(pFlag == "p1")                //일부포인트결제
            {
              // balace = (this.reserveInfo.cstPrice_new + this.reserveInfo.totSelOptPrc + this.tot_selAddPdtPrice) - this.pPoint
              // partFlag = "1"
              this.$swal.fire("", "결제프로세스에 문제(코드: CRT-001)가 발생했습니다. 당사 고객센타(16609-4602)로 연락을 주십시오.<br>불편을 드려 죄송합니다.", "error")
            }
            else if(pFlag == "p")                                                                                                              
            {
              balace = this.reserveInfo.balance
              partFlag = "2"
            }
            else
            {
              partFlag = "fu"
            }

            updResveStat = await this.$api("/api/updResveStat_pointOnly", {param: 
              ['PY', this.email, firstPayment, balace, partFlag, completeFlag, pPoint, rCode] 
            });
  
            if(updResveStat.affectedRows < 0)
            {
              this.$swal.fire("","예약처리 중 오류가 발생했습니다. 고객센터(1660-4602)로 연락을 주시면 감사하겠습니다.", "warning")
              return false;
            }
            else                                                                                                                                                                                                                                                                                            
            {
              let upd_UsePoint = await this.$api("/api/upd_UsePoint", {param: [ this.user.email, rCode ]});
              if(upd_UsePoint.affectedRows < 0)
              {
                this.$swal.fire("","죄송합니다. 포인트 처리에 문제가 발생했습니다(예약번호:"+ rCode +").<br>포인트문의: 1660-4602","warning")
                return false;
              }
              else
              {
                this.sendKakaoByPay("PY", rCode, pPoint)
              }
            }
          }
        }
      }
    },

    //PG를 통하지 않는 결제결과 카톡발송
    async sendKakaoByPay(param, orderId, pPoint) //PY or WP, SP/NB or notSP
    {
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        orderId, param, this.reserveInfo.conEmail
      ]});

      //알림톡 발송대상 확정
      this.handTel.push({aimCall: this.reserveInfo.reqTelNo})
      this.handTelCnt = this.handTel.length

      if(insKSendHistory.affectedRows > 0) 
      {
        let kakaoAlimData = {}
        let promises = []         //promise들의 배열
      
        let resPayAmount = 0
        if(this.payStyle === "p")
        {
          resPayAmount = pPoint
        }
        else
        { 
          resPayAmount = this.balance
        }
        let j = 0
        while(j < this.handTelCnt)
        {
          //let resPaxName = (this.reserveInfo.tripName == undefined || this.reserveInfo.tripName == "") ? this.reserveInfo.reqName : this.reserveInfo.tripName
          let resPaxCnt = this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1

          kakaoAlimData = 
          {
            //카카오 알림톡 발송(결제완료)
            clientName: this.reserveInfo.reqName,
            resName: this.productInfo.pdtNameKor,                             //예약상품명
            resPaxName: this.reserveInfo.tripName,
            resPaxCnt: resPaxCnt,
            dptDate: this.reserveInfo.reqDptDate,
            resPhone: this.reserveInfo.reqTelNo,
            resCode: orderId,
            resPayAmount: this.getCurrencyFormat(resPayAmount),
            resManager: this.productInfo.manName,
            alimFlag: param,                                                  //알림유형(결제완료)
            alimCall: this.handTel[j].aimCall,                                //알림톡발송대상
            pdtCode: this.productInfo.pdtCode                                //비회원예약 결제처리용 상품코드
          };

          let promise = this.$api("api/kakaoAlimSend2", {
            param: [{ kakaoAlimData: kakaoAlimData}]
          })

          promises.push(promise)
          j++
        }
          
        //반복문 이후 처리
        Promise.all(promises).then(() => {
          this.$swal.fire('', '결제완료 알림톡이 발송되었습니다', 'success').then(() => {
            this.$router.push({ path: "/" });
          })
        }).catch((error) => {
          console.log("Error calling api: ", error)
        })
      }
    },



    //토스페이먼츠PG결제
    async pgPay(pFlag){
      //console.log(pFlag)

      if(this.pointForPay > 0)
      {
        let payByPoint = await this.$api("/api/payByPoint", {param: [
          this.reserveInfo.reqEmail,this.paidPoint,'S',this.reqRsvCode,'사용대기', 'Y'
        ]});
        if(payByPoint.affectedRows < 0 || payByPoint.affectedRows == undefined)
        {
          this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다(코드: payByPoint)<br>포인트 복구문의: 1660-4602', 'error');
          return false;
        }
      }

      let veriSign = "N"
      let insVeriData = []

      //잔액결제청구처리
      let payAmount = 0
      let payPointAmt = (this.paidPoint == null || this.paidPoint == undefined) ? "0" : this.paidPoint
      
      if(pFlag === "p2")
      {
        payAmount = this.balance
        insVeriData = await this.$api("/api/proc_setVeriData_1", {param:
          [this.reserveInfo.reqReserveCode+"-2", payAmount, veriSign, pFlag, payPointAmt ]
        });
      }
      else
      {
        if(this.ctCode === "N")
        {
          payAmount = this.reserveInfo.nbusPriceTot
          insVeriData = await this.$api("/api/proc_setVeriData", {param:
            [this.reserveInfo.inqCode, payAmount, veriSign, payPointAmt ]
          });
        }
        else
        {
          payAmount = this.reserveInfo.cstPrice_new
          insVeriData = await this.$api("/api/proc_setVeriData", {param:
            [this.reserveInfo.reqReserveCode, payAmount, veriSign, payPointAmt ]
          });
        }
      }

      if(insVeriData.affectedRows < 0)
      {
        this.$swal.fire("","검증데이타가 생성되지 않았습니다. 재시도해주세요", "warning")
        return false;
      }

      //localStorage.setItem('total_amount',this.reserveInfo.cstPrice_new)

      // //결제수단 랜더링
      // paymentWidget.renderPaymentMethods('#payment-widget', this.eventInfo.payAmt);

      // ------  결제위젯 초기화 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
      this.paymentWidget = await loadPaymentWidget(this.clientKey, ANONYMOUS);
      // ------  결제 UI 렌더링 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
      this.paymentMethodWidget = this.paymentWidget.renderPaymentMethods("#payment-method", { value: payAmount }, { variantKey: "DEFAULT" });
      // ------  이용약관 UI 렌더링 ------
      // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
      //this.paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });
      this.showFlag = !this.showFlag      
    },

    async requestPayment(rNo, pFlag) {
      
      // 부분결제청구 처리
      let taxFreeAmount = 0
      let resCode = ""
      if(pFlag === "p2")
      {
        taxFreeAmount = this.balance
        resCode = rNo + "-2"
      }
      else
      {
        taxFreeAmount = this.reserveInfo.cstPrice_new
        resCode = rNo
      }

      //엔버스 결제금액
      if(this.ctCode === "N")
      {
        taxFreeAmount = this.reserveInfo.nbusPriceTot
      }
      else
      {
        taxFreeAmount = this.reserveInfo.cstPrice_new
      }

      try {
        if (this.paymentWidget) {
          // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
          // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
          // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
          // @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
          if(this.ctCode === "N")
          {
            await this.paymentWidget.requestPayment({
              orderId: resCode,
              orderName: rNo,
              //successUrl: 'http://localhost/success_pg',
              //failUrl: 'http://localhost/fail_pg',
              successUrl: 'https://ntabi.co.kr/success_pg',
              failUrl: 'https://ntabi.co.kr/fail_pg',
              customerEmail: this.reserveInfo.inqEmail,
              customerName: this.reserveInfo.inqName,
              taxFreeAmount: taxFreeAmount,
            });
          }
          else
          {
            await this.paymentWidget.requestPayment({
              orderId: resCode,
              orderName: this.productInfo.pdtNameKor,
              // successUrl: 'http://localhost/success_pg',
              // failUrl: 'http://localhost/fail_pg',
              successUrl: 'https://ntabi.co.kr/success_pg',
              failUrl: 'https://ntabi.co.kr/fail_pg',
              customerEmail: this.reserveInfo.conEmail,
              customerName: this.reserveInfo.reqName,
              taxFreeAmount: taxFreeAmount,
            });
          }
        }
      } catch (error) {
        // 에러 처리하기
        console.error(error);
      }
    },


    /** 토스페이 처리로직 */
    async tPay(param, value)
    {
      if(value === "T")
      {
        //포인트처리
        let payByPoint = await this.$api("/api/payByPoint", {param: [
          this.reserveInfo.reqEmail,this.paidPoint,'S',this.reqRsvCode,'사용대기', 'Y'
        ]});
        if(Object.keys(payByPoint)[0] === 'error') 
        {
          this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다(코드: payByPoint)<br>포인트 복구문의: 1660-4602', 'error');
          return false;
        }
        else
        {
          let tossData = {
            orderId: param,
            amount: this.reserveInfo.cstPrice_new,
            amountTaxFree: this.reserveInfo.cstPrice_new,
            //orderName: this.productInfo.pdtNameKor,
            productDesc: this.productInfo.pdtNameKor,
            autoExecute: true,
            // retUrl: "http://test.somen.co.kr:8088/success?amount="+this.reserveInfo.cstPrice_new,
            // retCancelUrl: "http://test.somen.co.kr:8088/fail",
            retUrl: "https://ntabi.co.kr/success?amount="+this.reserveInfo.cstPrice_new,
            retCancelUrl: "https://ntabi.co.kr/fail",
            resultCallback: "https://ntabi.co.kr/callback",
          }
          //console.log("tossData:",tossData)

          // connect with api on backend
          this.$api("api/tossPay/start",
          {
            param: tossData
          })
          .then((res) => {
            console.log(res);
            window.location.href = res.checkoutPage;
          })
          .catch((err) => {
            console.error(err);
          });
        }
      }
    },

    async freePay(rNo, pFlag)
    {
      // 부분결제청구 처리
      let taxFreeAmount = 0
      if(pFlag === "p2")
      {
        taxFreeAmount = this.balance
      }
      else
      {
        taxFreeAmount = this.reserveInfo.cstPrice_new
      }
            
      //포인트처리
      let payByPoint = await this.$api("/api/payByPoint", {param: [
        this.reserveInfo.reqEmail,this.paidPoint,'S',this.reqRsvCode,'사용대기', 'Y'
      ]});
      if(payByPoint.affectedRows < 0) 
      {
        this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다(코드: payByPoint)<br>포인트 복구문의: 1660-4602', 'error');
        return false;
      }
      else
      {
        //결제처리
        let payByUser = await this.$api("/api/payByUser", {param: [taxFreeAmount, this.user.email, '000', rNo]});
        
        if(payByUser.affectedRows > 0) 
        {
          let upd_UsePoint = await this.$api("/api/upd_UsePoint", {param: [ this.user.email, rNo ]});
          if(upd_UsePoint.affectedRows < 0 || upd_UsePoint.affectedRows == undefined )
          {
            this.$swal.fire("","죄송합니다. 결제처리에 오류가 발생했습니다(코드:upd_UsePoint).<br>문의처: 1660-4602","warning")
            return false        
          }
          else
          {
            this.getPayDetailInfo(rNo)
            this.$swal.fire('','전액 포인트 결제되었습니다. 감사합니다,', 'success');
            //location.reload(true);
          }
        }
        else 
        {
          this.$swal.fire('','결제처리 중 에러가 발생했습니다(코드: payByUser)<br>문의처(1660-4602)에 확인을 요청해주세요.', 'error')
          return false;
        }
      }
    },
    // //카톡발송 없이 결제상세만 체크
    // async getPayDetailInfo1(orderId)
    // {
    //   let resNo = orderId
    //   let payDetailInfo = await this.$api("/api/payDetailInfo", {param: [resNo]});
    //   if(payDetailInfo.length > 0)
    //   {
    //     this.payDetailInfo = payDetailInfo[0];
    //     console.log("payDetailInfo1:", this.payDetailInfo)
    //   }
    // },    
    async getPayDetailInfo(orderId)
    {
      let resNo = orderId
      let payDetailInfo = []
      if(this.ctCode === "N")
      {
        payDetailInfo = await this.$api("/api/payDetailInfo_nbus", {param: [resNo]});        
      }
      else
      {
        payDetailInfo = await this.$api("/api/payDetailInfo", {param: [resNo]});
      }

      if(payDetailInfo.length > 0)
      {
        this.payDetailInfo = payDetailInfo[0];
        //console.log("payDetailInfo:", this.payDetailInfo)
        //결제완료 카톡안내
        this.sendKakao("PY", resNo);
      }
    },

    toReview(rsvNo)
    {
      //엔버스
      if(this.ctCode === "N")
      {
        if(this.reserveInfo.inqStatus != "CO")
        {
          this.$swal.fire({
            html: '<small class="text-muted">리뷰작성기간이 아닙니다</small>',
            width: 'auto',
            height: 'auto',
            showConfirmButton: false
          })
          return false;
        }
        else
        {
          //this.$router.push({path: '/reviewCreate', query: {rsvNo: rsvNo, cCode: "N"}});

          let sDate = new Date(this.reserveInfo.rentStartDate)
          let tEndDate = sDate.setDate(sDate.getDate() + this.reserveInfo.rentTotDays)

          // 입력된 일자(yyyy-mm-dd)를 Date 객체로 변환
          let inputDate = new Date(tEndDate);

          // 오늘 날짜를 Date 객체로 생성.
          const today = new Date();

          // 여행종료일이 오늘이전=>리뷰작성
          if (inputDate < today)
          {
            this.$router.push({path: '/reviewCreate', query: {rsvNo: rsvNo, cCode: "N"}});
          }
          else
          {
            this.$swal.fire({
              position: 'top-end',
              // icon: 'warning',
              html: '<h6 class="text-danger">작성가능기간이 아닙니다.</h6>',
              width: 'auto',
              height:'auto',
              toast: true,
              showConfirmButton: false,
              timer: 1000
            })
            return false;
          }
        }
      }
      
      //일반예약
      else
      {
        if(this.reserveInfo.reqStatus != "CO")
        {
          this.$swal.fire({
            html: '<small class="text-muted">리뷰작성기간이 아닙니다</small>',
            width: 'auto',
            height: 'auto',
            showConfirmButton: false
          })
          return false;
        }
        else
        {
          let tEndDate = this.reserveInfo.reqRtnDate

          /** 작성일자기한 체크 */
          // 입력된 일자(yyyy-mm-dd)를 Date 객체로 변환
          let inputDate = new Date(tEndDate);

          // 오늘 날짜를 Date 객체로 생성.
          const today = new Date();

          // 여행종료일이 오늘이전=>리뷰작성
          if (inputDate < today)
          {
            this.$router.push({path: '/reviewCreate', query: {rsvNo: rsvNo, tEndDate:tEndDate, pCode: this.productId}});

            // // 입력된 일자에서 14일 후의 일자를 계산
            // let deadline = new Date(inputDate.getTime() + (30 * 24 * 60 * 60 * 1000));

            // // 현재 일자와 입력된 일자에서 14일 후의 일자를 비교
            // if (today > deadline) {
            //   this.$swal.fire({
            //     position: 'top-end',
            //     // icon: 'warning',
            //     html: '<h6 class="text-danger">작성기한이 지났습니다.</h6>',
            //     width: 'auto',
            //     height:'auto',
            //     toast: true,
            //     showConfirmButton: false,
            //     timer: 1000
            //   })
            //   return false;
            // }
            // else
            // {
            //   this.$router.push({path: '/reviewCreate', query: {rsvNo: rsvNo, tEndDate:tEndDate, pCode: this.productId}});
            // }
          }
          else
          {
            this.$swal.fire({
              position: 'top-end',
              // icon: 'warning',
              html: '<h6 class="text-danger">작성가능기간이 아닙니다.</h6>',
              width: 'auto',
              height:'auto',
              toast: true,
              showConfirmButton: false,
              timer: 1000
            })
            return false;
          }
        }
      }

    },

    //후기작성 가능여부체크(기존 후기존재여부)
    async checkReviewFlag()
    {
      let checkReviewFlag = await this.$api("/api/checkReviewFlag", {param: [this.user.email, this.reserveInfo.reqReserveCode]});
      //console.log("checkReviewFlag:",checkReviewFlag)
      if(checkReviewFlag.length > 0)
      {
        this.isReviewFlag = checkReviewFlag[0].nUseFlag
      }
      else
      {
        this.isReviewFlag = "N"
      }
      //console.log("isReviewFlag:", this.isReviewFlag)
    },

    // async getReqPayInfo(val)
    // {
    //   let rtnRPflag_tmp = await this.$api("/api/getReqPayInfo", {param: [val]});

    //   if(rtnRPflag_tmp.length > 0)
    //   {
    //     this.rtnRPflag_tmp = rtnRPflag_tmp[0]
    //     //결제여부체크
    //     if(rtnRPflag_tmp[0].paidId != undefined)
    //     {
    //       this.paidFlag = "Y" //결제자 아이디가 있으면 결제로 판정
    //     }
    //   }

    //   //포인트결제내역
    //   let payPointInfo = await this.$api("/api/payPointInfo", {param: [val]});
      
    //     //결제포인트액수
    //     if(payPointInfo[0].payPoint > 0)
    //     {
    //       this.payPointAmt = payPointInfo[0].payPoint
    //     }
    //     else
    //     {
    //       this.payPointAmt = 0
    //     }

    //   //console.log("payPointAmt:", this.payPointAmt)

    //   this.rtnRPflag = (rtnRPflag_tmp.length > 0) ? "Y" : "N";
    //   //console.log("rtnRPflag:",this.rtnRPflag);
    // },

    //부분결제기능 도입 후, 결제판정로직 변경(2024-05)
    async getReqPayInfo(val)
    {
      let resCode = ""
      if(this.reserveInfo.partFlag === "1")
      {
        resCode = val+"-1"
      }
      else if(this.reserveInfo.partFlag === "2")
      {
        resCode = val+"-1"
      }
      else
      {
        resCode = val
      }

      let rtnRPflag_tmp = await this.$api("/api/getReqPayInfo", {param: [resCode]});

      //console.log("rtnRPflag_tmp:????", rtnRPflag_tmp)
      
      //포인트 외 쿠폰결제도 추가(20250325)
      if(rtnRPflag_tmp.length > 0)
      {
        //this.rtnRPflag = "Y"    //결제여부

        if(rtnRPflag_tmp[0].reqPayStatus === "CO")      //확정
        {
          //this.payStyle = "f"
          this.paidFlag = "Y"
        }        
        else if(rtnRPflag_tmp[0].reqPayStatus === "PY" && (rtnRPflag_tmp[0].payFlag === "fu" || rtnRPflag_tmp[0].payFlag === "p2" || rtnRPflag_tmp[0].payFlag == null))
        {
          this.payStyle = "f"
          this.paidFlag = "Y"
        }
        else if(rtnRPflag_tmp[0].reqPayStatus === "PY" && rtnRPflag_tmp[0].payFlag === "p1")
        {
          this.payStyle = "p"
          this.paidFlag = "Y"
          if(rtnRPflag_tmp[0].payPointAmt < 0 || rtnRPflag_tmp[0].payPointAmt == undefined)
          {
            rtnRPflag_tmp[0].payPointAmt = 0
          }
          if(rtnRPflag_tmp[0].payCouponAmt < 0 || rtnRPflag_tmp[0].payCouponAmt == undefined)
          {
            rtnRPflag_tmp[0].payCouponAmt = 0
          }

          this.balance = (rtnRPflag_tmp[0].reqTotPayAmt)*1 - (rtnRPflag_tmp[0].actualPayAmount)*1 - (rtnRPflag_tmp[0].payPointAmt*1) - (rtnRPflag_tmp[0].payCouponAmt*1)
        }
        else if(rtnRPflag_tmp[0].reqPayStatus === "RP" && rtnRPflag_tmp[0].payFlag == null)
        {
          this.paidFlag = "Y"
          this.rtnRPflag = "Y"      //과거 로직에 사용하던 변수로 미사용일 가능성이 높으나 일단 변수값은 생성함
        }
        else if(rtnRPflag_tmp[0].reqPayStatus === "RP" && rtnRPflag_tmp[0].payFlag === 'p1')        //부분결제 시, 잔여대금
        {
          this.paidFlag = "Y"           //부분결제
          this.balance = (rtnRPflag_tmp[0].reqTotPayAmt)*1 - (rtnRPflag_tmp[0].actualPayAmount)*1
        }
        else if(rtnRPflag_tmp[0].reqPayStatus === "RP" && (rtnRPflag_tmp[0].payFlag === 'p2' || rtnRPflag_tmp[0].payFlag === 'fu'))
        {
          if(rtnRPflag_tmp[0].actualPayAmount != rtnRPflag_tmp[0].reqTotPayAmt)
          {
            this.$swal.fire("","최종결제금액과 상품대금이 일치하지 않습니다. 실제결제된 금액을 확인해주세요", "question")
            return false
          }
          else
          {
            this.paidFlag = "Y"       //전액결제
            this.rtnRPflag = "Y"
          }
        }

        //관리자화면의 펑션에서는 this.userPayInfo로 변경됨
        //this.userPayInfo = rtnRPflag_tmp[0]
        this.rtnRPflag_tmp = rtnRPflag_tmp[0]

        //console.log("balance:", this.balance, "payStyle:", this.payStyle)

        //포인트결제내역
        let payPointInfo = await this.$api("/api/payPointInfo", {param: [val]});
        
        //결제포인트액수
        if(payPointInfo[0].payPoint > 0)
        {
          this.payPointAmt = payPointInfo[0].payPoint
        }
        else
        {
          this.payPointAmt = 0
        }
      }
      else
      {
        this.rtnRPflag = "N"
        this.paidFlag = "N"
      }

      if(this.paidFlag != "N")
      {
        // 결제히스토리용(p2내역추가 20250307)
        this.userPayInfo = rtnRPflag_tmp[0]
        this.p1PayAmt = (this.userPayInfo.p1PayAmt == undefined) ? 0 :          this.userPayInfo.p1PayAmt
        this.p2PayAmt = (this.userPayInfo.p2PayAmt == undefined) ? 0 :          this.userPayInfo.p2PayAmt
        this.p1PayDate = (this.userPayInfo.p1PayDate == undefined) ? "" :       this.getKorDateFormat(this.userPayInfo.p1PayDate)
        this.p2PayDate = (this.userPayInfo.p2PayDate == undefined) ? "" :       this.getKorDateFormat(this.userPayInfo.p2PayDate)
        this.payPointAmt = (this.userPayInfo.payPointAmt == undefined) ? 0 :    this.userPayInfo.payPointAmt
        this.payPointDate = (this.userPayInfo.payPointDate == undefined) ? "" : this.getKorDateFormat(this.userPayInfo.payPointDate)
        this.payPointAmt2 = (this.userPayInfo.payPointAmt2 == undefined) ? 0 :    this.userPayInfo.payPointAmt2
        this.payPointDate2 = (this.userPayInfo.payPointDate2 == undefined) ? "" : this.getKorDateFormat(this.userPayInfo.payPointDate2)
        this.payCouponAmt = (this.userPayInfo.payCouponAmt == undefined) ? 0 :    this.userPayInfo.payCouponAmt
        this.payCouponDate = (this.userPayInfo.payCouponDate == undefined) ? "" : this.getKorDateFormat(this.userPayInfo.payCouponDate)
        this.payCouponAmt2 = (this.userPayInfo.payCouponAmt2 == undefined) ? 0 :    this.userPayInfo.payCouponAmt2
        this.payCouponDate2 = (this.userPayInfo.payCouponDate2 == undefined) ? "" : this.getKorDateFormat(this.userPayInfo.payCouponDate2)

        this.totalPay = this.p1PayAmt*1 + this.p2PayAmt*1 + this.payPointAmt*1 + this.payCouponAmt*1
      }

      let fPayFlag = ""       //전액Y, 부분N
      if(this.userPayInfo.reqTotPayAmt == this.p1PayAmt + this.payPointAmt + this.payCouponAmt)
      {
        fPayFlag = "Y"         //전액결제
      }
      else
      {
        fPayFlag = "N"        //부분결제
      }

      this.fPayFlag = fPayFlag
    },


    /** 두날짜 사이의 날짜추출함수 */
    getDatesStartToLast(startDate, lastDate) {
      //var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
      //if(!(regex.test(startDate) && regex.test(lastDate))) return "Not Date Format";
      if(!(startDate && lastDate)) return "Not Date Format";

      var result = [];
      var curDate = new Date(startDate);
      while(curDate <= new Date(lastDate)) {
        result.push(curDate.toISOString().split("T")[0]);
        curDate.setDate(curDate.getDate() + 1);
      }
      return result;
    },

    async getReqProcLevel() {
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }
      //console.log("rProc:",this.rProc);
    },

    //가용포인트 확인
    async getAvailPoint()
    {
      this.availPoint = await getAvailPoint(this.$api, this.user.email)
    },

    //적용가능한 프로모션정보
    async getPdtPromotion_apply() {
      let pdtPromoInfo_apply = await this.$api("/api/pdtPromo_apply", {param: [this.productId, this.reserveInfo.reqDptDate]});
      
      if(pdtPromoInfo_apply.length > 0) {
        this.pdtPromoInfo_apply = pdtPromoInfo_apply[0];
        if(this.pdtPromoInfo_apply.promoStart != undefined){
          this.pdtPromoInfo_apply.promoStart = this.getDateFormat(this.pdtPromoInfo_apply.promoStart);
        }
        if(this.pdtPromoInfo_apply.promoEnd != undefined) {
          this.pdtPromoInfo_apply.promoEnd = this.getDateFormat(this.pdtPromoInfo_apply.promoEnd);
        }
        this.aPromoFlag = "Y"
      }
      //console.log("pdtPromoInfo_apply:",this.pdtPromoInfo_apply,"/", pdtPromoInfo_apply.length);
    },

    //기존상품 교통정보
    async getProductTransInfoExited() {
      let pdtTransInfo1 = await this.$api("/api/pdtTransInfo_existed", {param: [this.productId]});
      if(pdtTransInfo1.length > 0) {
        this.pdtTransInfo1 = pdtTransInfo1[0];
      }
      //console.log("pdtTransInfo1:",this.pdtTransInfo1);
    },


    //상품프로모션
    async getProductPromotion() {
      let productPromotion = await this.$api("/api/productPromotion", {param: [this.productId]});
      //console.log("pPromo:",productPromotion);

      if(productPromotion.length > 0) {
        this.productPromotion = productPromotion[0]
      }
      else{
        return false;
      }
      //console.log("productPromotion: ",this.productPromotion.promoName);
    },
    //상품가격
    async getProductPrice() {
      let productPrice = await this.$api("/api/productPrice", {param: [this.pdtCode]});
      this.productPrice = productPrice;
      //console.log("productPrice:",this.productPrice);

      if(this.productInfo.etcOptionFlag === "Y") {
        this.getEtcOptionInfo();
      }
    },

    //기타옵션획득
    async getEtcOptionInfo() {
      let etcOptionInfo = []
      etcOptionInfo = await this.$api("/api/etcOptionInfo1", {param: [this.productId]});

      if(etcOptionInfo.length > 0)
      {
        this.etcOptionInfo = etcOptionInfo
      }
      //console.log("etcOptionInfo:",this.etcOptionInfo);
    },
    async getPromotionList() {
      let promotionList = await this.$api("/api/promotionList", {param: [this.productId]});
      this.promoList = promotionList;
      // console.log("promoList:",this.promoList, "pDetail:",this.productDetail.deliCategory);
    },
    goToMypage() {
      //this.$router.push({path: '/reserveInfoList_client'});
      this.$router.push({path: '/myPage'});
    },

    goToProductDetail(pdtCode){
      let salesRoute = pdtCode.charAt(0)
      this.$router.push({path: '/detail', query:{productId: pdtCode, salesRoute: salesRoute}});
    },

    //1:1문의하기
    goToCustomerAsk(){
      //let salesRoute = pdtCode.charAt(0)
      this.$router.push({path: '/customerAsk', query:{}});
    },

    /** 두배열간 중복제거 */
    removeDubofArrays(arr1, arr2)
    {
      return arr1.concat(arr2).filter(item => !arr1.includes(item) || !arr2.includes(item));
    },

    //취소요청여부체크
    async getCheckReqCxl(rCode){
      //기존요청이 있는지 체크
      let res_checkReqCxl = await this.$api("/api/checkReqCxl", {param: [rCode]})
      if(res_checkReqCxl.length > 0)
      {
        this.res_checkReqCxl = res_checkReqCxl[0]
      }
    },
    
    //취소요청처리
    async reqCxl(rCode, pCode){

      //상품별 취소안내사항
      let res_supCxl = await this.$api("/api/supCxl", {param: pCode})

      let supCxl = ""
      if(res_supCxl.length > 0)
      {
        supCxl = res_supCxl[0]
      }

      this.$swal.fire({
        html: "예약취소를 요청하시겠습니까?<br><br><p class='text-start swal-popup'>"+ supCxl.supCancellation.split('\n').join('<br>') +"</p>",
        showCancelButton: true,
        confirmButtonText: '동의하고 요청합니다',
        cancelButtonText: '요청하지 않습니다',
      }).then(async (result) => {
        if(result.isConfirmed) {
          let conFlag = "Y"   //취소약관동의
          let insReqCxlReserve = await this.$api("/api/insReqCxlReserve", {param: [
            rCode, this.user.email, conFlag
          ]});
          if(insReqCxlReserve.affectedRows < 0 || insReqCxlReserve.affectedRows === undefined) 
          {
            this.$swal.fire('','요청처리 중 오류가 발생했습니다.<br>온라인 문의 또는 연락(1660-4602)을 주시면 신속하게 처리해드리겠습니다.', 'warning')
            return false;
          }
          else
          {
            this.sendKakao("RCX", rCode)
          }
        }
      })
    },

    //엔버스예약 취소요청
    async reqCxl_nbus(nCode){
      //상품별 취소안내사항
      let res_supCxl = await this.$api("/api/supCxl_nbus", {param: nCode})

      let supCxl = ""
      if(res_supCxl.length > 0)
      {
        supCxl = res_supCxl[0]
      }
      this.$swal.fire({
        html: "예약취소를 요청하시겠습니까?<br><br><p class='text-start swal-popup'>"+ supCxl.supCancellation.split('\n').join('<br>') +"</p>",
        showCancelButton: true,
        confirmButtonText: '동의하고 요청합니다',
        cancelButtonText: '요청하지 않습니다',
        width: 1000,
      }).then(async (result) => {
        if(result.isConfirmed) {
          let conFlag = "Y"   //취소약관동의
          let insReqCxlReserve = await this.$api("/api/insReqCxlReserve", {param: [
            nCode, this.user.email,conFlag
          ]});
          if(Object.keys(insReqCxlReserve)[0] === 'error') 
          {
            this.$swal.fire('','요청처리 중 오류가 발생했습니다.<br>온라인 문의 또는 연락(1660-4602)을 주시면 신속하게 처리해드리겠습니다.', 'warning')
            return false;
          }
          else
          {
            this.sendKakao("RCX", nCode)
          }
        }
      })
    },


    //카카오발송대상자 정리
    async getHandlerInfo()
    {
      let handTel = await handlerInfo(this.$api);
      if(handTel.length > 0)
      {
        this.handTel = handTel
      }
      //console.log("handTel: ", this.handTel)
    },

    /** 카카오알림 발송 */
    async sendKakao(param, resNo)
    {
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        //this.orderId, param, this.userInfo
        resNo, param, this.user.email
      ]});

      //알림톡 발송대상 확정
      if(this.ctCode === "N")      //엔버스
      {
        this.handTel.push({aimCall: this.reserveInfo.inqTel})        
      }
      else                            //일반여행상품
      {
        this.handTel.push({aimCall: this.reserveInfo.reqTelNo})
      }
      this.handTelCnt = this.handTel.length

      //let kakaoAlimData = {}
      if(insKSendHistory.affectedRows > 0) {
        let promises = []

        if(param == "PY") 
        {
          for(let i=0; i<this.handTelCnt; i++)
          {
            let kakaoAlimData = 
            {
              //카카오 알림톡 발송(결제완료)
              clientName: this.payDetailInfo.clientName,
              resName: this.payDetailInfo.resName,
              resPaxName: this.payDetailInfo.resPaxName,
              resPaxCnt: this.payDetailInfo.resPaxCnt,
              dptDate: this.payDetailInfo.dptDate,
              resPhone: this.payDetailInfo.resPhone,
              resCode: resNo,
              resPayAmount: this.getCurrencyFormat(this.payDetailInfo.resPayAmount),
              //resPayAmount: this.getCurrencyFormat(this.rtnRPflag_tmp.actualPayAmount)+"P",
              resManager: this.payDetailInfo.resManager,
              alimFlag: param,                                             //알림유형(결제완료)
              clientCall: this.handTel[i].aimCall,                           //알림톡발송대상
            };

            let promise = this.$api("api/kakaoAlimSend2", {
              param: [ { kakaoAlimData: kakaoAlimData} ]
            })

            promises.push(promise)            
          }
          //반복문 이후 처리
          Promise.all(promises).then(() => {
            //console.log(results)
            this.$swal.fire('', '결제완료 알림톡이 발송되었습니다', 'success').then(() => {
              location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error)
          })

          // this.$api("api/kakaoAlimSend2", {
          //   param: [
          //     { kakaoAlimData: kakaoAlimData},
          //   ]
          // }).then((res) => {console.log(res)});
          // this.$swal.fire('', '결제완료 알림톡이 발송되었습니다', 'success');
          // //this.$router.push({path: '/'});
          // location.reload(true);
          // //history.back();
        }

        //취소요청접수 알림톡
        else if(param == "RCX") 
        {
          let resPaxCnt = 0

          if(this.ctCode === "N")
          {
            resPaxCnt = this.reserveInfo.personCnt*1
          }
          else
          {
            resPaxCnt = this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1
          }

          //취소요청 카카오 알림톡
          let kakaoAlimData = {}

          //엔버스
          if(this.ctCode === "N")
          {
            for(let i=0; i<this.handTelCnt; i++)
            {
              kakaoAlimData = 
              {
                inqName: this.reserveInfo.inqName,
                inqCode: resNo,
                rentStartDate: this.getDateFormat(this.reserveInfo.rentStartDate),
                inqTel: this.reserveInfo.inqTel,
                alimFlag: "RCX",                                             //알림유형(RCX:취소요청)
                clientCall: this.handTel[i].aimCall,                           //알림톡발송대상
                memFlag: "Y",
              };
              //console.log("kakaoAlimData:", kakaoAlimData)

              let promise_n = this.$api("api/kakaoAlimSend7", {
                param: [ { kakaoAlimData: kakaoAlimData} ]
              })

              promises.push(promise_n)
            }
          }
          //일반예약
          else
          {
            for(let i=0; i<this.handTelCnt; i++)
            {
              kakaoAlimData = 
              {
                clientName: this.reserveInfo.reqName,
                resName: this.productInfo.pdtNameKor,
                resPaxName: this.reserveInfo.reqName,
                resPaxCnt: resPaxCnt,
                dptDate: this.reserveInfo.reqDptDate,
                resPhone: this.reserveInfo.reqTelNo,
                //resCode: this.reserveInfo.reqReserveCode,
                resCode: resNo,
                resManager: this.productInfo.handlerNameKor,
                alimFlag: param,                                               //알림유형(취소요청)
                clientCall: this.handTel[i].aimCall,                           //알림톡발송대상
              };
              let promise = this.$api("api/kakaoAlimSend2", {
                param: [ { kakaoAlimData: kakaoAlimData} ]
              })

              promises.push(promise)
            }
          }

          //반복문 이후 처리
          Promise.all(promises).then(() => {
            //console.log("result:", results)
            this.$swal.fire('', '취소요청 알림톡이 발송되었습니다', 'success').then(() => {
              //location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error.response ? error.response.data : error);
          })
        }        
      }
    },
  }
}
</script>

<style>
@media (max-width:992px){
  #reserdetail{
    margin-top:3rem
  }
}

#reserdetail .m-title{
  font-weight: 600;
  color:#000;
  margin-bottom:5rem
}

@media (max-width:991px){
  #reserdetail .m-title{
    margin:1rem 0 3rem
  }
}



/* -------------------------------------------------
  # 상품안내
--------------------------------------------------- */
#reserdetail .r-name img{
  height:22rem;
  object-fit: cover;
}
#reserdetail .r-name .code{
  font-size:.8rem;
  color:#777;
  font-weight: 300;
}
#reserdetail .r-name .name{
  align-self:center;
  font-size:1.3rem;
  font-weight: 600;
  color:#000;
  padding-left:30px
}
#reserdetail .r-name .nnum{
  font-size:1rem;
  font-weight: 300;
}
#reserdetail .r-name .nadd{
  margin-top:20px;
  font-size:1.1rem;
  font-weight: 300;
  color:#111;
  border:1px solid #333;
  padding:.5rem 0;
}
#reserdetail .r-name .nadd:hover{
  color:#000;
  font-weight: 600;
}


@media (max-width:991px){
  #reserdetail .r-name img{
    height:15rem;
  }
  #reserdetail .r-name .code{
    font-size:.7rem;
    margin-bottom:.5rem
  }
  #reserdetail .r-name .name{
    padding-left:5px;
    font-size:1.2rem;
  }
  #reserdetail .r-name .nnum{
    font-size:.9rem;
  }
  #reserdetail .r-name .nadd{
    margin:5px 0;
    padding:.7rem 0
  }
}




/* -------------------------------------------------
  # 내용 공통부분
--------------------------------------------------- */
#reserdetail .pc{
  display: block;
}
#reserdetail .mobile{
  display: none;
}

#reserdetail .title{
  border-bottom: 2px solid #000;
}
#reserdetail .title p{
  font-size:1.4rem;
  color:#000;
}
#reserdetail .table{
  vertical-align: middle;
}
#reserdetail .table tr{
  font-size:.9rem;
  height:4rem;
}
#reserdetail .table td{
  padding-top:1rem;
  padding-left:1.5rem
}
#reserdetail .table .ta-name{
  background-color:#f8f8f8;
  color:#000;
  font-weight: normal;
  text-align: center;
}
@media (max-width:991px){
  #reserdetail .pc{
    display: none;
  }
  #reserdetail .mobile{
    display: block;
  }
  #reserdetail .title p{
    font-size:1.2rem;
  }
  #reserdetail .table tr{
    font-size:.85rem;
    height:3rem;
  }
  #reserdetail .table td{
    text-align: center;
  }
}



/* -------------------------------------------------
  # 여행 상품 정보
--------------------------------------------------- */
#reserdetail .traval{
  margin:4rem 0
}
@media (max-width:991px){
  #reserdetail .traval{
    margin:2rem 0
  }
}


/* -------------------------------------------------
  # 예약자 정보
--------------------------------------------------- */
#reserdetail .tourist{
  margin:4rem 0
}


/* -------------------------------------------------
  # 대표 여행자 정보
--------------------------------------------------- */
#reserdetail .tourist{
  margin:4rem 0
}
@media (max-width:991px){
  #reserdetail .tourist{
    margin:2rem 0
  }
}


/* -------------------------------------------------
  # 결제 정보
--------------------------------------------------- */
#reserdetail .payment{
  margin: 4rem 0
}
#reserdetail .payment .total{
  color:#f00;
  font-weight:600;
  font-size:1.7rem
} 
#reserdetail .payment .addpay{
  font-size:.9rem;
  color:#777;
  text-align: right;
}
#reserdetail .payment .addpay p{
  margin-bottom:.5rem
}
@media (max-width:991px){
  #reserdetail .payment{
    margin: 2rem 0
  }
  #reserdetail .payment .total{
    font-size:1.2rem;
    padding-right:0;
    text-align: right;
  } 
  #reserdetail .payment .addpay p{
    font-size:.8rem;
  }
}

/* --------
  결제하기
-------- */
.ht-pay{
  padding:.5rem 3rem
}
.ht-ex P{
  margin-bottom:0;
  font-size:.9rem
}
.ht-ex .ht-big{
  font-size:1.1rem;
  padding:.5rem 0
}
/* 모바일 장치를 위한 스타일 */
@media (max-width: 768px) {
  .ht-ex p, .ht-ex b {
    /* 모바일에서의 텍스트 크기 */
    font-size: .7rem; /* 모바일에 적합한 크기로 조정 */
  }
  .ht-big {
    /* 모바일에서 큰 텍스트의 크기 */
    font-size: .9rem /* 모바일에 적합한 크기로 조정 */
  }
  .mobile-new-line {
    display: block; /* 모바일 화면에서는 블록으로 설정하여 새로운 줄에서 시작 */
  }
}

/* -------------------------------------------------
  # 마이페이지 버튼
--------------------------------------------------- */
#reserdetail .go,
#reserdetail .cxl{
  --bs-btn-padding-y: 0;
  margin:5rem 0;
  border-radius: 0;
  padding:5px 5px 5px 5px;
  color:#fff;
  width: 100%;
  font-size: 1.2rem;
}
#reserdetail .go{
  background-color: #000;
  /* padding:20px 5px */
}
#reserdetail .cxl{
  background-color: #9e0000;
  /* padding:20px 5px */
}
#reserdetail .go:hover{
  background-color: #333;
}
#reserdetail .cxl:hover{
  background-color: #b90000;
}

/* @media (max-width:991px){
  #reserdetail .go,
  #reserdetail .cxl {
    margin: 1rem 0;
  }
} */

@media (max-width:991px){
  #reserdetail .go{
    margin:1rem 0
  }
  #reserdetail .cxl{
    padding-top: 0px;
  }
}

/* 취송요청 확인팝업 */
.swal-popup {
  font-size:0.9rem;
  overflow: hidden;
  word-wrap: break-word;
}

/** 모바일화면에서 개행처리 */
@media screen and (max-width: 768px) {
  /* 모바일 화면에서 적용할 스타일 */
  .trans-info small {
    display: block;
    /* margin-bottom: 10px; */
  }
}
</style>
