<template>
  <div id="renewal">
    <div class="renew_section">
    <img class="renew_main" src="../assets/img/renewal/renewmain.jpg" style="width:100%;">
    <img class="renew_main2" src="../assets/img/renewal/renewalmain_mobile.jpg" style="width:100%;">
    <img class="m_p" src="../assets/img/renewal/m_p.png">
    <img class="m_b" src="../assets/img/renewal/m_b.png">
    <img class="m_a" src="../assets/img/renewal/m_a.png">
    <img class="m_c" src="../assets/img/renewal/m_c.png">
    <img class="m_y" src="../assets/img/renewal/m_y.png">
    <img class="m_w" src="../assets/img/renewal/m_w.png">
    </div><!--모바일 마진 2rem pc 마진 -56px-->

    <div class="re_button"   style="background-color:#98ef00;">
        <button class="new_button" @click="scrollToSection('section1')">신규 <br class="rn_br"> 가입 혜택</button>
        <button class="new_button" @click="scrollToSection('section2')" style="border: 3px solid #619900; border-top:0; border-bottom:0;">멤버십 <br class="rn_br"> 가입 혜택</button>
        <button class="new_button" @click="scrollToSection('section3')" >시크릿 <br class="rn_br"> 쿠폰</button>
    </div>
    <div style="width: 100%; background-color:#121212; height:7rem;"></div>
    <div class="re_section1_wrap section section1"  ref="section1">
      <img class="m_mp" src="../assets/img/renewal/m_mp.png">
      <img class="m_ma" src="../assets/img/renewal/m_ma.png">
      <img class="m_mc" src="../assets/img/renewal/m_mc.png">
      <div class="re_section1" style="background-color:#121212;">
      <img class="new_img_bg" src="../assets/img/renewal/re_event1.png" alt="">
      <img class="new_img_bg_mo section1" src="../assets/img/renewal/re_event1_mo.png" alt="">
      <img class="new_img_bg_overay" src="../assets/img/renewal/renew_card.png" alt="">
    </div>
  </div>
  <div class="mo_del" style="width: 100%; background-color:#121212; height:3rem;"></div>
    <div style="display:block; background:#121212; width:100%; height:200px; text-align:center;" >
      <a class="new_sec_1_a" href="/membership">포인트 받기</a>
    </div>
    <div class="mo_del" style="width: 100%; background-color:#121212; height:7rem;"></div>
    <div class="re_section2_wrap section section2"  ref="section2">
      <div class="re_section2" style="background-color:#121212;">
        <img class="m_my" src="../assets/img/renewal/m_my.png">
      <img class="m_mb" src="../assets/img/renewal/m_mb.png">
      <img class="m_mw" src="../assets/img/renewal/m_mw.png">
      <img class="new_img_bg" src="../assets/img/renewal/re_event2.png" alt="">
      <img class="new_img_bg_mo section2" src="../assets/img/renewal/re_event2_mo.png" alt="">
      <img class="new_img_bg_overay" src="../assets/img/renewal/renew_card2.png" alt="">
    </div>
  </div>
  <div class="mo_del" style="width: 100%; background-color:#121212; height:3rem;"></div>
  <div style="display:block; background:#121212; width:100%; height:200px; text-align:center;" >
      <a class="new_sec_1_a" href="/membershipPage">멤버십 가입</a>
    </div>
    <div class="mo_del" style="width: 100%; background-color:#121212; height:7rem;"></div>
    
    <div class="re_section3_wrap section section3"  ref="section3">
      <div class="back_line_content_wrap1" style="background-color: #619900;"  >
    <div class="back_line_content1">
        <img class="back_line1" src="../assets/img/renewal/back_line1.png" alt="">
        <img class="back_line1" src="../assets/img/renewal/back_line1.png" alt="">
    </div>
</div>
      <div class="re_section3" style="background-color:#121212;">
      <img class="new_img_bg" src="../assets/img/renewal/re_event3.png" alt="">
      <img class="new_img_bg_mo section3" src="../assets/img/renewal/re_event3_mo.png" alt="">
      <img class="new_img_bg_overay1" src="../assets/img/renewal/renew_card3.png" alt="">
      
      
<div class="back_line_content_wrap" style="background-color: #619900;">
    <div class="back_line_content">
        <img class="back_line" src="../assets/img/renewal/back_line.png" alt="">
        <img class="back_line" src="../assets/img/renewal/back_line.png" alt="">
    </div>
</div>
      
      
    </div>
  </div>
  <div style="width: 100%; background-color:#121212; height:3rem;"></div>
  <div style="display:block; background:#121212; width:100%; height:200px; text-align:center;" >
      <a class="new_sec_1_a1" href="/membership">시크릿 쿠폰 받기</a>
    </div>


    <div class="mo_del" style="width: 100%; background-color:#121212; height:3rem;"></div>
    <div class="mo_del" style="width: 100%; background-color:#121212; height:3rem;"></div>
    <div class="mo_del" style="width: 100%; background-color:#121212; height:3rem;"></div>

    <div class="re_section4_wrap">
      <div class="re_section4" style="background-color:#121212;">
      <img class="live_title" src="../assets/img/renewal/live_title.png" alt="">
      <img class="live_body"  src="../assets/img/renewal/live_body.png" alt="">
      <img class="live_body_m"  src="../assets/img/renewal/live_body_m.png" alt="">
    </div>
  </div>
  
  <div style="display:block; background:#121212; width:100%; height:200px; text-align:center;" >
      <a class="new_sec_1_a2" href="https://naver.me/54L1IVo0">라이브 시청하기</a>
    </div>
    
    
  </div>
</template>

<script>

export default {
  // DB에서 가져온 데이타
  data() {
    return {
      isFixed: false,
    };
  },
  created() {
    this.bSeq = this.$route.query.bSeq;
  },
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
  },

  methods: {
    scrollToSection(section) {
      const element = this.$refs[section];
      if (element) {
        const topPos = element.getBoundingClientRect().top + window.scrollY; // 스크롤 위치 계산
        window.scrollTo({
          top: topPos,
          behavior: 'smooth' // 스무스 스크롤
        });
      }
    }
  },
  
}
</script>
<!---->

<style>
@font-face {
    font-family: 'Paperlogy-8ExtraBold';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2408-3@1.0/Paperlogy-8ExtraBold.woff2') format('woff2');
    font-weight: 800;
    font-style: normal;
}
/*----------------------------------------left-------------------------------*/
@keyframes m_b {
    0% { left: calc(5%); top: calc(50%); rotate: 10deg;}
    50% { left: calc(5%); top: calc(45%); rotate: 13deg;}
    100% { left: calc(5%); top: calc(50%); rotate: 10deg;}
}
@keyframes m_p {
    0% { left: calc(25%); top: calc(15%); }
    50% { left: calc(25%); top: calc(10%); }
    100% { left: calc(25%); top: calc(15%);}
}
@keyframes m_a {
    0% { left: calc(20%); top: calc(80%); }
    50% { left: calc(20%); top: calc(85%); }
    100% { left: calc(20%); top: calc(80%);}
}
/*----------------------------------------left-end-------------------------------*/

/*----------------------------------------right-------------------------------*/
@keyframes m_c {
    0% { right: calc(5%); top: calc(50%); rotate: 10deg;}
    50% { right: calc(5%); top: calc(45%); rotate: 13deg;}
    100% { right: calc(5%); top: calc(50%); rotate: 10deg;}
}
@keyframes m_y {
    0% { right: calc(20%); top: calc(15%); }
    50% { right: calc(20%); top: calc(10%); }
    100% { right: calc(20%); top: calc(15%);}
}
@keyframes m_w {
    0% { right: calc(16%); top: calc(80%); }
    50% { right: calc(16%); top: calc(75%); }
    100% { right: calc(16%); top: calc(80%);}
}
/*----------------------------------------right-end------------------------------*/

@keyframes card1 {
    0% { left: calc(50%); rotate: 0deg;}
    50% { left: calc(50%); rotate: 5deg; }
    100% { left: calc(50%); rotate: 00deg;}
}
@keyframes card2 {
    0% { left: calc(50%); scale: 100%;}
    50% { left: calc(50%); scale: 110%; }
    100% { left: calc(50%); scale: 100%;}
}
/*-----------------------------------------bg_flying_object---------------------------------*/
@keyframes m_mp {
    0% { left: calc(5%); top: calc(25%); }
    50% { left: calc(5%); top: calc(20%); }
    100% { left: calc(5%); top: calc(25%);}
}
@keyframes m_ma {
    0% { right: calc(15%); top: calc(70%);  rotate: 10deg;}
    50% { right: calc(15%); top: calc(75%); rotate: 20deg; }
    100% { right: calc(15%); top: calc(70%); rotate: 10deg;}
}
@keyframes m_mw {
    0% { left: calc(15%); top: calc(60%); }
    50% { left: calc(15%); top: calc(65%); }
    100% { left: calc(15%); top: calc(60%);}
}
@keyframes m_mb {
    0% { right: calc(5%); top: calc(25%); rotate: 15deg;}
    50% { right: calc(5%); top: calc(30%); rotate: 8deg;}
    100% { right: calc(5%); top: calc(25%); rotate: 15deg;}
}
@keyframes m_my {
    0% { left: calc(5%); top: calc(5%); rotate: 0deg; }
    50% { left: calc(5%); top: calc(1%); rotate: 10deg; }
    100% { left: calc(5%); top: calc(5%); rotate: 0deg;}
}
@keyframes m_mc {
    0% { right: calc(5%); top: calc(15%); rotate: 0deg;}
    50% { right: calc(5%); top: calc(10%); rotate: 0deg;}
    100% { right: calc(5%); top: calc(15%);rotate: 0deg;}
}
.m_mp {
    position: absolute;
    width: 16%; /* 이미지 크기 설정 */
    animation: m_mp 7.8s infinite;
    z-index: 1;
}
.m_ma {
    position: absolute;
    width: 17%; /* 이미지 크기 설정 */
    animation: m_ma 7.8s infinite;
}
.m_mb {
    position: absolute;
    width: 16%; /* 이미지 크기 설정 */
    animation: m_mb 7s infinite;
}
.m_my {
    position: absolute;
    width: 15%; /* 이미지 크기 설정 */
    animation: m_my 7s infinite;
}
.m_mw {
    position: absolute;
    width: 16%; /* 이미지 크기 설정 */
    animation: m_mw 9s infinite;
}
.m_mc {
    position: absolute;
    width: 13%; /* 이미지 크기 설정 */
    animation: m_mc 9s infinite;
}
/*-----------------------------------------bg_flying_object---------------------------------*/
#renewal{
  margin-top: -56px;
}
.rn_br{
  display: none;
}
.renew_section {
    width: 100%;
    height: 100%; /* 원하는 높이 설정 */
    overflow: hidden; /* 이미지가 섹션을 넘지 않도록 */
    position: relative;
}
.renew_main2{
display: none;
}
/*left*/ 
.m_p {
    position: absolute;
    width: 10%; /* 이미지 크기 설정 */
    animation: m_p 7.8s infinite;
}
.m_b {
    position: absolute;
    width: 12%; /* 이미지 크기 설정 */
    animation: m_b 7s infinite;
}
.m_a {
    position: absolute;
    width: 12%; /* 이미지 크기 설정 */
    animation: m_a 9s infinite;
}
/*left*/ 

/*right*/ 
.m_c {
    position: absolute;
    width: 9%; /* 이미지 크기 설정 */
    animation: m_c 7.8s infinite;
}
.m_y {
    position: absolute;
    width: 9%; /* 이미지 크기 설정 */
    animation: m_y 7s infinite;
}
.m_w {
    position: absolute;
    width: 13%; /* 이미지 크기 설정 */
    animation: m_w 9s infinite;
}
/*right*/ 
.re_button{
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.new_button {
  text-align: center;
  width: 18vw;
  height: 100%;
  font-size: 25px;
  cursor: pointer;
  border: none;
  background-color: #98ef00;
  font-weight: bold;
  transition: all 0.5s;
  font-family: Paperlogy-8ExtraBold;
}
.new_button:hover {
            background-color: #619900;
            color: #fff;
}
.re_section1_wrap{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.re_section1{
  display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;
}
.new_img_bg{
  width: calc(60%);
  z-index: 2;
}
.new_img_bg_mo{
  display: none;
}
.new_img_bg_overay{
  position: absolute;
  top: calc(55%);
  left: calc(50%);
  transform: translate(-50%,-50%);
  width: 25vw; /* 이미지 크기 설정 */
    animation: card1 1s infinite;
    transform-origin: top left;
    z-index: 3;
}
.new_sec_1_a{
  margin: 0 auto;
  padding: 20px;
  display:block;
  background-color: #98ef00;
  width: 15vw;
  height: 80px;
  font-size: 25px;
  font-weight: bold;
  color: #000;
  transition: all 0.5s;
  font-family: Paperlogy-8ExtraBold;
  border-radius: 5px;
}
.new_sec_1_a:hover{
  background-color:#466e00;
  color: #fff;
}

.re_section2_wrap{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.re_section2{
  display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;
}
.re_section3_wrap{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.re_section3{
  display: flex;
  margin:0 auto;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            flex-direction: column;
}
.re_section4_wrap{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.re_section4{
  display: flex;
  margin:0 auto;
            justify-content: center;
            align-items: center;
            flex-wrap: nowrap;
            flex-direction: column;
}
.new_img_bg_overay1{
  position: absolute;
  top: calc(56%);
  left: calc(50%);
  transform: translate(-50%,-50%);
  width: 25vw; /* 이미지 크기 설정 */
  transform-origin: top left;
  animation: card2 5s infinite;
  transition: all 0.5s;
  z-index: 3;
}



.back_line_content_wrap {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%; /* 원하는 너비 설정 */
}

.back_line_content {
    display: inline-block;
    animation: move 10s linear infinite;
}

.back_line {
    width: 100%; /* 이미지 크기 조정 */
    height: auto; /* 비율 유지 */
}

@keyframes move {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.back_line_content_wrap1 {
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%; /* 원하는 너비 설정 */
}

.back_line_content1 {
    display: inline-block;
    animation: move1 10s linear infinite;
}

.back_line1 {
    width: 100%; /* 이미지 크기 조정 */
    height: auto; /* 비율 유지 */
}

@keyframes move1 {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
.new_sec_1_a1{
  margin: 0 auto;
  padding: 20px;
  display:block;
  background-color: #98ef00;
  width: 15vw;
  height: 80px;
  font-size: 25px;
  font-weight: bold;
  color: #121212;
  transition: all 0.5s;
  font-family: Paperlogy-8ExtraBold;
  border-radius: 5px;
}
.new_sec_1_a1:hover{
  background-color:#619900;
  color: #fff;
}
.live_title{
margin-bottom: 50px;
width: calc(30%);
}
.live_body_m{
  display: none;
}
.live_body{
  width: calc(60%);
  margin-bottom: 50px;
}
.new_sec_1_a2{
  margin: 0 auto;
  padding: 20px;
  display:block;
  background-color: #98ef00;
  width: 15vw;
  height: 80px;
  font-size: 25px;
  font-weight: bold;
  color: #000;
  transition: all 0.5s;
  font-family: Paperlogy-8ExtraBold;
  border-radius: 5px;
}
.new_sec_1_a2:hover{
  background-color:#ec1c24;
  color: #fff;
}
@media (max-width:992px) and (min-width:250px){
  .back_line {
    width: 300%;
}
.back_line1 {
    width: 300%;
}
.renew_main2{
  display: block;
}
.renew_main{
  display: none;
}
#renewal{
  margin-top: 2rem;
}

.m_p {
    width: 12%;
}
.m_b {
    width: 14%;
}
.m_a {
    width: 17%;
}
/*left*/ 

/*right*/ 
.m_c {
    width: 11%;
}
.m_y {
    width: 10%;
}
.m_w {
    width: 16%;
}
/*right*/ 

.re_button{
  height: 80px;
}
.new_button {
  width: 100vw;
  font-size: 1.18rem;
  color: #121212;
}
.new_img_bg{
  display: none;
}
.new_img_bg_mo{
  display: block;
  width: 98%;

}
.new_img_bg_overay{
  width: 70%;
}
.new_sec_1_a{
  width: 70vw;
  height: 80px;
  padding-top: 25px;
  font-size: 1.38rem;
}
.mo_del{
  display: none;
}
.new_img_bg_overay1{
  width: 70%; /* 이미지 크기 설정 */
}
.new_sec_1_a1{
  width: 70vw;
  height: 80px;
  padding-top: 25px;
  font-size: 1.38rem;
}
.live_title{
  width: 80%;
}
.live_body{
  display: none;
}
.rn_br{
  display: block;
}
.new_sec_1_a2{
  width: 70vw;
  padding-top: 25px;
  height: 80px;
  font-size: 1.38rem;
}
.m_mp{
  display: none;
}
.m_ma{
  display: none;
}
.m_mb{
  display: none;
}
.m_my{
  display: none;
}
.m_mw{
  display: none;
}
.m_mc{
  display: none;
}
.live_body_m{
  display: block;
  width: 99.9%;
  margin-bottom: 50px;
}
}

</style>

