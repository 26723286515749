<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <h2 class="text-center">
          <u>&nbsp;알림톡 예약발송 리스트&nbsp;</u>
        </h2>        
        <div class="float-start mb-1">
          <!-- <button type="button" class="btn btn-primary" @click="goToInsert">TEST</button> -->
          <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode"
            v-model="pCode" @change="changeProcCode(pCode)">
            <option :value="noVal" disabled>진행상태선택</option>
            <option value="A" >전체보기</option>
            <option value="cur" >금일이후</option>            
            <option value="past" >금일이전</option>
            <option value="cxl" >발송취소</option>
            <!-- <option value="sended" >발송완료</option>
            <option value="fail" >발송실패</option> -->
          </select>
        </div>

        <table class="table table-bordered table-striped fs-6">
          <thead class="small text-center">
            <tr>
              <th style="width:10%;">발송일</th>
              <th style="width:10%;">발송예정일</th>
              <th style="width:10%;">발송예정시간</th>
              <th style="width:40%;">발송문 제목</th>
              <th style="width:5%;">유효</th>
              <th style="width:10%;">등록자</th>
              <th style="width:10%;">등록(변경)일자</th>
              <th style="width:10%;">변경</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr :key="i" v-for="(ss, i) in sendKakaoSKD_list">
              <td class="text-center" v-if="ss.sDate == undefined"></td>
              <td class="text-center text-danger" v-else>{{ getDateFormat(ss.sDate) }}</td>
              <td>
                <input type="date" v-model="ss.sendSKD">
              </td>

              <td v-if="ss.sendTime == undefined"></td>
              <td v-else class="text-center">
                <select id="sendTime" v-model="ss.sendTime" class="form-select-sm text-center" style="font-size:small;" ref="sendTime">
                  <option v-for="time in times" :key="time" :value="time">{{ time }}</option>
                </select>
              </td>

              <td>
                {{ ss.skdTitle }}
                <!-- <a @click="goToSendSkdDetail(ss.mmCode);" style="cursor:pointer;"><font class="text-primary">{{ ss.skdTitle }}</font></a> -->
              </td>
              <td class="text-center">{{ ss.sUseFlag }}</td>

              <td class="text-center" v-if="ss.updName == undefined">{{ ss.insName }}</td>
              <td class="text-center" v-else>{{ ss.updName }}</td>
              
              <td class="text-center" v-if="ss.updDate == undefined">{{ getDateFormat(ss.insDate) }}</td>
              <td class="text-center" v-else>{{ getDateFormat(ss.updDate) }}</td>

              <td class="text-center">
                <button type="button" style="margin-right: 3px;" class="btn btn-outline-success btn-sm" @click="changeSendSKD(ss.sendSKD, ss.sendTime, ss.mmCode)">내용</button>
                <button type="button" style="margin-right: 3px;" class="btn btn-outline-warning btn-sm" @click="changeStatus(ss.sUseFlag, ss.mmCode)">상태</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  // DB에서 가져온 데이타
  data() { 
    return {
      sendKakaoSKD_list: [],        //예약발송리스트
      sendKakaoSKD_list_org: [],    //예약발송리스트 원형
      //pCode: "",                    //선택프로세슼코드
    };
  },
  created() {
    this.productId = this.$route.query.productId;

    const today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")
    console.log(this.today)

    this.getSendKakaoSKD_list("cur");
  }, 
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    ////document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";

    this.generateTimeOptions();
  },
  methods: {
    //공통함수
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getTimeFormat(date) {
      return this.$timeFormat(date);
    },

    generateTimeOptions() {
      const times = [];
      const startTime = new Date();
      startTime.setHours(0);
      startTime.setMinutes(0);

      // 시간 옵션을 10분 간격으로 생성
      for (let i = 0; i < 144; i++) { // 24시간 * 6 (10분 간격)
        times.push(this.formatTime(startTime));
        startTime.setMinutes(startTime.getMinutes() + 10);
      }

      this.times = times;
    },

    formatTime(time) {
      const hours = time.getHours().toString().padStart(2, '0');
      const minutes = time.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },    

    async getSendKakaoSKD_list(){
      let sUseFlag = "Y"
      let sendKakaoSKD_list = await this.$api("/api/sendKakaoSKD_list", {param: [ sUseFlag ]})

      if(sendKakaoSKD_list.length > 0 )
      {
        this.sendKakaoSKD_list = sendKakaoSKD_list
        for(let i=0; i<sendKakaoSKD_list.length; i++)
        {
          this.sendKakaoSKD_list[i].sendSKD = this.getDateFormat(this.sendKakaoSKD_list[i].sendSKD)

          if(sendKakaoSKD_list[i].sendTime != null && sendKakaoSKD_list[i].sendTime != undefined)
          {
            this.sendKakaoSKD_list[i].sendTime = this.sendKakaoSKD_list[i].sendTime.substring(0, this.sendKakaoSKD_list[i].sendTime.length-3)
          }
          //console.log(this.sendKakaoSKD_list[i].sendSKD, this.sendKakaoSKD_list[i].sendTime)
        }
        this.sendKakaoSKD_list_org = this.sendKakaoSKD_list
      }
      //기본표시리스트: 금일이후 발송
      this.sendKakaoSKD_list = sendKakaoSKD_list.filter((item)=>{
        return item.sendSKD >= this.today
      })
      console.log("sendKakaoSKD_list:", this.sendKakaoSKD_list)
    },

    changeProcCode(pCode)
    {
      //alert(pCode)
      switch(pCode)
      {
        case "A":         //전체
          this.sendKakaoSKD_list = this.sendKakaoSKD_list_org
          break;

        case "cur":       //금일이후
          this.sendKakaoSKD_list = this.sendKakaoSKD_list_org.filter((item)=>{
            return item.sendSKD >= this.today
          })
          break;

        case "past":    //금일이전
          this.sendKakaoSKD_list = this.sendKakaoSKD_list_org.filter((item)=>{
            return item.sendSKD < this.today
          })
          break;

          case "cxl":    //발송취소
          this.sendKakaoSKD_list = this.sendKakaoSKD_list_org.filter((item)=>{
            return item.sUseFlag === "N"
          })
          break;

        default:
          this.sendKakaoSKD_list = this.sendKakaoSKD_list_org.filter((item)=>{
            return item.sendSKD >= this.today
          })
          break;
      }
      
    },

    // goToSendSkdDetail(mmCode) 
    // {
    //   this.$router.push({path: '/patternDetail', query: {mmCode: mmCode}});
    // },

    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },

    changeStatus(sUseFlag, mmCode) {
      //변경전 확인
      this.$swal.fire({
        title: '상태를 변경할까요?(Y<->N)',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let nStatus = (sUseFlag === "Y") ? "N" : "Y"
          await this.$api("/api/changeSendSKDStatus", {param: [nStatus, mmCode]});
          // 상태변경 후, 갱신
          this.$swal.fire('변경완료!', '', 'success')
          this.getSendKakaoSKD_list();
        }
      })
    },
    async changeSendSKD(sSKD, sTime, mmCode){
      if(sSKD < this.today)
      {
        this.$swal.fire("","출발일은 금일 이후여야 합니다.", "warning")
        location.reload()
        return false
      }

      let res_changeSKD = await this.$api("/api/changeSendSKD", {param: [sSKD, sTime, mmCode]});
      if(res_changeSKD.affectedRows < 0)
      {
        this.$swal.fire("","발송얘정일 변경에 오류가 발생했습니다. 재시도해 주십시오", "warning")
        return false
      }
      else
      {
        this.$swal.fire("","발송예정일이 변경되었습니다", "info")
        location.reload(true)
      }
    }    
  }
}
</script>