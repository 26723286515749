<template>
  <div id="member">
    <section>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-xs-12">
            <div class="page-login-form">
              <h3>회원가입</h3>

              <div class="container mt-5">
                <!-- 아이디 -->
                <form class="row">
                  <div class="col-8 form-floating">
                    <input type="text" class="form-control" id="id-email" placeholder="아이디(이메일 주소)" maxlength="50" v-model="this.uMail" ref="uMail" :disabled="isInputDisabled_id" @dblclick="standby('I')">
                    <label for="id-email">아이디(이메일 주소)</label>
                  </div>
                  <div class="col-4 align-self-center">
                    <button type="button" class="bcheck" @click="checkDub(this.uMail)">중복확인</button>
                  </div>
                </form>

                <form class="row">
                  <div class="form-floating">
                    <input type="password" class="form-control" id="floatingPassword" placeholder="비밀번호" v-model="this.uPass1"  ref="uPass1" :disabled="isInputDisabled_pass" @dblclick="standby('P')" maxlength="12">
                    <label for="floatingPassword">비밀번호(영문+숫자, 5~12자리)</label>
                  </div>
                </form>

                <!-- 비밀번호 확인 -->
                <form class="row">
                  <div class="col-8 form-floating">
                    <input type="password" class="form-control" id="floatingPasswordc" placeholder="비밀번호 확인" v-model="this.uPass2" ref="uPass2" :disabled="isInputDisabled_pass" @dblclick="standby('P')" maxlength="12">
                    <label for="floatingPasswordc">비밀번호 확인</label>
                  </div>
                  <div class="col-4 align-self-center">
                    <button type="button" class="bcheck" @click="passCheck(this.uMail)">비밀번호체크</button>
                  </div>                  
                </form>
                

                <!-- 이름 -->
                <form class="row">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="nameKor" placeholder="한국명" v-model="this.uNickName" ref="uNickName" maxlength="50">
                    <label for="name">한국명</label>
                  </div>
                </form>

                <form class="row">
                  <div class="form-floating">
                    <input type="text" class="form-control" id="nameEng" placeholder="영문명(여권표시)" v-model="this.engName" ref="engName" maxlength="40">
                    <label for="name">영문명(여권표시, 선택사항)</label>
                  </div>
                </form>                

                <!-- 휴대전화번호 -->
                <form class="row">
                  <div class="col-8 form-floating">
                    <input type="text" class="form-control" id="phone" placeholder="휴대전화번호('-'제외)" v-model="this.alimCallC" maxlength="11" ref="alimCallC" :disabled="isInputDisabled_mobile">
                    <label for="phone">휴대전화번호('-'제외)</label>
                  </div>
                  <div class="col-4 align-self-center">
                    <button type="button" class="bcheck" @click="reqCertiNo(this.alimCallC)">인증번호요청</button>
                  </div>
                  <div class="text-end" v-if="this.showCertiTime == 'Y'">
                    <input type="text" class="form-control-sm" ref="cetiNumber" placeholder="알림톡으로 받으신 인증번호를 넣으세요" v-model="this.cetiNumber" maxlength="4" style="width:15rem; font-size: smaller; text-align: end; background-color:rgba(33, 88, 227, 0.174);">&nbsp;
                    <button type="button" class="btn btn-outline-primary btn-sm" @click="authOk(this.cetiNumber)">인증</button>&nbsp;
                    ( <font class="text-danger">{{ this.timeRemaining }}</font> )
                  </div>
                </form>

                <!-- 생년월일 -->
                <form class="row">
                  <div class="form-floating mb-3">
                    <input type="date" class="form-control" id="birthday" placeholder="생년월일" v-model="this.uBirthday" ref="uBirthday">
                    <label for="birthday">생년월일(여권표시, 선택사항)</label>
                  </div>
                </form>

                <!-- 동의내용 -->
                <div class="mterms">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="this.allConfirmFlag" ref="allConfirmFlag" @change="checkAllConfirm()">
                    <label class="form-check-label" for="defaultCheck">
                      <p>전체동의</p>
                    </label>
                  </div>
                  
                  <hr style="border-top:1px solid; opacity:1; margin:0">
                      
                  <div class="accordion accordion-flush" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="confirm_Info" ref="confirm_Info">
                          <label class="form-check-label" for="defaultCheck3">
                            <span class="check">[필수]</span>개인정보 수집 및 이용동의
                          </label>
                        </button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          엔타비 글로벌 (이하 '엔타비')는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.<br>

                          1. 개인정보 수집 및 이용목적<br>
                          엔타비는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.<br>
                          &nbsp; 1) 홈페이지 회원가입 및 관리<br>
                          &nbsp; 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.<br>
                          &nbsp; 2) 서비스 제공<br>
                          &nbsp;  물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산 등을 목적으로 개인정보를 처리합니다.<br>
                          &nbsp; 3) 마케팅 및 광고에의 활용<br>
                          &nbsp; 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.<br>
                          <br>
                          2. 개인정보 수집 항목<br>
                          엔타비는 다음의 개인정보 항목을 처리하고 있습니다.<br>
                          &nbsp; 1) 홈페이지 회원가입 및 관리/마케팅 정보 제공의 목적 (회원탈퇴 시 까지)<br>
                          &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름<br>
                          &nbsp; 2) 상담 및 해외 및 국내 여행 예약의 목적 (신용카드정보, 은행계좌정보)<br>
                          &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 여권사본, 법정대리인 이름, 법정대리인 자택 전화번호, 법정대리인 자택 주소, 법정대리인 휴대전화번호<br>
                          &nbsp; 3) 여행자 보험가입의 목적 (신용카드정보, 은행계좌정보)<br>
                          &nbsp;  이메일, 휴대전화번호, 자택주소, 성별, 생년월일, 이름<br>
                          &nbsp; 4) 대금결제의 목적 (이용목적이 달성되면 파기)<br>
                          &nbsp;  신용카드정보, 은행계좌정보<br>
                            <br>
                          3. 개인정보 수집 항목 보유 및 이용기간<br>
                          &nbsp; 1) 엔타비는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.<br>
                          &nbsp; 2) 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
                          &nbsp;  ① 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년<br>
                          &nbsp;  ② 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br>
                          &nbsp;  ③ 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br>
                          &nbsp;  ④ 계약 또는 청약철회 등에 관한 기록 : 5년<br>
                          &nbsp;  ⑤ 표시/광고에 관한 기록 : 6개월<br>
                        </div>
                      </div>
                    </div>

                    <div class="option">
                      <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="confirm_spTerms" ref="confirm_spTerms">
                      <label class="form-check-label" for="defaultCheck1">
                        <span class="check">[필수]</span>만14세 이상 확인
                      </label>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="confirm_Terms" ref="confirm_Terms">
                          <label class="form-check-label" for="defaultCheck2">
                            <span class="check">[필수]</span>서비스 이용약관 동의
                          </label>
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          서비스 이용약관
                          
                        </div>
                      </div>
                    </div>

                    <div class="option">
                      <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="confirm_Privacy" ref="confirm_Privacy">
                      <label class="form-check-label" for="defaultCheck4">
                        <span class="nocheck">[선택]</span>마케팅 정보 수신 동의
                      </label>
                    </div>

                    <div class="option last">
                      <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="confirm_3rd" ref="confirm_3rd">
                      <label class="form-check-label" for="defaultCheck5">
                        <span class="nocheck">[선택]</span>SNS 발송 동의
                      </label>
                    </div>

                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-lg-12 d-grid">
                    <button type="button" class="btn go-member" @click="joinMember">회원가입</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { generateRandomString } from '../assets/js/commonFunctions'
import { handlerInfo } from '../assets/js/commonFunctions'
import {genCode} from '../assets/js/genCode';                         //유저쿠폰번호생성
import moment from 'moment';

export default {
  data() {
    return {
      uMail: "",        //join mail
      uPass1: "",
      uPass2: "",
      uNickName: "",
      alimCallC: "",
      uBirthday: "",
      engName: "",

      dubPassFlag: "N",           //중복체크통과
      emailCheckEnd: "N",         //이메일체크아웃
      passCheckEnd: "N",          //비번체크아웃
      mobileCheckEnd: "N",        //모바일체크아웃
      certiFlag: "N",             //인증처리

      showCertiTime: "N",         //인증번호유효시간표시
      timeRemaining: "",          //유효시간타이머

      //약관동의
      allConfirmFlag: "",         //동의체크-전체선택
      confirm_Terms: "",          //서비스이용약관동의
      confirm_spTerms: "",        //만14이상확인동의
      confirm_Info: "",           //개인정보수집이용동의
      confirm_Privacy: "",        //마케팅정보수신동의
      confirm_3rd: "",            //SNS발솧동의

      loginInfo: [],
      loginParam: "",
      checkRes: "",

      handTel: [],                //매니저연락처
      handTelCnt: 0,              //수신처갯수

      cpCode: "",                 //유저발급쿠폰코드
    }
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      return this.$store.state.user;
    },

    //특정조건에서 입력불가화
    isInputDisabled_id() {
      return this.emailCheckEnd === 'Y' ? true : false
    },

    isInputDisabled_pass() {
      return this.passCheckEnd === 'Y' ? true : false
    },

    isInputDisabled_mobile() {
      return this.mobileCheckEnd === 'Y' ? true : false
    }
  },
  created(){
    this.getHandlerInfo()
  },
  mounted(){
    //document.getElementById("recent-item").style.display = "none";
    ////document.getElementById("notice").style.display = "none";
    const today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")
  },
  watch: {
    timeRemaining: async function(newVal){
      if(newVal == "00:00" || newVal == "")
      {
        await this.$api("/api/upd_certiNumber_nullify", {param:[this.alimCallC]});
      }
    }
  },
  methods: {
    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },    
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },

    //입력대기
    standby(param){
      if(param === "I")
      {
        if(this.emailCheckEnd === "Y")
        {
          this.uMail = ""
          this.$refs.uMail.select()
          this.emailCheckEnd = "N"
          this.dubPassFlag = "N"
          //console.log("emailCheckEnd:", this.emailCheckEnd)
          this.$swal.fire("","입력 후 중복확인을 해주세요.","info")
        }
      }
      else if(param === 'P')
      {
        if(this.passCheckEnd === "Y")
        {
          this.uPass1 = ""
          this.uPass2 = ""
          this.$refs.uPass1.select()
          this.passCheckEnd = "N"
          this.$swal.fire("","새로운 비밀번호를 입력하세요","info")
        }
      }
    },

    //비번체크(정합성+일치)
    passCheck(){
      if (this.uPass1.length < 5 && this.uPass2.length < 5) {
        this.$refs.uPass1.select()
        this.$swal.fire("","비밀번호는 5자리 이상이어야 합니다","warning")
        return false;
      }
      else
      {
        // 영문자와 숫자를 포함하고 있는지 체크
        let hasLetter = /[a-zA-Z]/.test(this.uPass1);
        let hasNumber = /\d/.test(this.uPass1);

        if(!hasLetter || !hasNumber)
        {
          this.$refs.uPass1.select()
          this.$swal.fire("","비밀번호는 숫자와 문자로 구성되어야 합니다.","warning")
          return false;
        }
      }

      if(this.uPass1 === this.uPass2)
      {
        this.passCheckEnd = "Y"

        this.$swal.fire({
          //position: 'bottom-end',
          icon: 'success',
          html: '유효한 비밀번호입니다<br>(비번번경은 입력란 더블클릭)',
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })        
      }
      else
      {
        this.$refs.uPass2.select()
        this.$swal.fire("","비밀번호가 일치하지 않습니다.","warning")
        return false
      }      
    },


    async checkDub(val){
      this.dubPassFlag = "N"
      let checkResult = this.checkEmail(val)      //email 유효체크
      let checkResult2 = this.checkEmailInKeyword(val)

      if(!checkResult)
      {
        this.$refs.uMail.select()
        this.$swal.fire("","유효한 이메일이 아닙니다.","warning")
        return false
      }
      else if(checkResult2)
      {
        this.$refs.uMail.select()
        this.$swal.fire("","사용할 수 없는 단어가 있습니다.<br>다른 계정명을 이용해주세요.","warning")
        return false
      }      
      else
      {
        //this.dubPassFlag = "Y"
        let dubCheck_email = await this.$api("/api/checkEmail", {param: [val]})
        
        if(dubCheck_email.length > 0)
        {
          this.dubPassFlag = "N"
          this.$swal.fire("","이미 등록된 이메일입니다.","info")
          return false
        }
        else
        {
          this.dubPassFlag = "Y"
          this.emailCheckEnd = "Y"
          this.$swal.fire({
            //position: 'bottom-end',
            icon: 'success',
            html: '등록가능한 이메일입니다<br>(아이디변경은 입력화면 더블클릭)',
            toast: true,
            showConfirmButton: false,
            timer: 2000
          })
        }
      }
    },

    checkEmail(val){
      const emailRegex = /^(?!.*\.{2})[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
      
      return emailRegex.test(val)
    },

    checkEmailInKeyword(val) {
      // 계정명에 금칙어체크
      const keywords = ["master", "ntabi", "ndaytrip", 'ntabiglobal']
      //return val.toLowerCase().includes('master');
      return keywords.some(keywords => val.toLowerCase().includes(keywords))
    },

    checkMobile(val){
      const koreanMobileRegex = /^01[0]\d{3,4}\d{4}$/;
      return koreanMobileRegex.test(val);
    },

    //전체약관동의
    checkAllConfirm()
    {
      if(this.allConfirmFlag === "Y")
      {
        this.confirm_Terms = "Y" //약관동의
        this.confirm_spTerms = "Y"
        this.confirm_Info = "Y"
        this.confirm_Privacy = "Y"
        this.confirm_3rd = "Y"
      }
      else if(this.allConfirmFlag === "N")
      {
        this.confirm_Terms = "N" //약관동의
        this.confirm_spTerms = "N"
        this.confirm_Info = "N"
        this.confirm_Privacy = "N"
        this.confirm_3rd = "N"
      }      
    },

    async authOk(certi){
      if(!certi)
      {
        this.$refs.cetiNumber.focus();
        this.$swal.fire('','인증번호를 입력하세요', 'warning')
        return false;        
      }
      else
      {
        if(this.timeRemaining === '00:00' || this.timeRemaining === '')
        {
          await this.$api("/api/upd_certiNumber_nullify", {param: [certi]});
          this.certiNo = ""
          this.certiFlag = "N"
          this.$swal.fire('','인증번호가 유효하지 않습니다. 재요청해주세요.', 'warning')
          return false;
        }

        let res_checkCerti = await this.$api("/api/checkNonMember", {param: [this.alimCallC, certi]})
        if(res_checkCerti.length > 0)
        {
          this.certiFlag = "Y"
          this.mobileCheckEnd = "Y"
          await this.$api("/api/upd_certiNumber", {param: [certi, this.alimCallC]})
          this.showCertiTime = "N"
          this.$swal.fire('','인증이 되었습니다', 'success')
        }
      }

    },

    async reqCertiNo(val)
    {
      if(!val)
      {
        this.$refs.alimCallC.select()
        this.$swal.fire('',"수신가능한 <b class='text-danger'>모바일 연락처</b>를 입력해주세요", 'question')
        return false;
      }
      else
      {
        let checkResult = this.checkMobile(val)      //mobile number 유효체크
        if(!checkResult)
        {
          this.$refs.alimCallC.select()
          this.$swal.fire("","유효한 전화번호가 아닙니다.","warning")
          return false
        }
        else
        {
          //기존인증번호 무효화
          await this.$api("/api/upd_certiNumber_nullify", {param:[val]});
          this.generateRandomString(val);
        }
      }
    },
    
    async getHandlerInfo()
    {
      let handTel = await handlerInfo(this.$api);
      if(handTel.length > 0)
      {
        // let handTel_tmp = handTel.map(row => {
        //   return { aimCall: row.aimCall }
        // })
        
        this.handTel = handTel
      }
      // else
      // {
      //   this.$swal.fire('','연락가능한 매니저 번호가 없습니다', 'warning')
      //   return false;
      // }
      //let clientCall = []

      // let i=0
      // while(i<handTel.length)
      // {
      //   console.log("handTelNos: ", this.handTel[i].aimCall)
      //   i++
      // }
    },

    async generateRandomString(mobile)
    {
      this.certiNo = await generateRandomString(this.$api, mobile);
      if(!this.certiNo)
      {
        this.alimCallC = ""
        this.$refs.alimCallC.focus()
        this.$swal.fire('','인증번호를 다시 요청해주세요.', 'info')
        return false;        
      }
      else
      {
        this.sendKakao('CT', this.certiNo, mobile)
        this.getCountdown(3)
      }
    },

    //인증번호 유효시간표시
    getCountdown(minutes)
    {
      this.showCertiTime = "Y"
      let seconds = minutes * 60;

      const timer = setInterval(() => {
        const minutesRemaining = Math.floor(seconds / 60);
        const secondsRemaining = seconds % 60;
        this.timeRemaining = `${minutesRemaining.toString().padStart(2, '0')}:${secondsRemaining.toString().padStart(2, '0')}`;

        seconds--;
        if (seconds < 0) {
          clearInterval(timer);
          this.timeRemaining = "00:00";
          this.showCertiTime = "N"
        }
      }, 1000); // 1초마다 실행
    },

    //회원가입
    async joinMember(){
      if(!this.uMail)
      {
        this.$refs.uMail.focus()
        this.$swal.fire('', '아이디(이메일)을 입력하세요', 'warning')
        return false;
      }
      
      if(this.emailCheckEnd != "Y" || this.emailCheckEnd == undefined)
      {
        this.$refs.uMail.focus()
        this.$swal.fire('', '아이디(이메일)의 중복체크를 진행하세요', 'warning')
        return false;
      }

      if(this.passCheckEnd != "Y" || this.passCheckEnd == undefined)
      {
        this.$refs.uPass1.focus()
        this.$swal.fire('', '비밀번호체크를 진행하세요', 'warning')
        return false;
      }

      if(!this.uPass1 || !this.uPass2)
      {
        this.$refs.uPass1.focus()
        this.$swal.fire('', '비밀번호를 입력하세요', 'warning')
        return false;
      }
      else
      {
        this.passCheck()
      }
      // if(this.passCheckEnd != "Y")
      // {
      //   this.$refs.uPass1.focus()
      //   this.$swal.fire('', '비밀번호를 확인해 주세요', 'warning')
      //   return false;
      // }

      if(!this.uNickName)
      {
        this.$refs.uNickName.focus()
        this.$swal.fire('', '한국명을 입력하세요.', 'warning')
        return false;
      }

      if(!this.alimCallC)
      {
        this.$refs.alimCallC.focus()
        this.$swal.fire('', '연락처를 입력하세요', 'warning')
        return false;        
      }
      if(this.mobileCheckEnd != "Y")
      {
        this.$refs.alimCallC.focus()
        this.$swal.fire('', '연락처 인증을 받으세요.', 'warning')
        return false;
      }

      //필수약관동의체크
      if(this.confirm_Terms != "Y")
      {
        this.$refs.confirm_Terms.focus()
        this.$swal.fire('', '서비스약관에 동의해주세요', 'warning')
        return false;
      }
      if(this.confirm_spTerms != "Y")
      {
        this.$refs.confirm_spTerms.focus()
        this.$swal.fire('', '만 14세 이상확인에 동의해주세요', 'warning')
        return false;
      }
      if(this.confirm_Info != "Y")
      {
        this.$refs.confirm_Info.focus()
        this.$swal.fire('', '개인정보 수집 및 이용동의에 동의해주세요', 'warning')
        return false;
      }

      let joinFlag = "E"                        //홈페이지직가입
      let userNo = ""
      let useFlag = ""
      let uGender = ""
      let spRemarks = ""
      let engName = this.engName.toUpperCase()  //대문자화
      let uBirthday
      let uBirthyear
      if(this.uBirthday != "")
      {
        uBirthyear = this.getDateFormat(this.uBirthday).substring(0,4)
        uBirthday = this.getDateFormat(this.uBirthday).slice(-5).replace("-","")
      }
      let res_joinMember = await this.$api("/api/proc_joinMember",{param: [
        this.uMail, uBirthday, this.uNickName, this.alimCallC, joinFlag, userNo, useFlag, this.alimCallC, uGender, this.uPass1, spRemarks, engName, uBirthyear
      ]})
      if(res_joinMember.affectedRows >= 0) 
      {
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm_tmp = today.getMonth()+1;
        let dd_tmp = today.getDate();
  
        let mm = "";
        if(mm_tmp < 10) {mm = "0" + mm_tmp}
        else mm = mm_tmp;
        let dd = "";
        if(dd_tmp < 10) {dd = "0" + dd_tmp}
        else dd = dd_tmp;
        let joinDate = yyyy +"-" + mm + "-" + dd
        let checkJDate = (yyyy+mm+dd) * 1

        let plusPoint = "N"

        let joinMember = ""

        if(checkJDate < 20250401)
        {
          joinMember = '<b>'+ this.uNickName + '님</b>, 회원가입을 환영합니다!<br>가입축하 <span style="color: red; font-weight: 600;">10,000</span>포인트와 <span style="color:  gray; font-weight: 600;">시크릿쿠폰</span>을 드립니다. <br>여러 특전이 있는 <b style="color:goldenrod;">엔데이트립멤버십</b>에 가입하시겠습니까?'

          plusPoint = "Y"       //특전: 10,000포인트 + 시크릿선박쿠폰 5,000원
        }
        else
        {
          joinMember = '<b>'+ this.uNickName + '님</b>, 회원가입을 환영합니다!<br>여러 특전이 있는 <b style="color:goldenrod;">엔데이트립멤버십</b>에 가입하시겠습니까?'

          plusPoint = "N"
        }

        this.$swal.fire({
          // html: '<b>'+ this.uNickName + '님</b>, 회원가입을 환영합니다!<br>여러 특전이 있는 <b style="color:goldenrod;">엔데이트립멤버십</b>에 가입하시겠습니까?',
          html: joinMember,
          imageUrl: '/IMGs/welcome.png',
          imageWidth: 300,
          imageHeight: 150,
          imageAlt: 'WelcomeImage',
          showCancelButton: true,
          confirmButtonText: '멤버십에 가입합니다',
          cancelButtonText: '나중에 가입하겠습니다'
        }).then(async result => {
          if(result.isConfirmed) {
            if(plusPoint === "Y")
            {
              let evtPlusPoint = await this.$api("/api/evtPlusPoint", { param: [ this.uMail ] } );
              if(evtPlusPoint.affectedRows < 0)
              {
                this.$swal.fire("","특전 포인트 부여 중 에러가 발생했습니다. 당사 웹사이트나 연락처로 문의주시면 감사하겠습니다", "error")
              }

              //쿠폰발행
              let catCode = "FC001-";
              let inqCode = await this.genCode(catCode)

              this.cpCode = inqCode                             //발급쿠폰코드
              let cpName = "시크릿쿠폰"
              let cpPrice = 5000*1                              //쿠폰가치
              let cpUseFlag = "N"                               //사용여부
              let cpRemarks = "선박상품 구입 시, 할인"
              let cpHCode = "F"
              let useCode = "SR"                                //사용영역: SR(SalesRoute), US(User), PD(Product)
              let withPoint = "N"                               //포인트병용 (Y/N)

              //중복발행방지체크
              let checkDubCoupon = await this.$api("/api/checkDubCoupon", { param: [ this.alimCallC, catCode ] });
              if(checkDubCoupon.length>0)
              {
                this.$swal.fire("","기발행된 쿠폰"+ checkDubCoupon.cpCode +"이 있습니다.", "info")
              }
              else
              {
                let inssuanceCoupon = await this.$api("/api/inssuanceCoupon", { param: [ 
                  this.uMail, cpHCode, this.cpCode, cpName, cpPrice, cpUseFlag, cpRemarks, this.alimCallC, useCode, withPoint
                ] });
                if(inssuanceCoupon.affectedRows < 0)
                {
                  this.$swal.fire("","쿠폰발행 중 에러가 발생했습니다. 당사 웹사이트나 연락처로 문의주시면 감사하겠습니다", "error")
                }
              }
            }
            this.sendKakao_join("JO", joinDate, 'dt')

            //멤버십가입용 자동로그인처리
            this.autoLogin(uBirthyear, uBirthday, uGender).then(() => {
              this.$router.push({ path: "/myPageInfoChange_joinM", query: {st: "dt"}})  
            })
          }
          else  
          {
            if(plusPoint === "Y")
            {
              let evtPlusPoint = await this.$api("/api/evtPlusPoint", { param: [ this.uMail ] } );
              if(evtPlusPoint.affectedRows < 0)
              {
                this.$swal.fire("","특전 포인트 부여 중 에러가 발생했습니다. 당사 웹사이트나 연락처로 문의주시면 감사하겠습니다", "error")
              }

              //쿠폰발행
              let catCode = "FC001-";
              let inqCode = await this.genCode(catCode)

              this.cpCode = inqCode                             //발급쿠폰코드
              let cpName = "시크릿쿠폰"
              let cpPrice = 5000*1                              //쿠폰가치
              let cpUseFlag = "N"                               //사용여부
              let cpRemarks = "선박상품 구입 시, 할인"

              //중복발행방지체크
              let checkDubCoupon = await this.$api("/api/checkDubCoupon", { param: [ this.alimCallC, catCode ] });
              if(checkDubCoupon.length>0)
              {
                this.$swal.fire("","연락처로 이미 발행된 쿠폰이 있습니다.", "info")
              }
              else
              {
                let inssuanceCoupon = await this.$api("/api/inssuanceCoupon", { param: [ 
                  this.uMail, catCode, this.cpCode, cpName, cpPrice, cpUseFlag, cpRemarks, this.alimCallC
                ] });
                if(inssuanceCoupon.affectedRows < 0)
                {
                  this.$swal.fire("","쿠폰발행 중 에러가 발생했습니다. 당사 웹사이트나 연락처로 문의주시면 감사하겠습니다", "error")
                }
              }
            }

            this.sendKakao_join("JO", joinDate, 'ndt')
            //this.$router.push({ path: "/" }); 
          }
        })
      }
      else
      {
        this.$swal.fire('','<b class="text-danger">죄송합니다</b><br>처리 중 문제가 발생했습니다.('+ res_joinMember.error.sqlMessage +')', 'warning')
        return false;
      }

    },

    /** 카카오알림 발송(회원가입안내) */
    // async sendKakao_join(param, jDate, val){        //pPoint Y:포인트특전 N:없음
    //   let insKSendHistory = await this.$api("/api/insKSendHistory", {param: 
    //   [
    //     this.uMail, param, this.alimCallC
    //   ]});

    //   //알림톡 발송대상 확정
    //   this.handTel.unshift({aimCall: this.alimCallC})
    //   this.handTelCnt = this.handTel.length
    //   console.log("handTel: ", this.handTel, "/ Length: ", this.handTelCnt)

    //   if(insKSendHistory.affectedRows > 0) 
    //   {
    //     if(param == "JO") 
    //     {
    //       let i = 0
    //       while(i <this.handTelCnt)
    //       {
    //         let kakaoAlimData = 
    //         {
    //           //카카오 알림톡 발송(가입환영)
    //           clientName: this.uNickName,
    //           clientCall: this.handTel[i].aimCall,
    //           joinDate: jDate,
    //           resCode: '',
    //           alimFlag: param //알림유형(인증번호발송)
    //         };
    //         this.$api("api/kakaoAlimSend5", {
    //           param: [
    //             { kakaoAlimData: kakaoAlimData},
    //           ]
    //         }).then((res) => {console.log(res)});
    //         i++
    //       }
    //       this.$swal.fire('', '가입확인안내가 발송되었습니다.', 'success');
          
    //       if(val === 'ndt')
    //       {
    //         location.href = "/"
    //       }
    //     }
    //   }
    // },
    async sendKakao_join(param, jDate, val) {
      let insKSendHistory = await this.$api("/api/insKSendHistory", {
        param: [this.uMail, param, this.alimCallC]
      });

      this.handTel.push({aimCall: this.alimCallC});
      this.handTelCnt = this.handTel.length;
      //console.log("handTel: ", this.handTel, "/ Length: ", this.handTelCnt);

      if (insKSendHistory.affectedRows > 0) {
        if (param == "JO") {
          // Promise.all을 사용하여 모든 API 호출이 완료될 때까지 기다림
          const promises = this.handTel.map(async (tel) => {
            let kakaoAlimData = {
              clientName: this.uNickName,
              clientCall: tel.aimCall,
              joinDate: jDate,
              resCode: '',
              alimFlag: param
            };
            return this.$api("api/kakaoAlimSend5", {
              param: [{ kakaoAlimData: kakaoAlimData }]
            });
          });

          await Promise.all(promises);
          
          this.$swal.fire('', '가입확인안내가 발송되었습니다.', 'success');
          
          if (val === 'ndt') {
            location.href = "/";
          }
        }
      }
    },




    /** 카카오알림 발송 */
    async sendKakao(param, cNo, mobile){
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: 
      [
        cNo, param, mobile
      ]});

      //- 인증번호: #{certiNo}
      if(insKSendHistory.affectedRows > 0) {
        if(param == "CT") 
        {
          let kakaoAlimData = 
          {
            //카카오 알림톡 발송(인증번호발송)
            certiNo: cNo,
            clientCall: mobile,
            resCode: '',
            alimFlag: "CT" //알림유형(인증번호발송)
          };
          this.$api("api/kakaoAlimSend4", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          this.$swal.fire('', '요청된 연락처로 인증번호가 발송되었습니다.', 'success');
          //location.reload(true)
        }
      }
    },

    //직가입회원 로그인체크
    async autoLogin(param_uBirthyear, param_uBirthday, param_uGender){
      //멤버십회원인 경우 세션추가
      //store저장용
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm_tmp = today.getMonth()+1;
      let dd_tmp = today.getDate();
      let hour = today.getHours();
      let min = today.getMinutes();
      let sec = today.getSeconds();
      
      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;

      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;

      //세션용자료
      let joinFlag = "E"
      let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`
      let uBirthyear = param_uBirthyear
      let uBirthday = param_uBirthday
      let uGender = param_uGender

      let loginInfo_E1 = {
        email:this.uMail, nickname:this.uNickName, joinFlag:joinFlag, phone:this.alimCallC, alimCallC:this.alimCallC, birthday:uBirthday, birthyear:uBirthyear, gender:uGender, insDate:insDate
        , memLevel:null, memNo:null, memExpDate:null
        //, memLevel:this.membershipInfo.memLevel, memNo:this.membershipInfo.memNo, memExpDate:this.membershipInfo.expDate
      }

      //로그인 & 세션 처리
      this.$api("/api/loginMemberE", {param: [{ 
        email:this.uMail, nickname:this.uNickName, joinFlag:joinFlag, phone:this.alimCallC, alimCallC:this.alimCallC, birthday:uBirthday, birthyear:uBirthyear, gender:uGender, insDate:insDate
        , memLevel:null, memNo:null, memExpDate:null
        //, memLevel:this.membershipInfo.memLevel, memNo:this.membershipInfo.memNo, memExpDate:this.membershipInfo.expDate
      }]
    })
      
      this.$store.commit("user", loginInfo_E1);

      //세션유지시간 1시간으로 로컬세팅
      localStorage.setItem('sessionExpiration', new Date().getTime() + 60 * 60 * 1000);
    },

    //쿠폰코드생성
    async genCode(param) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 1000 && chkFlag === "N"; attempts++) 
      {
        let ymd = this.today.replace(/-/g, '')
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);

        let chk_genCode = await this.$api("/api/checkDub_coupon", { param: [rtnCode] });

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N") 
      {
        this.$swal.fire("","쿠폰번호 생성횟수 한계에 도달하였습니다.<br>담당자에게 문의(1660-4602)하여 주세요.","warning")
        return false;
      } 
      else 
      {
        return rtnCode
      }
    },
  }
}
</script>



<style>
#member{
  margin:6rem 0
}
#member h3 {
  color:#000;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom:1.5rem;
  text-transform: uppercase;
  text-align: center;
}



/*--------------------------------------------------------------
	# 내용
--------------------------------------------------------------*/
#member .form-control{
  border-radius: 0;
  border-color: #777;
  margin:.5rem 0
}
#member .form-floating>label{
  padding:1.4rem 0.75rem;
  color:#999
}

#member .bcheck{
  width:100%; 
  padding:1rem 0;
  border:1px solid #000;
  background-color: #000;
  color:#fff
}


/* 동의내용 */
#member .mterms{
  border:1px solid #000;
  padding:15px
}
#member .mterms .form-check{
  margin:7px 0 0 20px
}
#member .mterms .form-check p{
  font-size:1.2rem;
  font-weight: 600;
  color:#000;
  margin-top:-4px
}
#member .mterms .accordion-item{
  border:none
}
#member .mterms .accordion-body{
  font-size:.85rem
}
.no:after{
  opacity: 0;
}
#member .mterms .check{
  padding:0 5px 0 10px;
  font-weight: 600;
  color:#f00
}
#member .mterms .nocheck{
  padding:0 5px 0 10px;
  color:#777
}
#member .mterms .option{
  margin:10px 0 10px 20px
}
#member .mterms .last{
  margin-top:25px
}

/* 회원가입 */
#member .go-member{
  margin:3rem 0;
  background-color: #000;
  border-radius:0;
  padding:1.5rem 0;
  color:#fff;
  font-size:1.2rem;
}

</style>
