<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>문 의 상 세</u></h2>
      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">진행상태</label>
        <div class="col-md-9">
          <div class="input-group">
            <input type="text" class="form-control" ref="txt_status" v-model="this.jDetail.status" disabled>
            <select class="form-select form-select-sm text-primary" aria-label=".form-select-sm" ref="sel_procCode" v-model="this.rProcCode">
              <!-- <option value="" disabled>진행상태변경</option> -->
              <option value="" checked>진행상태유지</option>
              <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.procName}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">상품코드</label>
        <div class="col-md-9">
          <div class="input-group">
            <input type="text" class="form-control" ref="txt_pdtCode" v-model="this.jDetail.pdtCode" disabled>
            <button type="button" class="btn btn-success btn-xs me-1" @click="goToPage()">
              <small>상품페이지로 이동</small>
            </button>
          </div>
        </div>
      </div>      
      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">문의유형 / 제목</label>
        <div class="col-md-9">
          <div class="input-group">
            <input type="text" class="form-control text-danger" ref="txt_cName" v-model="this.jDetail.cat" disabled>
            <input type="text" class="form-control" ref="txt_reqTitle" v-model="this.jDetail.reqTitle" disabled>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">문의자 / 인원수 <font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group">
            <input type="text" maxlength="20" class="form-control" ref="txt_reqPersonName" v-model="this.jDetail.reqPersonName">
            <input type="number" class="form-control" ref="txt_perCnt" v-model="this.jDetail.perCnt">
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">연락처 / 이메일<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group">
            <input type="tel" maxlength="11" placeholder="-제외" class="form-control" ref="txt_cTel" v-model="this.jDetail.reqTel">
            <input type="email" maxlength="40" class="form-control" ref="txt_cEmail" v-model="this.jDetail.reqEmail">
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 row">
        <label class="col-md-3 col-form-label">여권번호 / 만료일</label>
        <div class="col-md-9">
          <div class="input-group">
            <input type="text" class="form-control" ref="txt_passNo" v-model="this.jDetail.passNo" @click="noTag()">
            <input type="date" class="form-control" ref="txt_passExpire" v-model="this.jDetail.passExpire">
            <button type="button" class="btn btn-success btn-xs me-1" disabled v-if="this.jDetail.passNo === ''">
              <small>여권사본업로드</small>
            </button>
            <button type="button" class="btn btn-success btn-xs me-1" @click="callPassUp()" v-else>
              <small>여권사본</small>
            </button>
          </div>
        </div>
      </div>
      <div class="mb-3 mt-2 row" v-if="(this.openTag === 'Y')">
        <label class="col-md-3 col-form-label">여권사진</label>
        <div class="col-md-9">
          <div class="form-control" type="file" accept="image/png, image/jpg, image/jpeg">
            <div class="position-relative">
              <img :src="`/download/passport/${this.seqReq}/${this.jDetail.passNo}.jpg`" class="img-fluid" />
              <div>
                <!-- <p class="position-absolute top-0 end-0" style="cursor:pointer;" @click="deleteImage(m.pdtCode, 'T', m.imgName)">
                  <i class="bi bi-file-excel text-warning"></i></p> -->
                <p class="position-absolute top-0 end-0" style="cursor:pointer;" @click="deleteImage('m.pdtCode', 'T', 'm.imgName')">
                  <i class="bi bi-trash3-fill text-danger fs-4"></i>
                </p>
              </div>
            </div>
          </div>
          <input type="file" class="form-control" accept="image/png,image/jpeg" @change="uploadFile($event.target.files, this.seqReq)">
          <div class="alert alert-secondary" role="alert" style="font-size:small;">
            <span>File 확장자: png, jpeg, jpg</span>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">문의내용</label>
        <div class="col-md-9">
          <!-- <input type="text" class="form-control" v-model="productInfo.pdtNameEng"> -->
          <textarea class="form-control" maxlength="245"  placeholder="250자 이내" ref="txt_nContent" id="floatingTextarea" style="height:150px" v-model="this.jDetail.reqSpRemarks" disabled></textarea>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">담당자 비고사항
          <p><small>내부용(외부표시안됨)</small></p>
        </label>
        <div class="col-md-9">
            <div class="row">
            <textarea class="form-control" ref="txt_nContent" id="floatingTextarea" style="height:150px; font-size:small" v-model="this.jDetail.respoComment" disabled></textarea>
            </div>
            <div class="row">
            <textarea class="form-control" maxlength="500"  placeholder="500자 이내" ref="txt_respoComment_new" id="floatingTextarea" style="height:150px; font-size:small" v-model="respoComment_new"></textarea>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-success" @click="requestUpdate();">수정</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList()">취소</button>
        </div>
        <!-- <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
        </div> -->
      </div>

      <hr class = "text-danger">

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">
          직전답변
          <p class="text-secondary">답변자 ({{this.requestAnswer.handlerNameKor}})</p>
        </label>
        <div class="col-md-9">
          <!-- <input type="text" class="form-control" v-model="productInfo.pdtNameEng"> -->
          <textarea class="form-control" maxlength="999" ref="txt_ansContent" id="floatingTextarea" style="height:100px" v-model="this.requestAnswer.ansContent" readonly></textarea>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">
          신규답변
          <p>
            <button type="button" class="btn btn-primary btn-sm me-1 align-text-middle" @click="sendAnswer();">
              <font style="font-size:small" class="align-bottom"><small>답변처리</small></font>
            </button>
          </p>
          <p class="text-danger">
            ※ 상기내용의 '수정'과 별개로 진행됩니다
          </p>
        </label>
        <div class="col-md-9">
          <!-- <input type="text" class="form-control" v-model="productInfo.pdtNameEng"> -->
          <textarea class="form-control" maxlength="999"  placeholder="1,000자 이내" ref="txt_ansContent" id="floatingTextarea" style="height:150px" v-model="this.ansContent"></textarea>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
export default {
  data() {
    return {
      jDetail: [],
      rProc: [],
      rProcCode: "",
      openTag: "N", //여권사진업로드 영역호출
      respoComment_new: "", //담당자 코멘트
      requestAnswer: [], //답변내용
      ansContent: "", //신규답변
      answerFlag: "N" //답변글 플래그
      //passExpireDate: "" //여권만료일
    };
  },
  created() {
    this.iqCode = this.$route.query.iqCode;
    this.getRequestDetail();
    this.getReqProcLevel();
    this.getReqAnswer(); //답변데이타

    console.log("pageUserInfo:", this.user)
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    async getRequestDetail() {
      let reqJoinDetail = await this.$api("/api/requestDetail", {param: [this.iqCode]});
      this.jDetail = reqJoinDetail[0];
      this.jDetail.passExpire = (this.jDetail.passExpire == null) ? null : this.getDateFormat(this.jDetail.passExpire);
    },
    async getReqAnswer() {
      let requestAnswer = await this.$api("/api/requestAnswer", {param: [this.iqCode]});
      if(requestAnswer.length > 0) {
        this.requestAnswer = requestAnswer[0];
        this.answerFlag = "Y";
      }
      console.log("requestAnswer:",this.requestAnswer);
    },    
    async getReqProcLevel() {
      this.rProc = await this.$api("/api/reqProcLevel", {});
      console.log("rProc:",this.rProc);
    },
    noTag() {
      this.openTag = "N";
    },
    goToPage() {
      this.$router.push({path: '/detail', query: {productId: this.jDetail.pdtCode}});
    },
    callPassUp() {
      this.openTag = (this.openTag === 'Y') ? 'N' : 'Y';
      //console.log("openTag2:", this.openTag);
    },
    //여권사본 업로드
    async uploadFile(files, id) {
      let fileLength = 0
      let fileType_tmp = "";
      //let fileType = ""
      let licenseFile = "";
      let data = null;
      if (files) {
        fileLength = files[0].name.length;
        fileType_tmp = files[0].name.lastIndexOf(".");
        this.fileType = files[0].name.substring(fileType_tmp+1, fileLength).toLowerCase();
        licenseFile = this.jDetail.passNo+"."+this.fileType;
        data = await this.$base64(files[0]);
      }
      const { error } = await this.$api(`/upload/passport/${id}/${licenseFile}`, { data });
      if (error) {
        return this.$swal("업로드 오류발생, 재시도 필요.");
      }

      this.$swal("업로드 완료");

      // setTimeout(() => {
      //   this.getProductImage();
      // }, 1000);
    },
    //여권사본 삭제
    deleteImage(pdtCode, catImg, imgName) {
      this.$swal("","삭제는 현재 개발 중입니다.","info");
      console.log(`id:${pdtCode}, catImg:${catImg}, imagName:${imgName}`);
      // this.$swal.fire({
      //   title: '확인: 삭제?',
      //   showCancelButton: true,
      //   confirmButtonText: `삭제`,
      //   cancelButtonText: `취소`
      // }).then(async (result) => {
      //   if (result.isConfirmed) {
      //     await this.$api("/api/imageDelete",{param:[pdtCode, catImg, imgName]});
      //     this.getProductImage();
      //     this.$swal.fire('삭제완료', '', 'success');
      //   } 
      // });
    },
    async sendAnswer()
    {
      let ins_requestAnswer = await this.$api("/api/ins_requestAnswer", {param: 
          [
            this.seqReq,'0',this.ansContent, this.user.handlerId, this.iqCode
          ]});
        //console.log("ins_requestAnswer:", ins_requestAnswer);
        if(Object.keys(ins_requestAnswer)[0] === 'error')
        {
          this.$swal.fire('','답글등록(ins_requestAnswer)중 오류가 발생했습니다.<br>관리자에게 문의해주세요', 'warning')
        }
        else
        {
          this.sendKakao("IA")
          this.$swal.fire('','답글등록이 완료되었습니다.', 'success')
          location.reload();
          
          //카카오 알림톡 전송
        }
    },

    /** 카카오알림 발송(문의답변 IA) */
    //param: BO/BO_non
    async sendKakao(param)
    {
      //let resCode = this.eventCode
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        this.iqCode, param, this.user.handlerId
      ]});
      //console.log("insKSendHistory:",insKSendHistory);
      if(insKSendHistory.affectedRows > 0) {
        if(param == "IA") 
        {
          //문의답변안내
          let kakaoAlimData = 
          {
            //카카오 알림톡 발송(예약접수)
            clientName: this.jDetail.reqPersonName,   //수신자명
            clientCall: this.jDetail.reqTel,          //수신자전번
            iqCode: this.iqCode,                      //문의번호
            alimFlag: param                           //알림유형(문의답변)
            //alimFlag: "r"
          };
          this.$api("api/kakaoAlimSend3", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          //this.$swal.fire('', '예약하신 내용의 안내가 발송되었습니다. 감사합니다.', '');
          //this.$router.push({path: '/'});
          //history.back();
        }
        // else if(param == "nonRP") 
        // {
        //   //비회원용 결제요청 카카오 알림톡
        //   let kakaoAlimData = 
        //   {
        //     //카카오 알림톡 발송(결제요청)
        //     clientName: this.reserveInfo.reqName,
        //     resCode: this.reqRsvCode,
        //     resName: this.productInfo.pdtNameKor,
        //     resPrice: this.getCurrencyFormat(this.reserveInfo.cstPrice_new),
        //     alimCall: this.reserveInfo.reqTelNo,
        //     alimFlag: "nonRP"
        //     //alimFlag: "r"
        //   };
        //   this.$api("api/kakaoAlimSend", {
        //     param: [
        //       { kakaoAlimData: kakaoAlimData},
        //     ]
        //   }).then((res) => {console.log(res)});
        //   this.$swal.fire('', '비회원용 결제요청 알림톡이 발송되었습니다', 'success');
        //   //this.$router.push({path: '/'});
        //   //history.back();
        // }
      }
    },

    async requestUpdate() {
      if(this.jDetail.passNo != "" || this.jDetail.passExpire != "") {
        this.jDetail.passFlag = "Y";
      }
      if(this.rProcCode === "") {
        this.rProcCode = this.jDetail.reqStatus;
      }
      this.jDetail.updId = this.user.handlerId;
      if(!this.respoComment_new) {
        this.respoComment_new = 
          this.jDetail.respoComment;
      }
      else{
        this.respoComment_new = 
          this.jDetail.respoComment + '\n' + "["+this.jDetail.updId +"("+ this.jDetail.respoComDate +")" +"] " +this.respoComment_new;
      }

      let resUpd_Request = await this.$api("/api/requestUpdate", 
        {param: [
          this.jDetail.reqPersonName,this.jDetail.perCnt,this.jDetail.reqEmail,this.jDetail.reqTel,this.jDetail.passFlag,this.jDetail.passExpire,this.rProcCode,this.jDetail.updId,this.jDetail.updId,this.respoComment_new,this.jDetail.passNo,this.iqCode
        ]});
      
      if(resUpd_Request.affectedRows > 0) 
      {
        this.$swal.fire('','수정완료', 'success');
        location.reload();
      }
      else {
        this.$swal.fire('','수정 중 오류가 발생했습니다.', 'error')
      }
      //console.log("resUpd_Request:",this.resUpd_Request);
    },
    goToList() {
      this.$router.push({path: '/requestList'});
    }
  }
}
</script>