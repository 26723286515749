<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>후 기 수 정</u></h2>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">후기제목<span class="text-danger">*</span></label>
        <div class="col-md-9">
          <div class="input-group">
            <input type="text" class="form-control w-75" ref="txt_nTitle" placeholder="후기제목" v-model="reviewInfo.nTitle">
          </div>
        </div>
      </div>
      <div class="mb-5 row">
        <label class="col-md-3 col-form-label">후기내용<span class="text-danger">*</span></label>
        <div class="col-md-9">
          <textarea class="form-control" placeholder="후기내용(2,000자 이내)" ref="txt_nContent" id="floatingTextarea" style="height:150px" 
            v-model="reviewInfo.nContent"></textarea>
        </div>
      </div>

      <div class="mb-3 row" v-if="this.displayFlag === 'Y'">
        <label class="col-md-3 col-form-label">리뷰이미지</label>
        <div class="col-md-9">
          <div class="form-control" type="file" accept="image/png, image/jpg, image/jpeg" :key="i" v-for="(img,i) in reviewImage.filter(c=>c.catImg=='T')">
            <div class="position-relative">
              <img v-if="this.reviewImage != undefined" :src="`/download/review/${this.revCode}/${img.imgName}`" class="img-fluid" />
              <img v-else :src="`/download/noImage/noImage.jpg`" class="d-block w-100" alt="...">
              <div>
                <p class="position-absolute top-0 end-0" style="cursor:pointer;" @click="sealingImage(img.seqImg, img.revCode)">
                  <i class="bi bi-file-excel text-warning fs-4"></i></p>
              </div>
            </div>
          </div>
          <!-- <input type="file" class="form-control" accept="image/png,image/jpeg" @change="uploadFile($event.target.files, 'T', 'Y')"> -->
          <input type="file" class="form-control" accept="image/png,image/jpeg,image/jpg" id="fileSelector">
          <div class="alert alert-secondary text-start" role="alert" style="font-size:small;">
            <ul>
              <li>등록가능: 1개까지</li>
              <li>File 사이즈: 640kb 이하</li>
              <li>File 확장자: jpg</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <div class="col">
          <button type="button" class="btn btn-outline-lg btn-success" @click="reviewUpdate();">변경</button>
          &nbsp;&nbsp;
          <button type="button" class="btn btn-outline-lg btn-danger" @click="reviewDelte();">삭제</button>
          &nbsp;&nbsp;
          <button type="button" class="btn btn-outline-lg btn-secondary" @click="goBack();">취소</button>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
//import moment from 'moment';
//import ResizeImage from 'image-resize'

export default {
  data() {
    return {
      reviewInfo: [], //리뷰정보
      revCode: "",
      // nTitle: "",
      // nContent: "",
      nUseFlag: "", //리뷰사용여부 기본값은 Y
      // insDate: "",
      // insId: "",
      // pdtCode: "", //상품코드
      addImageFlag: "N", //  이미지첨부 호출여부
      rsvCode: "", //예약번호      
      displayFlag: "Y",
      //fileList: "" //선택된 이미지파일
      reviewImage: [], //리뷰이미지
      resizedFile: {}
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  created() 
  {
    //console.log("USER:",this.user);
    this.revCode = this.$route.query.rvCode;
    this.seqNotice = this.$route.query.nSeq;
    this.seqImg = this.$route.query.iSeq;

    this.getReviewInfo();
    this.getReviewImage();
  },
  mounted() {
    // if(document.referrer == "") 
    // {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    // //비회원 접근 시 대응처리
    // if(!this.$store.state.user.email || this.$store.state.user.email == undefined) 
    // {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '',
    //     text: '로그인 후 이용해주세요.',
    //     icon: 'warning'
    //   });
    //   this.$router.push({path: '/'});
    // }
  },
  methods: {
    async getReviewInfo() 
    {
      let reviewInfo = await this.$api("/api/reviewInfo2", {param: [this.revCode, this.seqNotice]});
      //console.log("reivewInfo:",reviewInfo)
      if(reviewInfo.length > 0)
      {
        this.reviewInfo = reviewInfo[0]
      }
      //console.log("reivewInfo:",this.reviewInfo)
    },    
    async getReviewImage() 
    {
      let reviewImage = await this.$api("/api/imageList_review2", {param: [this.revCode, this.seqImg]});
      if(reviewImage.length > 0)
      {
        this.reviewImage = reviewImage
      }
      //console.log("reviewImage:",this.reviewImage)
    },
    // deleteImage(){
    //   alert("delete")
    // },
    sealingImage(seqImg, revCode) 
    {
      //console.log(`id:${pdtCode}, catImg:${catImg}, imgName:${imgName}`);
      this.$swal.fire({
        title: '확인: 미사용?',
        showCancelButton: true,
        confirmButtonText: `처리`,
        cancelButtonText: `취소`
      }).then(async (result) => {
        if (result.isConfirmed)
        {
          let sealingReviewImage = await this.$api("/api/sealingReviewImage",{param:[this.user.email, seqImg, revCode]});
          if(sealingReviewImage.affectedRows < 0 || sealingReviewImage.affectedRows === undefined)
          {
            this.$swal("","삭제처리 중 오류가 발생했습니다(코드:sealingReviewImage)","info");
            return false
          }
          else
          {
            this.$swal.fire('미사용 처리', '', 'success');
            location.reload(true)
          }
        } 
      });
    },    
    // addImage()
    // {
    //   this.displayFlag = (this.displayFlag === "N") ? "Y" : "N";
    // },
    async reviewUpdate() 
    {
      if(!this.reviewInfo.nTitle) {
        this.$refs.txt_nTitle.focus();
        //return this.$swal.fire("","제목을 입력하세요.");
        this.$swal.fire({
          position: 'top-end',
          // icon: 'warning',
          html: '<h6 class="text-danger">제목을 입력해 주세요.</h6>',
          width: 'auto',
          height:'auto',
          toast: true,
          showConfirmButton: false,
          timer: 1000
        })
        return false;
      }
      if(!this.reviewInfo.nContent) {
        this.$refs.txt_nContent.focus();
        this.$swal.fire({
          position: 'top-end',
          // icon: 'warning',
          html: '<h6 class="text-danger">내용을 입력해 주세요.</h6>',
          width: 'auto',
          height:'auto',
          toast: true,
          showConfirmButton: false,
          timer: 1000
        })
        return false;
      }
      //this.review.insDate = today;
      this.updId = this.user.email;
      //this.nUseFlag = "Y";
      //this.addImageFlag = this.displayFlag;

      //revCode, nTitle, nContent, nUseFlag, insId, insDate, updId, updDate, pdtCode, addImageFlag, rsvCode
      let res_reviewUpdate = await this.$api("/api/reviewUpdate", {param: [
        this.reviewInfo.nTitle,this.reviewInfo.nContent,this.updId,this.revCode
        ]});
      if(res_reviewUpdate.affectedRows < 0 || res_reviewUpdate.affectedRows === undefined)
      {
        this.$swal("","내용변경에 오류(코드:res_reviewUpdate)가 발생했습니다","info");
        return false
      }
      else {
        let imgFile = document.getElementById('fileSelector').files[0];

        //console.log("imageFileSize:",imgFile)
        if(imgFile != undefined && imgFile.size > 0)
        {
          //이미지 640kb 초과시 640KB로 축소업로드
          const maxFileSize = 640*1024

          if (imgFile.size > maxFileSize) 
          { // 파일 크기 확인
            this.resizeImage(imgFile)
            .then((resizedImg) => {
              //console.log("resizeImg:",resizedImg);
              let resizedImageFile = new File([resizedImg], imgFile.name)
              this.uploadFile(resizedImageFile,"T","Y")
              //this.$swal.fire('','이미지 크기가 640KB이하로 저장됩니다.', '');
              this.$swal.fire({
                position: 'top-end',
                icon: 'success',
                html: '<h6 class="text-danger">이미지가 저장되었습니다</h6>',
                width: 'auto',
                height:'auto',
                toast: true,
                showConfirmButton: false,
                timer: 1000
              })
              //history.back();
            })
            .catch((err) => {
              console.error(err);
              this.$swal.fire('','이미지 저장 중 오류가 발생했습니다.', 'warning');
              return false;
            })
          }
          else
          {
            this.uploadFile(imgFile, 'T', 'Y') //이미지파일, 카테고리, 사용여부
          }
        }
        else
        {
          this.$swal("","변경되었습니다. 감사합니다.","success");
          location.reload(true)
        }
      }


    },
    /** 이미지 크기조정 후 blob으로 리턴 */
    resizeImage(file) 
    {
      //console.log("resizing...")
      return new Promise((resolve, reject) => {
        // FileReader 객체를 사용하여 이미지 파일을 읽습니다.
        const reader = new FileReader();
        reader.onload = function (event) {
          const img = new Image();
          img.onload = function () {
            // 이미지 해상도를 조정합니다.
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;
            const maxFileSize = 640 * 1024;
            const maxSize = Math.sqrt(maxFileSize);

            if (width > maxSize || height > maxSize) {
              if (width > height) {
                height *= maxSize / width;
                width = maxSize;
              } else {
                width *= maxSize / height;
                height = maxSize;
              }
            }

            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);

            // Canvas 객체에서 Blob 객체를 생성합니다.
            canvas.toBlob(
              (blob) => {
                resolve(blob);
              },
              'image/jpeg',
              0.9
            );
          };
          img.onerror = function (err) {
            reject(err);
          };
          img.src = event.target.result;
        };
        reader.onerror = function (err) {
          reject(err);
        };
        reader.readAsDataURL(file);
      });
    },

    async uploadFile(files, catImg, useFlag)
    {
      //console.log("uploading...")
      let fileLength = 0
      let fileType_tmp = "";
      let imgName = "";
      let data = null;
      //console.log("files:",files);
      if (files) {
        fileLength = files.length;
        imgName = files.name;
        fileType_tmp = files.name.lastIndexOf(".");
        this.fileType = files.name.substring(fileType_tmp+1, fileLength).toLowerCase();
        data = await this.$base64(files);
      }
      // console.log("files:",files, "name:",imgName, "catImg:",catImg, "pdtCode:",this.productId);
      //const { error } = await this.$api(`/upload/review/${this.revCode}/${imgName}/${useFlag}`, { data });
      const { error } = await this.$api(`/upload/review/${this.revCode}/${catImg}/${imgName}/${useFlag}/${this.user.email}`, { data });
      if (error) 
      {
        return this.$swal("","이미지 저장에 실패했습니다","info");
      }

      this.$swal("","이미지가 업로드 되었습니다","");
      history.back();

      // setTimeout(() => {
      //   this.getReviewImage();
      // }, 1000);
    },
    reviewDelte()
    {
      this.$swal.fire({
        title: '후기를 삭제합니까?',
        showCancelButton: true,
        confirmButtonText: `삭제`,
        cancelButtonText: `취소`
      }).then(async (result) => {
        if (result.isConfirmed)
        {
          let reviewDelete = await this.$api("/api/reviewDelete", {param: [
            this.user.email, this.revCode
          ]});
          if(reviewDelete.affectedRows < 0 || reviewDelete.affectedRows === undefined)
          {
            this.$swal("","내용변경에 오류(코드:reviewDelete)가 발생했습니다","info");
            return false
          }
          else
          {
            this.$swal.fire('삭제되었습니다.', '', 'success');
            history.back();
          }
        } 
      });
    },
    goBack()
    {
      history.back();
    }
  }
}
</script>