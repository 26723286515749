<template>
  <div id="kinmen_bsu">
    <div class="kinmen_container">
      <div class="kinmen_main">
        <img class="kinmen_title" src="../assets/img/Kinmen/title.png" alt="">
        <img class="kinmen_main_pc" src="../assets/img/Kinmen/kinmen_main_pc.png" alt="">
        <img class="kinmen_main_mobile" src="../assets/img/Kinmen/kinmen_main_mobile.png" alt="">
        <img class="kinmen_page" src="../assets/img/Kinmen/page.png" alt="">
      </div>
      <div class="kinmen_icon_wrap">
            <img class="kinmen_hand" src="../assets/img/Kinmen/hand.png" alt="">
          <img class="kinmen_achol" src="../assets/img/Kinmen/achol.png" alt="">
          <img class="kinmen_car" src="../assets/img/Kinmen/place_car.png" alt="">
          </div>
    </div>
    <div class="kinmen_button">
      <a href="javascript:void(0)" @click="goToDetail('L-202493-1725333049766','L')">예약하기</a>
    </div>
    <div id="kinmen_gogo" @click="goToDetail('L-202493-1725333049766','L')" >상품 바로가기</div>
  </div>
</template>

<script>
export default {
  methods: {
    goToDetail(productId, salesRoute) {
      this.$router.push({path: '/detail', query: {productId: productId, salesRoute:salesRoute}});
    }
  },
  mounted() {
    let timeout;
        let lastScrollTop = 0;

        window.addEventListener('scroll', function() {
            const element = document.getElementById('kinmen_gogo');
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            // 스크롤 방향에 따라 요소 보이기/숨기기
            if (scrollTop > lastScrollTop) {
                // 아래로 스크롤
                element.style.opacity = '1';
            } else {
                // 위로 스크롤
                element.style.opacity = '11';
            }

            // 스크롤이 멈춘 경우 2초 후에 사라지게 설정
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                if (scrollTop < 13200) {
                    element.style.opacity = '0';
                }
            }, 2000);

            // 13200px 이상일 경우 요소 숨기기
            if (scrollTop >= 13200) {
                element.style.opacity = '0';
            }

            lastScrollTop = scrollTop; // 마지막 스크롤 위치 업데이트
        });
  }
}
</script>
<style>
#kinmen_bsu{
  margin-top: -56px;
}
.kinmen_container{
  background-color: rgb(240, 213, 179);
  
}
.kinmen_main{
  width: 100%;
  background: url(../assets/img/Kinmen/kinmen_bg.png)top;
  
}
.kinmen_title{
  position: absolute;
  top: 50%;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
  width: 45%;
}
.kinmen_main_pc{
  width: 100%;
}
.kinmen_main_mobile{
  display: none;
}
.kinmen_page{
  margin: 0 auto;
  display: block;
}
.kinmen_icon_wrap{
  width: 1200px;
  margin: 0 auto;
  height: 2740px;
  position: absolute;
  top: 3990px;
  left: 50%;
  right: 50%;
  transform: translate(-50%,-50%);
}
#kinmen_gogo{
  position: fixed;
  cursor: pointer;
            bottom: 20px;
            left: 20px;
            background: brown;
            color: white;
            padding: 10px;
            border-radius: 5px;
            transition: opacity 0.3s;
            opacity: 1; /* 초기 상태는 보이도록 설정 */
            font-weight: bold;
            font-size: 25px;
            padding: 30px 70px; /* 패딩 */
            transition: all 0.5s;
}
#kinmen_gogo a{
  color: #fff;
}

#kinmen_gogo:hover{
  background-color: rgb(100, 17, 17);
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, #000000de);
}
.kinmen_hand{
position: absolute;
left: 11%;
animation: hand 1s infinite;
transform-origin: bottom;
}
@keyframes hand{
  0%{rotate: 0deg}
  50%{rotate: 20deg}
 100%{rotate: 0deg}
}
.kinmen_achol{
  position: absolute;
  right: 15%;
  animation: hand 1s infinite;
}
.kinmen_car{
  position: absolute;
  bottom: 0;
  right: 5%;
  animation: car 3s infinite;
}
@keyframes car{
  0%{right:5%}
  50%{right:10%}
  100%{right:5%}
}
.kinmen_button a{
  display: block;
  width: 250px;
  height: 70px;
  background-color: brown;
  margin: 30px auto;
  text-align: center;
  padding-top: 20px;
  font-weight: bold;
  border-radius: 10px;
  transition: all 0.5s;
}
.kinmen_button a:hover{
  background-color: rgb(100, 17, 17);
}
.kinmen_button a{
  color: #FFf;
}
@media(max-width:7680px) and (min-width:3841px){
  .kinmen_icon_wrap{
  height: 2740px;
  top: 6880px;
}
}
@media(max-width:3840px) and (min-width:2561px){
  .kinmen_icon_wrap{
  height: 2740px;
  top: 4720px;
}
}
@media(max-width:1920px) and (min-width:768px){
  .kinmen_icon_wrap{
  height: 2738px;
  top: 3630px;
}
}
@media(max-width:767px){
  #kinmen_gogo{
    display: none;
  }
  .kinmen_title{
    width: 95%;
    top: 33%;
  }
  .kinmen_main_mobile{
    display: block;
    margin-top: 60px;
    width: 100%;
  }
  .kinmen_main_pc{
    display: none;
  }
  .kinmen_page{
  width: 95%;
}
.kinmen_icon_wrap{
  display:none;
}
.kinmen_hand{
  display:none;
}
.kinmen_achol{
  display: none;
}
.kinmen_car{
  display: none;
}
.kinmen_gogobutton a{
  display: none;
}
}

</style>
