<template>
  <div id="member">
    <section>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-xs-12">
            <div class="page-login-form">
              <h3>간 편 회 원 가 입</h3>
              <p class="text-center text-muted">회원정보를 확인하신 후, <br>가입완료를 해주세요.</p>

              <div class="container mt-5">
                <div style="font-weight: 600;font-size: 1.1rem;">
                  <i class="bi bi-person-lines-fill text-primary"></i>&nbsp;&nbsp; 회 원 가 입 정 보
                  <hr class="mt-0">
                </div>
                <table class="table userTable">
                  <tr>
                    <th>아이디</th>
                    <td>{{ temp_userInfo.uEmail }}</td>
                  </tr>
                  <tr>
                    <th>성명</th>
                    <td>{{ temp_userInfo.uNickName }}</td>
                  </tr>
                  <tr>
                    <th>성별</th>
                    <td v-if="temp_userInfo.uGender === 'M'">남성</td>
                    <td v-else-if="temp_userInfo.uGender === 'F'">여성</td>
                    <td v-else>비공개</td>
                  </tr>                  
                  <tr>
                    <th>생년월일</th>
                    <td>{{ temp_userInfo.uBirthyear }} {{ temp_userInfo.uBirthday }}</td>
                  </tr>
                  <tr>
                    <th>연락처</th>
                    <td>{{ temp_userInfo.uPhone }}</td>
                  </tr>
                </table>

                <!-- 동의내용 -->
                <div class="mterms">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="this.allConfirmFlag" ref="allConfirmFlag" @change="checkAllConfirm()">
                    <label class="form-check-label" for="defaultCheck">
                      <p>전체동의</p>
                    </label>
                  </div>
                  
                  <hr style="border-top:1px solid; opacity:0.3; margin:0">
                      
                  <div class="accordion accordion-flush" id="accordionExample">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingThree">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="confirm_Info" ref="confirm_Info">
                          <label class="form-check-label" for="defaultCheck3">
                            <span class="check">[필수]</span>개인정보 수집 및 이용동의
                          </label>
                        </button>
                      </h2>
                      <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                          엔타비 글로벌 (이하 '엔타비')는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.<br>

                          1. 개인정보 수집 및 이용목적<br>
                          엔타비는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.<br>
                          &nbsp; 1) 홈페이지 회원가입 및 관리<br>
                          &nbsp; 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.<br>
                          &nbsp; 2) 서비스 제공<br>
                          &nbsp;  물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산 등을 목적으로 개인정보를 처리합니다.<br>
                          &nbsp; 3) 마케팅 및 광고에의 활용<br>
                          &nbsp; 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.<br>
                          <br>
                          2. 개인정보 수집 항목<br>
                          엔타비는 다음의 개인정보 항목을 처리하고 있습니다.<br>
                          &nbsp; 1) 홈페이지 회원가입 및 관리/마케팅 정보 제공의 목적 (회원탈퇴 시 까지)<br>
                          &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름<br>
                          &nbsp; 2) 상담 및 해외 및 국내 여행 예약의 목적 (신용카드정보, 은행계좌정보)<br>
                          &nbsp;  이메일, 휴대전화번호, 자택주소, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 여권사본, 법정대리인 이름, 법정대리인 자택 전화번호, 법정대리인 자택 주소, 법정대리인 휴대전화번호<br>
                          &nbsp; 3) 여행자 보험가입의 목적 (신용카드정보, 은행계좌정보)<br>
                          &nbsp;  이메일, 휴대전화번호, 자택주소, 성별, 생년월일, 이름<br>
                          &nbsp; 4) 대금결제의 목적 (이용목적이 달성되면 파기)<br>
                          &nbsp;  신용카드정보, 은행계좌정보<br>
                            <br>
                          3. 개인정보 수집 항목 보유 및 이용기간<br>
                          &nbsp; 1) 엔타비는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.<br>
                          &nbsp; 2) 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br>
                          &nbsp;  ① 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년<br>
                          &nbsp;  ② 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년<br>
                          &nbsp;  ③ 대금결제 및 재화 등의 공급에 관한 기록 : 5년<br>
                          &nbsp;  ④ 계약 또는 청약철회 등에 관한 기록 : 5년<br>
                          &nbsp;  ⑤ 표시/광고에 관한 기록 : 6개월<br>
                        </div>
                      </div>
                    </div>

                    <div class="option">
                      <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="confirm_spTerms" ref="confirm_spTerms">
                      <label class="form-check-label" for="defaultCheck1">
                        <span class="check">[필수]</span>만14세 이상 확인
                      </label>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="confirm_Terms" ref="confirm_Terms">
                          <label class="form-check-label" for="defaultCheck2">
                            <span class="check">[필수]</span>서비스 이용약관 동의
                          </label>
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      </div>
                    </div>

                    <div class="option">
                      <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="confirm_Privacy" ref="confirm_Privacy">
                      <label class="form-check-label" for="defaultCheck4">
                        <span class="nocheck">[선택]</span>마케팅 정보 수신 동의
                      </label>
                    </div>

                    <div class="option last">
                      <input class="form-check-input" type="checkbox" true-value="Y" false-value="N" v-model="confirm_3rd" ref="confirm_3rd">
                      <label class="form-check-label" for="defaultCheck5">
                        <span class="nocheck">[선택]</span>SNS 발송 동의
                      </label>
                    </div>

                  </div>
                </div>

                <div class="row justify-content-center">
                  <div class="col-lg-12 d-grid">
                    <button type="button" class="btn go-member" @click="joinMember()">가입완료</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { handlerInfo } from '../assets/js/commonFunctions'

export default {
  data() {
    return {
      temp_userInfo: [],           //간편회원가입용 정보

      //약관동의
      allConfirmFlag: "",   //동의체크-전체선택
      confirm_Terms: "",    //서비스이용약관동의
      confirm_spTerms: "",  //만14이상확인동의
      confirm_Info: "",     //개인정보수집이용동의
      confirm_Privacy: "",  //마케팅정보수신동의
      confirm_3rd: "",      //SNS발솧동의

      loginInfo: [],
      loginParam: "",
      checkRes: "",

      handTel: [],           //매니저연락처
      handTelCnt: 0          //수신처갯수
    }
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      return this.$store.state.user;
    },
    //넘어온 params정보
    uNo() {
      return this.$route.params.uNo;
    }, 
  },
  created(){
    this.getHandlerInfo()
    let userNo = this.$route.params.uNo
    //console.log("userNo:", this.userNo)

    this.getTempUserInfo(userNo)
  },
  mounted(){
    //document.getElementById("recent-item").style.display = "none";
    ////document.getElementById("notice").style.display = "none";

  },
  methods: {
    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },    
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },

    async getTempUserInfo(val){
      let res_temp_userInfo = await this.$api("/api/res_temp_userInfo", { param: [val] })

      if(res_temp_userInfo.length > 0)
      {
        this.temp_userInfo = res_temp_userInfo[0]
      }
      //console.log("temp_userInfo:", this.temp_userInfo)
    },
    //전체약관동의
    checkAllConfirm()
    {
      if(this.allConfirmFlag === "Y")
      {
        this.confirm_Terms = "Y" //약관동의
        this.confirm_spTerms = "Y"
        this.confirm_Info = "Y"
        this.confirm_Privacy = "Y"
        this.confirm_3rd = "Y"
      }
      else if(this.allConfirmFlag === "N")
      {
        this.confirm_Terms = "N" //약관동의
        this.confirm_spTerms = "N"
        this.confirm_Info = "N"
        this.confirm_Privacy = "N"
        this.confirm_3rd = "N"
      }      
    },

    async getHandlerInfo()
    {
      let handTel = await handlerInfo(this.$api);
      if(handTel.length > 0)
      {
        this.handTel = handTel
      }
    },

    //회원가입
    async joinMember(){
      //필수약관동의체크
      if(this.confirm_Terms != "Y")
      {
        this.$refs.confirm_Terms.focus()
        this.$swal.fire('', '서비스약관에 동의해주세요', 'warning')
        return false;
      }
      if(this.confirm_spTerms != "Y")
      {
        this.$refs.confirm_spTerms.focus()
        this.$swal.fire('', '만 14세 이상확인에 동의해주세요', 'warning')
        return false;
      }
      if(this.confirm_Info != "Y")
      {
        this.$refs.confirm_Info.focus()
        this.$swal.fire('', '개인정보 수집 및 이용동의에 동의해주세요', 'warning')
        return false;
      }
      
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm_tmp = today.getMonth()+1;
      let dd_tmp = today.getDate();
      let hour = today.getHours();
      let min = today.getMinutes();
      let sec = today.getSeconds();
      
      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;
      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;
      
      let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
      let joinDate = `${yyyy}-${mm}-${dd}`
      let naverAccount1 = {
        email:    this.temp_userInfo.uEmail,
        birthday: this.temp_userInfo.uBirthday,
        name:     this.temp_userInfo.uNickName,
        mobile:   this.temp_userInfo.uPhone,
        joinFlag: "N",
        birthyear: this.temp_userInfo.uBirthyear,
        insDate: insDate,
        joinDate: joinDate
        //userNo: userNo
      };
      await this.$api("/api/login", {
      // 라우터에서 sql호출시, 파라메타를 같이 넘긴다
      // 처음 파라메터: 기존여부체크용, 두번째 파라메터: 업데이트용
      param: [
        {
          uEmail:     naverAccount1.email,
          uBirthday:  naverAccount1.birthday,
          uNickName:  naverAccount1.name,
          alimCallC:  naverAccount1.mobile,
          uPhone:     naverAccount1.mobile,
          joinFlag:   naverAccount1.joinFlag,
          insDate:    naverAccount1.insDate,
          uBirthyear: naverAccount1.birthyear,
          //userNo: naverAccount1.userNo
        },
        { uNickName: naverAccount1.name },
      ]
      })
      .then(async (res) => {
        //console.log("result:",res);
        if(res.uEmail != undefined)
        {
          // login으로 넘어온 유저정보를 store쪽으로
          // let res_membershipInfo = []
          // res_membershipInfo = await this.membershipCheck(res.uEmail)

          let naverAccount2 = {}
          
          naverAccount2 = {
            email:    naverAccount1.email,
            birthday: naverAccount1.birthday,
            name:     naverAccount1.name,
            mobile:   naverAccount1.mobile,
            joinFlag: "N",
            insDate:  insDate,
            //joinDate: naverAccount1.joinDate
            // memLevel: res_membershipInfo.memLevel, 
            // memNo:    res_membershipInfo.memNo, 
            // memExpDate:res_membershipInfo.expDate
          }
          this.$store.commit("user", naverAccount2);
          localStorage.setItem('sessionExpiration', new Date().getTime() + 60 * 60 * 1000);
          
          //회원가입안내
          this.sendKakao_join("JO", naverAccount1.joinDate, naverAccount2.email)
          
          //location.href = "/"                  //인증절차 완료 후, 완전한 페이지 리로드
          //this.$router.push({ path: "/" }); //vue컴포넌트만 새로고침
        }
        else
        {
          this.$swal.fire("","로그인이 되지 않았습니다. 재시도해주세요","error");
          location.href = "/"
        }
      })
    },

    /** 카카오알림 발송(회원가입안내) */
    async sendKakao_join(param, jDate, uEmail){
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: 
      [
        uEmail, param, this.temp_userInfo.uPhone
      ]});

      //알림톡 발송대상 확정
      this.handTel.push({aimCall: this.temp_userInfo.uPhone})
      this.handTelCnt = this.handTel.length
      //console.log("handTel: ", this.handTel, "/ Length: ", this.handTelCnt)

      if(insKSendHistory.affectedRows > 0) 
      {
        if(param == "JO") 
        {
          let i = 0
          while(i <this.handTelCnt)
          {
            let kakaoAlimData = 
            {
              //카카오 알림톡 발송(가입환영)
              clientName: this.temp_userInfo.uNickName,
              clientCall: this.handTel[i].aimCall,
              joinDate: jDate,
              resCode: '',
              alimFlag: param //알림유형(인증번호발송)
            };
            this.$api("api/kakaoAlimSend5", {
              param: [
                { kakaoAlimData: kakaoAlimData},
              ]
            }).then((res) => {console.log(res)});
            i++
          }
          this.$swal.fire('', '가입확인안내가 발송되었습니다.', 'success').then(() => {
            location.href = "/"
          })
        }
      }
    },
  }
}
</script>



<style>
.userTable th {
  background-color: lightgray;
  width: 40%;
  height: 45px;
  text-align: center;
  border: 2px solid white;
}
.userTable td {
  text-align: center;
  font-weight: 600;
  text-align: start;
  padding-left: 15px;
}




#member{
  margin:6rem 0
}
#member h3 {
  color:#000;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom:1.5rem;
  text-transform: uppercase;
  text-align: center;
}



/*--------------------------------------------------------------
	# 내용
--------------------------------------------------------------*/
#member .form-control{
  border-radius: 0;
  border-color: #777;
  margin:.5rem 0
}
#member .form-floating>label{
  padding:1.4rem 0.75rem;
  color:#999
}

#member .bcheck{
  width:100%; 
  padding:1rem 0;
  border:1px solid #000;
  background-color: #000;
  color:#fff
}


/* 동의내용 */
#member .mterms{
  border:1px solid darkgray;
  padding:5px
}
#member .mterms .form-check{
  margin:7px 0 0 20px
}
#member .mterms .form-check p{
  font-size:1.0rem;
  font-weight: 600;
  color:#000;
  margin-top:-4px
}
#member .mterms .accordion-item{
  border:none
}
#member .mterms .accordion-body{
  font-size:.85rem
}
.no:after{
  opacity: 0;
}
#member .mterms .check{
  padding:0 5px 0 10px;
  font-weight: 600;
  color:#f00
}
#member .mterms .nocheck{
  padding:0 5px 0 10px;
  color:#777
}
#member .mterms .option{
  margin:10px 0 10px 20px
}
#member .mterms .last{
  margin-top:25px
}
</style>
