<template>
  <div>
    <!----------------- Main contents Area: 카카오페이결제--------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="alert alert-danger" role="alert">
          ※ 결제가 되었습니다. 최종확인 후, 확정처리됩니다. ※
        </div>
        <!-- <div>
          <h1>결제 완료</h1>
          <h2>결제 정보</h2>
          <p>주문번호: {{ order_id }}</p>
          <p>상품명: {{ item_name }}</p>
          <p>결제 금액: {{ amount }}원</p>
          <p>결제 수단: {{ method }}</p>
          <p>승인일시: {{ approved_at }}</p>
        </div> -->
      </div>
    </main>    
  </div>
</template>

<script>
import axios from 'axios';
//import { getPayDetailInfo } from '../assets/js/commonFunctions.js';

export default {
  name: 'Success_kPay',
  data() {
    return {
      kPayParams: {}, // 카카오페이 파라미터
      order_id: '',
      item_name: '',
      amount: '',
      method: '',
      approved_at: '',
      payDetailInfo: [] //결제상세정보
    };
  },
  created() {
    this.user = this.$store.state.user;
    this.pg_token = this.$route.query.pg_token;

    if (this.$store.state.user.handlerId != undefined) {
      this.userInfo = this.$store.state.user.handlerId;
    }
    else if (this.$store.state.user.email != undefined) {
      this.userInfo = this.$store.state.user.email;
    }
    else {
      this.userInfo = "";
    }
    console.log("userInfo:", this.userInfo);
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  async mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    //this.getPaymentInfo();

    //결제승인 api호출
    this.tid = localStorage.getItem("tid")
    this.partner_order_id = localStorage.getItem("partner_order_id")
    this.total_amount = localStorage.getItem("total_amount")

    if(this.pg_token && this.tid)
    {
      try
      {
        let approveResponse = await axios.post("/api/kakaoPay/approve",
        {
          tid: this.tid,
          pg_token: this.pg_token,
          partner_order_id: this.partner_order_id,
        })
        console.log("approveResponse:",approveResponse.data)
        this.changeFlag(this.partner_order_id)
      }
      catch(error)
      {
        console.log(error)
      }
    }

  },
  // unmounted() {
  //   this.$el.removeEventListener('click', this.test());
  // },
  methods: 
  {
    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },    
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },    
    async changeFlag(oId)
    {
      let orderId = oId
      //return await this.$api("/api/getReqPayInfo", {param: [this.reqRsvCode]});
      await this.$api("/api/upd_UsePoint", {param: [this.userInfo, oId]});
      let updCheckFlag = await this.$api("/api/updCheckFlag", {param: ['Y', this.userInfo, this.total_amount, 'KP', oId]});
      if(updCheckFlag.affectedRows > 0) 
      {
        this.getPayDetailInfo(orderId);
        this.$swal.fire('','결제되었습니다. 감사합니다,', 'success');
        //location.reload();
      }
      else 
      {
        this.$swal.fire('','결제확인(updCheckFlag)이 되지 않습니다.<br>문의처(1660-4602)에 확인을 요청해주세요.', 'error')
      }
    },
    async getPayDetailInfo(orderId)
    {
      let payDetailInfo = await this.$api("/api/payDetailInfo", {param: [orderId]});
      if(payDetailInfo.length > 0)
      {
        this.payDetailInfo = payDetailInfo[0];
        console.log("payDetailInfo:", this.payDetailInfo)
        //결제완료 카톡안내
        this.sendKakao("PY");
      }
    },

    /** 카카오알림 발송 */
    async sendKakao(param)
    {
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        this.partner_order_id, param, this.userInfo
      ]});
      // #{clientName}님, 감사합니다!
      // 여행금액 결제가 완료되었습니다.
      // 행복하고 즐거운 여행 되세요^^
      // - 예약상품: #{resName}
      // - 예약자명: #{resPaxName}
      // - 여행인원: #{resPaxCnt}
      // - 여행일자: #{dptDate}
      // - 고객연락처: #{resPhone}
      // - 예약번호: #{resCode}
      // - 결제금액: #{resPayAmount}
      // - 담당자: #{resManager} 매니저
      // - 예약확인: #{resCheck}
      if(insKSendHistory.affectedRows > 0) {
        if(param == "PY") 
        {
          //결제요청 카카오 알림톡
          let kakaoAlimData = 
          {
            //카카오 알림톡 발송(결제완료)
            clientName: this.payDetailInfo.clientName,
            resName: this.payDetailInfo.resName,
            resPaxName: this.payDetailInfo.resPaxName,
            resPaxCnt: this.payDetailInfo.resPaxCnt,
            dptDate: this.payDetailInfo.dptDate,
            resPhone: this.payDetailInfo.resPhone,
            resCode: this.partner_order_id,
            resPayAmount: this.getCurrencyFormat(this.payDetailInfo.resPayAmount),
            resManager: this.payDetailInfo.resManager,
            alimFlag: param //알림유형(결제요청)
          };
          this.$api("api/kakaoAlimSend2", {
            param: [
              { kakaoAlimData: kakaoAlimData},
            ]
          }).then((res) => {console.log(res)});
          this.$swal.fire('', '결제완료 알림톡이 발송되었습니다', 'success');
          this.$router.push({path: '/'});
          //history.back();
        }
      }
    },    
  }
}
</script>