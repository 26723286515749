<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="alert alert-warning" role="alert">
          <b class="fs-4">지 출 항 목 관 리</b>
        </div>
        <div class="text-end mb-2">
          <button type="button" class="btn btn-warning" @click="showRegistExpInfo()">신규등록</button>
        </div>
        <table class="table table-bordered table-striped fs-6" v-if="this.createFlag == 'Y'">
          <thead class="small">
            <tr>
              <th style="width:90px;">지출분류</th>
              <th style="width:90px;">코드<font class="text-danger"> (중복불가)</font></th>
              <th style="width:120px;">항목명<font class="text-danger"> (중복불가)</font></th>
              <th style="width:200px;">비고사항</th>
              <th style="width:50px;">-</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr>
              <td>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="pCategory" v-model="this.pCategory">
                  <option :value="c.procCode" :key="i" v-for="(c,i) in this.expCatList">{{ c.procName }}</option>
                </select>
              </td>
              <td class="text-secondary">
                {{ this.procCode }}
              </td>
              <td>
                <input type="text"  class="text-success" v-model="this.pName" size="26" style="font-size: smaller" maxlength="40" ref="pName" placeholder="최대20자">
              </td>
              <td>
                <textarea class="form-control" placeholder="300자 이내" ref="spRemarks" id="floatingTextarea" style="height:15px; width:400px; font-size: small;" v-model="this.pRemarks" maxlength="300"></textarea>
              </td>
              <td>
                <!-- me-1: rigt margin1 -->
                <button type="button" class="btn btn-outline-warning btn-sm" @click="registExpInfo()">등록</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt-2 mb-2 align-middle fs-4">
          <i class="bi bi-arrow-down-square-fill text-secondary"  v-if="this.createFlag == 'Y'"></i>
        </div>        
        
        <div class="text-success text-start fs-6">
          <b>[ &nbsp; 지출항목리스트 &nbsp; ]</b>
        </div>
        <table class="table table-bordered table-striped fs-6 align-item-center">
          <thead class="small">
            <tr>
              <th style="width:40px;">
                <input class="form-check-input" type="checkbox" v-model="allSelected" @change="allChoice()" disabled />
              </th>
              <th class="col-2">분류</th>
              <th class="col-3">항목명</th>
              <th class="col-1">사용</th>
              <th class="col-5">비고사항</th>
              <th class="col-2">-</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr :key="i" v-for="(m, i) in expenditureInfo">
              <td>
                <input type="checkbox" v-model="m.checkFlag"  ref="chb_Seq" class="align-baseline mt-3" :id=i name="checkedBox" disabled>
              </td>
              <td>
                <select class="form-select form-select-sm" style="font-size: smaller" aria-label=".form-select-sm" ref="pCategory" v-model="m.expCategory">
                  <option :value="c.procCode" :key="i" v-for="(c,i) in this.expCatList">{{ c.procName }}</option>
                </select>
              </td>
              <td class="form-control-sm">
                <input type="text" v-model="m.expName" size="20" style="font-size: smaller">
              </td>
              <td>
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_pUseFlag" v-model="m.expUseFlag">
                  <option value="Y" class="text-success">Y</option>
                  <option value="N" class="text-danger">N</option>
                </select>
              </td>              
              <td>
                <textarea class="form-control-sm" ref="pRemarks" id="floatingTextarea" style="height:10px; width: 300px; font-size: smaller;" v-model="m.expRemarks" maxlength="300" placeholder="최대 300자"></textarea>
              </td>
              
              <td>
                <button type="button" class="btn btn-outline-primary btn-sm" style="font-size: small;" @click="changeStatus(m.expCategory,m.expName,m.expUseFlag,m.expRemarks,m.expCode)">변경</button> &nbsp;
                <button type="button" class="btn btn-outline-danger btn-sm" style="font-size: small;" @click="memWithdrawal(m.expCode)">중지</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
import { generateRandomCode } from '../assets/js/commonFunctions'

export default {
  // DB에서 가져온 데이타
  data() {
    return {
      expCatList: [],   //지출분류리스트

      //지출항목생성
      pCode: "",       //지출항목코드
      pCategory: "",   //지출항목카테고리
      pName: "",       //지출항목명
      pUseFlag: "",    //거래유무
      insId: "",
      insDate: "",
      pRemarks: "",

      expenditureInfo: [], //지출항목정보

      //지출항목등록란 표시플래그
      createFlag: "N",


      partnerInfo: [],  //거래처정보
      allSelected: "N", //전체체크여부
      

      categoryInfo: [], //거래처카테고리
      countryInfo: [],  //국가리스트

    };
  },
  created() {
    //this.productId = this.$route.query.productId;
    this.user = this.$store.state.user;

    this.getCategoryList();
    this.getExpList();

  }, 
  // filters: {
  //   currency: function(val) {
  //     var num = new Number(val);
  //     return num.toFixed(0).replacereplace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //   }
  // },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    ////document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    //카테고리정보
    async getCategoryList() 
    {
      let expCatList = await this.$api("/api/reqStatusInfo2", {param: ['EXP', '%']});
      if(expCatList.length > 0)
      {
        this.expCatList = expCatList
      }
    },

    showRegistExpInfo() {
      this.generateRandomCode();
      this.createFlag = (this.createFlag === "Y") ? "N" : "Y";

      this.pCategory = ""
      this.pName = ""
      this.pRemarks = ""
    },

    //중복되지 않는 항목코드 생성
    async generateRandomCode()
    {
      let char = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let gNo = '3';
      let today = new Date();
      let timeStamp = today.getTime()

      let procCode_tmp = await generateRandomCode(this.$api, char, gNo);
      this.procCode = procCode_tmp + timeStamp;
    },    

    //지출항목등록
    async registExpInfo() {
      //console.log("pCat:", this.pCategory)
      if(!this.pCategory) 
      {
        this.$refs.pCategory.focus();
        return this.$swal.fire("","지출분류를 선택하세요.","warning");
      }

      if(!this.pName) 
      {
        this.$refs.pName.focus();
        return this.$swal.fire("","지출항목명을 입력하세요.","warning");
      }
      
      let insId = this.user.handlerId;
      let pUseFlag = "Y"

      let ins_expenditureInfo = await this.$api("/api/ins_expenditureInfo", {param: 
      [
        this.procCode, this.pName, this.pCategory, pUseFlag, this.pRemarks, insId
      ]});

      if(Object.keys(ins_expenditureInfo)[0] === 'error') {
        this.$swal.fire('','등록실패('+ ins_expenditureInfo.error.sqlMessage +')', 'error')
      }
      else {
        this.$swal.fire('','항목이 등록되었습니다', 'success');
        location.reload();
      }
    },

    //지출항목리스트
    async getExpList() {
      let expenditureInfo = await this.$api("/api/expenditureInfo", {});

      if(expenditureInfo.length > 0)
      {
        this.expenditureInfo = expenditureInfo
      }
      //console.log("expenditureInfo:",this.expenditureInfo);
    },






    
    allChoice() 
    {
      if(this.allSelected)
      {
        this.memberInfo.forEach((uNickName) => {
          uNickName.checkFlag = true;
        })
      }
      else
      {
        this.memberInfo.forEach((uNickName) => {
          uNickName.checkFlag = false;
        })
      }
    },


    //거래처정보수정
    changeStatus(expCategory,expName,expUseFlag,expRemarks,expCode) 
    {
      console.log(expCategory,expName,expUseFlag,expRemarks,expCode)
      //변경전 확인
      this.$swal.fire({
        title: '지출항목정보를 변경합니까?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let upd_expenditureInfo = await this.$api("/api/upd_expenditureInfo", {param: [
            expName,expCategory,expUseFlag,expRemarks,this.user.handlerId,expCode
          ]});
          console.log("upd_expenditureInfo:",upd_expenditureInfo)
          // 상태변경 후, 갱신
          if(upd_expenditureInfo.affectedRows > 0)
          {
            this.$swal.fire('', '변경완료', 'success')
            location.reload();
          }
          else
          {
            this.$swal.fire('', '오류가 발생했습니다(코드:upd_expenditureInfo)', 'warning')
            return false;
          }
        }
      })
    },
    //거래중지
    memWithdrawal(pCode) 
    {
      //console.log(uBirthday, uNickName, alimCallC, uPhone, uGender, uPass, uEmail);
      //변경전 확인
      this.$swal.fire({
        html: '거래처관계를 종료합니까?',
        showCancelButton: true,
        confirmButtonText: '종료',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let del_partnerInfo = await this.$api("/api/del_partnerInfo", {param: [this.user.handlerId, pCode]});
          // 상태변경 후, 갱신
          if(del_partnerInfo.affectedRows > 0)
          {
            this.$swal.fire('', '처리완료', 'success')
            location.reload();
          }
          else
          {
            this.$swal.fire('', '오류가 발생했습니다(코드:del_partnerInfo)', 'warning')
            return false;
          }
        }
      })
    },
  }
}
</script>