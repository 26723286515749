<template>
  <div id="nbus_page">
    <section> 
      <!-- 메인 -->
      <div class="msmain">
        <div class="container" style="height:0px;">
        </div>
      </div>

      <!-- NBus -->
      <div class="benefit mt-0">
        <!-- 화면가림스크린 -->
        <div class="overlay" v-if="showOverlay">
          <div class="overlay-text">Coming Soon...</div>
        </div>

        <div class="container">
          <div class="b-cont">
          <!-- 상품별 후기리스트 -->
          <div class="container">
            <div v-if="this.reviewList.length > 0">
              <div class="row" :key=i v-for="(rl, i) in this.reviewList">
                <div class="col-lg-3 mt-2">
                  <img v-if="!rl.imgName || rl.iUseFlag == 'N'" :src="`/download/noImage/noImage2.jpg`" class="img-fluid" style="width: 250px; height:200px; border: 1px htgrey;" title="리뷰어의 이미지가 없습니다" />
                  <img v-else :src="`/download/review/${rl.revCode}/${rl.imgName}`" class="img-fluid" style="width: 250px; height:200px" />
                </div>
                <div class="c-content col-lg-9 text-start align-self-center">
                  <div class="reviewt d-inline-block text-truncate" style="max-width: 330px;" v-if="rl.insId === this.user.email">
                    {{ rl.nTitle }}&nbsp;
                    <button type="button" class="btn btn-outline-secondary btn-sm me-1 align-top" @click="changeReview(rl.revCode, rl.seqNotice, rl.seqImg);">
                      <span style="font-size:small">변경</span>
                    </button>
                  </div>
                  <div class="reviewt d-inline-block text-truncate" style="max-width: 330px;" v-else>{{ rl.nTitle }}</div>
                  <div class="reviewn text-end">
                    <span class="name">{{ rl.rId.substring(0,3) }}○○○님</span>
                    <span class="date">{{ this.getDateFormat(rl.rDate) }}</span>
                  </div>
                  <div class="reviewc">
                    <pre class="reviewContent">{{ rl.nContent }}</pre>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row">
                <div class="col-lg-3 mt-2 text-center">
                  <!-- 후기가 없다 -->
                </div>
              </div>
            </div>
          </div>            
            <!-- 타이틀 --> 
            <div class="text-center">
              <!-- <span style="font-size: 3rem; font-weight: bolder; color:white;">Nbus</span> -->
              <img src="../assets/img/nbus/nbus_trans.png" alt="엔버스로고" class="nbusLogo">
              <p class="mt-1 nbusSlogan">손쉬운 일본 버스대절 서비스</p>
            </div>

            <!-- 내용 -->
            <div class="row justify-content-center">
              <div class="col-12 col-lg-6  nBusDetail">
                <div class="input-group b-input">
                  <span class="input-group-text sub-title">탑승일</span>
                  <input type="date" class="form-control form-control text-center" placeholder="시작일" v-model="rentStartDate" ref="rentStartDate" style="font-size: small;width:20%">
                  <span class="input-group-text sub-title">사용일</span>
                  <select id="startTime" v-model="rentTotDays" class="form-select text-center" style="width:8%">
                    <option v-for="i in 10" :key="i">{{ i }}</option>
                  </select>
                </div>
                <p class="text-start underTitle01">※ 1일(09:00 ~ 18:00)기준입니다</p>

                <div class="input-group b-input mt-4 mb-5">
                  <span class="input-group-text text-center sub-title">탑승인원</span>
                  <input type="number" class="form-control form-control-lg text-end" min="0" max="1000" placeholder="미정인 경우, 입력 불필요" v-model="personCnt" ref="personCnt">
                </div>

                <div :key="j" v-for="j in rentTotDays*1">
                  <span class="text-start skdDay">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-bus-front text-secondary" viewBox="0 0 16 16">
                      <path d="M5 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0m8 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-6-1a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2zm1-6c-1.876 0-3.426.109-4.552.226A.5.5 0 0 0 3 4.723v3.554a.5.5 0 0 0 .448.497C4.574 8.891 6.124 9 8 9s3.426-.109 4.552-.226A.5.5 0 0 0 13 8.277V4.723a.5.5 0 0 0-.448-.497A44 44 0 0 0 8 4m0-1c-1.837 0-3.353.107-4.448.22a.5.5 0 1 1-.104-.994A44 44 0 0 1 8 2c1.876 0 3.426.109 4.552.226a.5.5 0 1 1-.104.994A43 43 0 0 0 8 3"/>
                      <path d="M15 8a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1V2.64c0-1.188-.845-2.232-2.064-2.372A44 44 0 0 0 8 0C5.9 0 4.208.136 3.064.268 1.845.408 1 1.452 1 2.64V4a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v3.5c0 .818.393 1.544 1 2v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5V14h6v1.5a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2c.607-.456 1-1.182 1-2zM8 1c2.056 0 3.71.134 4.822.261.676.078 1.178.66 1.178 1.379v8.86a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 11.5V2.64c0-.72.502-1.301 1.178-1.379A43 43 0 0 1 8 1"/>
                    </svg> {{ j }} 일차 <span style="font-weight: 600;"></span>&nbsp;
                    <spqan style="font-size:medium;vertical-align: bottom;" v-if="j > 1">
                      (동일한 일정 <input class="form-check-input" type="checkbox" :id="j" true-value="Y" false-value="N" @change="makeSame($event, j)">)
                    </spqan>
                  </span>
                  <div class="input-group mt-0 b-input mt-2">
                    <input type="text" class="form-control form-control" placeholder="출발지" v-model="deptPoint[j]" :ref="'deptPoint'+j">
                    <input type="text" class="form-control form-control" placeholder="경유지" v-model="viaPoint[j]"  :ref="'viaPoint'+j">
                    <input type="text" class="form-control form-control" placeholder="도착지" v-model="arrPoint[j]"  :ref="'arrPoint'+j">
                  </div>
                  <p class="mt-1 text-start underTitle02">※ 경유지입력은 선택사항입니다</p>

                  <div class="input-group mt-3 mb-4">
                    <span class="input-group-text sub-title">시작시간</span>
                    <select id="startTime" v-model="rentStartTime[j]" class="form-select text-center" style="width:16%" :ref="'rentStartTime'+j">
                      <option v-for="time in times" :key="time" :value="time">{{ time }}</option>
                    </select>
                    <span class="input-group-text sub-title">종료시간</span>
                    <select id="startTime" v-model="rentCloseTime[j]" class="form-select text-center" style="width:16%" :ref="'rentCloseTime'+j">
                      <option v-for="time in times" :key="time" :value="time">{{ time }}</option>
                    </select>
                  </div>

                  <hr>
                
                </div>

                <div class="mt-5 mb-3">
                    <textarea type="text" class="form-control" ref="etcInquiry" v-model="etcInquiry" maxlength="200" style="height: 200px" placeholder="기타 추가사항(최대500자)

  1. 일정관련 세부사항
  2. 이동경로 추가사항
  3. 기타사항 등
                    "></textarea>
                  </div>

                  <div class="card justify-content-center mt-5">
                  <table class="col-12">
                    <tr>
                      <td class="p-2">
                        <div class="justify-content-center fs-5 align-baseline text-center">
                          <div class="form-check-inline">
                            <input class="form-check-input" type="checkbox" name="guideFlag" @change="needGuide($event)" ref="guideFlag">
                            <label class="form-check-label" for="flexRadioDefault1">
                              &nbsp;&nbsp;가이드필요
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="card justify-content-center mt-2" v-if="this.nGuide === 'Y'">
                  <table class="col-12">
                    <tr>
                      <td class="p-2">
                        <div class="justify-content-center fs-5 align-baseline text-center">
                          <div class="form-check-inline" :key="i" v-for="(gd, i) in guideInfo">
                            <input class="form-check-input" type="radio" name="guideFlag" v-model="guideFlag" :value="gd.baseCode">
                            <label class="form-check-label guideType" for="flexRadioDefault1">
                              &nbsp;&nbsp;{{ gd.codeNameKor }}
                            </label>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="card justify-content-center mt-3">
                  <table>
                    <tr>
                      <td>
                        <button class="btn btn-lg text-primary" @click="choiceBus('luxB')" ref="busFlag">
                          <i class="bi bi-check-circle-fill text-primary fs-4" v-if="busFlag==='luxB'"></i>
                          <!-- <img src="../assets/img/nbus/luxB.png" alt="고급 최대10인승" style="width:60%;"> -->
                          <img src="../assets/img/nbus/nbus_style.jpg" class="busImg" alt="마이크로 최대16인승" style="width:60%;">
                          <p class="mt-0">
                            <span class="busType">{{ busInfo[0]?.codeNameKor }}</span>
                          </p>
                        </button>
                      </td>
                      <td>
                        <button class="btn btn-lg text-success" @click="choiceBus('micB')">
                          <i class="bi bi-check-circle-fill text-success fs-4" v-if="busFlag==='micB'"></i>
                          <!-- <img src="../assets/img/nbus/micB.png" alt="마이크로 최대16인승" style="width:60%;"> -->
                          <img src="../assets/img/nbus/nbus_style.jpg" class="busImg" alt="마이크로 최대16인승" style="width:60%;">
                          <p class="mt-0">
                            <span class="busType">{{ busInfo[1]?.codeNameKor }}({{ busInfo[1]?.spRemark }})</span>
                          </p>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <button class="btn btn-lg text-info" @click="choiceBus('midB')">
                          <i class="bi bi-check-circle-fill text-info fs-4" v-if="busFlag==='midB'"></i>
                          <!-- <img src="../assets/img/nbus/midB.png" alt="중형 최대26인승" style="width:60%;"> -->
                          <img src="../assets/img/nbus/nbus_style.jpg" class="busImg" alt="마이크로 최대16인승" style="width:60%;">
                          <p class="mt-0">
                            <span class="busType">{{ busInfo[2]?.codeNameKor }}({{ busInfo[2]?.spRemark }})</span>
                          </p>
                        </button>
                      </td>
                      <td>
                        <button class="btn btn-lg text-danger" @click="choiceBus('lagB')">
                          <i class="bi bi-check-circle-fill text-danger fs-4" v-if="busFlag==='lagB'"></i>
                          <!-- <img src="../assets/img/nbus/lagB.png" alt="대형 최대49인승" style="width:60%;"> -->
                          <img src="../assets/img/nbus/nbus_style.jpg" class="busImg" alt="마이크로 최대16인승" style="width:60%;">
                          <p class="mt-0">
                            <span class="busType">{{ busInfo[3]?.codeNameKor }}({{ busInfo[3]?.spRemark }})</span>
                          </p>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <div class="text-start" style="padding-left: 45px; font-size: medium; font-weight: 600;">
                          <p>
                            ※ 모든 버스에는 운전기사가 배치됩니다.
                          </p>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="card justify-content-center mt-5">
                  <table>
                    <tr>
                      <td>
                        <div class="input-group b-input mt-0">
                          <span class="input-group-text text-center sub-title">고객명</span>
                          <input type="text" class="form-control" placeholder="성함을 입력해 주세요" v-model="inqName" ref="inqName">
                        </div>
                        <div class="input-group b-input mt-2">
                          <span class="input-group-text text-center sub-title">휴대전화</span>
                          <input type="text" class="form-control" placeholder="안내(카카오톡)를 받으실 모바일연락처" v-model="inqTel" ref="inqTel">
                        </div>                        
                      </td>
                    </tr>
                  </table>
                </div>

                <div class="mt-5 mb-0">
                  <button class="btn btn-lg reqBtn" @click="inquiryNbus">
                    요 청 하 기
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="join mt-5">
        <div class="container nbusBottom">
          <div class="j-text">
            <span>엔버스(Nbus)</span>
            <p class="j-sub">지금 문의주세요!</p>
          </div>
          <img src="../assets/img/new-test/ms/f-bus.png" class="img-fluid fbus">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {genCode} from '../assets/js/genCode';
import moment from 'moment';
import { handlerInfo } from '../assets/js/commonFunctions'

export default {
  data() {
    return {
      inqCode: "",          //문의코드
      inqName: "",          //문의자성명
      inqId: "",
      inqTel: "",
      rentStartDate: "",    //이용시작일
      rentCloseDate: "",
      rentStartTime: [],    //이용시작시간
      rentCloseTime: [],
      deptPoint: [],        //출발지정보
      arrPoint: [],
      etcInquiry: [],       //기타문의(500자)
      guideFlag: "",        //가이드(GG/PG/BG)
      personCnt: 0,         //탑승인원
      busFlag: "",          //버스선택(luxB/micB/midB/lagB)

      times: [],             // 시간 옵션을 담을 배열
      rentTotDays: 1,        // 사용일
      viaPoint: [],          // 경유지
      today: "",
      nGuide: "N",           //가이드필요(Y/N)
      //rentDateDetail: []     //일차별 탑승일

      handTel: [],           //매니저연락처
      handTelCnt: 0,         //수신처갯수
      memFlag: "",           //회원여부

      guideInfo: [],         //가이드코드정보
      busInfo: [],           //렌트버스정보

      reviewList: [],        //리뷰리스트

      showOverlay: true,    //화면가림여부
    }
  },
  created() {
    this.user = this.$store.state.user;

    if(!this.user.email)
    {
      this.memFlag = "N"
    }
    else
    {
      this.memFlag = "Y"
      this.inqId = this.user.email;

      //카카오알림용 인적정보
      if(this.user.joinFlag === "K" || this.user.joinFlag === "E" || this.user.joinFlag === "H")
      {
        this.k_name = (!this.user.nickname) ? "" : this.user.nickname;

        let kakaoPhone = ""
        if(this.user.alimCallC != undefined && this.user.alimCallC.charAt(0) === '+')
        {
          //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
          kakaoPhone = this.user.alimCallC.replace("+82 ", "0")
        }
        else
        {
          kakaoPhone = this.user.alimCallC
        }
        this.k_tel = (!this.user.alimCallC) ? "" : kakaoPhone.replace(/-/g,""); //-제거
      }
      else
      {
        this.k_name = this.user.name;
        this.k_tel = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
      }

      //카카오&직가입 || 네이버
      this.inqName = this.k_name
      this.inqTel = this.k_tel
    }

    this.getGuideInfo()
    this.getBusInfo()
    this.getHandlerInfo()
    this.getReviewList()        //리뷰호출

    //우클릭 & 드래그 금지
    document.oncontextmenu = function()
    {
      return false;
    }
    document.ondragstart = function() {
      return false;
    }
  },
  computed: {
    user() {
      // 로그인전: email정보없음, 로그인후: email정보있음
      return this.$store.state.user;
    },
  },
  mounted(){
    const today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")

    this.generateTimeOptions();
  },
  methods: {
    //공통함수
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },

    //리뷰작성자는 리뷰를 수정할 수 있다
    changeReview(rvCode, nSeq, iSeq)
    {
      //alert("rvCode:"+rvCode+"nSeq:"+nSeq)
      this.$router.push({path: '/reviewModify', query: {rvCode: rvCode, nSeq: nSeq, iSeq: iSeq}});
    },

    generateTimeOptions() {
      const times = [];
      const startTime = new Date();
      startTime.setHours(0);
      startTime.setMinutes(0);

      // 시간 옵션을 30분 간격으로 생성
      for (let i = 0; i < 48; i++) { // 24시간 * 2 (30분 간격)
        times.push(this.formatTime(startTime));
        startTime.setMinutes(startTime.getMinutes() + 30);
      }

      this.times = times;
    },
    formatTime(time) {
      const hours = time.getHours().toString().padStart(2, '0');
      const minutes = time.getMinutes().toString().padStart(2, '0');
      return `${hours}:${minutes}`;
    },

    //상품리뷰리스트
    async getReviewList()
    {
      let reviewList = await this.$api("/api/reviewList_nbus", {});
      if(reviewList.length > 0)
      {
        this.reviewList = reviewList
      }
      // console.log("reviewList:",this.reviewList)
    },

    //가이드정보
    async getGuideInfo(){
      let guideInfo = await this.$api("/api/searchBasecode_more", { param: [ "guideLv", "guCd", "Y"] });
      if(guideInfo.length > 0)
      {
        this.guideInfo = guideInfo
      }
      //console.log("guide:", this.guideInfo)
    },

    //버스정보
    async getBusInfo(){
      let busInfo = await this.$api("/api/searchBasecode_more", { param: [ "rentBus", "buCd", "Y"] });
      if(busInfo.length > 0)
      {
        this.busInfo = busInfo
      }
      // console.log("busInfo:::::::", this.busInfo)
    },


    //동일일정처리
    makeSame(e, j){
      let isChecked = e.target.checked
      if(isChecked === true)
      {
        this.deptPoint[j] = this.deptPoint[1]
        this.viaPoint[j] = this.viaPoint[1]
        this.arrPoint[j] = this.arrPoint[1]
        this.rentStartTime[j] = this.rentStartTime[1]
        this.rentCloseTime[j] = this.rentCloseTime[1]
        this.etcInquiry[j] = this.etcInquiry[1]
      }
      else
      {
        this.deptPoint[j] = ""
        this.viaPoint[j]  = ""
        this.arrPoint[j]  = ""
        this.rentStartTime[j] = ""
        this.rentCloseTime[j] = ""
        this.etcInquiry = ""
      }
    },

    needGuide(event){
      let isChecked= event.target.checked
      if(isChecked === true)
      {
        this.nGuide = "Y"
        this.guideFlag = "GG"
      }
      else
      {
        this.nGuide = "N"
        this.guideFlag = ""
      }
    },


    choiceBus(bFlag){
      this.busFlag = bFlag
    },

    //예약코드생성
    async genCode(param) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 1000 && chkFlag === "N"; attempts++) 
      {
        let ymd = this.today.replace(/-/g, '')
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);

        let chk_genCode = await this.$api("/api/checkDub_resCode", { param: [rtnCode] });

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N") 
      {
        this.$swal.fire("","예약번호 생성횟수 한계에 도달하였습니다.<br>담당자에게 문의(1660-4602)하여 주세요.","warning")
        return false;
      } 
      else 
      {
        return rtnCode
      }
    },

    //담당자 정보
    async getManagerList() 
    {
      //담당자정보
      let chargePList = await this.$api("/api/handlerInfo", {})
      if(chargePList.length > 0)
      {
        this.chargePList = chargePList;
      }
      //console.log("chargePList:", this.chargePList)
    },

    //알림톡 발송대상 연락처
    async getHandlerInfo()
    {
      let handTel = await handlerInfo(this.$api);
      if(handTel.length > 0)
      {
        this.handTel = handTel
      }
      //console.log("handTel: ", this.handTel)
    },

    async inquiryNbus(){
      if(!this.rentStartDate)
      {
        this.$refs.rentStartDate.focus();
        this.$swal.fire("","이용일자를 입력하세요", "warning")
        return false;
      }
      else
      {
        if(this.rentStartDate <= this.today)
        {
          this.$refs.rentStartDate.focus();
          this.$swal.fire("","이전일 또는 당일예약은 진행하실 수 없습니다.", "warning")
          return false;
        }
      }

      let j = 1
      while(j <= this.rentTotDays*1)
      {
        if(!this.deptPoint[j])
        {
          this.$refs['deptPoint'+j][0].focus();
          this.$swal.fire("","출발지를 입력하세요", "warning")
          return false;
        }
        if(!this.arrPoint[j])
        {
          this.$refs['arrPoint'+j][0].focus();
          this.$swal.fire("","도착지를 입력하세요", "warning")
          return false;
        }
        if(!this.rentStartTime[j])
        {
          this.$refs['rentStartTime'+j][0].focus();
          this.$swal.fire("","출발시간을 입력하세요", "warning")
          return false;
        }
        if(!this.rentCloseTime[j])
        {
          this.$refs['rentCloseTime'+j][0].focus();
          this.$swal.fire("","종료시간을 입력하세요", "warning")
          return false;
        }        
        j++
      }

      if(!this.busFlag)
      {
        this.$refs.busFlag.focus();
        this.$swal.fire("","탑승할 버스를 선택해주세요", "warning")
        return false;
      }

      if(!this.inqName)
      {
        this.$refs.inqName.focus();
        this.$swal.fire("","고객성함을 입력해주세요", "warning")
        return false;
      }

      if(!this.inqTel)
      {
        this.$refs.inqTel.focus();
        this.$swal.fire("","연락받으실 휴대전화번호를 입력해주세요", "warning")
        return false;
      }

      // if(!this.personCnt || this.personCnt == undefined)
      // {
      //   this.personCnt = 9999       //인원미정
      // }

      //문의코드 생성
      let catCode = "NB-";
      let inqCode = await this.genCode(catCode)
      this.inqCode = inqCode

      let useFlag = "Y"
      let inqEmail = ""
      let insId = this.inqId
      let inqStatus = "IQ"
      let checkFlag = "N"

      let insNbusInquiry = await this.$api("/api/insNbusInquiry", {param: [
        this.inqCode, this.inqName, this.inqId, this.inqTel, inqEmail, this.rentStartDate, this.guideFlag, this.personCnt, this.busFlag, insId, useFlag, inqStatus, checkFlag, this.rentTotDays, this.etcInquiry, this.memFlag, this.user.memLevel
      ]});
      if(insNbusInquiry.affectedRows < 0)
      {
        this.$swal.fire("", "문의등록 중 오류가 발생했습니다<br>재시도를 해주십시오", "warning")
        return false;
      }
      else
      {
        let promises = []

        let i = 1
        while(i <= this.rentTotDays*1)
        {
          let insNbusInquiry_detail = await this.$api("/api/insNbusInquiry_detail", {param: [
            this.inqCode, i, this.rentStartTime[i], this.rentCloseTime[i], this.deptPoint[i], this.viaPoint[i], this.arrPoint[i], "Y"
          ]});

          promises.push(insNbusInquiry_detail)
          i++
        }

        Promise.all(promises).then(() => {
          // console.log(result)
          this.sendKakao(this.inqCode, "nbRes")
          // this.$swal.fire("", "문의가 등록되었습니다<br>빠른 시간안에 연락을 드리겠습니다<br>감사합니다.", "info")
          // location.reload(true)
        })
      }
    },

    async sendKakao(nbCode, param){
      
      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        nbCode, param, this.inqTel
      ]});

      //선택가이드
      let guide = ""
      if(this.nGuide === "Y")
      {
        let guide_tmp = this.guideInfo.filter((item) => {
          return item.baseCode === this.guideFlag
        })

        guide = guide_tmp[0].codeNameKor
      }
      else
      {
        guide = "없음"
      }

      //선택버스
      let bus = ""
      let bus_tmp = this.busInfo.filter((item) => {
          return item.baseCode === this.busFlag
        })
      bus = bus_tmp[0].codeNameKor + "("+bus_tmp[0].spRemark+")"


      //알림톡 발송대상 확정
      this.handTel.push({aimCall: this.inqTel})
      this.handTelCnt = this.handTel.length

      if(insKSendHistory.affectedRows > 0) 
      {
        let promises = []
        
        if(param == "nbRes")                                         //엔버스 문의접수 nbRes
        {
          let i = 0
          while(i < this.handTelCnt)
          {
            let kakaoAlimData = 
            {
              //카카오 알림톡 발송(문의접수)
              inqName: this.inqName,
              inqTel: this.inqTel,
              paxCnt: this.personCnt,
              guideLv: guide,
              busLv: bus,
              deptPoint: this.deptPoint[1],                           //1일차 탑승지
              rentStartDate: this.rentStartDate,
              rentStartTime: this.rentStartTime[1],
              rentTotDays: this.rentTotDays,                          //사용일자
              inqCode: nbCode,
              alimFlag: param,                                        //알림유형(버스문의)
              memFlag: this.memFlag,                                  //멤버여부(Y/N)
              pdtCode: nbCode,                                        //결제처리용 상품코드
              clientCall: this.handTel[i].aimCall,                      //알림톡발송대상
            };

            let promise = this.$api("api/kakaoAlimSend7", {
              param: [{ kakaoAlimData: kakaoAlimData}]
            })

            promises.push(promise)
            i++
          }

          //반복문 이후 처리
          Promise.all(promises).then(() => {
            //console.log(results)
            this.$swal.fire('', '엔버스접수 알림톡이 발송되었습니다', 'success').then(() => {
              this.$router.push({ path: "/" })              
              //location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error)
          })
        }
      }
    }
  }
}
</script>


<style scoped>
/*@import "../assets/css/style.css";*/
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

#nbus_page{
  margin-top:-3.5rem;
  /* background: linear-gradient(#3baeef, #0b7fd4,  #0065bd); */
  background: white;
}
@media (max-width:992px){
  #nbus_page{
    margin-top:2.2rem
  }
}


/*********************************************
  # 화면가림설정
*********************************************/
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명 검정 배경 */
  backdrop-filter: blur(8px); /* 배경 흐림 효과 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* 가장 위에 표시 */
}

/* 중앙 텍스트 스타일 */
.overlay-text {
  color: white;
  font-size: 10rem;
  font-weight: bold;
}



/*********************************************
  # 엔버스 로고와 슬로건
*********************************************/
#nbus_page .nbusLogo{
  width: 200px;
}

#nbus_page .nbusSlogan{
    font-size: 1.3rem;
    font-weight: 600;
  }

@media (max-width:500px){
  #nbus_page .nbusLogo{
    width: 150px;
  }
  #nbus_page .nbusSlogan{
    font-size: 1rem;
  }
  .overlay-text {
    font-size: 3rem;
  }  
}






/* #nbus_page .product #p-nday_mo{
  display: block;
} */

#nbus_page .input-group-text.sub-title {
  font-size: 1.2rem;
  font-weight: 600;
  /*background-color: rgb(52, 148, 227);*/
  background-color: #e9ecef;
  color: #000;
  text-align: start;
  padding-left: 7%;
  width: 25%;
}


@media (max-width:500px){
  #nbus_page .input-group-text.sub-title {
    font-size: 1rem;
    font-weight: 600;
    text-align: start;
    padding-left: 4%;
  }
}

#nbus_page .member-dday{
  font-family: none;
  font-size:1.2rem;
  font-weight: 600;
  color:#ff0;
  text-align: center;
  margin-top:.7rem
}
@media (max-width:992px){
  #nbus_page .member-dday{
    font-size:1rem;
    margin-top:.5rem
  }
}


/* 선택된 라디오 버튼의 색상 */
input[type="radio"]:checked {
  background-color: #a80606; /* 원하는 색상으로 변경 */
}

/* 선택되지 않은 라디오 버튼의 색상 */
input[type="radio"] {
  background-color: #ffffff; /* 원하는 색상으로 변경 */
}


/*--------------------------------------------------------------
	# 메인(단순상단여백역할)
--------------------------------------------------------------*/
#nbus_page .msmain {
    width: 100%;
    height: 0px;
}

@media (max-width:500px){
  #nbus_page .msmain{
    height:20px;
  }
}

/* ----------
  메인글자
  ---------- */
#nbus_page .msmain .m-cont{
  font-family: 'Black Han Sans', sans-serif;
  text-align: center;
}


/*--------------------------------------------------------------
	# 내용 타이틀 공통
--------------------------------------------------------------*/
#nbus_page .title{
  text-align: center;
}
#nbus_page .title .sub{
  position: relative;
  width:13rem;
  height: 3rem;
  margin:auto;
  padding-top:9px;
  background-color: #fff;
  border-radius:50px;
  color:#000;
  font-weight: 600;
  font-size:1.2rem
}
#nbus_page .title .sub:after{
  content:""; 
  position: absolute;
  bottom: -40%; 
  left: 50%; 
  transform: translateX(-50%);
  border-left: 20px solid transparent; 
  border-right: 20px solid transparent; 
  border-top: 30px solid #fff;
}
#nbus_page .title .mtit{
  font-family: 'Black Han Sans', sans-serif;
  color:#fff;
  padding-top:2rem;
  font-size:4rem
}

@media (max-width:991px){
  #nbus_page .title .sub{
    width:8rem;
    height: 2rem;
    padding-top:5px;
    font-size:.9rem
  }
  #nbus_page .title .sub:after{
    bottom: -30%; 
    border-left: 10px solid transparent; 
    border-right: 10px solid transparent; 
    border-top: 10px solid #fff;
  }
  #nbus_page .title .mtit{
    padding-top:2rem;
    font-size:2.5rem;
    line-height:40px
  }
}

/*--------------------------------------------------------------
	# 버스정보
--------------------------------------------------------------*/
#nbus_page .busImg {
  padding-left: 5px;
}

#nbus_page .busType {
  padding-left: 5px;
}

@media (max-width:991px){
  #nbus_page .busType {
    font-size: 1.1rem;
  }
}

@media (max-width:500px){
  #nbus_page .busType {
    font-size: 0.8rem;
  }
}

/*--------------------------------------------------------------
	# 요청내용
--------------------------------------------------------------*/
#nbus_page .benefit{
  padding-top: 0px;
  width:100%;
  height:auto;
  /* margin: 5rem 0 15rem; */
  position: relative;
}

@media (max-width:991px){
  #nbus_page .benefit{
    margin:7rem 0 13rem;
  }
}


#nbus_page .benefit .b-cont{
  position: relative;
  z-index:99;
}

#nbus_page .benefit .underTitle01, .underTitle02{
  font-size: 0.9rem;
}

#nbus_page .benefit .skdDay {
  font-size: 1.1rem;
  font-weight: 600;
}

#nbus_page .card .guideType {
  font-size: 1.1rem;
  /* font-weight: 600; */
}

@media (max-width:500px){
  #nbus_page .benefit .underTitle01, .underTitle02{
    font-size: 0.8rem;
  }
  #nbus_page .benefit .skdDay {
    font-size: 1.0rem;
    font-weight: 600;
  }
  #nbus_page .card .guideType {
  font-size: 1rem;
  /* font-weight: 600; */
}  
}

/* ----------
  내용
  ---------- */
#nbus_page .benefit .cont{
  text-align: center;
}
#nbus_page .benefit .cont .card{
  background-color: #f5f5f5;
}
#nbus_page .benefit .cont .card-body{
  padding:0 2rem;
  height: 22rem;
}
#nbus_page .benefit .cont .card .number{
  font-family: 'Black Han Sans', sans-serif;
  font-size:1.8rem;
  color:#000;
  padding-top:1rem;
  margin-bottom:0
}
#nbus_page .benefit .cont .card-title{
  font-family: 'Black Han Sans', sans-serif;
  font-size:2.5rem;
  line-height:43px;
  color:#000;
  margin-bottom:0
}
#nbus_page .benefit .cont .card-title span{
  color:#7bbb10
}
#nbus_page .benefit .cont .card img{
  width:8rem;
  height:auto;
  padding:1rem 0
}
#nbus_page .benefit .cont .card-text{
  color:#000;
  font-size:1.2rem
}

@media (max-width:991px){
  #nbus_page .benefit .cont .card-body{
    height: 17rem;
  }
  #nbus_page .benefit .cont .card .number{
    padding:2rem 0 1rem;
    font-size:1.3rem;
  }
  #nbus_page .benefit .cont .card-title{
    font-size:2rem;
    line-height:35px;
  }
  #nbus_page .benefit .cont .card img{
    width:6rem;
    padding:.8rem 0
  }
  #nbus_page .benefit .cont .card-text{
    font-size:1rem
  }
}
@media (max-width:767px){
  #nbus_page .benefit .cont .card .number{
    padding:1rem 0 .5rem;
  }
  #nbus_page .benefit .cont .card-body{
    height: 15.5rem;
  }
  #nbus_page .benefit .cont .card-title{
    font-size:1.8rem;
    line-height:30px;
  }
  #nbus_page .benefit .cont .card img{
    width:6rem;
  }
  #nbus_page .benefit .cont .card-text{
    font-size:.85rem
  }
}


/*--------------------------------------------------------------
	# 가입하기
--------------------------------------------------------------*/

#nbus_page .join{
  font-family: 'Black Han Sans', sans-serif;
  text-align: center;
  margin:3rem 0 3rem;
  animation: fadeInUp 1.3s;
}
#nbus_page .join .j-text{
  color: black;
  font-size:5rem;
}
/* #nbus_page .join .j-text .j-ndayt{
  width: auto;
  height:4rem
} */
#nbus_page .join .j-text span{
  font-size:4.5rem;
  padding-left:10px
}
#nbus_page .join .j-text p{
  margin-bottom:0
}
#nbus_page .join .j-text .j-sub{
  letter-spacing: 8px;
  margin-top:-25px
}
#nbus_page .join .fbus{
  width:30rem;
  height:auto;
  margin-bottom:1rem
}

@media (max-width:500px){
  #nbus_page .join .j-text span{
    font-size: 3rem;
    padding-left:6px;
  }
  #nbus_page .join .j-text{
    font-size: 2.5rem;
  }
}




/********************************
  # 요청하기 버튼
*******************************************/
#nbus_page .btn.reqBtn{
  background-color: #3494E3;
  color: white;
  font-size: 2rem;
  font-weight: 900;
  width:100%;
  padding:15px;
  border-radius:0.5rem;
  transition: all .5s;
}

@media (max-width:500px){
  #nbus_page .btn.reqBtn{
    font-size: 1.2rem;
    padding:10px;
  }
}


/********************************
  # 커스텀 CSS
*******************************************/
.form-select {
  appearance: none;
  padding: 0.5rem 2rem 0.5rem 1rem;
  /*background-image: url("data:image/svg+xml,...");
  /* 커스텀 화살표 아이콘 */
  background:url('../assets/img/icons/arrow_bottom.png') no-repeat right 24px center;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 16px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.form-select.active {
  background:url('../assets/img/icons/arrow_up.png') no-repeat right 24px center;
}

.form-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
</style>