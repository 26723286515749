<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="alert alert-danger" role="alert">
          ※ 결제가 취소되거나, 처리 되지 않았습니다. ※
        </div>
      </div>
    </main>    
  </div>
</template>

<script>
export default {
  data() {
    return {
      // paymentKey: "",
      // orderId: "",
      // amount: 0,
      tossParam: {} // 토스페이파라미터
    };
  },
  created() 
  {
    //alert("SUCCESS");
    let paymentKey = this.$route.query.paymentKey;
    let orderId = this.$route.query.orderId;
    let amount = this.$route.query.amount;

    // console.log("orderId:",orderId);

    let tossParam = 
    {
      paymentKey: paymentKey,
      orderId: orderId,
      amount: amount
    };

    console.log(tossParam)

    // /** 토스페이 승인처리 */
    // this.$api("api/tossSuccess", 
    // {
    //   param: tossParam
    // })
    // .then((res) => {
    //   console.log(res);
    // })
    // .catch((err) => {
    //   console.log(err);
    // });
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
  },
  // unmounted() {
  //   this.$el.removeEventListener('click', this.test());
  // },
  methods: 
  {
    
  }
}
</script>