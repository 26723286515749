<template>
  <main class="mt-3">
    <div class="container">
      <h2 class="text-center"><u>상 품 등 록</u></h2>
      <div class="mb-3 row">
        <!-- 수정불가 -->
        <label class="col-md-3 col-form-label">상품코드</label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="productInfo.pdtCode" readonly>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품구분<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" v-model="productInfo.salesRoute" value="T" ref="rbt_salesRoute">
            <label class="form-check-label text-success" for="flexRadioDefault1">
              여행상품
            </label>
          </div>
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" v-model="productInfo.salesRoute" value="L">
            <label class="form-check-label text-primary" for="flexRadioDefault2">
              현지투어
            </label>
          </div>
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" v-model="productInfo.salesRoute" value="F">
            <label class="form-check-label text-danger" for="flexRadioDefault3">
              선박상품
            </label>
          </div>
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" v-model="productInfo.salesRoute" value="E">
            <label class="form-check-label text-info" for="flexRadioDefault4">
              기타상품
            </label>
          </div>
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" v-model="productInfo.salesRoute" value="S">
            <label class="form-check-label text-warning" for="flexRadioDefault5">
              단일가상품
            </label>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품명<font class="text-secondary" style="font-size: small;">(국문/영문)</font> <font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group">
            <input type="text" class="form-control" ref="txt_pdtNameKor" v-model="productInfo.pdtNameKor">
            <input type="text" class="form-control" v-model="productInfo.pdtNameEng">
          </div>
        </div>
      </div>
      <div class="mb-3 row" v-if="productInfo.salesRoute === 'F'" style="display: none;">
        <label class="col-md-3 col-form-label">리턴속성 / 체류일수<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" v-model="productInfo.tripProp" value="D" ref="rbt_tripProp" :disabled="productInfo.salesRoute === 'F'">
            <label class="form-check-label text-danger" for="flexRadioDefault1">
              리턴일 지정
            </label>
          </div>
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" v-model="productInfo.tripProp" value="F">
            <label class="form-check-label text-primary" for="flexRadioDefault2">
              리턴일 자유
            </label>
          </div>
          <div class="form-check-inline" v-if="productInfo.tripProp === 'D'">
            <label class="form-check-label" for="txt_stayCnt">
              체류일수<small>(예:3박5일->5입력)</small>
            </label>&nbsp;
            <input class="form-check-inline col-3 text-end" type="number" min="0" ref="txt_stayCnt" v-model="productInfo.stayCnt">(0=당일리턴)
          </div>
        </div>
      </div>
      <div class="mb-3 row" v-else>
        <label class="col-md-3 col-form-label">리턴속성 / 체류일수<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" v-model="productInfo.tripProp" value="D" ref="rbt_tripProp" :disabled="productInfo.salesRoute === 'F'">
            <label class="form-check-label text-danger" for="flexRadioDefault1">
              리턴일 지정
            </label>
          </div>
          <div class="form-check-inline">
            <input class="form-check-input" type="radio" v-model="productInfo.tripProp" value="F">
            <label class="form-check-label text-primary" for="flexRadioDefault2">
              리턴일 자유
            </label>
          </div>
          <div class="form-check-inline" v-if="productInfo.tripProp === 'D'">
            <label class="form-check-label" for="txt_stayCnt">
              체류일수<small>(예:3박5일->5입력)</small>
            </label>&nbsp;
            <input class="form-check-inline col-3 text-end" type="number" min="0" ref="txt_stayCnt" v-model="productInfo.stayCnt">(0=당일리턴)
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">출발지/여행국가/여행지역<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <!-- 출발도시 -->
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_deptCity" v-model="productInfo.ctCd">
              <option :value="cityList[i].baseCode" :key=i v-for="(baseCode,i) in cityList">{{cityList[i].codeNameKor}}</option>
            </select>
            <!-- 여행국가 -->
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tripNation" v-model="productInfo.ntCd" @change="changeNationList()">
              <option :value="nationList[i].baseCode" :key=i v-for="(baseCode,i) in nationList">{{nationList[i].codeNameKor}}</option>
            </select>
            <!-- 여행지역 -->
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_tripArea" v-model="productInfo.arCd">
              <option :value="areaList[j].baseCode" :key=j v-for="(baseCode,j) in areaList">{{areaList[j].codeNameKor}}</option>
            </select>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3 row">
        <label class="col-md-3 col-form-label">상품게시기간<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <!-- <input type="text" class="form-control" ref="txt_salesStart" v-model="productInfo.salesStart"> -->
            <date-picker class="form-control" v-model="productInfo.tripStart" ref="txt_tripStrart" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
            <span class="input-group-text" id="basic-addon1">종료</span>
            <!-- <input type="text" class="form-control" v-model="productInfo.salesEnd"> -->
            <date-picker class="form-control" v-model="productInfo.tripEnd" ref="txt_tripEnd" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품판매기간<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <date-picker class="form-control" v-model="productInfo.salesStart" ref="txt_fromDate" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
            <span class="input-group-text" id="basic-addon1">종료</span>
            <date-picker class="form-control" v-model="productInfo.salesEnd" ref="txt_toDate" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
          </div>
        </div>
      </div>
            
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">출발불가일</label>
        <div class="col-md-9">
          <textarea class="form-control text-small" placeholder="(예시) 2022-12-25, 2022-12-26, 매주 화요일" ref="txt_unableDpDate" id="floatingTextarea" style="height:80px" 
            v-model="productInfo.unableDpDate" maxlength="210"></textarea>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">매니저<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_deptCity" v-model="productInfo.manId">
              <option :value="m.handlerId" :key=i v-for="(m, i) in this.managerInfo">{{m.handlerNameKor}} ({{ m.handlerId }})</option>
            </select>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">기본마진(1인당)<font class="text-danger">*</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <input type="number" class="form-control text-end" ref="txt_basicMargin" v-model="productInfo.basicMargin">
            <select class="form-select form-select-md" aria-label=".form-select-md" v-model="productInfo.marginMethod">
              <option value="A" id="promoMethod" selected>원</option>
              <option value="P" id="promoMethod">%</option>
            </select>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">적립포인트</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <input type="number" class="form-control text-end" ref="txt_basicMargin" v-model="productInfo.userPoint">
            <select class="form-select form-select-md" aria-label=".form-select-md" v-model="productInfo.userPointMethod">
              <option value="A" id="userPointMethod" selected>점</option>
              <option value="P" id="userPointMethod">%</option>
            </select>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">부가세</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <input type="text" class="form-control text-end" ref="txt_vatPrice" v-model="productInfo.vatPrice">
            <span class="input-group-text">%</span>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">한줄소개</label>
        <div class="col-md-9">
          <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
            v-model="productInfo.pdtRemarks" maxlength="210"></textarea>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">포함사항</label>
        <div class="col-md-9">
          <textarea class="form-control" placeholder="1000자 이내" ref="txt_inContent" id="floatingTextarea" style="height:80px" v-model="productInfo.inContent" maxlength="210"></textarea>
        </div>
      </div>
      
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">불포함사항</label>
        <div class="col-md-9">
          <textarea class="form-control" placeholder="1000자 이내" ref="txt_exContent" id="floatingTextarea" style="height:80px" v-model="productInfo.exContent" maxlength="210"></textarea>
        </div>
      </div>
      
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">토픽<font class="text-secondary" style="font-size: small;">(주제어)</font></label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="productInfo.pdtTopic">
        </div>
      </div>

      <div class="mb-3 row">
        <!-- <label class="col-md-3 col-form-label">프로모션설정<font class="text-secondary" style="font-size: small;">(Y/N)</font></label> -->
        <div class="col-md-9">
          <div class="form-check-inline align-middle">
            <!-- <input class="form-check-input" type="checkbox" v-model="productInfo.promotionFlag" true-value="0" false-value="1">
            <label class="form-check-label text-primary" for="ckb_proN">
              &nbsp;설정 <font class="text-secondary" style="font-size: small;">(중단프로모션 재시작불가(데이타 안정성) -> 신규프로모션 생성)</font>
            </label> -->
          </div>
        </div>
      </div>
      <div class="mb-3 row" v-if="productInfo.promotionFlag === '0'">
        <label class="col-md-3 col-form-label">프로모션명</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="productPromotion.promoName">
          </div>
        </div>
        <label class="col-md-3 col-form-label">할인<font class="text-secondary" style="font-size: small;">(원/%)</font></label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <input type="number" class="form-control text-end" v-model="productPromotion.promoRate">
            <select class="form-select form-select-md" aria-label=".form-select-md" v-model="productPromotion.promoMethod">
              <option value="A" id="promoMethod" selected>원</option>
              <option value="P" id="promoMethod">%</option>
            </select>
          </div>
        </div>
      </div>
      <div class="mb-3 row" v-else>
      </div>
      <div class="mb-3 row" v-if="productInfo.promotionFlag === '0'">
        <label class="col-md-3 col-form-label">프로모션기간</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <date-picker class="form-control" v-model="productPromotion.promoStart" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
            <span class="input-group-text" id="basic-addon1">종료</span>
            <date-picker class="form-control" v-model="productPromotion.promoEnd" type="date" lang="en" format="YYYY-MM-DD"></date-picker>
          </div>
        </div>
      </div>
      <div class="mb-3 row" v-else>
      </div>
      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-primary" @click="productInsert();">저장</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList()">취소</button>
        </div>
      </div>
    </div>
  </main>

</template>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      cityList: {},
      nationList: {},
      areaList: {},
      areaList_tmp: {},
      productInfo: {
        // TB_Product_M
        pdtCode: "", // 상품코드
        pdtNameKor: "", //코드명(한글)
        pdtNameEng: "",
        ctCd: "", //도시코드(출발지)
        ntCd: "", //국가코드
        arCd: "", //지역코드
        tripStart: "", //여행(사용)시작
        tripEnd: "", //여행(사용)종료
        //vatPrice: 0,  // -> TB_product_prc
        basicMargin: 0, //기본마진
        marginMethod: "P", //마진비율
        userPoint: 0, //포인트적립
        userPointMethod: "A", //포인트 적립방법(A/P)
        salesRoute: "", //상품분류
        pdtTopic: "",
        saleStatus: "N", // 초기값은 판매중지
        salesStart: "",
        salesEnd: "",
        pdtRemarks: "",
        insDate: "",
        insId: "",
        nominalAmt: 0, //화면표시금액
        nominalAmt_t: 0, //화면표시금액-최성수
        nominalAmt_p: 0, //화면표시금액
        promotionFlag: "1", //기본값: 프로모션비적용
        vatPrice: 0, //부가세(여행상품은 0%)
        autoSales: "N", //즉시자동판매
        unableDpDate: "", //선택불가조건
        tripProp: "D", //일정속성(def: 리턴지정)
        sFlag_g: "Y", //명목표시사용(평상)
        sFlag_t: "",
        sFlag_p: "",
        stayCnt: 0, //숙박일수
        inContent: "", //포함내용
        exContent: "", //불포함내용
        sellerCode_nt: "Y", //판매자코드(엔타비)
        sellerCode_nd: "Y", //판매자코드(엔데트)
        manId: "",          //매니저아이디
      },

      productPromotion: {
        promoCode: "",      //프로모션코드
        promoMethod: "",
        promoRate: 0,
        promoStart: "",
        promoEnd: "",
        useFlag: "",
        promoName: ""
      },

      promoStart: "",
      promoEnd: "",

      managerInfo: []     //매니저리스트
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.getCityList();
    this.getNationList();
    this.getAreaList();
    this.getManagerList();
    //console.log("user:", this.user);
  },
  mounted() {
    //console.log("handler:",this.user.handlerId);
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade == undefined || this.user.handlerGrade > 9) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    //document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";        
  },
  methods: {
    async getManagerList() {
      let managerInfo = await this.$api("/api/handlerInfo", {});
      
      if(managerInfo.length > 0)
      {
        this.managerInfo = managerInfo.filter(item => {
          return item.manFlag === 'Y'
        })
      }
      console.log("managerInfo:",this.managerInfo);
    },    
    async getCityList() {
      let cityList = await this.$api("/api/cityList", {});
      this.cityList = cityList;
      //console.log("cityList",cityList);
    },
    async getNationList() {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      //console.log("nationList",nationList);
    },
    async getAreaList() {
      this.areaList_tmp = await this.$api("/api/areaList", {});
    },
    changeNationList() {
      //this.nationCode = this.productInfo.baseCode;
      this.viewAreaList(this.productInfo.ntCd);
    },
    viewAreaList(param1){
      //console.log("selectedNation:", param);
      this.areaList = this.areaList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("area:", this.areaList);
    },
    goToList() {
      this.$router.push({path: '/sales'});
    },

    productInsert() {
      // e.preventDefault();
      // 입력 validation check
      let timeStamp = "";
      let today = new Date();   
      let yyyy = today.getFullYear();
      let mm = today.getMonth()+1;
      let dd = today.getDate();
      let ymd = `${yyyy}${mm}${dd}`
      //console.log(`ymd: ${ymd}`);
      timeStamp = today.getTime(); // 상품코드에 들어갈 타임스탬프
      let catCode = this.productInfo.salesRoute;
      this.productInfo.pdtCode = catCode+"-"+ymd+"-"+timeStamp;
      this.productInfo.insDate = `${yyyy}-${mm}-${dd}`;
      this.productInfo.autoSales = 'N';
      // console.log(this.productInfo.insDate);
      this.productInfo.insId = this.user.handlerId;

      //리턴자유상품은 1일단위패턴(일단위 계산)
      this.productInfo.stayCnt = (this.productInfo.tripProp === "D") ? this.productInfo.stayCnt : 0;
      
      //선박상품은 리턴자유로 처리(옵션에서 처리하므로)
      if(this.productInfo.salesRoute === "F")
      {
        this.productInfo.tripProp = "F"
      }


      this.productInfo.tripStart = moment(this.productInfo.tripStart).format("YYYY-MM-DD");
      this.productInfo.tripEnd = moment(this.productInfo.tripEnd).format("YYYY-MM-DD");
      
      this.productInfo.salesStart = moment(this.productInfo.salesStart).format("YYYY-MM-DD");
      this.productInfo.salesEnd = moment(this.productInfo.salesEnd).format("YYYY-MM-DD");

      // 등록전 확인
      this.$swal.fire({
        title: '상품등록 진행?',
        showCancelButton: true,
        confirmButtonText: '등록',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // await this.$api("/api/produtInsert", {param: [this.productInfo]});
          let resIns_product_m = await this.$api("/api/produt_mInsert", {param: [this.productInfo]});

          if(resIns_product_m.affectedRows < 0)
          {
            this.$swal.fire('상품 기본정보 저장오류', '', 'alert')
          }
          else{
            // 상품관리리스트 표시를 위해 임시저장
            await this.$api("/api/productTmpImgInsert",{param: this.productInfo.pdtCode});
            await this.$api("/api/productQtyInsert",{param: [this.productInfo.pdtCode,this.productInfo.insId]});
            // 프로모션세팅
            if(this.productInfo.promotionFlag === "0") {
              this.productPromotion.promoCode = "PM-"+ymd+"-"+timeStamp    //프로모션코드
              this.productPromotion.promoStart = moment(this.productPromotion.promoStart).format("YYYY-MM-DD");
              this.productPromotion.promoEnd = moment(this.productPromotion.promoEnd).format("YYYY-MM-DD");
              //pdtCode, salesRoute, promoStart, promoEnd, promoMethod, promoRate, insId, insDate, useFlag, promoName
              let resIns_product_promo = await this.$api("/api/productPromoInsert_new", 
                {param: [this.productInfo.pdtCode, this.productInfo.salesRoute, this.productPromotion.promoStart, this.productPromotion.promoEnd,
                  this.productPromotion.promoMethod, this.productPromotion.promoRate, this.productInfo.insId,// this.productInfo.insDate = `${yyyy}-${mm}-${dd}`,
                  this.productInfo.promotionFlag, this.productPromotion.promoName, this.productPromotion.promoCode]}); 
              console.log("resIns_product_promo:",resIns_product_promo);
            }
            // let resIns_product_prc = await this.$api("/api/productPrcInsert", {param: [this.productPrice]});
            // console.log(resIns_product_prc)
          }
          // this.$swal.fire('등록완료!', '', 'success')
          // 등록 후 라우터를 통해 상품관리리스트로
          this.$router.push({path: '/sales'});
        }
      })
    }
  }
}
</script>