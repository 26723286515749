<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="container">
        <div class="card mx-auto" style="width: 18rem;">
          <img src="../assets/img/b2bLogin.jpg" class="card-img-top">
          <div class="card-body">
            <!-- <h5 class="card-title"></h5> -->
            <form>
              <div class="form-floating mt-3">
                <input type="text" class="form-control" placeholder="id" v-model="bId" ref="txt_bId">
                <label for="floatingInput">Admin-ID</label>
              </div>
              <div class="form-floating mt-2">
                <input type="password" class="form-control" id="floatingPassword" placeholder="Password" v-model="bPass" ref="txt_bPass">
                <label for="floatingPassword">Password</label>
              </div>
              <button class="w-100 btn btn-lg btn-primary mt-3" type="button" ref="enterBtn" @click="loginCheck()">Log-In</button>
            </form>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
// import moment from 'moment';
export default {
  data() {
    return {
      loginInfo: [],
    }
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      // 로그인전: bId정보없음, 로그인후: bId정보있음
      return this.$store.state.user;
    }
  },
  mounted() {
    document.addEventListener("keydown",this.handleEnterKeyPress);

    ////document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
    document.getElementById("footer-pc").style.display = "none";
    document.getElementById("footer-m").style.display = "none";


  },
  beforeUnmount() {
    document.removeEventListener("keydown",this.handleEnterKeyPress);
  },
  methods: {
    handleEnterKeyPress(event)
    {
      if(event.keyCode === 13)
      {
        this.$refs.enterBtn.click();
      }
    },
    async loginCheck() 
    {
      let swal = require('sweetalert2');
      let loginInfo = await this.$api("/api/checkLogin", {param: [this.bId]});
      this.loginInfo = loginInfo;
      //console.log("loginInfo:",loginInfo);
      if(loginInfo.length < 0) {
        this.$refs.txt_bId.select();
        //var swal = require('sweetalert2');
        swal.fire({
          text: 'ID를 확인해 주세요',
          icon: 'error'
        });
        // this.$router.push({path: '/'});
      }
      else{
        //console.log("bPass:", this.bPass);
        loginInfo.forEach(loginInfo=>{
          if(loginInfo.handlerPass === this.bPass) {
            swal.fire({
              text: `안녕하세요? ${loginInfo.handlerNameKor} 님`,
              icon: 'success'
            });
            // 로그인 & 세션 처리
            this.$api("/api/loginB2B", {
              param: [
                //bId: 접속자ID, bName: 접속자이름, bGrade: 작업레벨, admin: loginInfo.admin, alimCall: loginInfo.alimCall
                { bId: loginInfo.handlerId, bName: loginInfo.handlerNameKor, bGrade: loginInfo.handlerGrade, alimCall: loginInfo.alimCall, 
                divCode: loginInfo.divCd, compCode: loginInfo.compCode}
              ]
            })
            //.then((res) => {alert(res)})
            ;
            // location.reload();
            this.$store.commit("user", loginInfo);

            localStorage.setItem('sessionExpiration', new Date().getTime() + ((60 * 60)*12) * 1000);
            
            //this.$router.push({path: '/admin'});
            this.$router.push({path: '/reserveInfoList_admin'});
          }
          else {
            this.$refs.txt_bPass.select();
            swal.fire({
              text: 'Password를 확인해주세요',
              icon: 'error'
            });
          }
        })
      }
    }
  }
}
</script>