<template>
  <div id="pointlist">
    <main>
      <div class="container my-4">
        <div class="row justify-content-center">
          <div class="col-12">
            <h3>포인트 조회</h3>

            <!-- 포인트 메인 -->
            <div class="p-top">
              <div class="container text-center">
                <!-- 보유포인트 -->
                <div class="row">
                  <p class="mb-0 nowp">{{ this.getCurrencyFormat(this.availPoint) }} P</p>
                  <p class="mb-0">{{ this.k_name }} 님의 보유 포인트</p>
                </div>
                <!-- 사용 내역 -->
                <div class="row p-main">
                  <div class="col-6 pdivid">
                    <span>총적립포인트</span>
                    <span class="text-primary">+ {{ this.getCurrencyFormat(this.totAddPoint) }} P</span>
                  </div>
                  <div class="col-6 minusPoint">
                    <span>총사용포인트</span>
                    <span class="text-danger">- {{ this.getCurrencyFormat(this.totUsePoint) }} P</span>
                  </div>
                </div>

              </div>
            </div>

            <!-- 포인트 내용 -->
            <div class="p-info">
              <div class="p-title">
                <p>사용 현황</p>
                <div class="p-option">
                  <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode" v-model="con" @change="changeCondition(con)">
                    <option :value="noVal" disabled>현황조회</option>
                    <option value="all" >전체</option>
                    <option value="A" >적립</option>
                    <option value="U" >사용</option>
                    <option value="C" >취소</option>
                  </select>
                </div>
              </div>
              
              <table class="table">
                <thead>
                  <tr>
                    <th class="col-3">날짜</th>
                    <th class="col-4">적립 / 사용내역</th>
                    <th class="col-3">사용기한</th>
                    <th class="col-2">포인트</th>
                  </tr>
                </thead>
                <tbody class = "small">
                  <tr :key="i" v-for="(uPoint, i) in resUserPoint">
                    <td v-if="uPoint.useFlag === 'A'">{{ this.getDateFormat(uPoint.pointAddDate) }} (적립)</td>
                    <td v-else-if="uPoint.useFlag === 'U'">{{ this.getDateFormat(uPoint.pointAddDate) }} (사용)</td>
                    <td v-else-if="uPoint.useFlag === 'C'">{{ this.getDateFormat(uPoint.pointAddDate) }} (취소)</td>

                    <td class="pContents">
                      <a @click="goToReqReserveDetail(uPoint.reserNo, uPoint.pdtCode, uPoint.p_ntCd);" style="cursor:pointer;color:blue;">
                        {{ uPoint.pdtNameKor }}
                      </a>&nbsp;
                      <small>{{ uPoint.pointRemarks }} </small>
                    </td>

                    <td v-if="uPoint.useFlag === 'A'">{{ this.getDateFormat(uPoint.pointExpDate) }}</td>
                    <td v-else-if="uPoint.useFlag === 'U'"></td>
                    <td v-else-if="uPoint.useFlag === 'C'"></td>

                    <td v-if="uPoint.useFlag === 'U' || uPoint.useFlag === 'S'" class="text-danger">{{ this.getCurrencyFormat(uPoint.userPoint) }}</td>
                    <td v-else-if="uPoint.useFlag === 'A' || uPoint.useFlag === 'W'" class="text-primary">{{ this.getCurrencyFormat(uPoint.userPoint) }}</td>
                    <td v-else-if="uPoint.useFlag === 'C'" class="text-warning">{{ this.getCurrencyFormat(uPoint.userPoint) }}</td>
                    <!-- <td>{{ this.getCurrencyFormat(this.userTotalPoint.total_A) }}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- 포인트 안내사항 -->
            <div class="p-guide">
              <p><i class="bi bi-square-fill" style="font-size:3px; padding-right:5px"></i> 고객님! 적립된 포인트로 즐거운 여행하세요!</p>
              <p><i class="bi bi-square-fill" style="font-size:3px; padding-right:5px"></i> 포인트는 최소 10P 이상이 되어야 사용하실 수 있습니다. 포인트는 상품마다 다를 수 있으며, 규정에 따라 적립됩니다.</p>
              <p><i class="bi bi-square-fill" style="font-size:3px; padding-right:5px"></i> 포인트는 항공권, 선박권, 입장권, 교통패스, 호텔 이용시 적립이 불가하며 사용하실 수 없습니다. (유효기간 : 적립 후 6개월 또는 지정기한 내)</p>
              <p><i class="bi bi-square-fill" style="font-size:3px; padding-right:5px"></i> 포인트 관련 자세한 내용은 <a href="/customerPoint">[포인트약관]</a> 을 참고해주세요</p>
            </div>

          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {getAvailPoint} from "../assets/js/commonFunctions.js" //call the namedFunction
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      rProc: [],
      userTotalPoint: [],   //유형별 포인트총함
      totAddPoint: 0,       //누적포인트합
      totUsePoint: 0,       //사용포인트합
      resUserPoint: [],
      availPoint: 0         // 사용자 가용포인트
      //reqList: [],
    };
  },
  created() {
    this.bSeq = this.$route.query.bSeq;
    console.log("userInfo:",this.$store.state.user);
    // console.log("userInfo:",this.$store.state.user.email,"alimCall:", this.$store.state.user.alimCallC);
    // console.log("userInfoAA:",this.user.email,"alimCallAA:", this.user.alimCallC);

    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }

    if(this.user != undefined || this.user != "")
    {
      this.getTotUserPoint();
      this.getUserPoint();
      this.getAvailPoint();

      //카카오알림용 인적정보
      if(this.user.joinFlag === "K" || this.user.joinFlag === "E" || this.userInfo.joinFlag == 'H')
      {
        this.k_name = (!this.user.nickname) ? "" : this.user.nickname;

        let kakaoPhone = ""
        if(this.user.phone != undefined &&this.user.phone.charAt(0) === '+')
        {
          //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
          kakaoPhone = this.user.phone.replace("+82 ", "0")
        }
        this.k_tel = (!this.user.phone) ? "" : kakaoPhone.replace(/-/g,""); //-제거       
      }
      else
      {
        this.k_name = this.user.name;
        this.k_tel = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
      }
      console.log("k_name:",this.k_name, "k_tel:",this.k_tel)
    }
    // //카카오알림용 인적정보
    // if(this.user.joinFlag === "K" || this.user.joinFlag === "E" || this.userInfo.joinFlag == 'H')
    // {
    //   this.k_name = (!this.user.nickname) ? "" : this.user.nickname;

    //   let kakaoPhone = ""
    //   if(this.user.phone != undefined &&this.user.phone.charAt(0) === '+')
    //   {
    //     //console.log("kakao:",this.user.phone.replace("+82 ", "0"))
    //     kakaoPhone = this.user.phone.replace("+82 ", "0")
    //   }
    //   this.k_tel = (!this.user.phone) ? "" : kakaoPhone.replace(/-/g,""); //-제거       
    // }
    // else
    // {
    //   this.k_name = this.user.name;
    //   this.k_tel = (!this.user.mobile) ? "" : this.user.mobile.replace(/-/g,""); //-제거
    // }
    // console.log("k_name:",this.k_name, "k_tel:",this.k_tel)
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    window.scrollTo(0, 0);

    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    
    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/b2CLogin', query:{st: "Y"}});
    }    
    // //권한없는 로그인 차단
    // if(this.user.handlerGrade >= 20) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '로그인 필요',
    //     text: '접근권한 확인불가.',
    //     icon: 'error'
    //   });
    //   this.$router.push({path: '/'});
    // }
  },
  methods: {
    // 숫자 3자리마다 콤마 찍기
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },    
    getDateFormat(date) {
      return this.$dateFormat(date);
    },

    //포인트 차감유형별 조회
    async changeCondition(con) {
      this.getUserPoint(con);
    },

    //상태별 포인트합계
    async getTotUserPoint() 
    {
      let userTotalPoint = await this.$api("/api/userTotalPoint", {param: [this.user.email]});
      if(userTotalPoint.length > 0) 
      {
        this.userTotalPoint = userTotalPoint;
      }
      //console.log("userTotalPoint:",this.userTotalPoint);

      let totAddPoint = this.userTotalPoint[0].total_A
      this.totAddPoint = totAddPoint

      let totUsePoint = this.userTotalPoint[0].total_U
      this.totUsePoint = totUsePoint
    },

    async getUserPoint(con) 
    {
      if (con === "all" || con === "" || con == undefined) 
      {
        con = "%"; 
      }

      //펑션호출시점에서 일단 초기화
      this.resUserPoint = []
      let resUserPoint = []

      resUserPoint = await this.$api("/api/getUserPoint", {param: [this.user.email, con]});
      if(resUserPoint.length > 0) 
      {
        this.resUserPoint = resUserPoint;
      }
    },

    async getAvailPoint() 
    {
      this.availPoint = await getAvailPoint(this.$api, this.user.email)
    },

    //적립,사용예약으로
    goToReqReserveDetail(seqReq, pCode, ntCode1)
    {
      console.log(seqReq, pCode, ntCode1)
      if(seqReq.substr(0,2) === "RQ")
      {
        this.$router.push({path: '/reserveInfoDetail_client', query: {seqReq: seqReq, pCode: pCode, cCode: 'Y'}});
      }
      else
      {
        this.$router.push({path: '/eventInfo_client', query: {eCode: seqReq, ntCd: ntCode1}});
      }
    },
  }
}
</script>


<style>
#pointlist{
  margin:4rem 0 8rem;
}
/* @media (max-width:992px){
  #pointlist{
    margin:0rem 0 4rem;
  }
} */

#pointlist h3{
  color:#000;
  font-weight: 600;
  font-size: 1.8rem;
  margin-bottom:1.5rem;
  text-transform: uppercase;
  text-align: center;
}



/*--------------------------------------------------------------
# 포인트 메인
--------------------------------------------------------------*/
#pointlist .p-top{
  margin:4rem 0 5rem
}
#pointlist .p-top p{
  font-size:1.3rem;
  color:#000
}
#pointlist .p-top .nowp{
  font-size:2rem;
  font-weight: 600;
}
#pointlist .p-main{
  margin-top:1.5rem;
  border:1px solid #000;
  padding:25px 0
}
#pointlist .p-main span{
  font-weight: 600;
  color:#000;
  padding:0 7px
}
#pointlist .p-main .pdivid{
  border-right:1px solid #000
}



/*--------------------------------------------------------------
# 포인트 내용
--------------------------------------------------------------*/
#pointlist .p-info{
  margin:4rem 0
}

/* ----- 제목 ----- */
#pointlist .p-info .p-title p{
  font-size:1.3rem;
  font-weight: 600;
  color:#000;
  margin-bottom:.8rem
}
#pointlist .p-info .p-title .p-option{
  float: right;
  margin-top:-41px
}

/* ----- 테이블 내용 ----- */
#pointlist .table{
  border-color:#e0e0e0; 
  border-top:2px solid #000;
  text-align: center;
}
#pointlist .table thead th{
  font-size:.9rem;
  font-weight:600;
  max-width:50px;
  padding:15px 0;
  background-color: #f8f8f8;
}
@media (max-width:370px){
  #pointlist .table thead th{
    font-size:.7rem
  }
}
#pointlist .table tbody td{
  padding:20px 0
}



/*--------------------------------------------------------------
# 포인트 안내사항
--------------------------------------------------------------*/
#pointlist .p-guide{
  padding:20px 0 0 10px;
  text-align:left;
  font-size:.85rem;
  line-height:23px;
}
#pointlist .p-guide p{
  margin-bottom: .3rem;
}
#pointlist .p-guide p a{
  padding:0 5px;
  color:#000;
  font-weight: 600;
  text-decoration: underline;
}

/*--------------------------------------------------------------
  # 모바일화면 포인트
--------------------------------------------------------------*/
/* CSS 파일이나 <style> 태그 안에 추가 */
@media (max-width: 768px) {
  .p-main .pdivid span, 
  .p-main .minusPoint span,
  .pContents small
  {
    display: block; /* <span> 요소를 블록 레벨 요소로 변경하여 개행 처리 */
    margin-bottom: 10px; /* 필요에 따라 마진을 조정하여 간격을 설정 */
  }
}
</style>
