<template>
  <div id="sub">

    <!-- 연계상품리스트 -->
    <section id="pack" class="packages">
			<div class="container">
				<div class="packages-content">
					<div class="row gx-4">
            <!-- 제목 -->
            <div class="sub-title" id="world-etc">
              <div  v-if="assoFlag != ''">
                <div v-if="assoFlag === 'lj'" class="assoText">
                  <!-- <img :src="getImageUrl(assoFlag)" class="assoLogo"> -->
                  <img src="../assets/img/asso/jin.png" class="assoLogo_jin">
                  <span class="text-content">&nbsp;와&nbsp;&nbsp;함께하는&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <img src="../assets/img/asso/nd.png" class="assoLogo_nd">
                  <span class="text-content">&nbsp;추천 일본 버스투어 특가!</span>
                </div>
                <div v-else>
                  <img :src="getImageUrl(assoFlag)" class="assoLogo">
                </div>
              </div>
              <!-- <img src="../../src/assets/img/asso/ab.png" class="assoLogo" v-if="this.assoFlag != ''"> -->
            </div>
            <!-- 카테고리 -->
            <!-- <div class="category">
              <ul>
                <li key="all" @click="showEtcSelArea('all')"><a href="javascript: void(0)">전체</a></li>
                <li :key="ntCd" v-for="(etc,ntCd) in this.ntToAr" @click="showEtcSelArea(etc[0].ntCd)"><a href="javascript: void(0)">{{ etc[0].NT }}</a></li>
              </ul>
            </div> -->
            <!-- 내용 -->
						<div class="col-lg-4 col-md-6 col-sm-12 mb-5 mt-5" :key="j" v-for="(nd, j) in this.productListForSales">
              <a class="text-decoration-none link-dark" href="javascript: void(0)"  @click='goToDetail(nd.pdtCode,nd.salesRoute,nd.assoFlag)' style="cursor: pointer">
								<div class="package-item">
									<div class="pic">  
										<div class="packages-hover">
											<div class="packages-hover-content"></div>
										</div>
										<img v-if="nd.imgName != 'noImage'" :src="`/download/${nd.pdtCode}/${nd.imgName}`" class="card-img-top" alt="..."/>
										<img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>
                    <!-- <div class="badge">
                      <div v-if="nd.badgeFlag === 'Y'">
                        <div class="badge1">
                          <small class="badge1-text">{{ nd.badge }}</small>
                        </div>
                      </div>
                      <div v-if="nd.badge2Flag === 'Y'">
                        <div v-if="nd.badgeFlag != 'Y'" class="badge1">
                          <small class="badge1-text">{{ nd.badge2 }}</small>
                        </div>
                        <div v-else class="badge2" style="margin-left: 180px;">
                          <small class="badge2-text">{{ nd.badge2 }}</small>
                        </div>
                      </div>
                    </div> -->
									</div>

									<div class="package-item-txt">
										<h3>
											{{ nd.pdtNameKor }}
										</h3>
										<div class="packages-para">
											<p>
												<i class="bi bi-bus-front-fill"></i> {{ nd.pdtRemarks }}
											</p>
										</div>

                    <hr>

										<div class="price">

                      <!-- 비로그인과 일반회원 멤버십표시 -->
                      <div>
                        <!-- original가격(default) -->
                        <div v-if="nd.promoStatus === 'Y'" class="my-1">
                          <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(nd.sMinPrice)}}</b>
                            <small><i class="bi bi-caret-right-fill"></i></small>
                          <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                          <small><span>원~</span></small>
                        </div>

                        <div v-else-if="nd.promoStatus === 'E'" class="my-1">
                          <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                          &nbsp;
                          <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.pPrice)}}</b>
                          <small><span>원~</span></small>
                        </div>
                        
                        <div v-else-if="nd.promoStatus === 'N'" class="my-1">
                          <div class="my-1">
                            <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                          &nbsp;
                          <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.orgPrice)}}</b>
                            <small><span>원~</span></small>
                          </div> 
                        </div>
                      </div>
										</div>
									</div>
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>

      <!-- 배너 -->
      <div class="banner2 d-flex justify-content-center">
        <!-- <a href="/membershipPage" onclick="window.open('/membershipPage', '_blank', 'top=100')"> -->
        <a href="/membershipPage" target="_blank"> 
          <img src="../assets/img/new-test/banner3.jpg" class="img-fluid pc">
          <img src="../assets/img/new-test/banner3-2.jpg" class="img-fluid pad">
          <img src="../assets/img/new-test/banner3-3.jpg" class="img-fluid mobile">
        </a>
      </div>
    </section>
    

    
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      ntToAr: {},                   //버스투어가능국가지역
      ntToAr_jp: [],                //일본내 버스투어가능지역
      ntToAr_etc: [],               //버스투어가능 국가와 지역
      nt: "",                       //상품카테고리(국가기준)
      ar: "",                       //상품카테고리(지역기준)

      sellerCode: "ND",             //판매자코드

      productListForSales_org: [],  //로컬전체상품
      productListForSales: [],      //판매상품리스트
      etc_more: "N",                //일본외 더보기

      //showLayer: false,           //지역(국가)선택레이어

      natName: "",                      //표시페이지 국가명
      selectableNation: [],             //선택가능 국가

      nationList: {},                   //여행지역리스트
      pdtCatList: {},                   //상품카테고리
      
      productListForSales_recomend: {}, //추천상품리스트
      pPriceY: 0,
      pPriceN: 0,
      pPriceE: 0,
      
      dispPrice: [],        //가격표시방법(mem / promo)

      promoPrice: 0,        //프로모션할인액
    };
  },
  created() {
    // 페이지생성과 동시에 리스트정보를 get
		this.nt = (this.$route.query.nt == undefined) ? "%" : this.$route.query.nt;
    this.ar = (this.$route.query.ar == undefined) ? "%" : this.$route.query.ar;
		this.sellerCode = (this.$route.query.sc === undefined) ? "ND" : this.$route.query.sc;
    this.assoFlag = this.$route.query.assoFlag
    //console.log("assoFlag: ", this.assoFlag)

    this.getProductList();
    this.getNationList();
    this.getBustourArea();
  },
  mounted() {
    if(this.assoFlag == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'warning').then(() => {
        location.replace("https://ntabi.co.kr");
      })
    }

    //상세정보와 무관한 내용 비표시처리
    document.getElementById("user").style.display = "none";
    document.getElementById("userM").style.display = "none";
    ////document.getElementById("notice").style.display = "none";
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      // 로그인전: bId정보없음, 로그인후: bId정보있음
      return this.$store.state.user;
    }
  },  
  methods: {
    getImageUrl(flag) {
      try {
        // require를 사용하여 동적으로 이미지 로드
        return require(`@/assets/img/asso/${flag}.png`);
      } catch (error) {
        console.error("이미지를 찾을 수 없습니다:", error);
        return ''; // 이미지가 없을 경우 대체 이미지 경로 또는 빈 문자열 반환
      }
    },

    async getNationList() 
    {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      
      let natName = nationList.filter((item) => {
        return item.baseCode == this.nt
      })
      this.natName = natName.codeNameKor
      //console.log("nationList:",this.nationList,",natName:",this.natName)
    },

    //선택가능한 버스투어 지역정보
    async getBustourArea() {
      let bustourArea = await this.$api("/api/getBustourArea_asso", {param:[this.assoFlag]})

      //console.log("bustourArea:", bustourArea)

      let ntToAr = {}

      if(bustourArea.length > 0)
      {
        this.bustourArea = bustourArea
        //국가별 지역으로 정리
        bustourArea.forEach(item => {
          if(!ntToAr[item.ntCd])
          {
            ntToAr[item.ntCd] = [];
          }
          ntToAr[item.ntCd].push({
            ntCd: item.ntCd,
            arCd: item.arCd,
            NT: item.NT,
            AR: item.AR
          })
        })
      }
      this.ntToAr = ntToAr
    },


    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },
		/** 일자처리 */
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },

    async getProductList() 
    {
      let ntCd = this.nt
      //let arCd = this.ar
      let sRoute = "L";
      let sMinPrice = 0;
      let sMargin = 0;

      //console.log("ntCd:",ntCd,"arCd:",arCd);

      //전체상품추출
      let productListForSales_tmp = await this.$api("/api/productListForSales_asso", {param: ['%', sRoute, this.assoFlag]});
      // let productListForSales_tmp = productListForSales_tmptmp.filter((item) => {
      //   return (item.assoFlag == null || item.assoFlag == "" || item.assoFlag == undefined)
      // })
      
      let i = 0;
      while(i < productListForSales_tmp.length)
      {
        if(productListForSales_tmp[i].marginMethod === "P")
        {
          sMargin = productListForSales_tmp[i].minPrice * (productListForSales_tmp[i].basicMargin / 100);
        }
        else
        {
          sMargin = productListForSales_tmp[i].basicMargin;
        }
        sMinPrice = productListForSales_tmp[i].minPrice + sMargin;
        productListForSales_tmp[i].sMinPrice = sMinPrice;
        i++;
      }

      this.productListForSales_org = productListForSales_tmp    //로컬전체상품(org)
      console.log("productListForSales_org: ", this.productListForSales_org)

			//엔데트립 상품만 추출
			if(this.sellerCode === 'ND')
			{
				let productListForSales_ndaytrip1 = this.productListForSales_org.filter((item) => 
				{
					return item.sellerCode_nd == "Y";
				});

        let productListForSales_ndaytrip = productListForSales_ndaytrip1.sort((a, b) => {
          if (a.ntCd == 'JP' && b.ntCd != 'JP') {
            return -1;
          } else if (a.ntCd != 'JP' && b.ntCd === 'JP') {
            return 1;
          } else if (a.ntCd < b.ntCd) {
            return -1;
          } else if (a.ntCd > b.ntCd) {
            return 1;
          } else {
            return 0;
          }
        });
        
				this.productListForSales = productListForSales_ndaytrip;
			}

      //console.log("productListForSales: ", this.productListForSales)

      //상품가격계산 최저가기준 표시 + 마진적용
      let marginPrice = 0;
      let promoPrice = 0;

      //상품가격계산 최저가기준 표시 + 마진적용(성인기준)
      // let maxDC_P = 0;        //프리미엄 최대환급율
      // let maxDC_S = 0;        //스탠다드 최대환급율
      // let dcForMem_P = 0      //이외상품 프리미엄 할인율
      // let dcForMem_S = 0      //이외상품 스탠다드 할인율
      // let dcForStd = 0        //프리이엄전용을 스탠다드가 구매시 할인율

      // let price_memP = 0      //프리미엄 환급액
      // let price_memS = 0      //스탠다드 환급액

      // let price_dcForStd = 0  //프리이엄전용을 스탠다드가 구매시 할인액

      // let price_dcForMem_P = 0  //일반상품을 프리미엄이 구매시 할인액
      // let price_dcForMem_S = 0  //일반상품을 스탠다드가 구매시 할인액

      for (let i = 0; i < this.productListForSales.length; i++) 
      {
        if(this.productListForSales[i].marginMethod == "P")
        {
          marginPrice = this.productListForSales[i].minPrice * (this.productListForSales[i].basicMargin / 100);
        }
        else
        {
          marginPrice = this.productListForSales[i].basicMargin;
        }
        //console.log("marginPrice:",marginPrice);

        ////프로모션 상태에 따른 프로모션가격
        if (this.productListForSales[i].promoStatus === "Y") 
        {
          if (this.productListForSales[i].promoMethod === "P") 
          {
            promoPrice = (this.productListForSales[i].minPrice+marginPrice)*(this.productListForSales[i].promoRate / 100);
          }
          else
          {
            promoPrice = this.productListForSales[i].promoRate;
          }
          this.promoPrice = promoPrice.toFixed(0)*1  //프로모션 할인액
        }
        //console.log("promoPrice:",promoPrice);

        // if (this.productListForSales[i].memLevel_P === "Y" || this.productListForSales[i].memLevel_S === "Y") 
        // {
        //   //최대환급율
        //   maxDC_P = (this.productListForSales[i].maxDC_P == undefined) ? 0 : this.productListForSales[i].maxDC_P*1
        //   maxDC_S = (this.productListForSales[i].maxDC_S == undefined) ? 0 : this.productListForSales[i].maxDC_S*1
        //   //프리미엄전용을 스탠다드가 구매시 할인율
        //   dcForStd = (this.productListForSales[i].dcForStd == undefined) ? 0 : this.productListForSales[i].dcForStd*1

        //   //환급액
        //   price_memP = (this.productListForSales[i].minPrice+marginPrice)*(maxDC_P / 100);
        //   price_memS = (this.productListForSales[i].minPrice+marginPrice)*(maxDC_S / 100);
        //   //소숫점 절사
        //   this.price_memP = price_memP.toFixed(0)*1   //price_memP*1
        //   this.price_memS = price_memS.toFixed(0)*1   //price_memS*1
          
        //   //프리미엄전용을 스탠다드가 구매시 할인액 
        //   price_dcForStd = (this.productListForSales[i].minPrice+marginPrice)*(dcForStd / 100); 
        //   this.price_dcForStd = price_dcForStd.toFixed(0)*1
        // }

        // //일반상품, 엔데어||버라이어티의 멤버십등급에 따른 할인액1
        // if(this.productListForSales[i].salesRoute == "T" || (this.productListForSales[i].salesRoute == 'L' && (this.productListForSales[i].nrFlag == 'Y' || this.productListForSales[i].vrFlag == 'Y')))
        // {   
        //   dcForMem_P = (this.productListForSales[i].dcForMem_P == undefined) ? 0 : this.productListForSales[i].dcForMem_P*1
        //   dcForMem_S = (this.productListForSales[i].dcForMem_S == undefined) ? 0 : this.productListForSales[i].dcForMem_S*1

        //   //할인방식에 따라 분기처리
        //   if(this.productListForSales[i].dcRateForMem_P === "P")
        //   {
        //     price_dcForMem_P = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_P/100)
        //   }
        //   else
        //   {
        //     price_dcForMem_P = this.productListForSales[i].dcForMem_P*1
        //   }
          
        //   if(this.productListForSales[i].dcRateForMem_S === "P")
        //   {
        //     price_dcForMem_S = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_S/100)
        //   }
        //   else
        //   {
        //     price_dcForMem_S = this.productListForSales[i].dcForMem_S*1
        //   }

        //   this.price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
        //   this.price_dcForMem_S = price_dcForMem_S.toFixed(0)*1

        //   //프리미엄과 스탠다드 할인액을 배열에 추가
        //   this.productListForSales[i].price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
        //   this.productListForSales[i].price_dcForMem_S = price_dcForMem_S.toFixed(0)*1          
        // }

        // //멤버십미지정 로컬투어
        // else if(this.productListForSales[i].salesRoute == 'L' && ((this.productListForSales[i].memLevel_P == null || this.productListForSales[i].memLevel_P == 'N') && (this.productListForSales[i].memLevel_S == null || this.productListForSales[i].memLevel_S == 'N')))
        // {
        //   dcForMem_P = (this.productListForSales[i].dcForMem_P == undefined) ? 0 : this.productListForSales[i].dcForMem_P*1
        //   dcForMem_S = (this.productListForSales[i].dcForMem_S == undefined) ? 0 : this.productListForSales[i].dcForMem_S*1

        //   price_dcForMem_P = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_P/100)
        //   price_dcForMem_S = (this.productListForSales[i].minPrice + marginPrice)*(dcForMem_S/100)

        //   this.price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
        //   this.price_dcForMem_S = price_dcForMem_S.toFixed(0)*1

        //   //프리미엄과 스탠다드 할인액을 배열에 추가
        //   this.productListForSales[i].price_dcForMem_P = price_dcForMem_P.toFixed(0)*1
        //   this.productListForSales[i].price_dcForMem_S = price_dcForMem_S.toFixed(0)*1          
        // }

        //화면표시용 가격
        //구매자 멤버십
        /*
        if(this.user.memLevel === 'P' || this.user.memLevel === 'S')
        {
          //this.productListForSales[i].pPrice = "멤버십가격"
          //멤버십 + 멤버십상품
          if(this.productListForSales[i].memLevel_P === "Y" || this.productListForSales[i].memLevel_S === "Y")
          {
            //this.productListForSales[i].pPrice = "멤버용 상품가격" 
            
            //유효프로모션 존재
            if (this.productListForSales[i].promoStatus === "Y")
            {
              if(this.user.memLevel === 'P')
              {
                //멤버십환급과 프로모션할인을 비교하여 큰 금액으로 표시
                if(this.price_memP >= this.promoPrice && this.price_memP > 0)
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].memP = this.price_memP  //환급액
                  this.productListForSales[i].forStd = this.price_dcForStd  //스탠다드를 위한 할인율
                }
                else if(this.price_memP < this.promoPrice || this.price_memP == 0) 
                {
                  this.productListForSales[i].dispPrice = "promo"       //표시방식 프로모션
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
              else if(this.user.memLevel === 'S' && this.price_memS > 0)
              {
                if(this.price_memS >= this.promoPrice)
                {
                  this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice)
                  this.productListForSales[i].memS = this.price_memS  //환급액
                }
                else if(this.price_memS < this.promoPrice || this.price_memS == 0) 
                {
                  this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                }
              }
            }

            //프로모션 없음
            else
            {
              if(this.user.memLevel === 'P')
              {
                this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].memP = this.price_memP  //환급액
              }
              else if(this.user.memLevel === 'S')
              {
                this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice)
                this.productListForSales[i].memS = this.price_memS  //환급액
              }
            }
          }

          //멤버십+일반상품
          else
          {
            //일반상품지정(패키지, 로컬+엔데어 || 버라이어티)
            if(this.productListForSales[i].salesRoute == 'T' || (this.productListForSales[i].salesRoute === 'L' && (this.productListForSales[i].nrFlag == 'Y' || this.productListForSales[i].vrFlag == 'Y')))
            {
              //this.productListForSales[i].pPrice = "멤버용 일반상품가격1" 
              //유효프로모션 존재
              if (this.productListForSales[i].promoStatus === "Y")
              {
                if(this.user.memLevel === 'P')
                {
                  //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                  if(this.price_dcForMem_P >= this.promoPrice && this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].memDC = "Y"       //멤버십할인적용
                  }
                  else if(this.price_dcForMem_P < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S >= this.promoPrice && this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].memDC = "Y"     //멤버십할인적용
                  }
                  else if(this.price_dcForMem_S < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
              }
              //프로모션 없음
              else
              {
                if(this.user.memLevel === 'P')
                {
                  if(this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "promo"       //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"               //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_P
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_P
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"       //표시방식 멤버십
                    this.productListForSales[i].dispPrice_S = "promo"       //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"               //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_S
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) -this.price_dcForMem_S
                }
              }
            }
            
            //멤버십+일반상품유형2(로컬+멤버십미지정)
            else if(this.productListForSales[i].salesRoute == 'L' && ((this.productListForSales[i].memLevel_P == null || this.productListForSales[i].memLevel_P == 'N') && (this.productListForSales[i].memLevel_S == null || this.productListForSales[i].memLevel_S == 'N')))
            {
              this.productListForSales[i].pPrice = "멤버용 일반상품가격2" 
              //유효프로모션 존재
              if (this.productListForSales[i].promoStatus === "Y")
              {
                if(this.user.memLevel === 'P')
                {
                  //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                  if(this.price_dcForMem_P >= this.promoPrice && this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  }
                  else if(this.price_dcForMem_P < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S >= this.promoPrice && this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                  }
                  else if(this.price_dcForMem_S < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                    this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.promoPrice
                  }
                }
              }
              //프로모션 없음
              else
              {
                if(this.user.memLevel === 'P')
                {
                  if(this.price_dcForMem_P > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"  //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "promo"  //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"          //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                  this.productListForSales[i].pPrice_P = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_P
                }
                else if(this.user.memLevel === 'S')
                {
                  if(this.price_dcForMem_S > 0)
                  {
                    this.productListForSales[i].dispPrice = "promo"   //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"           //멤버십할인적용
                  }
                  else
                  {
                    this.productListForSales[i].dispPrice = "mem"       //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                  this.productListForSales[i].pPrice_S = (this.productListForSales[i].minPrice + marginPrice) - this.price_dcForMem_S
                }
              }
            }
          }
        }
        */
      //   //구매자 비멤버십
      //   else
      //   {
      //     //프로모션이 있으면 할인처리
      //     if (this.productListForSales[i].promoStatus === "Y")
      //     {
      //       this.pPriceY = (this.productListForSales[i].minPrice + marginPrice) - promoPrice;
      //       //console.log(`pPrice${i}: ${this.pPriceY}`);
      //       this.productListForSales[i].pPrice = this.pPriceY;
      //     } 
      //     else if (this.productListForSales[i].promoStatus == "E") 
      //     {
      //       this.pPriceE = this.productListForSales[i].minPrice + marginPrice;
      //       this.productListForSales[i].pPrice = this.pPriceE;
      //     } 
      //     else if (this.productListForSales[i].promoStatus == "N") 
      //     {
      //       this.pPriceN = this.productListForSales[i].minPrice + marginPrice;
      //       this.productListForSales[i].pPrice = this.pPriceN;
      //     }
      //   }
        //프로모션이 있으면 할인처리
        if (this.productListForSales[i].promoStatus === "Y")
        {
          this.pPriceY = (this.productListForSales[i].minPrice + marginPrice) - promoPrice;
          //console.log(`pPrice${i}: ${this.pPriceY}`);
          this.productListForSales[i].pPrice = this.pPriceY;
        } 
        else if (this.productListForSales[i].promoStatus == "E") 
        {
          this.pPriceE = this.productListForSales[i].minPrice + marginPrice;
          this.productListForSales[i].pPrice = this.pPriceE;
        } 
        else if (this.productListForSales[i].promoStatus == "N") 
        {
          this.pPriceN = this.productListForSales[i].minPrice + marginPrice;
          this.productListForSales[i].pPrice = this.pPriceN;
        }
      }





      this.productListForSales_more = this.productListForSales_org

      //엔데트 전체상품
      if((ntCd == "%" || ntCd == ""))
      {
        this.productListForSales_org.slice(0,6)
        this.productListForSales.slice(0,6)
        // this.productListForSales = ndaytirpList_jp.slice(0,6)
        // this.ndaytirpList_etc = ndaytirpList_etc.slice(0,6)
        return; //이후 조건에 따라 값이 배열값이 덮어써지는 것을 막는다
      }
    },


    showEtcSelArea(param) {
      if(param === 'all') 
      {
        //document.querySelector('.more-etc').style.display = 'block'
        // document.querySelector('.more-etc').classList.add('initial-state');
        this.productListForSales = this.productListForSales_org.slice(0,6)
      }
      else
      {
        //document.querySelector('.more-etc').style.display = "none"
        let productListForSales_tmp = this.productListForSales_org.filter(item => {
          return item.ntCd === param
        })

        this.productListForSales = productListForSales_tmp.slice(0,6)
      }
    },

    // //상품 더보기 처리
    // showEtcMoreList() {
    //   this.etc_more = (this.etc_more === 'Y') ? "N" : "Y"
    //   if(this.etc_more === "N")
    //   {
    //     //this.productListForSales = this.productListForSales_more
    //     this.productListForSales = this.productListForSales.slice(0,6)
    //   }
    //   else
    //   {
    //     //this.productListForSales = this.productListForSales.slice(0,6)
    //     this.productListForSales = this.productListForSales_more
    //   }
    // },



    goToDetail(productId, salesRoute, assoFlag) {
      this.$router.push({path: '/productDetail_asso', query: {productId: productId, salesRoute:salesRoute, asso:assoFlag}});
    },
    // 숫자콤마표시
    // getCurrencyFormat(value) {
    //   // console.log('value' + value)
    //   return (value+"").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

@media (min-width:991px){
  #sub{
    margin-top:-6rem
  }
}

/******************************* 
  # assoText
*******************************/
#world-etc .assoText {
  font-size: 2rem;
  display: flex;
  align-items: flex-end;
  font-weight: 800;
}

#world-etc .assoLogo_jin {
  width: 27%;
  vertical-align: bottom;
}

#world-etc .assoLogo_nd {
  width: 15%;
  vertical-align: bottom;
}

#world-etc .text-content {
  position: relative;
  bottom: -7px;
}

#world-etc div:not(.assoText) {
  display: flex;
  align-items: flex-end;
}




/*--------------------------------------------------------------
	# 서브카로셀
--------------------------------------------------------------*/
/* #sub .carousel-indicators .active {
  width:50px
}
#sub .carousel-control-prev, #sub .carousel-control-next, 
#sub .carousel-control-prev:hover, #sub .carousel-control-prev:focus, 
#sub.carousel-control-next:hover, #sub .carousel-control-next:focus{
  opacity:0
}
#sub .carousel-caption h5{
  font-family: 'Black Han Sans', sans-serif;
  font-size:3.5rem
}
#sub .carousel-caption p{
  font-size:1.3rem
}

@media (max-width:580px){
  #sub .carousel-caption h5{
    font-size:2.1rem
  }
  #sub .carousel-caption p{
    font-size:.9rem
  }
} */


/*--------------------------------------------------------------
	# 일본투어
--------------------------------------------------------------*/
.packages{
  margin: 5rem 0;
}

/* 제목 */
#sub .sub-title .st-main{
  color:#000;
  font-size:2rem;
  font-weight: 600;
  padding-right:20px
}

@media (max-width:767px) {
  #sub .sub-title .st-main{
    font-size:1.7rem;
  }
  #sub .sub-title .brdown{ 
    display: block;
  }
}


/* 카테고리 */
#sub .category{
  margin-top:.8rem
}
#sub .category ul{
  padding-left:0
}
#sub .category ul li{
  list-style: none;
  display: inline-block;
  padding:10px 30px;
  border:1px solid darkgray;
  border-radius: 50px;
  margin:5px 15px;
  transition: all .5s
}
#sub .category ul li:hover{
  background-color:rgba(4, 45, 20, 0.8);
}
#sub .category ul li:hover a{
  color:#fff
}

@media (max-width:1024px){
  #sub .category ul li{
    padding:5px 15px;
    margin:5px;
  }
  #sub .category ul li a{
    font-size:.85rem
  }
}


/* 내용 */
#sub .packages .package-item{
  -webkit-transition: .5s; 
  -moz-transition:.5s; 
  -ms-transition:.5s; 
  -o-transition:.5s;
  transition: .5s;
  height:auto;
}
@media (max-width: 991px){
	#sub .packages .package-item{
		margin-bottom:3.5rem;
	}
  #sub .packages .sub-etc{
    margin-bottom:1.5rem
  }
}

#sub .package-item .pic{
  position:relative;
}
#sub .package-item .card-img-top {
  width: 100%;
  min-height:350px;
  max-height:350px;
  object-fit: cover;
}

/* 멤버십 아이콘 */
#sub .package-item .mbus-icon{
  width: 2.5rem !important;
  height: auto !important;
}

#sub .package-item .pic .packages-hover {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;
  opacity:0;
  transition: opacity ease-in-out 0.5s;
}
#sub .package-item:hover .pic .packages-hover {
  opacity:.7;
}
#sub .package-item-txt{
  padding: 0 5px;
}
#sub .package-item h3{
  text-align:start;
  position: relative;
  font-size: 1rem;
  line-height:23px;
  color: #000;
  font-weight:600;
  padding-top: 15px;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
#sub .package-item-txt h3{
  height:60px;
}
#sub .packages-para{
  text-align:start;
  text-transform: capitalize;
	font-size: .75rem;
  color: #777;
}

#sub .packages-para-etc{
  text-align:center;
  text-transform: capitalize;
	font-size: .75rem;
  color: #777;
}

#sub .packages-para,.packages-para-etc p{
  margin-bottom:0;
  width: auto;
  height:20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#sub .packages-content .price {
	float:left;
  font-size: 1.2rem;
  font-weight:600;
  color:#111;
}

/* 뱃지 */
#sub .badge {
  /* width: 120px; */
  text-align: center;
}

#sub .badge .badge1{
  position: absolute;
  top:0;
  left:0;
  background-color:red;
  opacity: 0.7 !important;
  padding:15px 15px;
  width: 160px;
}
#sub .badge .badge1 .badge1-text{
  /* color:#fff; */
  color: white;
  font-size:1.2rem;
  padding:0
}

#sub .badge .badge2{
  position: absolute;
  top:0;
  left:0;
  /* left:100px; */
  background-color:white;
  opacity: 0.8 !important;
  padding:15px 15px;
  width: 160px;
  /* background:linear-gradient(#dacd4e, #97ca55); */
}
#sub .badge .badge2 .badge2-text{
  /* color:#fff; */
  color:#000;
  font-size:1.2rem;
  padding:0
}

/* 더보기 */
#sub .more-jp, .more-etc{
  margin:3rem 0 2rem;
  text-align: center;
}
#sub .more-jp p, .more-etc p{
  padding:15px 0;
  margin: 0 auto;
  border:1px solid #000;
  font-weight: 600;
  color: #000;
  transition: all .5s
}
#sub .more-jp p:hover, .more-etc p:hover{
  background-color:black;
}
#sub .more-jp p:hover a, .more-etc p:hover a{
  color:#fff
}

.fingerPointer {
  cursor: pointer;
}


/*--------------------------------------------------------------
	#배너
--------------------------------------------------------------*/
#sub .banner2{
  background-color: #49b8f8;
  width: 100%;
  height: auto;
  margin: 1rem 0;
}
#sub .banner2 .pc{
  display: block;
}
#sub .banner2 .pad{
  display: none;
}
#sub .banner2 .mobile{
  display: none;
}
@media (max-width:699px){
  #sub .banner2{
    margin:1rem 0;
  }
  #sub .banner2 .pc{
    display: none;
  }
  #sub .banner2 .pad{
    display: none;
  }
  #sub .banner2 .mobile{
    display: block;
  }
}
@media (min-width:700px) and (max-width:1800px){
  #sub .banner2 .pc{
    display: none;
  }
  #sub .banner2 .pad{
    display: block;
  }
  #sub .banner2 .mobile{
    display: none;
  }
}


/*--------------------------------------------------------------
	# 그 외 투어
--------------------------------------------------------------*/
#sub .sub-etc{
  margin-bottom:2rem
}
#sub .sub-etc img{
  border-radius: 15px;
}
#sub .sub-etc h3{
  text-align: center;
  -webkit-line-clamp: 1;
  height:auto
}
#sub .sub-etc .price{
  /* text-align: center; */
  text-align: start;
  padding-left: 1rem;
  float:none;
  font-size:1.3rem
}
#sub .sub-etc .pic .packages-hover{
  border-radius: 15px;
}
</style>

