<template>

  <div class="reserdetail">
        <section class="py-1">
          <div class="container my-3">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-11">
                <h2 class="d-flex justify-content-center mt-5" style="font-weight:600">예 약 내 역</h2>
  
                <!-- 상품안내 -->
                <div class="r-name container">
                  <div class="row col-12">
                    <p class="code">{{ this.eventInfo.spResCode }}</p>
                    <p class="fs-2 mt-0"><strong>{{ this.eventInfo.spName }}</strong></p>
                    <p class="ndate">{{this.getDateFormat(this.eventInfo.tripDate)}}</p>
                  </div>
                </div>
  
                <!-- 여행자정보 -->
                <div class="traval">
                  <div class="container">
                    <div class="title text-start">
                      <p>예약자 정보 <span class="recode">({{ this.eventInfo.spResCode }})</span></p>
                    </div>
  
                    <div class="text-start">
                      <table class="table">
                        <tbody>
                          <tr>
                            <th class="col-3">예약자</th>
                            <td class="col-9 text-start">{{this.eventInfo.payerName}}</td>
                          </tr>
                          <tr>
                            <th>연락처</th>
                            <td class="col-9 text-start">{{this.eventInfo.callNo}}</td>
                          </tr>
                          <tr>
                            <th>신청일</th>
                            <td class="col-9 text-start">{{this.getDateFormat(this.eventInfo.insDate)}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
  
                <!-- 예약정보 -->
                <div class="reser">
                  <div class="container">
                    <div class="title text-start">
                      <p>결제상태 <span class="recode" v-if="this.rpCode === 'nonWP'">(가상계좌)</span></p>
                    </div>
    
                    <div class="text-start">
                      <table class="table">
                        <tbody>
                          <tr>
                            <th class="col-3">상태</th>
                            <td class="col-9 text-start">
                              <div v-if="this.rpCode === 'nonWP'">
                                {{ this.accPayStatus }}
                              </div>
                              <div v-else>
                                <div class="col-md-9" v-if="this.eventInfo.reqStatus === 'RP' && this.paidFlag == 'Y'">
                                  결제가 완료되었습니다.
                                  <p><small class="text-muted">(담당자가 확인 중에 있습니다.)</small></p>
                                </div>
                                <div class="col-md-9" v-else-if="this.eventInfo.reqStatus === 'RP' && this.paidFlag != 'Y'">
                                  결제요청
                                </div>
                                <div class="col-md-9 text-primary" v-if="this.eventInfo.reqStatus === 'CO' && this.paidFlag == 'Y'">
                                  확정
                                </div>                                                            
                                <div class="col-md-9" v-else>
                                  {{ this.eventInfo.procName }}
                                </div>
                              </div>
                            </td>
                          </tr>                        
  
                          <tr>
                            <th>결제금액</th>
                            <td class="col-9 text-start">
                              <div class="fs-6">
                                {{getCurrencyFormat(this.eventInfo.payAmt)}}원
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
  
                <!-- 결제선택 -->
                <div class="reser mt-5">
                  <div class="container">
                    <div class="title text-start">
                      <p>예약결제</p>
                    </div>
  
                    <div class="text-start">
                      <table class="table">
                        <tbody>
                          <tr>
                            <th class="col-3">행사명</th>
                            <td class="col-9 text-start">{{ this.eventInfo.spName }}</td>
                          </tr>
                          <tr v-if="this.paidFlag == 'Y'">
                            <th>결제액</th>
                            <td class="col-9 text-start">
                              <div class="text-danger fs-5" v-if="this.eventInfo.reqStatus === 'RP' || this.eventInfo.reqStatus === 'CO' || this.eventInfo.reqStatus === 'CX'">
                                {{getCurrencyFormat(this.eventInfo.payAmt)}}원
                                (
                                  <span class="text-secondary fs-6">
                                    {{this.rtnRPflag_tmp.fName}} {{getCurrencyFormat(this.rtnRPflag_tmp.actualPayAmount)}} 원 + {{getCurrencyFormat(this.rtnRPflag_tmp.reqPayAmount - this.rtnRPflag_tmp.actualPayAmount)}}포인트
                                  </span>
                                )
                              </div>
                              <div class="text-muted fs-6" v-else>
                                상담완료 후, 안내됩니다.
                              </div>
                            </td>
                          </tr>
                          <tr v-else>
                            <th>결제액</th>
                            <td class="col-9 text-start">
                              <div class="text-danger fs-5" v-if="this.eventInfo.reqStatus === 'RP' || this.eventInfo.reqStatus === 'CO' || this.eventInfo.reqStatus === 'CX'">
                                {{getCurrencyFormat(this.eventInfo.payAmt)}} 원
                              </div>
                              <div class="text-muted fs-6" v-else>
                                상담완료 후, 안내됩니다.
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
  
                    <div class="col d-grid text-end" v-if="this.eventInfo.reqStatus === 'RP' && this.paidFlag != 'Y'">
                      <div v-if="this.eventInfo.payAmt >= 200">
                        <button type="button" class="btn ht-pay btn-outline-primary" @click="pgPay();">
                          <b class="fs-6">온라인결제</b>
                        </button>
                      </div>
                      <div v-else-if="this.eventInfo.payAmt == 0">
                        <button type="button" class="btn ht-pay btn-outline-success" @click="freePay(this.eventInfo.spResCode);">
                          <b class="fs-6">무료결제</b>
                        </button>
                      </div>
                      <div id="payment-method" v-show="showFlag" style="height:100%"></div>
                      <!-- <div id="agreement"></div> -->
                      <div>
                        <button @click="requestPayment(this.eventInfo.spResCode)" class="btn btn-primary" id="payment-button" v-show="showFlag" style="width:100%">
                          <b class="fs-6">결제하기</b>
                        </button>
                      </div>
                      <div class="mt-3 ht-ex">
                        <p>
                          <i class="bi bi-exclamation-triangle-fill text-danger"></i>&nbsp;
                          결제금액이 <b>200원 미만</b> 또는 온라인 결제가 어려운 경우, <span class="mobile-new-line">아래 계좌로 송금을 하실 수 있습니다.</span>
                        </p>
                        <p class="ht-big">
                          <b class="text-success">우리은행 1006-901-267484</b> (엔타비글로벌)                        
                        </p>
                        <p >(송금 후 <b>1660-4602</b> 또는 <b>카카오톡</b>으로 연락을 주시면 빠른 확인이 가능합니다)</p>
                      </div>
                    </div>

                  </div>
                </div>
  
              </div>
            </div>
          </div>
        </section>
    </div>  
  </template>
  
  <script>
  import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";

  export default {
    data() {
      return {
        showFlag: false,        //결제창 표시여부

        //토스페이결제용
        paymentWidget: null,
        paymentMethodWidget: null,
        //clientKey: "test_ck_YyZqmkKeP8gG2Klanln8bQRxB9lG",
        clientKey: "live_ck_jZ61JOxRQVEZXa1aoR0rW0X9bAqw",
        customerKey: "Ntabi-1936=@",

        eventInfo: [], //이벤트정보
  
        rtnRPflag: "N", //결제요청여부
        paidFlag: "N", //결제여부

        /////결제히스토리용 정보
        userPayInfo: [],              //히스토리용 결제내역(향후 내역은 이 배열을 이용)
        p1PayAmt: 0, 
        p1PayDate: "",
        p2PayAmt: 0, 
        p2PayDate:"",
        payPointDate: "",
        fPayFlag: "",                //예약금결제N, 전액결제Y
        totalPay: 0,                 //히스토리합산

        virAccInfo: [],             //가상계좌결제정보
        accPayStatus: "",           //가상계좌결제상태        
      };
    },
    computed: {
      user() {
        return this.$store.state.user;
      }
    },
    created() {
      this.spResCode = this.$route.query.seqReq;
      this.rpCode = this.$route.query.rpCode;
    },
    async mounted() {
      if(this.rpCode === 'nonRP' || this.rpCode === 'nonCO' || this.rpCode === 'nonWP')
      {
        this.checkNonMem(this.spResCode)
      }
    },
    methods: {
      async checkNonMem(rCode){
  
        if(this.rpCode === "nonRP" || this.rpCode === "nonWP")
        {
          let { value: formValues } = await this.$swal.fire({
          html:
            '<div class="searchNonRes">' +
            '<hr>' +
            '<b class="text-secondary fs-4">결 제 하 기</b><br>' +
            '<input id="nResName" class="swal2-input" placeholder="예약자성명" style="font-size:1.0rem;">' +
            '<input id="nResCall" class="swal2-input" placeholder="예약자연락처(01012345678)" style="font-size:1.0rem;">' +
            '<label id="nResCode" class="swal2-input" style="background:darkgray; font-size:1.0rem; color:white; padding-top:6px; vertical-align:middle; width:210px;" placeholder="예약번호">'+ rCode +'</label>' +
            '</div>',
          confirmButtonText: "확인",
          showCancelButton: true,
          cancelButtonText: "취소",
          imageUrl: '/IMGs/payment.png',
          imageWidth: 400,
          imageHeight: 200,        
          focusConfirm: false,
            preConfirm: () => {
              return [
                document.getElementById('nResName').value,
                document.getElementById('nResCall').value,
              ]
            }
          })
          
          if (!formValues)    //cancel 선택시
          {
            //location.href = "/"
            location.reload(true)
          }
          else
          {
            let nResName = formValues[0]
            let nResCall = formValues[1]
  
            let loginInfo_E = await this.$api("/api/checkSpPayInfo", {param: [nResName, nResCall, rCode]});
  
            if(loginInfo_E.length > 0)
            {
              this.loginInfo_E = loginInfo_E[0]
              this.$swal.fire("", '<b>'+ loginInfo_E[0].payerName + '님</b>, 반갑습니다.', "success")
              this.getEventInfo(rCode);
            }
            else 
            {
              this.$swal.fire("","카카오알림톡으로 안내된 정보를 확인해주세요<br>확인이 어려운 경우 문의처: <b class='text-danger'>1660-4062</b>","warning")
              location.reload(true)
            }
          }
        }
  
        else if(this.rpCode === "nonCO")
        {
          let { value: formValues } = await this.$swal.fire({
          html:
            '<div class="searchNonRes">' +
            '<hr>' +
            '<b class="text-secondary fs-4">확 정 확 인</b><br>' +
            '<input id="nResName" class="swal2-input" placeholder="예약자성명" style="font-size:1.0rem;">' +
            '<input id="nResCall" class="swal2-input" placeholder="예약자연락처(01012345678)" style="font-size:1.0rem;">' +
            '<label id="nResCode" class="swal2-input" style="background:darkgray; font-size:1.0rem; color:white; padding-top:6px; vertical-align:middle; width:210px;" placeholder="예약번호">'+ rCode +'</label>' +
            '</div>',
          confirmButtonText: "확인",
          showCancelButton: true,
          cancelButtonText: "취소",
          imageUrl: '/IMGs/payment.png',
          imageWidth: 400,
          imageHeight: 200,        
          focusConfirm: false,
            preConfirm: () => {
              return [
                document.getElementById('nResName').value,
                document.getElementById('nResCall').value,
              ]
            }
          })
          
          if (!formValues)    //cancel 선택시
          {
            //location.href = "/"
            location.reload(true)
          }
          else
          {
            let nResName = formValues[0]
            let nResCall = formValues[1]
  
            let loginInfo_E = []
            loginInfo_E = await this.$api("/api/checkSpPayInfo", {param: [nResName, nResCall, rCode]});
  
            if(loginInfo_E.length > 0)
            {
              this.loginInfo_E = loginInfo_E[0]
              this.$swal.fire("", '<b>'+ loginInfo_E[0].payerName + '님</b>, 반갑습니다.', "success")
              this.getEventInfo(rCode);
            }
            else 
            {
              this.$swal.fire("","카카오알림톡으로 안내된 정보를 확인해주세요<br>확인이 어려운 경우 문의처: <b class='text-danger'>1660-4062</b>","warning")
              location.reload(true)
            }
          }
        }
      }, 
  
      //토스페이먼츠결제(PG)
      async pgPay()
      {
  
        //localStorage.setItem('partner_order_id',kakaoData.partner_order_id)
        //localStorage.setItem('total_amount', this.eventInfo.payAmt)

        let payPointAmt = 0
        let payAmount = 0
        let veriSign = "N"
        let pFlag = "fu"
        let insVeriData = []

        payAmount = this.eventInfo.payAmt
        insVeriData = await this.$api("/api/proc_setVeriData_1", {param:
          [this.eventInfo.spResCode, payAmount, veriSign, pFlag, payPointAmt ]
        });

        if(insVeriData.affectedRows < 0)
        {
          this.$swal.fire("","검증데이타가 생성되지 않았습니다. 재시도해주세요", "warning")
          return false;
        }
  
        // //결제수단 랜더링
        // paymentWidget.renderPaymentMethods('#payment-widget', this.eventInfo.payAmt);
  
        // ------  결제위젯 초기화 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#sdk-설치-및-초기화
        this.paymentWidget = await loadPaymentWidget(this.clientKey, ANONYMOUS);

        // ------  결제 UI 렌더링 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods선택자-결제-금액-옵션
        this.paymentMethodWidget = this.paymentWidget.renderPaymentMethods("#payment-method", { value: payAmount }, { variantKey: "DEFAULT" });
        // ------  이용약관 UI 렌더링 ------
        // @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement선택자-옵션
        //this.paymentWidget.renderAgreement("#agreement", { variantKey: "AGREEMENT" });

        this.showFlag = !this.showFlag
      },

      async requestPayment(rNo) {
        try {
          if (this.paymentWidget) {
            // ------ '결제하기' 버튼 누르면 결제창 띄우기 ------
            // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
            // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
            // @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment결제-정보
            await this.paymentWidget.requestPayment({
              orderId: rNo,
              orderName: this.eventInfo.spName,
              // successUrl: 'http://localhost/success_pg',
              // failUrl: 'http://localhost/fail_pg',
              successUrl: 'https://ntabi.co.kr/success_pg',
              failUrl: 'https://ntabi.co.kr/fail_pg',
              //customerEmail: this.eventInfo.conEmail,
              customerEmail: "",
              customerName: this.eventInfo.payerName,
              taxFreeAmount: this.eventInfo.payAmt,
            });
          }
        } catch (error) {
          // 에러 처리하기
          console.error(error);
        }
      },
      
      getDateFormat(date) {
        return this.$dateFormat(date);
      },
      getCurrencyFormat(value) {
        return this.$currencyFormat(value);
      },
  
      //당해 이벤트 정보
      async getEventInfo(spCode) {
        //console.log("spCode: ", spCode)
        let eventInfo = await this.$api("/api/getSpPayInfo", {param:[spCode]});
        if(eventInfo.length > 0) {
          this.eventInfo = eventInfo[0];
        }
  
        //console.log("eventInfo: ", this.eventInfo)
  
        //결제요청 여부
        this.getReqPayInfo(spCode);
      },
  
      async getReqPayInfo(val)
      {
        let rtnRPflag_tmp = []
        if(this.rpCode === "nonWP")     //가상계좌는 결제전이므로 actualPayment1 == null이므로
        {
          rtnRPflag_tmp = await this.$api("/api/getReqPayInfo_vir", {param: [val]});
          let virAccInfo = await this.$api("/api/res_virAccPayInfo", {param: [val]});
          if(virAccInfo.length > 0)
          {
            this.virAccInfo = virAccInfo[0]
          }
          //console.log("virAccInfo:", this.virAccInfo)
          
          let accPayStatus= ""
          if((this.virAccInfo.payStyle === "fu" && this.virAccInfo.payFlag1 === "Y") || (this.virAccInfo.payStyle === "p2" && this.virAccInfo.payFlag2 === "Y"))
          {
            accPayStatus = "결제완료(담당자 확인 중)" 
          }
          else if((this.virAccInfo.payStyle === "p1" && this.virAccInfo.payFlag1 === "Y") || (this.virAccInfo.payStyle === "p2" && this.virAccInfo.payFlag2 === "N"))
          {
            accPayStatus = "잔액입금대기" 
          }
          else
          {
            accPayStatus = "입금대기"
          }
          this.accPayStatus = accPayStatus
        }
        else
        {
          rtnRPflag_tmp = await this.$api("/api/getReqPayInfo", {param: [val]});
        }
  
        if(rtnRPflag_tmp.length > 0) 
        {
          this.rtnRPflag_tmp = rtnRPflag_tmp[0];
          
          this.paidFlag = rtnRPflag_tmp[0].checkFlag
  
          this.rtnRPflag = (rtnRPflag_tmp.length > 0) ? "Y" : "N";
        }

        //console.log("rtnRPflag_tmp:", rtnRPflag_tmp, "paidFlag:", this.paidFlag, "rtnRPflag:", this.rtnRPflag)
      },
  
  
      /** 두배열간 중복제거 */
      removeDubofArrays(arr1, arr2)
      {
        return arr1.concat(arr2).filter(item => !arr1.includes(item) || !arr2.includes(item));
      },
    }
  }
  </script>
  
  <style>
  .reserdetail .r-name{
    margin-top:3rem;
  }
  .reserdetail .r-name .name{
    font-size:1.3rem;
    font-weight: 600;
    color:#000;
    padding-top:10px
  }
  .reserdetail .r-name .code{
    font-size:.8rem;
    color:#aaa;
    font-weight: 300;
  }
  .reserdetail .r-name .ndate{
    font-size:1rem;
    font-weight: 300;
  }
  
  .reserdetail .table{
    border-color:transparent; 
  }
  .reserdetail .table{
    margin-top:10px
  }
  .reserdetail .table tbody tr{
    font-weight:600;
    font-size:.9rem;
    height:2.5rem;
  }
  
  .reserdetail .title{
    border-bottom: 2px solid #000;
  }
  .reserdetail .title p{
    font-size:1.4rem;
    letter-spacing: 3px;
    color:#000;
  }
  
  .reserdetail .traval, .reserdetail .vehicle{
      margin:3.7rem 0
    }
  
  .reserdetail .paybtn{
    background-color: transparent;
  }
  .reserdetail .paybtn img{
    height: 2rem;
  }
  
  .reserdetail .go{
    margin-top:5rem;
    background-color: #000;
    color:#fff;
    padding:15px 5px
  }
  .reserdetail .go:hover{
    background-color: #333;
  }
  
  /** 결제버튼 */
  .reserdetail .pay{
    background-color:cadetblue;
    color:#fff;
    padding:15px 5px
  }
  .reserdetail .pay:hover{
    background-color:darkcyan;
  }
  
  @media (min-width:992px){
    .reserdetail .r-name .name{
      padding-left:30px
    }
    .reserdetail .table{
      margin:10px 0 0 10px
    }
    .reserdetail .table tbody tr{
      height:3.2rem;
    }
    .reserdetail .traval, .reserdetail .vehicle{
      margin:4.5rem 0
    }
  }

  /* --------
    결제하기
  -------- */
  .ht-pay{
    padding:.5rem 3rem
  }
  .ht-ex P{
    margin-bottom:0;
    font-size:.9rem
  }
  .ht-ex .ht-big{
    font-size:1.1rem;
    padding:.5rem 0
  }
  /* 모바일 장치를 위한 스타일 */
  @media (max-width: 768px) {
    .ht-ex p, .ht-ex b {
      /* 모바일에서의 텍스트 크기 */
      font-size: .7rem; /* 모바일에 적합한 크기로 조정 */
    }
    .ht-big {
      /* 모바일에서 큰 텍스트의 크기 */
      font-size: .9rem /* 모바일에 적합한 크기로 조정 */
    }
    .mobile-new-line {
      display: block; /* 모바일 화면에서는 블록으로 설정하여 새로운 줄에서 시작 */
    }
  }
  </style>
  