<template>
  <div class="cpoint">
    <section>
      <div class="container my-3">
        <div class="row justify-content-center">
          <div class="col-lg-12" style="line-height:200%">
            <h2 class="d-flex justify-content-center" style="font-weight:600">포인트 약관</h2>
              
            <hr style="margin:30px 0;">

            <p class="text-start" style="font-size:.85rem; line-height:25px;"> 
              <strong>제1조 (목적)</strong>
              <br>
              본 약관은 엔타비글로벌(주) 회원이 엔타비글로벌(주)(이하 "회사"라 합니다)가 제공하는 포인트를 이용함에 있어 엔타비글로벌(주) 회원 및 회사의 제반 권리 의무 및 관련 절차 등을 규정하는 그 목적이 있습니다. 
              <br><br>

              <strong>제 2조 (정의)</strong>
              <br>
              1. 엔타비글로벌 포인트라 함은 엔타비글로벌 회원을 위해 회사가 제공하는 서비스로서 그 개요는 본 약관 제 3조에 기술한 바와 같습니다.
              <br>
              2. 엔타비글로벌 포인트란 엔타비글로벌 회원이 엔타비글로벌 포인트를 정상적으로 이용할 수 있도록 회사가 승인하여 적립된 모든 포인트를 총칭하는 말입니다.
              <br>
              3. 엔타비글로벌 회원이란 엔타비글로벌 홈페이지를 가입하여 권한을 부여 받은 자를 말합니다.
              <br>
              4. 엔타비글로벌 포인트라 함은 회원이 엔타비글로벌 포인트를 이용하기 위하여 취득하는 것으로서 그 취득 및 사용 등에 관한 구체적인 사항은 본 약관 제 3조에 기술된 바와 같습니다.
              <br><br>

              <strong>제 3조 (포인트의 적립)</strong>
              <br>
              1. 회사가 본 약관에 정해진 바에 따라 회원에게 제공하는 엔타비글로벌 포인트는 아래와 같으며 포인트를 이용하고자 하는 고객은 본 약관에 정해진 제반 절차를 거쳐야 합니다.
              <br>
              2. 엔타비글로벌 포인트는 포인트 적립 여행상품을 온, 오프라인으로 예약하여 이용하신 회원 본인께만 적립하여 드립니다. 즉, 회원 한 분이 여러 명의 여행자와 동반하여 여행상품을 이용하셨다면, 각 여행자의 아이디로 포인트가 적립됩니다. 예약자, 또는 여행 대표자에게 포인트를 합산 적립해드리지 않습니다.
              <br>
              3. 엔타비글로벌 포인트 적립은 여행상품 행사 완료 후 영업일기준 약 7일 이내에 적립되며, 기상조건, 천재지변, 불가항력, 파업, 폭동, 출입국 금지, 동란, 전쟁 등의 사유로 여행이 완료되지 않은 경우 포인트는 적립되지 않습니다.
              <br>
              4. 상품마다 적용되는 포인트는 다르며, 특정상품, 특가행사 상품, 할인행사 상품의 경우 포인트가 적립되지 않습니다. 
              <br>
              5. 24개월 미만 유아의 경우 회원가입이 되어있더라도 포인트 적립 대상에서 제외됩니다.
              <br>
              6. 추후 여행포인트 적립은 여행 종료일로부터 3개월 이내만, 회원가입 후 통보 시 소급 적립 가능함을 알려드립니다. 
              <br><br>

              <strong>제 4조 (포인트의 사용)</strong>
              <br>
              1. 일반적인 상품을 이용하여 받으신 포인트는 적립된 다음 날부터 사용 가능합니다.
              <br>
              2. 포인트는 본인만 사용 가능하며, 가족에게 사용을 양도할 시 가족을 증명할 수 있는 서류 및 동의서 
              <br>
              &nbsp; &nbsp; [① 동의서신청 및 ② 양도서류접수처 : 담당자 문의 및 요청]를 제출해 주셔야 합니다. (가족증빙서류 : 주민등록등본, 가족관계증명서, 의료보험증사본 등 / ※ 각 증빙 서류는 주민등록번호 뒷자리 미포함 된 자료)
              <br>
              3. 상품 결제 시 사용한 포인트는 현금영수증 발급 대상에서 제외됩니다.
              <br>
              4. 포인트를 타인에게 상업적인 목적으로 양도하거나 대여 또는 담보의 목적으로 사용할 수 없습니다.
              <br>
              5. 엔타비글로벌 포인트는 회사에서 판매하고 있는 유효기간 내에 출발하는 모든 상품에 한해서 사용 가능하나, 실시간 항공권은 포인트 사용에 제한될 수 있습니다.
              <br>
              6. 엔타비글로벌 포인트는 회사가 규정한 제한사유가 없는 한 자유로이 결제에 사용할 수 있습니다.
              <br>
              단, 일부 판매상품 또는 결제수단은 회사의 사정에 따라, 포인트를 사용이 제한될 수 있습니다.
              <br>
              상기의 사용제외 상품은 상담 또는 결제 시, 안내를 드립니다.
              <br><br>


              <strong>제 5조 (포인트의 환불)</strong>
              <br>
              1. 엔타비글로벌 포인트를 사용하여 상품을 결제하신 후 예약 취소 시, 취소 수수료를 제한 금액을 포인트로 환불해 드립니다.
              <br>
              2. 본 조 제 1항의 경우 환불 완료 시까지 소요 시간이 발생할 수 있으며, 환불된 포인트는 환불된 날로부터 즉시 사용 가능합니다.
              <br>
              3. 포인트의 환불은 포인트로만 가능합니다. (금전적으로 환산하여 환불해 드리지는 않습니다.)
              <br>
              4. 환불된 포인트의 유효기간은 최초 부여된 기한의 적용을 받습니다.
              <br><br>


              <strong>제 6조 (포인트의 소멸/상실)</strong>
              <br>
              1. 포인트 유효기간은 적립된 시점으로부터 2년이며, 특정 행사로 제공한 포인트의 경우 별도의 유효기간이 적용됩니다.
              <br>
              2. 유효기간 내에 사용되지 않은 포인트는 자동 소멸되며 복구되지 않습니다.
              <br>
              3. 엔타비글로벌 회원 탈퇴를 하시게 되면 자동으로 포인트가 소멸되며, 재가입하시더라도 사용하실 수 없습니다.
              <br>
              4. 타인의 개인정보를 도용하여 회원가입 및 포인트를 이용한 경우 등 부정적인 방법으로 포인트를 획득/사용하거나, 시스템 오류 등의 잘못 입력된 포인트의 경우 임의로 조정될 수 있습니다.
              <br>
              5. 회원이 사망할 경우, 자동으로 회원 자격이 소멸되며 그에 따른 포인트도 자동 소멸됩니다. 가족이 포인트를 증여 받을 경우, 가족을 증명할 수 있는 서류를 제출해 주셔야 합니다.
              <br>
              6. 회원이 고의/부주의로 입금 채무 발생시 , 포인트 자격이 상실되며 해당 포인트는 모두 상실됩니다.
              <br>
              7. 회원 자격이 휴면 상태인 기간 동안 발생한 포인트 소멸은 휴면 기간을 포인트 유효기간에 소급 적용하여 재산정하지 않습니다.
              <br><br>

              <strong>제 7조 (포인트의 보안)</strong><br>
              1. 포인트 활용을 위해 개인정보 보안에 신경을 써주셔야 하며, 개인정보 유출 등 회원 부주의로 인한 포인트 유출은 회사에서 책임지지 않습니다. 
              <br><br>

              <strong>제 8조 (약관 개정)</strong><br>
              1. 본 약관은 수시로 개정될 수 있으며, 약관을 개정하고자 할 경우 회사는 개정되는 약관을 적용하고자 하는 날(이하 "효력 발생일")로부터 15일 이전에 약관이 개정되었다는 사실과 개정된 내용 등을 아래에 규정된 방법 중 1가지 이상의 방법으로 고지할 것입니다.
              <br>
              2. E-MAIL 통보의 방법으로 본 약관이 개정된 사실 및 개정된 내용을 회원에게 고지하는 경우에는 회원이 회사에 기 제공한 E-MAIL 주소로 통보하며, E-MAIL 주소의 정보 오류가 있는 고객은 정보전달 미흡으로 인한 불이익이 있을 수 있습니다.
              <br>
              3. 본 조의 규정에 의하여 개정된 약관(이하 "개정약관")은 원칙적으로 그 효력 발생일로부터 장래를 향하여 유효합니다.
              <br>
              4. 본 약관의 개정과 관련하여 이의가 있는 회원은 회원탈퇴를 할 수 있습니다. 단 이의가 있음에도 불구하고 본 조 제1항 내지 제 2항에 정해진 바에 따른 회사 고지가 있은 후 30일 이내에 회원 탈퇴를 하지 않은 회원은 개정 약관에 동의한 것으로 봅니다.
              <br>
              5. 본 조의 통지 방법 및 통지의 효력은 본 약관의 각 조항에서 규정하는 개별적인 또는 전체적인 통지의 경우에 이를 준용합니다. 
              <br><br>
            </p>

            <button class="mypoint" @click="goToMyPage()">
              나의 포인트 보러가기
            </button>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
//import moment from 'moment';
export default {
  data() {
    return {
    };
  },
  created(){
    // console.log("userInfo:",this.$store.state.user);
    // console.log("userInfo:",this.$store.state.user.email,"alimCall:", this.$store.state.user.alimCallC);

    if (this.$store.state.user.handlerId != undefined) {
      this.user = this.$store.state.user.handlerId
    }
    else if (this.$store.state.user.email != undefined) {
      this.user = this.$store.state.user.email
    }
    else {
      this.user = ""
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      //this.$router.push({path: '/'});
      location.replace("/");
    }
    //비회원 접근 시 대응처리
    if(!this.$store.state.user.email || this.$store.state.user.email == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 후 이용해주세요.',
        icon: 'warning'
      });
      this.$router.push({path: '/b2CLogin', query:{st: "Y"}});
    }    
  },  
  methods: {
    goToMyPage() 
    {
      this.$router.push({path: '/pointList_client'});
    }
  }
}
</script>

<style>
@media (max-width:992px){
  .cpoint{
    margin:4rem 0;
  }
}
.cpoint .mypoint{
  margin-top:5rem;
  padding:10px 20px;
  color:#fff;
  border:1px solid #222;
  background-color:#222
}
</style>
