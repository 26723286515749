<template>
  <main class="mt-3">
  <div class="pl-1 text-center">
    <div class="mb-3 row">
      <div class="container-fluid">
        <h2 class="text-center">
          <u>&nbsp;엔버스 문의상세&nbsp;</u>
        </h2>
        <div v-if="this.reserveInfo.insId == undefined || this.reserveInfo.insId == null" class="text-success">
          (비회원)
        </div>
        <div v-else-if="this.reserveInfo.memLevel === 'P'" style="color:goldenrod;">
          (프리미엄멤버십)
        </div>
        <div v-else-if="this.reserveInfo.memLevel === 'S'" style="color:royalblue;">
          (스탠다드멤버십)
        </div>
        <div v-else-if="this.reserveInfo.memFlag === 'Y' && (this.reserveInfo.memLevel == undefined || this.reserveInfo.memLevel == '') " style="color:gray;">
          (일반회원)
        </div>
          <div class="text-success text-start fs-6 mb-2">
            <b>[요청정보&nbsp; <i class="mb-2 bi  bi-pencil-square text-end text-danger" style="cursor:pointer" @click="updateResInfo(this.inqCode);">&nbsp;내용변경</i>&nbsp; ]</b>&nbsp;&nbsp;
            <button type="button" class="btn btn-sm btn-out
            line-primary align-right" @click="callWinCalc();">
              <i class="bi bi-calculator-fill">계산기이용</i>
            </button>
          </div>
          <table class="table table-bordered border-success align-middle w-1
          00 h-auto">
            <tbody>
              <tr style="text-align: center">
                <th class="table-secondary col-2">엔버스코드</th>
                <td class="col-4 text-primary">{{this.inqCode}}</td>
                <th class="table-secondary col-2">상태</th>
                <td class="col-4">
                  <div class="input-group">
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode" v-model="this.reserveInfo.inqStatus" @change="changeProcCode(this.reserveInfo.inqStatus)">
                      <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{ rProcCode.procName }} </option>
                    </select>
                    <!-- 취소사유 -->
                    <select class="form-select form-select-sm" style="background-color:lightyellow;" aria-label=".form-select-sm" ref="sel_cxlFlag" v-model="this.reserveInfo.cxlFlag" @change="changeCxlCode(this.reserveInfo.cxlFlag)" v-if="this.reserveInfo.inqStatus === 'CX'">
                      <option :value="cx.procCode" :key=i v-for="(cx,i) in cxlFlagList">{{cx.procName}}</option>
                    </select>

                    <!-- <button type="button" class="btn btn-warning btn-sm me-1" @click="callKakao(rProcCode);">
                      <font style="font-size:small" class="align-bottom">카톡발송</font>
                    </button> -->
                    <!-- <button type="button" class="btn btn-success btn-sm me-1" @click="pSetAside();" v-if="reserveInfo.resMFlag != 'N' && reserveInfo.reqStatus === 'CO'">
                      <span style="font-size:small" class="align-bottom">포인트적립</span>
                    </button> -->
                  </div>

                </td>
              </tr>
              <tr style="text-align: center">
                <th class="table-secondary"><i class="bi bi-check text-danger"></i>고객명/연락처</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="text" class="form-control form-control-sm" style="font-size: small" ref="txt_reqName" v-model="reserveInfo.inqName">
                    <input type="text" class="form-control form-control-sm" style="font-size: small" ref="txt_reqTelNo" v-model="reserveInfo.inqTel">
                    <!-- <input type="text" class="form-control form-control-sm" style="font-size: small" ref="txt_conEmail" v-model="reserveInfo.conEmail"> -->
                  </div>
                </td>
                <th class="table-secondary"><i class="bi bi-check text-danger"></i>신청일</th>
                <td class="col-md-4">
                  <input type="date" class="form-control form-control-sm" ref="txt_reqDate" v-model="reserveInfo.insDate" disabled>
                </td>
              </tr>

              <tr style="text-align: center">
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>탑승일 / 사용일</th>
                <td>
                  <div class="input-group">
                    <span class="input-group-text text-center" style="width:20%; background-color:rgba(107, 130, 234, 0.413);font-size: small;">탑승일</span>
                    <input type="date" class="form-control form-control-sm" ref="deptPoint" style="width:auto" v-model="reserveInfo.rentStartDate">
                    <span class="input-group-text text-center" style="width:20%;font-size: small;">사용일</span>
                    <!-- <input type="number" class="form-control form-control-sm text-end" ref="rentTotDays" style="width:10%" v-model="reserveInfo.rentTotDays" disabled> -->
                    <select id="startTime" v-model="reserveInfo.rentTotDays" class="form-select text-center" style="width:8%">
                      <option v-for="i in 15" :key="i">{{ i }}</option>
                    </select>
                  </div>
                </td>
                <th class="table-secondary col-md-2"><i class="bi bi-check text-danger"></i>인원 / 가이드 / 버스</th>
                <td colspan="3">
                  <div class="input-group">
                    <input type="number" class="form-control form-control-sm" style="font-size: small;text-align: end;color:red;width:10%" ref="personCnt" v-model="reserveInfo.personCnt">
                    <select v-model="reserveInfo.guideFlag" class="form-select form-select-sm text-center" style="width:30%">
                      <option v-for="(g, i) in guideLvInfo" :key="i" :value="g.baseCode">{{ g.codeNameKor }}</option>
                    </select>
                    <select v-model="reserveInfo.busFlag" class="form-select form-select-sm text-center" style="width:30%">
                      <option v-for="(b, j) in busLvInfo" :key="j" :value="b.baseCode">{{ b.codeNameKor }}({{ b.spRemark }})</option>
                    </select>
                  </div>
                </td>
              </tr>

              <tr style="text-align: center">
                <th class="table-secondary">
                  <i class="bi bi-check text-danger"></i>출발지 / 경유지 / 도착지
                  <p>
                    <i class="bi bi-check text-danger"></i>시작시간 / 종료시간
                  </p>
                  <p>
                    <btn type="button" class="btn btn-primary btn-md" @click="saveRoute(this.inqCode)">일정저장</btn>
                  </p>
                </th>
                <td colspan="3">
                  <div :key="i" v-for="(rd, i) in exist_nbRoute">
                    <div class="input-group mt-1">
                      <span class="input-group-text input-group-sm" style="font-size: small;">{{ i+1 }}일차</span>
                      <input type="hidden" class="form-control form-control-sm" style="font-size: small;" ref="iSubNo" v-model="rd.iSubNo">
                      <input type="text" class="form-control form-control-sm" style="font-size: small; color:blue;" ref="deptPoint" v-model="rd.deptPoint">
                      <input type="text" class="form-control form-control-sm" style="font-size: small; color:green;" ref="viaPoint" v-model="rd.viaPoint">
                      <input type="text" class="form-control form-control-sm" style="font-size: small; color:red;" ref="arrPoint" v-model="rd.arrPoint">
                    </div>
                    <div class="input-group mt-1 mb-4">
                      <span class="input-group-text input-group-sm" style="font-size: small;background-color:darkkhaki;">{{ i+1 }}일차 시작시간</span>
                      <input type="time" class="form-control form-control-sm" style="font-size: small; color:blue;" ref="rentStartTime" v-model="rd.rentStartTime">
                      <span class="input-group-text input-group-sm" style="font-size: small;background-color:darkkhaki;">{{ i+1 }}일차 종료시간</span>
                      <input type="time" class="form-control form-control-sm" style="font-size: small; color:blue;" ref="rentCloseTime" v-model="rd.rentCloseTime">
                      <button type="button" class="btn btn-outline-dark btn-sm" style="cursor: pointer; width:7%;" @click="removeNbRoute('E',this.inqCode, rd.iSubNo)">
                          <i class="bi bi-dash-circle-fill text-danger"></i>
                        </button>
                    </div>
                  </div>
                  <hr>

                  <div class="text-secondary text-start">
                    <btn type="button" class="btn btn-success btn-sm" @click="addRentday(this.inqCode)">사용일추가</btn>
                  </div>
                  <div :key="i" v-for="(an, i) in nbRoute">
                    <div class="input-group mt-1">
                      <span class="input-group-text input-group-sm" style="font-size: small;">{{ nbRouteCnt+i+1 }}일차</span>
                      <input type="text" class="form-control form-control-sm" style="font-size: small; color:blue;" ref="deptPoint" v-model="an.deptPoint">
                      <input type="text" class="form-control form-control-sm" style="font-size: small; color:green;" ref="viaPoint" v-model="an.viaPoint">
                      <input type="text" class="form-control form-control-sm" style="font-size: small; color:red;" ref="arrPoint" v-model="an.arrPoint">
                    </div>
                    <div class="input-group mt-1 mb-4">
                      <span class="input-group-text input-group-sm" style="font-size: small;background-color:darkkhaki;">{{ nbRouteCnt+i+1 }}일차 시작시간</span>
                      <input type="time" class="form-control form-control-sm" style="font-size: small; color:blue;" ref="rentStartTime" v-model="an.rentStartTime">
                      <span class="input-group-text input-group-sm" style="font-size: small;background-color:darkkhaki;">{{ nbRouteCnt+i+1 }}일차 종료시간</span>
                      <input type="time" class="form-control form-control-sm" style="font-size: small; color:blue;" ref="rentCloseTime" v-model="an.rentCloseTime">
                      <!-- <button type="button" class="btn btn-outline-dark btn-sm" style="cursor: pointer; width:7%;" @click="addNbPrice('P', this.inqCode, nbusPriceCnt + i, aP.nbPriceName, aP.nbPrice, aP.nbPriceNote);">
                        <i class="bi bi-save text-primary"></i>
                      </button> -->
                      <button type="button" class="btn btn-outline-dark btn-sm" style="cursor: pointer; width:7%;" @click="removeNbRoute('A', i)">
                        <i class="bi bi-trash3 text-danger"></i>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>

              <tr style="text-align: center">
                <th class="table-secondary col-md-2">고객기타추가사항</th>
                <td class="col-md-4" colspan = "3">
                  <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:100px; font-size:smaller;" v-model="reserveInfo.etcInquiry" maxlength="500" readonly></textarea>
                </td>
              </tr>
              <tr style="text-align: center">
                <th class="table-secondary col-md-2">담당자안내사항
                  <p style="font-weight: normal;">(고객이 확인가능)</p>
                </th>
                <td class="col-md-4" colspan = "3">
                  <textarea class="form-control" placeholder="상담결과 고객에게 고지할 내용을 기재하세요 (1,000자이내)" ref="addComment" id="floatingTextarea" style="height:100px; font-size:smaller;" v-model="reserveInfo.addComment" maxlength="1000"></textarea>
                </td>
              </tr>

              <tr>
                <th scope="row" class="table-secondary col-md-1">안내사항</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="text" min=0 class="form-control text-end text-small w-25" ref="txt_stayCnt" value="템플릿" readonly>
                    <select class="form-select form-select-sm w-75" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="patternCode_P" @change = "set_supplement('P', patternCode_P)">
                      <option :value="p.patternCode" :key=j v-for="(p,j) in patternList_P">{{p.pTitle}}</option>
                    </select>
                  </div>
                  <textarea class="form-control" placeholder="5,000자 이내" ref="txt_inContent" id="floatingTextarea" style="height:150px" v-model="reserveInfo.supPrecaution" maxlength="5000"></textarea>
                </td>
                <th scope="row" class="table-secondary col-md-1">취소안내</th>
                <td class="col-md-4">
                  <div class="input-group">
                    <input type="text" min=0 class="form-control text-end text-small w-25" ref="txt_stayCnt" value="템플릿" readonly>
                    <select class="form-select form-select-sm w-75" aria-label=".form-select-sm" ref="sel_salesUnit" v-model="patternCode_C" @change = "set_supplement('C', patternCode_C)">
                      <option :value="c.patternCode" :key=j v-for="(c,j) in patternList_C">{{c.pTitle}}</option>
                    </select>
                  </div>
                  <textarea class="form-control" placeholder="5,000자 이내" ref="txt_exContent" id="floatingTextarea" style="height:150px" v-model="reserveInfo.supCancellation" maxlength="5000"></textarea>
                </td>
              </tr>

              <tr style="text-align: center">
                <th class="table-secondary col-md-2">기존업무코멘트
                  <p style="font-weight: normal;">내부용(외부노출X)</p>
                </th>
                <td class="col-md-4">
                  <textarea class="form-control" ref="txt_orgComment" id="floatingTextarea" style="height:110px; font-size:smaller;"
                    v-model="this.reserveInfo.oldComment" maxlength="1000" disabled></textarea>
                </td>
                <th class="table-secondary col-md-2">업무코멘트
                  <p style="font-weight: normal;">내부용(외부노출X)</p>
                </th>
                <td class="col-md-4">
                  <textarea class="form-control" placeholder="250자 이내" ref="txt_newComment" id="floatingTextarea" style="height:110px; font-size:smaller;"
                    v-model="this.reserveInfo.newComment" maxlength="250"></textarea>
                </td>
              </tr>

              <tr style="text-align: center">
                <th class="table-secondary">
                  <i class="bi bi-check text-danger"></i>청구가격구성
                  <p class="text-danger mt-1" style="font-size: small;">※ 문의저장대상 아님!</p>
                </th>
                <td class="col-md-4 text-start">
                  <div class="text-secondary">
                    <btn type="button" class="btn btn-outline-primary btn-sm" @click="makeNbPrice(this.inqCode, 'P')">가격정보추가</btn>
                  </div>
                  <hr class="mt-2 mb-2">
                  <div :key="j" v-for="(eo, j) in exist_nbPrice">
                    <div class="col">
                      <div class="input-group mb-1">
                        <input type="text" class="form-control-sm" placeholder="금액명(50자)" v-model="eo.nbPriceName" style="width:20%;" maxlength="50">
                        <input type="number" class="form-control-sm text-end" placeholder="금액" v-model="eo.nbPrice" min="0" style="width:20%;">
                        <input type="text" class="form-control-sm" placeholder="특기사항(300자)" v-model="eo.nbPriceNote" style="width:50%;" maxlength="300">
                        <button type="button" class="btn btn-outline-dark btn-sm" style="cursor: pointer; width:10%;" @click="removeNbPrice('P', this.inqCode, eo.nbSubNo, 'N')">
                          <i class="bi bi-dash-circle-fill text-danger"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div v-for="(aP, i) in nbPrice" :key="i">
                    <div class="col">
                      <div class="input-group mb-3">
                        <input type="text" class="form-control-sm" placeholder="금액명(50자)" :ref="'nbPriceName'+ i" v-model="aP.nbPriceName" style="width:20%;" maxlength="50">
                        <input type="number" class="form-control-sm text-end" placeholder="금액" :ref="'nbPrice'+ i" v-model="aP.nbPrice" min="0" style="width:20%;">
                        <input type="text" class="form-control-sm" placeholder="특기사항(300자)" :ref="'nbPriceNote'+ i" v-model="aP.nbPriceNote" style="width:46%;" maxlength="300">

                        <button type="button" class="btn btn-outline-dark btn-sm" style="cursor: pointer; width:7%;" @click="addNbPrice('P', this.inqCode, nbusPriceCnt + i, aP.nbPriceName, aP.nbPrice, aP.nbPriceNote);">
                          <i class="bi bi-save text-primary"></i>
                        </button>
                        <button type="button" class="btn btn-outline-dark btn-sm" style="cursor: pointer; width:7%;" @click="removeAddNbPrice('P', i)">
                          <i class="bi bi-trash3 text-danger"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </td>

                <th class="table-secondary">
                  할인가격구성
                  <p class="text-danger mt-1" style="font-size: small;">※ 문의저장대상 아님!</p>
                </th>
                <td class="col-md-4 text-start">
                  <div class="text-secondary">
                    <btn type="button" class="btn btn-outline-warning btn-sm" @click="makeNbPrice(this.inqCode, 'M')">할인정보추가</btn>
                  </div>
                  <hr class="mt-2 mb-2">
                  <div :key="j" v-for="(em, j) in exist_nbPrice_M">
                    <div class="col">
                      <div class="input-group mb-1">
                        <input type="text" class="form-control-sm" placeholder="할인명(50자)" v-model="em.nbPriceName" style="width:20%;" maxlength="50">
                        <input type="number" class="form-control-sm text-end" placeholder="금액" v-model="em.nbPrice" min="0" style="width:20%;">
                        <input type="text" class="form-control-sm" placeholder="특기사항(300자)" v-model="em.nbPriceNote" style="width:50%;" maxlength="300">
                        <button type="button" class="btn btn-outline-dark btn-sm" style="cursor: pointer; width:10%;" @click="removeNbPrice('M', this.inqCode, em.nbSubNo, 'N')">
                          <i class="bi bi-dash-circle-fill text-danger"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div v-for="(aM, i) in nbPrice_M" :key="i">
                    <div class="col">
                      <div class="input-group mb-3">
                        <!-- {{ nbusPriceCnt_M + i }} -->
                        <input type="text" class="form-control-sm" placeholder="할인명(50자)" :ref="'nbPriceName_M'+ i" v-model="aM.nbPriceName" style="width:20%;" maxlength="50">
                        <input type="number" class="form-control-sm text-end" placeholder="금액" :ref="'nbPrice_M'+ i" v-model="aM.nbPrice" min="0" style="width:20%;">
                        <input type="text" class="form-control-sm" placeholder="특기사항(300자)" :ref="'nbPriceNote_M'+ i" v-model="aM.nbPriceNote" style="width:46%;" maxlength="300">

                        <button type="button" class="btn btn-outline-dark btn-sm" style="cursor: pointer; width:7%;" @click="addNbPrice('M', this.inqCode, nbusPriceCnt_M + i, aM.nbPriceName, aM.nbPrice, aM.nbPriceNote);">
                          <i class="bi bi-save text-primary"></i>
                        </button>
                        <button type="button" class="btn btn-outline-dark btn-sm" style="cursor: pointer; width:7%;" @click="removeAddNbPrice('M', i)">
                          <i class="bi bi-trash3 text-danger"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>

              <tr style="text-align: center">
                <th class="table-secondary col-md-2">
                  <button type="button" class="btn btn-primary btn-md me-1" @click="calcNbPrice(this.inqCode);">
                    <span style="font-size:medium" class="align-middle">가격계산</span>
                  </button>
                  <p style="font-size:small;color:red;">※가격계산 후 저장필수(내용변경)</p>
                </th>
                <td class="col-md-4 text-end text-danger fs-5 ">
                  {{ this.getCurrencyFormat(nbusPriceTot) }} 원
                </td>
                <th class="table-secondary col-md-2">
                </th>
                <td class="col-md-4 text-end text-danger fs-5 ">
                </td>
              </tr>
            </tbody>
          </table>

          <div class="text-success text-start fs-6 mb-2" v-if="this.reserveInfo.inqStatus == 'PY' || (this.reserveInfo.inqStatus == 'RP' && this.userPayInfo.padiId != '') || this.reserveInfo.inqStatus == 'CO' || this.reserveInfo.inqStatus == 'CX' || this.reserveInfo.inqStatus == 'WP'">
            <b>[결제내역]</b>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto text-center" v-if="this.reserveInfo.inqStatus == 'PY' || (this.reserveInfo.inqStatus == 'RP' && this.userPayInfo.padiId != '') || this.reserveInfo.inqStatus == 'CO' || this.reserveInfo.inqStatus == 'CX' || this.reserveInfo.inqStatus == 'WP'">
            <tbody>
              <tr>
                <th class="table-secondary col-md-2">
                  결제내역
                </th>
                <td class="col-md-3">
                  <div class="text-start">
                    - 대금총액: {{ getCurrencyFormat(reserveInfo.nbusPriceTot) }}
                    <br>
                    - 청구액: {{ getCurrencyFormat(this.userPayInfo.reqPayAmount) }}
                    <span></span>
                    <hr>
                      <p style="padding-left: 2rem;">- 실결제액({{ this.userPayInfo.fName }}): {{ getCurrencyFormat(this.userPayInfo.actualPayAmount) }}</p>
                      <p style="padding-left: 2rem;">- 포인트결제: {{ getCurrencyFormat(this.payPointAmt) }}</p>
                  </div>
                </td>
                <!-- <td class="col-md-3" v-if="userPayInfo.reqTotPayAmt > userPayInfo.actualPayAmount">
                  <div class="text-start">
                    - 잔여대금: {{ getCurrencyFormat(balance) }}
                    <span class="text-danger" v-if="userPayInfo.reqPayBalance === 'Y' && userPayInfo.reqTotPayAmt  != userPayInfo.actualPayAmount">(잔여대금 청구 중)</span>
                    <hr>
                    <center><button class="btn btn-success mt-3" @click="payBalance()">잔여대금청구</button></center>
                  </div>
                </td> -->
                <th class="table-secondary col-md-2">
                  히스토리
                  <p v-if="totalPay <= 0" style="font-size:small; color:red;">2024-05-13 이전결제는 히스토리 제공X</p>
                </th>
                <td class="col-md-3">
                  <div class="text-start">
                    <p v-if="fPayFlag === 'N' && p1PayAmt > 0">
                      <i class="bi bi-stop-fill text-warning"></i> 예약금결제: {{ getCurrencyFormat(p1PayAmt) }}
                      <span v-if="p1PayDate != ''" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                      <span v-else></span>
                    </p>
                    <p v-else-if="fPayFlag === 'Y' && p1PayAmt > 0">
                      <i class="bi bi-stop-fill text-warning"></i> 전액결제: {{ getCurrencyFormat(p1PayAmt) }}
                      <span v-if="p1PayDate != ''" style="font-size:small;"> ( {{ p1PayDate }} )</span>
                      <span v-else></span>
                    </p>

                    <p  v-if="payPointAmt != '' && payPointAmt > 0">
                      <i class="bi bi-stop-fill text-success"></i> 포인트결제: {{ getCurrencyFormat(payPointAmt) }}
                      <span v-if="payPointDate != ''" style="font-size:small;"> ( {{ payPointDate }} )</span>
                      <span v-else></span>
                    </p>

                    <p  v-if="p2_paidAmt != '' && p2PayAmt > 0">
                      <i class="bi bi-stop-fill text-danger"></i> 잔여금결제: {{ getCurrencyFormat(p2PayAmt) }}
                      <span v-if="p2PayDate != ''" style="font-size:small;"> ( {{ p2PayDate }} )</span>
                      <span v-else></span>
                    </p>
                    <hr>
                    <span style="font-weight:600; padding-left: 50%;" v-if="this.totalPay > 0">합계: {{ getCurrencyFormat(this.totalPay) }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

      </div>
    </div>
    <div class="mb-3 row">
      <!-- <div class="col-auto d-grid p-1">

        <button type="button" class="btn btn-lg btn-primary" @click="productInsert();">저장</button>
      </div> -->
      <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-success" @click="goToList()">리스트로 이동</button>
      </div>
    </div>
  </div>
  </main>
</template>

<script>
import { handlerInfo } from '../assets/js/commonFunctions'

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      reserveInfo: [],              //엔버스문의정보
      //reserveInfo_detail: [],       //엔버스문의정보_디테일
      guideLvInfo: [],              //가이드등급
      busLvInfo: [],                //버스등급

      //가격정보생성&삭제
      nbPrice: [],                  //신규엔버스가격정보
      exist_nbPrice: [],            //기존엔버스가격정보
      nbusPriceCnt: 0,              //기존엔버스가격정보숫자(무효포함)

      //엔버스 할인정보
      nbPrice_M: [],                //신규엔버스할인정보
      exist_nbPrice_M: [],          //기존엔버스할인정보
      nbusPriceCnt_M: 0,            //기존엔버스할인정보숫자(무효포함)

      nbusPriceTot: "",              //엔버스 상품가격

      //엔버스 경로정보
      nbRoute: [],                  //추가엔버스루트
      exist_nbRoute: [],            //기존엔버스루트
      nbRouteCnt: 0,                //기존엔버스루트서브넘버

      cxlFlagList: [],              //취소사유리스트
      cxlName: "",                  //취소사유

      rtnRPflag: "N",               //결제요청여부
      paymentInFull: "",            //완납여부
      userPayInfo: [],              //결제내역정보
      payPointAmt: 0,               //포인트결제액

      handTel: [],                  //매니저연락처
      handTelCnt: 0,                //수신처갯수

      //actualPayAmt: 0,            //실결제액
      personCntK: 0,                //카카오알림톡용 인원수
      pdtPriceNameInfo: [],         //권종이명
      totRefundAmt: 0,              //환급액
      totDiscountAmt:0,             //할인액
      //tot_comDC: 0,                 //멤버십 동반자할인총액

      balance: 0,                 //부분결제 시 잔액

      /////결제히스토리용 정보
      p1PayAmt: 0,
      p1PayDate: "",
      p2PayAmt: 0,
      p2PayDate:"",
      //payPointAmt: 0,
      payPointDate: "",
      fPayFlag: "",                //예약금결제N, 전액결제Y
      totalPay: 0,                 //히스토리합산

      personCnt: 0,                 //총인원(화면상)
      insComment: "",               //예약상담내용

      paidPoint: 0,                 //사용포인트
      payPointFlag: "N",            //포인트사용여부
      userPayPoint: [],             //해당예약에서 유저지불 포인트

      // standPat_P: "",               //안내사항
      // standPat_C: "",               //취소사항
      // standPat_L: "",               //유의사항







      //부록패턴 템플릿
      patternList_P: [], //안내사항패턴리스트
      patternList_C: [], //취소안내패턴리스트
      patternList_L: [], //유의사항패턴리스트
      // patternCode_P: "", //안내사항패턴코드
      // patternCode_C: "",  //취소안내패턴코드
      // patternCode_L: "",  //유의사항패턴코드
      sel_patternList_P: [], //선택된 안내사항패턴리스트
      sel_patternList_C: [], //선택된 취소안내패턴리스트
      sel_patternList_L: [], //선택된 유의사항패턴리스트
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.inqCode = this.$route.query.iCode;
    this.reqRsvCode = this.$route.query.seqReq;
    this.productId = this.$route.query.pCode;
    this.copyFlag = this.$route.query.cCode;

    //this.productId = this.$route.query.productId;
    this.salesRoute = this.$route.query.salesRoute;
    this.ntCd = this.$route.query.ntCd;
    //this.pdtFlag = 'MX';

    console.log("USER:",this.user);

    //this.getCopyReservation(); //예약처리용 데이타복사

    this.getReqProcLevel();
    this.getReserInfo();
    this.getBaseCode();

    this.getHandlerInfo()           //알림톡 발송대상 체크
  },
  mounted() {
    //console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    //document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
  },
  methods: {
    /** 윈도우즈 계산기호출*/
    callWinCalc()
    {
      window.open('Calculator: ');
    },

    async getBaseCode(){
      //가이드등급리스트
      //WHERE categoryCode = 'rentBus' AND primeCatCode = 'buCd' AND useFlag = 'Y'
      let guideLvInfo = await this.$api("/api/searchBasecode_more", {param: [ "guideLv", "guCd", "Y" ]})
      if(guideLvInfo.length > 0)
      {
        this.guideLvInfo = guideLvInfo
      }
      console.log("guideLvInfo:", this.guideLvInfo)

      //버스등급리스트
      let busLvInfo = await this.$api("/api/searchBasecode_more", {param: [ "rentBus", "buCd", "Y" ]})
      if(busLvInfo.length > 0)
      {
        this.busLvInfo = busLvInfo
      }
      console.log("busLvInfo:", this.busLvInfo)
    },

    async getNbusPriceInfo(nbCode) {
      //가격정보
      let resNbPriceInfo = await this.$api("/api/nbusPriceInfo", { param: [nbCode, "Y", "P"]})
      if(resNbPriceInfo.length > 0)
      {
        this.exist_nbPrice = resNbPriceInfo
      }
      console.log("exist_nbPrice:", this.exist_nbPric)

      let resNbPriceInfo_M = await this.$api("/api/nbusPriceInfo", { param: [nbCode, "Y", "M"]})
      if(resNbPriceInfo_M.length > 0)
      {
        this.exist_nbPrice_M = resNbPriceInfo_M
      }
      console.log("exist_nbPrice_M:", this.exist_nbPrice_M)
    },

    //안내,취소 패턴데이타(엔버스용)
    async getPatternList() {
      let patternList = await this.$api("/api/patternList", {});
      this.patternList_P = patternList.filter(e => {return e.pCat === "P" && e.pUseFlag === "Y" && e.pdtCat === "NB"})         //안내
      this.patternList_C = patternList.filter(e => {return e.pCat === "C" && e.pUseFlag === "Y" && e.pdtCat === "NB"})         //취소
      //this.patternList_L = patternList.filter(e => {return e.pCat === "L" && e.pUseFlag === "Y" && e.pdtCat === "NB"})         //체크
      console.log("patternList_P:", this.patternList_P, "patternList_C:", this.patternList_C)
    },

    set_supplement(param, pCode) {
      if(param === "P")
      {
        this.sel_patternList_P = this.patternList_P.filter(e => {return e.patternCode === pCode });
        this.reserveInfo.supPrecaution = this.sel_patternList_P[0].pContent;
      }
      else if(param === "C")
      {
        this.sel_patternList_C = this.patternList_C.filter(e => {return e.patternCode === pCode });
        this.reserveInfo.supCancellation = this.sel_patternList_C[0].pContent;
      }
      // else if(param === "L")
      // {
      //   this.sel_patternList_L = this.patternList_L.filter(e => {return e.patternCode === this.patternCode_L });
      //   //console.log("sel_patternList_P:",this.sel_patternList_P);
      //   this.productInfo.supLookup = this.sel_patternList_L[0].pContent;
      // }
      console.log("sel_patternList_P:",this.sel_patternList_P,"sel_patternList_C:",this.sel_patternList_C)
    },        

    //엔버스가격/할인 정보생성
    async makeNbPrice(nbCode, pmFlag) {
      this.nbusPriceTot = ""

      let nbusPriceCnt_tmp = await this.$api("/api/nbusPriceCnt", { param: [nbCode, "P"]})
      let nbusPriceCnt = (nbusPriceCnt_tmp[0].count*1)+1
      this.nbusPriceCnt = (nbusPriceCnt == undefined || !nbusPriceCnt) ? 0 : nbusPriceCnt

      let nbusPriceCnt_tmp_M = await this.$api("/api/nbusPriceCnt", { param: [nbCode, "M"]})
      let nbusPriceCnt_M = (nbusPriceCnt_tmp_M[0].count*1)+1
      this.nbusPriceCnt_M = (nbusPriceCnt_M == undefined || !nbusPriceCnt_M) ? 0 : nbusPriceCnt_M

      console.log("nbusPriceCnt:", this.nbusPriceCnt, "nbusPriceCnt_M:", this.nbusPriceCnt_M)

      if(pmFlag === "P")
      {
        this.nbPrice.push({
          nbCode: nbCode, nbPrice: 0, nbPriceName: "", nbPriceNote: "", nUseFlag: "Y"
        })
      }
      else if(pmFlag === "M")
      {
        this.nbPrice_M.push({
          nbCode: nbCode, nbPrice: 0, nbPriceName: "", nbPriceNote: "", nUseFlag: "Y"
        })
      }
      else
      {
        this.$swal.fire("", "가격/할인정보 구분에 오류가 발생하였습니다.", "warning")
        location.reload(true)
      }
    },

    //엔버스가격/할인 정보삭제 removNbPrice('P', this.inqCode, eo.nbSubNo, 'N')
    async removeNbPrice(pmFlag, nbCode, subNo, useFlag) {
      this.nbusPriceTot = ""

      let res_updNbusPrice = await this.$api("/api/updNbusPrice", { param: [
        useFlag, this.user.handlerId, nbCode, subNo, pmFlag
      ]})
      if(res_updNbusPrice.affectedRows > 0)
      {
        this.$swal.fire("", "해당 가격정보는 제외되었습니다.", "info")
        //location.reload(true)
        // 전체 새로고침 대신 해당 데이터만 업데이트
        if(pmFlag === "P")
        {
          let index = this.exist_nbPrice.findIndex(price => price.nbSubNo === subNo);
          if (index !== -1) {
            this.exist_nbPrice.splice(index, 1);  // 배열에서 해당 항목만 제거
          }
        }
        else if(pmFlag === "M")
        {
          let index = this.exist_nbPrice_M.findIndex(price => price.nbSubNo === subNo);
          if (index !== -1) {
            this.exist_nbPrice_M.splice(index, 1);  // 배열에서 해당 항목만 제거
          }
        }
        else
        {
          this.$swal.fire("", "가격/할인정보 구분에 오류가 발생하였습니다.", "warning")
          location.reload(true)
        }
      }
    },

    //신규추가가격저장 addNbPrice('P', this.inqCode, nbusPriceCnt + i, aP.nbPriceName, aP.nbPrice, aP.nbPriceNote)
    async addNbPrice(pmFlag, nbCode, subNo, nbPriceName, nbPrice, nbPriceNote){
      this.nbusPriceTot = ""

      let nUseFlag = "Y"
      let insId = this.user.handlerId

      console.log(pmFlag, nbCode, subNo, nbPriceName, nbPrice, nbPriceNote)
      let ins_nbPrice = await this.$api("/api/insNbusPrice", {param: [
        nbCode, subNo, nbPriceName, pmFlag, nbPrice, nbPriceNote, nUseFlag, insId
      ]})
      if(ins_nbPrice.affectedRows < 0)
      {
        this.$swal.fire("","가격정보입력에 오류가 발생하였습니다. 재시도해주세요", "warning")
        return false;
      }
      else
      {
        this.$swal.fire("","정보가 (신규/추가)설정되었습니다.", "success")
        location.reload(true)
      }
    },

    //신규생성 엔버스 가격/할인 정보삭제 -> 삭제는 인덱스정보에 따라 실행
    async removeAddNbPrice(pmFlag, subNo) {
      this.nbusPriceTot = ""

      if(pmFlag === "P")
      {
        this.nbPrice.splice(subNo, 1);
      }
      else if(pmFlag === "M")
      {
        this.nbPrice_M.splice(subNo, 1);
      }
      else
      {
        this.$swal.fire("","정보가 (신규/추가)설정되었습니다.", "success")
        location.reload(true)
      }
    },

    async calcNbPrice(nbCode){
      let nbusPriceTot = await this.$api("/api/resNbusPrice", {param: [ nbCode, "Y"]})
      this.nbusPriceTot = (nbusPriceTot[0].result === undefined || !nbusPriceTot[0].result) ? 0 : nbusPriceTot[0].result
      console.log("nbusPriceTot:", this.nbusPriceTot)
    },

    //엔버스 루트추가
    async addRentday(nbCode) {
      // let nbRouteCnt_tmp = await this.$api("/api/nbusPriceCnt", { param: [nbCode]})

      // let nbRouteCnt = (nbRouteCnt_tmp[0].count*1)+1
      // this.nbRouteCnt = (nbRouteCnt == undefined || !nbRouteCnt) ? 0 : nbRouteCnt

      this.nbRouteCnt = (this.exist_nbRoute.length <= 0) ? 0 : this.exist_nbRoute.length
      console.log(nbCode, "/", this.nbRouteCnt*1+1)

      this.nbRoute.push({
        nbCode: nbCode, deptPoint: "", viaPoint: "", arrPoint: "", rentStartTime: "", rentClosetTime: "", useFlag: "Y"
      })
      this.reserveInfo.rentTotDays++
    },

    //엔버스 루트삭제
    async removeNbRoute(param, nbCode, subNo) {   //param E:기존삭제 A:추가삭제
      let useFlag = "N"
      let rentTotDays = --this.reserveInfo.rentTotDays
      if(rentTotDays < 0)
      {
        rentTotDays = 0
      }

      if(param === "E")
      {
        let delId = this.user.handlerId
        let res_updNbusRoute = await this.$api("/api/proc_removeNbRoute", { param: [
          nbCode, subNo, useFlag, delId, rentTotDays
        ]})
        if(res_updNbusRoute.affectedRows < 0)
        {
          this.$swal.fire("", "해당 일정제외에 오류가 발생하였습니다. 재시도해주세요", "error")
          location.reload(true)
        }
        else
        {
          this.$swal.fire("", "해당 일정은 제외되었습니다.", "info")
          //전체 새로고침 대신 해당 데이터만 업데이트
          let index = this.exist_nbRoute.findIndex(route => route.iSubNo === subNo);
          if (index !== -1) {
            this.exist_nbRoute.splice(index, 1);  // 배열에서 해당 항목만 제거
          }
        }
      }
      else if(param === "A")
      {
        this.nbRoute.splice(subNo, 1);
      }
    },

    //템플릿 기본형추출
    async getStandPattern(param){     //P,C,L
      let pUseFlag = "Y"
      //let pCat_p = param         //안내
      // let pCat_c = "C"        //취소
      // let pCat_l = "L"        //유의
      let pStandard = "Y"
      let limit = 1

      let rtnStandPattern = []
      rtnStandPattern = await this.$api("/api/standPattern", { param: [
        pUseFlag, param, pStandard, limit
      ] })

      if(rtnStandPattern.length > 0)
      {
        console.log(rtnStandPattern[0].pContent)
        return rtnStandPattern[0].pContent
      }
      else
      {
        return ""
      }
    },

    async getReserInfo()
    {
      //엔버스문의 마스터
      let reserveInfo = await this.$api("/api/nbusInqInfo", {param: [this.inqCode]});
      //엔버스문의 디테일(일정루트)
      let exist_nbRoute = await this.$api("/api/nbusInqInfo_d", {param: [this.inqCode]});

      if(reserveInfo.length > 0)
      {
        this.reserveInfo = reserveInfo[0]
        //이용시작일, 이용종료일
        this.reserveInfo.rentStartDate = this.getDateFormat(this.reserveInfo.rentStartDate);
        this.reserveInfo.insDate = this.getDateFormat(this.reserveInfo.insDate);
      }

      //안내,취소 사항이 없으면 기본패턴을 호출하여 리턴값을 사용한다
      if(this.reserveInfo.supPrecaution == null || this.reserveInfo.supPrecaution == "")
      {
        this.reserveInfo.supPrecaution = await this.getStandPattern("P")
      }
      if(this.reserveInfo.supCancellation == null || this.reserveInfo.supCancellation == "")
      {
        this.reserveInfo.supCancellation = await this.getStandPattern("C")
      }      
      console.log("reserveInfo:",this.reserveInfo)

      if(exist_nbRoute.length > 0)
      {
        this.exist_nbRoute = exist_nbRoute
      }

      //최초 예약체커 정보저장
      if(this.reserveInfo.checkFlag == 'N')
      {
        let updResConfirm = await this.$api("/api/updResConfirm", {param: [this.user.handlerId, this.reqRsvCode]});
        if(updResConfirm.affectedRows < 0 || updResConfirm.affectedRows == undefined)
        {
          this.$swal.fire("","조회 중 에러가 발생했습니다.<br>화면을 리로드해 주세요.", "warning")
          return false
        }
      }

      this.getNbusPriceInfo(this.inqCode)           //엔버스가격구성정보획득

      //결제요청 여부
      this.getReqPayInfo(this.inqCode);

      //추가안내사항 패턴
      this.getPatternList();
    },

    //상태변화체크
    async changeProcCode(rProcCode)
    {
      if(rProcCode === "CX")
      {
        this.$swal.fire("","취소사유를 반드시 선택해주세요!", "question")
        return false;
      }
      this.reserveInfo.inqStatus = (rProcCode == '') ? this.reserveInfo.inqStatus : rProcCode
      //console.log("selectedProcCode:", this.reserveInfo.reqStatus)

      //문의확인상태로 변경
      if(this.reserveInfo.checkFlag == 'N' || this.reserveInfo.checkFlag == '')
      {
        let updResConfirm = await this.$api("/api/updNbusConfirm", {param: [this.user.handlerId, this.inqCode]});
        if(updResConfirm.affectedRows < 0 || updResConfirm.affectedRows == undefined)
        {
          this.$swal.fire("","문의확인처리 중 오류가 발생했습니다. 다시 진행해주세요", "warning")
          return false
        }
        else
        {
          this.$swal.fire("","당해 문의는 확인 되었습니다", "info")
        }
      }
    },

    //취소사유추적
    changeCxlCode(cxCode)
    {
      let cxlName_tmp = this.cxlFlagList.filter((item) => {
        return item.procCode === cxCode
      })

      this.cxlName = cxlName_tmp[0].procName
      this.reserveInfo.cxlFlag = (cxCode == '') ? this.reserveInfo.cxlFlag : cxCode
      //console.log("selectedCxlCode:", this.reserveInfo.cxlFlag, "cxlName:", cxlName)
    },

    //권종이명이 있는 경우, 우선표시
    async getPdtPriceName(pCode)
    {
      let pdtPriceNameInfo = await this.$api("/api/pdtPriceNameInfo", {param: [pCode, this.reserveInfo.reqDptDate]});
      if(pdtPriceNameInfo.length > 0)
      {
        //this.rtnRPflag = "Y"

        this.pdtPriceNameInfo = pdtPriceNameInfo
      }
      //console.log("pdtPriceNameInfo:", this.pdtPriceNameInfo)
    },
    async getReqPayInfo(val)
    {
      //포인트결제내역
      let payPointInfo = await this.$api("/api/paidPoint", {param: [val]});
      console.log("payPointInfo:", payPointInfo)

      //결제포인트액수
      if(payPointInfo.length > 0)
      {
        if(payPointInfo[0].userPoint > 0 && payPointInfo[0].useFlag === 'U')
        {
          this.payPointAmt = payPointInfo[0].userPoint
          this.payPointDate = payPointInfo[0].pointAddDate
        }
        else
        {
          this.payPointAmt = 0
        }
      }


      let resCode = ""
      if(this.reserveInfo.partFlag === "1")
      {
        resCode = val+"-1"
      }
      else if(this.reserveInfo.partFlag === "2")
      {
        resCode = val+"-1"
      }
      else
      {
        resCode = val
      }

      let rtnRPflag_tmp = await this.$api("/api/getReqPayInfo_vir", {param: [resCode]});
      console.log("rtnRPflag_tmp:", rtnRPflag_tmp)

      if(rtnRPflag_tmp.length > 0)
      {
        // this.rtnRPflag = "Y"    //결제여부
        if(rtnRPflag_tmp[0].payFlag === 'p1')        //부분결제 시, 잔여대금
        {
          this.balance = (rtnRPflag_tmp[0].reqTotPayAmt)*1 - (rtnRPflag_tmp[0].actualPayAmount)*1
        }

        //잔액결제 또는 전액결제
        if(rtnRPflag_tmp[0].payFlag === 'p2' || rtnRPflag_tmp[0].payFlag === 'fu')
        {
          if(this.payPointAmt*1 > 0)
          {
            if(rtnRPflag_tmp[0].reqTotPayAmt*1 == (rtnRPflag_tmp[0].actualPayAmount*1 + this.payPointAmt*1))
            {
              this.rtnRPflag = "Y"
            }
            else if(rtnRPflag_tmp[0].reqTotPayAmt != (rtnRPflag_tmp[0].actualPayAmount*1 + this.payPointAmt*1))
            {
              this.$swal.fire("","최종결제금액과 상품대금이 일치하지 않습니다. 실제결제된 금액을 확인해주세요", "question")
              return false
            }
          }
          else if(rtnRPflag_tmp[0].actualPayAmount == rtnRPflag_tmp[0].reqTotPayAmt)
          {
            this.rtnRPflag = "Y"
          }
          else if(rtnRPflag_tmp[0].actualPayAmount != rtnRPflag_tmp[0].reqTotPayAmt)
          {
            // this.$swal.fire("","최종결제금액과 상품대금이 일치하지 않습니다. 실제결제된 금액을 확인해주세요", "question")
            // return false
            this.rtnRPflag = "N"

          }
        }
        this.userPayInfo = rtnRPflag_tmp[0]
      }
      else
      {
        this.rtnRPflag = "N"
      }
      console.log("userPayInfo:", this.userPayInfo)

      this.p1PayAmt = (this.userPayInfo.p1PayAmt == null) ? 0 :          this.userPayInfo.p1PayAmt
      this.p2PayAmt = (this.userPayInfo.p2PayAmt == null) ? 0 :          this.userPayInfo.p2PayAmt
      this.p1PayDate = (this.userPayInfo.p1PayDate == null) ? "" :       this.getKorDateFormat(this.userPayInfo.p1PayDate)
      this.p2PayDate = (this.userPayInfo.p2PayDate == null) ? "" :       this.getKorDateFormat(this.userPayInfo.p2PayDate)
      this.payPointAmt = (this.userPayInfo.payPointAmt == null) ? 0 :    this.userPayInfo.payPointAmt
      this.payPointDate = (this.userPayInfo.payPointDate == null) ? "" : this.getKorDateFormat(this.userPayInfo.payPointDate)

      this.totalPay = this.p1PayAmt*1 + this.p2PayAmt*1 + this.payPointAmt*1

      let fPayFlag = ""       //전액Y, 부분N
      if(this.userPayInfo.reqTotPayAmt == this.p1PayAmt)
      {
        fPayFlag = "Y"         //부분결제
      }
      else
      {
        fPayFlag = "N"
      }

      this.fPayFlag = fPayFlag
    },






    //예약확정->포인트적립
    pSetAside()
    {
      //console.log(this.userPayInfo.actualPayAmount)
      let setAsidePoint = 0;

      if(this.reserveInfo.rePointMethod === 'P')
      {
        setAsidePoint = (this.userPayInfo.actualPayAmount*1) * ((this.reserveInfo.rePoint*1) / 100)
      }
      else
      {
        setAsidePoint = this.reserveInfo.rePoint*1
      }

      this.$swal.fire({
      icon: 'question',
      html: setAsidePoint + '포인트를 적립하시겠습니까?',
      showCancelButton: true,
      confirmButtonText: '적립',
      cancelButtonText: '나중에'
      }).then(async (result) =>
      {
        if (result.isConfirmed)
        {
          let pSetAside = await this.$api("/api/payByPoint", {param: [
            this.reserveInfo.reqEmail,setAsidePoint,'A',this.reqRsvCode, '구매확정'
            ]});
            if(Object.keys(pSetAside)[0] === 'error')
            {
              this.$swal.fire('', '포인트적립 중 오류가 발생하였습니다립(코드: pSetAside).<br>관리자에게 문의해 주세요.', 'error');
              return false;
            }
            else
            {
              this.$swal.fire('', setAsidePoint+'포인트 적립되었습니다.', 'success');
            }
        }
      })
    },
    /** 두날짜 사이의 날짜추출함수 */
    getDatesStartToLast(startDate, lastDate) {
      //var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
      //if(!(regex.test(startDate) && regex.test(lastDate))) return "Not Date Format";
      if(!(startDate && lastDate)) return "Not Date Format";

      var result = [];
      var curDate = new Date(startDate);
      while(curDate <= new Date(lastDate)) {
        result.push(curDate.toISOString().split("T")[0]);
        curDate.setDate(curDate.getDate() + 1);
      }
      return result;
    },

 async getReqProcLevel() {
      //예약진행상태
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }

      //취소사유
      let cxlFlagList = await this.$api("/api/reqStatusInfo2", {param: ['CXL', 'Y']});
      if(cxlFlagList.length > 0) {
        this.cxlFlagList = cxlFlagList
      }
      //console.log("cxlFlagList:",this.cxlFlagList);
    },

    //엔버스 일정저장
    async saveRoute(nbCode){
      if(this.exist_nbRoute.length <= 0 && this.nbRoute.length <= 0)
      {
        this.$swal.fire("", "저장할 일정경로가 없습니다. 일정을 체크해주세요.", "warning")
        return false;
      }

      let promises = []
      //기존루트 업데이트
      for(let i=0; i<this.exist_nbRoute.length; i++)
      {
        let res_upd_existNbroute = await this.$api("/api/upd_existRoute", {param:[
          this.exist_nbRoute[i].rentStartTime, this.exist_nbRoute[i].rentCloseTime, this.exist_nbRoute[i].deptPoint, this.exist_nbRoute[i].viaPoint, this.exist_nbRoute[i].arrPoint, this.user.handlerId,
          nbCode, this.exist_nbRoute[i].iSubNo
        ]})

        promises.push(res_upd_existNbroute)
      }

      //반복문 이후처리
      Promise.all(promises)
        .then(async (results) => {
          console.log(results)
          //신규루트 인서트
          let promises_add = []
          for(let j=0; j<this.nbRoute.length; j++)
          {
            let res_ins_nbroute = await this.$api("/api/insNbusInquiry_detail", {param:[
              nbCode, this.nbRouteCnt+j+1, this.nbRoute[j].rentStartTime, this.nbRoute[j].rentCloseTime, this.nbRoute[j].deptPoint, this.nbRoute[j].viaPoint, this.nbRoute[j].arrPoint, "Y"
            ]})

            promises_add.push(res_ins_nbroute)
          }
          Promise.all(promises_add)
          .then((res) => {
            console.log(res)
            this.$swal.fire("", "엔버스일정이 등록되었습니다.", "success").then(async () => {
              let nbRentTotDays = this.reserveInfo.rentTotDays
              let upd_nbRenTotDays = await this.$api("/api/upd_nbRenTotDays", {param: [ nbRentTotDays, nbCode]})
              if(upd_nbRenTotDays.affectedRows < 0)
              {
                this.$swal.fire("","사용일자가 맞지 않습니다. 체크 후 수동변경해주세요", "warning")
              }
              location.reload(true)
            })
          })
        })
        .catch((error) => {
          console.log("Api error:", error)
        })
    },

    //결제요청 정보처리
    async insReqPay(nbCode, priceTot)
    {
      let insReqPayInfo = await this.$api("/api/insReqPayInfo_1",{param: [
        nbCode,"RP", priceTot, 'N', this.user.handlerId, 'fu', priceTot
      ]});
      if(Object.keys(insReqPayInfo)[0] === 'error')
      {
        this.$swal.fire('','처리 중 오류발생(insReqPayInfo)<br>관리자에게 문의하세요', 'error')
        return false;
      }
    },

    async updReqPay(nbCode, priceTot)
    {
      let updReqPayInfo = await this.$api("/api/updReqPayInfo",{param: [
        "RP", priceTot, this.user.handlerId, nbCode
      ]});
      if(updReqPayInfo.affectedRows < 0 || updReqPayInfo.affectedRows === undefined)
      {
        this.$swal.fire('','처리 중 오류발생(updReqPayInfo)<br>관리자에게 문의하세요', 'error')
        return false;
      }
    },

    //엔버스 내용업데이트
    async updateResInfo(nbCode){
      if(this.nbusPriceTot == "" || this.nbusPriceTot < 0)
      {
        this.$swal.fire("","상품가격이 없습니다. 계산해주세요", "question")
        return false;
      }

      if(this.reserveInfo.inqStatus === "CX")
      {
        if(this.reserveInfo.cxlFlag == null || this.reserveInfo.cxlFlag == "")
        {
          this.$swal.fire("","취소사유를 선택하세요!","error")
          return false;
        }
        else
        {
          //취소처리
          let inqStatus = "CX"
          let cxlFlag = this.reserveInfo.cxlFlag
          let cxlId = this.user.handlerId
          let cxl_nbus = this.$api("/api/cxlNbusRes", { param: [ inqStatus, cxlFlag, cxlId, nbCode ]})

          if(cxl_nbus.affectedRows < 0)
          {
            this.$swal.fire("","취소처리 중 오류가 발생했습니다. 재시도해주세요", "error")
            location.reload(true)
          }
          else
          {
            this.$swal.fire("","취소처리되었습니다.", "info").then(() => {
              this.sendKakao("CX", this.reserveInfo.memFlag, nbCode, "")
            })
            //location.reload(true)
          }
        }
      }
      else
      {
        if(this.reserveInfo.inqStatus === "RP" || this.reserveInfo.inqStatus === "CO")
        {
          if(this.rtnRPflag === "Y")
          {
            this.updReqPay(nbCode, this.nbusPriceTot)
          }
          else
          {
            this.insReqPay(nbCode, this.nbusPriceTot)
          }
        }
        //진행상태별 분류처리(알림톡발송: 결제요청(RP)/확정대기(WC)/확정(CO))
        this.updateNbusData(nbCode, this.reserveInfo.inqStatus)
      }
    },

    async updateNbusData(nbCode, nbStatus){
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm_tmp = today.getMonth()+1;
      let dd_tmp = today.getDate();
      let hour = today.getHours();
      let min = today.getMinutes();
      let sec = today.getSeconds();
      
      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;
      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;
      
      let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
      //데이타 업데이트
      let handlerId = this.user.handlerId
      
      let oldComment = ""; 
      let newComment = "";
      oldComment = this.reserveInfo.oldComment;
      newComment = this.reserveInfo.newComment;
      
      //요금이 없으면 0으로
      let nbusPriceTot = (this.nbusPriceTot == undefined || this.nbusPriceTot == "") ? 0 : this.nbusPriceTot*1
      
      let insComment = oldComment +'\n'+ "["+ this.user.handlerId +":"+ insDate +"]: "+ newComment;
      
      let res_updNbusInfo = await this.$api("/api/proc_updNbusInfo", { param: [
        nbCode, this.reserveInfo.inqName, this.reserveInfo.inqTel, this.reserveInfo.rentStartDate, this.reserveInfo.guideFlag,
        this.reserveInfo.personCnt, this.reserveInfo.busFlag, handlerId, this.reserveInfo.inqStatus, this.reserveInfo.rentTotDays,
        this.reserveInfo.addComment, nbusPriceTot, insComment, this.reserveInfo.supPrecaution, this.reserveInfo.supCancellation
      ]})
      if(res_updNbusInfo.affectedRows < 0)
      {
        this.$swal.fire("", "업데이트 중 오류가 발생했습니다. 재시도해주세요", "error")
        location.reload(true)
      }
      else
      {
        this.$swal.fire("", "업데이트가 완료되었습니다", "success").then(() => {
          //결제요청인 경우 알림톡 전액결제로 처리
          let pFlag = (nbStatus === "RP") ? "fu" : ""
          
          //알림톡 발송조건에 부합되면 호출
          if(nbStatus === "RP" || nbStatus === "WC" || nbStatus === "CO")
          {
            this.sendKakao(nbStatus, this.reserveInfo.memFlag, nbCode, pFlag)
          }
          else
          {
            location.reload(true)
          }
        })
      }
    },

    async getHandlerInfo()
    {
      let handTel = await handlerInfo(this.$api);
      if(handTel.length > 0)
      {
        this.handTel = handTel
      }
      //console.log("handTel: ", this.handTel)
    },

    /** 카카오알림 발송 */
    async sendKakao(param, mFlag, nbCode, pFlag)   //예약상태, 회원여부, 엔버스예번, 전액/일부결제
    {
      //let mParam = ""
      console.log(pFlag)
      if(mFlag === 'N' || mFlag == null)
      {
        param = "non" + param
        mFlag = "N"
      }

      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        nbCode, param, this.user.handlerId
      ]});

      //알림톡 발송대상 확정
      this.handTel.push({aimCall: this.reserveInfo.inqTel})
      this.handTelCnt = this.handTel.length

      if(insKSendHistory.affectedRows > 0)
      {
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm_tmp = today.getMonth()+1;
        let dd_tmp = today.getDate();

        let mm = "";
        if(mm_tmp < 10) {mm = "0" + mm_tmp}
        else mm = mm_tmp;
        let dd = "";
        if(dd_tmp < 10) {dd = "0" + dd_tmp}
        else dd = dd_tmp;

        let curDate = yyyy + "-" + mm + "-" + dd

        // //부분결제의 잔액청구 시, 처리
        // let resPayAmount = 0
        // if(pFlag === "p2")
        // {
        //   resPayAmount = this.balance
        // }
        // else
        // {
        //   resPayAmount = this.nbusPriceTot
        // }

        let promises = []         //promise들의 배열

        let guideLvName = ""
        if(this.reserveInfo.guideFlag == "")
        {
          guideLvName = "없음"
        }
        else
        {
          let guideLvName_tmp = this.guideLvInfo.filter(item => {
            return item.baseCode === this.reserveInfo.guideFlag
          })
          guideLvName = guideLvName_tmp[0].codeNameKor
        }

        let busLvName_tmp = this.busLvInfo.filter(item => {
          return item.baseCode === this.reserveInfo.busFlag
        })
        let busLvName = busLvName_tmp[0].codeNameKor        
        console.log(guideLvName, busLvName)

        //회원 결제요청
        if(param == "RP" || param == "nonRP")
        {
          let i = 0
          while(i < this.handTelCnt)
          {
            //결제요청 카카오 알림톡
            let kakaoAlimData =
            {
              //카카오 알림톡 발송(결제요청)
              clientName: this.reserveInfo.inqName,
              inqName: this.reserveInfo.inqName,
              inqTel: this.reserveInfo.inqTel,                             //고객연락처
              nbusPriceTot: this.getCurrencyFormat(this.nbusPriceTot),     //결제금액
              paxCnt: this.reserveInfo.personCnt,
              guideLv: guideLvName,
              busLv: busLvName,
              deptPoint: this.exist_nbRoute[0].deptPoint,
              rentStartDate: this.reserveInfo.rentStartDate,
              rentStartTime: this.exist_nbRoute[0].rentStartTime,
              rentTotDays: this.reserveInfo.rentTotDays,
              inqCode: nbCode,
              alimFlag: param,                                             //알림유형(결제요청)
              pdtCode: nbCode,                                             //결제처리용 상품코드
              toNumber: this.handTel[i].aimCall,                           //알림톡발송대상
              memFlag: mFlag,                                              //멤버여부
            };
            let promise = this.$api("api/kakaoAlimSend7", {
              param: [{ kakaoAlimData: kakaoAlimData}]
            })

            promises.push(promise)
            i++
          }

          //반복문 이후 처리
          Promise.all(promises).then((results) => {
            console.log(results)
            this.$swal.fire('', '결제요청 알림톡이 발송되었습니다', 'success').then(() => {
              location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error)
          })
        }

        else if(param == "CO" || param == "nonCO")
        {
          let inqStatus = "예약확정"
          let i = 0
          while(i < this.handTelCnt)
          {
            //카카오 알림톡 발송(확정)
            let kakaoAlimData =
            {
              clientName: this.reserveInfo.inqName,
              inqStatus: inqStatus,
              inqName: this.reserveInfo.inqName,
              inqTel: this.reserveInfo.inqTel,                             //고객연락처
              paxCnt: this.reserveInfo.personCnt,
              guideLv: guideLvName,
              busLv: busLvName,
              deptPoint: this.exist_nbRoute[0].deptPoint,
              rentStartDate: this.reserveInfo.rentStartDate,
              rentStartTime: this.exist_nbRoute[0].rentStartTime,
              rentTotDays: this.reserveInfo.rentTotDays,
              inqCode: nbCode,
              alimFlag: param,                                             //알림유형(결제요청)
              pdtCode: nbCode,                                             //결제처리용 상품코드
              toNumber: this.handTel[i].aimCall,                           //알림톡발송대상
              memFlag: mFlag,                                              //멤버여부
            };

            let promise = this.$api("api/kakaoAlimSend7", {
              param: [{ kakaoAlimData: kakaoAlimData}]
            })

            promises.push(promise)

            i++
          }
          Promise.all(promises).then((results) => {
            console.log(results)
            this.$swal.fire('', `${inqStatus} 알림톡이 발송되었습니다`, 'success').then(() => {
              location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error)
          })
        }

        //취소처리
        else if(param == "CX" || param == "nonCX")
        {
          let i = 0
          while(i < this.handTelCnt)
          {
            //카카오 알림톡 발송(취소)
            let kakaoAlimData =
            {
              inqName: this.reserveInfo.inqName,
              inqCode: nbCode,
              cxlDate: curDate,
              cxlRemark: this.cxlName,                                  //취소사유
              alimCall: this.reserveInfo.inqTel,
              toNumber: this.handTel[i].aimCall,                        //카톡발송연락처
              alimFlag: param,
            };

            let promise = this.$api("api/kakaoAlimSend7", {
              param: [{ kakaoAlimData: kakaoAlimData}]
            })

            promises.push(promise)

            i++
          }

          Promise.all(promises).then((results) => {
            console.log(results)
            this.$swal.fire('', '취소 알림톡이 발송되었습니다', 'success').then(() => {
              location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error)
          })

        }
      }
    },

    //해당예약의 유저가 사용한 포인트
    async getUserPayPoint(resCode)
    {
      let userPayPoint = [];
      userPayPoint = await this.$api("/api/getUserPayPoint", {param: [this.reserveInfo.reqEmail, resCode]});
      if(userPayPoint.length > 0)
      {
        this.userPayPoint = userPayPoint[0];
      }
      //console.log("userPayPoint:", this.userPayPoint)
    },

    //가용포인트 확인
    async getAvailPoint()
    {
      let totalPoint = await this.$api("/api/userTotalPoint", {param: [this.reserveInfo.reqEmail]});
      //console.log("totalPoint:",totalPoint)

      let unavailPoint = await this.$api("/api/userUnavailablePoint", {param: [this.reserveInfo.reqEmail]});
      console.log("unavailPoint:",unavailPoint)

      //let availPoint = 0;
      if(totalPoint.length > 0)
      {
        //적립
        let sumA = totalPoint.filter((itme) => {return itme.useFlag === 'A'})
        sumA = (sumA.length > 0) ? sumA[0].total_A : 0;

        //사용
        let sumU = totalPoint.filter((itme) => {return itme.useFlag === 'U'})
        sumU = (sumU.length > 0) ? sumU[0].total_U : 0;

        //사용대기
        let sumS = totalPoint.filter((itme) => {return itme.useFlag === 'S'})
        sumS = (sumS.length > 0) ? sumS[0].total_S : 0;

        //적립대기
        let sumW = totalPoint.filter((itme) => {return itme.useFlag === 'W'})
        sumW = (sumW.length > 0) ? sumW[0].total_W : 0;
        //console.log(sumA,"/", sumU,"/", sumS,"/", sumW)

        //조회시점 가용포인트
        this.availPoint = (sumA*1 + sumW*1) - (sumU*1 + sumS*1);
      }
      else
      {
        this.availPoint = 0;
      }
    },
    // 가용포인트 사용처리
    payByPoint()
    {
      if(!this.cstPrice || this.cstPrice == 0)
      {
        this.$swal.fire("","결제할 금액이 없거나 0원입니다.","warning");
        return;
      }
      else
      {
        this.$swal.fire({
        icon: 'question',
        text: '포인트를 사용하시겠습니까?',
        showCancelButton: true,
        confirmButtonText: '사용',
        cancelButtonText: '나중에'
        }).then(async (result) =>
        {
          if (result.isConfirmed)
          {
            //포인트 사용처리(포인트결제 한도없음)
            let availPoint = this.availPoint
            let amtAfterPayingPoint =  this.cstPrice*1 - availPoint*1

            if(amtAfterPayingPoint > 0)
            {
              this.paidPoint = availPoint
              this.availPoint = 0
              this.cstPrice = amtAfterPayingPoint;
              //this.paymentInFull = "Y" //완납여부
            }
            else
            {
              this.paidPoint = this.cstPrice
              this.availPoint = availPoint - this.cstPrice
              this.cstPrice = 0
              this.paymentInFull = "Y" //완납여부
            }
          }
        })
      }
    },

    goToList() {
      this.$router.push({path: '/nbusInquiryList_admin'});
      //this.$router.push({path: '/myPage'});
    },

    /** 두배열간 중복제거 */
    removeDubofArrays(arr1, arr2)
    {
      return arr1.concat(arr2).filter(item => !arr1.includes(item) || !arr2.includes(item));
    },

    //고객취소요청예약인 경우, 마이페이지용 처리
    async reqCxlHandling(rCode)
    {
      let upd_reqCxl = await this.$api("/api/upd_reqCxl", {param: [
        this.user.handlerId,'Y',rCode
      ]});
      if(upd_reqCxl.affectedRows < 0 || upd_reqCxl.affectedRows === undefined)
      {
        this.$swal.fire('', '예약취소 중 중 오류가 발생하였습니다.', 'error');
        return false;
      }
    },

    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    //한국표준시로 변경
    getKorDateFormat(date) {
      return this.$korDateFormat(date)
    },



    // // 잔액결제청구
    // async payBalance(){
    //   let reqPayBalanceStat = await this.$api("/api/reqPayBalanceStat", {param: [
    //     "Y", this.balance, this.reqRsvCode+"-1"
    //   ]});

    //   if(reqPayBalanceStat.affectedRows < 0)
    //   {
    //     this.$swal.fire("","잔액결제청구에 문제가 발생했습니다. 재시도 또는 관리자에게 문의해주세요", "warning")
    //     return false
    //   }
    //   else
    //   {
    //     this.sendKakao("RP", this.reserveInfo.memFlag, "p2")
    //   }
    // },    
  }
}
</script>