<template>
  <main id="newMain">
    <div id="app" class="main-container" v-if="!this.mobileDisplay">
      <!-- Header Section -->
      <header class="d-flex justify-content-between align-items-center py-3 top-menu">
        <div class="mlogo">
          <a href="/">
            <img src="../../src/assets/img/Ntabi.png"  class="logo" alt="Ntabi" />
          </a>
          <!-- <img src="../../src/assets/img/pBus.png" alt="" style="width:30px;"> -->
          <img src="../../src/assets/img/pBus.png" alt="" style="width: 30px" v-if="this.user.email != undefined && this.user.memLevel == 'P'" />
          <img src="../../src/assets/img/sBus.png" alt="" style="width: 30px" v-else-if="this.user.email != undefined && this.user.memLevel == 'S'" />
          <img src="../../src/assets/img/gBus.png" alt="" style="width: 30px" v-else-if="this.user.email != undefined && this.user.memLevel != 'P' && this.user.memLevel != 'S'" />
        </div>

        <nav>
          <ul class="nav" style="display: flex; align-items: center">
            <li class="nav-item" v-if="user.email == undefined">
              <a href="javascript:void(0)" class="nav-link text-secondary" @click="toB2CLoginPage" >로그인</a>
            </li>                                                                                                                                                                                               
            <li class="nav-item" v-else>
              <a href="javascript:void(0)" class="nav-link text-secondary" @click="toB2CLogout" >로그아웃</a>
            </li>
            <li class="nav-item" v-if="user.email == undefined">
              <a href="javascript:void(0)" class="nav-link text-secondary" @click="toMembership">회원가입</a>
            </li>
            <li class="nav-item" v-else>
              <a href="javascript:void(0)" class="nav-link text-secondary" @click="toMyPage">마이페이지</a>
            </li>
            <li class="nav-item">
              <a href="https://smartstore.naver.com/ntabi" target="_blank" class="nav-link blink fw-bold" style="color: green" >네이버예약</a>
            </li>
            <!-- <li class="nav-item">
              <a href="https://smartstore.naver.com/somen" target="_blank" class="nav-link" style="color: green" >소면공방</a>
            </li> -->
            <!-- <div class="input-with-icon">
              <li class="nav-item">
                <input type="text" class="form form-text-sm searchBox text-primary"/>
              </li>
            </div> -->
          </ul>
        </nav>
      </header>

      <section id="topBanner" class="topBanner" ref="mainBanner">
        <div id="carouselExampleControls" class="topCarousel carousel slide" data-bs-ride="carousel" data-bs-interval="5000">
          <div class="carousel-inner" style="scroll-behavior: smooth">
            <div class="carousel-item" v-for="(slide, index) in slides" :key="index" :class="{ active: index === 0 }">
              <div class="carousel-image-container">
                <img 
                  :src="slide.image"
                  class="d-block"
                  :alt="slide.alt"
                  style="cursor: pointer"
                  @click="goLink(slide.type, slide.link, slide.pId, slide.sRoute)"
                />
                <img 
                  :src="slide.imageTxt"
                  class="overLay"
                  style="cursor: pointer"
                  @click="goLink(slide.type, slide.link, slide.pId, slide.sRoute)"
                />                
              </div>

              <div class="carousel-text-box">
                <div class="carouselText1" v-html="slide.text1"></div>
                <div class="carouselText2" v-html="slide.text2"></div>
                <!-- <div class="carouselText2">{{ slide.text2 }}</div> -->
              </div>
              <div class="carousel-blur-overlay"></div>
            </div>

            <div class="carousel-indicators thumbnail-indicators">
              <button
                v-for="(slide, index) in slides"
                :key="index"
                type="button"
                :data-bs-target="'#carouselExampleControls'"
                :data-bs-slide-to="index"
                :class="{ active: index === 0 }"
                :aria-current="index === 0 ? 'true' : 'false'"
                :aria-label="'Slide ' + (index + 1)"
              >
                <img :src="slide.image" :alt="slide.alt" class="thumbnail-image"/>
              </button>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </section>

      <!-- 상단메뉴 -->
      <div class="pc-container">
        <div class="side left"></div>

        <div class="center">
          <section class="menu_backgorund_color w-100 mt-3">
            <div class="justify-content-center">
              <table class="w-100 text-center">
                <tr>
                  <td class="mainCategory">
                    <button type="btn" @click="goToPage('BsTour');" style="border: none; padding: 0" class="cateButton" >
                      <img
                        src="../assets/img/main/cateBtn/ndaytrip_mt.png"
                        alt=""
                        style="width: 100%; height: 100%"
                      />
                    </button>
                  </td>
                  <td class="mainCategory">
                    <button type="btn" @click="goToPage('MShip')" style="border: none; padding: 0" class="cateButton">
                      <img
                        src="../assets/img/main/cateBtn/membership_mt.png"
                        alt=""
                        style="width: 100%; height: 100%"
                      />
                    </button>
                  </td>
                  <td class="mainCategory">
                    <button type="btn" @click="goToPage('NBus')" style="border: none; padding: 0" class="cateButton">
                      <img
                        src="../assets/img/main/cateBtn/nbus_mt.png"
                        alt=""
                        style="width: 100%; height: 100%"
                      />
                    </button>
                  </td>
                </tr>
                <tr>
                  <td class="mainCategory">
                    <button type="btn" @click="goToPage('NtVr');" style="border: none; padding: 0" class="cateButton">
                      <img
                        src="../assets/img/main/cateBtn/veriety_mt.png"
                        alt=""
                        style="width: 100%; height: 100%"
                      />
                    </button>
                  </td>
                  <td class="mainCategory">
                    <button type="btn" @click="goToPage('JShip')" style="border: none; padding: 0" class="cateButton">
                      <img
                        src="../assets/img/main/cateBtn/japanship_mt.jpg"
                        alt=""
                        style="width: 100%; height: 100%"
                      />
                    </button>
                  </td>
                  <td class="mainCategory">
                    <button type="btn" @click="goToPage('TkPass')" style="border: none; padding: 0" class="cateButton" >
                      <img
                        src="../assets/img/main/cateBtn/ticketpass_mt.png"
                        alt=""
                        style="width: 100%; height: 100%"
                      />
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </section>

          <!-- 엔데이트립 상품리스트 (상단연동중단: 20250311) -->
          <section class="mt-5 w-100" id="saleitemSection">
            <div>
              <div class="justify-content-center">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="section-title">
                    <h2 class="section-title">일본에서 최고의 1일, 엔데이트립 현지투어</h2>
                    <p class="section-description">
                      현지전문 한국인가이드와 알차게, 전용버스로 편안하게, 각종 특전으로 여행을 더욱 실속있고 즐겁게
                    </p>
                  </div>
                  <div class="text-end">
                    <a href="/bustour" ><i class="bi bi-luggage-fill"></i> 전체상품보기</a>
                  </div>
                </div>
              </div>

              <div class="justify-content-center">
                <table class="w-100 text-center">
                  <tr>
                    <td class="col-6 saleitemTable" rowspan="2">
                      <div class="nday">
                        <div class="card product-detail">
                          <!-- 선택없은 초기화면 -->
                          <div v-if = "this.selFlag === 'N'">
                            <img v-if="this.selectedPdtInfo.imgName != 'noImage'" :src="`/download/${this.selectedPdtInfo.pdtCode}/${this.selectedPdtInfo.imgName}`" class="card-img-top rightPdtImage" alt="..."/>
                            <img v-else :src="`/download/noImage/noSelectedPDT.png`" class="card-img-top rightPdtImage" alt="no image"/>

                            <!-- 뱃지 -->
                            <div class="badge">
                              <div v-if="this.selectedPdtInfo.badgeFlag == 'Y'">
                                <div class="badge1">
                                  <span class="badge1-text" style="font-size: 1.5rem">{{ this.selectedPdtInfo.badge }}</span>
                                </div>
                              </div>
                              <div v-if="this.selectedPdtInfo.badge2Flag == 'Y'" >
                                <div v-if=" this.selectedPdtInfo.badgeFlag == 'N' || this.selectedPdtInfo.badgeFlag == undefined" class="badge1">
                                  <span class="badge1-text" style="font-size: 1.5rem" >{{ this.selectedPdtInfo.badge2 }}</span>
                                </div>
                                <div v-else class="badge2" style="margin-left: 16rem">
                                  <span class="badge2-text" style="font-size: 1.5rem" >{{ this.selectedPdtInfo.badge2 }}</span>
                                </div>
                              </div>
                              <div v-else-if="(this.badgeF1 === '' || this.badgeF1 === 'N') && (this.badgeF2 === '' || this.badgeF2 === 'N')">
                                <!-- 영역고정을 위해 뱃지가 없을 때도 선언 -->
                              </div>
                            </div>

                            <h5 class="nday-body">
                              <h5 class="nday-title">{{ this.selectedPdtInfo.arName }}</h5>
                              <p class="showPdtName">{{ this.selectedPdtInfo.pdtNameKor }}</p>
                            </h5>
                          </div>

                          <!-- 우측상품선택 후 -->
                          <div v-else-if="this.selFlag === 'Y'">
                            <img v-if="this.pImgName != 'noImage'" :src="`/download/${this.pCode}/${this.pImgName}`" class="card-img-top rightPdtImage" alt="..."/>
                            <img v-else :src="`/download/noImage/noSelectedPDT.png`" class="card-img-top rightPdtImage" alt="no image"/>

                            <!-- 뱃지표시 -->
                            <div class="badge">
                              <div v-if="this.badgeF1 == 'Y'">
                                <div class="badge1">
                                  <span class="badge1-text" style="font-size: 1.5rem">{{ this.badge1 }}</span>
                                </div>
                              </div>
                              <div v-if="this.badgeF2 == 'Y'">
                                <div v-if="this.badgeF1 == ''" class="badge1">
                                  <span class="badge1-text" style="font-size: 1.5rem">{{ this.badge2 }}</span>
                                </div>
                                <div v-else class="badge2" style="margin-left: 16rem">
                                  <span class="badge2-text" style="font-size: 1.5rem">{{ this.badge2 }}</span>
                                </div>
                              </div>
                              <div v-else-if="(this.badgeF1 === '' || this.badgeF1 === 'N') && (this.badgeF2 === '' || this.badgeF2 === 'N')">
                                <!-- 영역고정을 위해 뱃지가 없을 때도 선언 -->
                              </div>
                            </div>
                            <h5 class="nday-body">
                              <h5 class="nday-title">{{ this.pArName }}</h5>
                              <p class="showPdtName">{{ this.pName }}</p>
                            </h5>
                          </div>

                          <div class="card-body">
                            <div class="nday-price">
                              <div v-if="this.user.memLevel === 'P'">
                                <div v-if="selectedPdtInfo.dispPrice === 'mem'">
                                  <div v-if="selectedPdtInfo.sMinPrice === selectedPdtInfo.memP">
                                    <img src="../assets/img/pBus.png" alt="" class="mbus-icon"/>
                                    <b class="mx-2 ndayPrice">0원! <span>(연간무제한)</span></b>
                                  </div>
                                  <div v-else>
                                    <img src="../assets/img/pBus.png" alt="" class="mbus-icon"/>
                                    <b class="mx-2 ndayPrice"> 
                                      {{ getCurrencyFormat(selectedPdtInfo.sMinPrice) }}<span>원~</span>
                                    </b>
                                    <span style="font-size: 0.8rem" v-if="selectedPdtInfo.memP * 1 > 0">(결제 후,</span>
                                    <b class="mx-2" style="color: #ffce00; font-size: 0.9rem" v-if="selectedPdtInfo.memP * 1 > 0" >
                                      {{ getCurrencyFormat (selectedPdtInfo.memP) }}
                                    </b>
                                    <span style="font-size: 0.8rem" v-if="selectedPdtInfo.memP * 1 > 0" >원 환급)</span>
                                  </div>
                                </div>
                                <div v-else>
                                  <div v-if="selectedPdtInfo.promoStatus === 'Y'" >
                                    <img src="../assets/img/pBus.png" alt="" class="mbus-icon" /> 
                                    <b class="text-muted text-decoration-line-through mx-2 ndayPrice">{{ getCurrencyFormat(selectedPdtInfo.sMinPrice) }}
                                    </b>
                                    <i class="bi bi-caret-right-fill"></i>
                                    <b class="mx-2 ndayPrice" >{{ getCurrencyFormat(selectedPdtInfo.pPrice) }}<span>원~</span></b>
                                  </div>
                                  <div v-else-if="selectedPdtInfo.promoStatus === 'N'">
                                    <img src="../assets/img/pBus.png" alt="" class="mbus-icon" /> 
                                    <b class="mx-2 ndayPrice" >{{ getCurrencyFormat(selectedPdtInfo.pPrice) }}<span>원~</span></b>
                                  </div>
                                  <div v-else-if="selectedPdtInfo.promoStatus === 'E'">
                                    <img src="../assets/img/pBus.png" alt="" class="mbus-icon" /> 
                                    <b class="mx-2 ndayPrice" >{{ getCurrencyFormat(selectedPdtInfo.pPricepagedProductList) }}<span>원~</span></b>
                                  </div>
                                </div>
                              </div>

                              <div v-else-if="this.user.memLevel === 'S'">
                                <div v-if="selectedPdtInfo.dispPrice === 'mem'">
                                  <div v-if="selectedPdtInfo.dcForStd > 0">
                                    <div>
                                      <img src="../assets/img/sBus.png" alt="" class="mbus-icon" />
                                      <b class="mx-2 ndayPrice"> 
                                      {{ getCurrencyFormat(selectedPdtInfo.sMinPrice * 1 - selectedPdtInfo.sMinPrice * (selectedPdtInfo.dcForStd / 100)) }}<span>원~</span>
                                      </b>
                                    </div>
                                    <div>
                                      <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined"/>
                                      <b class="mx-2 ndayPrice" v-if="selectedPdtInfo.sMinPrice === selectedPdtInfo.memP">0원! <span>(연간무제한)</span></b>
                                      <b class="mx-2 ndayPrice" v-else > 
                                      {{ getCurrencyFormat(selectedPdtInfo.sMinPrice * 1 - selectedPdtInfo.sMinPrice * (selectedPdtInfo.maxDC_P / 100)) }}<span>원~</span>
                                      </b>
                                    </div>
                                  </div>
                                  <div v-else>
                                    <div
                                      v-if="selectedPdtInfo.sMinPrice === selectedPdtInfo.memS">
                                      <img
                                        src="../assets/img/sBus.png"
                                        alt=""
                                        class="mbus-icon"
                                      />
                                      <b class="mx-2 ndayPrice">0원! <span>(연간무제한)</span></b>
                                    </div>
                                    <div v-else>
                                      <img
                                        src="../assets/img/sBus.png"
                                        alt=""
                                        class="mbus-icon"
                                      />
                                      <b class="mx-2 ndayPrice"> {{ getCurrencyFormat(selectedPdtInfo.sMinPrice) }}<span>원~</span></b>
                                      <span style="font-size: 0.8rem" v-if="selectedPdtInfo.memS * 1 > 0">(결제 후, </span><b class="mx-2 ndayPrice" style="color: slategrey; font-size: 0.9rem;" v-if="selectedPdtInfo.memS * 1 > 0" > {{ getCurrencyFormat(selectedPdtInfo.memS) }}</b>
                                      <span style="font-size: 0.8rem" v-if="selectedPdtInfo.memS * 1 > 0">원 환급)
                                      </span>
                                    </div>
                                    <div>
                                      <img
                                        src="../assets/img/pBus.png"
                                        alt=""
                                        class="mbus-icon"
                                        v-if="user.email != undefined"
                                      />
                                      <b class="mx-2 ndayPrice" v-if="selectedPdtInfo.sMinPrice === selectedPdtInfo.memP">0원! <span>(연간무제한)</span></b>
                                      <b class="mx-2 ndayPrice" v-else>{{ getCurrencyFormat(selectedPdtInfo.sMinPrice * 1 - selectedPdtInfo.sMinPrice * (selectedPdtInfo.maxDC_P / 100))}}<span>원~</span>
                                      </b>
                                    </div>
                                  </div>
                                </div>

                                <div v-else>
                                  <div v-if="selectedPdtInfo.promoStatus === 'Y'">
                                    <img
                                      src="../assets/img/sBus.png"
                                      alt=""
                                      class="mbus-icon"
                                    />
                                    <b class="text-muted text-decoration-line-through mx-2 ndayPrice"> {{getCurrencyFormat(selectedPdtInfo.sMinPrice) }}</b>
                                    <i class="bi bi-caret-right-fill"></i>
                                    <b class="mx-2 ndayPrice"> {{ getCurrencyFormat(selectedPdtInfo.pPrice) }}<span>원~</span></b>
                                  </div>
                                  <div v-else-if="selectedPdtInfo.promoStatus === 'N'">
                                    <b class="mx-2 ndayPrice"> {{ getCurrencyFormat(selectedPdtInfo.pPrice) }}<span>원~</span></b>
                                  </div>
                                  <div v-else-if="selectedPdtInfo.promoStatus === 'E'">
                                    <b class="mx-2 ndayPrice"> {{ getCurrencyFormat(selectedPdtInfo.pPrice) }}<span>원~</span></b>
                                  </div>
                                </div>
                              </div>

                              <!-- 비로그인, 일반회원 멤버십표시 -->
                              <div v-else>
                                <!-- 일반회원가격(default) -->
                                <div class="my-1" v-if="selectedPdtInfo.promoStatus === 'Y'">
                                  <img
                                    src="../assets/img/gBus.png"
                                    alt=""
                                    class="mbus-icon"
                                    v-if="user.email != undefined"
                                  />
                                  <label class="memLabel-G" v-else >일반회원
                                  </label>
                                  <b class="text-muted text-decoration-line-through mx-2 ndayPrice"> {{ getCurrencyFormat(selectedPdtInfo.sMinPrice) }}</b>
                                  <i class="bi bi-caret-right-fill"></i>
                                  <b class="mx-2 ndayPrice"> {{ getCurrencyFormat(selectedPdtInfo.pPrice) }}<span>원~</span></b>
                                </div>
                                <div class="my-1" v-else-if="selectedPdtInfo.promoStatus === 'E'">
                                  <div class="my-1">
                                    <img
                                      src="../assets/img/gBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label class="memLabel-G" v-else >일반회원</label>
                                    <b class="mx-2 ndayPrice"> {{ getCurrencyFormat(selectedPdtInfo.pPrice) }}<span>원~</span></b>
                                  </div>
                                </div>
                                <div class="my-1" v-else-if="selectedPdtInfo.promoStatus === 'N'">
                                  <div class="my-1" v-if="selectedPdtInfo.dcForMem_S > 0">
                                    <img
                                      src="../assets/img/sBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label class="memLabel-S" v-else >스탠다드</label>
                                    <b class="mx-2 ndayPrice"> {{ getCurrencyFormat(selectedPdtInfo.pPrice) }}<span>원~</span></b>
                                  </div>
                                  <div v-else class="my-1">
                                    <img
                                      src="../assets/img/gBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label class="memLabel-G" v-else >일반회원</label>
                                    <b class="mx-2 ndayPrice"> {{ getCurrencyFormat(selectedPdtInfo.pPrice) }}<span>원~</span></b>
                                  </div>
                                </div>

                                <div v-if="((selectedPdtInfo.memLevel_S == 'N' || selectedPdtInfo.memLevel_S == null) && (selectedPdtInfo.memLevel_P == 'N' || selectedPdtInfo.memLevel_P == null)) || selectedPdtInfo.nrFlag == 'Y' || selectedPdtInfo.vrFlag == 'Y'">
                                  <div class="my-1" v-if="selectedPdtInfo.promoStatus === 'E'" >
                                    <div>
                                      <div class="my-1">
                                        <img
                                          src="../assets/img/sBus.png"
                                          alt=""
                                          class="mbus-icon"
                                          v-if="user.email != undefined"
                                        />
                                        <label class="memLabel-S" v-else>스탠다드</label>
                                        <b class="mx-2 ndayPrice"> 
                                          {{ getCurrencyFormat(selectedPdtInfo.sMinPrice * 1 - selectedPdtInfo.price_dcForMem_S) }}<span>원~</span>
                                        </b>
                                      </div>
                                      <div class="my-1">
                                        <img
                                          src="../assets/img/pBus.png"
                                          alt=""
                                          class="mbus-icon"
                                          v-if="user.email != undefined"
                                        />
                                        <label class="memLabel-P" v-else>프리미엄</label>
                                        <b class="mx-2 ndayPrice"> 
                                          {{ getCurrencyFormat(selectedPdtInfo.sMinPrice * 1 - selectedPdtInfo.price_dcForMem_P) }}<span>원~</span>
                                        </b>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="my-1" v-else-if="selectedPdtInfo.promoStatus === 'N'">
                                    <div>
                                      <div class="my-1">
                                        <img
                                          src="../assets/img/sBus.png"
                                          alt=""
                                          class="mbus-icon"
                                          v-if="user.email != undefined"
                                        />
                                        <label class="memLabel-S" v-else>스탠다드</label>
                                        <b class="mx-2 ndayPrice"> 
                                          {{ getCurrencyFormat(selectedPdtInfo.sMinPrice * 1 - selectedPdtInfo.price_dcForMem_S) }}<span>원~</span>
                                        </b>
                                      </div>
                                      <div class="my-1">
                                        <img
                                          src="../assets/img/pBus.png"
                                          alt=""
                                          class="mbus-icon"
                                          v-if="user.email != undefined"
                                        />
                                        <label class="memLabel-P" v-else>프리미엄</label>
                                        <b class="mx-2 ndayPrice"> 
                                          {{ getCurrencyFormat(selectedPdtInfo.sMinPrice * 1 - selectedPdtInfo.price_dcForMem_P) }}<span>원~</span>
                                        </b>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <!-- 스탠다드멤버십 상품 -->
                                <div class="my-1" v-if="selectedPdtInfo.memLevel_S === 'Y' && (selectedPdtInfo.nrFlag === 'N' || selectedPdtInfo.nrFlag == null) && (selectedPdtInfo.vrFlag === 'N' || selectedPdtInfo.vrFlag == null)">
                                  <img
                                    src="../assets/img/sBus.png"
                                    alt=""
                                    class="mbus-icon"
                                    v-if="user.email != undefined"
                                  />
                                  <label class="memLabel-S" v-else>스탠다드</label>
                                  <b class="mx-2 ndayPrice" v-if="getCurrencyFormat(selectedPdtInfo.sMinPrice * 1 - selectedPdtInfo.sMinPrice * (selectedPdtInfo.maxDC_S / 100)) == 0"> 0원! <span>(연간무제한)</span></b>
                                  <b class="mx-2 ndayPrice" v-else> 
                                    {{ getCurrencyFormat(selectedPdtInfo.sMinPrice * 1 - selectedPdtInfo.sMinPrice * (selectedPdtInfo.maxDC_S / 100)) }}<span>원~</span>
                                  </b>
                                </div>

                                <!-- 프리미엄멤버십 상품 -->
                                <div class="my-1" v-if="selectedPdtInfo.memLevel_P === 'Y' && (selectedPdtInfo.nrFlag === 'N' || selectedPdtInfo.nrFlag == null) && (selectedPdtInfo.vrFlag === 'N' || selectedPdtInfo.vrFlag == null)">
                                  <div class="my-1" v-if="(selectedPdtInfo.memLevel_s == 'N' || selectedPdtInfo.memLevel_s == null) && selectedPdtInfo.dcForStd > 0">
                                    <img
                                      src="../assets/img/sBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label class="memLabel-S" v-else>스탠다드</label>
                                    <b class="mx-2 ndayPrice"> 
                                      {{ getCurrencyFormat(selectedPdtInfo.sMinPrice * 1 - selectedPdtInfo.sMinPrice * (selectedPdtInfo.dcForStd / 100)) }}<span>원~</span>
                                    </b>
                                  </div>
                                  <div class="my-1">
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label class="memLabel-P" v-else>프리미엄</label>
                                    <b class="mx-2 ndayPrice" v-if="getCurrencyFormat(selectedPdtInfo.sMinPrice * 1 - selectedPdtInfo.sMinPrice * (selectedPdtInfo.maxDC_P / 100)) == 0"> 0원! <span>(연간무제한)</span>
                                    </b>
                                    <b class="mx-2 ndayPrice" v-else> 
                                      {{ getCurrencyFormat(selectedPdtInfo.sMinPrice * 1 - selectedPdtInfo.sMinPrice * (selectedPdtInfo.maxDC_P / 100)) }}<span>원~</span>
                                    </b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    <td style="vertical-align: top; height: 480px; margin: 0px; padding-top: 0px;" class="col-6 saleitemTable-1">
                      <div class="saleList">
                        <div class="product-list">
                          <transition :name="animationDirection">
                            <div v-if="this.pagedProductList.mid.length > 0" key="product-list">
                              <div class="row" :key="i" v-for="(pdt, i) in this.pagedProductList.mid">
                                <div class="col-lg-3" style="padding-bottom: 5px">
                                  <img
                                    v-if="pdt.imgName != 'noImage'"
                                    :src="`/download/${pdt.pdtCode}/${pdt.imgName}`"
                                    class="img-fluid hover-zoom pdt-img"
                                    style="width: 195px; height: 120px; border-radius: 5%;"
                                    alt="..."
                                    @mouseover="setHoveredProduct(pdt.pdtCode, pdt.pdtNameKor, pdt.arName, pdt.imgName, pdt.badgeFlag, pdt.badge, pdt.badge2Flag, pdt.badge2)"
                                  />
                                  <img
                                    v-else
                                    :src="`/download/noImage/noImage.jpg`"
                                    class="img-fluid pdt-img"
                                    style="width: 195px; height: 120px; border-radius: 5%;"
                                    alt="no image"
                                  />
                                </div>
                                <div class="c-content col-lg-9 text-start align-self-center" style="padding-top: 0px; padding-left: 10px; cursor: pointer" @click="goToDetail(pdt.pdtCode, pdt.salesRoute)">
                                  <div style="padding-top: 0px; margin-top: 0px">
                                    <h5 class="product-title" style="margin-top: 0; padding-top: 0px">{{ pdt.pdtNameKor }}</h5>
                                    <p class="product-description">{{ pdt.pdtRemarks }}</p>  
                                  </div>
                                  <!-- <h5 class="product-title" style="margin-top: 0; padding-top: 0px">{{ pdt.pdtNameKor }}</h5>
                                  <p class="product-description">{{ pdt.pdtRemarks }}</p> -->
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <div>
                                <div class="col-lg-3"></div>
                              </div>
                            </div>
                          </transition>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="height: 100px" class="col-6 saleitemTable-2">
                      <div class="pagination text-end">
                        <button @click="changePage('prev')" :disabled="currentPage === 1">
                          <i class="bi bi-arrow-left-square-fill"></i>
                        </button>
                        <span>{{ currentPage }} / {{ totalPages.mid }}</span>
                        <button @click="changePage('next')" :disabled="currentPage === totalPages.mid">
                          <i class="bi bi-arrow-right-square-fill"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </section>

          <!-- 엔타비+버라이어티팩 상품리스트 -->
          <section class="mt-5 w-100 vrItem" id="vrItemSection">
            <div>
              <div class="justify-content-center">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="section-title">
                    <h2 class="section-title">엔타비 단독기획! 버라이티한 패키지 여행상품</h2>
                    <p class="section-description">
                      시즌별, 기간별 한정! 엔타비가 단독기획한 이벤트 테마여행
                    </p>
                  </div>
                  <div class="text-end">
                    <a href="/exhibitionEvent" ><i class="bi bi-luggage-fill"></i> 전체상품보기</a>
                  </div>
                </div>
              </div>

              <div class="justify-content-center">
                <div class="container scrolling-wrapper" ref="scrollingContent_VR">
                  <button @click="scrollLeft_btn('VR')" class="scroll-btn left">
                    <b><i class="bi bi-chevron-left" style="color: white; font-weight: 900; font-size: 2rem"></i></b>
                  </button>
                  <div class="row gx-2 mt-3 scrolling-content">
                    <div class="col-12 col-md-6 col-lg-4 my-2 vrItems" :key="i" v-for="(product, i) in this.productListForSales_ntabi">
                      <a class="card" href="javascript: void(0)" @click="goToDetail(product.pdtCode, product.salesRoute)" style="cursor: pointer">
                        <img
                          v-if="product.imgName != 'noImage'"
                          :src="`/download/${product.pdtCode}/${product.imgName}`"
                          class="card-img-top vrPdtImage"
                          alt="..."
                        />
                        <img
                          v-else
                          :src="`/download/noImage/noImage.jpg`"
                          class="card-img-top vrPdtImage"
                        />
                        <!-- 뱃지표시 -->
                        <div class="badge">
                          <div v-if="product.badgeFlag === 'Y'">
                            <div class="badge1" style="width: 140px">
                              <small class="badge1-text" style="font-size: 0.8rem">{{ product.badge }}</small>
                            </div>
                          </div>
                          <div v-if="product.badge2Flag === 'Y'">
                            <div v-if="product.badgeFlag != 'Y'" class="badge1" style="width: 140px">
                              <small class="badge1-text" style="font-size: 0.8rem" >{{ product.badge2 }}</small>
                            </div>
                            <div v-else class="badge2" style="margin-left: 10rem; width: 140px">
                              <small class="badge2-text" style="font-size: 0.8rem" >{{ product.badge2 }}</small>
                            </div>
                          </div>
                          <div v-if="product.badgeFlag != 'Y' && product.badge2Flag != 'Y'"></div>
                        </div>
                        <!-- 카드 제목 -->
                        <div class="card-body">
                          <h5 class="card-title">
                            <div class="b-height" v-if="product.cityName != undefined">
                              <small><small><small><small class="align-top" ></small></small></small></small>{{ product.pdtNameKor }}
                            </div>
                            <div v-else>
                              {{ product.pdtNameKor }}
                            </div>
                            <div class="mt-3">
                              <div v-if="this.user.memLevel === 'P'">
                                <div v-if="product.dispPrice === 'mem'">
                                  <div v-if="product.sMinPrice === product.memP">
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                    />
                                    <b class="mx-1"> 0원!<span style="font-size: small">(연간무제한)</span></b>
                                  </div>
                                  <div v-else>
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                    />
                                    <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}원~</b>
                                    <small style="font-size: 0.8rem" v-if="product.memP * 1 > 0">(결제 후,</small>
                                    <b class="mx-1" style="color: #ffce00; font-size: 0.9rem" v-if="product.memP * 1 > 0">{{ getCurrencyFormat(product.memP) }}</b>
                                    <small style="font-size: 0.8rem" v-if="product.memP * 1 > 0">원 환급)</small>
                                  </div>
                                </div>
                                <div v-else>
                                  <div v-if="product.promoStatus === 'Y'">
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                    />
                                    <b class="text-muted text-decoration-line-through mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                                    <small><i class="bi bi-caret-right-fill"></i></small>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                  <div v-else-if="product.promoStatus === 'N'">
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                    />
                                    <b class="mx-1"> {{getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                  <div v-else-if="product.promoStatus === 'E'">
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                    />
                                    <b class="mx-1"> {{getCurrencyFormat(product.pPrice)}}원~</b>
                                  </div>
                                </div>
                              </div>

                              <div v-else-if="this.user.memLevel === 'S'">
                                <div v-if="product.dispPrice === 'mem'">
                                  <div v-if="product.dcForStd > 0">
                                    <img
                                      src="../assets/img/sBus.png"
                                      alt=""
                                      class="mbus-icon"
                                    />
                                    <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice - product.sMinPrice * (product.dcForStd / 100)) }}</b>
                                  </div>
                                  <div v-else>
                                    <div v-if="product.sMinPrice === product.memS">
                                      <img
                                        src="../assets/img/sBus.png"
                                        alt=""
                                        class="mbus-icon"
                                      />
                                      <b class="mx-1"> 0원!<span style="font-size: small">(연간무제한)</span></b>
                                    </div>
                                    <div v-else>
                                      <img
                                        src="../assets/img/sBus.png"
                                        alt=""
                                        class="mbus-icon"
                                      />
                                      <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}원~</b>
                                      <small style="font-size: 0.8rem" v-if="product.memS * 1 > 0">(결제 후,</small>
                                      <b class="mx-1" style="color: slategrey; font-size: 0.9rem" v-if="product.memS * 1 > 0">{{ getCurrencyFormat(product.memS) }}</b>
                                      <small style="font-size: 0.8rem" v-if="product.memS * 1 > 0">원 환급)</small>
                                    </div>
                                  </div>
                                  <div>
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <b class="mx-1" v-if="product.sMinPrice === product.memP"> 0원!<span style="font-size: small">(연간무제한)</span></b>
                                    <b class="mx-1" v-else > {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100)) }}원~</b>
                                  </div>
                                </div>

                                <div v-else>
                                  <div v-if="product.promoStatus === 'Y'">
                                    <b class="text-muted text-decoration-line-through mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                                    <small><i class="bi bi-caret-right-fill"></i></small>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                  <div v-else-if="product.promoStatus === 'N'">
                                    <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                  <div v-else-if="product.promoStatus === 'E'">
                                    <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                </div>
                              </div>
                              <!-- 로그인전 상품도 멤버십표시 -->
                              <div v-else>
                                <!-- 일반회원가격(default) -->
                                <div class="my-1" v-if="product.promoStatus === 'Y'">
                                  <img
                                    src="../assets/img/gBus.png"
                                    alt=""
                                    class="mbus-icon"
                                    v-if="user.email != undefined"
                                  />
                                  <label style="background-color: darkgray;padding: 3px 5px;" class="memLabel" v-else>일반회원</label>
                                  <b class="text-muted text-decoration-line-through mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                                  <small><i class="bi bi-caret-right-fill"></i></small>
                                  <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                </div>
                                <div class="my-1" v-else-if="product.promoStatus === 'E'">
                                  <div>
                                    <img
                                      src="../assets/img/gBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label style="background-color: darkgray;padding: 3px 5px;" class="memLabel" v-else>일반회원</label>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                </div>
                                <div class="my-1" v-else-if="product.promoStatus === 'N'">
                                  <!-- 이외상품 스탠다드 할인율이 있는 케이스 -->
                                  <div class="my-1" v-if="product.dcForMem_S > 0">
                                    <img
                                      src="../assets/img/sBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                    <b class="mx-1"> {{getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                  <div class="my-1" v-else>
                                    <img
                                      src="../assets/img/gBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label style="background-color: darkgray;padding: 3px 5px;" class="memLabel" v-else>일반회원</label>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                </div>

                                <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || product.nrFlag == 'Y' || product.vrFlag == 'Y'">
                                  <div class="my-1" v-if="product.promoStatus === 'E'">
                                    <div class="my-1">
                                      <div>
                                        <img
                                          src="../assets/img/sBus.png"
                                          alt=""
                                          class="mbus-icon"
                                          v-if="user.email != undefined"
                                        />
                                        <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                        <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_S) }}원~</b>
                                      </div>
                                      <div class="my-1">
                                        <img
                                          src="../assets/img/pBus.png"
                                          alt=""
                                          class="mbus-icon"
                                          v-if="user.email != undefined"
                                        />
                                        <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else>프리미엄</label>
                                        <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_P) }}원~</b>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="my-1" v-else-if="product.promoStatus === 'N'">
                                    <div>
                                      <div class="my-1">
                                        <img
                                          src="../assets/img/sBus.png"
                                          alt=""
                                          class="mbus-icon"
                                          v-if="user.email != undefined"
                                        />
                                        <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                        <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_S) }}원~</b>
                                      </div>
                                      <div class="my-1">
                                        <img
                                          src="../assets/img/pBus.png"
                                          alt=""
                                          class="mbus-icon"
                                          v-if="user.email != undefined"
                                        />
                                        <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else>프리미엄</label>
                                        <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_P) }}원~</b>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <!-- 스탠다드멤버십 상품 -->
                                <div class="my-1" v-if="product.memLevel_S === 'Y' && (product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null)">
                                  <img
                                    src="../assets/img/sBus.png"
                                    alt=""
                                    class="mbus-icon"
                                    v-if="user.email != undefined"
                                  />
                                  <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                  <b class="mx-1" v-if="product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_S / 100) == 0"> 0원!<b style="font-size: small">(연간무제한)</b></b>
                                  <b class="mx-1" v-else> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_S / 100)) }}원~</b>
                                </div>

                                <!-- 프리미엄멤버십 상품 -->
                                <div class="my-1" v-if="product.memLevel_P === 'Y' && (product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null)">
                                  <div class="my-1" v-if="(product.memLevel_s == 'N' || product.memLevel_s == null) && product.dcForStd > 0">
                                    <img
                                      src="../assets/img/sBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.dcForStd / 100)) }}원~</b>
                                  </div>
                                  <img
                                    src="../assets/img/pBus.png"
                                    alt=""
                                    class="mbus-icon"
                                    v-if="user.email != undefined"
                                  />
                                  <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else>프리미엄</label>
                                  <b class="mx-1" v-if="product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100) == 0"> 0원!<span style="font-size: small">(연간무제한)</span></b>
                                  <b class="mx-1" v-else> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100)) }}원~</b>
                                </div>
                              </div>
                            </div>
                          </h5>
                        </div>
                      </a>
                    </div>
                  </div>

                  <button @click="scrollRight_btn('VR')" class="scroll-btn right">
                    <b><i class="bi bi-chevron-right" style="color: white; font-weight: 900; font-size: 2rem" ></i></b>
                  </button>
                </div>
              </div>
            </div>
          </section>

          <!-- 일본선박 상품리스트 -->
          <section class="mt-5 w-100 shItem" id="shItemSection">
            <div>
              <div class="justify-content-center">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="section-title">
                    <h2 class="section-title">부산출발 선박특가 모음</h2>
                    <p class="section-description">
                      시모노세키 부관훼리 왕복, 후쿠오카 뉴카멜리아 왕복, 엔타비에서 가장 저렴하게!
                    </p>
                  </div>
                  <div class="text-end">
                    <a href="/shipTour" ><i class="bi bi-luggage-fill"></i> 전체상품보기</a>
                  </div>
                </div>
              </div>

              <div class="justify-content-center">
                <div class="container scrolling-wrapper" ref="scrollingContent_SH">
                  <button @click="scrollLeft_btn('SH')" class="scroll-btn left">
                    <b><i class="bi bi-chevron-left" style="color: white; font-weight: 900; font-size: 2rem"></i></b>
                  </button>
                  <div class="row gx-2 mt-3 scrolling-content">
                    <div class="col-12 col-md-6 col-lg-4 my-2 shItems" :key="i" v-for="(product, i) in this.productListForSales_ship">
                      <a class="card" href="javascript: void(0)" @click="goToDetail(product.pdtCode, product.salesRoute)" style="cursor: pointer">
                        <img
                          v-if="product.imgName != 'noImage'"
                          :src="`/download/${product.pdtCode}/${product.imgName}`"
                          class="card-img-top shPdtImage"
                          alt="..."
                        />
                        <img
                          v-else
                          :src="`/download/noImage/noImage.jpg`"
                          class="card-img-top shPdtImage"
                        />
                        <!-- 뱃지표시 -->
                        <div class="badge">
                          <div v-if="product.badgeFlag === 'Y'">
                            <div class="badge1" style="width: 140px">
                              <small class="badge1-text" style="font-size: 0.8rem">{{ product.badge }}</small>
                            </div>
                          </div>
                          <div v-if="product.badge2Flag === 'Y'">
                            <div v-if="product.badgeFlag != 'Y'" class="badge1" style="width: 140px">
                              <small class="badge1-text" style="font-size: 0.8rem" >{{ product.badge2 }}</small>
                            </div>
                            <div v-else class="badge2" style="margin-left: 10rem; width: 140px">
                              <small class="badge2-text" style="font-size: 0.8rem" >{{ product.badge2 }}</small>
                            </div>
                          </div>
                          <div v-if="product.badgeFlag != 'Y' && product.badge2Flag != 'Y'"></div>
                        </div>
                        <!-- 카드 제목 -->
                        <div class="card-body">
                          <h5 class="card-title">
                            <div class="b-height" v-if="product.cityName != undefined">
                              <small><small><small><small class="align-top" ></small></small></small></small>{{ product.pdtNameKor }}
                            </div>
                            <div v-else>
                              {{ product.pdtNameKor }}
                            </div>
                            <div class="mt-3">
                              <div v-if="this.user.memLevel === 'P'">
                                <div v-if="product.dispPrice === 'mem'">
                                  <div v-if="product.sMinPrice === product.memP">
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                    />
                                    <b class="mx-1"> 0원!<span style="font-size: small">(연간무제한)</span></b>
                                  </div>
                                  <div v-else>
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                    />
                                    <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}원~</b>
                                    <small style="font-size: 0.8rem" v-if="product.memP * 1 > 0">(결제 후,</small>
                                    <b class="mx-1" style="color: #ffce00; font-size: 0.9rem" v-if="product.memP * 1 > 0">{{ getCurrencyFormat(product.memP) }}</b>
                                    <small style="font-size: 0.8rem" v-if="product.memP * 1 > 0">원 환급)</small>
                                  </div>
                                </div>
                                <div v-else>
                                  <div v-if="product.promoStatus === 'Y'">
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                    />
                                    <b class="text-muted text-decoration-line-through mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                                    <small><i class="bi bi-caret-right-fill"></i></small>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                  <div v-else-if="product.promoStatus === 'N'">
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                    />
                                    <b class="mx-1"> {{getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                  <div v-else-if="product.promoStatus === 'E'">
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                    />
                                    <b class="mx-1"> {{getCurrencyFormat(product.pPrice)}}원~</b>
                                  </div>
                                </div>
                              </div>

                              <div v-else-if="this.user.memLevel === 'S'">
                                <div v-if="product.dispPrice === 'mem'">
                                  <div v-if="product.dcForStd > 0">
                                    <img
                                      src="../assets/img/sBus.png"
                                      alt=""
                                      class="mbus-icon"
                                    />
                                    <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice - product.sMinPrice * (product.dcForStd / 100)) }}</b>
                                  </div>
                                  <div v-else>
                                    <div v-if="product.sMinPrice === product.memS">
                                      <img
                                        src="../assets/img/sBus.png"
                                        alt=""
                                        class="mbus-icon"
                                      />
                                      <b class="mx-1"> 0원!<span style="font-size: small">(연간무제한)</span></b>
                                    </div>
                                    <div v-else>
                                      <img
                                        src="../assets/img/sBus.png"
                                        alt=""
                                        class="mbus-icon"
                                      />
                                      <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}원~</b>
                                      <small style="font-size: 0.8rem" v-if="product.memS * 1 > 0">(결제 후,</small>
                                      <b class="mx-1" style="color: slategrey; font-size: 0.9rem" v-if="product.memS * 1 > 0">{{ getCurrencyFormat(product.memS) }}</b>
                                      <small style="font-size: 0.8rem" v-if="product.memS * 1 > 0">원 환급)</small>
                                    </div>
                                  </div>
                                  <div>
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <b class="mx-1" v-if="product.sMinPrice === product.memP"> 0원!<span style="font-size: small">(연간무제한)</span></b>
                                    <b class="mx-1" v-else > {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100)) }}원~</b>
                                  </div>
                                </div>

                                <div v-else>
                                  <div v-if="product.promoStatus === 'Y'">
                                    <b class="text-muted text-decoration-line-through mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                                    <small><i class="bi bi-caret-right-fill"></i></small>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                  <div v-else-if="product.promoStatus === 'N'">
                                    <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                  <div v-else-if="product.promoStatus === 'E'">
                                    <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                </div>
                              </div>
                              <!-- 로그인전 상품도 멤버십표시 -->
                              <div v-else>
                                <!-- 일반회원가격(default) -->
                                <div class="my-1" v-if="product.promoStatus === 'Y'">
                                  <img
                                    src="../assets/img/gBus.png"
                                    alt=""
                                    class="mbus-icon"
                                    v-if="user.email != undefined"
                                  />
                                  <label style="background-color: darkgray;padding: 3px 5px;" class="memLabel" v-else>일반회원</label>
                                  <b class="text-muted text-decoration-line-through mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                                  <small><i class="bi bi-caret-right-fill"></i></small>
                                  <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                </div>
                                <div class="my-1" v-else-if="product.promoStatus === 'E'">
                                  <div>
                                    <img
                                      src="../assets/img/gBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label style="background-color: darkgray;padding: 3px 5px;" class="memLabel" v-else>일반회원</label>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                </div>
                                <div class="my-1" v-else-if="product.promoStatus === 'N'">
                                  <!-- 이외상품 스탠다드 할인율이 있는 케이스 -->
                                  <div class="my-1" v-if="product.dcForMem_S > 0">
                                    <img
                                      src="../assets/img/sBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                    <b class="mx-1"> {{getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                  <div class="my-1" v-else>
                                    <img
                                      src="../assets/img/gBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label style="background-color: darkgray;padding: 3px 5px;" class="memLabel" v-else>일반회원</label>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                                  </div>
                                </div>

                                <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || product.nrFlag == 'Y' || product.vrFlag == 'Y'">
                                  <div class="my-1" v-if="product.promoStatus === 'E'">
                                    <div class="my-1">
                                      <div>
                                        <img
                                          src="../assets/img/sBus.png"
                                          alt=""
                                          class="mbus-icon"
                                          v-if="user.email != undefined"
                                        />
                                        <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                        <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_S) }}원~</b>
                                      </div>
                                      <div class="my-1">
                                        <img
                                          src="../assets/img/pBus.png"
                                          alt=""
                                          class="mbus-icon"
                                          v-if="user.email != undefined"
                                        />
                                        <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else>프리미엄</label>
                                        <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_P) }}원~</b>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="my-1" v-else-if="product.promoStatus === 'N'">
                                    <div>
                                      <div class="my-1">
                                        <img
                                          src="../assets/img/sBus.png"
                                          alt=""
                                          class="mbus-icon"
                                          v-if="user.email != undefined"
                                        />
                                        <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                        <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_S) }}원~</b>
                                      </div>
                                      <div class="my-1">
                                        <img
                                          src="../assets/img/pBus.png"
                                          alt=""
                                          class="mbus-icon"
                                          v-if="user.email != undefined"
                                        />
                                        <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else>프리미엄</label>
                                        <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_P) }}원~</b>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <!-- 스탠다드멤버십 상품 -->
                                <div class="my-1" v-if="product.memLevel_S === 'Y' && (product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null)">
                                  <img
                                    src="../assets/img/sBus.png"
                                    alt=""
                                    class="mbus-icon"
                                    v-if="user.email != undefined"
                                  />
                                  <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                  <b class="mx-1" v-if="product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_S / 100) == 0"> 0원!<b style="font-size: small">(연간무제한)</b></b>
                                  <b class="mx-1" v-else> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_S / 100)) }}원~</b>
                                </div>

                                <!-- 프리미엄멤버십 상품 -->
                                <div class="my-1" v-if="product.memLevel_P === 'Y' && (product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null)">
                                  <div class="my-1" v-if="(product.memLevel_s == 'N' || product.memLevel_s == null) && product.dcForStd > 0">
                                    <img
                                      src="../assets/img/sBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.dcForStd / 100)) }}원~</b>
                                  </div>
                                  <img
                                    src="../assets/img/pBus.png"
                                    alt=""
                                    class="mbus-icon"
                                    v-if="user.email != undefined"
                                  />
                                  <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else>프리미엄</label>
                                  <b class="mx-1" v-if="product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100) == 0"> 0원!<span style="font-size: small">(연간무제한)</span></b>
                                  <b class="mx-1" v-else> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100)) }}원~</b>
                                </div>
                              </div>
                            </div>
                          </h5>
                        </div>
                      </a>
                    </div>
                  </div>

                  <button @click="scrollRight_btn('SH')" class="scroll-btn right">
                    <b><i class="bi bi-chevron-right" style="color: white; font-weight: 900; font-size: 2rem" ></i></b>
                  </button>
                </div>
              </div>
            </div>
          </section>


          <!-- Video Banner Section -->
          <section class="video-section w-100">
            <div class="video" id="ntabi-video" v-if="this.vBannerIdx == 0">
              <video muted autoplay loop>
                <source src="../assets/img/main/banner/mem_resize.mp4" type="video/mp4" />
              </video>
            </div>
            <div class="video" id="ntabi-video" v-if="this.vBannerIdx == 1">
              <video muted autoplay loop>
                <source src="../assets/img/main/banner/ndtAdLow.mp4" type="video/mp4" />
              </video>
            </div>
          </section>


          <!-- 그룹핑상품판매 -->
            <section id="groupProducts" class="w-100 mt-3" style="background-color: rgba(179, 218, 238, 0.095)">
              <div class="image-rotator">
                <transition-group name="blur-transition" tag="div" class="image-container">
                  <img
                    v-for="(image, index) in grSlides"
                    :key="index"
                    :src="image.image"
                    :class="['group-image', { active: activeIndex === index }]"
                    @mouseover="handleMouseOver(index)"
                    @mouseleave="handleMouseLeave"
                  />
                </transition-group>
              </div>
              <div class="progress-indicator mt-0">
                <div v-for="(_, index) in grSlides" :key="index" :class="['progress-dot', { active: activeIndex === index }]"></div>
              </div>
            </section>

            <section id="grProductList" class="w-100 mt-4 grProductList mb-5" style="background-color: rgba(179, 218, 238, 0.095); height:480px">
              <div class="row grProductLsitContainer" v-if="this.currentIndex === 0">
                <div class="grProductCard col-3" :key="i" v-for="(product, i) in this.productListForSales_gr">
                  <div class="card" style="height: 450px;">
                    <img v-if="product.imgName != 'noImage'" :src="`/download/${product.pdtCode}/${product.imgName}`" class="card-img-top" alt="..." style="height: 200px"/>
                    <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>

                    <!-- 카드 제목 -->
                    <div class="card-body mt-1 grCardBody" style="height: 250px;">
                      <a class="text-decoration-none link-dark stretched-link" href="javascript: void(0)" @click="goToDetail(product.pdtCode, product.salesRoute)" style="cursor: pointer">
                        <h5 class="card-title">
                          <div v-if="product.cityName != undefined">
                            <span class="align-top grProductTitle">{{ product.pdtNameKor }}</span>
                          </div>
                          <div v-else>
                            <span class="align-top grProductTitle">{{ product.pdtNameKor }}</span>
                          </div>
                        </h5>
                      </a>
                      <!-- 상품카드내용 -->
                      <div class="card-text">
                        <span class="grProductRemarks">{{ product.pdtRemarks }}</span>
                      </div>
                      <!-- 상품카드 금액 -->
                      <div class="card-price">
                        <div v-if="this.user.memLevel === 'P'">
                          <div v-if="product.dispPrice === 'mem'">
                            <div v-if="product.sMinPrice === product.memP">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> 0원! (연간무제한)</b>
                            </div>
                            <div v-else>
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}원~</b>
                              <small style="font-size: 0.8rem" v-if="product.memP * 1 > 0">(</small>
                              <b class="mx-1" style="color: #ffce00; font-size: 0.9rem" v-if="product.memP * 1 > 0"> {{ getCurrencyFormat(product.memP) }}</b>
                              <small style="font-size: 0.8rem" v-if="product.memP * 1 > 0">원 환급)</small>
                            </div>
                          </div>
                          <div v-else>
                            <div v-if="product.promoStatus === 'Y'">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="text-muted text-decoration-line-through mx-1 price-m"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                              <small><i class="bi bi-caret-right-fill"></i></small> 
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else-if="product.promoStatus === 'N'">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else-if="product.promoStatus === 'E'">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>https://ap.wifidosirak.com/ApIntro.aspx?ntabig
                            </div>
                          </div>
                        </div>

                        <div v-else-if="this.user.memLevel === 'S'">
                          <div v-if="product.dispPrice === 'mem'">
                            <div v-if="product.dcForStd > 0">
                              <img
                                src="../assets/img/sBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice - product.sMinPrice * (product.dcForStd / 100)) }}원~</b>
                            </div>
                            <div v-else>
                              <div v-if="product.sMinPrice === product.memS">
                                <img
                                  src="../assets/img/sBus.png"
                                  alt=""
                                  class="mbus-icon-m"
                                />
                                <b class="mx-1"> 0원! (연간무제한)</b>
                              </div>
                              <div v-else>
                                <img
                                  src="../assets/img/sBus.png"
                                  alt=""
                                  class="mbus-icon-m"
                                />
                                <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}원~</b>
                                <small style="font-size: 0.8rem" v-if="product.memS * 1 > 0">(</small>
                                <b class="mx-1" style="color: slategrey; font-size: 0.9rem" v-if="product.memS * 1 > 0"> {{ getCurrencyFormat(product.memS) }}원~</b>
                                <small style="font-size: 0.8rem" v-if="product.memS * 1 > 0">원 환급)</small>
                              </div>
                            </div>
                            <div>
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <b class="mx-1" v-if="product.sMinPrice === product.memP"> 0원! (연간무제한)</b>
                              <b class="mx-1" v-else > {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100)) }}원~</b>
                            </div>
                          </div>

                          <div v-else>
                            <div v-if="product.promoStatus === 'Y'">
                              <b class="text-muted text-decoration-line-through mx-1" >{{ getCurrencyFormat(product.sMinPrice) }}</b> 
                              <small><i class="bi bi-caret-right-fill"></i></small> 
                              <b class="mx-1" > {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else-if="product.promoStatus === 'N'">
                              <b class="mx-1" > {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else-if="product.promoStatus === 'E'">
                              <b class="mx-1" > {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                          </div>
                        </div>

                        <!-- 비로그인, 일반회원의 멤버십표시 -->
                        <div v-else>
                          <!-- 일반회원가격(default) -->
                          <div class="my-1" v-if="product.promoStatus === 'Y' || product.dispPrice == 'promo'">
                            <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                            <label style="background-color: darkgray; padding: 3px 5px" class="memLabel" v-else >일반회원</label>
                            <b class="text-muted text-decoration-line-through mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}원~</b> 
                            <small><i class="bi bi-caret-right-fill"></i></small> 
                            <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'E'">
                            <div>
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                              <label style="background-color: darkgray; padding: 3px 5px;" class="memLabel" v-else >일반회원</label>
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'N'">
                            <div v-if="product.dcForMem_S > 0">
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                              <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else class="my-1">
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                              <label style="background-color: darkgray; padding: 3px 5px;" class="memLabel" v-else >일반회원</label>
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                          </div>

                          <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || product.nrFlag == 'Y' || product.vrFlag == 'Y'">
                            <div class="my-1" v-if="product.promoStatus === 'E'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                                  <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                                  <b class="mx-1"> {{ getCurrencyFormat( product.sMinPrice * 1 - product.price_dcForMem_S ) }}원~</b>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                                  <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else >프리미엄</label>
                                  <b class="mx-1"> {{ getCurrencyFormat( product.sMinPrice * 1 - product.price_dcForMem_P ) }}원~</b>
                                </div>
                              </div>
                            </div>
                            <div class="my-1" v-else-if="product.promoStatus === 'N'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                                  <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                                  <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_S) }}원~</b>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                                  <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else >프리미엄</label>
                                  <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_P)}}원~</b>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- 스탠다드멤버십 상품 -->
                          <div class="my-1" v-if="product.memLevel_S === 'Y' && (product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null)">
                            <img
                              src="../assets/img/sBus.png"
                              alt=""
                              class="mbus-icon-m"
                              v-if="user.email != undefined"
                            />
                            <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                            <b class="mx-1" v-if="product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_S / 100) == 0"> 0원! (연간무제한)</b>
                            <b class="mx-1" v-else> {{ getCurrencyFormat((product.sMinPrice * 1) - product.sMinPrice * (product.maxDC_S / 100)) }}원~</b>
                          </div>

                          <!-- 프리미엄멤버십 상품 -->
                          <div class="my-1" v-if="product.memLevel_P === 'Y' && (product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null)">
                            <div class="my-1" v-if="(product.memLevel_s == 'N' || product.memLevel_s == null) && product.dcForStd > 0">
                              <img
                                src="../assets/img/sBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                              <b class="mx-1"> {{ getCurrencyFormat((product.sMinPrice * 1) - product.sMinPrice * (product.dcForStd / 100)) }}원~</b>
                            </div>
                            <img
                              src="../assets/img/pBus.png"
                              alt=""
                              class="mbus-icon-m"
                              v-if="user.email != undefined"
                            />
                            <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else >프리미엄</label>
                            <b class="mx-1" v-if=" product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100) == 0"> 0원! (연간무제한)</b>
                            <b class="mx-1" v-else> {{ getCurrencyFormat((product.sMinPrice * 1) - product.sMinPrice * (product.maxDC_P / 100)) }}원~</b>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row grProductLsitContainer" v-else-if="this.currentIndex === 1">
                <div class="grProductCard col-3" :key="i" v-for="(product, i) in this.productListForSales_gr2">
                  <div class="card" style="height: 450px;">
                    <img v-if="product.imgName != 'noImage'" :src="`/download/${product.pdtCode}/${product.imgName}`" class="card-img-top" alt="..." style="height: 200px"/>
                    <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>

                    <!-- 상품카드 제목 -->
                    <div class="card-body mt-1 grCardBody" style="height: 250px;">
                      <a class="text-decoration-none link-dark stretched-link" href="javascript: void(0)" @click="goToDetail(product.pdtCode, product.salesRoute)" style="cursor: pointer">
                        <h5 class="card-title">
                          <div v-if="product.cityName != undefined">
                            <span class="align-top grProductTitle">{{ product.pdtNameKor }}</span>
                          </div>
                          <div v-else>
                            <span class="align-top grProductTitle">{{ product.pdtNameKor }}</span>
                          </div>
                        </h5>
                      </a>
                      <!-- 상품카드내용 -->
                      <div class="card-text">
                        <span class="grProductRemarks">{{ product.pdtRemarks }}</span>
                      </div>
                      <!-- 상품카드 금액 -->
                      <div class="card-price">
                        <div v-if="this.user.memLevel === 'P'">
                          <div v-if="product.dispPrice === 'mem'">
                            <div v-if="product.sMinPrice === product.memP">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> 0원! (연간무제한)</b>
                            </div>
                            <div v-else>
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}원~</b>
                              <small style="font-size: 0.8rem" v-if="product.memP * 1 > 0">(</small>
                              <b class="mx-1" style="color: #ffce00; font-size: 0.9rem" v-if="product.memP * 1 > 0"> {{ getCurrencyFormat(product.memP) }}</b>
                              <small style="font-size: 0.8rem" v-if="product.memP * 1 > 0">원 환급)</small>
                            </div>
                          </div>
                          <div v-else>
                            <div v-if="product.promoStatus === 'Y'">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="text-muted text-decoration-line-through mx-1 price-m"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                              <small><i class="bi bi-caret-right-fill"></i></small> 
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else-if="product.promoStatus === 'N'">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else-if="product.promoStatus === 'E'">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                          </div>
                        </div>

                        <div v-else-if="this.user.memLevel === 'S'">
                          <div v-if="product.dispPrice === 'mem'">
                            <div v-if="product.dcForStd > 0">
                              <img
                                src="../assets/img/sBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice - product.sMinPrice * (product.dcForStd / 100)) }}원~</b>
                            </div>
                            <div v-else>
                              <div v-if="product.sMinPrice === product.memS">
                                <img
                                  src="../assets/img/sBus.png"
                                  alt=""
                                  class="mbus-icon-m"
                                />
                                <b class="mx-1"> 0원! (연간무제한)</b>
                              </div>
                              <div v-else>
                                <img
                                  src="../assets/img/sBus.png"
                                  alt=""
                                  class="mbus-icon-m"
                                />
                                <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}원~</b>
                                <small style="font-size: 0.8rem" v-if="product.memS * 1 > 0">(</small>
                                <b class="mx-1" style="color: slategrey; font-size: 0.9rem" v-if="product.memS * 1 > 0"> {{ getCurrencyFormat(product.memS) }}원~</b>
                                <small style="font-size: 0.8rem" v-if="product.memS * 1 > 0">원 환급)</small>
                              </div>
                            </div>
                            <div>
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <b class="mx-1" v-if="product.sMinPrice === product.memP"> 0원! (연간무제한)</b>
                              <b class="mx-1" v-else > {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100)) }}원~</b>
                            </div>
                          </div>

                          <div v-else>
                            <div v-if="product.promoStatus === 'Y'">
                              <b class="text-muted text-decoration-line-through mx-1" >{{ getCurrencyFormat(product.sMinPrice) }}</b> 
                              <small><i class="bi bi-caret-right-fill"></i></small> 
                              <b class="mx-1" > {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else-if="product.promoStatus === 'N'">
                              <b class="mx-1" > {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else-if="product.promoStatus === 'E'">
                              <b class="mx-1" > {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                          </div>
                        </div>

                        <!-- 비로그인, 일반회원의 멤버십표시 -->
                        <div v-else>
                          <!-- 일반회원가격(default) -->
                          <div class="my-1" v-if="product.promoStatus === 'Y' || product.dispPrice == 'promo'">
                            <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                            <label style="background-color: darkgray; padding: 3px 5px" class="memLabel" v-else >일반회원</label>
                            <b class="text-muted text-decoration-line-through mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                            <small><i class="bi bi-caret-right-fill"></i></small> 
                            <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'E'">
                            <div>
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                              <label style="background-color: darkgray; padding: 3px 5px;" class="memLabel" v-else >일반회원</label>
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'N'">
                            <!-- 이외상품 스탠다드 할인이 있는 케이스 -->
                            <div v-if="product.dcForMem_S > 0">
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                              <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else class="my-1">
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                              <label style="background-color: darkgray; padding: 3px 5px;" class="memLabel" v-else >일반회원</label>
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                          </div>

                          <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || product.nrFlag == 'Y' || product.vrFlag == 'Y'">
                            <div class="my-1" v-if="product.promoStatus === 'E'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                                  <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                                  <b class="mx-1"> {{ getCurrencyFormat( product.sMinPrice * 1 - product.price_dcForMem_S ) }}원~</b>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                                  <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else >프리미엄</label>
                                  <b class="mx-1"> {{ getCurrencyFormat( product.sMinPrice * 1 - product.price_dcForMem_P ) }}원~</b>
                                </div>
                              </div>
                            </div>
                            <div class="my-1" v-else-if="product.promoStatus === 'N'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                                  <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                                  <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_S) }}원~</b>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                                  <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else >프리미엄</label>
                                  <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_P)}}원~</b>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- 스탠다드멤버십 상품 -->
                          <div class="my-1" v-if="product.memLevel_S === 'Y' && (product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null)">
                            <img
                              src="../assets/img/sBus.png"
                              alt=""
                              class="mbus-icon-m"
                              v-if="user.email != undefined"
                            />
                            <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                            <b class="mx-1" v-if="product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_S / 100) == 0"> 0원! (연간무제한)</b>
                            <b class="mx-1" v-else> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_S / 100)) }}원~</b>
                          </div>

                          <!-- 프리미엄멤버십 상품 -->
                          <div class="my-1" v-if="product.memLevel_P === 'Y' && (product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null)">
                            <div class="my-1" v-if="(product.memLevel_s == 'N' || product.memLevel_s == null) && product.dcForStd > 0">
                              <img
                                src="../assets/img/sBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                              <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.dcForStd / 100)) }}원~</b>
                            </div>
                            <img
                              src="../assets/img/pBus.png"
                              alt=""
                              class="mbus-icon-m"
                              v-if="user.email != undefined"
                            />
                            <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else >프리미엄</label>
                            <b class="mx-1" v-if=" product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100) == 0"> 0원! (연간무제한)</b>
                            <b class="mx-1" v-else> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100)) }}원~</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row grProductLsitContainer" v-if="this.currentIndex === 2">
                <div class="grProductCard col-3" :key="i" v-for="(product, i) in this.productListForSales_gr3">
                  <div class="card" style="height: 450px;">
                    <img v-if="product.imgName != 'noImage'" :src="`/download/${product.pdtCode}/${product.imgName}`" class="card-img-top" alt="..." style="height: 200px"/>
                    <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top"/>

                    <!-- 카드 제목 -->
                    <div class="card-body mt-1 grCardBody" style="height: 250px;">
                      <a class="text-decoration-none link-dark stretched-link" href="javascript: void(0)" @click="goToDetail(product.pdtCode, product.salesRoute)" style="cursor: pointer">
                        <h5 class="card-title">
                          <div v-if="product.cityName != undefined">
                            <span class="align-top grProductTitle">{{ product.pdtNameKor }}</span>
                          </div>
                          <div v-else>
                            <span class="align-top grProductTitle">{{ product.pdtNameKor }}</span>
                          </div>
                        </h5>
                      </a>
                      <!-- 카드내용 -->
                      <div class="card-text">
                        <span class="grProductRemarks">{{ product.pdtRemarks }}</span>
                      </div>
                      <!-- 카드 금액 -->
                      <div class="card-price">
                        <div v-if="this.user.memLevel === 'P'">
                          <div v-if="product.dispPrice === 'mem'">
                            <div v-if="product.sMinPrice === product.memP">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> 0원! (연간무제한)</b>
                            </div>
                            <div v-else>
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}원~</b>
                              <small style="font-size: 0.8rem" v-if="product.memP * 1 > 0">(</small>
                              <b class="mx-1" style="color: #ffce00; font-size: 0.9rem" v-if="product.memP * 1 > 0"> {{ getCurrencyFormat(product.memP) }}</b>
                              <small style="font-size: 0.8rem" v-if="product.memP * 1 > 0">원 환급)</small>
                            </div>
                          </div>
                          <div v-else>
                            <div v-if="product.promoStatus === 'Y'">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="text-muted text-decoration-line-through mx-1 price-m"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                              <small><i class="bi bi-caret-right-fill"></i></small> 
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else-if="product.promoStatus === 'N'">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else-if="product.promoStatus === 'E'">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                          </div>
                        </div>

                        <div v-else-if="this.user.memLevel === 'S'">
                          <div v-if="product.dispPrice === 'mem'">
                            <div v-if="product.dcForStd > 0">
                              <img
                                src="../assets/img/sBus.png"
                                alt=""
                                class="mbus-icon-m"
                              />
                              <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice - product.sMinPrice * (product.dcForStd / 100)) }}원~</b>
                            </div>
                            <div v-else>
                              <div v-if="product.sMinPrice === product.memS">
                                <img
                                  src="../assets/img/sBus.png"
                                  alt=""
                                  class="mbus-icon-m"
                                />
                                <b class="mx-1"> 0원! (연간무제한)</b>
                              </div>
                              <div v-else>
                                <img
                                  src="../assets/img/sBus.png"
                                  alt=""
                                  class="mbus-icon-m"
                                />
                                <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}원~</b>
                                <small style="font-size: 0.8rem" v-if="product.memS * 1 > 0">(</small>
                                <b class="mx-1" style="color: slategrey; font-size: 0.9rem" v-if="product.memS * 1 > 0"> {{ getCurrencyFormat(product.memS) }}원~</b>
                                <small style="font-size: 0.8rem" v-if="product.memS * 1 > 0">원 환급)</small>
                              </div>
                            </div>
                            <div>
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <b class="mx-1" v-if="product.sMinPrice === product.memP"> 0원! (연간무제한)</b>
                              <b class="mx-1" v-else > {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100)) }}원~</b>
                            </div>
                          </div>

                          <div v-else>
                            <div v-if="product.promoStatus === 'Y'">
                              <b class="text-muted text-decoration-line-through mx-1" >{{ getCurrencyFormat(product.sMinPrice) }}</b> 
                              <small><i class="bi bi-caret-right-fill"></i></small> 
                              <b class="mx-1" > {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else-if="product.promoStatus === 'N'">
                              <b class="mx-1" > {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else-if="product.promoStatus === 'E'">
                              <b class="mx-1" > {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                          </div>
                        </div>

                        <!-- 비로그인, 일반회원의 멤버십표시 -->
                        <div v-else>
                          <!-- 일반회원가격(default) -->
                          <div class="my-1" v-if="product.promoStatus === 'Y' || product.dispPrice == 'promo'">
                            <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                            <label style="background-color: darkgray; padding: 3px 5px" class="memLabel" v-else >일반회원</label>
                            <b class="text-muted text-decoration-line-through mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                            <small><i class="bi bi-caret-right-fill"></i></small> 
                            <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'E'">
                            <div>
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                              <label style="background-color: darkgray; padding: 3px 5px;" class="memLabel" v-else >일반회원</label>
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                          </div>
                          <div class="my-1" v-else-if="product.promoStatus === 'N'">
                            <div v-if="product.dcForMem_S > 0">
                              <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                              <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div v-else class="my-1">
                              <img src="../assets/img/gBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                              <label style="background-color: darkgray; padding: 3px 5px;" class="memLabel" v-else >일반회원</label>
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                          </div>

                          <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || product.nrFlag == 'Y' || product.vrFlag == 'Y'">
                            <div class="my-1" v-if="product.promoStatus === 'E'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                                  <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                                  <b class="mx-1"> {{ getCurrencyFormat( product.sMinPrice * 1 - product.price_dcForMem_S ) }}원~</b>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                                  <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else >프리미엄</label>
                                  <b class="mx-1"> {{ getCurrencyFormat( product.sMinPrice * 1 - product.price_dcForMem_P ) }}원~</b>
                                </div>
                              </div>
                            </div>
                            <div class="my-1" v-else-if="product.promoStatus === 'N'">
                              <div>
                                <div class="my-1">
                                  <img src="../assets/img/sBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                                  <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                                  <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_S) }}원~</b>
                                </div>
                                <div class="my-1">
                                  <img src="../assets/img/pBus.png" alt="" class="mbus-icon" v-if="user.email != undefined" />
                                  <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else >프리미엄</label>
                                  <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_P)}}원~</b>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- 스탠다드멤버십 상품 -->
                          <div class="my-1" v-if="product.memLevel_S === 'Y' && (product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null)">
                            <img
                              src="../assets/img/sBus.png"
                              alt=""
                              class="mbus-icon-m"
                              v-if="user.email != undefined"
                            />
                            <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                            <b class="mx-1" v-if="product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_S / 100) == 0"> 0원! (연간무제한)</b>
                            <b class="mx-1" v-else> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_S / 100)) }}원~</b>
                          </div>

                          <!-- 프리미엄멤버십 상품 -->
                          <div class="my-1" v-if="product.memLevel_P === 'Y' && (product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null)">
                            <div class="my-1" v-if="(product.memLevel_s == 'N' || product.memLevel_s == null) && product.dcForStd > 0">
                              <img
                                src="../assets/img/sBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel" v-else >스탠다드</label>
                              <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.dcForStd / 100)) }}원~</b>
                            </div>
                            <img
                              src="../assets/img/pBus.png"
                              alt=""
                              class="mbus-icon-m"
                              v-if="user.email != undefined"
                            />
                            <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else >프리미엄</label>
                            <b class="mx-1" v-if=" product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100) == 0"> 0원! (연간무제한)</b>
                            <b class="mx-1" v-else> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100)) }}원~</b>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </section>
          <!-- </section> -->


          <!-- 여행자보험 & 와이파이 배너 -->
          <section id="midBanner" class="midBanner w-100 mt-5" style="padding-top: 50px; padding-bottom: 50px">
            <div class="mid-banner1">
              <a href="https://travelmedic.co.kr/mypage/event_view.php?idx=44" target="_blank">
                <img src="../assets/img/main/banner4.jpg" class="img-fluid" />
              </a>
            </div>
            <div class="mid-banner2">
              <a
                href="https://ap.wifidosirak.com/ApIntro.aspx?ntabi"
                target="_blank"
              >
                <img src="../assets/img/main/banner5.jpg" class="img-fluid" />
              </a>
            </div>
          </section>

          <!-- ======= 베스트아이템 ======= -->
          <section id="best-item" class="bestitem w-100 mt-5">
            <div class="section-title">
              <p>
                <b class="text-danger" style="font-family: Impact Black, Verdana, Geneva, Tahoma,sans-serif;font-size: 2.5rem; letter-spacing: 0.2rem;">
                  <i>BEST <span class="text-primary">SELLER</span></i>
                </b>
              </p>
            </div>
            <div class="container scrolling-wrapper" ref="scrollingContent_best">
              <button @click="scrollLeft_btn('BE')" class="scroll-btn left">
                <b><i class="bi bi-chevron-left" style="color: white; font-weight: 900; font-size: 2rem"></i></b>
              </button>

              <div class="row gx-2 mt-3 scrolling-content">
                <div class="col-12 col-md-6 col-lg-4 my-2 bestItems" :key="i" v-for="(product, i) in this.productListForSales_best">
                  <a class="card" href="javascript: void(0)" @click="goToDetail(product.pdtCode, product.salesRoute)" style="cursor: pointer">
                    <img
                      v-if="product.imgName != 'noImage'"
                      :src="`/download/${product.pdtCode}/${product.imgName}`"
                      class="card-img-top bestPdtImage"
                      alt="..."
                    />
                    <img
                      v-else
                      :src="`/download/noImage/noImage.jpg`"
                      class="card-img-top bestPdtImage"
                    />
                    <!-- 뱃지표시 -->
                    <div class="badge">
                      <div v-if="product.badgeFlag === 'Y'">
                        <div class="badge1" style="width: 140px">
                          <small class="badge1-text" style="font-size: 0.8rem">{{ product.badge }}</small>
                        </div>
                      </div>
                      <div v-if="product.badge2Flag === 'Y'">
                        <div v-if="product.badgeFlag != 'Y'" class="badge1" style="width: 140px">
                          <small class="badge1-text" style="font-size: 0.8rem" >{{ product.badge2 }}</small>
                        </div>
                        <div v-else class="badge2" style="margin-left: 10rem; width: 140px">
                          <small class="badge2-text" style="font-size: 0.8rem" >{{ product.badge2 }}</small>
                        </div>
                      </div>
                      <div v-if="product.badgeFlag != 'Y' && product.badge2Flag != 'Y'"></div>
                    </div>
                    <!-- 카드 제목 -->
                    <div class="card-body">
                      <h5 class="card-title">
                        <div class="b-height" v-if="product.cityName != undefined">
                          <small><small><small><small class="align-top" ></small></small></small></small>{{ product.pdtNameKor }}
                        </div>
                        <div v-else>
                          {{ product.pdtNameKor }}
                        </div>
                        <div class="mt-3">
                          <div v-if="this.user.memLevel === 'P'">
                            <div v-if="product.dispPrice === 'mem'">
                              <div v-if="product.sMinPrice === product.memP">
                                <img
                                  src="../assets/img/pBus.png"
                                  alt=""
                                  class="mbus-icon"
                                />
                                <b class="mx-1"> 0원!<span style="font-size: small">(연간무제한)</span></b>
                              </div>
                              <div v-else>
                                <img
                                  src="../assets/img/pBus.png"
                                  alt=""
                                  class="mbus-icon"
                                />
                                <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}원~</b>
                                <small style="font-size: 0.8rem" v-if="product.memP * 1 > 0">(결제 후,</small>
                                <b class="mx-1" style="color: #ffce00; font-size: 0.9rem" v-if="product.memP * 1 > 0">{{ getCurrencyFormat(product.memP) }}</b>
                                <small style="font-size: 0.8rem" v-if="product.memP * 1 > 0">원 환급)</small>
                              </div>
                            </div>
                            <div v-else>
                              <div v-if="product.promoStatus === 'Y'">
                                <img
                                  src="../assets/img/pBus.png"
                                  alt=""
                                  class="mbus-icon"
                                />
                                <b class="text-muted text-decoration-line-through mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                                <small><i class="bi bi-caret-right-fill"></i></small>
                                <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                              </div>
                              <div v-else-if="product.promoStatus === 'N'">
                                <img
                                  src="../assets/img/pBus.png"
                                  alt=""
                                  class="mbus-icon"
                                />
                                <b class="mx-1"> {{getCurrencyFormat(product.pPrice) }}원~</b>
                              </div>
                              <div v-else-if="product.promoStatus === 'E'">
                                <img
                                  src="../assets/img/pBus.png"
                                  alt=""
                                  class="mbus-icon"
                                />
                                <b class="mx-1"> {{getCurrencyFormat(product.pPrice)}}원~</b>
                              </div>
                            </div>
                          </div>

                          <div v-else-if="this.user.memLevel === 'S'">
                            <div v-if="product.dispPrice === 'mem'">
                              <div v-if="product.dcForStd > 0">
                                <img
                                  src="../assets/img/sBus.png"
                                  alt=""
                                  class="mbus-icon"
                                />
                                <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice - product.sMinPrice * (product.dcForStd / 100)) }}</b>
                              </div>
                              <div v-else>
                                <div v-if="product.sMinPrice === product.memS">
                                  <img
                                    src="../assets/img/sBus.png"
                                    alt=""
                                    class="mbus-icon"
                                  />
                                  <b class="mx-1"> 0원!<span style="font-size: small">(연간무제한)</span></b>
                                </div>
                                <div v-else>
                                  <img
                                    src="../assets/img/sBus.png"
                                    alt=""
                                    class="mbus-icon"
                                  />
                                  <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}원~</b>
                                  <small style="font-size: 0.8rem" v-if="product.memS * 1 > 0">(결제 후,</small>
                                  <b class="mx-1" style="color: slategrey; font-size: 0.9rem" v-if="product.memS * 1 > 0">{{ getCurrencyFormat(product.memS) }}</b>
                                  <small style="font-size: 0.8rem" v-if="product.memS * 1 > 0">원 환급)</small>
                                </div>
                              </div>
                              <div>
                                <img
                                  src="../assets/img/pBus.png"
                                  alt=""
                                  class="mbus-icon"
                                  v-if="user.email != undefined"
                                />
                                <b class="mx-1" v-if="product.sMinPrice === product.memP"> 0원!<span style="font-size: small">(연간무제한)</span></b>
                                <b class="mx-1" v-else > {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100)) }}원~</b>
                              </div>
                            </div>

                            <div v-else>
                              <div v-if="product.promoStatus === 'Y'">
                                <b class="text-muted text-decoration-line-through mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                                <small><i class="bi bi-caret-right-fill"></i></small>
                                <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                              </div>
                              <div v-else-if="product.promoStatus === 'N'">
                                <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                              </div>
                              <div v-else-if="product.promoStatus === 'E'">
                                <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                              </div>
                            </div>
                          </div>
                          <!-- 로그인전 상품도 멤버십표시 -->
                          <div v-else>
                            <!-- 일반회원가격(default) -->
                            <div class="my-1" v-if="product.promoStatus === 'Y'">
                              <img
                                src="../assets/img/gBus.png"
                                alt=""
                                class="mbus-icon"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: darkgray;padding: 3px 5px;" class="memLabel" v-else>일반회원</label>
                              <b class="text-muted text-decoration-line-through mx-1"> {{ getCurrencyFormat(product.sMinPrice) }}</b> 
                              <small><i class="bi bi-caret-right-fill"></i></small>
                              <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                            </div>
                            <div class="my-1" v-else-if="product.promoStatus === 'E'">
                              <div>
                                <img
                                  src="../assets/img/gBus.png"
                                  alt=""
                                  class="mbus-icon"
                                  v-if="user.email != undefined"
                                />
                                <label style="background-color: darkgray;padding: 3px 5px;" class="memLabel" v-else>일반회원</label>
                                <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                              </div>
                            </div>
                            <div class="my-1" v-else-if="product.promoStatus === 'N'">
                              <!-- 이외상품 스탠다드 할인율이 있는 케이스 -->
                              <div class="my-1" v-if="product.dcForMem_S > 0">
                                <img
                                  src="../assets/img/sBus.png"
                                  alt=""
                                  class="mbus-icon"
                                  v-if="user.email != undefined"
                                />
                                <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                <b class="mx-1"> {{getCurrencyFormat(product.pPrice) }}원~</b>
                              </div>
                              <div class="my-1" v-else>
                                <img
                                  src="../assets/img/gBus.png"
                                  alt=""
                                  class="mbus-icon"
                                  v-if="user.email != undefined"
                                />
                                <label style="background-color: darkgray;padding: 3px 5px;" class="memLabel" v-else>일반회원</label>
                                <b class="mx-1"> {{ getCurrencyFormat(product.pPrice) }}원~</b>
                              </div>
                            </div>

                            <div v-if="((product.memLevel_S == 'N' || product.memLevel_S == null) && (product.memLevel_P == 'N' || product.memLevel_P == null)) || product.nrFlag == 'Y' || product.vrFlag == 'Y'">
                              <div class="my-1" v-if="product.promoStatus === 'E'">
                                <div class="my-1">
                                  <div>
                                    <img
                                      src="../assets/img/sBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_S) }}원~</b>
                                  </div>
                                  <div class="my-1">
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else>프리미엄</label>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_P) }}원~</b>
                                  </div>
                                </div>
                              </div>
                              <div class="my-1" v-else-if="product.promoStatus === 'N'">
                                <div>
                                  <div class="my-1">
                                    <img
                                      src="../assets/img/sBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_S) }}원~</b>
                                  </div>
                                  <div class="my-1">
                                    <img
                                      src="../assets/img/pBus.png"
                                      alt=""
                                      class="mbus-icon"
                                      v-if="user.email != undefined"
                                    />
                                    <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else>프리미엄</label>
                                    <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.price_dcForMem_P) }}원~</b>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- 스탠다드멤버십 상품 -->
                            <div class="my-1" v-if="product.memLevel_S === 'Y' && (product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null)">
                              <img
                                src="../assets/img/sBus.png"
                                alt=""
                                class="mbus-icon"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                              <b class="mx-1" v-if="product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_S / 100) == 0"> 0원!<b style="font-size: small">(연간무제한)</b></b>
                              <b class="mx-1" v-else> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_S / 100)) }}원~</b>
                            </div>

                            <!-- 프리미엄멤버십 상품 -->
                            <div class="my-1" v-if="product.memLevel_P === 'Y' && (product.nrFlag === 'N' || product.nrFlag == null) && (product.vrFlag === 'N' || product.vrFlag == null)">
                              <div class="my-1" v-if="(product.memLevel_s == 'N' || product.memLevel_s == null) && product.dcForStd > 0">
                                <img
                                  src="../assets/img/sBus.png"
                                  alt=""
                                  class="mbus-icon"
                                  v-if="user.email != undefined"
                                />
                                <label style="background-color: #0b7fd4;padding: 3px 5px;" class="memLabel" v-else>스탠다드</label>
                                <b class="mx-1"> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.dcForStd / 100)) }}원~</b>
                              </div>
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: #ffce00;padding: 3px 5px;" class="memLabel" v-else>프리미엄</label>
                              <b class="mx-1" v-if="product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100) == 0"> 0원!<span style="font-size: small">(연간무제한)</span></b>
                              <b class="mx-1" v-else> {{ getCurrencyFormat(product.sMinPrice * 1 - product.sMinPrice * (product.maxDC_P / 100)) }}원~</b>
                            </div>
                          </div>
                        </div>
                      </h5>
                    </div>
                  </a>
                </div>
              </div>

              <button @click="scrollRight_btn('BE')" class="scroll-btn right">
                <b><i class="bi bi-chevron-right" style="color: white; font-weight: 900; font-size: 2rem" ></i></b>
              </button>
            </div>
          </section>

          <!-- Live Section -->
          <section id="liveSection" class="live-section w-100 mt-5">
            <h3 class="text-start">
              <img src="../assets/img/main/live_logo.png" style="width: 12%" />
            </h3>
            <div class="section-title">
              <div class="mt-0 mb-0" style="font-size: 1.3rem; font-weight: bold; margin-top: 20px">
                지역전문 한국인 가이드가 진행하는 라이브 방송입니다.
              </div>
              <div id="more">
                <a href="https://smartstore.naver.com/ntabi/lives?cp=1" target="_blank" ><i class="bi bi-broadcast"></i> 전체보기</a>
              </div>
            </div>
            <div class="row">
              <div class="iframe-container">
                <iframe src="https://smartstore.naver.com/ntabi/lives?cp=1" frameborder="0" scrolling="no" ></iframe>
              </div>
            </div>
          </section>

          <!-- 우측고정공지가 없는 경우 -->
          <section id="notice" class="notice w-100 mb-5" style="padding-top: 4rem" v-if="!this.fixedPR[0] && !this.fixedPR[1]">
            <div class="row">
              <div class="section-title">
                <a href="/customerNotice">
                  <h3
                    class="text-start text-touch"
                    style="font-weight: 800; cursor: pointer"
                    @touchstart="handleTouchStart"
                    @touchend="handleTouchEnd"
                    :class="{ touchable: isTouched }"
                  >이벤트 <i class="bi bi-dot"></i> 홍보 & 공지사항</h3>
                </a>
                <div id="more"><a href="/customerNotice"><i class="bi bi-box-arrow-up-right"></i> 더보기</a></div>
              </div>
            </div>

            <div class="justify-content-center mt-5">
              <table class="w-100 text-center noticeTable">
                <tr>
                  <td style="background-color: darkgray; width: 600px; height: 600px;" rowspan="2">
                    <div id="carouselvarietyControls" class="carousel slide notice-new" data-bs-ride="carousel" data-bs-interval="5000">
                      <div class="carousel-inner">
                        <div :class="`carousel-item ${i == '0' ? 'active' : ''}`" :key="i" v-for="(ev, i) in this.ingEvent">
                          <div v-if="ev.addImageFlag == 'Y'" class="img-container">
                            <div v-if="ev.ctCd === 'EV'">
                              <img
                                :src="`/download/notice/EV/${ev.noticeCode}.jpg`"
                                class="card-img-top leftImg"
                                v-if="ev.link != undefined"
                                @click="goToLink(ev.link)"
                                style="cursor: pointer"
                              />
                              <img
                                :src="`/download/notice/EV/${ev.noticeCode}.jpg`"
                                class="card-img-top leftImg"
                                v-else
                              />
                            </div>
                            <div v-else-if="ev.ctCd === 'PR'">
                              <img
                                :src="`/download/notice/PR/${ev.noticeCode}.jpg`"
                                class="card-img-top leftImg"
                                v-if="ev.link != undefined"
                                @click="goToLink(ev.link)"
                                style="cursor: pointer"
                              />
                              <img
                                :src="`/download/notice/PR/${ev.noticeCode}.jpg`"
                                class="card-img-top leftImg"
                                v-else
                              />
                            </div>
                          </div>
                          <div v-else-if="ev.addVideoFlag == 'Y'" class="video-container">
                            <div v-if="ev.ctCd === 'EV'">
                              <video
                                :src="`/download/notice/EV/${ev.noticeCode}.mp4`"
                                class="card-img-top leftImg"
                                v-if="ev.link != undefined"
                                @click="goToLink(ev.link)"
                                style="cursor: pointer"
                                muted
                                autoplay
                                loop
                              />
                              <video
                                :src="`/download/notice/EV/${ev.noticeCode}.mp4`"
                                class="card-img-top leftImg"
                                v-else
                                muted
                                autoplay
                                loop
                              />
                            </div>
                            <div v-if="ev.ctCd === 'PR'">
                              <video
                                :src="`/download/notice/PR/${ev.noticeCode}.mp4`"
                                class="card-img-top leftImg"
                                v-if="ev.link != undefined"
                                @click="goToLink(ev.link)"
                                style="cursor: pointer"
                                muted
                                autoplay
                                loop
                              />
                              <video
                                :src="`/download/notice/PR/${ev.noticeCode}.mp4`"
                                class="card-img-top leftImg"
                                v-else
                                muted
                                autoplay
                                loop
                              />
                            </div>
                          </div>
                          <div v-else class="evtext">
                            <img
                              src="../assets/img/noImage.jpg"
                              class="img-fluid leftImg"
                            />
                            <div class="evname">
                              {{ ev.nTitle }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselvarietyControls" data-bs-slide="prev">
                        <div class="back">
                          <span class="noticePrev"><i class="bi bi-arrow-left-circle"></i></span>
                          <span class="visually-hidden">Previous</span>
                        </div>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselvarietyControls" data-bs-slide="next">
                        <div class="back">
                          <span class="noticeNext"><i class="bi bi-arrow-right-circle"></i></span>
                          <span class="visually-hidden">Next</span>
                        </div>
                      </button>
                    </div>
                  </td>
                  <!-- 공지사항 리스트 -->
                  <td style="width: 600px; height: 400px; vertical-align: top">
                    <div class="row">
                      <div :key="j" v-for="(notice, j) in this.noticeList.slice(0, 5)">
                        <div class="post-entry d-flex">
                          <div class="row contents order-md-1 pl-0">
                            <div class="col-9">
                              <a href="javascript:void(0)" @click="showArea(j)">
                                <h5 v-if="notice.ctCd === 'NT'">
                                  <b style="color: black">[공지사항]</b> &nbsp;
                                  {{ notice.nTitle }}
                                </h5>
                                <h5 v-else-if="notice.ctCd === 'EV'">
                                  <b style="color: darkgreen">[이 &nbsp;벤&nbsp; 트]</b>&nbsp; {{ notice.nTitle }}
                                </h5>
                                <h5 v-else-if="notice.ctCd === 'TR'">
                                  <b style="color: darkred">[여행관련]</b>&nbsp;{{ notice.nTitle }}
                                </h5>
                                <h5 v-else-if="notice.ctCd === 'MO'">
                                  <b style="color: darkblue">[항공선박]</b>&nbsp; {{ notice.nTitle }}
                                </h5>
                                <h5 v-else-if="notice.ctCd === 'PR'">
                                  <b style="color: darkorange">[홍보/안내]</b>&nbsp; {{ notice.nTitle }}
                                </h5>
                                <h5 v-else>{{ notice.nTitle }}</h5>
                              </a>
                            </div>
                            <div class="col-3 text-end">
                              <span class="date-read"> {{this.getDateFormat(notice.nFrom)}}</span>
                            </div>
                          </div>
                        </div>
                        <!-- 공지사항 내용 -->
                        <div class="nContent" :id="j">
                          <div class="container py-1 px-4">
                            <p class="mb-5 text-start" style="font-size: smaller">
                              {{ notice.nContent }}
                            </p>
                            <div class="position-relative mb-3 text-center">
                              <img
                                v-if="notice.addImageFlag === 'Y'"
                                :src="`/download/notice/${notice.ctCd}/${notice.noticeCode}.jpg`"
                                class="card-img-top"
                                alt="..."
                                style="
                                  width: 60%;
                                  height: 60%;
                                  object-fit: cover;
                                "
                              />
                              <video
                                v-else-if="notice.addVideoFlag === 'Y'"
                                :src="`/download/notice/${notice.ctCd}/${notice.noticeCode}.mp4`"
                                class="card-img-top"
                                alt="..."
                                style="
                                  width: 60%;
                                  height: 60%;
                                  object-fit: cover;
                                "
                                controls
                              />
                            </div>
                            <p class="row col-lg-3 gopro" v-if="notice.linkFlag === 'Y'">
                              <a :href="`${notice.link}`" class="text-center">
                                바로가기
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <!-- 퀵메뉴 -->
                <tr>
                  <td style="width: 600px; height: 200px; vertical-align: top">
                    <div class="container quick" style="padding-left: 25px">
                      <div class="row contents">
                        <div class="col-3 text-center">
                          <a href="/customerAsk" class="d-flex flex-column align-items-center">
                            <img
                              src="../assets/img/new-test/m-ask.png"
                              class="mb-2"
                            />
                            <p class="quickTitle">1:1 문의</p>
                          </a>
                        </div>
                        <div class="col-3 text-center">
                          <a href="/requestQuotation" class="d-flex flex-column align-items-center">
                            <img
                              src="../assets/img/new-test/m-group.png"
                              class="mb-2"
                            />
                            <p class="quickTitle">기업/단체 문의</p>
                          </a>
                        </div>
                        <div class="col-3 text-center">
                          <a href="/customerService" class="d-flex flex-column align-items-center">
                            <img
                              src="../assets/img/new-test/m-question.png"
                              class="mb-2"
                            />
                            <p class="quickTitle">자주하는 질문</p>
                          </a>
                        </div>
                        <div class="col-3 text-center">
                          <div class="d-flex flex-column align-items-center">
                            <img
                              src="../assets/img/new-test/m-point.png"
                              class="mb-2"
                              style="cursor: pointer"
                              @click="
                                goLink('page', 'pointList_client', '', '')
                              "
                            />
                            <p class="quickTitle">포인트 안내</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </section>

          <!-- 우측고정공지가 있는 경우-->
          <section id="notice" class="notice w-100 mt-5" v-else>
            <div class="row">
              <div class="section-title">
                <a href="/customerNotice">
                  <h3 class="text-start text-touch" style="font-weight: 800; cursor: pointer" 
                    @touchstart="handleTouchStart"
                    @touchend="handleTouchEnd"
                    :class="{ touchable: isTouched }"
                  >이벤트 <i class="bi bi-dot"></i> 홍보 & 공지사항
                  </h3>
                </a>
                <div id="more">
                  <a href="/customerNotice"><i class="bi bi-box-arrow-up-right"></i> 더보기</a>
                </div>
              </div>
            </div>
            <div class="justify-content-center mt-3">
              <table class="w-100 text-center noticeTable" style="background-color: darkgray">
                <tr>
                  <td style="width: 700px; height: 600px" rowspan="2">
                    <div id="carouselvarietyControls" class="carousel slide notice-new" data-bs-ride="carousel" data-bs-interval="5000" >
                      <div class="carousel-inner">
                        <div :class="`carousel-item ${i == '0' ? 'active' : ''}`" :key="i" v-for="(ev, i) in this.ingEvent">
                          <div v-if="ev.addImageFlag == 'Y'" class="img-container">
                            <div v-if="ev.ctCd === 'EV'">
                              <img
                                :src="`/download/notice/EV/${ev.noticeCode}.jpg`"
                                class="card-img-top leftImg"
                                v-if="ev.link != undefined"
                                @click="goToLink(ev.link)"
                                style="cursor: pointer"
                              />
                              <img
                                :src="`/download/notice/EV/${ev.noticeCode}.jpg`"
                                class="card-img-top leftImg"
                                v-else
                              />
                            </div>
                            <div v-else-if="ev.ctCd === 'PR'">
                              <img
                                :src="`/download/notice/PR/${ev.noticeCode}.jpg`"
                                class="card-img-top leftImg"
                                v-if="ev.link != undefined"
                                @click="goToLink(ev.link)"
                                style="cursor: pointer"
                              />
                              <img
                                :src="`/download/notice/PR/${ev.noticeCode}.jpg`"
                                class="card-img-top leftImg"
                                v-else
                              />
                            </div>
                          </div>
                          <div v-else-if="ev.addVideoFlag == 'Y'" class="video-container">
                            <div v-if="ev.ctCd === 'EV'">
                              <video
                                :src="`/download/notice/EV/${ev.noticeCode}.mp4`"
                                class="card-img-top leftImg"
                                v-if="ev.link != undefined"
                                @click="goToLink(ev.link)"
                                style="cursor: pointer"
                                muted
                                autoplay
                                loop
                              />
                              <video
                                :src="`/download/notice/EV/${ev.noticeCode}.mp4`"
                                class="card-img-top leftImg"
                                v-else
                                muted
                                autoplay
                                loop
                              />
                            </div>
                            <div v-if="ev.ctCd === 'PR'">
                              <video
                                :src="`/download/notice/PR/${ev.noticeCode}.mp4`"
                                class="card-img-top leftImg"
                                v-if="ev.link != undefined"
                                @click="goToLink(ev.link)"
                                style="cursor: pointer"
                                muted
                                autoplay
                                loop
                              />
                              <video
                                :src="`/download/notice/PR/${ev.noticeCode}.mp4`"
                                class="card-img-top leftImg"
                                v-else
                                muted
                                autoplay
                                loop
                              />
                            </div>
                          </div>
                          <div v-else class="evtext">
                            <img
                              src="../assets/img/noImage.jpg"
                              class="img-fluid leftImg"
                            />
                            <div class="evname">
                              {{ ev.nTitle }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselvarietyControls" data-bs-slide="prev">
                        <div class="back">
                          <span class="noticePrev"><i class="bi bi-arrow-left-circle"></i></span>
                          <span class="visually-hidden">Previous</span>
                        </div>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselvarietyControls" data-bs-slide="next">
                        <div class="back">
                          <span class="noticeNext"><i class="bi bi-arrow-right-circle"></i></span>
                          <span class="visually-hidden">Next</span>
                        </div>
                      </button>
                    </div>
                  </td>
                  <td style="width: 500px; height: 300px">
                    <div class="fixedNotice">
                      <div v-if="this.fixedPR[0]">
                        <div v-if="this.fixedPR[0].addImageFlag == 'Y'">
                          <div v-if="this.fixedPR[0].ctCd === 'EV'">
                            <img
                              :src="`/download/notice/EV/${this.fixedPR[0].noticeCode}.jpg`"
                              class="card-img-top rightImg1"
                              v-if="this.fixedPR[0].link != undefined"
                              @click="goToLink(this.fixedPR[0].link)"
                              style="cursor: pointer"
                            />
                            <img
                              :src="`/download/notice/EV/${this.fixedPR[0].noticeCode}.jpg`"
                              class="card-img-top rightImg1"
                              v-else
                            />
                          </div>
                          <div v-else-if="this.fixedPR[0].ctCd === 'PR'">
                            <img
                              :src="`/download/notice/PR/${this.fixedPR[0].noticeCode}.jpg`"
                              class="card-img-top rightImg1"
                              v-if="this.fixedPR[0].link != undefined"
                              @click="goToLink(this.fixedPR[0].link)"
                              style="cursor: pointer"
                            />
                            <img
                              :src="`/download/notice/PR/${this.fixedPR[0].noticeCode}.jpg`"
                              class="card-img-top rightImg1"
                              v-else
                            />
                          </div>
                        </div>
                        <div v-else-if="this.fixedPR[0].addVideoFlag == 'Y'">
                          <div v-if="this.fixedPR[0].ctCd === 'EV'">
                            <video
                              :src="`/download/notice/EV/${this.fixedPR[0].noticeCode}.mp4`"
                              type="video/mp4"
                              class="card-img-top rightImg1"
                              v-if="this.fixedPR[0].link != undefined"
                              @click="goToLink(this.fixedPR[0].link)"
                              style="cursor: pointer"
                              muted
                              autoplay
                              loop
                            />
                            <video
                              :src="`/download/notice/EV/${this.fixedPR[0].noticeCode}.mp4`"
                              type="video/mp4"
                              class="card-img-top rightImg1"
                              v-else
                              muted
                              autoplay
                              loop
                            />
                          </div>
                          <div v-if="this.fixedPR[0].ctCd === 'PR'">
                            <video
                              :src="`/download/notice/PR/${this.fixedPR[0].noticeCode}.mp4`"
                              type="video/mp4"
                              class="card-img-top rightImg1"
                              v-if="this.fixedPR[0].link != undefined"
                              @click="goToLink(this.fixedPR[0].link)"
                              style="cursor: pointer"
                              muted
                              autoplay
                              loop
                            />
                            <video
                              :src="`/download/notice/PR/${this.fixedPR[0].noticeCode}.mp4`"
                              type="video/mp4"
                              class="card-img-top rightImg1"
                              v-else
                              muted
                              autoplay
                              loop
                            />
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div></div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td style="width: 500px; height: 300px">
                    <div class="fixedNotice">
                      <div v-if="this.fixedPR[1]">
                        <div v-if="this.fixedPR[1].addImageFlag == 'Y'">
                          <div v-if="this.fixedPR[1].ctCd === 'EV'">
                            <img
                              :src="`/download/notice/EV/${this.fixedPR[1].noticeCode}.jpg`"
                              class="card-img-top rightImg2"
                              v-if="this.fixedPR[1].link != undefined"
                              @click="goToLink(this.fixedPR[1].link)"
                              style="cursor: pointer"
                            />
                            <img
                              :src="`/download/notice/EV/${this.fixedPR[1].noticeCode}.jpg`"
                              class="card-img-top rightImg2"
                              v-else
                            />
                          </div>
                          <div v-else-if="this.fixedPR[1].ctCd === 'PR'">
                            <img
                              :src="`/download/notice/PR/${this.fixedPR[1].noticeCode}.jpg`"
                              class="card-img-top rightImg2"
                              v-if="this.fixedPR[1].link != undefined"
                              @click="goToLink(this.fixedPR[1].link)"
                              style="cursor: pointer"
                            />
                            <img
                              :src="`/download/notice/PR/${this.fixedPR[1].noticeCode}.jpg`"
                              class="card-img-top rightImg2"
                              v-else
                            />
                          </div>
                        </div>
                        <div v-else-if="this.fixedPR[1].addVideoFlag == 'Y'">
                          <div v-if="this.fixedPR[1].ctCd === 'EV'">
                            <video
                              :src="`/download/notice/EV/${this.fixedPR[1].noticeCode}.mp4`"
                              type="video/mp4"
                              class="card-img-top rightImg2"
                              v-if="this.fixedPR[1].link != undefined"
                              @click="goToLink(this.fixedPR[1].link)"
                              style="cursor: pointer"
                              muted
                              autoplay
                              loop
                            />
                            <video
                              :src="`/download/notice/EV/${this.fixedPR[1].noticeCode}.mp4`"
                              type="video/mp4"
                              class="card-img-top rightImg2"
                              v-else
                              muted
                              autoplay
                              loop
                            />
                          </div>
                          <div v-if="this.fixedPR[1].ctCd === 'PR'">
                            <video
                              :src="`/download/notice/PR/${this.fixedPR[1].noticeCode}.mp4`"
                              type="video/mp4"
                              class="card-img-top rightImg2"
                              v-if="this.fixedPR[1].link != undefined"
                              @click="goToLink(this.fixedPR[1].link)"
                              style="cursor: pointer"  
                              muted
                              autoplay
                              loop
                            />
                            <video
                              :src="`/download/notice/PR/${this.fixedPR[1].noticeCode}.mp4`"
                              type="video/mp4"
                              class="card-img-top rightImg2"
                              v-else
                              muted
                              autoplay
                              loop
                            />
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div></div>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>

            <!-- 공지관련 리스트 / 빠른메뉴 -->
            <div class="col-lg-12 my-4">
              <div class="row">
                <div class="col-lg-12" :key="j" v-for="(notice, j) in this.noticeList.slice(0, 3)">
                  <div class="post-entry d-flex">
                    <div class="row contents order-md-1 pl-0">
                      <div class="col-10">
                        <a href="javascript:void(0)" @click="showArea(j)">
                          <h5 v-if="notice.ctCd === 'NT'">
                            <b style="color: black">[공지사항]</b> &nbsp;
                            {{ notice.nTitle }}
                          </h5>
                          <h5 v-else-if="notice.ctCd === 'EV'">
                            <b style="color: darkgreen"
                              >[이 &nbsp;벤&nbsp; 트]</b
                            >&nbsp; {{ notice.nTitle }}
                          </h5>
                          <h5 v-else-if="notice.ctCd === 'TR'">
                            <b style="color: darkred">[여행관련]</b>&nbsp;
                            {{ notice.nTitle }}
                          </h5>
                          <h5 v-else-if="notice.ctCd === 'MO'">
                            <b style="color: darkblue">[항공선박]</b>&nbsp;
                            {{ notice.nTitle }}
                          </h5>
                          <h5 v-else-if="notice.ctCd === 'PR'">
                            <b style="color: darkorange">[홍보/안내]</b>&nbsp;
                            {{ notice.nTitle }}
                          </h5>
                          <h5 v-else>{{ notice.nTitle }}</h5>
                        </a>
                      </div>
                      <div class="col-2 text-end">
                        <span class="date-read">{{
                          this.getDateFormat(notice.nFrom)
                        }}</span>
                      </div>
                    </div>
                  </div>
                  <!-- 공지사항 내용 -->
                  <div class="nContent" :id="j">
                    <div class="container py-1 px-4">
                      <p class="mb-5 text-start" style="font-size: smaller">
                        {{ notice.nContent }}
                      </p>
                      <div class="position-relative mb-3 text-center">
                        <img
                          v-if="notice.addImageFlag === 'Y'"
                          :src="`/download/notice/${notice.ctCd}/${notice.noticeCode}.jpg`"
                          class="card-img-top"
                          alt="..."
                          style="width: 60%; height: 60%; object-fit: cover"
                        />
                        <video
                          v-else-if="notice.addVideoFlag === 'Y'"
                          :src="`/download/notice/${notice.ctCd}/${notice.noticeCode}.mp4`"
                          class="card-img-top"
                          alt="..."
                          style="width: 60%; height: 60%; object-fit: cover"
                          controls
                        />
                      </div>
                      <p
                        class="row col-lg-3 gopro"
                        v-if="notice.linkFlag === 'Y'"
                      >
                        <a :href="`${notice.link}`" class="text-center">
                          바로가기
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 빠른메뉴 -->
              <hr style="color: #000; margin: 0 0 0.7rem" />
              <div class="container quick">
                <div class="row gx-2 text-center">
                  <div class="col-3">
                    <a href="/customerAsk">
                      <img src="../assets/img/new-test/m-ask.png" />
                      <p class="noticeText">1:1 문의</p>
                    </a>
                  </div>
                  <div class="col-3">
                    <a href="/requestQuotation">
                      <img src="../assets/img/new-test/m-group.png" />
                      <p class="noticeText">기업/단체 문의</p>
                    </a>
                  </div>
                  <div class="col-3">
                    <a href="/customerService">
                      <img src="../assets/img/new-test/m-question.png" />
                      <p class="noticeText">자주하는 질문</p>
                    </a>
                  </div>
                  <div class="col-3">
                    <img
                      src="../assets/img/new-test/m-point.png"
                      style="cursor: pointer"
                      @click="goLink('page', 'pointList_client', '', '')"
                    />
                    <p class="noticeText">포인트 안내</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div id="bannerEnd"></div>
        </div>

        <div class="side right">
          <!-- Remote Control Section -->
          <div ref="rtRemoteContainer" class="rt-remote-container not-hidden-remote">
            <MainRtRemote :is-hidden="isHidden" />
          </div>
        </div>
      </div>
    </div>


    <!-- ******************* *******************  ************************************** -->
    <!-- ******************* 모바일 디바이스 대응  ************************************** -->
    <!-- ******************* *******************  ************************************** -->
    <div id="main-app" v-else-if="this.mobileDisplay">
      <!-- 모바일 메뉴체계(햄버거) -->
      <div class="mobile-menu" id="userM">
        <nav id="navbarExample" class="navbar navbar-expand-lg navbar-light justify-content-center" aria-label="Main navigation">
          <div class="row container">
            <div class="col-4">
              <button class="border-0 m-recent" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobileCollapseRecent" aria-controls="mobileCollapseRecent" id="btn-recentM" style="display: none">
                <i class="bi bi-clock-history fs-5"></i>
              </button>
            </div>
            <div class="col-4">
              <!-- 홈 -->
              <a href="/">
                <img src="../../src/assets/img/Ntabi.png" class="logo" alt="Logo" style="width: 70px" />
              </a>
            </div>
            <div class="col-4 text-end">
              <!-- 메뉴 -->
              <button
                class="navbar-toggler border-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#mobileCollapseMenu"
                aria-controls="mobileCollapseMenu"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
        </nav>

        <!-- 모바일 메뉴 -->
        <div class="row mmenu">
          <div class="offcanvas offcanvas-start" tabindex="-1" id="mobileCollapseMenu" aria-labelledby="mobileCollapseMenuLabel">
            <div class="offcanvas-header">
              <!-- 로그인 -->
              <div class="toolbar-login">
                <a class="b2cTag" href="javascript: void(0)" style="text-decoration-line: none; font-weight: normal" @click="toB2CLoginPage" v-if="user.email == undefined">
                  로그인
                </a>
                <a class="b2cTag text-danger" href="javascript: void(0)" style="text-decoration-line: none; font-weight: normal" @click="toB2CLogout" v-else>
                  로그아웃
                </a>
                <a class="b2cTag" href="javascript: void(0)" style="text-decoration-line: none; font-weight: normal" @click="toMyPage" v-if="user.email != undefined">
                  마이페이지
                </a>
                <a
                  class="b2cTag"
                  href="javascript: void(0)"
                  style="text-decoration-line: none; font-weight: normal"
                  @click="toReqQuotation"
                  v-if="user.email != undefined"
                >
                  기업/단체문의
                </a>
                <a href="/customerService">고객센터</a>
              </div>
              <!-- 닫기 -->
              <button
                type="button"
                class="btn-close text-reset mbtn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            <!-- 메뉴 -->
            <div class="offcanvas-body text-start">
              <ul class="navbar-nav ms-auto navbar-nav-scroll">
                <li class="nav-item dropdown" v-if="user.handlerId == undefined">
                  <a class="nav-link dropdown-toggle" href="javascipt:void(0)" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none">
                    엔데이트립현지투어
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdown01">
                    <li class="nav-item2 dropdown">
                      <div class="n-country text-start" style="margin-left: 10px; margin-right: auto" :key="ntCd" v-for="(ba, ntCd) in this.ntToAr">
                        <div>
                          <a :href="`/bustour?nt=${ba[0].ntCd}&ar=`" class="fw-bold">
                            {{ ba[0].NT }}
                          </a>
                        </div>
                        <div class="mssun-menu">
                          <a :href="`/bustour?nt=${info.ntCd}&ar=${info.arCd}`" :key="info.arCd" v-for="info in ba" class="mt-0 p-0">
                            &nbsp;&nbsp;&nbsp;{{ info.AR }}
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <!-- <li  class="nav-item dropdown one" v-if="user.handlerId == undefined">
                  <a class="nav-link dropdown-toggle" href="javascript: void(0)" id="dropdown03" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none" onclick="location.href='subList?nt=JP'">
                    패키지여행
                  </a>
                </li> -->
                <li class="nav-item dropdown one" v-if="user.handlerId == undefined">
                  <a class="nav-link dropdown-toggle" href="javascript: void(0)" id="dropdown04" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none" onclick="location.href='/exhibitionEvent'">
                    버라이어티팩 (테마기획상품)
                  </a>
                </li>

                <li class="nav-item dropdown" v-if="user.handlerId == undefined">
                  <a class="nav-link dropdown-toggle" href="javascipt:void(0)" id="dropdown01" data-bs-toggle="dropdown" aria-expanded="false" style="text-decoration-line: none">
                    일본선박
                  </a>
                  <ul class="dropdown-menu" aria-labelledby="dropdown01">
                    <li class="nav-item2 dropdown">
                      <div class="n-country text-start" style="margin-left: 10px; margin-right: auto" :key="dtCd" v-for="(ba, dtCd) in this.spToDt">
                        <div>
                          <a :href="`/shipTour?dt=${ba[0].dtCd}&tr=`" class="fw-bold">
                            {{ ba[0].DT }}
                          </a>
                        </div>
                        <div class="mssun-menu">
                          <a :href="`/shipTour?dt=${info.dtCd}&tr=${info.trCd}`" :key="info.trCd" v-for="info in ba" class="mt-0 p-0">
                            &nbsp;&nbsp;&nbsp;{{ info.TR }}
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>

                <li
                  class="nav-item dropdown" v-if="user.handlerId == undefined">
                  <a
                    class="nav-link dropdown-toggle"
                    href="javascipt:void(0)"
                    id="dropdown01"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style="text-decoration-line: none"
                    >티켓/패스</a
                  >
                  <ul class="dropdown-menu" aria-labelledby="dropdown01">
                    <li class="nav-item2 dropdown">
                      <div
                        class="n-country text-start"
                        style="margin-left: 10px; margin-right: auto"
                        :key="ntCd"
                        v-for="(ba, ntCd) in this.tkNtToAr"
                      >
                        <div>
                          <a
                            :href="`/tktPass?nt=${ba[0].ntCd}&ar=`"
                            class="fw-bold"
                            >{{ ba[0].NT }}</a
                          >
                        </div>
                        <div class="mssun-menu">
                          <a
                            :href="`/tktPass?nt=${info.ntCd}&ar=${info.arCd}`"
                            :key="info.arCd"
                            v-for="info in ba"
                            class="mt-0 p-0"
                          >
                            &nbsp;&nbsp;&nbsp;{{ info.AR }}
                          </a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>

                <li
                  class="nav-item dropdown one"
                  v-if="user.handlerId == undefined"
                >
                  <a
                    class="nav-link dropdown-toggle"
                    href="javascript: void(0)"
                    id="dropdown04"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    style="text-decoration-line: none"
                    onclick="location.href='/membershipPage'"
                  >
                    멤버십<!-- <img class="lvar-m" src="../src/assets/img/pBus.png"> -->
                  </a>
                </li>
                <li class="nav-item dropdown one" v-if="user.handlerId == undefined">
                  <a class="nav-link dropdown-toggle blink" href="https://smartstore.naver.com/ntabi" target="_blank">
                    <span style="color: green">네이버예약</span>
                  </a>
                </li>

                <!-- <li class="nav-item dropdown one" v-if="user.handlerId == undefined">
                  <a class="nav-link dropdown-toggle" href="https://smartstore.naver.com/somen" target="_blank">
                    <span style="color: green">소면공방</span>
                  </a>
                </li> -->
              </ul>
            </div>
          </div>
        </div>

        <!-- 모바일 최근 본 상품 -->
        <div class="row mrecent" id="recent-item-m">
          <div
            class="offcanvas off-recent offcanvas-top"
            tabindex="-1"
            id="mobileCollapseRecent"
            aria-labelledby="mobileCollapseRecentLabel"
          >
            <div class="offcanvas-header">
              <h5 class="offcanvas-title">최근 본 상품</h5>
              <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            <!-- 메뉴 -->
            <div class="offcanvas-body">
              <div
                class="recent-items"
                :style="{
                  height: this.recentFlag === 'Y' ? 'auto' : '0',
                  overflow: 'hidden',
                  transition: 'height 0.3s ease',
                }"
                :key="i"
                v-for="(rv, i) in this.recentViewInfo"
              >
                <a
                  href="javascript:void(0)"
                  @click="goToDetail(rv.pdtCode, rv.salesRoute)"
                >
                  <div class="row item recent">
                    <div class="col-4">
                      <img
                        v-if="rv.imgName != 'noImage'"
                        :src="`/download/${rv.pdtCode}/${rv.imgName}`"
                        class="card-img-top"
                        alt="상품이미지"
                      />
                    </div>
                    <div class="col-8 align-self-center">
                      <p class="name">{{ rv.pdtNameKor }}</p>
                    </div>
                  </div>
                </a>
                <hr style="margin: 20px 3px" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- TOP배너 -->
      <section id="topBanner-m" class="topBanner" ref="mainBanner">
        <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel" @slide-bs-carousel="handleSlideChange">
          <div class="carousel-indicators">
            <button
              v-for="(slide, index) in topSlides"
              :key="'indicator-' + index"
              type="button"
              :data-bs-target="'#carouselExampleControls'"
              :data-bs-slide-to="index"
              :class="{ active: index === 0 }"
              :aria-current="index === 0 ? 'true' : 'false'"
              :aria-label="'Slide ' + (index + 1)"
            ></button>
          </div>
          <div class="carousel-inner">
            <div v-for="(slide, index) in topSlides" :key="index" class="carousel-item" :class="{ active: index === 0 }">
              <img :src="slide.image" class="d-block w-100" :alt="slide.alt" style="height: 350px" @click="goLink2(slide.type, slide.link, slide.pId, slide.sRoute)"/>
              <div class="carousel-caption d-none d-md-block">
                <h5 v-html="slide.text1"></h5>
                <p>{{ slide.text2 }}</p>
              </div>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </section>

      <div class="hr-line mt-3"></div>

      <!-- 모바일 메인 메뉴 -->
      <section class="main-menu">
        <div class="icon-menu" style="margin-top: 10px;">
          <!-- 현지투어 -->
          <div class="icon-item">
            <img src="../assets/img/AppImg/ndaytrip.png" alt="현지투어" @click="goLink2('page', 'bustour', '', '')"/>
            <p class="icon-text" style="padding-top: 5px;">엔데이트립<br/>현지투어</p>
          </div>

          <div class="icon-item">
            <img src="../assets/img/AppImg/membership2.png" alt="멤버십" @click="goLink2('page', 'membershipPage', '', '')"/>
            <p class="icon-text" style="padding-top: 5px;">엔데이트립<br/>멤버십</p>
          </div>

          <div class="icon-item">
            <img src="../assets/img/AppImg/nbus.png" alt="엔버스" @click="goLink2('page', 'nbus', '', '')"/>
            <p class="icon-text">엔버스</p>
          </div>

          <div class="icon-item">
            <a href="https://smartstore.naver.com/ntabi" target="_blank">
              <img src="../assets/img/AppImg/naver.png" alt="네이버예약" />
            </a>
            <p class="icon-text">네이버예약</p>
          </div>


          <!-- 패키지여행 -->
          <!-- <div class="icon-item">
            <img src="../assets/img/AppImg/ntabi.png" alt="페키지여행" @click="goLink2('Ntabi', '', '', '')"/>
            <p class="icon-text">패키지투어</p>
          </div> -->
        </div>


        <div class="icon-menu mt-3">

          <div class="icon-item">
            <img src="../assets/img/AppImg/variety.png" alt="버라이어티팩" @click="goLink2('page', 'exhibitionEvent', '', '')"/>
            <p class="icon-text">버라이어티팩</p>
          </div>

          <div class="icon-item">
            <img src="../assets/img/AppImg/japanship.png" alt="일본선박" @click="goLink2('page', 'shipTour', '', '')"/>
            <!-- <p class="icon-text">부산-일본 승선권</p> -->
            <p class="icon-text" style="padding-top: 5px;">부산-일본<br/>승선권</p>
          </div>

          <div class="icon-item">
            <img src="../assets/img/AppImg/ticket.png" alt="티켓패스" @click="goLink2('page', 'tktPass', '', '')"/>
            <p class="icon-text">티켓&패스</p>
          </div>

          <div class="icon-item"> </div>

        </div>
      </section>

      <div class="hr-line mb-3"></div>

      <!-- Mid배너 -->
      <section id="midBanner-m" class="midBanner" ref="mainBanner">
        <div id="midCsControls" class="carousel slide" data-bs-ride="carousel" @slide-bs-carousel="handleSlideChange">
          <div class="carousel-indicators">
            <button
              v-for="(slide, index) in midSlides"
              :key="'indicator-' + index"
              type="button"
              :data-bs-target="'#midCsControls'"
              :data-bs-slide-to="index"
              :class="{ active: index === 0 }"
              :aria-current="index === 0 ? 'true' : 'false'"
              :aria-label="'Slide ' + (index + 1)"
            ></button>
          </div>
          <div class="carousel-inner">
            <div v-for="(slide, index) in midSlides" :key="index" class="carousel-item" :class="{ active: index === 0 }">
              <img :src="slide.image" class="d-block w-100" :alt="slide.alt" style="width: 100%; height: 250px; object-fit: cover" @click="goLink2(slide.type, slide.link, slide.pId, slide.sRoute)"/>
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#midCsControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#midCsControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </section>


      <!-- Mobile grouping 상품리스트 -->
      <section id="groupProducts" class="groupProduct" style="background-color: rgba(182, 185, 188, 0.2)" >
        <!-- 그루핑 테마이미지 캐러셀 -->
        <div id="carouselExampleControls_gr" class="carousel slide" data-bs-ride="carousel" data-bs-interval="7000" >
          <div class="carousel-indicators">
            <button
              v-for="(slide, grIndex) in grSlides"
              :key="'indicator-' + grIndex"
              type="button"
              :data-bs-target="'#carouselExampleControls_gr'"
              :data-bs-slide-to="grIndex"
              :class="{ active: currentSlideIndex === grIndex }"
              :aria-current="grIndex === 0 ? 'true' : 'false'"
              :aria-label="'Slide ' + (grIndex + 1)"
            ></button>
          </div>
          <div class="carousel-inner">
            <div 
              v-for="(slide, grIndex) in grSlides"
              :key="grIndex"
              class="carousel-item"
              :class="{ active: currentSlideIndex === grIndex }"
            >
              <img
                :src="slide.image"
                class="d-block w-100"
                :alt="slide.alt"
                style="height: 400px"
              />
            </div>
          </div>

          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls_gr" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls_gr" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>


        <!-- 그룹별 상품리스트 -->
        <div class="product-list mt-2" v-if="this.currentSlideIndex == 0 && this.productListForSales_grFin.length <= 0">
          <!-- <div v-if="this.productListForSales_grFin.length > 0" key="product-list"> -->
          <div key="product-list">
            <div class="row" :key="i" v-for="(pdt, i) in this.productListForSales_gr">
              <div class="col-4 mt-4">
                <img
                  v-if="pdt.imgName != 'noImage'"
                  :src="`/download/${pdt.pdtCode}/${pdt.imgName}`"
                  class="img-fluid pdt-img"
                  style="width: 125px; height: 124px; border-radius: 8%; cursor: pointer;"
                  alt="..."
                  @click="goToDetail(pdt.pdtCode, pdt.salesRoute)"
                />
                <img
                  v-else
                  :src="`/download/noImage/noImage.jpg`"
                  class="img-fluid pdt-img"
                  style="width: 500px; border-radius: 10%"
                  alt="no image"
                />
              </div>
              <div class="col-8 mt-3" style="padding-left: 10px">
                <h6 class="product-title">{{ pdt.pdtNameKor }}</h6>
                <div class="product-container-m">
                  <span class="price">
                    <div v-if="this.user.memLevel === 'P'">
                      <div v-if="pdt.dispPrice === 'mem'">
                        <div v-if="pdt.sMinPrice === pdt.memP">
                          <img
                            src="../assets/img/pBus.png"
                            alt=""
                            class="mbus-icon-m"
                          />
                          <b class="mx-1 price-m"> 0원! (연간무제한)</b>
                        </div>
                        <div v-else>
                          <img
                            src="../assets/img/pBus.png"
                            alt=""
                            class="mbus-icon-m"
                          />
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice) }}원~</b>
                          <small style="font-size: 0.8rem" v-if="pdt.memP * 1 > 0">(결제 후,</small>
                          <b class="mx-1 price-m" style="color: #ffce00; font-size: 0.9rem" v-if="pdt.memP * 1 > 0"> {{ getCurrencyFormat(pdt.memP) }}</b>
                          <small style="font-size: 0.8rem" v-if="pdt.memP * 1 > 0">원 환급)</small>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="pdt.promoStatus === 'Y'">
                          <img
                            src="../assets/img/pBus.png"
                            alt=""
                            class="mbus-icon-m"
                          />
                          <b class="text-muted text-decoration-line-through mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice) }}</b>
                          <small><i class="bi bi-caret-right-fill"></i></small>
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                        <div v-else-if="pdt.promoStatus === 'N'">
                          <img
                            src="../assets/img/pBus.png"
                            alt=""
                            class="mbus-icon-m"
                          />
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                        <div v-else-if="pdt.promoStatus === 'E'">
                          <img
                            src="../assets/img/pBus.png"
                            alt=""
                            class="mbus-icon-m"
                          />
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                      </div>
                    </div>

                    <div v-else-if="this.user.memLevel === 'S'">
                      <div v-if="pdt.dispPrice === 'mem'">
                        <div v-if="pdt.dcForStd > 0">
                          <img
                            src="../assets/img/sBus.png"
                            alt=""
                            class="mbus-icon-m"
                          />
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice - pdt.sMinPrice * (pdt.dcForStd / 100)) }}원~</b>
                        </div>
                        <div v-else>
                          <div v-if="pdt.sMinPrice === pdt.memS">
                            <img
                              src="../assets/img/sBus.png"
                              alt=""
                              class="mbus-icon-m"
                            />
                            <b class="mx-1 price-m"> 0원! (연간무제한)</b>
                          </div>
                          <div v-else>
                            <img
                              src="../assets/img/sBus.png"
                              alt=""
                              class="mbus-icon-m"
                            />
                            <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice) }}원~</b
                            >
                            <small style="font-size: 0.8rem" v-if="pdt.memS * 1 > 0">(결제 후, </small>
                            <b class="mx-1 price-m" style="color: slategrey; font-size: 0.9rem" v-if="pdt.memS * 1 > 0"> {{ getCurrencyFormat(pdt.memS) }}원~</b>
                            <small style="font-size: 0.8rem" v-if="pdt.memS * 1 > 0">원 환급)</small>
                          </div>
                        </div>
                        <div>
                          <img
                            src="../assets/img/pBus.png"
                            alt=""
                            class="mbus-icon-m"
                            v-if="user.email != undefined"
                          />
                          <b class="mx-1 price-m" v-if="pdt.sMinPrice === pdt.memP">0원! (연간무제한)</b>
                          <b class="mx-1 price-m" v-else > {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.sMinPrice * (pdt.maxDC_P / 100)) }}원~</b>
                        </div>
                      </div>

                      <div v-else>
                        <div v-if="pdt.promoStatus === 'Y'">
                          <b class="text-muted text-decoration-line-through mx-1 price-m" >{{ getCurrencyFormat(pdt.sMinPrice) }}</b>
                          <small><i class="bi bi-caret-right-fill"></i></small>
                          <b class="mx-1 price-m" > {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                        <div v-else-if="pdt.promoStatus === 'N'">
                          <b class="mx-1 price-m" > {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                        <div v-else-if="pdt.promoStatus === 'E'">
                          <b class="mx-1 price-m" > {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                      </div>
                    </div>

                    <!-- 로그인전 상품도 멤버십표시 -->
                    <div v-else>
                      <!-- 일반회원가격(default) -->
                      <div class="my-0" v-if="pdt.promoStatus === 'Y'">
                        <img
                          src="../assets/img/gBus.png"
                          alt=""
                          class="mbus-icon-m"
                          v-if="user.email != undefined"
                        />
                        <label style="background-color: darkgray; padding: 3px 5px" class="memLabel-m" v-else >일반회원</label>
                        <b class="text-muted text-decoration-line-through mx-1 price-m" > {{ getCurrencyFormat(pdt.sMinPrice) }}</b>
                        <small><i class="bi bi-caret-right-fill"></i></small>
                        <b class="mx-1 price-m" > {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                      </div>
                      <div class="my-0" v-else-if="pdt.promoStatus === 'E'">
                        <div>
                          <img
                            src="../assets/img/gBus.png"
                            alt=""
                            class="mbus-icon-m"
                            v-if="user.email != undefined"
                          />
                          <label style="background-color: darkgray; padding: 3px 5px" class="memLabel-m" v-else >일반회원</label> 
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                      </div>
                      <div class="my-0" v-else-if="pdt.promoStatus === 'N'">
                        <!-- 이외상품 스탠다드 할인율이 있는 케이스 -->
                        <div class="my-0" v-if="pdt.dcForMem_S > 0">
                          <img
                            src="../assets/img/sBus.png"
                            alt=""
                            class="mbus-icon-m"
                            v-if="user.email != undefined"
                          />
                          <label style="background-color: #0b7fd4; padding: 3px 5px" class="memLabel-m" v-else>스탠다드</label> 
                          <b class="mx-1 price-m" > {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                        <div class="my-0" v-else>
                          <img
                            src="../assets/img/gBus.png"
                            alt=""
                            class="mbus-icon-m"
                            v-if="user.email != undefined"
                          />
                          <label style="background-color: darkgray; padding: 3px 5px" class="memLabel-m" v-else>일반회원</label>
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                      </div>

                      <div
                        v-if="((pdt.memLevel_S == 'N' || pdt.memLevel_S == null) && (pdt.memLevel_P == 'N' || pdt.memLevel_P == null)) || pdt.nrFlag == 'Y' || pdt.vrFlag == 'Y'">
                        <div class="my-0" v-if="pdt.promoStatus === 'E'">
                          <div class="my-0">
                            <div>
                              <img
                                src="../assets/img/sBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel-m" v-else >스탠다드</label>
                              <b class="mx-1 price-m" > {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.price_dcForMem_S) }}원~</b>
                            </div>
                            <div class="my-0">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: #ffce00; padding: 3px 5px;" class="memLabel-m" v-else>프리미엄</label>
                              <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.price_dcForMem_P) }}원~</b>
                            </div>
                          </div>
                        </div>
                        <div class="my-0" v-else-if="pdt.promoStatus === 'N'">
                          <div>
                            <div class="my-0">
                              <img
                                src="../assets/img/sBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel-m" v-else>스탠다드</label>
                              <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.price_dcForMem_S) }}원~</b>
                            </div>
                            <div class="my-0">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: #ffce00; padding: 3px 5px;" class="memLabel-m" v-else>프리미엄</label>
                              <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.price_dcForMem_P) }}원~</b>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- 스탠다드멤버십 상품 -->
                      <div class="my-0" v-if="pdt.memLevel_S === 'Y' && (pdt.nrFlag === 'N' || pdt.nrFlag == null) && (pdt.vrFlag === 'N' || pdt.vrFlag == null)">
                        <img
                          src="../assets/img/sBus.png"
                          alt=""
                          class="mbus-icon-m"
                          v-if="user.email != undefined"
                        />
                        <label style="background-color: #0b7fd4; padding: 3px 5px" class="memLabel-m" v-else>스탠다드</label>
                        <b class="mx-1 price-m" v-if="pdt.sMinPrice * 1 - pdt.sMinPrice * (pdt.maxDC_S / 100) == 0"> 0원! (연간무제한)</b>
                        <b class="mx-1 price-m" v-else> {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.sMinPrice * (pdt.maxDC_S / 100)) }}원~</b>
                      </div>

                      <!-- 프리미엄멤버십 상품 -->
                      <div class="my-0" v-if="pdt.memLevel_P === 'Y' && (pdt.nrFlag === 'N' || pdt.nrFlag == null) && (pdt.vrFlag === 'N' || pdt.vrFlag == null)">
                        <div class="my-0" v-if="(pdt.memLevel_s == 'N' || pdt.memLevel_s == null) && pdt.dcForStd > 0">
                          <img
                            src="../assets/img/sBus.png"
                            alt=""
                            class="mbus-icon-m"
                            v-if="user.email != undefined"
                          />
                          <label style="background-color: #0b7fd4; padding: 3px 5px" class="memLabel-m" v-else>스탠다드</label>
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.sMinPrice * (pdt.dcForStd / 100)) }}원~</b>
                        </div>
                        <img
                          src="../assets/img/pBus.png"
                          alt=""
                          class="mbus-icon-m"
                          v-if="user.email != undefined"
                        />
                        <label style="background-color: #ffce00; padding: 3px 5px" class="memLabel-m" v-else>프리미엄</label>
                        <b class="mx-1 price-m" v-if=" pdt.sMinPrice * 1 - pdt.sMinPrice * (pdt.maxDC_P / 100) == 0"> 0원! (연간무제한)</b>
                        <b class="mx-1 price-m" v-else> {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.sMinPrice * (pdt.maxDC_P / 100)) }}원~</b>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="product-list mt-2" v-else>
          <!-- <div v-if="this.productListForSales_grFin.length > 0" key="product-list"> -->
          <div key="product-list">
            <div class="row" :key="i" v-for="(pdt, i) in this.productListForSales_grFin">
              <div class="col-4 mt-4">
                <img
                  v-if="pdt.imgName != 'noImage'"
                  :src="`/download/${pdt.pdtCode}/${pdt.imgName}`"
                  class="img-fluid pdt-img"
                  style="width: 125px; height: 124px; border-radius: 8%; cursor: pointer;"
                  alt="..."
                  @click="goToDetail(pdt.pdtCode, pdt.salesRoute)"
                />
                <img
                  v-else
                  :src="`/download/noImage/noImage.jpg`"
                  class="img-fluid pdt-img"
                  style="width: 500px; border-radius: 10%"
                  alt="no image"
                />
              </div>
              <div class="col-8 mt-3" style="padding-left: 10px">
                <h6 class="product-title">{{ pdt.pdtNameKor }}</h6>
                <div class="product-container-m">
                  <span class="price">
                    <div v-if="this.user.memLevel === 'P'">
                      <div v-if="pdt.dispPrice === 'mem'">
                        <div v-if="pdt.sMinPrice === pdt.memP">
                          <img
                            src="../assets/img/pBus.png"
                            alt=""
                            class="mbus-icon-m"
                          />
                          <b class="mx-1 price-m"> 0원! (연간무제한)</b>
                        </div>
                        <div v-else>
                          <img
                            src="../assets/img/pBus.png"
                            alt=""
                            class="mbus-icon-m"
                          />
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice) }}원~</b>
                          <small style="font-size: 0.8rem" v-if="pdt.memP * 1 > 0">(결제 후,</small>
                          <b class="mx-1 price-m" style="color: #ffce00; font-size: 0.9rem" v-if="pdt.memP * 1 > 0"> {{ getCurrencyFormat(pdt.memP) }}</b>
                          <small style="font-size: 0.8rem" v-if="pdt.memP * 1 > 0">원 환급)</small>
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="pdt.promoStatus === 'Y'">
                          <img
                            src="../assets/img/pBus.png"
                            alt=""
                            class="mbus-icon-m"
                          />
                          <b class="text-muted text-decoration-line-through mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice) }}</b>
                          <small><i class="bi bi-caret-right-fill"></i></small>
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                        <div v-else-if="pdt.promoStatus === 'N'">
                          <img
                            src="../assets/img/pBus.png"
                            alt=""
                            class="mbus-icon-m"
                          />
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                        <div v-else-if="pdt.promoStatus === 'E'">
                          <img
                            src="../assets/img/pBus.png"
                            alt=""
                            class="mbus-icon-m"
                          />
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                      </div>
                    </div>

                    <div v-else-if="this.user.memLevel === 'S'">
                      <div v-if="pdt.dispPrice === 'mem'">
                        <div v-if="pdt.dcForStd > 0">
                          <img
                            src="../assets/img/sBus.png"
                            alt=""
                            class="mbus-icon-m"
                          />
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice - pdt.sMinPrice * (pdt.dcForStd / 100)) }}원~</b>
                        </div>
                        <div v-else>
                          <div v-if="pdt.sMinPrice === pdt.memS">
                            <img
                              src="../assets/img/sBus.png"
                              alt=""
                              class="mbus-icon-m"
                            />
                            <b class="mx-1 price-m"> 0원! (연간무제한)</b>
                          </div>
                          <div v-else>
                            <img
                              src="../assets/img/sBus.png"
                              alt=""
                              class="mbus-icon-m"
                            />
                            <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice) }}원~</b
                            >
                            <small style="font-size: 0.8rem" v-if="pdt.memS * 1 > 0">(결제 후, </small>
                            <b class="mx-1 price-m" style="color: slategrey; font-size: 0.9rem" v-if="pdt.memS * 1 > 0"> {{ getCurrencyFormat(pdt.memS) }}원~</b>
                            <small style="font-size: 0.8rem" v-if="pdt.memS * 1 > 0">원 환급)</small>
                          </div>
                        </div>
                        <div>
                          <img
                            src="../assets/img/pBus.png"
                            alt=""
                            class="mbus-icon-m"
                            v-if="user.email != undefined"
                          />
                          <b class="mx-1 price-m" v-if="pdt.sMinPrice === pdt.memP">0원! (연간무제한)</b>
                          <b class="mx-1 price-m" v-else > {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.sMinPrice * (pdt.maxDC_P / 100)) }}원~</b>
                        </div>
                      </div>

                      <div v-else>
                        <div v-if="pdt.promoStatus === 'Y'">
                          <b class="text-muted text-decoration-line-through mx-1 price-m" >{{ getCurrencyFormat(pdt.sMinPrice) }}</b>
                          <small><i class="bi bi-caret-right-fill"></i></small>
                          <b class="mx-1 price-m" > {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                        <div v-else-if="pdt.promoStatus === 'N'">
                          <b class="mx-1 price-m" > {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                        <div v-else-if="pdt.promoStatus === 'E'">
                          <b class="mx-1 price-m" > {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                      </div>
                    </div>

                    <!-- 로그인전 상품도 멤버십표시 -->
                    <div v-else>
                      <!-- 일반회원가격(default) -->
                      <div class="my-0" v-if="pdt.promoStatus === 'Y'">
                        <img
                          src="../assets/img/gBus.png"
                          alt=""
                          class="mbus-icon-m"
                          v-if="user.email != undefined"
                        />
                        <label style="background-color: darkgray; padding: 3px 5px" class="memLabel-m" v-else >일반회원</label>
                        <b class="text-muted text-decoration-line-through mx-1 price-m" > {{ getCurrencyFormat(pdt.sMinPrice) }}</b>
                        <small><i class="bi bi-caret-right-fill"></i></small>
                        <b class="mx-1 price-m" > {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                      </div>
                      <div class="my-0" v-else-if="pdt.promoStatus === 'E'">
                        <div>
                          <img
                            src="../assets/img/gBus.png"
                            alt=""
                            class="mbus-icon-m"
                            v-if="user.email != undefined"
                          />
                          <label style="background-color: darkgray; padding: 3px 5px" class="memLabel-m" v-else >일반회원</label> 
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                      </div>
                      <div class="my-0" v-else-if="pdt.promoStatus === 'N'">
                        <!-- 이외상품 스탠다드 할인율이 있는 케이스 -->
                        <div class="my-0" v-if="pdt.dcForMem_S > 0">
                          <img
                            src="../assets/img/sBus.png"
                            alt=""
                            class="mbus-icon-m"
                            v-if="user.email != undefined"
                          />
                          <label style="background-color: #0b7fd4; padding: 3px 5px" class="memLabel-m" v-else>스탠다드</label> 
                          <b class="mx-1 price-m" > {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                        <div class="my-0" v-else>
                          <img
                            src="../assets/img/gBus.png"
                            alt=""
                            class="mbus-icon-m"
                            v-if="user.email != undefined"
                          />
                          <label style="background-color: darkgray; padding: 3px 5px" class="memLabel-m" v-else>일반회원</label>
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.pPrice) }}원~</b>
                        </div>
                      </div>

                      <div
                        v-if="((pdt.memLevel_S == 'N' || pdt.memLevel_S == null) && (pdt.memLevel_P == 'N' || pdt.memLevel_P == null)) || pdt.nrFlag == 'Y' || pdt.vrFlag == 'Y'">
                        <div class="my-0" v-if="pdt.promoStatus === 'E'">
                          <div class="my-0">
                            <div>
                              <img
                                src="../assets/img/sBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel-m" v-else >스탠다드</label>
                              <b class="mx-1 price-m" > {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.price_dcForMem_S) }}원~</b>
                            </div>
                            <div class="my-0">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: #ffce00; padding: 3px 5px;" class="memLabel-m" v-else>프리미엄</label>
                              <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.price_dcForMem_P) }}원~</b>
                            </div>
                          </div>
                        </div>
                        <div class="my-0" v-else-if="pdt.promoStatus === 'N'">
                          <div>
                            <div class="my-0">
                              <img
                                src="../assets/img/sBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: #0b7fd4; padding: 3px 5px;" class="memLabel-m" v-else>스탠다드</label>
                              <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.price_dcForMem_S) }}원~</b>
                            </div>
                            <div class="my-0">
                              <img
                                src="../assets/img/pBus.png"
                                alt=""
                                class="mbus-icon-m"
                                v-if="user.email != undefined"
                              />
                              <label style="background-color: #ffce00; padding: 3px 5px;" class="memLabel-m" v-else>프리미엄</label>
                              <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.price_dcForMem_P) }}원~</b>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- 스탠다드멤버십 상품 -->
                      <div class="my-0" v-if="pdt.memLevel_S === 'Y' && (pdt.nrFlag === 'N' || pdt.nrFlag == null) && (pdt.vrFlag === 'N' || pdt.vrFlag == null)">
                        <img
                          src="../assets/img/sBus.png"
                          alt=""
                          class="mbus-icon-m"
                          v-if="user.email != undefined"
                        />
                        <label style="background-color: #0b7fd4; padding: 3px 5px" class="memLabel-m" v-else>스탠다드</label>
                        <b class="mx-1 price-m" v-if="pdt.sMinPrice * 1 - pdt.sMinPrice * (pdt.maxDC_S / 100) == 0"> 0원! (연간무제한)</b>
                        <b class="mx-1 price-m" v-else> {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.sMinPrice * (pdt.maxDC_S / 100)) }}원~</b>
                      </div>

                      <!-- 프리미엄멤버십 상품 -->
                      <div class="my-0" v-if="pdt.memLevel_P === 'Y' && (pdt.nrFlag === 'N' || pdt.nrFlag == null) && (pdt.vrFlag === 'N' || pdt.vrFlag == null)">
                        <div class="my-0" v-if="(pdt.memLevel_s == 'N' || pdt.memLevel_s == null) && pdt.dcForStd > 0">
                          <img
                            src="../assets/img/sBus.png"
                            alt=""
                            class="mbus-icon-m"
                            v-if="user.email != undefined"
                          />
                          <label style="background-color: #0b7fd4; padding: 3px 5px" class="memLabel-m" v-else>스탠다드</label>
                          <b class="mx-1 price-m"> {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.sMinPrice * (pdt.dcForStd / 100)) }}원~</b>
                        </div>
                        <img
                          src="../assets/img/pBus.png"
                          alt=""
                          class="mbus-icon-m"
                          v-if="user.email != undefined"
                        />
                        <label style="background-color: #ffce00; padding: 3px 5px" class="memLabel-m" v-else>프리미엄</label>
                        <b class="mx-1 price-m" v-if=" pdt.sMinPrice * 1 - pdt.sMinPrice * (pdt.maxDC_P / 100) == 0"> 0원! (연간무제한)</b>
                        <b class="mx-1 price-m" v-else> {{ getCurrencyFormat(pdt.sMinPrice * 1 - pdt.sMinPrice * (pdt.maxDC_P / 100)) }}원~</b>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Video Section -->
      <section class="video-section mx-auto main-left">
        <div class="video" id="ntabi-video" v-if="this.vBannerIdx == 0">
          <video muted autoplay loop>
            <source src="../assets/img/main/banner/mem_resize.mp4" type="video/mp4" />
          </video>
        </div>
        <div class="video" id="ntabi-video" v-if="this.vBannerIdx == 1">
          <video muted autoplay loop>
            <source src="../assets/img/main/banner/ndtAdLow.mp4" type="video/mp4" />
          </video>
        </div>
      </section>

      <!-- 모바일스마트스토어 라이브 -->
      <!-- <section id="liveSection-m" class="live-section-m">
        <div class="section-title">
          <div class="col-7">
            <img src="../assets/img/main/live_logo.png" style="width:40%; padding-left:5px;">
          </div>
          <div id="more">
            <a href="https://m.smartstore.naver.com/ntabi/lives?cp=1" target="_blank"><i class="bi bi-broadcast"></i> 전체보기</a>
          </div>
        </div>
        <div class="row mt-2">
          <div class="iframe-container-m">
            <iframe src="https://m.smartstore.naver.com/ntabi/lives?cp=1" frameborder="0" scrolling="yes"></iframe>
          </div>
        </div>
      </section> -->

      <section id="liveSection-m" class="live-section-m">
        <div class="section-title">
          <div class="col-7">
            <img
              src="../assets/img/main/live_logo.png"
              style="width: 50%;"
            />
          </div>
          <div id="more">
            <a
              href="https://m.smartstore.naver.com/ntabi/lives?cp=1"
              target="_blank"
              ><i class="bi bi-broadcast"></i> 전체보기</a
            >
          </div>
        </div>

        <div class="row ndayliveTable mt-2">
          <table>
            <tr>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardImg">
                  <a href="https://view.shoppinglive.naver.com/replays/1584363?fm=store&offFeature=contentLayer&sn=ltlim&swipe=%7B%22sortType%22%3A%22LATEST%22%2C%22pagerType%22%3A%22ALL%22%2C%22channelId%22%3A144805%2C%22referrerType%22%3A%22STORE%22%2C%22referrerKey%22%3A%22144805%22%7D&tr=ltlim" target="_blank">
                    <img src="../assets/img/ndayLiveThumbnail/오사카10.jpg" class="nliveThumb"/>
                  </a>
                </div>
              </td>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardImg">
                  <a href="https://view.shoppinglive.naver.com/replays/1550016?fm=store&offFeature=contentLayer&sn=ltlim&swipe=%7B%22sortType%22%3A%22LATEST%22%2C%22pagerType%22%3A%22ALL%22%2C%22channelId%22%3A144805%2C%22referrerType%22%3A%22STORE%22%2C%22referrerKey%22%3A%22144805%22%7D&tr=ltlim" target="_blank">
                    <img src="../assets/img/ndayLiveThumbnail/오타루11.jpg" class="nliveThumb"/>
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardText">
                  <p class="p1">
                    [즉시확정] 오사카 교토 청수사 금각사 아라시야마 일일버스투어 엔데이트립
                  </p>
                  <p class="p2 mt-0">
                    <span class="dcRate">10%</span> 49,000원
                  </p>
                </div>
              </td>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardText">
                  <p class="p1">
                    [즉시확정] 삿포로 비에이 후라노 일일버스투어 북해도 홋카이도 엔데이트립 쥰페이예약
                  </p>
                  <p class="p2 mt-0">
                    <span class="dcRate">54%</span> 59,000원
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardImg">
                  <a href="https://view.shoppinglive.naver.com/replays/1511618?fm=store&offFeature=contentLayer&sn=ltlim&swipe=%7B%22sortType%22%3A%22LATEST%22%2C%22pagerType%22%3A%22ALL%22%2C%22channelId%22%3A144805%2C%22referrerType%22%3A%22STORE%22%2C%22referrerKey%22%3A%22144805%22%7D&tr=ltlim" target="_blank">
                    <img src="../assets/img/ndayLiveThumbnail/교토라이브1.jpg" class="nliveThumb"/>
                  </a>
                </div>
              </td>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardImg">
                  <a href="https://view.shoppinglive.naver.com/replays/1501944?fm=store&offFeature=contentLayer&sn=ltlim&swipe=%7B%22sortType%22%3A%22LATEST%22%2C%22pagerType%22%3A%22ALL%22%2C%22channelId%22%3A144805%2C%22referrerType%22%3A%22STORE%22%2C%22referrerKey%22%3A%22144805%22%7D&tr=ltlim" target="_blank">
                    <img src="../assets/img/ndayLiveThumbnail/라이브2.jpg" class="nliveThumb"/>
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardText">
                  <p class="p1">
                    [즉시확정] 오사카 교토 청수사 금각사 아라시야마 일일버스투어 엔데이트립
                  </p>
                  <p class="p2 mt-0">
                    <span class="dcRate">10%</span> 49,000원
                  </p>
                </div>
              </td>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardText">
                  <p class="p1">
                    [즉시확정] 삿포로 비에이 후라노 일일버스투어 북해도 홋카이도 엔데이트립 쥰페이예약
                  </p>
                  <p class="p2 mt-0">
                    <span class="dcRate">54%</span> 59,000원
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardImg">
                  <a
                    href="https://view.shoppinglive.naver.com/replays/1424215?fm=store&offFeature=contentLayer&sn=ltlim&swipe=%7B%22sortType%22%3A%22LATEST%22%2C%22pagerType%22%3A%22ALL%22%2C%22channelId%22%3A144805%2C%22referrerType%22%3A%22STORE%22%2C%22referrerKey%22%3A%22144805%22%7D&tr=ltlim"
                    target="_blnak"
                  >
                    <img
                      src="../assets/img/ndayLiveThumbnail/라이브3.jpg"
                      class="nliveThumb"
                    />
                  </a>
                </div>
              </td>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardImg">
                  <a
                    href="https://view.shoppinglive.naver.com/replays/1402655?fm=store&offFeature=contentLayer&sn=ltlim&swipe=%7B%22sortType%22%3A%22LATEST%22%2C%22pagerType%22%3A%22ALL%22%2C%22channelId%22%3A144805%2C%22referrerType%22%3A%22STORE%22%2C%22referrerKey%22%3A%22144805%22%7D&tr=ltlim"
                    target="_blnak"
                  >
                    <img
                      src="../assets/img/ndayLiveThumbnail/라이브4.jpg"
                      class="nliveThumb"
                    />
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardText">
                  <p class="p1">
                    [1명출발] 위스키 향 가득 카발란 증류소 자오시 온천 셔틀
                    버스투어 대만 타이베이 출발
                  </p>
                  <p class="p2 mt-0">
                    <span class="dcRate">40%</span> 35,000원
                  </p>
                </div>
              </td>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardText">
                  <p class="p1">
                    [즉시확정] 삿포로 비에이 후라노 일일버스투어 북해도 홋카이도
                    엔데이트립 쥰페이예약
                  </p>
                  <p class="p2 mt-0">
                    <span class="dcRate">54%</span> 59,000원
                  </p>
                </div>
              </td>
            </tr>
            <!-- <tr>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardImg">
                  <a
                    href="https://view.shoppinglive.naver.com/replays/1371320?fm=store&offFeature=contentLayer&sn=ltlim&swipe=%7B%22sortType%22%3A%22LATEST%22%2C%22pagerType%22%3A%22ALL%22%2C%22channelId%22%3A144805%2C%22referrerType%22%3A%22STORE%22%2C%22referrerKey%22%3A%22144805%22%7D&tr=ltlim"
                    target="_blnak"
                  >
                    <img
                      src="../assets/img/ndayLiveThumbnail/라이브5.jpg"
                      class="nliveThumb"
                    />
                  </a>
                </div>
              </td>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardImg">
                  <a
                    href="https://view.shoppinglive.naver.com/replays/1365456?fm=store&offFeature=contentLayer&sn=ltlim&swipe=%7B%22sortType%22%3A%22LATEST%22%2C%22pagerType%22%3A%22ALL%22%2C%22channelId%22%3A144805%2C%22referrerType%22%3A%22STORE%22%2C%22referrerKey%22%3A%22144805%22%7D&tr=ltlim"
                    target="_blnak"
                  >
                    <img
                      src="../assets/img/ndayLiveThumbnail/라이브6.jpg"
                      class="nliveThumb"
                    />
                  </a>
                </div>
              </td>
            </tr>
            <tr>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardText">
                  <p class="p1">
                    [즉시확정] 삿포로 비에이 후라노 일일버스투어 북해도 홋카이도
                    엔데이트립 쥰페이예약
                  </p>
                  <p class="p2 mt-0">
                    <span class="dcRate">54%</span> 59,000원
                  </p>
                </div>
              </td>
              <td class="ndayliveThumb" style="width: 50%">
                <div class="cardText">
                  <p class="p1">
                    [온천입욕 특전] 도야호 노보리베츠온천 삿포로출발 버스투어
                    엔데이트립 한국인 가이드
                  </p>
                  <p class="p2 mt-0">
                    <span class="dcRate">23%</span> 99,000원
                  </p>
                </div>
              </td>
            </tr> -->
          </table>
        </div>
      </section>

      <section class="product-banner">
        <div class="border" style="height: 150px; border-radius: 10px">
          <img
            style="width: 100%; height: 150px; object-fit: fill"
            src="../assets/img/AppImg/pukuanferry2.png"
            alt=""
            @click="goLink2('page', 'yamaguchi', '', '')"
          />
        </div>
      </section>

      <section class="ntabi-recommend">
        <h2>
          <span style="color: darkgreen; font-weight: 800">엔타비</span>가 추천하는 여행
        </h2>
        <div class="scrolling-wrapper" ref="scrollingContent_m" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
          <div class="scrolling-content">
            <div class="ntabi-pdt" v-for="(product, i) in productListForSales_ntabi_mdChoice" :key="i">
              <div class="ntabi">
                <a class="text-decoration-none link-dark stretched-link" href="javascript: void(0)" @click="goToDetail(product.pdtCode, product.salesRoute)">
                  <img
                    v-if="product.imgName !== 'noImage'"
                    :src="`/download/${product.pdtCode}/${product.imgName}`"
                    class="img-fluid hover-zoom"
                    alt="..."
                  />
                  <img
                    v-else
                    src="/download/noImage/noImage.jpg"
                    class="img-fluid"
                    alt="No Image"
                  />
                  <span class="recommendation-badge outlined-badge">
                    <img
                      src="../assets/img/icons/ntb.png"
                      class="iconBadge"
                      style="width: 40px; height: auto"
                    />
                  </span>
                  <div class="ntabi-body">
                    <h5 class="ntabi-title">{{ product.arName }}</h5>
                    <p class="showPdtName">{{ product.pdtNameKor }}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="ndaytrip-recommend mt-2">
        <h2>
          <span style="color: darkblue; font-weight: 800">엔데이트립</span>이 추천하는 현지투어
        </h2>
        <div class="scrolling-wrapper" ref="scrollingContent_nday" @touchstart="onTouchStart_nday" @touchmove="onTouchMove_nday" @touchend="onTouchEnd_nday">
          <div class="scrolling-content">
            <div class="ntabi-pdt" v-for="(product, i) in productListForSales_ndaytrip_mdChoice" :key="i">
              <div class="ntabi">
                <a class="text-decoration-none link-dark stretched-link" href="javascript: void(0)" @click="goToDetail(product.pdtCode, product.salesRoute)">
                  <img
                    v-if="product.imgName !== 'noImage'"
                    :src="`/download/${product.pdtCode}/${product.imgName}`"
                    class="img-fluid hover-zoom"
                    alt="..."
                  />
                  <img
                    v-else
                    src="/download/noImage/noImage.jpg"
                    class="img-fluid"
                    alt="No Image"
                  />
                  <span class="recommendation-badge outlined-badge">
                    <img
                      src="../assets/img/icons/nday.png"
                      class="iconBadge"
                      style="width: 40px; height: auto"
                    />
                  </span>
                  <div class="ntabi-body">
                    <h5 class="ntabi-title">{{ product.arName }}</h5>
                    <p class="showPdtName">{{ product.pdtNameKor }}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="ndaytrip-recommend mt-2">
        <h2>
          일본여행에 유익한 <span style="color: darkred; font-weight: 800">패스모음</span>
        </h2>
        <div class="scrolling-wrapper" ref="scrollingContent_new" @touchstart="onTouchStart_new" @touchmove="onTouchMove_new" @touchend="onTouchEnd_new">
          <div class="scrolling-content">
            <div class="ntabi-pdt" v-for="(product, i) in productListForSales_new" :key="i">
              <div class="ntabi">
                <a
                  class="text-decoration-none link-dark stretched-link"
                  href="javascript: void(0)"
                  @click="goToDetail(product.pdtCode, product.salesRoute)"
                >
                  <img
                    v-if="product.imgName !== 'noImage'"
                    :src="`/download/${product.pdtCode}/${product.imgName}`"
                    class="img-fluid hover-zoom"
                    alt="..."
                  />
                  <img
                    v-else
                    src="/download/noImage/noImage.jpg"
                    class="img-fluid"
                    alt="No Image"
                  />
                  <span class="new-badge outlined-badge">
                    <img
                      src="../assets/img/icons/good.png"
                      class="iconBadge"
                      style="width: 35px; height: auto"
                    />
                  </span>
                  <div class="ntabi-body">
                    <h5 class="ntabi-title">{{ product.arName }}</h5>
                    <p class="showPdtName">{{ product.pdtNameKor }}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- <section class="ndaytrip-recommend mt-1">
        <h2>
          엔데이트립 <span style="color: darkred; font-weight: 800">땡처리 긴급특가</span>
        </h2>
        <div class="scrolling-wrapper" ref="scrollingContent_new" @touchstart="onTouchStart_new" @touchmove="onTouchMove_new" @touchend="onTouchEnd_new">
          <div class="scrolling-content">
            <div v-if="this.productListForSales_new.length > 0">
              <div class="ntabi-pdt" v-for="(product, i) in productListForSales_new" :key="i">
                <div class="ntabi">
                  <a class="text-decoration-none link-dark stretched-link" href="javascript: void(0)" @click="goToDetail(product.pdtCode, product.salesRoute)">
                    <img
                      v-if="product.imgName !== 'noImage'"
                      :src="`/download/${product.pdtCode}/${product.imgName}`"
                      class="img-fluid hover-zoom"
                      alt="..."
                    />
                    <img
                      v-else
                      src="/download/noImage/noImage.jpg"
                      class="img-fluid"
                      alt="No Image"
                    />
                    <span class="new-badge outlined-badge">
                      <img
                        src="../assets/img/icons/good.png"
                        class="iconBadge"
                        style="width: 35px; height: auto"
                      />
                    </span>
                    <div class="ntabi-body">
                      <h5 class="ntabi-title">{{ product.arName }}</h5>
                      <p class="showPdtName">{{ product.pdtNameKor }}</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            <div v-else>
              <p style="padding-left: 10px;">아쉬워요. 현재 모든 땡처리 상품이 마감되었습니다.</p>
            </div>
          </div>
        </div>
      </section> -->

      <!-- 이벤트와 공지사항 -->
      <section id="notice-m" class="notice-m; mt-5">
        <div class="section-title">
          <div class="col-8">
            <a href="/customerNotice">
              <h2>
                <span style="font-weight: 800; padding-left: 20px"
                  >이벤트 & 공지사항</span
                >
              </h2>
            </a>
          </div>
          <div id="more" style="font-size: 0.8rem">
            <div id="more">
              <a href="/customerNotice"
                ><i class="bi bi-box-arrow-up-right"></i> 더보기</a
              >
            </div>
          </div>
        </div>
        <div class="justify-content-center mt-3">
          <table class="w-100 text-center noticeTable-m">
            <tr>
              <td
                style="width: 380px; height: 400px; background-color: darkgray"
              >
                <div
                  id="carouselvarietyControls"
                  class="carousel slide notice-new"
                  data-bs-ride="carousel"
                  data-bs-interval="5000"
                >
                  <div class="carousel-inner">
                    <div
                      :class="`carousel-item ${i == '0' ? 'active' : ''}`"
                      :key="i"
                      v-for="(ev, i) in this.ingEvent"
                    >
                      <div v-if="ev.addImageFlag == 'Y'" class="img-container">
                        <div v-if="ev.ctCd === 'EV'">
                          <img
                            :src="`/download/notice/EV/${ev.noticeCode}.jpg`"
                            class="card-img-top leftImg"
                            v-if="ev.link != undefined"
                            @click="goToLink(ev.link)"
                            style="cursor: pointer"
                          />
                          <img
                            :src="`/download/notice/EV/${ev.noticeCode}.jpg`"
                            class="card-img-top leftImg"
                            v-else
                          />
                        </div>
                        <div v-else-if="ev.ctCd === 'PR'">
                          <img
                            :src="`/download/notice/PR/${ev.noticeCode}.jpg`"
                            class="card-img-top leftImg"
                            v-if="ev.link != undefined"
                            @click="goToLink(ev.link)"
                            style="cursor: pointer"
                          />
                          <img
                            :src="`/download/notice/PR/${ev.noticeCode}.jpg`"
                            class="card-img-top leftImg"
                            v-else
                          />
                        </div>
                      </div>
                      <div
                        v-else-if="ev.addVideoFlag == 'Y'"
                        class="video-container"
                      >
                        <div v-if="ev.ctCd === 'EV'">
                          <video
                            :src="`/download/notice/EV/${ev.noticeCode}.mp4`"
                            class="card-img-top leftImg"
                            v-if="ev.link != undefined"
                            @click="goToLink(ev.link)"
                            style="cursor: pointer"
                            muted
                            autoplay
                            loop
                          />
                          <video
                            :src="`/download/notice/EV/${ev.noticeCode}.mp4`"
                            class="card-img-top leftImg"
                            v-else
                            muted
                            autoplay
                            loop
                          />
                        </div>
                        <div v-if="ev.ctCd === 'PR'">
                          <video
                            :src="`/download/notice/PR/${ev.noticeCode}.mp4`"
                            class="card-img-top leftImg"
                            v-if="ev.link != undefined"
                            @click="goToLink(ev.link)"
                            style="cursor: pointer"
                            muted
                            autoplay
                            loop
                          />
                          <video
                            :src="`/download/notice/PR/${ev.noticeCode}.mp4`"
                            class="card-img-top leftImg"
                            v-else
                            muted
                            autoplay
                            loop
                          />
                        </div>
                      </div>
                      <div v-else class="evtext">
                        <img
                          src="../assets/img/noImage.jpg"
                          class="img-fluid leftImg"
                        />
                        <div class="evname">
                          {{ ev.nTitle }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselvarietyControls"
                    data-bs-slide="prev"
                  >
                    <div class="back">
                      <!-- <span class="carousel-control-prev-icon noticePrev" aria-hidden="true"></span> -->
                      <span class="noticePrev"
                        ><i class="bi bi-arrow-left-circle"></i
                      ></span>
                      <span class="visually-hidden">Previous</span>
                    </div>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselvarietyControls"
                    data-bs-slide="next"
                  >
                    <div class="back">
                      <!-- <span class="carousel-control-next-icon noticeNext" aria-hidden="true"></span> -->
                      <span class="noticeNext"
                        ><i class="bi bi-arrow-right-circle"></i
                      ></span>
                      <span class="visually-hidden">Next</span>
                    </div>
                  </button>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <!-- 공지관련 리스트 / 빠른메뉴 -->
        <div class="col-lg-12 my-4">
          <!-- 빠른메뉴 -->
          <hr style="color: #000; margin: 0 0 0.7rem" />
          <div class="container quick">
            <div class="row gx-2 text-center">
              <div class="col-3">
                <a href="/customerAsk">
                  <img src="../assets/img/new-test/m-ask.png" />
                  <p>1:1 문의</p>
                </a>
              </div>
              <div class="col-3">
                <a href="/requestQuotation">
                  <img src="../assets/img/new-test/m-group.png" />
                  <p>기업/단체 문의</p>
                </a>
              </div>
              <div class="col-3">
                <a href="/customerService">
                  <img src="../assets/img/new-test/m-question.png" />
                  <p>자주하는 질문</p>
                </a>
              </div>
              <div class="col-3">
                <img
                  src="../assets/img/new-test/m-point.png"
                  style="cursor: pointer"
                  @click="goLink('page', 'pointList_client', '', '')"
                />
                <p>포인트 안내</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import MainRtRemote from "../components/Main_rtRemote.vue";
import { recentView } from "../assets/js/recentView";
import { toRaw } from 'vue'

export default {
  components: {
    MainRtRemote,
  },
  data() {
    return {
      //PC 메인캐러샐 이미지 정보
      slides: [
        {
          image: require("@/assets/img/carousel/asso_jinair_pc.jpg"),
          imageTxt: "",
          alt: "Slide 0",
          type: "asso_jinair",
          link: "",
          pId: "",
          sRoute: "",
          text1: "<span style='font-size: 2.6rem;'>진에어와 함께하는<br>엔데이트립 버스투어</span>",
          text2: "Trip, better trip with <span style='color:greenyellow;'>JINAIR</span>",
        },
        // {
        //   image: require("@/assets/img/carousel/renew.jpg"),
        //   imageTxt: require("@/assets/img/carousel/renew_title.png"),
        //   alt: "Slide 0",
        //   type: "page",
        //   link: "rNevent",
        //   pId: "", 
        //   sRoute: "",
        //   text1: "2025년 새로운<br>출발",
        //   text2: "Brand Renewal",
        // },
        {
          image: require("@/assets/img/carousel/mem.jpg"),
          imageTxt: require("@/assets/img/carousel/mem_title.png"),
          alt: "Slide 1",
          type: "page",
          link: "membershippage",
          pId: "",
          sRoute: "",
          text1: "1년간 공짜여행을<br>즐기세요!",
          text2: "엔데이트립멤버십",
        },
        {
          image: require("@/assets/img/carousel/three.jpg"),
          imageTxt: require("@/assets/img/carousel/three_title.png"),
          alt: "Slide 2",
          type: "page",
          link: "threecourse",
          pId: "",
          sRoute: "",
          text1: "엔데이트립<br><span style='color: gold;'>3新</span> 코스",
          text2: "마루코마·다카치호·마쓰야마",
        },
        // {
        //   image: require("@/assets/img/carousel/setsetset.jpg"),
        //   imageTxt: require("@/assets/img/carousel/setsetset_title.png"),
        //   alt: "Slide 3",
        //   type: "page",
        //   link: "setsetset",
        //   pId: "",
        //   sRoute: "T",
        //   text1: "한 번에 세트로!<br>셑 셑 셑",
        //   text2: "두 코스를 하나로 세팅~",
        // },
        // {
        //   image: require("@/assets/img/carousel/marukoma.jpg"),
        //   imageTxt: require("@/assets/img/carousel/marukoma_title.png"),
        //   alt: "Slide 4",
        //   type: "page",
        //   link: "marukoma",
        //   pId: "",
        //   sRoute: "",
        //   text1: "마루코마온천과<br>노보리베츠<br>지옥계곡",
        //   text2: "따듯한 겨울을 즐기세요",
        // },
        {
          image: require("@/assets/img/carousel/yamaguchi.jpg"),
          imageTxt: require("@/assets/img/carousel/yamaguchi_title.png"),
          alt: "Slide 5",
          type: "page",
          link: "yamaguchi",
          pId: "",
          sRoute: "",
          text1: "뉴욕타임즈가<br>선정한 세계여행지",
          text2: "기타큐슈 야마구치 버스투어",
        },
      ],

      //모바일 탑캐러샐 이미지 정보
      topSlides: [
      {
          image: require("@/assets/img/AppImg/topCarousel/asso_jinair_mo.jpg"),
          alt: "Slide 0",
          type: "asso_jinair",
          link: "",
          pId: "",
          sRoute: "",
          text1: "2025년 새로운<br>출발",
          text2: "Brand Renewal",
        },
        // {
        //   image: require("@/assets/img/AppImg/topCarousel/rrnewbg2.jpg"),
        //   alt: "Slide 1",
        //   type: "page",
        //   link: "rNevent",
        //   pId: "",
        //   sRoute: "",
        //   text1: "2025년 새로운<br>출발",
        //   text2: "Brand Renewal",
        // },
        {
          image: require("@/assets/img/AppImg/topCarousel/newmember3.jpg"),
          alt: "Slide 1",
          type: "page",
          link: "membershippage",
          pId: "",
          sRoute: "",
          text1: "1년간 공짜여행을<br>즐기세요!",
          text2: "엔데이트립멤버십",
        },
        {
          image: require("@/assets/img/AppImg/topCarousel/threecourselol2.jpg"),
          alt: "Slide 2",
          type: "page",
          link: "threecourse",
          pId: "",
          sRoute: "",
          text1: "엔데이트립<br>3新 코스",
          text2: "마루코마·다카치호·마쓰야마",
        },
        // {
        //   image: require("@/assets/img/AppImg/topCarousel/setsetset2.jpg"),
        //   alt: "Slide 3",
        //   type: "page",
        //   link: "setsetset",
        //   pId: "",
        //   sRoute: "T",
        //   text1: "한 번에 세트로!<br>셑 셑 셑",
        //   text2: "저렴하게 투어를 즐겨봅시다",
        // },
        // {
        //   image: require("@/assets/img/AppImg/topCarousel/marukoma2.jpg"),
        //   alt: "Slide 4",
        //   type: "page",
        //   link: "marukoma",
        //   pId: "",
        //   sRoute: "",
        //   text1: "마루코마온천과<br>노보리베츠<br>지옥계곡",
        //   text2: "따듯한 겨울을 즐기세요",
        // },
        // {
        //   image: require("@/assets/img/AppImg/topCarousel/ddd2.jpg"),
        //   alt: "Slide 6",
        //   type: "product",
        //   link: "",
        //   pId: "L-2024125-1706174265647",
        //   sRoute: "T",
        //   text1: "대만<br>자오시 & 카발란",
        //   text2: "셔틀버스 1호선",
        // },
        // {
        //   image: require("@/assets/img/AppImg/topCarousel/bull2.jpg"),
        //   alt: "Slide 7",
        //   type: "page",
        //   link: "bullfight",
        //   pId: "",
        //   sRoute: "",
        //   text1: "오키나와 우루마시 鬪牛",
        //   text2: "鬪牛 Oneday",
        // },
        // {
        //   image: require("@/assets/img/AppImg/topCarousel/bloggertrip2.jpg"),
        //   alt: "Slide 8",
        //   type: "page",
        //   link: "bloggertrip",
        //   pId: "",
        //   sRoute: "",
        //   text1: "인플루언서들과<br>함께한",
        //   text2: "일본 소도시 렌터카 추천여행",
        // },
        {
          image: require("@/assets/img/AppImg/topCarousel/yamaguchi2.jpg"),
          alt: "Slide 5",
          type: "page",
          link: "yamaguchi",
          pId: "",
          sRoute: "",
          text1: "뉴욕타임즈가<br>선정한 세계여행지",
          text2: "기타큐슈 야마구치 버스투어",
        },
        // {
        //   image: require("@/assets/img/AppImg/topCarousel/kinmen2.jpg"),
        //   alt: "Slide 10",
        //   type: "page",
        //   link: "kinmen",
        //   pId: "",
        //   sRoute: "",
        //   text1: "금문도의 역사와<br>고량주를 동시에<br>맛보다",
        //   text2: "대만 금문도 역사투어 1박2일",
        // },
      ],

      //모바일 미들캐러샐 이미지 정보
      midSlides: [
        {
          image: require("@/assets/img/AppImg/midCarousel/midBanner01.jpg"),
          alt: "Slide 0",
          type: "product",
          link: "",
          pId: "L-2023220-1676865317720",
          sRoute: "L",
        },
        {
          image: require("@/assets/img/AppImg/midCarousel/midBanner02.jpg"),
          alt: "Slide 1",
          type: "product",
          link: "",
          pId: "L-2023221-1676943535719",
          sRoute: "L",
        },
        {
          image: require("@/assets/img/AppImg/midCarousel/midBanner03.jpg"),
          alt: "Slide 2",
          type: "product",
          link: "",
          pId: "L-2023317-1679040615952",
          sRoute: "L",
        },
      ],


      // 모바일 그루핑 테마 캐러셀 이미지 정보
      grSlides: [
        {
          image: require("@/assets/img/AppImg/grCarousel/grPdt01.jpg"),
          alt: "Slide 0",
          grNo: "1",
          text1: "N번째 북해도",
          text2: "Fot Hot Winter!",
        },
        {
          image: require("@/assets/img/AppImg/grCarousel/grPdt02.jpg"),
          alt: "Slide 2",
          grNo: "2",
          text1: "벛꽃구경과",
          text2: "Fot Family Trip!",
        },
        {
          image: require("@/assets/img/AppImg/grCarousel/grPdt03.jpg"),
          alt: "Slide 3",
          grNo: "3",
          text1: "엔데이트립맴버십",
          text2: "Fot Sweet Couple♡",
        },
      ],

      grIndex: 0,
      currentSlideIndex: 0, //모바일 그루핑캐러셀의 현재화면인덱스
      productListForSales_grFin: [],
      productListForSales_gr: [], //그루핑상품 리스트

      ////PC 그루핑 상품 스크롤베너
      currentIndex: 0,
      rotationInterval: null,
      isPaused: false,
      hoveredIndex: null, //마우스가 올라간 배너이미지 인덱스

      // 상품상하스크롤처리
      currentPage: 1,
      itemsPerPage: 4, // 페이지당 표시할 상품 수(카테고리 연동상품)
      animationDirection: "slide-up", // 기본 애니메이션 방향

      productListForSales: [], //판매용 상품리스트

      productListForSales_ntabi: [],
      productListForSales_ndaytrip: [],
      productListForSales_ndaytrip_main: [],
      productListForSales_nthere: [],
      productListForSales_variety: [],
      productListForSales_best: [],            //베스트아이템
      productListForSales_ship: [],            //선박상품
      productListForSales_pass: [],            //티켓패스상품

      sellerFlag: "", //판매처(nt / nd)

      selectedPdtInfo: [],                     //엔데트 선택된 상품정보
      productListForSales_mid: [],             //엔데트 상품정보
      selFlag: "N",                            //엔데트 상품선택플래그

      //상품 좌측리스트
      ////엔데트
      pCode: "",                               //상품코드
      pName: "",                               //상품명
      pArName: "",                             //지역명
      pImgName: "",
      badgeF1: "",                             //상품1뱃지 여부
      badgeF2: "",                             //상품2뱃지 여부
      badge1: "",                              //상품1뱃지
      badge2: "",                              //상품2뱃지

      recentFlag: "Y",                        //최근구경

      b2bTag: "",
      b2cTag: "",

      bustourArea: [],                        //선택가능한 버스투어지역
      ntToAr: {},                             //버스투어지역

      transportList: [],                      //선택가능한 교통편정보
      spToDt: {},                             //선박운항지

      tktPassArea: [],                        //선택가능한 티켓패스
      tpNtToAr: {},                           //티켓패스 이용지역

      recentViewInfo_m: [],                   //최근 본 상품정보(Mobile)

      touchStartX: 0,
      touchEndX: 0,
      scrollStartX: 0,

      productListForSales_ntabi_mdChoice: [],       //엔타비추천상품
      productListForSales_ndaytrip_mdChoice: [],    //엔데트추천상품
      productListForSales_ship_mdChoice: [],        //선박추천상품
      productListForSales_new: [],                  //티켓패스상품(신규오픈에서 변경됨) 

      noticeList: [], //공지사항 리스트
      showNoticeFlag: "N",
      nContent: "",
      ingEvent: [], //진행 중인 이벤트

      fixedPR: [], //홍보고정

      isTouched: false, //터치처리

      isOpenNo: 0,        //오픈회수

      more_nt: "N",
      more_nd: "",
      more_nr: "",
      more_vr: "",
      more_be: "N", //베스트상품

      memLv: "", //멤버십등급(S,P)
      memDC: [0, 0, 0], //멤버십환급율(성인,소인,유아)

      dispPrice: [], //가격표시방법(mem / promo)

      promoPrice: 0,          //프로모션할인액
      price_memP: 0,          //프리미엄 환급액
      price_memS: 0,          //스탠다드 환급액
      price_dcForStd: 0,      //프리미엄전용을 스탠다드가 구매할 경우 할인액
      price_dcForMem_P: 0,    //일반상품을 프리미엄이 구매시 할인액
      price_dcForMem_S: 0,    //일반상품을 스탠다드가 구매시 할인액

      vBannerIdx: 1,         //비디오배너 인덱스
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },

    //모바일 탑캐러셀배너용
    visibleSlides() {
      const slides = this.slides;
      const prev = (this.currentIndex - 1 + slides.length) % slides.length;
      const next = (this.currentIndex + 1) % slides.length;
      return [slides[prev], slides[this.currentIndex], slides[next]];
    },

    //상품스크롤용
    // pagedProductList() {
    //   if (!this.mobileDisplay) 
    //   {
    //     const start = (this.currentPage - 1) * this.itemsPerPage;
    //     const end = start + this.itemsPerPage;
    //     // console.log("productListForSales_mid::::::", this.productListForSales_mid)
    //     return this.productListForSales_mid.slice(start, end);
    //   }
    //  // 모바일에서는 그루핑 상품을 보여준다
    //   else 
    //   {
    //     const start = (this.currentPage - 1) * this.itemsPerPage;
    //     const end = start + this.itemsPerPage;
    //     return this.productListForSales_grFin.slice(start, end);
    //   }
    // },

    pagedProductList() {
      if (!this.mobileDisplay) 
      {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        // return this.productListForSales_mid.slice(start, end)
        return {
          mid: this.productListForSales_mid.slice(start, end),
          // mid_vr: this.productListForSales_mid_vr.slice(start, end)
        }
      }
     // 모바일에서는 그루핑 상품을 보여준다
      else 
      {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        return this.productListForSales_grFin.slice(start, end);
      }
    },

    totalPages() {
      if (!this.mobileDisplay) 
      {
        return {
          mid: Math.ceil(this.productListForSales_mid.length / this.itemsPerPage),
          // mid_vr: Math.ceil(this.productListForSales_mid_vr.length / this.itemsPerPage)
        }
      } 
      else 
      {
        return Math.ceil(
          this.productListForSales_grFin.length / this.itemsPerPage
        );
      }
    },

    // totalPages() {
    //   if (!this.mobileDisplay) 
    //   {
    //     return Math.ceil(
    //       this.productListForSales_mid.length / this.itemsPerPage
    //     );
    //   } 
    //   else 
    //   {
    //     return Math.ceil(
    //       this.productListForSales_grFin.length / this.itemsPerPage
    //     );
    //   }
    // },


    //pc그뤂핑 상품배너
    trackStyle() {
      return {
        transform: `translateX(-${this.activeIndex * 33.33}%)`,
      };
    },

    activeImageIndex() {
      return this.currentIndex;
    },

    // 모바일그루핑 활성화 배너인덱스
    activeGrIndex() {
      return this.currentSlideIndex;
    },

    //그루핑에서 호버된 인덱스
    activeIndex() {
      return this.hoveredIndex !== null ? this.hoveredIndex : this.currentIndex;
    },
  },

  created() {
    this.height = 700;

    // 유저디바이스체크 (모바일처리용)
    if (this.$isMobile()) {
      this.mobileDisplay = true;
    } else {
      this.mobileDisplay = false;
    }

    // path에서 사용된 productId
    this.user = this.$store.state.user;
    this.conEmail = this.user.email;
    //console.log("user:", this.user, "/", this.memberId, "/", this.conEmail);

    this.getProductList();
    this.getBustourArea();
    this.getTransportInfo();
    this.getNoticeList("Y"); //공지&이벤트 리스트
  },

  mounted() {
    // 메인배너 이미지 엘리먼트 찾기(모바일X)
    if (!this.mobileDisplay) 
    {
      document.documentElement.style.setProperty("--topBanner-height", "700px");

      this.updateTopBannerHeight();
      window.addEventListener("resize", this.updateTopBannerHeight);

      window.addEventListener("scroll", this.handleScroll);

      //this.showNdt("ND"); //페이지 초기오픈

      //그루핑상품
      this.startRotation();
    } 
    else if (this.mobileDisplay) 
    {
      this.getProductList("X", "", "NT"); //엔타비가 추천하는 상품추출용

      const myCarousel = document.getElementById("carouselExampleControls_gr");
      myCarousel.addEventListener("slide.bs.carousel", (event) => {
        this.currentSlideIndex = event.to;

        // console.log("currentSlideIndex:::::::", this.currentSlideIndex)
        if (this.currentSlideIndex == 0) 
        {
          this.productListForSales_grFin = this.productListForSales_gr;
        } 
        else if (this.currentSlideIndex == 1) 
        {
          this.productListForSales_grFin = this.productListForSales_gr2;
        } 
        else if (this.currentSlideIndex == 2) 
        {
          this.productListForSales_grFin = this.productListForSales_gr3;
        } 
        else 
        {
          this.productListForSales_grFin = []
        }
      });
      //console.log("productListForSales_grFin:>>>>>", this.productListForSales_grFin)

      //모바일 탑캐러셀
      this.startTimer();
    }

    if (this.conEmail != undefined) {
      this.getRecentViewProduct(this.conEmail);
    }

    this.getIdxNo(); //비디오배너 인덱스 랜덤으로 선택
  },

  beforeUnmount() {
    if (!this.mobileDisplay) 
    {
      window.removeEventListener("resize", this.updateTopBannerHeight);
      window.removeEventListener("scroll", this.handleScroll);
      clearInterval(this.rotationInterval);
    } 
    else if (this.mobileDisplay) 
    {
      //모바일 탑캐러샐
      this.stopTimer();
    }
  },
  methods: {

    //동영상 배너선택
    getIdxNo(){
      let vBannerIdx =  Math.floor(Math.random() * 2);

      this.vBannerIdx = vBannerIdx == 0 ? 1 : 0;
    },

    //PC 그루핑상품
    startRotation() {
      this.rotationInterval = setInterval(() => {
        if (!this.isPaused) {
          this.currentIndex = (this.currentIndex + 1) % this.grSlides.length;
        }
      }, 10000);
    },

    //배너이미지에 마우스 호버시 호출
    handleMouseOver(index) {
      this.hoveredIndex = index;
      this.currentIndex = index;
      this.pauseRotation();
    },

    handleMouseLeave() {
      this.hoveredIndex = null;
      this.resumeRotation();
    },

    pauseRotation() {
      this.isPaused = true;
    },
    resumeRotation() {
      this.isPaused = false;
    },

    //모바일 탑캐러샐
    startTimer() {
      this.timer = setInterval(this.nextSlide, 5000);
    },
    stopTimer() {
      clearInterval(this.timer);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
    },
    prevSlide() {
      this.currentIndex =
        (this.currentIndex - 1 + this.slides.length) % this.slides.length;
    },

    updateTopBannerHeight() {
      this.height = this.$refs.mainBanner.offsetHeight;
      document.documentElement.style.setProperty(
        "--topBanner-height",
        `${this.height}px`
      );

      //console.log("height:>>>>", this.height);
    },

    async getNoticeList(param) {
      let noticeList_tmp = await this.$api("/api/noticeList", {});
      this.noticeList = noticeList_tmp.filter((item) => {
        return item.status == param;
      });

      //console.log("noticeList_tmp:>>>>>>>> ", noticeList_tmp);

      let ingEvent = await this.$api("/api/ingEventInfo", {});

      if (ingEvent.length > 0) {
        this.ingEvent = ingEvent;
      }
      //console.log("ingEvent:", this.ingEvent);

      this.fixedPR = this.ingEvent.filter((item) => {
        return item.fixedPRFlag === "Y";
      });
      //console.log("fixedPR:", this.fixedPR);
    },

    goToLink(link) {
      window.open(link, "_blank");
    },

    //페이지이동
    goToPage(path) {
      switch (path) {
        case "JShip":
          this.$router.push({ path: "/shipTour" });
          break;

        case "MShip":
          this.$router.push({ path: "/membershipPage" });
          break;

        case "TkPass":
          this.$router.push({ path: "/tktPass" });
          break;

        case "BsTour":
          this.$router.push({ path: "/bustour" });
          break;

        case "NBus":
          this.$router.push({ path: "/nbus" });
          break;

        case "NtVr":
          // this.$router.push({ path: "/subList", query: { nt: "JP"} });
          this.$router.push({ path: "/exhibitionEvent"});
          break;
      }
    },

    //선택가능한 버스투어 지역정보
    async getBustourArea() {
      let bustourArea = await this.$api("/api/getBustourArea", {});

      let ntToAr = {};

      if (bustourArea.length > 0) {
        this.bustourArea = bustourArea;
        //국가별 지역으로 정리
        bustourArea.forEach((item) => {
          if (!ntToAr[item.ntCd]) {
            ntToAr[item.ntCd] = [];
          }
          ntToAr[item.ntCd].push({
            ntCd: item.ntCd,
            arCd: item.arCd,
            NT: item.NT,
            AR: item.AR,
          });
        });
      }
      this.ntToAr = ntToAr;
      //console.log("ntToAr:", this.ntToAr);
    },

    //선택가능한 티켓패스 지역정보
    async getTktPassArea() {
      let salesRoute = "E";
      let tktPassArea = await this.$api("/api/getTktPassArea", {
        param: [salesRoute],
      });

      let tkNtToAr = {};

      if (tktPassArea.length > 0) {
        this.tktPassArea = tktPassArea;
        //국가별 지역으로 정리
        tktPassArea.forEach((item) => {
          if (!tkNtToAr[item.ntCd]) {
            tkNtToAr[item.ntCd] = [];
          }
          tkNtToAr[item.ntCd].push({
            ntCd: item.ntCd,
            arCd: item.arCd,
            NT: item.NT,
            AR: item.AR,
          });
        });
      }
      this.tkNtToAr = tkNtToAr;
      //console.log("tkNtToAr:", this.tkNtToAr);
    },

    //선택가능한 선박
    async getTransportInfo() {
      //선박상품용 변수
      let ctCode = "SHP";
      let uFlag = "Y";
      let rtCode = "JP";

      let transportList = await this.$api("/api/transportList", {
        param: [ctCode, uFlag, rtCode],
      });

      let spToDt = {};

      if (transportList.length > 0) {
        //console.log("transportList:", transportList)
        this.transportList = transportList;
        //운항지별로 정리
        transportList.forEach((item) => {
          if (!spToDt[item.dtCode]) {
            spToDt[item.dtCode] = [];
          }
          spToDt[item.dtCode].push({
            dtCd: item.dtCode, //운항지코드
            trCd: item.transCode, //선박코드
            DT: item.destName, //운항지명
            TR: item.transName, //선박명
          });
        });
      }
      this.spToDt = spToDt;
      //console.log("spToDt:", this.spToDt)
    },

    // goToBustour() {
    //   this.$router.push({ path: "/bustour", query: {} });
    // },

    // 최근 본 상품
    async getRecentViewProduct(mId) {
      let recentViewInfo = await this.$api("/api/proc_viewRecentProduct", {
        param: [mId],
      });

      //console.log("recentViewInfo:", recentViewInfo)

      if (recentViewInfo.length > 0) {
        this.recentViewInfo = recentViewInfo[0];
        this.recentViewInfo_m = recentViewInfo[0].slice(0, 2);
      }
    },

    // //중간상품이미지 상하스크롤처리
    // nextPage() {
    //   if (this.currentPage < this.totalPages) {
    //     this.currentPage++;
    //   }
    // },
    // prevPage() {
    //   if (this.currentPage > 1) {
    //     this.currentPage--;
    //   }
    // },

    changePage(direction) {
      if (direction === "prev" && this.currentPage > 1) 
      {
        this.animationDirection = "slide-down"; // 이전 버튼: 아래로 이동
        this.currentPage--;
      } 
      else if (direction === "next" && (this.currentPage < this.totalPages.mid || this.currentPage < this.totalPages.mid_vr)) 
      {
        this.animationDirection = "slide-up"; // 다음 버튼: 위로 이동
        this.currentPage++;
      }
    },

    //엔타비 마우스오버
    setHoveredProduct(pCode, pName, pArName, pImgName, badgeF1, badge1, badgeF2, badge2) {   //pCat: 상품카테고리(ND,VR,SH)
      this.selFlag = "Y"
      this.pCode = pCode;
      this.pName = pName;
      this.pArName = pArName;
      this.pImgName = pImgName;
      this.badgeF1 = badgeF1 ?? "";
      this.badge1 = (this.badgeF1 == "N" || this.badgeF1 == "" || !this.badgeF1) ? null : badge1;
      this.badgeF2 = badgeF2 ?? "";
      this.badge2 = (this.badgeF2 == "N" || this.badgeF2 == "" || !this.badgeF2) ? null : badge2;

      let selectedPdtInfo = this.productListForSales_mid.filter((item) => {
        return item.pdtCode === pCode
      });

      //proxy해제
      this.selectedPdtInfo = toRaw(selectedPdtInfo[0]);
      
      // console.log("selectedPdtInfo:", this.selectedPdtInfo, "badge:", this.badge1, this.badge2)
    },

    handleScroll() {
      const rtRemoteContainer = this.$refs.rtRemoteContainer;
      const bannerEnd = document.getElementById("bannerEnd");

      if (rtRemoteContainer && bannerEnd) {
        const bannerEndPosition =
          bannerEnd.getBoundingClientRect().top + window.scrollY;
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        const containerHeight = rtRemoteContainer.offsetHeight;

        if (scrollPosition + windowHeight > bannerEndPosition) {
          const newBottomPosition = Math.max(
            30,
            bannerEndPosition - scrollPosition - containerHeight
          );
          rtRemoteContainer.style.bottom = `${newBottomPosition}px`;
          rtRemoteContainer.style.position = "absolute";
        } else {
          rtRemoteContainer.style.bottom = "30px";
          rtRemoteContainer.style.position = "fixed";
        }
      }
    },

    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },

    toB2CLoginPage() {
      location.href = "/b2cLogin";
    },

    //to-do: 일단 통합로그아웃(향후 분기하여 네이버 전용로그아웃 구성할 것-20220616)
    toB2CLogout() {
      window.Kakao.Auth.logout(() => {
        this.$store.commit("user", {}); // 로그아웃->카카오유저 정보를 빈값으로 대체

        window.sessionStorage.clear(); // 로그아웃->세션스토리지를 모두 삭제
        window.localStorage.clear();
        //console.log(response);
      });
      this.$api("/api/logoutB2C", {});

      var swal = require("sweetalert2");
      swal.fire({
        text: "안전하게 로그아웃되었습니다.",
        icon: "success",
      });

      //로그아웃 후, 리로드를 시킨 후 메인으로 보낸다
      //location.href = "/"
      localStorage.setItem("reloadCheck", "true");
      this.$router.push({ path: "/" }); // 로그아웃->메인페이지로
    },

    toMembership() {
      location.href = "/membership";
    },

    kakaoLogin() {
      window.Kakao.Auth.login({
        scope: "profile, account_email, gender, birthday",
        success: this.getProfile,
      });
    },

    goLink(type, link, pId, sR) {
      if (type === "page") 
      {
        this.$router.push({ path: `/${link}` });
      } 
      else if (type === "product") 
      {
        this.$router.push({
          path: "/detail",
          query: { productId: pId, salesRoute: sR },
        });
      } 
      else if (type === "Ntabi") 
      {
        this.$router.push({ path: "/subList", query: { nt: "JP" } });
      }
      else if (type === "asso_jinair") 
      {
        this.$router.push({ path: "/bustour_asso", query: { assoFlag: "lj" } });
      }
    },

    // 화면해상도 1300px 이하에서 작동
    goLink2(type, link, pId, sR) {
      if (window.innerWidth <= 1300) 
      {
        if (type === "page") 
        {
          this.$router.push({ path: `/${link}` });
        } 
        else if (type === "product") 
        {
          this.$router.push({
            path: "/detail",
            query: { productId: pId, salesRoute: sR },
          });
        } 
        else if (type === "Ntabi") 
        {
          this.$router.push({ path: "/subList", query: { nt: "JP" } });
        }
        else if (type === "asso_jinair") 
        {
          this.$router.push({ path: "/bustour_asso", query: { assoFlag: "lj" } });
        }
      }
    },

    toMyPage() {
      location.href = "/myPage";
    },

    //기업/단체문의
    toReqQuotation() {
      location.href = "/requestQuotation";
    },

    async goToDetail(productId, salesRoute) {
      if (this.user.email != undefined) {
        await recentView(this.$api, this.user.email, productId);
      }
      this.$router.push({
        path: "/detail",
        query: { productId: productId, salesRoute: salesRoute },
      });
    },

    //상단 메뉴카테고리 선택 대응
    // showNdt(flag) {
    //   this.selFlag = "N"; //상품선택플래그
    //   this.currentPage = 1; //1페이지로 이동
    //   this.sellerFlag = this.sellerFlag == "" ? "ND" : flag;
    //   this.getProductList("", "", this.sellerFlag);
    // },

    scrollToElement(elementId) {
      const element = document.getElementById(elementId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },

    async getProductList(p1, p2, p3) {    //p1(X:더보기) p2() p3(nt nd nr be)
      console.log(p3)
      //멤버십할인정보체크
      if (this.user.memNo > 999) {
        this.memLv = this.user.memLevel; //멤버십등급
      }
      //기본보기
      let ntCd = "";
      let sRoute = "";
      let sMinPrice = 0; //최소상품가
      let sMargin = 0;
      ntCd = p1 == undefined || p1 == "" || p1 == "X" ? "%" : p1;
      sRoute = p2 == undefined || p2 == "" ? "%" : p2;
      p3 = p3 == undefined || p3 == "" ? "ND" : p3;

      //console.log("params:", ntCd,sRoute)
      let productListForSales_tmp = await this.$api(
        "/api/productListForSales",
        { param: [ntCd, sRoute] }
      );
      //console.log("productListForSales_tmp:",productListForSales_tmp)

      let i = 0;
      while (i < productListForSales_tmp.length) {
        if (productListForSales_tmp[i].marginMethod === "P") 
        {
          sMargin =
            productListForSales_tmp[i].minPrice *
            (productListForSales_tmp[i].basicMargin / 100);
        } 
        else 
        {
          sMargin = productListForSales_tmp[i].basicMargin;
        }
        sMinPrice = productListForSales_tmp[i].minPrice + sMargin;
        productListForSales_tmp[i].sMinPrice = sMinPrice;
        i++;
      }

      this.productListForSales = productListForSales_tmp.filter((item) => {
        return item.assoFlag == null || item.assoFlag == "";                  //연계상품이 아닌 상품만+추천이 아닌 상품도 포함
      });

      // //금월추천
      // this.productListForSales_new = productListForSales_tmp.filter((item) => {
      //   return (item.newFlag === "Y" && new Date(item.newDate).getMonth() === new Date().getMonth() && new Date(item.newDate).getFullYear() === new Date().getFullYear());
      // });
      // //console.log("productListForSales_new:>>>", this.productListForSales_new)

      // if (p3 == "ND") 
      // {
      //   //엔데트상품
      //   let productListForSales_ndaytrip = this.productListForSales.filter((item) => {
      //     return item.sellerCode_nd === "Y";
      //   });

      //   this.productListForSales_ndaytrip = productListForSales_ndaytrip;
        
      //   this.productListForSales_ndaytrip_main = this.productListForSales_ndaytrip.filter((item) => {
      //     return item.pdtCode != "L-2023921-1695276228098";
      //   });
        
      //   this.productListForSales_mid = this.productListForSales_ndaytrip_main;

      //   this.productListForSales_ndaytrip_mdChoice = this.productListForSales_ndaytrip_main.filter((item) => {
      //     return item.mdChoice === "Y";
      //   });

      //   //console.log("productListForSales_ndaytrip_mdChoice:", this.productListForSales_ndaytrip_mdChoice)
      // } 
      // else if (p3 == "NR") 
      // {
      //   //엔데어
      //   let productListForSales_nthere = this.productListForSales.filter(
      //     (item) => {
      //       return item.nrFlag === "Y";
      //     }
      //   );
      //   this.productListForSales_nthere = productListForSales_nthere;

      //   this.productListForSales_mid = this.productListForSales_nthere;
      //   //console.log("productListForSales_nthere:", this.productListForSales_mid)
      // } 
      // else if (p3 == "VR") 
      // {
      //   //버라이어티
      //   let productListForSales_variety = this.productListForSales.filter(
      //     (item) => {
      //       return item.vrFlag === "Y";
      //     }
      //   );
      //   this.productListForSales_variety = productListForSales_variety;

      //   this.productListForSales_mid = this.productListForSales_variety;
      //   //console.log("productListForSales_variety:", this.productListForSales_mid)

      // //리뉴얼 후 버라이어티팩=> 엔타비 + 버라이어티 통합으로 변경(2025-02)
      // } 
      // else if (p3 == "NT") 
      // {
      //   //엔타비
      //   let productListForSales_ntabi = this.productListForSales.filter((item) => {
      //     return ((item.sellerCode_nt === "Y" || item.vrFlag === "Y") && item.salesRoute != "F" && item.salesRoute != "E");   //선박과 기타상품 제외
      //   });
      //   this.productListForSales_ntabi = productListForSales_ntabi;


      //   // NT 추천
      //   this.productListForSales_ntabi_mdChoice = this.productListForSales_ntabi.filter((item) => {
      //     return item.mdChoice === "Y";
      //   });

      //   this.productListForSales_mid = this.productListForSales_ntabi;
      // }

      // //상품선택이 없는 경우, 기본상품표시처리
      // if (this.productListForSales_mid.length > 0) 
      // {
      //   this.selectedPdtInfo = this.productListForSales_mid[0];
      //   // console.log("selectedPdtInfo:>>>>>", this.selectedPdtInfo);
      // } 
      // else 
      // {
      //   this.showNdt("ND");
      // }

      // if (p3 == "" || p3 == "ND" || p3 === undefined || p3 == "BE") 
      // {
      //   //베스트 + 기본 <- 초기값 ND에서 BEST는 독립적으로 호출해야 한다
      //   let productListForSales_best = this.productListForSales.filter((item) => {
      //     return item.bestFlag === "Y";
      //   });

      //   this.productListForSales_best = productListForSales_best;
      // }

      //상품가격계산 최저가기준 표시 + 마진적용(성인기준)
      let maxDC_P = 0;          //프리미엄 최대환급율
      let maxDC_S = 0;          //스탠다드 최대환급율
      let dcForMem_P = 0;       //이외상품 프리미엄 할인율
      let dcForMem_S = 0;       //이외상품 스탠다드 할인율
      let dcForStd = 0;         //프리이엄전용을 스탠다드가 구매시 할인율
      let marginPrice = 0;      //마진가격
      let promoPrice = 0;       //프로모션금액
      let price_memP = 0;       //프리미엄 환급액
      let price_memS = 0;       //스탠다드 환급액
      let price_dcForStd = 0;   //프리이엄전용을 스탠다드가 구매시 할인액
      let price_dcForMem_P = 0; //일반상품을 프리미엄이 구매시 할인액
      let price_dcForMem_S = 0; //일반상품을 스탠다드가 구매시 할인액
      //console.log(dcForMem_P,"/",dcForMem_S,"/",dcForStd)
      for (let i = 0; i < this.productListForSales.length; i++) 
      {
        ////마진에 따른 마진가격
        if (this.productListForSales[i].marginMethod == "P") 
        {
          marginPrice = this.productListForSales[i].minPrice * (this.productListForSales[i].basicMargin / 100);
        } 
        else 
        {
          marginPrice = this.productListForSales[i].basicMargin;
        }
        //console.log("marginPrice:",marginPrice);
        
        ////프로모션 상태에 따른 프로모션가격
        if (this.productListForSales[i].promoStatus === "Y") 
        {
          if (this.productListForSales[i].promoMethod === "P") 
          {
            promoPrice = (this.productListForSales[i].minPrice + marginPrice) * (this.productListForSales[i].promoRate / 100);
          } 
          else 
          {
            promoPrice = this.productListForSales[i].promoRate;
          }
          this.promoPrice = promoPrice.toFixed(0) * 1; //프로모션 할인액
          //console.log("promoPrice:",this.promoPrice)
        }
        //멤버십상품의 멤버십등급에 따른 멤버십환급가
        if (this.productListForSales[i].memLevel_P === "Y" || this.productListForSales[i].memLevel_S === "Y") 
        {
          //최대환급율
          maxDC_P = (this.productListForSales[i].maxDC_P == undefined || this.productListForSales[i].maxDC_P == null) ? 0 : this.productListForSales[i].maxDC_P * 1;
          maxDC_S = (this.productListForSales[i].maxDC_S == undefined || this.productListForSales[i].maxDC_S == null) ? 0 : this.productListForSales[i].maxDC_S * 1;
          //프리미엄전용을 스탠다드가 구매시 할인율
          dcForStd = this.productListForSales[i].dcForStd == undefined ? 0 : this.productListForSales[i].dcForStd * 1;
          //환급액
          price_memP = (this.productListForSales[i].minPrice + marginPrice) * (maxDC_P / 100);
          price_memS = (this.productListForSales[i].minPrice + marginPrice) * (maxDC_S / 100);
          //소숫점 절사
          this.price_memP = price_memP.toFixed(0) * 1;          //price_memP*1
          this.price_memS = price_memS.toFixed(0) * 1;          //price_memS*1

          //프리미엄전용을 스탠다드가 구매시 할인액
          price_dcForStd = (this.productListForSales[i].minPrice + marginPrice) * (dcForStd / 100);
          this.price_dcForStd = price_dcForStd.toFixed(0) * 1;
        }

        //일반상품, 선박상품, 엔데어||버라이어티의 멤버십등급에 따른 할인액1
        if (this.productListForSales[i].salesRoute == "T" || this.productListForSales[i].salesRoute == "F" || (this.productListForSales[i].salesRoute == "L" && (this.productListForSales[i].nrFlag == "Y" || this.productListForSales[i].vrFlag == "Y"))) 
        {
          dcForMem_P = this.productListForSales[i].dcForMem_P == undefined ? 0 : this.productListForSales[i].dcForMem_P * 1;
          dcForMem_S = this.productListForSales[i].dcForMem_S == undefined ? 0 : this.productListForSales[i].dcForMem_S * 1;
          //할인방식에 따라 분기처리
          if (this.productListForSales[i].dcRateForMem_P === "P") 
          { 
            price_dcForMem_P = (this.productListForSales[i].minPrice + marginPrice) * (dcForMem_P / 100);
          } 
          else 
          {
            price_dcForMem_P = this.productListForSales[i].dcForMem_P * 1;
          }

          if (this.productListForSales[i].dcRateForMem_S === "P") 
          {
            price_dcForMem_S = (this.productListForSales[i].minPrice + marginPrice) * (dcForMem_S / 100);
          } 
          else 
          {
            price_dcForMem_S = this.productListForSales[i].dcForMem_S * 1;
          }
          
          this.price_dcForMem_P = price_dcForMem_P.toFixed(0) * 1;
          this.price_dcForMem_S = price_dcForMem_S.toFixed(0) * 1;
          //프리미엄과 스탠다드 할인액을 배열에 추가
          this.productListForSales[i].price_dcForMem_P = price_dcForMem_P.toFixed(0) * 1;
          this.productListForSales[i].price_dcForMem_S = price_dcForMem_S.toFixed(0) * 1;
        }
        //멤버십미지정 로컬투어
        else if (this.productListForSales[i].salesRoute == "L" && (this.productListForSales[i].memLevel_P == null || this.productListForSales[i].memLevel_P == "N") && (this.productListForSales[i].memLevel_S == null || this.productListForSales[i].memLevel_S == "N")) 
        {
          dcForMem_P =
            (this.productListForSales[i].dcForMem_P == undefined || this.productListForSales[i].dcForMem_P == null) ? 0 : this.productListForSales[i].dcForMem_P * 1;
          dcForMem_S =
            (this.productListForSales[i].dcForMem_S == undefined || this.productListForSales[i].dcForMem_S == null) ? 0 : this.productListForSales[i].dcForMem_S * 1;

          price_dcForMem_P =
            (this.productListForSales[i].minPrice + marginPrice) * (dcForMem_P / 100);
          price_dcForMem_S =
            (this.productListForSales[i].minPrice + marginPrice) * (dcForMem_S / 100);

          this.price_dcForMem_P = price_dcForMem_P.toFixed(0) * 1;
          this.price_dcForMem_S = price_dcForMem_S.toFixed(0) * 1;

          //프리미엄과 스탠다드 할인액을 배열에 추가
          this.productListForSales[i].price_dcForMem_P =
            price_dcForMem_P.toFixed(0) * 1;
          this.productListForSales[i].price_dcForMem_S =
            price_dcForMem_S.toFixed(0) * 1;
        }
        //화면표시용 가격
        //구매자 멤버십
        if (this.user.memLevel === "P" || this.user.memLevel === "S") {
          //this.productListForSales[i].pPrice = "멤버십가격"
          //멤버십 + 멤버십상품
          if (this.productListForSales[i].memLevel_P === "Y" || this.productListForSales[i].memLevel_S === "Y") 
          {
            //this.productListForSales[i].pPrice = "멤버용 상품가격"

            //유효프로모션 존재
            if (this.productListForSales[i].promoStatus === "Y") 
            {
              if (this.user.memLevel === "P") 
              {
                //멤버십환급과 프로모션할인을 비교하여 큰 금액으로 표시
                if (this.price_memP >= this.promoPrice && this.price_memP > 0) 
                {
                  this.productListForSales[i].dispPrice = "mem"; //표시방식 멤버십
                  this.productListForSales[i].pPrice = this.productListForSales[i].minPrice + marginPrice;
                  this.productListForSales[i].pPrice_P = this.productListForSales[i].minPrice + marginPrice;
                  this.productListForSales[i].memP = this.price_memP; //환급액
                  this.productListForSales[i].forStd = this.price_dcForStd; //스탠다드를 위한 할인율
                  this.productListForSales[i].memS = this.price_memS; //Std환급액
                } 
                else if (this.price_memP < this.promoPrice || this.price_memP == 0) 
                {
                  this.productListForSales[i].dispPrice = "promo"; //표시방식 프로모션
                  this.productListForSales[i].pPrice = this.productListForSales[i].minPrice + marginPrice - this.promoPrice;
                  this.productListForSales[i].pPrice_P = this.productListForSales[i].minPrice + marginPrice - this.promoPrice;
                }
              } 
              else if (this.user.memLevel === "S" && this.price_memS > 0) 
              {
                if (this.price_memS >= this.promoPrice) 
                {
                  this.productListForSales[i].dispPrice = "mem"; //표시방식 멤버십
                  this.productListForSales[i].pPrice = this.productListForSales[i].minPrice + marginPrice;
                  this.productListForSales[i].pPrice_S = this.productListForSales[i].minPrice + marginPrice;
                  this.productListForSales[i].memS = this.price_memS; //환급액
                  this.productListForSales[i].memP = this.price_memP; //Pre환급액
                } 
                else if (this.price_memS < this.promoPrice || this.price_memS == 0) 
                {
                  this.productListForSales[i].dispPrice = "promo"; //표시방식 멤버십
                  this.productListForSales[i].pPrice = this.productListForSales[i].minPrice + marginPrice - this.promoPrice;
                  this.productListForSales[i].pPrice_S = this.productListForSales[i].minPrice + marginPrice - this.promoPrice;
                }
              }
            }
            //프로모션 없음
            else {
              if (this.user.memLevel === "P") 
              {
                this.productListForSales[i].dispPrice = "mem"; //표시방식 멤버십
                this.productListForSales[i].pPrice = this.productListForSales[i].minPrice + marginPrice;
                this.productListForSales[i].pPrice_P = this.productListForSales[i].minPrice + marginPrice;
                this.productListForSales[i].memS = this.price_memS; //STD환급액
                this.productListForSales[i].memP = this.price_memP; //환급액
              } 
              else if (this.user.memLevel === "S") 
              {
                this.productListForSales[i].dispPrice = "mem"; //표시방식 멤버십
                this.productListForSales[i].pPrice = this.productListForSales[i].minPrice + marginPrice;
                this.productListForSales[i].pPrice_S = this.productListForSales[i].minPrice + marginPrice;
                this.productListForSales[i].memS = this.price_memS; //PRE환급액
                this.productListForSales[i].memP = this.price_memP; //환급액
              }
            }
          }

          //멤버십+일반상품
          else {
            //일반상품지정(패키지, 선박상품, 로컬+엔데어 || 버라이어티)
            if (this.productListForSales[i].salesRoute == "T" || this.productListForSales[i].salesRoute == "F" || (this.productListForSales[i].salesRoute === "L" && (this.productListForSales[i].nrFlag == "Y" || this.productListForSales[i].vrFlag == "Y"))) 
            {
              //this.productListForSales[i].pPrice = "멤버용 일반상품가격1"
              //유효프로모션 존재
              if (this.productListForSales[i].promoStatus === "Y") 
              {
                if (this.user.memLevel === "P") 
                {
                  //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                  if (this.price_dcForMem_P >= this.promoPrice && this.price_dcForMem_P > 0) 
                  {
                    this.productListForSales[i].pPrice =
                      this.productListForSales[i].minPrice + marginPrice - this.price_dcForMem_P;
                    this.productListForSales[i].pPrice_P =
                      this.productListForSales[i].minPrice + marginPrice - this.price_dcForMem_P;
                    this.productListForSales[i].memDC = "Y"; //멤버십할인적용
                  } 
                  else if (this.price_dcForMem_P < this.promoPrice) 
                  {
                    this.productListForSales[i].pPrice =
                      this.productListForSales[i].minPrice + marginPrice - this.promoPrice;
                    this.productListForSales[i].pPrice_P =
                      this.productListForSales[i].minPrice + marginPrice - this.promoPrice;
                  }
                } 
                else if (this.user.memLevel === "S") 
                {
                  if (this.price_dcForMem_S >= this.promoPrice && this.price_dcForMem_S > 0) 
                  {
                    this.productListForSales[i].pPrice =
                      this.productListForSales[i].minPrice + marginPrice - this.price_dcForMem_S;
                    this.productListForSales[i].pPrice_S =
                      this.productListForSales[i].minPrice + marginPrice - this.price_dcForMem_S;
                    this.productListForSales[i].memDC = "Y"; //멤버십할인적용
                  } else if (this.price_dcForMem_S < this.promoPrice) {
                    this.productListForSales[i].pPrice =
                      this.productListForSales[i].minPrice + marginPrice - this.promoPrice;
                    this.productListForSales[i].pPrice_S =
                      this.productListForSales[i].minPrice + marginPrice - this.promoPrice;
                  }
                }
              }
              //프로모션 없음
              else {
                if (this.user.memLevel === "P") {
                  if (this.price_dcForMem_P > 0) {
                    this.productListForSales[i].dispPrice = "promo"; //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "promo"; //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"; //멤버십할인적용
                  } else {
                    this.productListForSales[i].dispPrice = "mem"; //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "mem"; //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = this.productListForSales[i].minPrice + marginPrice - this.price_dcForMem_P;
                  this.productListForSales[i].pPrice_P = this.productListForSales[i].minPrice + marginPrice - this.price_dcForMem_P;
                } else if (this.user.memLevel === "S") {
                  if (this.price_dcForMem_S > 0) {
                    this.productListForSales[i].dispPrice = "promo"; //표시방식 멤버십
                    this.productListForSales[i].dispPrice_S = "promo"; //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"; //멤버십할인적용
                  } else {
                    this.productListForSales[i].dispPrice = "mem"; //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice = this.productListForSales[i].minPrice + marginPrice - this.price_dcForMem_S;
                  this.productListForSales[i].pPrice_S = this.productListForSales[i].minPrice + marginPrice - this.price_dcForMem_S;
                }
              }
            }

            //멤버십+일반상품유형2(로컬+멤버십미지정)
            else if (
              this.productListForSales[i].salesRoute == "L" &&
              (this.productListForSales[i].memLevel_P == null ||
                this.productListForSales[i].memLevel_P == "N") &&
              (this.productListForSales[i].memLevel_S == null ||
                this.productListForSales[i].memLevel_S == "N")
            ) {
              this.productListForSales[i].pPrice = "멤버용 일반상품가격2";
              //유효프로모션 존재
              if (this.productListForSales[i].promoStatus === "Y") {
                if (this.user.memLevel === "P") {
                  //멤버십할인과 프로모션할인을 비교하여 큰 금액표시
                  if (
                    this.price_dcForMem_P >= this.promoPrice &&
                    this.price_dcForMem_P > 0
                  ) {
                    this.productListForSales[i].pPrice =
                      this.productListForSales[i].minPrice +
                      marginPrice -
                      this.price_dcForMem_P;
                    this.productListForSales[i].pPrice_P =
                      this.productListForSales[i].minPrice +
                      marginPrice -
                      this.price_dcForMem_P;
                  } else if (this.price_dcForMem_P < this.promoPrice) {
                    this.productListForSales[i].pPrice =
                      this.productListForSales[i].minPrice +
                      marginPrice -
                      this.promoPrice;
                    this.productListForSales[i].pPrice_P =
                      this.productListForSales[i].minPrice +
                      marginPrice -
                      this.promoPrice;
                  }
                } else if (this.user.memLevel === "S") {
                  if (
                    this.price_dcForMem_S >= this.promoPrice &&
                    this.price_dcForMem_S > 0
                  ) {
                    this.productListForSales[i].pPrice =
                      this.productListForSales[i].minPrice +
                      marginPrice -
                      this.price_dcForMem_S;
                    this.productListForSales[i].pPrice_S =
                      this.productListForSales[i].minPrice +
                      marginPrice -
                      this.price_dcForMem_S;
                  } else if (this.price_dcForMem_S < this.promoPrice) {
                    this.productListForSales[i].pPrice =
                      this.productListForSales[i].minPrice +
                      marginPrice -
                      this.promoPrice;
                    this.productListForSales[i].pPrice_S =
                      this.productListForSales[i].minPrice +
                      marginPrice -
                      this.promoPrice;
                  }
                }
              }
              //프로모션 없음
              else {
                if (this.user.memLevel === "P") {
                  if (this.price_dcForMem_P > 0) {
                    this.productListForSales[i].dispPrice = "promo"; //표시방식 멤버십
                    this.productListForSales[i].dispPrice_P = "promo"; //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"; //멤버십할인적용
                  } else {
                    this.productListForSales[i].dispPrice = "mem"; //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice =
                    this.productListForSales[i].minPrice +
                    marginPrice -
                    this.price_dcForMem_P;
                  this.productListForSales[i].pPrice_P =
                    this.productListForSales[i].minPrice +
                    marginPrice -
                    this.price_dcForMem_P;
                } else if (this.user.memLevel === "S") {
                  if (this.price_dcForMem_S > 0) {
                    this.productListForSales[i].dispPrice = "promo"; //표시방식 멤버십
                    this.productListForSales[i].memDC = "Y"; //멤버십할인적용
                  } else {
                    this.productListForSales[i].dispPrice = "mem"; //표시방식 멤버십
                  }
                  this.productListForSales[i].pPrice =
                    this.productListForSales[i].minPrice +
                    marginPrice -
                    this.price_dcForMem_S;
                  this.productListForSales[i].pPrice_S =
                    this.productListForSales[i].minPrice +
                    marginPrice -
                    this.price_dcForMem_S;
                }
              }
            }
          }
        }
        //구매자 비멤버십
        else {
          //프로모션이 있으면 할인처리
          if (this.productListForSales[i].promoStatus === "Y") {
            this.pPriceY =
              this.productListForSales[i].minPrice + marginPrice - promoPrice;
            //console.log(`pPrice${i}: ${this.pPriceY}`);
            this.productListForSales[i].pPrice = this.pPriceY;
          } else if (this.productListForSales[i].promoStatus == "E") {
            this.pPriceE = this.productListForSales[i].minPrice + marginPrice;
            this.productListForSales[i].pPrice = this.pPriceE;
          } else if (this.productListForSales[i].promoStatus == "N") {
            this.pPriceN = this.productListForSales[i].minPrice + marginPrice;
            this.productListForSales[i].pPrice = this.pPriceN;
          }
        }
      }
      // console.log("productListForSales:**********", this.productListForSales)

      //금월추천 -> 패스류로 변경(20250331)
      // this.productListForSales_new = productListForSales_tmp.filter((item) => {
      //   return (item.newFlag === "Y" && new Date(item.newDate).getMonth() === new Date().getMonth() && new Date(item.newDate).getFullYear() === new Date().getFullYear());
      // });

      this.productListForSales_new = productListForSales_tmp.filter((item) => {
        return (item.salesRoute == "E");
      });

      //엔데트상품
      let productListForSales_ndaytrip = this.productListForSales.filter((item) => {
        return item.sellerCode_nd === "Y";
      });
      this.productListForSales_ndaytrip = productListForSales_ndaytrip;
      
      this.productListForSales_ndaytrip_main = this.productListForSales_ndaytrip.filter((item) => {
        return item.salesRoute != 'E';
      });
      // this.productListForSales_ndaytrip_main = this.productListForSales_ndaytrip.filter((item) => {
      //   return item.pdtCode != "L-2023921-1695276228098" || item.salesRoute === 'L';
      // });      
      
      this.productListForSales_mid = this.productListForSales_ndaytrip_main;

      //엔데트추천
      this.productListForSales_ndaytrip_mdChoice = this.productListForSales_ndaytrip_main.filter((item) => {
        return item.mdChoice === "Y";
      });


      //엔타비 + 버라이어티팩
      let productListForSales_ntabi = this.productListForSales.filter((item) => {
        return ((item.sellerCode_nt === "Y" || item.vrFlag === "Y") && item.salesRoute != "F" && item.salesRoute != "E");   //선박과 기타상품 제외
      });
      this.productListForSales_ntabi = productListForSales_ntabi;
      // console.log("productListForSales_ntabi:>>>>", this.productListForSales_ntabi)

      //this.productListForSales_mid_vr = this.productListForSales_ntabi;

       // 엔타비+버라이어티 추천
      this.productListForSales_ntabi_mdChoice = this.productListForSales_ntabi.filter((item) => {
        return item.mdChoice === "Y";
      });

      //일본선박
      let productListForSales_ship = this.productListForSales.filter((item) => {
        return (item.salesRoute == "F")   //선박과 기타상품 제외
      });
      this.productListForSales_ship = productListForSales_ship;
      // console.log("productListForSales_ship:>>>>", this.productListForSales_ship)

      //this.productListForSales_mid_vr = this.productListForSales_ntabi;

       // 엔타비+버라이어티 추천
      this.productListForSales_ship_mdChoice = this.productListForSales_ship.filter((item) => {
        return item.mdChoice === "Y";
      });


      //상품선택이 없는 경우, 기본상품표시처리
      //// 엔데트
      if (this.productListForSales_mid.length > 0) 
      {
        if(this.selFlag === "N")
        {
          this.selectedPdtInfo = this.productListForSales_mid[0];
        }
        else
        {
          this.selectedPdtInfo = this.productListForSales_mid
        }
      }

      // //// 엔타비+버라이어티 (표시방식 변경으로 주석처리)
      // if (this.productListForSales_mid_vr.length > 0) 
      // {
      //   if(this.selFlag_vr === "N")
      //   {
      //     this.selectedPdtInfo_vr = this.productListForSales_mid_vr[0];
      //   }
      //   else
      //   {
      //     this.selectedPdtInfo_vr = this.productListForSales_mid_vr
      //   }
      // }

      //베스트 상품
      let productListForSales_best = this.productListForSales.filter((item) => {
        return item.bestFlag === "Y";
      });
      this.productListForSales_best = productListForSales_best;

      // if (p3 == "ND") 
      // {
      //   //엔데트상품
      //   let productListForSales_ndaytrip = this.productListForSales.filter((item) => {
      //     return item.sellerCode_nd === "Y";
      //   });

      //   this.productListForSales_ndaytrip = productListForSales_ndaytrip;
        
      //   this.productListForSales_ndaytrip_main = this.productListForSales_ndaytrip.filter((item) => {
      //     return item.pdtCode != "L-2023921-1695276228098";
      //   });
        
      //   this.productListForSales_mid = this.productListForSales_ndaytrip_main;

      //   this.productListForSales_ndaytrip_mdChoice = this.productListForSales_ndaytrip_main.filter((item) => {
      //     return item.mdChoice === "Y";
      //   });

      //   //console.log("productListForSales_ndaytrip_mdChoice:", this.productListForSales_ndaytrip_mdChoice)
      // } 
      // else if (p3 == "NR") 
      // {
      //   //엔데어
      //   let productListForSales_nthere = this.productListForSales.filter(
      //     (item) => {
      //       return item.nrFlag === "Y";
      //     }
      //   );
      //   this.productListForSales_nthere = productListForSales_nthere;

      //   this.productListForSales_mid = this.productListForSales_nthere;
      //   //console.log("productListForSales_nthere:", this.productListForSales_mid)
      // } 
      // else if (p3 == "VR") 
      // {
      //   //버라이어티
      //   let productListForSales_variety = this.productListForSales.filter(
      //     (item) => {
      //       return item.vrFlag === "Y";
      //     }
      //   );
      //   this.productListForSales_variety = productListForSales_variety;

      //   this.productListForSales_mid = this.productListForSales_variety;
      //   //console.log("productListForSales_variety:", this.productListForSales_mid)

      // //리뉴얼 후 버라이어티팩=> 엔타비 + 버라이어티 통합으로 변경(2025-02)
      // } 
      // else if (p3 == "NT") 
      // {
      //   //엔타비
      //   let productListForSales_ntabi = this.productListForSales.filter((item) => {
      //     return ((item.sellerCode_nt === "Y" || item.vrFlag === "Y") && item.salesRoute != "F" && item.salesRoute != "E");   //선박과 기타상품 제외
      //   });
      //   this.productListForSales_ntabi = productListForSales_ntabi;


      //   // NT 추천
      //   this.productListForSales_ntabi_mdChoice = this.productListForSales_ntabi.filter((item) => {
      //     return item.mdChoice === "Y";
      //   });

      //   this.productListForSales_mid = this.productListForSales_ntabi;
      // }

      // //상품선택이 없는 경우, 기본상품표시처리
      // if (this.productListForSales_mid.length > 0) 
      // {
      //   this.selectedPdtInfo = this.productListForSales_mid[0];
      //   // console.log("selectedPdtInfo:>>>>>", this.selectedPdtInfo);
      // } 
      // else 
      // {
      //   this.showNdt("ND");
      // }

      // if (p3 == "" || p3 == "ND" || p3 === undefined || p3 == "BE") 
      // {
      //   //베스트 + 기본 <- 초기값 ND에서 BEST는 독립적으로 호출해야 한다
      //   let productListForSales_best = this.productListForSales.filter((item) => {
      //     return item.bestFlag === "Y";
      //   });

      //   this.productListForSales_best = productListForSales_best;
      // }


      //그루핑된 상품정보 추출(3개 그룹)
      //필터링 후 시간역순으로 정렬하고 4개까지만 추출
      this.productListForSales_gr = this.productListForSales
        .filter(item => item.grNo === "Y")
        .sort((a, b) => new Date(b.grDate) - new Date(a.grDate))
        .slice(0, 4);

      this.productListForSales_gr2 = this.productListForSales
        .filter(item => item.grNo2 === "Y")
        .sort((a, b) => new Date(b.grDate2) - new Date(a.grDate2))
        .slice(0, 4);

      this.productListForSales_gr3 = this.productListForSales
        .filter(item => item.grNo3 === "Y")
        .sort((a, b) => new Date(b.grDate3) - new Date(a.grDate3))
        .slice(0, 4);

        // console.log("productListForSales_gr>>>>>", this.productListForSales_gr)
        // console.log("productListForSales_gr2>>>>>", this.productListForSales_gr2)
        // console.log("productListForSales_gr3>>>>>", this.productListForSales_gr3)

    },

    onTouchStart(event) {
      this.touchStartX = event.touches[0].clientX;
      this.scrollStartX = this.$refs.scrollingContent_m.scrollLeft;
    },

    onTouchMove(event) {
      event.preventDefault(); // 기본 스크롤 동작 방지
      const touchCurrentX = event.touches[0].clientX;
      const diff = this.touchStartX - touchCurrentX;
      this.$refs.scrollingContent_m.scrollLeft = this.scrollStartX + diff;
    },

    onTouchEnd(event) {
      this.touchEndX = event.changedTouches[0].clientX;
      const diff = this.touchStartX - this.touchEndX;
      if (Math.abs(diff) > 50) {
        // 50px 이상 스와이프 했을 때만 페이지 전환
        if (diff > 0) {
          // 왼쪽으로 스와이프
          console.log("Swiped left");
        } else {
          // 오른쪽으로 스와이프
          console.log("Swiped right");
        }
      }
    },

    onTouchStart_nday(event) {
      this.touchStartX = event.touches[0].clientX;
      this.scrollStartX = this.$refs.scrollingContent_nday.scrollLeft;
    },

    onTouchMove_nday(event) {
      event.preventDefault(); // 기본 스크롤 동작 방지
      const touchCurrentX = event.touches[0].clientX;
      const diff = this.touchStartX - touchCurrentX;
      this.$refs.scrollingContent_nday.scrollLeft = this.scrollStartX + diff;
    },

    onTouchEnd_nday(event) {
      this.touchEndX = event.changedTouches[0].clientX;
      const diff = this.touchStartX - this.touchEndX;
      if (Math.abs(diff) > 50) {
        // 50px 이상 스와이프 했을 때만 페이지 전환
        if (diff > 0) {
          // 왼쪽으로 스와이프
          console.log("Swiped left");
        } else {
          // 오른쪽으로 스와이프
          console.log("Swiped right");
        }
      }
    },

    onTouchStart_new(event) {
      this.touchStartX = event.touches[0].clientX;
      this.scrollStartX = this.$refs.scrollingContent_new.scrollLeft;
    },

    onTouchMove_new(event) {
      event.preventDefault(); // 기본 스크롤 동작 방지
      const touchCurrentX = event.touches[0].clientX;
      const diff = this.touchStartX - touchCurrentX;
      this.$refs.scrollingContent_new.scrollLeft = this.scrollStartX + diff;
    },

    onTouchEnd_new(event) {
      this.touchEndX = event.changedTouches[0].clientX;
      const diff = this.touchStartX - this.touchEndX;
      if (Math.abs(diff) > 50) {
        // 50px 이상 스와이프 했을 때만 페이지 전환
        if (diff > 0) {
          // 왼쪽으로 스와이프
          console.log("Swiped left");
        } else {
          // 오른쪽으로 스와이프
          console.log("Swiped right");
        }
      }
    },

    //베스트셀러 스크롤버튼
    scrollLeft_btn(pCat) {
      let container = ""
      let itemWidth = ""

      if(pCat === "BE")
      {
        container = this.$refs.scrollingContent_best.querySelector(".scrolling-content");
        itemWidth = container.querySelector(".bestItems").offsetWidth;
      }

      if(pCat === "VR")
      {
        container = this.$refs.scrollingContent_VR.querySelector(".scrolling-content");
        itemWidth = container.querySelector(".vrItems").offsetWidth;
      }

      if(pCat === "SH")
      {
        container = this.$refs.scrollingContent_SH.querySelector(".scrolling-content");
        itemWidth = container.querySelector(".shItems").offsetWidth;
      }

      container.scrollBy({
        left: -itemWidth,
        behavior: "smooth",
      });
    },


    scrollRight_btn(pCat) {
      let container = ""
      let itemWidth = ""

      if(pCat === "BE")
      {
        container = this.$refs.scrollingContent_best.querySelector(".scrolling-content");
        itemWidth = container.querySelector(".bestItems").offsetWidth;
      }

      if(pCat === "VR")
      {
        container = this.$refs.scrollingContent_VR.querySelector(".scrolling-content");
        itemWidth = container.querySelector(".vrItems").offsetWidth;
      }

      if(pCat === "SH")
      {
        container = this.$refs.scrollingContent_SH.querySelector(".scrolling-content");
        itemWidth = container.querySelector(".shItems").offsetWidth;
      }

      container.scrollBy({
        left: itemWidth,
        behavior: "smooth",
      });
    },

    //공지화면에 보이기
    showArea(j) {
      document.querySelectorAll(".nContent").forEach((item) => {
        item.style.display = "none";
      });

      this.showNoticeFlag = this.showNoticeFlag === "Y" ? "N" : "Y";

      //선택된 항목만 보이도록
      if (this.showNoticeFlag == "Y") {
        document.getElementById(j).style.display = "block";
      } else {
        document.getElementById(j).style.display = "none";
      }
    },
  },
};
</script>

<style scoped>
@import url("https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css");
@import "../assets/css/style.css";

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
    font-family: 'SUIT-Regular';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_suit@1.0/SUIT-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Freesentation-9Black';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2404@1.0/Freesentation-9Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

.slide-text {
  font-family: "SBAggroB";
  text-align: left;
}

html,
body {
  background-color: #ffffff !important;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
  font-family: "Pretendard-Regular" !important; 
}

/* font */
#newMain .cap {
  font-family: "Pretendard-Regular";
}

#app .main-container {
  width: 100%;
}

/* 로고 */
#app .mlogo {
  display: block;
  padding-top: 5px !important;
  padding-left: 0 !important;
  margin-left: 0 !important;
}
#app .mlogo .logo {
  width: 80px;
  height: auto;
}

.pc-container {
  display: flex;
  width: 100%;
}

.side {
  flex: 1;
}

.center {
  width: 1200px;
  flex-shrink: 0;
}

/** Main-banner */
.banner-text {
  display: none; /* 초기에는 모든 텍스트 숨기기 */
}

/*
#banner3-text {
  display: block;
}
*/

/*********************************
  # PC그루핑상품
*********************************/
#groupProducts .image-container {
  display: flex;
  width: 1200px;
  height: 450px;
  justify-content: center;
}

#groupProducts .group-image {
  width: 400px;
  height: 400px;
  object-fit: contain;
  padding: 7px;
  transition: all 0.5s ease;
  filter: blur(2px);
  opacity: 0.5;
  margin: 0;
  border-radius: 10px;
}

#groupProducts .group-image.active,
#groupProducts .group-image:hover {
  filter: blur(0);
  opacity: 1;
  transform: scale(1.04);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 0px;
  z-index: 10;
  border-radius: 10px;
}

#groupProducts .image-container:hover .group-image:not(:hover) {
  filter: blur(2px);
  opacity: 0.5;
  transform: scale(1);
  box-shadow: none;
  padding: 7px;
}

#groupProducts .blur-transition-enter-active,
#groupProducts .blur-transition-leave-active {
  transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

#groupProducts .blur-transition-enter-from,
#groupProducts .blur-transition-leave-to {
  filter: blur(10px);
  opacity: 0;
}

/** 인디케이터 */
#groupProducts .progress-indicator {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 15px;
}

#groupProducts .progress-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ddd;
  transition: all 0.3s ease;
}

#groupProducts .progress-dot.active {
  background: #4caf50;
  transform: scale(1.2);
}

/*--------------------------------------------------------------
# 그루핑상품리스트
--------------------------------------------------------------*/
#grProductList .grProductLsitContainer{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 1200px;
  height: 450px;
}

#grProductList .grProductCard {
  flex: 0 0 auto;
  width: calc(25% - 20px);  /* 한 줄에 4개의 카드 */
  margin: 10px;             /* 카드 간격 */
  justify-content: center;
  padding-left: 10px;
}

#grProductList .grCardBody .grProductTitle{
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 1.2rem;  
}

#grProductList .grCardBody .grProductRemarks {
  /* 필수 속성 */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  
  /* 추가 권장 속성 */
  line-height: 1.4; /* 줄 높이 설정 */
  max-height: calc(1.4em * 3); /* (줄 높이 × 줄 수) */
  word-break: break-all; /* 단어 중간에서 줄바꿈 허용 */
  text-overflow: ellipsis;
  
  /* 기존 속성 유지 */
  width: auto;
  height: 85px;
  font-size: 1rem;
  white-space: normal;
}

#grProductList .card-price {
  padding-top: 6px;
}

/****************************
  topCarousel 정보
****************************/
.topBanner #carouselExampleControls {
  max-width: 3000px;
  max-height: 1000px;
  width: 100%;
  height: 100%;
}

.topBanner .carousel-inner,
.carousel-item {
  height: 100%;
}

.topBanner .carousel-image-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000; /* 선택적: 이미지 주변의 배경색 설정 */
}

.topBanner .carousel-image-container img {
  max-width: 3000px;
  max-height: 700px;
  object-fit: fill;
  height: 700px;
}

/* 카로우셀 오버레이이미지 */
#topBanner .overLay {
  position: absolute; /* 부모 요소(.image-container)를 기준으로 위치 지정 */
  top: 50%; /* 세로 중앙 정렬 */
  left: 55%; /* 가로 중앙 정렬 */
  transform: translate(-50%, -50%); /* 중앙 정렬을 위해 이미지의 중심점 이동 */
  width: 550px; /* 오버레이 이미지의 너비 */
  height: auto; /* 비율 유지 */
}


/* 썸네일 인디케이터 */
.topBanner .thumbnail-indicators {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  margin: 0;
  padding: 10px 0;
  z-index: 2;
}

.topBanner .thumbnail-indicators button {
  position: relative;
  width: 100px;
  height: 60px;
  margin: 10px;
  padding: 0;
  border: 0px solid transparent;
  opacity: 0.3;
  overflow: hidden;
}

.thumbnail-indicators button.active {
  transform: scale(1.2);
  opacity: 1;
  border-color: #fff;
}

.thumbnail-indicators button:hover {
  transform: translateY(-5%);
  opacity: 1;
}

.thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  object-fit: cover;
  outline: 3px solid white;
  outline-offset: -2px;
}

/* 캐러셀 텍스트 표시영역 */
.topCarousel {
  position: relative;
  overflow: hidden;
}

.carousel-text-box {
  position: absolute;
  top: 0;
  left: 20%;
  width: 380px;
  height: 80%;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.carouselText1 {
  font-size: 45px;
  font-weight: 900;
  margin-top: 60px;
  margin-bottom: 10px;
  word-wrap: break-word;
  white-space: pre-wrap;
  text-align: center;
  align-self: flex-start;
  font-family: Pretendard-Regular;
  text-align: left;
  transition: all 0.5s;
  line-height: 3.5rem;
}

.carouselText2 {
  font-size: 20px;
  font-weight: 600;
  margin-top: auto;
  padding-bottom: 40px;
  text-align: left;
  word-wrap: break-word;
  white-space: pre-wrap;
  align-self: flex-start;
  transition: all 0.5s;
}

.carousel-blur-overlay {
  position: absolute;
  top: 0;
  left: 20%;
  width: 380px;
  height: 80%;
  backdrop-filter: blur(10px);
  z-index: 1;
}

/****************************
  리모트 배너
****************************/
.side.right {
  position: relative;
}

.rt-remote-container {
  position: fixed;
}

.rt-remote-container {
  position: fixed;
  left: calc(50% + 640px) !important;
  top: calc(72px + var(--topBanner-height) + 20px) !important;
  transition: top 0.3s ease;
  z-index: 99;
}

/*
.not-hidden-banner {
  position: absolute;
  top: calc(var(--topBanner-height) + 70px) !important;
  right: -30%;
  transition: top 0.3s ease;
  z-index: 1000;
}
*/

/* 1860을 기준으로 보이거나 안보이거나 */
@media only screen and (max-width: 1859px) {
  .rt-remote-container {
    display: none !important;
  }
}

@media only screen and (min-width: 1860px) {
  .rt-remote-container {
    display: block !important;
  }
}

/* 1860px 이하에서 remote-control 숨기기 */
/* @media screen and (max-width: 1860px) {
  .hidden-remote {
    display: none !important;
  }
} */



/****************************
  최상단 메뉴
****************************/
#newMain .top-menu {
  padding-left: 20%;
  padding-right: 20%;
}

/* 컨텐츠 영역처리 */
.ct-position {
  width: 100%;
}

/* 상단 서치박스 */
.searchBox {
  border: 1px solid gray;
  border-radius: 8px;
  padding-left: 25px; /* 이모티콘이 input 안에 겹치지 않도록 여백 추가 */
  font-size: smaller;
}

.input-with-icon {
  position: relative;
}

.input-with-icon::before {
  content: "🔍";
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  pointer-events: none; /* 이모티콘이 클릭되지 않도록 설정 */
}

/* 텍스트 점멸 */
.blink {
  animation: blinker 1.5s linear infinite;
  /* color: darkgreen; */
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/** 멤버십라벨 */
.memLabel {
  padding-left: 3px;
  padding-right: 3px;
  font-size: 1.2rem;
  border-radius: 5%;
  color: white;
}

/* 그루핑용 멤버십 */
#grProductList .memLabel {
  padding-left: 3px;
  padding-right: 3px;
  font-size: 0.9rem;
  border-radius: 5%;
  color: white;
}


/* 멤버십 아이콘 */
.mbus-icon {
  width: 2.5rem !important;
  height: auto !important;
}

/* 멤버십 아이콘 & 가격표시: 모바일추천 */
.mbus-icon-m {
  width: 1.5rem !important;
  height: auto !important;
}
.price-m {
  font-size: 0.85rem;
  color: darkslategray;
}

/** 모바일용 멤버십 라벨 */
.memLabel-m {
  padding-left: 3px;
  padding-right: 3px;
  font-size: 0.7rem;
  border-radius: 7%;
  color: white;
}

/*--------------------------------------------------------------
	# 선택된 상품카테고리 표시영역 (상품메인)
  --------------------------------------------------------------*/
#saleitemSection {
  background-color: #ffffff !important;
}

#saleitemSection .section-title,
#vrItemSection .section-title,
#shItemSection .section-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

#saleitemSection .section-description,
#vrItemSection .section-description,
#shItemSection .section-description {
  margin-bottom: 20px;
  font-size: 18px;
  color: #666;
}

#saleitemSection .saleitemTable {
  height: 620px;
  margin: 0;
  padding: 0;
  /* box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.3); */
}

#saleitemSection .saleitemTable,
#saleitemSection .saleitemTable-2 {
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.3);
}

/* 우측상품리스트 */
#saleitemSection .saleitemTable-1 .saleList {
  padding-left: 10px;
  padding-right: 5px;
  vertical-align: top;
  padding-top: 0px;
}

#saleitemSection .saleitemTable-1 .pdt-img {
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

#saleitemSection .c-content {
  height: 120px;            /* 부모 요소의 고정 높이 */
  display: flex;            /* 플렉스 박스를 사용하여 정렬 */
  align-items: flex-start;  /* 텍스트를 상단에 정렬 */
  padding-top: 0;           /* 상단 여백 제거 */
}

#saleitemSection .product-title {
  margin-top: 0;            /* 상단 마진 제거 */
  line-height: 1.2;         /* 줄 간격 고정 */
}

#saleitemSection .saleitemTable-1 .product-title,
#saleitemSection .saleitemTable-1 .product-description {
  font-family: "Pretendard-Regular";
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 좌측상품정보 */
#saleitemSection .product-detail {
  border-width: 0px;
}

#saleitemSection .product-detail .rightPdtImage {
  padding-top: 0;
  margin-top: 0;
  width: 600px;
  height: 500px;
}

#saleitemSection .product-detail .card-body {
  height: 140px;
  padding-top: 0px;
}

#saleitemSection .nday-price {
  text-align: left;
  font-size: 1.1rem;
}

#saleitemSection .nday .nday-body {
  position: absolute;
  bottom: 25%;
  left: 5%;
  color: white;
}
#saleitemSection .nday .nday-body h5 {
  font-family: 'Freesentation-9Black'; 
  font-size: 3.6rem;
  margin-bottom: 0;
  text-align: start;
  font-weight: bolder;
}

#saleitemSection .nday-body .showPdtName {
  font-size: 1.5rem;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: start;
  padding-right: 20px;
  font-weight: 600;
}

#saleitemSection .memLabel-G {
  background-color: darkgray;
  color: white;
  font-size: 1rem;
  padding: 5px;
  margin: 3px;
}
#saleitemSection .memLabel-S {
  background-color: #0b7fd4;
  color: white;
  font-size: 1rem;
  padding: 5px;
  margin: 3px;
}
#saleitemSection .memLabel-P {
  background-color: #ffce00;
  color: white;
  font-size: 1rem;
  padding: 5px;
  margin: 3px;
}

#saleitemSection .ndayPrice {
  font-size: 1.3rem;
}

#saleitemSection .badge {
  text-align: center;
}

#saleitemSection .badge .badge1 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: red;
  opacity: 0.7 !important;
  margin: 15px 15px;
  width: 200px;
}
#saleitemSection .badge .badge1 .badge1-text {
  color: white;
  font-size: 1.2rem;
  margin: 0;
}

#saleitemSection .badge .badge2 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 0.7 !important;
  margin: 15px 15px;
  width: 200px;
}
#saleitemSection .badge .badge2 .badge2-text {
  color: #000;
  font-size: 1.2rem;
  margin: 0;
}

#saleitemSection .pagination {
  margin-top: 0px;
  display: flex;
  justify-content: flex-end; /* 우측 정렬 */
  align-items: center;
  padding-right: 10px;
  gap: 10px; /* 버튼 간 간격 */
}

#saleitemSection .pagination button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#saleitemSection .pagination button:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

#saleitemSection .pagination button:hover:not(:disabled) {
  background-color: #007bff;
  color: white;
}

#saleitemSection .pagination span {
  font-weight: bold;
}

/*--------------------------------------------------------------
	# mid-banner
--------------------------------------------------------------*/
#midBanner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

#midBanner .mid-banner1,
#midBanner .mid-banner2 {
  width: calc(50% - 10px);
}

#midBanner .mid-banner1 img,
#midBanner .mid-banner2 img {
  width: 100%;
  height: auto;
  object-fit: cover;
}


/* -------------------------------------------------
  # 메인컨텐츠영역
--------------------------------------------------- */
#newMain .main-left {
  padding-right: 1rem;
}
@media (max-width: 991px) {
  #newMain .main-left {
    padding-right: 0;
  }
}

/* -------------------------------------------------
  # Main Category
--------------------------------------------------- */
/* 버튼클릭효과 */
#newMain .cateButton {
  border: none;
  padding: 0;
  background: none;
  cursor: pointer;
  transition: all 0.1s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

#newMain .cateButton img {
  width: 100%;
  height: 70px;
  display: block;
}

#newMain .cateButton:active {
  transform: translateY(2px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
  transition: all 0.1s ease;
}

/* -------------------------------------------------
  # 메인 비디오 영역
--------------------------------------------------- */
.video {
  width: 100%;
  height: 500px;
  overflow: hidden;
  margin: 0px auto;
  position: relative;
}
.video video {
  width: 100%;
  height: 100%;
}

@media (max-width: 500px) {
  .video {
    height: 200px;
  }
  .video-text p {
    font-size: 1.5rem;
  }
}

/*--------------------------------------------------------------
  # 엔타비+버라이어티팩(vrItemSection), 일본선박(shItemSection)
--------------------------------------------------------------*/
#vrItemSection .vrItem,
#shItemSection .shItem{
  margin-bottom:5rem
}

.vrItem .card,
.shItem .card{
  position: relative;
  border: 1px solid rgba(128, 128, 128, 0.363);
  overflow: hidden;
  border-radius: 15px;
  width: 100%;
}

.vrItem .vrPdtImage,
.shItem .shPdtImage{
  width:100%;
  height:320px;
  object-fit:cover;
  transition: all .7s ease-in-out;
}
.vrItem .card:hover .vrPdtImage,
.shItem .card:hover .shPdtImage{
  transform: scale(1.02);
}

#vrItemSection .vrItem .card-title,
#shItemSection .shItem .card-title {
  color: #fff;
  height: 20px;
  font-weight: 600;
  font-size: 1.3rem;
  /* 추가 권장 속성 */
  line-height: 1.6;  
}
.vrItem .card-title .b-height,
.shItem .card-title .b-height  {
  /* height: 3rem; */
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#vrItemSection .vrItem .card-body,
#shItemSection .shItem .card-body{
  position:absolute;
  bottom:0%;
  /* background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.7), rgba(0,0,0,0.5), rgba(0,0,0,0) ); */
  width:100%;
}

/* 엔타비+버라이어티팩 횡스크롤 */
#vrItemSection .scrolling-wrapper, 
#shItemSection .scrolling-wrapper{
  position: relative;
  overflow-x: hidden;
}

#vrItemSection .scrolling-content,
#shItemSection .scrolling-content {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

#vrItemSection .scrolling-content::-webkit-scrollbar,
#shItemSection .scrolling-content::-webkit-scrollbar {
  display: none;
}

#vrItemSection .vrItems,
#shItemSection .shItems  {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  width: 33.333%;
}

#vrItemSection .scroll-btn,
#shItemSection .scroll-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(36, 33, 33, 0.492);
  border: none;
  font-size: 25px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
}

#vrItemSection .scroll-btn.left,
#shItemSection .scroll-btn.left {
  left: 10px;
}

#vrItemSection .scroll-btn.right,
#shItemSection .scroll-btn.right {
  right: 10px;
}



/*--------------------------------------------------------------
  # 베스트 아이템
--------------------------------------------------------------*/
.bestitem{
  margin-bottom:5rem
}
.bestitem .card{
  border:none;
  overflow: hidden;
  border-radius: 15px;
}
.bestitem .card img{
  width:auto;
  height:35rem;
  object-fit: cover;
  transition: all .7s ease-in-out;
}
.bestitem .card:hover img{
  transform: scale(1.1);
}
.bestitem .card:hover .mbus-icon{
  transform: scale(1);
}
/*
@media (max-width:991px){
  .bestitem .card img{
    height:25rem;
  }
}
*/
.bestitem .card-body{
  position:absolute;
  bottom:0%;
  background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.7), rgba(0,0,0,0.5), rgba(0,0,0,0) );
  width:100%;
}

.bestitem .card-title{
  color:#fff;
  height:180px;
  font-weight: 600;
  font-size:1.3rem;
}

.bestitem .card-title .b-height{
  height:3rem;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* 베스트셀러 횡스크롤 */
#best-item .scrolling-wrapper {
  position: relative;
  overflow-x: hidden;
}

#best-item .scrolling-content {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

#best-item .scrolling-content::-webkit-scrollbar {
  display: none;
}

#best-item .bestItems {
  flex: 0 0 33.333%;
  max-width: 33.333%;
  width: 33.333%;
}

#best-item .scroll-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  font-size: 25px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

#best-item .scroll-btn.left {
  left: 10px;
}

#best-item .scroll-btn.right {
  right: 10px;
}


/*--------------------------------------------------------------
# 상품뱃지
--------------------------------------------------------------*/
.badge {
  /* width: 120px; */
  text-align: center;
}

.badge .badge1 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: red;
  opacity: 0.7 !important;
  padding: 15px 15px;
  width: 160px;
}
.badge .badge1 .badge1-text {
  /* color:#fff; */
  color: white;
  font-size: 1.2rem;
  padding: 0;
}

.badge .badge2 {
  position: absolute;
  top: 0;
  left: 0;
  /* left:100px; */
  background-color: white;
  opacity: 0.7 !important;
  padding: 15px 15px;
  width: 160px;
  /* background:linear-gradient(#dacd4e, #97ca55); */
}
.badge .badge2 .badge2-text {
  /* color:#fff; */
  color: #000;
  font-size: 1.2rem;
  padding: 0;
}

/* -------------------------------------------------
  # 엔데이라이브쇼핑
--------------------------------------------------- */
#liveSection .section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#liveSection .mt-0.mb-0 {
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 20px;
  padding-left: 10px;
}

#liveSection #more {
  margin-left: auto;
  padding-right: 10px;
}

/*--------------------------------------------------------------
# 스마트스토어 라이브 임베디드
--------------------------------------------------------------*/
.iframe-container {
  position: relative;       /* 포지션 조정 */
  width: 100%;              /* 부모 컨테이너의 너비에 맞춤 */
  height: 550px;            /* 임베디드 사이즈 */
  /* 16:9 비율을 유지하기 위한 비율 계산 */
  /* padding-bottom: 56.25%; */
  overflow-x: auto;         /* x축 스크롤바 표시 */
  overflow-y: hidden;       /* x축 스크롤바 표시 */
  /* 초과부분 숨김*/
  /* overflow: hidden; */
}

.iframe-container iframe {
  position: absolute;
  top: -350px;
  left: 0;
  width: 1400px; /* 부모 컨테이너의 전체 너비 사용 */
  height: 1300px; /* 부모 컨테이너의 전체 높이 사용 */
  border: none; /* iframe의 경계선 제거 */
}

/*************************************************
  공지 & 이벤트
 *************************************************/
/* 진행중 이벤트 */
#notice .section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#notice #more {
  margin-left: auto;
  padding-right: 10px;
}

#notice * {
  margin: 0;
  padding: 0;
}

#notice .noticeTable table {
  border-spacing: 0;
  border-collapse: collapse;
}

#notice .noticeTable tr {
  padding: 0;
}

#notice .noticeTable td {
  padding: 0 !important;
}

#notice .noticeTable td img {
  display: block !important;
}

#notice .noticeTable .noticePrev,
#notice .noticeTable .noticeNext {
  font-size: 2rem;
  font-weight: 800;
  color: black;
}

#notice .noticeTable .img-container,
#notice .noticeTable .video-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* 좌측 이벤트 이미지 */
#notice .noticeTable .leftImg {
  max-width: 100%;
  max-height: 100%;
  width: 700px;
  height: 600px;
  object-fit: cover;
  padding: 0;
  vertical-align: middle;
  padding-right: 10px;
}

/* 고정 공지가 없을 때의 캐러셀 컨테이너 스타일 */
#notice
  .noticeTable
  td[style*="width:1200px; height:600px;"]
  #carouselvarietyControl {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#notice .noticeTable td[style*="width:1200px; height:600px;"] .carousel-inner {
  max-width: 100%;
  max-height: 100%;
}

/* 우측 이미지 */
#notice .noticeTable .rightImg1,
#notice .noticeTable .rightImg2 {
  width: 500px;
  height: 300px;
  object-fit: fill;
  object-position: center;
}
#notice .noticeTable .rightImg1 {
  padding-bottom: 5px;
}
#notice .noticeTable .rightImg2 {
  padding-top: 5px;
}

/* 공지사항 */
#notice .post-entry {
  text-align: left;
  padding: 0 15px;
  font-size: 0.9rem;
  margin-bottom: 10px;
}
#notice .post-entry .contents {
  width: 100%;
  padding: 5px 5px;
}
#notice .post-entry a h6 {
  font-size: 0.9rem;
  color: #777;
  transition: all 0.3s;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
#notice .post-entry .contents:hover a h6 {
  color: #000;
}
#notice .post-entry .date-read {
  font-size: 0.75rem;
  float: right;
  color: #aaa;
}

@media (max-width: 991px) {
  #notice .post-entry {
    padding: 0;
  }
  #notice .post-entry .date-read {
    font-size: 0.45rem;
  }
}

/* 공지사항내용 */

/**공지사항표시 초기세팅 */
#notice .nContent {
  display: none;
}

#notice .nContent {
  word-wrap: break-word;
  white-space: pre-wrap;
}

#notice .nContent .gopro {
  float: none;
  margin: 3rem auto 4rem;
}
#notice .nContent .gopro a {
  color: #fff;
  background-color: #333;
  padding: 15px 0;
  font-weight: 600;
  letter-spacing: 2px;
  transition: all 0.5s;
}
#notice .nContent .gopro a:hover {
  background-color: #000;
}

/* 빠른메뉴 */
#notice .quick {
  margin-top: 1.2rem;
}

#notice .quick img {
  width: 4.5rem;
  height: auto;
}

#notice .quickTitle {
  font-size: 1rem;
}

/*

#notice .quick p{
  color:#777;
  font-size:1.1rem;
  margin-top:5px;
  transition:all 0.5s
}
#notice .quick a:hover p{
  color:#000
} */

/***************************************
  ** Mobile-App CSS
****************************************/
/* 공통 스타일 */
body {
  /* font-family: "Arial", sans-serif; */
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}
#main-app {
  font-family: "Pretendard-Regular";
  max-width: 500px;
  margin: 0 auto;
  background-color: #ffffff;
  margin-top: 55px;
}

/* 헤더 스타일 */
#main-app .menu-icons i {
  font-size: 18px;
  margin-left: 15px;
}

/**** 배너 스타일 ****/
/* 최상단 배너*/
#main-app .top-banner,
#main-app .banner img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 8%;
  box-sizing: border-box;
  overflow: hidden;
}

/*
#main-app .banner img {
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 10%;
}
*/

/* 메인 메뉴 스타일 */
#main-app .main-menu {
  text-align: center;
  padding: 20px 0 0 0;
  z-index: 999;
}

#main-app .icon-menu {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  margin-top: 10px;
  padding-inline: 20px;
  height: 140px;
}


#main-app .icon-item {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 140px;
}

#main-app .icon-item img {
  width: 60px !important;
  height: 60px !important;
  object-fit: fill;
  margin-bottom: 8px;
}

#main-app .icon-text {
  font-weight: bold;
  text-align: center;
  width: 100%;
  line-height: 1.2;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* 최근 본 상품 스타일 */
#main-app .recent-products {
  padding: 20px;
}
#main-app .products {
  display: flex;
  overflow-x: auto;
}
#main-app .product {
  margin-right: 10px;
  text-align: center;
}
#main-app .product img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
}
#main-app .product-info {
  font-size: 14px;
}

/* 추천 버스 투어 스타일 */
#main-app .groupProduct,
#main-app .product-banner,
#main-app .ntabi-recommend,
#main-app .ndaytrip-recommend,
#main-app .notice-m {
  padding: 20px;
}
#main-app .tour-item {
  display: flex;
  margin-bottom: 15px;
}
#main-app .tour-item img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
#main-app .tour-info {
  margin-left: 15px;
}
#main-app .tour-name {
  font-size: 16px;
  font-weight: bold;
}
#main-app .tour-price {
  font-size: 14px;
  color: #666;
}

/* 푸터 스타일 */
#main-app .footer {
  text-align: center;
  padding: 15px;
  background-color: #f1f1f1;
  font-size: 14px;
  color: #666;
}

/* -------------------------------------------------
  # 모바일 메뉴
--------------------------------------------------- */
#main-app .mobile-menu {
  display: block;
  background: #fff;
  color: #fff;
  text-align: center;
  transition: all 0.4s ease;
  cursor: pointer;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 5px;
  z-index: 999;
}

#main-app .mobile-menu .m-recent {
  background-color: transparent;
}

#main-app .hr-line {
  /* border-top: 1px solid #000;
  margin: 20px 0; */
  border-top: 10px solid lightgray;
}

/* -----------------
  모바일 메뉴 내용들
  ----------------- */
/* 로그인 */
#main-app .mobile-menu .mmenu .toolbar-login a {
  display: inline-block;
  color: #000;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 0 8px;
  z-index: 999;
}
#main-app .mobile-menu .mmenu .mbtn-close {
  padding-right: 30px;
}

/* 메뉴 */
#main-app .mobile-menu .mmenu .navbar-nav .nav-item {
  z-index: 999;
  position: relative;
  margin-left: 20px;
}
#main-app .mobile-menu .mmenu .navbar .nav-item.dropdown.show .nav-link,
#main-app .mobile-menu .mmenu .navbar .nav-item .nav-link:hover,
#main-app .mobile-menu .mmenu .navbar .nav-item .nav-link.active {
  color: #9fcd53;
}
#main-app .mobile-menu .mmenu .navbar-nav .nav-item a {
  color: #000;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 50px;
}
#main-app .mobile-menu .mmenu .navbar-nav .one a::after {
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  #main-app .mobile-menu .mmenu .navbar-nav .nav-item a {
    display: flex;
    /* justify-content: space-between; */
    justify-content: left;
  }
}

/* 드롭다운 메뉴 */
#main-app .mobile-menu .mmenu .dropdown .dropdown-menu {
  position: sticky !important;
  transform: translateY(0px) !important;
  margin-top: -20px !important;
  border: none;
}

#main-app .mobile-menu .mmenu .dropdown .nav-item2 a {
  font-weight: 300;
  font-size: 1rem;
}
#main-app .mobile-menu .mmenu .dropdown .nav-item2 .mssun-menu a {
  display: block;
  font-size: 0.9rem;
  line-height: 40px;
}

/* -----------------
  모바일 최근 본 상품
  ----------------- */
#main-app .mobile-menu .mrecent {
  color: #000;
}
#main-app .mobile-menu .mrecent .offcanvas-title {
  font-weight: 600;
}
#main-app .mobile-menu .mrecent img {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
}
#main-app .mobile-menu .mrecent .recent p {
  text-align: left;
  vertical-align: middle;
  font-size: 0.9rem;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}

@media (max-width: 1199px) {
  #main-app .mobile-menu .offcanvas {
    max-width: 100%;
  }
  #main-app .mobile-menu .off-recent {
    max-width: 100%;
    height: 50%;
  }
}

/* pc 화면 안 보임 */
@media (min-width: 992px) {
  #main-app .mobile-menu {
    display: none;
  }
}

/**메인메뉴 사이드로고이미지(멤버십) */
/* .tablink .lvar{
  height:1.7rem;
  width:auto;
  margin-top:-5px
} */

/**메인메뉴 사이드로고이미지(멤버십 모바일) */
.lvar-m {
  height: 2.5rem;
  width: auto;
  margin-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

/* -------------------------------------------------
  # 모바일 탑메뉴
--------------------------------------------------- */
/*
#main-app .toTop_m{
  position:fixed;
  z-index: 999;
  bottom:5%;
  right:0%
}
@media (min-width:1670px){
  #main-app .toTop_m{
    bottom:5%;
    right:5%
  }
}
*/

/*
.mbus-icon {
  transform: scale(0.5);
}
*/

/* 텍스트 점멸 */
.blink {
  animation: blinker 1.5s linear infinite;
  /* color: darkgreen; */
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/* 텍스트 첨자 */
.text-container {
  position: relative;
  display: inline-block;
}
.text {
  display: inline-block;
}
.superscript {
  position: absolute;
  top: -1em; /* adjust this value to move the superscript up or down */
  left: 0;
  color: red;
  font-size: 0.7rem;
}

/*--------------------------------------------------------------
# 모바일 카테고리 메뉴
--------------------------------------------------------------*/

/* .main-menu .icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.main-menu .icon-item a {
  display: flex;
  justify-content: center;
  width: 100%;
}

.main-menu .icon-item img {
  display: block;
  max-width: 100%;
}

.main-menu .icon-text {
  text-align: center;
  font-weight: 900;
  width: 100%;
} */

/*--------------------------------------------------------------
# 모바일 엔타비 추천
--------------------------------------------------------------*/
#main-app .ntabi-recommend {
  z-index: 1;
}

#main-app .ntabi {
  position: relative;
  overflow: hidden;
  border-radius: 5%;
  width: 140px;
  height: 140px;
}

#main-app .ntabi img {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 5%;
  transition: all 0.7s ease-in-out;
}

#main-app .ntabi .ntabi-body {
  position: absolute;
  bottom: 5%;
  left: 5%;
  color: #fff;
}
#main-app .ntabi .ntabi-body h5 {
  font-family: "Black Han Sans", sans-serif;
  font-size: 1rem;
  margin-bottom: 0;
}

#main-app .ntabi .ntabi-price {
  text-align: left;
  font-size: 1.3rem;
}

#main-app .ntabi .showPdtName {
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-wrap: break-word;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  padding-right: 20px;
  font-size: 0.8rem;
  margin-bottom: 6px;
}

#main-app .ntabi-recommend .recommendation-badge {
  position: absolute;
  color: red;
  padding: 1px 10px;
  left: -2px;  
  /* top: 3px;
  left: 5px;
  color: red;
  padding: 5px 10px; */
  /* font-size: 20px;
  font-weight: 800;
  border-radius: 4px; */
}

.ntabi-recommend h2 {
  margin-bottom: 20px;
}

.ndaytip-recommend h2 {
  margin-bottom: 20px !important;
}
/* 위 왼쪽 */
/* 위 오른쪽 */
/* 아래 왼쪽 */
/* 아래 오른쪽 */
.ntabi-recommend .outlined-badge {
  text-shadow: -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff,
    1px 1px 0 #ffffff;
}

/*************************************************
  엔타비상품 스크롤(스와이프)
 *************************************************/
#main-app .scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; /* iOS에서 부드러운 스크롤 지원 */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

#main-app .scrolling-wrapper::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

#main-app .scrolling-content {
  display: inline-flex;
}

#main-app .ntabi-pdt {
  flex: 0 0 auto;
  width: 150px; /* 각 아이템의 너비 조정 */
  margin-right: 10px;
}

/*************************************************
  엔데이트립 추천상품(모바일)
 *************************************************/

/* #main-app .ndaytrip-recommend .recommendation-badge {
  position: absolute;
  top: 3px;
  left: 5px;

  color: white;
  padding: 5px 10px;
  font-size: 20px;
  font-weight: 800;
  border-radius: 8%;
} */
#main-app .ndaytrip-recommend .recommendation-badge {
  position: absolute;
  left: 0px;
  /*background-color: rgba(255, 255, 255, 0.8);*/
  color: white;
  padding: 1px 10px;
  font-size: 20px;
  font-weight: 800;
  border-radius: 8%;
}
.ndaytrip-recommend .outlined-badge {
  text-shadow: -1px -1px 0 red, /* 위 왼쪽 */ 1px -1px 0 red,
    /* 위 오른쪽 */ -1px 1px 0 red, /* 아래 왼쪽 */ 1px 1px 0 red; /* 아래 오른쪽 */
}

/*************************************************
  이달의 신상품(모바일)
 *************************************************/

#main-app .ndaytrip-recommend .new-badge {
  position: absolute;
  top: 3px;
  left: 5px;
  /*background-color: rgba(255, 255, 255, 0.8);*/
  color: white;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: 800;
  border-radius: 5%;
}
.ndaytrip-recommend .outlined-badge {
  text-shadow: -1px -1px 0 red, /* 위 왼쪽 */ 1px -1px 0 red,
    /* 위 오른쪽 */ -1px 1px 0 red, /* 아래 왼쪽 */ 1px 1px 0 red; /* 아래 오른쪽 */
}

/* -------------------------------------------------
  # 엔데이라이브쇼핑(모바일)
--------------------------------------------------- */
#liveSection-m .section-title,
/* #notice-m .section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
#notice-m .section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-inline: 15px;
}

#liveSection-m .section-title .col-7,
#liveSection-m #more {
  width: 50%;
}

#liveSection-m #more {
  text-align: end;
}

/* #liveSection-m #more,
#notice-m #more {
  margin-left: auto;
  padding-right: 5px;
} */

#liveSection-m .ndayliveThumb {
  border: solid 0px lightgray;
  width: 50%;
}
#liveSection-m .ndayliveTable {
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  align-items: center;
}

/* #liveSection-m .cardImg .nliveThumb {
  text-align: center;
  vertical-align: top;
  padding-left: 4px;
  width: 180px;
  border-radius: 2%;
} */
#liveSection-m .cardImg .nliveThumb {
  text-align: center;
  vertical-align: top;
  width: 100%;
  object-fit: cover;
}

#liveSection-m .cardText .p1 {
  font-size: 1rem;
  padding-top: 1px;
  padding-inline: 3px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3.2em; /* 줄 높이의 2배 */
  line-height: 1.4em; /* 기본 줄 높이 */
  font-family: 'Pretendard-Regular';
  margin-bottom: 0px;
}

#liveSection-m .cardText .p2 {
  padding-inline: 3px;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 25px;
}

#liveSection-m .cardText .dcRate {
  color: red;
}

/*--------------------------------------------------------------
# 스마트스토어 라이브 임베디드(모바일)
--------------------------------------------------------------*/
/*
.iframe-container-m {
  position: relative;
  width: 90%;
  height: 800px;
  padding-bottom: 56.25%;
  overflow-x: hidden;
  overflow-y: hidden;
}

.iframe-container-m iframe {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: calc(100% + 100px);
  border: none;
}
*/

/* -------------------------------------------------
  # 공지/퀵메뉴 (모바일)
--------------------------------------------------- */
.noticeTable-m .noticePrev,
.noticeNext {
  font-size: 1.5rem;
  font-weight: 800;
  color: gray;
}

.noticeTable-m .img-container,
.video-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* 우측 이벤트 이미지 */
.noticeTable-m .leftImg {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 380px;
  object-fit: cover;
  object-position: center;
}

.noticeTable-m .evtext {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noticeTable-m .evtext img {
  max-width: 100%;
  max-height: 80%;
  object-fit: contain;
}

.noticeTable-m .evname {
  margin-top: 10px;
  text-align: center;
}

/* 공지사항 */
/*
#notice-m .post-entry {
  text-align: left;
  padding: 0 15px;
  font-size: .7rem;
  margin-bottom: 10px;
}
#notice-m .post-entry .contents {
  width: 100%;
  padding: 5px 5px;
}
#notice-m .post-entry a h6 {
  font-size: 0.8rem;
  color: #777;
  transition: all 0.3s;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
#notice-m .post-entry .contents:hover a h6 {
  color: #000;
}
#notice-m .post-entry .date-read {
  font-size: .7rem;
  float: right;
  color: #aaa
}
*/

/* 빠른메뉴 */
#notice-m .quick {
  margin-top: 1.2rem;
}
#notice-m .quick img {
  width: 2.5rem;
  height: auto;
}
#notice-m .quick p {
  color: #777;
  font-size: 0.7rem;
  margin-top: 5px;
  transition: all 0.5s;
}
#notice-m .quick a:hover p {
  color: #000;
}

/******************************************************
  # 모바일 탑카로우셀
*******************************************************/
#topBanner-m .carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

#topBanner-m .carousel-indicators button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.5;
  border: none;
  margin: 0 5px;
  transition: opacity 0.6s ease;
}

#topBanner-m .carousel-indicators button.active {
  opacity: 1;
}
.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

#topBanner-m .carousel-indicators button {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.3;
  border: none;
  margin: 0 5px;
  transition: opacity 0.6s ease;
}

#topBanner-m .carousel-indicators button.active {
  opacity: 1;
  background-color: yellow;
}

h2{
  margin-bottom: 20px !important;
}


/******************************************************
  # 모바일 미드카로우셀 (midBanner-m)
*******************************************************/
</style>