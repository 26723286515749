<template>
  <div id="#stLanding">
    <div class="st_container">
      <div>
        <h1>선박상품용 랜딩페이지입니다</h1>
      </div>
      <div style="height: 600px">

      </div>
    </div>
  </div>
</template>

<script>

// import defaultPath from '@/assets/img/taiwan/back.jpg';

export default {
  // DB에서 가져온 데이타
  data() {
    return {
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
    
  },
  methods: {
  },
  mounted() {

    // //권한없는 로그인 차단
    // if(this.user.handlerGrade >= 20) {
    //   var swal = require('sweetalert2');
    //   swal.fire({
    //     title: '로그인 필요', 
    //     text: '접근권한 확인불가.',
    //     icon: 'error'
    //   });
    //   this.$router.push({path: '/'}); 
    // }
  }
}

</script>

<style>
</style>

