import { createRouter, createWebHistory } from 'vue-router'
import Main from '../views/Main.vue'
//import MainAdmin from '../views/MainAdmin.vue'

import ProductList from '../views/ProductList.vue'
import ProductDetail from '../views/ProductDetail.vue'
import ProductDetail_preview from '../views/ProductDetail_preview.vue' //상품미리보기
import AboutInfo from '../views/AboutInfo.vue'
import EventCreate from '../views/EventCreate.vue'//행사생성
import ProductCreate from '../views/ProductCreate.vue'
import ProductUpdate from '../views/ProductUpdate.vue'
import SeasonList from '../views/SeasonList.vue' //판매시즌리스트
import SalesList from '../views/SalesList.vue' //단독상품리스트
import EventList from '../views/EventList.vue' //행사리스트
import SalesListMix from '../views/SalesListMix.vue' //결합상품리스트
import EtcPriceList from '../views/EtcPriceList.vue'
import EtcPriceCreate from '../views/EtcPriceCreate.vue'
import EtcPriceUpdate from '../views/EtcPriceUpdate.vue'
import ProductTransInsert from '../views/ProductTransInsert.vue'
import ProductPriceInsert from '../views/ProductPriceInsert.vue'
import ProductPriceInsertMix from '../views/ProductPriceInsertMix.vue'
import ProductPriceSet from '../views/ProductPriceSet.vue'
import ProductInfo from '../views/ProductInfo.vue'
import ProductInfoMix from '../views/ProductInfoMix.vue'
import EventInfo from '../views/EventInfo.vue' //행사상세
import EventInfo_client from '../views/EventInfo_client.vue' //행사상세(고객)
import EventInfo_client_non from '../views/EventInfo_client_non.vue' //행사상세(비회원)
import NoticeList from '../views/NoticeList.vue' //공지사항리스트(관리자용)
import PatternList from '../views/PatternList.vue' //패턴리스트(관리자용)
import PatternCreate from '../views/PatternCreate.vue' //부속패턴생성
import PatternDetail from '../views/PatternDetail.vue'
import NoticeCreate from '../views/NoticeCreate.vue'
import NoticeDetail from '../views/NoticeDetail.vue'

import ReviewCreate from '../views/ReviewCreate.vue' //리뷰생성
import ReviewModify from '../views/ReviewModify.vue' //리뷰수정

import QuotationList from '../views/QuotationList.vue' //견적사항리스트(관리자용)
import QuotationList_client from '../views/QuotationList_client.vue' //견적사항리스트(고객용)
import QuotationDetail from '../views/QuotationDetail.vue' //견적상세(관리자용)
import QuotationDetail_client from '../views/QuotationDetail_client.vue' //견적상세(고객용)
import RequestQuotation from '../views/RequestQuotation.vue' //견적요청(고객용)

import PaymentList from '../views/PaymentList.vue' //결제관리리스트(관리자용)
import RequestCancelList from '../views/RequestCancelList.vue' //취소요청리스트(관리자용)

import PromotionList from '../views/PromotionList.vue'
import ImageInsert from '../views/ImageInsert.vue'
import B2BLogin from '../views/B2BLogin.vue'
import B2CLogin from '../views/B2CLogin.vue'
import NonMemberLogin from '../views/NonMemberLogin.vue'
import TestLogin from '../views/TestLogin.vue' //테스트로그인(각종 심사용)

import InquiryBoadingPass from '../views/InquiryBoadingPass.vue'
import InquiryProduct from '../views/InquiryProduct.vue' //단순상품문의
import RequestList from '../views/RequestList.vue' //문의리스트(관리자용)
import InquiryDetail from '../views/InquiryDetail.vue'

import RequestList_client from '../views/RequestList_client.vue' //문의리스트(고객용)
import InquiryDetail_client from '../views/InquiryDetail_client.vue' //문의상세(고객용)

import ReserveInfoList from '../views/ReserveInfoList.vue' //예약정보리스트
import ReserveInfoDetail from '../views/ReserveInfoDetail.vue' //예약정보상세
import ReserveInfoDetail_client from '../views/ReserveInfoDetail_client.vue' //예약정보상세(고객용)
import ReserveInfoDetail_non from '../views/ReserveInfoDetail_non.vue' //예약정보상세(비회원용)

import ReserveInfoList_admin from '../views/ReserveInfoList_admin.vue' //예약정보리스트(관리자용)
import ReserveInfoList_client from '../views/ReserveInfoList_client.vue' //예약정보리스트(고객용)

import MemberList from '../views/MemberList.vue' //회원리스트(관리자용)
import PartnerList from '../views/PartnerList.vue' //거래처리스트(관리자용)
import ExpenditureInfo from '../views/ExpenditureInfo.vue' //지출항목관리(관리자용)

import FinanceRateInfo from '../views/FinanceRateInfo.vue' //환율-수수료

import PointList_client from '../views/PointList_client.vue' //포인트(고객용)
import PointList_admin from '../views/PointList_admin.vue' //포인트(관리자용)

import Success from '../views/Success' //결제성공안내(토스페이)
import Success_pg from '../views/Success_pg' //결제성공안내(토스페이먼츠)
import Fail from '../views/Fail' //결제취소 or 실패안내(토스페이)
import Fail_pg from '../views/Fail_pg' //결제취소 or 실패안내(토스페이먼츠)
import Callback from '../views/Callback' //결제취소 or 실패안내(토스페이)

import Success_kPay from '../views/Success_kPay' //결제성공안내(카카오페이)
import Fail_kPay from '../views/Fail_kPay' //결제성공안내(카카오페이)

import SubList from '../views/SubList.vue' //상품서브리스트
import Bustour from '../views/Bustour.vue' //버스투어
import Mongolia from '../views/Mongolia.vue' //몽골상품
import Ureshino from '../views/Ureshino.vue' //우레시노상품


//푸터 영역
import CompanyInfo from '../views/CompanyInfo.vue' //회사소개
import Advertising from '../views/Advertising.vue' //광고제휴문의
import Location from '../views/Location.vue' //오시는 길
import Terms from '../views/Terms.vue' //여행약관
import TermsHomepage from '../views/TermsHomepage.vue' //홈페이지이용약
import Privacy from '../views/Privacy.vue' //개인정보취급방침

//마이페이지
import MyPage from '../views/MyPage.vue' //나의페이지
import CustomerAsk_client from '../views/CustomerAsk_client.vue' //1:1문의답글확인

//고객센타
import CustomerAsk from '../views/CustomerAsk.vue' //1:1문의
import CustomerAskList from '../views/CustomerAskList.vue' //1:1리스트
import CustomerAskDetail from '../views/CustomerAskDetail.vue' //1:1문의상세-관리자용

//정산관리
import BusinessInfo from '../views/BusinessInfo.vue' //정산거래
import BusinessInfo_detail from '../views/BusinessInfo_detail.vue' //정산거래상세
import SupExSettlement from '../views/SupExSettlement.vue' //외부정산지원
//import Modal_BusinessInvoice from '../views/Modal_BusinessInvoice.vue' //정산서
import SupExpenseList from '../views/SupExpenseList.vue' //외부출금리스트



//고객센타
import CustomerService from '../views/CustomerService.vue' //고객센터
import CustomerNotice from '../views/CustomerNotice.vue' //공지사항
import CustomerQuestion from '../views/CustomerQuestion.vue' //자주하는질문
import CustomerPoint from '../views/CustomerPoint.vue' //포인트안내


//import MyPageReserInfo from '../views/MyPageReserInfo.vue' //나의페이지예약
import MyPageRequestList from '../views/MyPageRequestList.vue' //나의페이지문의
import MyPageQuotationList from '../views/MyPageQuotationList.vue' //나의페이지견적



import NaverCallBack from '../views/NaverCallBack.vue' //네이버 로그인 테스트

import ExhibitionEvent from '../views/ExhibitionEvent.vue' //기획전및이벤트

//정산서
import BusinessInvoice from '../views/BusinessInvoice.vue' //정산서인쇄

//외부연계상품
import ProductDetail_asso from '../views/ProductDetail_asso.vue' //외부연계상품상세


//import ProductDetail_asso from '../views/ProductDetail_asso.vue' //외부연계상품상세


////////////////NewTrip 신규추가화면
import Reservation from '../views/Reservation.vue'      //예약화면
import Membership from '../views/Membership.vue'        //회원가입
import MyPageInfoChange from '../views/MyPageInfoChange.vue'             //회원가입
import ReserveInfoDetail_nMem from '../views/ReserveInfoDetail_nMem.vue' //예약정보상세_new(비회원)
import MyPageInfoChange_joinM from '../views/MyPageInfoChange_joinM.vue'  //멤버십가입
import MyPageInfoChange_memberS from '../views/MyPageInfoChange_memberS.vue'  //멤버십등업,연장
import MyPageInfoChange_memberP from '../views/MyPageInfoChange_memberP.vue'  //멤버십등업,연장
import Success_membership from '../views/Success_membership.vue' //결제성공안내(멤버십)
import Success_membership_dt from '../views/Success_membership_dt.vue' //결제성공안내(멤버십-배너경유)
import Fail_membership from '../views/Fail_membership.vue' //결제취소 or 실패안내(멤버십)
import ProductMembershipSet from '../views/ProductMembershipSet.vue' //멤버십할인율관리(관리자)
import MembershipPage from '../views/MembershipPage.vue' //멤버십페이지
import BulkNoticeCall from '../views/BulkNoticeCall.vue' //알림톡대량발송
import SpPayList_admin from '../views/SpPayList_admin.vue' //외부결제리스트
import ReserveInfoDetail_spPay from '../views/ReserveInfoDetail_spPay.vue' //예약정보상세_new(외부결제)
import KyushuOlle from '../views/KyushuOlle.vue' //규슈올레
import ProductOptionInsert from '../views/ProductOptionInsert.vue'  //상품옵션등록
import ProductOptSet from '../views/ProductOptSet.vue'              //데일리옵션가격정보
import Biei from '../views/Biei.vue'                            //비에이홍보페이지
import Kavalan from '../views/Kavalan.vue'                      //카발란 체험단 이벤트페이지페이지
import Taiwan from '../views/Taiwan.vue'                        //타이완
import Bustour_asso_gen from '../views/Bustour_asso_gen.vue'    //버스투어 연계상품페이지(자동생성)
import Bustour_asso from '../views/Bustour_asso.vue'            //버스투어 연계상품페이지
import Yamaguchi from '../views/Yamaguchi.vue'                  //야마구치
import ShipTour from '../views/ShipTour.vue'                    //선박상품

import TktPass from '../views/TktPass.vue'                      //선박상품
import ExpenseReport from '../views/ExpenseReport.vue'          //지출품의서생성
import ExpenseReportList from '../views/ExpenseReportList.vue'  //지출품의서리스트
import ExpenseReportView from '../views/ExpenseReportView.vue'  //지출품의서조회

import TenyearAnniversary from '../views/TenyearAnniversary.vue'  //10주년기념행사
import Summerten from '../views/Summerten.vue'  //10주년기념행사

import BulkNoticeCallSKD_list from '../views/BulkNoticeCallSKD_list.vue'  //알림톡발송일정리스트(관리자)
import AcceptJoinUs from '../views/AcceptJoinUs.vue'              //3자인증 회원가입처리

import VirtualAccountInfo from '../views/VirtualAccountInfo.vue'              //가상계좌 확인

import Lastautumn from '../views/Lastautumn.vue'              //라스트오텀 기획전

import Kinmen from '../views/Kinmen.vue'              //대만금문도 기획전

import Coupon01 from '../views/Coupon01.vue'              //시크릿쿠폰

import BusinessInfo_profit_loss from '../views/BusinessInfo_profit_loss.vue'              //수지계산서(관리자)
import BusinessInvoice_profit_loss from '../views/BusinessInvoice_profit_loss.vue'        //수지계산서내역(관리자)

import BusinessInvoice_profit_loss_fixed from '../views/BusinessInvoice_profit_loss_fixed.vue'        //확정수지계산서(관리자)

import NbusInquiryList_admin from '../views/NbusInquiryList_admin.vue'        //엔버스문의리스트(관리자)
import NbusInquiryInfo_detail from '../views/NbusInquiryInfo_detail.vue'

import Bloggertrip from '../views/Bloggertrip.vue'              //블로거트립 기획전

import Marukoma from '../views/Marukoma.vue'                    //마루코마 기획전

import Setsetset from '../views/Setsetset.vue'                  //셋셋셋 기획전

import Threecourse from '../views/Threecourse.vue'              //쓰리코스 기획전

import RNevent from '../views/RNevent.vue'                      //리뉴얼이벤트

import ShipTourLanding from '../views/ShipTourLanding.vue'     //선박상품랜딩페이지

import Bullfight from '../views/Bullfight.vue'

import Bustour_asso_jinair from '../views/Bustour_asso_jinair.vue'            //버스투어 진에어 연계상품페이지

/////// 개발작업 중 페이지

import Nbus from '../views/Nbus.vue'                                          //엔버스

// import Main_test from '../views/Main_test.vue'              //리뉴얼메인

// import Main_moApp from '../views/Main_moApp.vue'

//import PdtGrImgCreate from '../views/PdtGrImgCreate.vue'    //상품그룹이미지


//모달창

// import TestModal from '../views/TestModal.vue'  //모달창
// import ChildModal from '../views/ChildModal.vue'  //모달창

//import Schedule from '../views/Schedule.vue' //일정표생성

//////디자인 작업테스트영역
// import Draft_Sub from '../views/Draft_Sub.vue' //예약정보상세
// import CountryList from '../views/CountryList.vue' //나라별상품리스트

// import TicketPass from '../views/TicketPass.vue' //티켓패스

// import TestEditor from '../views/TestEditor' //텍스트에디터 테스트(이미지저장)
// import TestEditor2 from '../views/TestEditor2' //텍스트에디터 테스트(문서에디터기능)
// import TestEditor3 from '../views/TestEditor3' //텍스트에디터 테스트(summerEditor)
// import Test_calendar from '../views/Test_calendar' //달력테스트

// import B2BOrder from '../views/B2BOrder.vue'
// import OrderHistory from '../views/OrderHistory.vue'
// import OrderDetail from '../views/OrderDetail.vue'
// import Eis from '../views/Eis.vue'



// import CustomerDetail from '../views/CustomerDetail.vue'
// import CustomerCreate from '../views/CustomerCreate.vue'
// import MyInfo from '../views/MyInfo.vue'
// import CustomerJoin from '../views/CustomerJoin.vue'


const routes = [
  {
    // 메인(B2C기준)
    path: '/',
    name: 'Home',
    component: Main
  },
  // {
  //   // 메인(B2C기준)
  //   path: '/admin',
  //   name: 'Admin',
  //   component: Main
  // },
  {
    // 상품리스트
    path: '/list',
    name: 'list',
    component: ProductList
  },
  {
    path: '/detail',
    name: 'ProductDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: ProductDetail
  },
  {
    //상품미리보기
    path: '/productDetail_preview',
    name: 'ProductDetail_preview',
    component: ProductDetail_preview
  },
  {
    path: '/about',
    name: 'AboutInfo',
    component: AboutInfo
  },
  {
    //행사리스트
    path: '/eventList',
    name: 'EventList',
    component: EventList
  },
  {
    path: '/sales',
    name: 'SalesList',
    component: SalesList
  },
  {
    path: '/salesMix',
    name: 'SalesListMix',
    component: SalesListMix
  },
  //상품생성
  {
    path: '/create',
    name: 'ProductCreate',
    component: ProductCreate
  },
  {
    //행사생성
    path: '/createEvent',
    name: 'EventCreate',
    component: EventCreate
  },
  {
    path: '/productTransInsert',
    name: 'ProductTransInsert',
    component: ProductTransInsert
  },
  {
    path: '/productPriceInsert',
    name: 'ProductPriceInsert',
    component: ProductPriceInsert
  },
  {
    path: '/productPriceInsertMix',
    name: 'ProductPriceInsertMix',
    component: ProductPriceInsertMix
  },
  //상품가격세팅
  {
    path: '/productPriceSet',
    name: 'ProductPriceSet',
    component: ProductPriceSet
  },
  //상품정보(조회/수정용)
  {
    path: '/productInfo',
    name: 'ProductInfo',
    component: ProductInfo
  },
  {
    path: '/productInfoMix',
    name: 'ProductInfoMix',
    component: ProductInfoMix
  },
  //멤버십할인율세팅
  {
    path: '/productMembershipSet',
    name: 'ProductMembershipSet',
    component: ProductMembershipSet
  },
  //행사정보
  {
    path: '/eventInfo',
    name: 'EventInfo',
    component: EventInfo
  },
  //행사정보(고객)
  {
    path: '/eventInfo_client',
    name: 'EventInfo_client',
    component: EventInfo_client
  },
  //행사정보(비회원)
  {
    path: '/eventInfo_client_non',
    name: 'EventInfo_client_non',
    component: EventInfo_client_non
  },

  //기타비용(제세금류)
  {
    path: '/etcPrice',
    name: 'EtcPriceList',
    component: EtcPriceList
  },
  {
    path: '/etcPriceCreate',
    name: 'EtcPriceCreate',
    component: EtcPriceCreate
  },
  {
    path: '/etcPriceUpdate',
    name: 'EtcPriceUpdate',
    component: EtcPriceUpdate
  },
  {
    //시즌관리
    path: '/seasonList',
    name: 'SeasonList',
    component: SeasonList
  },
  {
    //공지관리
    path: '/noticeList',
    name: 'NoticeList',
    component: NoticeList
  },
  {
    path: '/noticeCreate',
    name: 'NoticeCreate',
    component: NoticeCreate
  },
  {
    path: '/noticeDetail',
    name: 'NoticeDetail',
    component: NoticeDetail
  },

  {
    //리뷰작성
    path: '/reviewCreate',
    name: 'ReviewCreate',
    component: ReviewCreate
  },
  {
    //리뷰수정
    path: '/reviewModify',
    name: 'ReviewModify',
    component: ReviewModify
  },
  {
    path: '/patternDetail',
    name: 'PatternDetail',
    component: PatternDetail
  },
  {
    //패턴관리
    path: '/patternList',
    name: 'PatternList',
    component: PatternList
  },
  {
    path: '/patternCreate',
    name: 'PatternCreate',
    component: PatternCreate
  },
  {
    path: '/promotion',
    name: 'PromotionList',
    component: PromotionList
  },
  {
    path: '/update',
    name: 'ProductUpdate',
    component: ProductUpdate
  },
  {
    path: '/image_insert',
    name: 'ImageInsert',
    component: ImageInsert
  },
  {
    //비회원 로그인(결제용)
    path: '/nonMemberLogin',
    name: 'NonMemberLogin',
    component: NonMemberLogin
  },
  {
    //테스트로그인(각종 심사용)
    path: '/testLogin',
    name: 'TestLogin',
    component: TestLogin
  },
  {
    path: '/b2bLogin',
    name: 'b2bLogin',
    component: B2BLogin
  },
  {
    path: '/b2cLogin',
    name: 'b2cLogin',
    component: B2CLogin
  },
  {
    //승선권등 구입문의
    path: '/inquiryBoadingPass',
    name: 'InquiryBoadingPass',
    component: InquiryBoadingPass
  },
  {
    //상품단순문의
    path: '/inquiryProduct',
    name: 'InquiryProduct',
    component: InquiryProduct
  },
  {
    //문의리스트
    path: '/requestList',
    name: 'RequestList',
    component: RequestList
  },
  {
    //문의리스트(고객용)
    path: '/requestList_client',
    name: 'RequestList_client',
    component: RequestList_client
  },
  {
    //문의상세
    path: '/inquiryDetail',
    name: 'InquiryDetail',
    component: InquiryDetail
  },
  {
    //문의상세(고객용)
    path: '/inquiryDetail_client',
    name: 'InquiryDetail_client',
    component: InquiryDetail_client
  },
  {
    //예약정보리스트
    path: '/reserveInfoList',
    name: 'ReserveInfoList',
    component: ReserveInfoList
  },
  {
    //예약정보리스트(관리자용)
    path: '/reserveInfoList_admin',
    name: 'ReserveInfoList_admin',
    component: ReserveInfoList_admin
  },
  {
    //예약정보리스트(고객용)
    path: '/reserveInfoList_client',
    name: 'ReserveInfoList_client',
    component: ReserveInfoList_client
  },
  {
    //회원리스트
    path: '/memberList',
    name: 'MemberList',
    component: MemberList
  },
  {
    //거래처리스트
    path: '/partnerList',
    name: 'PartnerList',
    component: PartnerList
  },
  {
    //환율-기관(수수료)
    path: '/financeRateInfo',
    name: 'FinanceRateInfo',
    component: FinanceRateInfo
  },
  {
    //지출항목관리(관리자)
    path: '/expenditureInfo',
    name: 'ExpenditureInfo',
    component: ExpenditureInfo
  },
  {
    //예약정보상세
    path: '/reserveInfoDetail',
    name: 'ReserveInfoDetail',
    component: ReserveInfoDetail
  },
  {
    //예약정보상세(고객용)
    path: '/reserveInfoDetail_client',
    name: 'ReserveInfoDetail_client',
    component: ReserveInfoDetail_client
  },

  {
    //예약정보상세(비회원용)
    path: '/reserveInfoDetail_non',
    name: 'ReserveInfoDetail_non',
    component: ReserveInfoDetail_non
  },
  {
    //포인트(고객용)
    path: '/pointList_client',
    name: 'PointList_client',
    component: PointList_client
  },
  {
    //포인트(관리자용)
    path: '/pointList_admin',
    name: 'PointList_admin',
    component: PointList_admin
  },
  {
    //결제성공(토스페이)
    path: '/success',
    name: 'Success',
    component: Success
  },
  {
    //결제성공(토스페이페이먼츠)
    path: '/success_pg',
    name: 'Success_pg',
    component: Success_pg
  },
  {
    //결제실패
    path: '/fail',
    name: 'Fail',
    component: Fail
  },
  {
    //결제실패(토스페이먼츠)
    path: '/fail_pg',
    name: 'Fail_pg',
    component: Fail_pg
  },
  {
    //토스콜백
    path: '/callback',
    name: 'Callback',
    component: Callback
  },
  {
    //카카오결제성공
    path: '/success_kPay',
    name: 'Success_kPay',
    component: Success_kPay
  },
  {
    //결제실패
    path: '/fail_kPay',
    name: 'Fail_kPay',
    component: Fail_kPay
  },
  {
    //견적요청(티키타카: 고객)
    path: '/requestQuotation',
    name: 'RequestQuotation',
    component: RequestQuotation
  },
  {
    //견적리스트
    path: '/quotationList',
    name: 'QuotationList',
    component: QuotationList
  },
  {
    //견적리스트(고객용)
    path: '/quotationList_client',
    name: 'QuotationList_client',
    component: QuotationList_client
  },
  {
    //견적상세
    path: '/quotationDetail',
    name: 'QuotationDetail',
    component: QuotationDetail
  },
  {
    //견적상세(고객용)
    path: '/quotationDetail_client',
    name: 'QuotationDetail_client',
    component: QuotationDetail_client
  },
  {
    //결제관리리스트
    path: '/paymentList',
    name: 'PaymentList',
    component: PaymentList
  },
  {
    //취소요청리스트
    path: '/requestCancelList',
    name: 'RequestCancelList',
    component: RequestCancelList
  },
  {
    //서브상품리스트
    path: '/subList',
    name: 'SubList',
    component: SubList
  },
  {
    //외부정산지원 Supplement for External Settlement
    path: '/supExSettlement',
    name: 'SupExSettlement',
    component: SupExSettlement
  },
  {
    //외부지급정산 Supplement for ExpenseList
    path: '/supExpenseList',
    name: 'SupExpenseList',
    component: SupExpenseList
  },

  {
    //거래정보(정산관리)
    path: '/businessInfo',
    name: 'BusinessInfo',
    component: BusinessInfo
  },
  {
    //거래정보상세(정산관리)
    path: '/businessInfo_detail',
    name: 'BusinessInfo_detail',
    component: BusinessInfo_detail
  },

  {
    //정산서인쇄
    path: '/businessInvoice',
    name: 'BusinessInvoice',
    component: BusinessInvoice
  },


  // {
  //   //행사정산서(정산관리)
  //   path: '/modal_businessInvoice',
  //   name: 'Modal_BusinessInvoice',
  //   component: Modal_BusinessInvoice
  // },

  //버스투어
  {
    //버스투어
    path: '/bustour',
    name: 'Bustour',
    component: Bustour
  },
  {
    //몽골상품
    path: '/mongolia',
    name: 'Mongolia',
    component: Mongolia
  },
  {
    //우레시노상품
    path: '/ureshino',
    name: 'Ureshino',
    component: Ureshino
  },
  {
    //고객센터1:1문의
    path: '/customerAsk',
    name: 'CustomerAsk',
    component: CustomerAsk
  },
  {
    //고객센터1:1문의답글-마이페이지
    path: '/customerAsk_client',
    name: 'CustomerAsk_client',
    component: CustomerAsk_client
  },
  {
    //고객센터1:1문의 리스트
    path: '/customerAskList',
    name: 'CustomerAskList',
    component: CustomerAskList
  },
  {
    //고객센터1:1문의상세-관리자용
    path: '/customerAskDetail',
    name: 'CustomerAskDetail',
    component: CustomerAskDetail
  },

  //네이버로그인 테스트(콜백페이지)
  {
    path: '/naverCallBack',
    name: 'NaverCallBack',
    component: NaverCallBack
  },

  //////////////푸터 추가 영역
  {
    //회사소개
    path: '/companyInfo',
    name: 'CompanyInfo',
    component: CompanyInfo
  },

  {
    //광고제휴문의
    path: '/advertising',
    name: 'Advertising',
    component: Advertising
  },
  {
    //오시는 길
    path: '/location',
    name: 'Location',
    component: Location
  },
  {
    //여행약관
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    //홈페이지이용약관
    path: '/termsHomepage',
    name: 'TermsHomepage',
    component: TermsHomepage
  },
  {
    //개인정보취급방침
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    //나의페이지
    path: '/mypage',
    name: 'MyPage',
    component: MyPage
  },

  //////////////기획전및이벤트
  {
    //기획전및이벤트
    path: '/exhibitionEvent',
    name: 'ExhibitionEvent',
    component: ExhibitionEvent
  },



  // 추가(이현주)
  {
    //고객센터
    path: '/customerService',
    name: 'CustomerService',
    component: CustomerService
  },
  {
    //고객센터공지사항
    path: '/customerNotice',
    name: 'CustomerNotice',
    component: CustomerNotice
  },
  {
    //고객센터자주하는질문
    path: '/customerQuestion',
    name: 'CustomerQuestion',
    component: CustomerQuestion
  },
  {
    //고객센터포인트안내
    path: '/customerPoint',
    name: 'CustomerPoint',
    component: CustomerPoint
  },


  // {
  //   //나의페이지예약내역
  //   path: '/myPageReserInfo',
  //   name: 'MyPageReserInfo',
  //   component: MyPageReserInfo
  // },
  {
    //나의페이지문의
    path: '/myPageRequestList',
    name: 'MyPageRequestList',
    component: MyPageRequestList
  },
  {
    //나의페이지견적
    path: '/myPageQuotationList',
    name: 'MyPageQuotationList',
    component: MyPageQuotationList
  },


  /////////////////////////////////////외부연계상품
  {
    path: '/productDetail_asso',
    name: 'PoductDetail_asso',
    component: ProductDetail_asso
  },



  ////////////////New Trip 고유화면
  //예약화면
  {
    path: '/reservation',
    name: 'Reservation',
    component: Reservation
  },

  //회원가입
  {
    path: '/membership',
    name: 'Membership',
    component: Membership
  },

  //회원정보변경
  {
    path: '/myPageInfoChange',
    name: 'MyPageInfoChange',
    component: MyPageInfoChange
  },

  {
    //예약정보상세_new(비회원)
    path: '/reserveInfoDetail_nMem',
    name: 'ReserveInfoDetail_nMem',
    component: ReserveInfoDetail_nMem
  },

  //멤버십가입
  {
    path: '/myPageInfoChange_joinM',
    name: 'MyPageInfoChange_joinM',
    component: MyPageInfoChange_joinM,            //페이지 오픈 시, 화면커서 상단위치
    meta: { scrollToTop: true }
  },
  //멤버십등업 또는 연장
  {
    path: '/myPageInfoChange_memberP',
    name: 'MyPageInfoChange_memberP',
    component: MyPageInfoChange_memberP
  },
  {
    path: '/myPageInfoChange_memberS',
    name: 'MyPageInfoChange_memberS',
    component: MyPageInfoChange_memberS
  },

  {
    //결제성공(멤버십)
    path: '/success_membership',
    name: 'Success_membership',
    component: Success_membership
  },
  {
    //결제성공(멤버십-배너경유)
    path: '/success_membership_dt',
    name: 'Success_membership_dt',
    component: Success_membership_dt
  },
  {
    //결제실패(멤버십)
    path: '/fail_membership',
    name: 'Fail_membership',
    component: Fail_membership
  },

  {
    //멥버쉽페이지
    path: '/membershipPage',
    name: 'MembershipPage',
    component: MembershipPage
  },

  {
    //알림톡 대량발송
    path: '/bulkNoticeCall',
    name: 'BulkNoticeCall',
    component: BulkNoticeCall
  },

  {
    //외부결제리스트
    path: '/spPayList_admin',
    name: 'SpPayList_admin',
    component: SpPayList_admin
  },

  {
    //예약정보상세_new(외부결제)
    path: '/reserveInfoDetail_spPay',
    name: 'ReserveInfoDetail_spPay',
    component: ReserveInfoDetail_spPay
  },

  {
    //규슈올레이벤트
    path: '/kyushuOlle',
    name: 'KyushuOlle',
    component: KyushuOlle
  },

  {
    //상품가격옵션등록
    path: '/productOptionInsert',
    name: 'ProductOptionInsert',
    component: ProductOptionInsert
  },

  {
    //데일리옵션가격리스트
    path: '/productOptSet',
    name: 'ProductOptSet',
    component: ProductOptSet
  },

  {
    //비에이홍보
    path: '/biei',
    name: 'Biei',
    component: Biei
  },

  {
    //카발란 체험단이벤트
    path: '/kavalan',
    name: 'Kavalan',
    component: Kavalan
  },

  {
    //대만상품
    path: '/taiwan',
    name: 'Taiwan',
    component: Taiwan
  },

  //버스투어_asso
  ////자동생성
  {
    path: '/bustour_asso_gen',
    name: 'Bustour_asso_gen',
    component: Bustour_asso_gen
  },

  {
    path: '/bustour_asso',
    name: 'Bustour_asso',
    component: Bustour_asso
  },
  

  //야마구치
  {
    path: '/yamaguchi',
    name: 'Yamaguchi',
    component: Yamaguchi
  },

  //선박상품
  {
    path: '/shipTour',
    name: 'ShipTour',
    component: ShipTour
  },

  //티켓패스
  {
    path: '/tktPass',
    name: 'TktPass',
    component: TktPass
  },
  //지출품의생성
  {
    path: '/expenseReport',
    name: 'ExpenseReport',
    component: ExpenseReport
  },
  //지출품의리스트
  {
    path: '/expenseReportList',
    name: 'ExpenseReportList',
    component: ExpenseReportList
  },
  //지출품의보기
  {
    path: '/expenseReportView',
    name: 'ExpenseReportView',
    component: ExpenseReportView
  },

  //10주년 기념행사
  {
    path: '/tenyearAnniversary',
    name: 'TenyearAnniversary',
    component: TenyearAnniversary
  },

  //10주년 기념행사
  {
    path: '/summerten',
    name: 'Summerten',
    component: Summerten
  },

  //알림톡발송일정리스트(관리자)
  {
    path: '/bulkNoticeCallSKD_list',
    name: 'BulkNoticeCallSKD_list',
    component: BulkNoticeCallSKD_list
  },

  {
    //3자인증 회원가입처리(파라메타 외부노출방지처리)
    path: '/acceptJoinUs/:uNo',
    name: 'AcceptJoinUs',
    component: AcceptJoinUs
  },

  {
    //가상계좌정보
    path: '/virtualAccountInfo/:oId/:pStyle',
    name: 'VirtualAccountInfo',
    component: VirtualAccountInfo
  },

  {
    //라스트오텀 기획전
    path: '/lastautumn',
    name: 'Lastautumn',
    component: Lastautumn
  },

  {
    //대만금문도 기획전
    path: '/kinmen',
    name: 'Kinmen',
    component: Kinmen
  },

  {
    //시크릿쿠폰
    path: '/coupon01',
    name: 'Coupon01',
    component: Coupon01
  },

  {
    //수지계산서
    path: '/businessInfo_profit_loss',
    name: 'BusinessInfo_profit_loss',
    component: BusinessInfo_profit_loss
  },

  {
    //수지계산서내역
    path: '/businessInvoice_profit_loss',
    name: 'BusinessInvoice_profit_loss',
    component: BusinessInvoice_profit_loss
  },

  {
    //확정수지계산서
    path: '/businessInvoice_profit_loss_fixed',
    name: 'BusinessInvoice_profit_loss_fixed',
    component: BusinessInvoice_profit_loss_fixed
  },

  //엔버스문의리스트(관리자)
  {
    path: '/nbusInquiryList_admin',
    name: 'NbusInquiryList_admin',
    component: NbusInquiryList_admin
  },
  //엔버스문의상세(관리자)
  {
    path: '/nbusInquiryInfo_detail',
    name: 'NbusInquiryInfo_detail',
    component: NbusInquiryInfo_detail
  },

  //블로거 프로모션
  {
    path: '/bloggertrip',
    name: 'Bloggertrip',
    component: Bloggertrip
  },

  //마루오카 기획전
  {
    path: '/marukoma',
    name: 'Marukoma',
    component: Marukoma
  },

  //셋셋셋 기획전
  {
    path: '/setsetset',
    name: 'Setsetset',
    component: Setsetset
  },

  //쓰리코스 기획전
  {
    path: '/threecourse',
    name: 'Threecourse',
    component: Threecourse
  },

  //리뉴얼 이벤트
  {
    path: '/rNevent',
    name: 'RNevent',
    component: RNevent
  },


  //선박상품랜딩
  {
    path: '/shipTourLanding',
    name: 'ShipTourLanding',
    component: ShipTourLanding
  },

  //오키나와 투우페이지
  {
    path: '/bullfight',
    name: 'Bullfight',
    component: Bullfight
  },

  //버스투어_asso + 진에어
  {
    path: '/bustour_asso_jinair',
    name: 'Bustour_asso_jinair',
    component: Bustour_asso_jinair
  },



  // {
  //   //외부연계상품
  //   path: '/productDetail_asso?productId=L-2024311-1710124328348&salesRoute=T&asso=jj',
  //   name: 'ProductDetail_asso',
  //   component: ProductDetail_asso
  // },




  /////////////// 개발 중 페이지 
  //엔버스
  {
    path: '/nbus',
    name: 'Nbus',
    component: Nbus
  },


  // //리뉴얼메인
  // {
  //   path: '/main_test',
  //   name: 'Main_test',
  //   component: Main_test
  // },

  // //리뉴얼메인 : 모바일 & 앱
  // {
  //   path: '/main_moApp',
  //   name: 'Main_moApp',
  //   component: Main_moApp
  // },




  // //상품그룹 이미지
  // {
  //   path: '/pdtGrImgCreate',
  //   name: 'PdtGrImgCreate',
  //   component: PdtGrImgCreate
  // },





  //{
  //대만상품테스트
  // path: '/Taiwan',
  // name: 'Taiwan',
  // component: Taiwan
  //},



  // //모달테스트
  // {
  //   path: '/testModal',
  //   name: 'TestModal',
  //   component: TestModal
  // },
  // {
  //   path: '/childModal',
  //   name: 'ChildModal',
  //   component: ChildModal
  // },


  //////////테스트에디터 
  //이미지저장
  // {
  //   path: '/testEditor',
  //   name: 'TestEditor',
  //   component: TestEditor
  // },
  // //에디터문서저장(종합)
  // {
  //   path: '/testEditor2',
  //   name: 'TestEditor2',
  //   component: TestEditor2
  // },
  // //에디터문서저장(summerEditor)
  // {
  //   path: '/testEditor3',
  //   name: 'TestEditor3',
  //   component: TestEditor3
  // },

  // //////////////서브시안
  // {
  //   //서브시안
  //   path: '/draft_Sub',
  //   name: 'Draft_Sub',
  //   component: Draft_Sub
  // },
  // //////////////나라별상품리스트
  // {
  //   //나라별상품리스트
  //   path: '/countryList',
  //   name: 'CountryList',
  //   component: CountryList
  // },

  // //////////////티켓패스
  // {
  //   //티켓패스
  //   path: '/ticketPass',
  //   name: 'TicketPass',
  //   component: TicketPass
  // },


  // //공통달력테스트
  // {
  //   path: '/testCalendar',
  //   name: 'Test_calendar',
  //   component: Test_calendar
  // },

  // {
  //   path: '/b2bOrder',
  //   name: 'b2bOrder',
  //   component: B2BOrder
  // },
  // {
  //   //구매이력
  //   path: '/orderHistory',
  //   name: 'OrderHistory',
  //   component: OrderHistory
  // },
  // {
  //   //구매상세
  //   path: '/orderDetail',
  //   name: 'OrderDetail',
  //   component: OrderDetail
  // },
  // {
  //   //EIS
  //   path: '/eis',
  //   name: 'Eis',
  //   component: Eis
  // },


  // {
  //   path: '/customerDetail',
  //   name: 'CustomerDetail',
  //   component: CustomerDetail
  // },
  // {
  //   path: '/customerCreate',
  //   name: 'CustomerCreate',
  //   component: CustomerCreate
  // },
  // {
  //   path: '/customerJoin',
  //   name: 'CustomerJoin',
  //   component: CustomerJoin
  // },
  // {
  //   //거래처 마이정보
  //   path: '/myInfo',
  //   name: 'MyInfo',
  //   component: MyInfo
  // },
  // {
  //   //일정표생성
  //   path: '/schedule',
  //   name: 'Schedule',
  //   component: Schedule
  // },  
  // {
  //   //테스터
  //   path: '/test',
  //   name: 'Test',
  //   component: Test
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // // 'supExSettlement' 페이지에서 벗어날 때 로컬 스토리지를 삭제
  // if (from.path === '/supExSettlement' && to.path !== '/supExSettlement') {
  //   console.log('Deleting searchCondition from localStorage');  // 확인용 로그
  //   localStorage.removeItem('searchCondition');  // 검색 조건 삭제
  // }

  if (to.meta.scrollToTop) {
    window.scrollTo(0, 0);
  }
  next();
});

export default router
