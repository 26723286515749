<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-0">
      <div class="p-1">
        <div class="top-container d-flex align-items-center justify-content-between">
          <div class="flex-item">
            <!-- <button type="button" class="btn btn-primary" @click="goToInsert">TEST</button> -->
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode" v-model="rProcCode" @change="changeProcCode()">
              <option :value="noVal" disabled>진행상태선택</option>
              <option value="A">전체보기</option>
              <option value="NC">신규</option>            
              <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.procName}}</option>
              <option value="FP">전액결제</option>
              <option value="PP">부분결제</option>
              <option value="RF">환불</option>
              <option value="MEM">멤버십</option>
            </select>
          </div>

          <div class="flex-item">
            <input class="form-check-input" type="checkbox" id="cancelReservationCheckbox" v-model="noDispCxl" @change="cxlNoDisplay(noDispCxl)">
            <label class="form-check-label" for="cancelReservationCheckbox">취소예약非표시</label>
          </div>

          <div class="flex-item">
            <input v-model="dataInput" @input="submitAutoComplete" type="text" class="form-control form-control-sm text-primary" placeholder="신청자 또는 상담번호" />
            <!-- <div class="autocomplete disabled"> -->
            <div class="autocomplete disabled">
              <div class="text-success" style="cursor: pointer" :key="i" v-for="(res,i) in result" @click="searchData(res)"><small>{{ res }}</small></div>
            </div>
          </div>
        </div>


        <table class="table table-bordered table-striped fs-6">
          <thead class="small">
            <tr class="text-center">
              <th>확인</th>
              <th>상태</th>
              <th>신청일</th>
              <th>출발일</th>
              <th>예약번호</th>
              <th>멤버십</th>
              <th>상품명</th>
              <th>신청자</th>
              <th>회원</th>
              <th>결제</th>
              <th>연락처</th>
              <!-- <th>이메일</th> -->
              <th>확인자</th>
              <th>상품참조</th>
              <!-- <th>cFlag</th> -->
            </tr>
          </thead>
          <tbody class="small">
            <tr :key="i" v-for="(rList, i) in this.reqList" class="text-center">
              <td v-if="rList.rCopyFlag == 'N'" class="text-danger">N</td>
              <td v-else></td>

              <td v-if="rList.procCode != 'PY'">
                <div v-if="rList.reqStatus === 'WP' && (rList.firstPayment != null && rList.firstPayment<100)">
                  <div v-if="rList.p1PayDate == undefined && rList.p2PayDate == undefined">1차대기</div>
                  <div v-else-if="rList.p1PayDate != undefined && rList.p2PayDate == undefined">2차대기</div>
                </div>
                <div v-else>{{ rList.procName }}</div>
              </td>
              <td v-else-if="rList.procCode === 'PY'">
                <div v-if="rList.cstPrice_new === (rList.pP1*1) + (rList.pP2*1) + (rList.pPt*1) + (rList.pPt2*1) + (rList.pCt*1) + (rList.pCt2*1)" class="text-warning">전액결제</div>
                <div v-else class="text-danger">부분결제</div>
              </td>

              <td>{{ getDateFormat(rList.reqDate) }}</td>
              <td>{{ getDateFormat(rList.reqDptDate) }}</td>
              <td class="text-primary"><a @click="goToReqReserveDetail(rList.reqReserveCode, rList.reqPdtCode,rList.rCopyFlag);" style="cursor:pointer;">{{ rList.reqReserveCode }}</a></td>

              <td v-if="rList.memLevel === 'P'" style="color:goldenrod;">PRE</td>
              <td v-else-if="rList.memLevel === 'S'" style="color:royalblue;">STD</td>
              <td v-else></td>
              
              <td class="text-start" v-if="rList.reqPdtCode.substr(0,2) === 'MX'">{{ rList.mxName }}</td>
              <td class="text-start" v-else>{{ rList.mName  }}</td>

              <td>{{ rList.reqName }}</td>
              <td class="text-danger" v-if="rList.resMFlag === 'N'">X</td>
              <td v-else></td>
              <td v-if="rList.paidFlag === 'Y'" class="text-danger"> <b>{{ rList.paidFlag }}</b></td>
              <td v-else>N</td>
              <td>{{ rList.reqTelNo }}</td>
              <!-- <td>{{ rList.respodent }}</td> -->
              <td>{{ rList.checkerName }}</td>
              <td>
                <button type="button" class="btn btn-outline-success btn-sm me-1" @click="refProduct(rList.reqPdtCode);">
                  <i class="bi bi-search small"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      reqList: [],
      rProc: [],

      skillInput: null,
      result: null,
      arrSearchData: [],

      //fullPayFlag: ""             //완불여부 (fp: 완불)
      noDispCxl: false,             //취소예약비표시
    };
  },
  created() {
    this.getReqProcLevel();
    this.getRequestConsultList();

    //console.log("AccessInfo:",this.$store.state.user); 
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    //document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },

  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },

    async getReqProcLevel() {
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }
      //console.log("rProc:",this.rProc);
    },

    async getRequestConsultList(cFlag) {
      let reqList = await this.$api("/api/reqResConsultList2", {});
      console.log("reqList:",reqList);

      let j = 0
      while(j < reqList.length)
      {
        // 불명확한 데이타 방지
        reqList[j].pP1 = (reqList[j].pP1 == null || reqList[j].pP1 == undefined || reqList[j].pP1 == '') ? 0 : reqList[j].pP1
        reqList[j].pP2 = (reqList[j].pP2 == null || reqList[j].pP2 == undefined || reqList[j].pP2 == '') ? 0 : reqList[j].pP2
        reqList[j].pPt = (reqList[j].pPt == null || reqList[j].pPt == undefined || reqList[j].pPt == '') ? 0 : reqList[j].pPt
        reqList[j].pPt2 = (reqList[j].pPt2 == null || reqList[j].pPt2 == undefined || reqList[j].pPt2 == '') ? 0 : reqList[j].pPt2

        if(reqList[j].procCode === "PY")
        {
          if(reqList[j].cstPrice_new === ((reqList[j].pP1*1) + (reqList[j].pP2*1) + (reqList[j].pPt*1)))
          {
            reqList[j].fullPayFlag = "Y"
          }
          else
          {
            reqList[j].fullPayFlag = "N"
          }
        }
        j++
      }

      if(cFlag === "cx")
      {
        reqList = reqList.filter(e => {
          return e.reqStat != "CX"
        })
      }

      let i=0;
      let arrSearchData = [];
      if(reqList.length > 0) {
        this.reqList = reqList;
        while(i<reqList.length) 
        {
          arrSearchData.push(reqList[i].reqReserveCode, reqList[i].reqName);
          i++;
        }
      }
      this.arrSearchData = arrSearchData;
      //console.log("arrSearchData:",this.arrSearchData)
    },

    //자동완성기능
    submitAutoComplete() {
      const autocomplete = document.querySelector(".autocomplete");
      let result_tmp = [];
      if (this.dataInput) {
        autocomplete.classList.remove("disabled");
        autocomplete.style.display = "block"
        result_tmp = this.arrSearchData.filter((e) => {
          return String(e).match(new RegExp("^" + this.dataInput.toUpperCase(), "i"));
        });
        //결과값 중복제거
        this.result = Array.from(new Set(result_tmp));        
      }
      else {
        autocomplete.classList.add("disabled");
        autocomplete.style.display = "none";
        this.getRequestConsultList()
      }
    },
    
    searchData(res) {
      // console.log("reqList1:", this.reqList);
      // console.log("res:",res);
      let reqList_tmp = this.reqList;
      if(res.substr(0,2) === "RQ") {
        this.reqList =  reqList_tmp.filter(e => {return e.reqReserveCode === res});
      }
      else {
        this.reqList =  reqList_tmp.filter(e => {return e.reqName === res});
      }

      //console.log("reqList_searchData:",this.reqList);
    },
    refProduct(pdtCode) {
      this.$router.push({path: '/detail', query: {productId: pdtCode}});
    },
    goToReqReserveDetail(seqReq, pCode, cCode) {
      this.$router.push({path: '/reserveInfoDetail', query: {seqReq: seqReq, pCode: pCode, cCode: cCode}});
      //console.log("oId:", orderId);
    },
    
    async changeProcCode() {
      if(this.rProcCode === 'A') 
      {
        this.getRequestConsultList();
      }
      else if(this.rProcCode === 'NC')    //신규정보
      {
        let reqList_tmp1 = await this.$api("/api/reqResConsultList2", {})
        //console.log("reqList_tmp1:",reqList_tmp1);
        this.reqList = reqList_tmp1.filter(e => {return e.rCopyFlag === 'N'});
        //console.log("reqList:", this.reqList)
      }
      else if(this.rProcCode === 'MEM')    //멤버십
      {
        let reqList_tmp1 = await this.$api("/api/reqResConsultList2", {})
        //console.log("reqList_tmp1:",reqList_tmp1);
        this.reqList = reqList_tmp1.filter(e => {return (e.memLevel === 'P' || e.memLevel === 'S')});
      }
      else if(this.rProcCode === 'FP')    //전액결제
      {
        let reqList_tmp1 = await this.$api("/api/reqResConsultList2", {})
        this.reqList = reqList_tmp1.filter(e => {return (e.reqStatus === 'PY' && (e.cstPrice_new === (e.pP1*1 + e.pP2*1 + e.pPt*1)))});
      }
      else if(this.rProcCode === 'PP')    //부분결제
      {
        let reqList_tmp1 = await this.$api("/api/reqResConsultList2", {})
        this.reqList = reqList_tmp1.filter(e => {return (e.reqStatus === 'PY' && (e.cstPrice_new != (e.pP1*1 + e.pP2*1 + e.pPt*1)))});
      }
      else 
      {
        let reqList_tmp = await this.$api("/api/reqResConsultList2", {})
        //console.log("reqList_tmp:",reqList_tmp);
        this.reqList = reqList_tmp.filter(e => {return e.reqStatus === this.rProcCode});
      }
    },

    //취소된 예약비표시처리
    async cxlNoDisplay(dFlag){
      if(dFlag === true)
      {
        this.getRequestConsultList("cx")
        //let reqList_tmp = await this.$api("/api/reqResConsultList2", {})
      }
      else
      {
        this.getRequestConsultList();
      }
    },

  }
}
</script>

<style>
.top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-item {
  margin: 5px;
}
</style>