import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import mixins from './mixins'
import store from './store'
// vue-sweetalert2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue2TouchEvents from 'vue2-touch-events';

//유저디바이스 모바일여부체크
import Vue3MobileDetection from 'vue3-mobile-detection'

//스와이프기능
import TouchDirective from './assets/js/touch'

// import GoogleMap from 'vue3-google-map'
import { GoogleMap } from 'vue3-google-map';

const app = createApp(App);
app.use(router);
app.mixin(mixins);
app.use(store);
app.use(VueSweetalert2);
app.use(Vue2TouchEvents);
app.use(Vue3MobileDetection)
app.use('touch', TouchDirective)        //스와이프기능 등록
app.use(GoogleMap)

app.mount('#app');


// createApp(App).use(router).mount('#app')
// app에서 사용할 수 있도록 use시켜준다
// createApp(App).use(router).mixin(mixins).use(store).mount('#app')



// 카카오 api용키(for javascri pt)
window.Kakao.init("2d91bae1654af0bf8b3e7b6acbdaf16c");
//window.Kakao.init("08cdc469c3031b412bdda9337de06e2d");