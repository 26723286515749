<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="pl-1 text-center">
        <!-- <div class="float-end mb-1">
          <select class="form-select form-select-sm" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_procCode" v-model="rProcCode" @change="changeProcCode()">
            <option :value="noVal" disabled>진행상태선택</option>
            <option value="A" >전체보기</option>
            <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.procName}} ({{rProcCode.procCode}})</option>
            <option value="RF" >환불 (RF)</option>
          </select>
        </div> -->

        <table class="table table-bordered table-striped fs-6 text-center">
          <thead class = "small">
            <tr>
              <th>상태</th>
              <th>코드</th>
              <th>상품명</th>
              <th>출발일</th>
              <th>결제액</th>
              <th>요청자</th>
              <th>요청일</th>
              <th>처리자</th>
              <th>처리일</th>
            </tr>
          </thead>
          <tbody class = "small">
            <!-- 엔버스 예약의 취소요청 -->
            <tr :key="j" v-for="(rq3, j) in this.reqCxlList3">
              <!-- <td>{{ rList.prName }}</td> -->
              <td>{{ rq3.procName }}</td>
              <td class="text-success">
                <small><a @click="goToInfo(rq3.reqCxlResCode);" style="cursor:pointer;">{{ rq3.reqCxlResCode }}</a></small>
              </td>
              <td style="text-align: start;">엔버스>>> {{ rq3.deptPoint }} ({{ rq3.rentStartTime }})</td>
              <td>{{ this.getDateFormat(rq3.rentStartDate) }}</td>
              <td>{{ this.getCurrencyFormat(rq3.nbusPriceTot) }}</td>
              <td>{{ rq3.inqName }}</td>
              <td>{{ this.getDateFormat(rq3.reqCxlDate) }}</td>
              <td>{{ rq3.cxlName }}</td>
              <td>{{ this.getDateFormat(rq3.cDate) }}</td>
            </tr>
            <!-- 접수된 예약의 취소요청 -->
            <tr :key="i" v-for="(rq, i) in this.reqCxlList">
              <!-- <td>{{ rList.prName }}</td> -->
              <td>{{ rq.procName }}</td>
              <td class="text-primary">
                <small><a @click="goToInfo(rq.reqCxlResCode, rq.pdtCode);" style="cursor:pointer;">{{ rq.reqCxlResCode }}</a></small>
              </td>
              <td style="text-align: start;">{{ rq.pdtNameKor }}</td>
              <td>{{ this.getDateFormat(rq.reqDptDate) }}</td>
              <td>{{ this.getCurrencyFormat(rq.cstPrice_new) }}</td>
              <td>{{ rq.reqCxlId }}</td>
              <td>{{ this.getDateFormat(rq.reqCxlDate) }}</td>
              <td>{{ rq.cxlName }}</td>
              <td>{{ this.getDateFormat(rq.handlingDate) }}</td>
            </tr>
            <!-- 행사의 취소요청 -->
            <tr :key="k" v-for="(rq2, k) in this.reqCxlList2">
              <!-- <td>{{ rList.prName }}</td> -->
              <td>{{ rq2.procName }}</td>
              <td class="text-primary">
                <small><a @click="goToInfo(rq2.reqCxlResCode, rq2.eventCode);" style="cursor:pointer;">{{ rq2.reqCxlResCode }}</a></small>
              </td>
              <td style="text-align: start;">{{ rq2.eventNameKor }}</td>
              <td>{{ this.getDateFormat(rq2.evtStartDate) }}</td>
              <td>{{ this.getCurrencyFormat(rq2.cstPrice_new) }}</td>
              <td>{{ rq2.reqCxlId }}</td>
              <td>{{ this.getDateFormat(rq2.reqCxlDate) }}</td>
              <td>{{ rq2.cxlName }}</td>
              <td>{{ this.getDateFormat(rq2.handlingDate) }}</td>
            </tr>
            <!-- 접수중 예약의 취소요청 -->
            <tr :key="j" v-for="(rq1, j) in this.reqCxlList1">
              <!-- <td>{{ rList.prName }}</td> -->
              <td>접수중</td>
              <td class="text-primary">
                <small><a @click="goToInfo(rq1.reqCxlResCode, rq1.pdtCode);" style="cursor:pointer;">{{ rq1.reqCxlResCode }}</a></small>
              </td>
              <td style="text-align: start;">{{ rq1.pdtNameKor }}</td>
              <td>{{ this.getDateFormat(rq1.reqDptDate) }}</td>
              <td>{{ this.getCurrencyFormat(rq1.cstPrice_new) }}</td>
              <td>{{ rq1.reqCxlId }}</td>
              <td>{{ this.getDateFormat(rq1.reqCxlDate) }}</td>
              <td>{{ rq1.cxlName }}</td>
              <td>{{ this.getDateFormat(rq1.handlingDate) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      reqCxlList1: [],    //취소요청리스트(접수중예약)
      reqCxlList2: [],    //취소요청리스트(행사상품)
      reqCxlList: [],     //취소요청리스트

      reqCxlList3: [],    //엔버스 취소요청리스트
      // reqList: [],
      // rProc: [],
    };
  },
  created() {
    this.bSeq = this.$route.query.bSeq;
    //this.getReqProcLevel();
    this.getReqCxlList();
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    //document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    // async getReqProcLevel() {
    //   let rProc = await this.$api("/api/reqProcLevel", {});
    //   if(rProc.length > 0) {
    //     this.rProc = rProc
    //   }
    // },
    //취소요청 리스트 
    async getReqCxlList() 
    {
      let reqCxlList1 = await this.$api("/api/reqCancelList1", {});
      if(reqCxlList1.length > 0) 
      {
        this.reqCxlList1 = reqCxlList1;
      }
      let reqCxlList = await this.$api("/api/reqCancelList", {});
      if(reqCxlList.length > 0) 
      {
        this.reqCxlList = reqCxlList;
      }
      //행사상품
      let reqCxlList2 = await this.$api("/api/reqCancelList2", {});
      if(reqCxlList2.length > 0) 
      {
        this.reqCxlList2 = reqCxlList2;
      }
      //엔버스
      let reqCxlList3 = await this.$api("/api/reqCancelList3", {});
      if(reqCxlList3.length > 0) 
      {
        this.reqCxlList3 = reqCxlList3;
      }            
      //console.log("reqCxlList1:",this.reqCxlList1,"reqCxlList:",this.reqCxlList,"reqCxlList2:",this.reqCxlList2,"reqCxlList3:",this.reqCxlList3);
    },

    goToInfo(param, pCode)
    {
      let p = "";
      p = param.substring(0, 2);

      if(p === "EV")
      {
        this.$router.push({path: '/eventInfo', query: {eCode: param}});
      }
      else if(p === "NB")
      {
        this.$router.push({path: '/nbusInquiryInfo_detail', query: {iCode: param}});
      }
      else
      {
        this.$router.push({path: '/reserveInfoDetail', query: {seqReq: param, pCode: pCode}});
        // this.$router.push({path: '/reserveInfoDetail', query: {seqReq: seqReq, pCode: pCode, cCode: cCode}});
      }
    },
    async changeProcCode() {
      if(this.rProcCode === 'A') {
        this.getRequestList();
      }
      else {
        let reqList_tmp = await this.$api("/api/paymentList", {})
        //console.log("reqList_tmp:",reqList_tmp);
        //this.reqList = reqList_tmp.filter(e => {return e.reqPayStatus === this.rProcCode});
        this.reqList = reqList_tmp.filter(e => {return e.rStatus === this.rProcCode});
      }
    }
  }
}
</script>