<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-0">
      <div class="p-0">
        <h2 class="text-center"><u>&nbsp;외 부 결 제&nbsp;</u></h2>

        <div>
          <div class="float-start text-success fs-6 mb-2">
            <b>
              [ 결제요청정보생성&nbsp; <i class="mb-2 bi  bi-pencil-square text-end text-danger" style="cursor:pointer" @click="dataBaseTransaction('C');">&nbsp;생성</i> ]
            </b>
          </div>
        </div>

        <!-- 모달영역 -->
        <!-- <div v-if="showModal" class="modal">
          <div class="modal-content">
            <span @click="showModal = false" class="close">&times;</span>
            <Modal_SPayInfoDetail :showModal="showModal" :modalContent="this.spResCode" @close="closeModal"/>
          </div>
        </div> -->

        <table class="table table-bordered border-success align-middle w-100 h-auto text-center">
          <thead>
            <tr>
              <th style="width:15%">결제상품명</th>
              <th style="width:8%">결제자</th>
              <th style="width:8%">거래생성일</th>
              <th style="width:10%">여행일자</th>
              <th style="width:10%">연락처</th>
              <th style="width:12%">결제금액</th>
              <th style="width:8%">담당자</th>
              <th style="width:5%">인원수</th>
              <th style="width:15%">비고사항</th>
            </tr>
            <tr>
              <td>
                <input type="text" class="form-control" ref="spName" placeholder="50자이내" maxlength="50" v-model="this.spName" style="font-size:smaller">
              </td>
              <td>
                <input type="text" class="form-control" ref="payer" placeholder="40자이내" maxlength="40" v-model="this.payer" style="font-size:smaller">
              </td>
              <td style="vertical-align: middle;">
                {{ this.today }}
              </td>
              <td>
                <input type="date" class="form-control" ref="tripDate" v-model="this.tripDate" style="font-size:smaller">
              </td>
              <td>
                <input type="text" class="form-control" ref="callNo" placeholder="-제외, 숫자만" maxlength="11" v-model="this.callNo" style="font-size:smaller">
              </td>
              <td>
                <input type="number" class="form-control" ref="spPrice" placeholder="숫자만" min="0" v-model="this.spPrice" style="color:red;">
              </td>
              <td>
                <select class="form-select form-select-sm" v-model="this.managerId" style="font-size: small;" ref="managerId">
                  <option :value="p.handlerId" :key="i" v-for="(p,i) in this.chargePList">{{ p.handlerNameKor }}</option>
                </select>
              </td>
              <td>
                <input type="number" class="form-control" ref="spPaxCnt" placeholder="숫자만" min="1" max="1000" v-model="this.spPaxCnt" style="font-size:smaller">
              </td>
              <td>
                <input type="text" class="form-control" ref="spPayMemo" placeholder="100자이내" maxlength="100" v-model="this.spPayMemo" style="font-size:smaller">
              </td>              
            </tr>
          </thead>
        </table>

        <div class="float-start mb-0">
          <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode"
            v-model="rProcCode" @change="changeProcCode()">
            <option :value="noVal" disabled>진행상태선택</option>
            <option value="A" >전체보기</option>
            <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.procName}}</option>
            <!-- <option value="RF" >환불</option> -->
          </select>
        </div>
        <div class="float-end mb-1">
          <input v-model="dataInput" @input="submitAutoComplete" type="text" class="form-control form-control-sm text-primary" placeholder="신청자 또는 외부결제코드" />
          <div class="autocomplete disabled">
            <div class="text-success" style="cursor: pointer" :key="i" v-for="(res,i) in result" @click="searchData(res)"><small>{{ res }}</small></div>
          </div>
        </div>
        <table class="table table-bordered table-striped fs-6">
          <thead class="small">
            <tr class="text-center">
              <!-- <th>확인</th> -->
              <th style="width:5%;">상태</th>
              <th style="width:5%;">결제</th>
              <th style="width:5%;">생성일</th>
              <th style="width:6%;">외부결제코드</th>
              <th style="width:9%;">결제상품명</th>
              <th style="width:7%;">결제자</th>
              <th style="width:8%;">여행일자</th>
              <th style="width:7%;">결제액</th>
              <th style="width:8%;">연락처</th>
              <th style="width:5%;">담당자</th>
              <th style="width:4%;">인원수</th>
              <th style="width:8%;">메모사항</th>
              <th style="width:8%;">취소사유</th>
              <th style="width:8%;">기능</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr :key="i" v-for="(rList, i) in this.reqList" class="text-center">
              <td>
                <select class="form-select form-select-sm" style="font-size:small;" aria-label=".form-select-sm" ref="cxlFlag" v-model="rList.reqStatus">
                  <option :value="pn.procCode" :key=i v-for="(pn,i) in rProc">{{pn.procName}}</option>
                </select>
                <!-- {{ rList.curStat }} -->
              </td>
              <td>{{ rList.newPaidDate }}</td>
              <td>{{ this.getDateFormat(rList.insDate) }}</td>
              <td class="text-success">
                {{ rList.spResCode }}
                <!-- <a @click="openModal(rList.spResCode);" style="cursor:pointer;">{{ rList.spResCode }}</a> -->
              </td>
              <td>
                <samll>{{ rList.spName }}</samll>
                <!-- <input type="text" class="form-control" ref="spName" placeholder="50자이내" maxlength="50" v-model="rList.spName" style="font-size:small"> -->
              </td>
              <td>
                <input type="text" class="form-control" ref="payerName" maxlength="50" v-model="rList.payerName" style="font-size:small">
              </td>
              <td>
                <input type="date" class="form-control" ref="tripDate" style="font-size:small" v-model="rList.tripDate">
              </td>
              <td>
                <input type="number" class="form-control" ref="payAmt" style="font-size:small" v-model="rList.payAmt">
              </td>
              <td>
                <input type="text" class="form-control" ref="callNo" style="font-size:small" v-model="rList.callNo">
              </td>
              <td>
                <select class="form-select form-select-sm" v-model="rList.managerId" style="font-size: small;" ref="managerId">
                  <option :value="m.handlerId" :key="i" v-for="(m,i) in this.chargePList">{{ m.handlerNameKor }}</option>
                </select>
              </td>
              <td>
                <input type="number" class="form-control" ref="spPaxCnt" style="font-size:small" min="1" max="1000" v-model="rList.spPaxCnt">
              </td>              
              <td>
                <input type="text" class="form-control" ref="spRemark" style="font-size:small" v-model="rList.spRemark">
              </td>
              <td>
                <select class="form-select form-select-sm" style="font-size:small;" aria-label=".form-select-sm" ref="cxlFlag" v-model="rList.cxlFlag">
                  <option :value="cx.procCode" :key=i v-for="(cx,i) in cxlFlagList">{{cx.procName}}</option>
                </select>
              </td>
              <td>
                <button type="button" class="btn btn-primary btn-sm me-1" @click="infoUpdate(rList.reqStatus, rList.payerName, rList.tripDate, rList.callNo, rList.payAmt, rList.managerId, rList.spRemark, rList.cxlFlag, rList.spPaxCnt, rList.spResCode);" v-if="rList.curStat != 'CX' && rList.curStat != 'CO'">
                  <div style="font-size:smaller">변경</div>
                </button>
                <button type="button" class="btn btn-outline-danger btn-sm me-1" @click="infoCxl(rList.spResCode, rList.cxlFlag);"  v-if="rList.curStat != 'CX'">
                  <div style="font-size:smaller">취소</div>
                </button>
                <button type="button" class="btn btn-warning btn-sm me-1" @click="sendKakao(rList.reqStatus, rList.payerName, rList.tripDate, rList.callNo, rList.payAmt, rList.managerId, rList.cxlFlag, rList.spPaxCnt, rList.spResCode, rList.spName, rList.insDate);" v-if="rList.curStat == 'RP' || rList.curStat == 'CX' || rList.curStat == 'CO'">
                  <div style="font-size:smaller">발신</div>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </main>
  </div>
</template>

<script>
import moment from 'moment';
import {genCode} from '../assets/js/genCode'
import { handlerInfo } from '../assets/js/commonFunctions'
//import Modal_SPayInfoDetail from "./Modal_SPayInfoDetail.vue"

export default {
  // components: {
  //   Modal_SPayInfoDetail
  // },
  data() {
    return {
      //modal
      // showModal: false,
      // modalContent: "",

      chargePList: [],        //담당자리스트

      //외부결제생성정보
      spCode: "",               //외부결제코드
      spName: "",               //결제상품명
      payer: "",                //결제자명
      tripDate: "",             //여행일
      callNo: "",               //연락처
      spPrice: 0,               //외부결제금액
      managerId: "",            //선택담당자
      spPayMemo: "",            //비고사항
      spPaxCnt:1,               //참여인원

      reqList: [],              //외부결제정보
      rProc: [],                //진행상태리스트

      cxlFlagList: [],          //취소사유리스트

      skillInput: null,
      result: null,
      arrSearchData: [],
      
      handTel: [],                //매니저연락처
      handTelCnt: 0               //수신처갯수
    };
  },
  created() {
    this.getReqProcLevel();
    this.getManagerList() 

    const today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")

    this.getRequestConsultList();

    this.getHandlerInfo()           //알림톡 발송대상 체크 
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    //document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) 
    {
      return this.$currencyFormat(value);
    },

    // //외부결제상세(모달처리)
    // async openModal(val)
    // {
    //   console.log("val: ",val)

    //   //모달표시
    //   this.showModal = true;
    //   this.spResCode = val;      
    // },
    // closeModal(){
    //   this.showModal = false;
    // },

    async getManagerList() 
    {
      //담당자정보
      let chargePList = await this.$api("/api/handlerInfo", {})
      if(chargePList.length > 0)
      {
        this.chargePList = chargePList;
      }
      //console.log("chargePList:", this.chargePList)
    },

    async getReqProcLevel() {
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }
      //console.log("rProc:",this.rProc);
    },

    //외부결제번호생성
    async genCode(param) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 1000 && chkFlag === "N"; attempts++) {
        let today = new Date();
        let yy = today.getFullYear().toString().slice(-2);
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let ymd = `${yy}${mm}${dd}`;
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);
        //console.log("rtnCode:",rtnCode)

        let chk_genCode = await this.$api("/api/checkDub_evCode", { param: [rtnCode] });

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N") 
      {
        this.$swal.fire("","결제코드 생성횟수 한계에 도달하였습니다.<br>담당자에게 문의(1660-4602)하여 주세요.","warning")
        return false;
      } 
      else 
      {
        return rtnCode
      }
    },

    dataBaseTransaction(p1){
      if(p1==="C") 
      { //행사 생성처리
        //console.log("memberFlag:",this.memberFlag);
        this.$swal.fire({
        icon: 'question',
        text: '외부결제를 생성합니까?',
        showCancelButton: true,
        confirmButtonText: '생성',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            let catCode = "SP-"
            let spCode = await this.genCode(catCode)
            this.spCode = spCode      //외부결제코드

            let reqStatus = "IQ";     //진행상태

            let insSpayInfo = await this.$api("/api/insSpayInfo", {param: [
              this.spCode, reqStatus, this.spName, this.payer, this.tripDate, this.callNo, this.spPrice, this.managerId, this.spPayMemo, this.user.handlerId, this.spPaxCnt
            ]})

            if(insSpayInfo.affectedRows < 0 || insSpayInfo.affectedRows === undefined)
            {
              this.$swal.fire('', '생성 중 오류가 발생했습니다(insSpayInfo)', 'error');
              return false;
            }
            else
            {
              this.$swal.fire('', '결제정보가 생성되었습니다', 'success');
              location.reload(true);
            }
          }
        })
      }
    },

    //결제요청 정보처리
    async insReqPay(rCode, rStat, payAmt)
    {
      let checkFlag = ""
      if(rStat === 'RP')
      {
        checkFlag = "N"
      }
      else if(rStat === 'CO')
      {
        checkFlag = "Y"
      }

      let insReqPayInfo = await this.$api("/api/proc_reqSpPay", {param:[
        rCode, rStat, payAmt, checkFlag, this.user.handlerId
      ]})
      if(insReqPayInfo.affectedRows < 0) 
      {
        this.$swal.fire('','처리 중 오류발생(insReqPayInfo)<br>관리자에게 문의하세요', 'error')
        return false;
      }
    },

    //외부결제정보변경
    async infoUpdate(rStat, payer, tripDate, callNo, payAmt, managerId, spRemark, cxlFlag, spPaxCnt, rCode){
      if(rStat === 'CX' && (!cxlFlag || cxlFlag == ''))
      {
        this.$swal.fire("", "취소사유를 선택하세요!", "warning")
        return false
      }

      let sPayInfoUpdate = await this.$api("/api/sPayInfoUpdate", {param:[
        rStat, payer, tripDate, callNo, payAmt, managerId, spRemark, cxlFlag, this.user.handlerId, spPaxCnt, rCode
      ]})

      if(sPayInfoUpdate.affectedRows >= 0)
      {
        if(rStat === 'RP')
        {
          this.insReqPay(rCode, rStat, payAmt)
        }
        else if(rStat === 'CO')
        {
          this.insReqPay(rCode, rStat, payAmt)
        }        
        this.$swal.fire("","정보가 변경되었습니다","info")
        location.reload(true)
      }
      else
      {
        this.$swal.fire("","정보가 변경 중 오류가 발생했습니다(sPayInfoUpdate)","error")
        return false
      }
    },

    //외부결제취소
    async infoCxl(rCode, cxlFlag){

      if(!cxlFlag || cxlFlag == '')
      {
        this.$swal.fire("", "취소사유를 선택하세요!", "warning")
        return false
      }

      let sPayInfoCxl = await this.$api("/api/sPayInfoCxl", {param:[
        cxlFlag, this.user.handlerId, rCode
      ]})

      if(sPayInfoCxl.affectedRows >= 0)
      {
        this.$swal.fire("","취소되었습니다","info")
        location.reload(true)
      }
      else
      {
        this.$swal.fire("","취소 중 오류가 발생했습니다(sPayInfoCxl)","error")
        return false
      }
    },

    async getHandlerInfo()
    {
      let handTel = await handlerInfo(this.$api);
      if(handTel.length > 0)
      {
        this.handTel = handTel
      }
      //console.log("handTel: ", this.handTel)
    },

    //외부결제상태 카카오알림
    async sendKakao(reqStatus, payerName, tripDate, callNo, payAmt, managerId, cxlFlag, spPaxCnt, spResCode, spName, insDate){
      
      let cxlReason = ""
      if(reqStatus === 'CX' && (!cxlFlag || cxlFlag == ''))
      {
        this.$swal.fire("", "취소사유를 선택하세요!", "warning")
        return false
      }
      else if(reqStatus === 'CX' && (cxlFlag != ''))
      {
        //취소사유처리
        let cxlReason_tmp = this.cxlFlagList.filter((item) => {
          return item.procCode == cxlFlag
        })
        cxlReason = (cxlReason_tmp[0].procName == null || cxlReason_tmp[0].procName == '') ? "" : cxlReason_tmp[0].procName
      }

      let param = "non" + reqStatus

      //담당자처리
      let managerName_tmp = this.chargePList.filter((item) => {
        return item.handlerId == managerId
      })
      //console.log("managerName_tmp: ", managerName_tmp)
      let managerName = (managerName_tmp[0].handlerNameKor == null || managerName_tmp[0].handlerNameKor == '') ? "" : managerName_tmp[0].handlerNameKor


      let insKSendHistory = await this.$api("/api/insKSendHistory", {param: [
        spResCode, param, this.user.handlerId
      ]});

      //알림톡 발송대상 확정
      this.handTel.push({aimCall: callNo})
      this.handTelCnt = this.handTel.length

      if(insKSendHistory.affectedRows > 0) 
      {
        let promises = []
        
        if(param == "nonRP") 
        {
          let i = 0
          while(i < this.handTelCnt)
          {
            //비회원용 외부결제요청 카카오 알림톡
            let kakaoAlimData = 
            {
              //카카오 알림톡 발송(결제요청)
              clientName: payerName,
              resName: spName,
              resPaxName: payerName,
              resPaxCnt: spPaxCnt,
              dptDate: tripDate,
              resPhone: callNo,
              resCode: spResCode,
              resPayAmount: this.getCurrencyFormat(payAmt),
              resManager: managerName,
              alimFlag: param,                                        //알림유형(결제요청)
              pdtCode: spResCode,                                     //비회원예약 결제처리용 상품코드
              clientCall: this.handTel[i].aimCall,                      //알림톡발송대상
            };

            let promise = this.$api("api/kakaoAlimSend2", {
              param: [{ kakaoAlimData: kakaoAlimData}]
            })

            promises.push(promise)
            i++
          }

          //반복문 이후 처리
          Promise.all(promises).then(() => {
            //console.log(results)
            this.$swal.fire('', '결제요청 알림톡이 발송되었습니다', 'success').then(() => {
              location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error)
          })
        }

        else if(param == "nonCO") 
        {
          let j = 0
          while(j < this.handTelCnt)
          {
            //카카오 알림톡 발송(확정)
            let kakaoAlimData = 
            {
              clientName: payerName,
              resCode: spResCode,
              resName: spName,
              resPrice: this.getCurrencyFormat(payAmt),
              //alimCall: callNo,
              alimCall: this.handTel[j].aimCall,     //알림톡발송대상
              alimFlag: param,
              pdtCode: spResCode                     //비회원예약 결제처리용 상품코드
            };

            let promise = this.$api("api/kakaoAlimSend", {
              param: [{ kakaoAlimData: kakaoAlimData}]
            })

            promises.push(promise)

            j++
          }

          //반복문 이후 처리
          Promise.all(promises).then(() => {
            //console.log(results)
            this.$swal.fire('', '예약확정 알림톡이 발송되었습니다', 'success').then(() => {
              location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error)
          })
        } 

        else if(param == "nonCX") 
        {
          let i = 0
          while(i < this.handTelCnt)
          {
            //카카오 알림톡 발송(취소)
            let kakaoAlimData = 
            {
              clientName: payerName,
              resCode: spResCode,
              resDate: this.getDateFormat(insDate),
              dptDate: tripDate,
              cxlDate: this.today, 
              cxlRemark: cxlReason,                      //취소사유 
              alimCall: callNo,
              //clientCall: callNo,                      //카톡발송연락처
              clientCall: this.handTel[i].aimCall,       //알림톡발송대상
              pdtCode: spResCode,                        //비회원예약 결제처리용 상품코드
              alimFlag: param,
            };
            let promise = this.$api("api/kakaoAlimSend2", {
              param: [{ kakaoAlimData: kakaoAlimData}]
            })

            promises.push(promise)            
            i++
          }

          //반복문 이후 처리
          Promise.all(promises).then(() => {
            //console.log(results)
            this.$swal.fire('', '예약취소 알림톡이 발송되었습니다', 'success').then(() => {
              location.reload(true)
            })
          }).catch((error) => {
            console.log("Error calling api: ", error)
          })
        }        
      }
    },


    async getRequestConsultList() {
      let reqList = await this.$api("/api/sPayInfoList", {});

      //예약진행상태
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }

      //취소사유
      let cxlFlagList = await this.$api("/api/reqStatusInfo2", {param: ['CXL', 'Y']});
      if(cxlFlagList.length > 0) 
      {
        this.cxlFlagList = cxlFlagList
      }
      //console.log("cxlFlagList: ", this.cxlFlagList)
      
      let i=0;
      let arrSearchData = [];
      if(reqList.length > 0) {
        this.reqList = reqList;

        while(i<reqList.length) 
        {
          this.reqList[i].tripDate = this.getDateFormat(reqList[i].tripDate)    //날짜형식표시용
          this.reqList[i].curStat = reqList[i].reqStatus
          
          arrSearchData.push(reqList[i].spResCode, reqList[i].payerName);
          i++;
        }
      }
      this.arrSearchData = arrSearchData;
      //console.log("reqList:",this.reqList)
    },
    //자동완성기능
    submitAutoComplete() {
      const autocomplete = document.querySelector(".autocomplete");
      let result_tmp = [];
      if (this.dataInput) {
        autocomplete.classList.remove("disabled");
        autocomplete.style.display = "block"
        result_tmp = this.arrSearchData.filter((e) => {
          return String(e).match(new RegExp("^" + this.dataInput.toUpperCase(), "i"));
        });
        //결과값 중복제거
        this.result = Array.from(new Set(result_tmp));        
      }
      else {
        autocomplete.classList.add("disabled");
        autocomplete.style.display = "none";
        this.getRequestConsultList()
      }
    },
    searchData(res) {
      // console.log("reqList1:", this.reqList);
      //console.log("res:",res);
      let reqList_tmp = this.reqList;
      if(res.slice(0,2) === "SP") {
        this.reqList = reqList_tmp.filter(e => {return e.spResCode === res});
      }
      else {
        this.reqList = reqList_tmp.filter(e => {return e.payerName === res});
      }

      //console.log("reqList_searchData:",this.reqList);
    },
    refProduct(pdtCode) {
      this.$router.push({path: '/detail', query: {productId: pdtCode}});
    },
    goToReqReserveDetail(seqReq, pCode, cCode) {
      this.$router.push({path: '/reserveInfoDetail', query: {seqReq: seqReq, pCode: pCode, cCode: cCode}});
      //console.log("oId:", orderId);
    },
    async changeProcCode() {
      if(this.rProcCode === 'A') 
      {
        this.getRequestConsultList();
      }  
      else 
      {
        let reqList_tmp = await this.$api("/api/sPayInfoList", {})
        //console.log("reqList_tmp:",reqList_tmp);
        this.reqList = reqList_tmp.filter(e => {return e.reqStatus === this.rProcCode});
      }
    },
  }
}
</script>