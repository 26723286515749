<template>
  <main class="mt-3">
  <div>
    <div class="mb-3 row">
      <div class="container-fluid">
          <h2 class="text-center"><u>&nbsp;입 금 기 록&nbsp;</u></h2>
          <!-- <div class="fixed-top"> -->
            <div>
              <div class="float-start text-success fs-6 mb-2">
                <b>
                [ 입금기록&nbsp; <i class="mb-2 bi  bi-pencil-square text-end text-danger" style="cursor:pointer"
                  @click="dataBaseTransaction('C','D');">&nbsp;기록</i> ] &nbsp;
                <i class="bi bi-exclamation-triangle-fill text-primary"></i>입금내역을 기록합니다
                </b>
              </div>

              <div class="float-end mb-2" style="display: flex; align-items: center;">
                <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_sellerCode" v-model="this.sellerCode" style="font-size: smaller; width:150px">
                  <option value="NT">엔타비</option>
                  <option value="ND">엔데트</option>
                  <option value="SO">소면공방</option>
                </select>&nbsp;&nbsp;
                <select class="form-select form-select-sm" v-model="this.bankCd" style="font-size: smaller; width:150px" ref="finCd" :style="{ color: getOptionColor(this.depositInfo.finCd) }">
                  <option :value="f.fCodeNo" :key="i" v-for="(f,i) in this.finBankingInfo" :style="{ color: getOptionColor(f.fCodeNo) }">{{ f.fName }}</option>
                </select>&nbsp;&nbsp;
                <b>
                  <input type="file" class="btn btn-outline-secondary btn-sm" ref="searchExcel" @change="handleFileUpload">
                </b>
              </div>

            </div>
            <table class="table table-bordered border-success align-middle w-100 h-auto text-center">
              <thead>
                <tr>
                  <th>정산처</th>
                  <th>입금일</th>
                  <th>입금자</th>
                  <th>입금내역</th>
                  <th>입금은행</th>
                  <th>입금액</th>
                  <th>메모사항</th>
                  <!-- <th>내용번경</th> -->
                </tr>
                <tr>
                  <td>
                    <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_sellerCode" v-model="this.depositInfo.sellerCode" :style="{color: getOptionColor(this.depositInfo.sellerCode)}">
                      <option :value="noVal" disabled>-정산처-</option>
                      <option :value="sl.pCode" :key="i" v-for="(sl, i) in this.partnerInfo" :style="{ color: getOptionColor(sl.pCode) }">{{ sl.pName }}</option>
                    </select>
                  </td>
                  <td>
                    <input type="date" class="form-control" ref="txt_depositDate" v-model="this.depositInfo.depositDate" style="font-size:x-small">
                  </td>
                  <td>
                    <input type="text" class="form-control" ref="txt_depositor" placeholder="50자이내" maxlength="50" v-model="this.depositInfo.depositor" style="font-size:smaller">
                  </td>
                  <td>
                    <input type="text" class="form-control" ref="txt_depositDetail" placeholder="200자이내" maxlength="200" v-model="this.depositInfo.depositDetail" style="font-size:smaller">
                  </td>
                  <td>
                    <select class="form-select from-select-sm" v-model="this.depositInfo.finCd" style="font-size: smaller;" ref="finCd" :style="{ color: getOptionColor(this.depositInfo.finCd) }">
                      <option :value="f.fCodeNo" :key="i" v-for="(f,i) in this.finBankingInfo" :style="{ color: getOptionColor(f.fCodeNo) }">{{ f.fName }}</option>
                    </select>
                  </td>
                  <td>
                    <input type="number" class="form-control" ref="txt_depositAmt" v-model="this.depositInfo.depositAmt" style="font-size:small">
                  </td>
                  <td>
                    <input type="text" class="form-control" ref="txt_depositMemo" placeholder="200자이내" maxlength="200" v-model="this.depositInfo.depositMemo" style="font-size:smaller">
                  </td>
                </tr>
              </thead>
            </table>
          <!-- </div> -->

          <!-- 내용검색 -->
          <div class="text-success text-start fs-6 mb-2">
            <b>[ &nbsp; 입금기록 검색조건 &nbsp; ]</b>
          </div>
          <table class="table table-bordered border-success align-middle w-100 h-auto text-center">
            <tbody>
              <tr>
                <th class="table-secondary">
                  정산처
                </th>
                <td style="width:15%;">
                  <select class="form-select form-select-sm" v-model="this.partnerCode" :style="{ color: getOptionColor(this.partnerCode) }">
                    <option value="all" selected>전체</option>
                    <option :value="p.pCode" :key="i" v-for="(p, i) in this.partnerInfo" :style="{ color: getOptionColor(p.pCode) }">
                      {{ p.pName }}
                    </option>
                  </select>
                </td>

                <th class="table-secondary">
                  입금은행
                </th>
                <td  style="width:10%;">
                  <select class="form-select from-select-sm" v-model="this.finCd" style="font-size: smaller;" ref="finCd" :style="{ color: getOptionColor(this.finCd) }">
                    <option value="all" selected>전체</option>
                    <option :value="f.fCodeNo" :key="i" v-for="(f,i) in this.finBankingInfo" :style="{ color: getOptionColor(f.fCodeNo) }">{{ f.fName }}</option>
                  </select>
                </td>

                <th class="table-secondary col-md-1" style="font-size:smaller">
                  입금기간
                </th>
                <td  style="width:25%;">
                  <div class="input-group">
                    <span class="input-group-text input-group-sm" style="font-size:smaller">시작</span>
                    <input type="date" class="form-control form-control-sm" ref="searchStart" v-model="this.dSearchStart" style="font-size:smaller">
                    <span class="input-group-text input-group-sm" style="font-size:smaller">종료</span>
                    <input type="date" class="form-control form-controm-sm" ref="searchEnd" v-model="this.dSearchEnd" style="font-size:smaller">
                  </div>
                </td>
                
                <th class="table-secondary col-md-1" style="font-size:smaller">
                  입금자
                </th>
                <td>
                  <input type="text" v-model="this.depositorName" style="font-size:smaller" class="resize-input" maxlength="40">
                </td>
              </tr>

              <tr>
                <th class="table-secondary col-md-1" style="font-size:smaller">
                  입금액
                </th>
                <td>
                  <div class="input-group">
                    <input type="text" class="form-control form-control-sm" ref="minMoney" v-model="this.minMoney" style="font-size:smaller">
                    <span class="input-group-text input-group-sm" style="font-size:smaller"><b>~</b></span>
                    <input type="text" class="form-control form-controm-sm" ref="maxMoney" v-model="this.maxMoney" style="font-size:smaller">
                  </div>
                </td>

                <th class="table-secondary col-md-1" style="font-size:smaller">
                  입금내역
                </th>
                <td>
                  <input type="text" v-model="this.depositD" style="font-size:smaller" class="resize-input" maxlength="200">
                </td>

                <th class="table-secondary col-md-1" style="font-size:smaller" >
                  메모사항
                </th>
                <td>
                  <input type="text" v-model="this.depositMm" style="font-size:smaller" class="resize-input" maxlength="200">
                </td>

                <th class="table-secondary col-md-1" style="font-size:smaller" >
                  입금기록코드
                </th>
                <td>
                  <input type="text" v-model="this.exDepoCode" style="font-size:smaller" class="resize-input" maxlength="200">
                </td>
              </tr>
            </tbody> 
          </table>
          <div class="text-success text-end fs-6 mb-2">
            <b><button type="button" class="btn btn-outline-danger btn-sm" ref="searchBtn" @click="searchSettleData();">검색하기</button></b>&nbsp;&nbsp;&nbsp;&nbsp;
            <b><button type="button" class="btn btn-outline-primary btn-sm" ref="searchBtn" @click="cleanLocalStorage">초기화</button></b>            
          </div>

          <!-- 모달영역 -->
          <div v-if="showModal" class="modal">
            <div class="modal-content">
              <span @click="showModal = false" class="close">&times;</span>
              <Modal_DepositSplit :showModal="showModal" :modalContent="this.sel_dExdepositCode" @close="closeModal"/>
            </div>
          </div>

          <div class="text-success text-start fs-6 mt-3 mb-2 d-flex align-items-center">
              <b>[ 입금내역리스트 ]</b>
              &nbsp;&nbsp;
              <div>
                <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="dataBaseTransaction('U','D')" style="font-size: smaller">
                  변경
                </button>
                <button type="button" class="btn btn-outline-danger btn-sm me-1" @click="delDeposit()" style="font-size:smaller">
                  삭제
                </button>
              <span style="color:green; font-size: smaller;">(기본제공결과는 금일을 기준으로 과거1개월 분입니다)</span>
            </div>
            <div class="ms-auto">
              <button type="button" class="btn btn-info btn-sm me-1" @click="showList('ND')" style="font-size: smaller">
                미분할 내역
              </button>
              <button type="button" class="btn btn-warning btn-sm me-1" @click="showList('ID')" style="font-size:smaller">
                분할 중 내역
              </button>
            </div>
          </div>

          <!-- <div v-if="loading">
            <p class="text-primary fw-bold">데이타 분류처리 중...</p>
            <div class="progress">
              <div class="progress-bar" role="progressbar" :style="{width: progress + '%'}" aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{ progress }}%</div>
            </div>
          </div> -->

        <table class="table fs-6 w-100 h-auto text-center">
          <table class="table table-striped">
            <thead>
              <tr>
                <th style="width: 50px">선택</th>
                <th style="width: 100px">정산처</th>
                <th style="width: 120px">입금일</th>
                <th style="width: 180px">입금자</th>
                <th style="width: 300px">입금내역</th>
                <th style="width: 130px">입금은행</th>
                <th style="width: 130px">입금액</th>
                <th>메모사항</th>
                <th style="width: 120px">추가기능</th>
              </tr>
            </thead>
            <!-- <tbody :key="i" v-for="(di, i) in this.depositInfoList" class="table_sup"> -->
              <!-- diffValue==0 -> 분할완료  -->
            <tbody :key="i" v-for="(di, i) in depositInfoList" :class="di.diffValue == 0 ? 'table_sup1' : 'table_sup'">
                <!-- Your table row content here -->  
              <tr>
                <td  style="width: 50px">
                  <input type="checkbox" class="form-check-input form-check-inline" ref="chk_deposit" v-model="checkedSeq" :value=i v-if="di.checkFlag === 'Y'" disabled>
                  <input type="checkbox" class="form-check-input form-check-inline" ref="chk_deposit" v-model="checkedSeq" :value=i v-else>
                  <i class="bi bi-pin-fill text-danger" v-if="di.checkFlag === 'Y'"></i>
                </td>
                <td>
                  <select class="form-select form-select" style="font-size:smaller" aria-label=".form-select-sm" ref="sel_sellerCode" v-model="di.sellerCode" :style="{ color: getOptionColor(di.sellerCode) }">
                    <option :value="sl.pCode" :key="i" v-for="(sl, i) in this.partnerInfo" :style="{ color: getOptionColor(sl.pCode) }">
                      {{ sl.pName }}
                    </option>
                  </select>
                </td>
                <td>
                  <div>
                    <input type="date" class="form-control" ref="txt_depositDate" style="font-size:xx-small" v-model="di.depositDate">
                  </div>
                </td>
                <td>
                  <input type="text" class="form-control" name="active" style="font-size:smaller" v-model="di.depositor">
                </td>
                <td>
                  <input type="text" class="form-control" style="font-size:smaller" v-model="di.depositDetail">
                </td>
                <td>
                  <select class="form-select from-select-sm" v-model="di.finCd" style="font-size: smaller;" ref="finCd" :style="{ color: getOptionColor(di.finCd) }">
                    <option :value="fb.fCodeNo" :key="j" v-for="(fb,j) in this.finBankingInfo" :style="{ color: getOptionColor(fb.fCodeNo) }">{{ fb.fName }}</option>
                  </select>
                </td>                
                <td>
                  <input type="text" class="form-control text-end" v-model="di.depositAmt" style="caret-color: red; font-size:smaller; color:red;">
                </td>
                <td>
                  <input type="text" class="form-control" style="font-size:smaller" v-model="di.depositMemo">
                </td>
                <td>
                  <button type="button" class="btn btn-secondary btn-sm me-1" @click="showHistory(di.exDepositNo, i)"
                    v-if="di.depositSplitFlag === 'Y'">
                    <small><i class="bi bi-caret-down-fill"></i></small>
                  </button>
                  <button type="button" class="btn btn-primary btn-sm me-1" @click="splitDepositAmt(di.exDepositNo, di.checkFlag)"
                    v-if="di.depositSplitFlag === 'Y'">
                    <small><small>분할</small></small>
                  </button>
                  <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="splitDepositAmt(di.exDepositNo, di.checkFlag)" v-else>
                    <small><small>분할</small></small>
                  </button>
                  <button type="button" class="btn btn-danger btn-sm me-1" @click="checkDeposit(di.exDepositNo, 'N')" v-if="di.checkFlag === 'Y' && this.user.handlerGrade < 6">
                    <small><small>취소</small></small>
                  </button>
                  <button type="button" class="btn btn-outline-danger btn-sm me-1" @click="checkDeposit(di.exDepositNo, 'Y')" v-else-if="this.user.handlerGrade<6 && (di.checkFlag != 'Y' || di.checkFlag == undefined)">
                    <small><small>마감</small></small>
                  </button>
                </td>
              </tr>

              <!-- 선택된 분할내역 히스토리 표시 -->
              <tr v-if="rowIndexToShow ===i">
                <td colspan="9">
                  <table class="table table-striped table-bordered" style="border-color:lightgray">
                    <thead class="text-secondary" style="font-size: small; font-weight: bold; font-style: italic; background-color:lightyellow;">
                      <td style="width: 3%;"></td>
                      <td style="width: 5%;">확정</td>
                      <td style="width: 8%;">분할액(원)</td>
                      <td style="width: 25%;">행사명</td>
                      <td style="width: 10%;">분할일자</td>
                      <td style="width: 10%;">처리자</td>
                      <td style="width: 30%;">메모사항</td>
                      <td style="width: 10%;">-</td>
                    </thead>
                    <tbody class="tbody-sp">
                      <tr :key=j v-for="(e, j) in this.exdepositSplitHistory">
                        <td>{{ j+1 }}</td>
                        
                        <td class="col-1 text-danger" v-if="e.exFixFlag === 'Y'">Y</td>
                        <td class="col-1" style="font-weight: bold; color:red" v-else-if="e.dealStatus === 'SE'">정산마감</td>
                        <td v-else></td>
                        
                        <td :style="{ fontSize: 'small', textAlign: 'end', color: parseFloat(e.splitDepositAmt) < 0 ? 'red' : 'black' }">
                          <b>{{ getCurrencyFormat(parseFloat(e.splitDepositAmt)) }}</b>
                        </td>

                        <td v-if="this.showSelDeal === 'N' && e.dealName == undefined" class="text-start">
                          {{ e.simpleName }}
                          <p style="font-size:small;">{{ e.exDepositNo }} // {{ e.dealCode }}</p>
                        </td>
                        <td v-else-if="this.showSelDeal === 'Y' && e.dealName == undefined" class="text-start">
                          {{ e.simpleName }}
                          <p style="font-size:small;">{{ e.exDepositNo }} // {{ e.dealCode }}</p>
                          <input v-model="dataInput" @input="submitAutoComplete()" type="text" class="form-control form-control-sm resize-input" placeholder="행사명(※ 정산완료된 행사는 검색제외)" :ref="dataInput[j]" :id="j" v-if="j === this.idx"/>
                          <div class="autocomplete disabled">
                            <div class="text-start" style="cursor: pointer" :key="i" v-for="(res,i) in this.result" @click="depositSplit('SA',e.exDepositNo,res[1],e.exSubNo,e.splitDepositAmt,e.splitMemo)" :id="j">
                              <div class="mt-2" v-if="j === idx">&nbsp;&nbsp;
                                <font style="color:darkblue">{{ res[0] }}</font> 
                                <font style="color:darkgray; font-size:smaller">({{ res[1] }})</font>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td v-else>
                          <a @click="goToBizinfo_detail(e.dealCode);" style="cursor:pointer" class="text-primary">{{ e.dealName }}</a>
                          <p style="font-size:smaller;">{{ e.exDepositNo }} // {{ e.dealCode }}</p>
                        </td>

                        <td>
                          {{this.getDateFormat(e.splitDate) }}
                        </td>
                        <td>
                          {{ e.spName }}
                        </td>

                        <td class="text-center">
                          {{ e.splitMemo }}
                        </td>                        
                        
                        <td v-if="di.checkFlag === 'Y'" class="text-danger">마감</td>
                        <td v-else>
                          <button type="button" class="btn btn-success btn-sm me-1" @click="splitApply(e.exFixFlag,'N',j)" v-if="e.dealName != undefined">
                            <small>적용</small>
                          </button>
                          <button type="button" class="btn btn-outline-success btn-sm me-1" @click="splitApply(e.exFixFlag,'Y',j)" v-else>
                            <small>적용</small>
                          </button>

                          <button type="button" class="btn btn-secondary btn-sm me-1" @click="splitCxl(e.exFixFlag,'Y',e.exDepositNo,e.exSubNo,e.dealCode)" v-if="this.user.handlerGrade<10">
                            <small>삭제</small>
                          </button>
                          <button type="button" class="btn btn-warning btn-sm me-1" @click="splitConfirm('CX',e.exDepositNo,e.exSubNo,e.dealCode)" v-if="e.exFixFlag == 'Y' && this.user.handlerGrade<10">
                            <small>취소</small>
                          </button>
                          <button type="button" class="btn btn-outline-warning btn-sm me-1" @click="splitConfirm('CO',e.exDepositNo,e.exSubNo,e.dealCode)" v-else-if="(e.exFixFlag == 'N' || e.exFixFlag == undefined) && this.user.handlerGrade<6">
                            <small>확정</small>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </td>
              </tr>

              <tr v-if="this.showTR === 'Y'">
                <td>{{ di.exDepositNo }}</td>
              </tr>

            </tbody>
          </table>
        </table>
      </div>
    </div>

    <div class="mb-3 row">
      <!-- <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-primary" @click="productInsert();">저장</button>
      </div> -->
      <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-success" @click="goToBack()">돌아가기</button>
      </div>
      <!-- <div class="col-auto d-grid p-1">
        <button type="button" class="btn btn-lg btn-secondary" @click="callTest();">test</button>
      </div> -->
    </div>
  </div>
  </main>
</template>

<script>
import Modal_DepositSplit from "./Modal_DepositSplit.vue"
import * as XLSX from 'xlsx'

export default {
  components: {
    Modal_DepositSplit
  },
  data() {
    return {
      //modal컨트롤
      showModal: false,
      modalContent: "",

      bankCd: "BWO",                //엑셀업로드용 입금은행(def)
      sellerCode: "NT",             //엑셀업로드용 거래처(def)
      //sel_dExdepositCode: "",     //선택된 내역번호

      partnerInfo: [],              //거래처리스트(내부용)
      finBankingInfo: [],           //거래은행리스트(내부용)

      exdepositSplitHistory: [],    //분할내역

      //검색조건
      partnerCode: "",              //입금처코드
      dSearchStart: "",             //입금검색(시작)
      dSearchEnd: "",               //입금검색(종료)
      depositorName: "",            //입금자
      minMoney: "",                 //입금액(최소)
      maxMoney: "",                 //입금액(최대)
      depositD: "",                 //입금내역
      depositMm: "",                //입금메모
      finCd: "",                    //입금은행코드
      exDepoCode: "",              //입금기록코드

      excelUpData: {},              //엑셀업로드데이타

      depositInfo: {
        exDepositNo: "",            //외부입금번호
        depositDate: "",            //입금일자
        depositor: "",              //입금자
        finCd: "BWO",               //입금은행(기본값: 우리은행)
        depositDetail: "",          //입금내역
        depositAmt: 0,              //입금금액
        depositMemo: "",            //입금메모
        depositSplitFlag: "N",      //입금분할여부
        checkId: "",                //체크아이디
        checkDate: "",              //체크일자
        //splitDepositAmt: 0,       //입금분할금액
        insId: "",                  //입력자
        modId: "",                  //수정자
        dUseFlag: "Y",              //유효여부
        sellerCode: "NT",           //기본값은 엔타비
        delFlag: "N",               //삭제플래그
        splitFlag: "N"              //분할플래그
      },
      depositInfoList: [],          //입금내역리스트
      exDepositDetail: [],          //외부입금내역
      sellerList: [],               //판매자리스트
      showTR: "N",                  //내역영역표시
      showSpHistory: "N",           //분할내역표시
      rowIndexToShow: null,         //다른 데이타 속성
      showSelDeal: "N",             //행사선택창 보이기
      dataInput: "",                //자동완성을 위한 입력값
      
      new_splitMemo:"",             //분할적용-신규메모
      sel_depositInfoList: [],      //선택된입금내역리스트

      idx: null,                    //처리할 라인의 인덱스
      checkedSeq: [],               //체크된 입금내역번호
      //viewTr: "N",                //입금분할여부
      addLine: "N",                 //추가라인
      //tdCnt: 0,                   //추가될 row수
      //신규분할내역정보
      calcRes: 0,
      calcSplitDepositAmt: 0,
      newSplitMemo:"",

      depositInfoList_org: [],      //서치결과 레거시

      // //배치작업 표시
      // loading: false,               //로딩상태
      // progress: 0,                  //진행상태(0~100)
      // totalBatches: 0,              //총batch수
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    //console.log("handler:",this.user.handlerId);
    this.lnk_exCode = this.$route.query.lnk_exCode
    
    if(this.lnk_exCode != undefined)
    {
      this.getDepositInfo_linked(this.lnk_exCode)
    }
    else
    {
      this.getDepositInfo();
    }

    this.getSellerList();
    this.getPartnerList();
    this.getFinBankingInfo();

    // 저장된 검색기록 이용
    const savedCondition = localStorage.getItem('searchCondition');
    if (savedCondition) 
    {
      const parsedCondition = JSON.parse(savedCondition);
        // this.sellerCode = parsedCondition.sellerCode || 'NT';  // 기본값 설정
        // this.bankCd = parsedCondition.bankCd || '';
      this.partnerCode    = parsedCondition.partnerCode || ""
      this.finCd          = parsedCondition.finCd || ""
      this.dSearchStart   = parsedCondition.dSearchStart || ""
      this.dSearchEnd     = parsedCondition.dSearchEnd || ""
      this.depositorName  = parsedCondition.depositorName || ""
      this.minMoney       = parsedCondition.minMoney || ""
      this.maxMoney       = parsedCondition.maxMoney || ""
      this.depositD       = parsedCondition.depositD || ""
      this.depositMm      = parsedCondition.depositMm || ""
      this.exDepoCode     = parsedCondition.exDepoCode || ""

      this.searchSettleData()
    }    
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 20 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
    }
    //엔터키 바로가기
    document.addEventListener("keydown",this.handleEnterKeyPress);
    //document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
  },
  beforeUnmount() {
    document.removeEventListener("keydown",this.handleEnterKeyPress);
  }, 
  methods:
  {
    //mixins 공통함수
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value)
    {
      return this.$currencyFormat(value);
    },
    getDeCurrencyFormat(value)
    {
      return this.$deCurrencyFormat(value);
    },
    getDayOfWeekFormat(text)
    {
      return this.$dayOfWeekFormat(text);
    },
    getNumberOfWeekFormat(text)
    {
      return this.$numberOfWeekFormat(text);
    },
    

    //엔터키 바로작동
    handleEnterKeyPress(event)
    {
      if(event.keyCode === 13)
      {
        this.$refs.searchBtn.click();
      }
    },

    handleFileUpload(event) {
      console.log(event)
      const file = event.target.files[0];

      if(!file) return

      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'binary' });
        this.processExcel(workbook);
      };

      reader.readAsBinaryString(file);
    },

    async processExcel(workbook) {
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      //const jsonData = XLSX.utils.sheet_to_json(worksheet);

      let startLine = 0
      if(this.bankCd === "BWO")
      {
        startLine = 3
      }
      else if(this.bankCd === "BKB")
      {
        startLine = 6
      }
      else
      {
        startLine = 5                   //부산은행
      }      

      // 날짜 형식 옵션을 사용하여 JSON 변환
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        raw: false, // 날짜 데이터를 숫자가 아닌 텍스트로 변환
        dateNF: 'yyyy-mm-dd HH:MM:SS', // 날짜 형식 지정
        range: startLine
      });
      //console.log("jsonData:",jsonData);

      if(jsonData.length > 2004)
      {
        this.$swal.fire("","일괄입력데이타의 최대값은 2000건입니다.", "warning")
        return false
      }
      else
      {
        this.$swal.fire("","데이타의 양에 따라 시간이 소요될 수 있습니다.<br>종료안내까지 대기하세요.", "info")
      }

      //외부입금코드
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm_tmp = today.getMonth()+1;
      let dd_tmp = today.getDate();

      let hour = today.getHours();
      let min = today.getMinutes();
      let sec = today.getSeconds();
      let timestamp = "";
      
      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;
      
      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;
      
      hour = (hour > 9) ? hour : "0" + hour;
      min = (min > 9) ? min : "0" + min;
      sec = (sec > 9) ? sec : "0" + sec;
      let todayDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
      let ymd = `${yyyy}${mm}${dd}`;
      timestamp = today.getTime();
      
      let exDepositNo = "DS-"+ymd+"-"+timestamp; //외부입금번호

      let res = ""
      let i=0
      if(this.bankCd === "BWO")
      {
        while(i < jsonData.length) 
        {
          this.excelUpData.exDepositNo = exDepositNo + i
          this.excelUpData.depositDate = this.getDateFormat(jsonData[i].거래일시)
          this.excelUpData.depositor = jsonData[i].기재내용
          this.excelUpData.finCd = this.bankCd
          this.excelUpData.depositDetail = ""
          this.excelUpData.depositAmt = this.getDeCurrencyFormat(jsonData[i]['입금(원)'])
          this.excelUpData.depositMemo = ""
          this.excelUpData.insId = this.user.handlerId;
          this.excelUpData.checkDate = null;
          this.excelUpData.insDate = todayDate;
          this.excelUpData.dUseFlag = 'Y';
          this.excelUpData.sellerCode = this.sellerCode
          this.excelUpData.delFlag = 'N'

          let insDepositInfo = await this.$api("/api/insDepositInfo", {param: [this.excelUpData]});
          
          if(insDepositInfo.affectedRows > 0)
          {
            res = "OK"
          }
          else
          {
            res = "Error"
            break
          }
          i++
        }
        if(res === "error")
        {
          this.$swal.fire('', '처리 중 문제가 발생했습니다.', 'error');
          return false;
        }
        else
        {
          this.$swal.fire('', '기록이 완료되었습니다.', 'success');
          location.reload(true);
        }
      }

      else if(this.bankCd === "BKB")
      {
        while(i < jsonData.length) 
        {
          this.excelUpData.exDepositNo = exDepositNo + i
          this.excelUpData.depositDate = this.getDateFormat(jsonData[i].거래일시)
          this.excelUpData.depositor = jsonData[i]['보낸분/받는분']
          this.excelUpData.finCd = this.bankCd
          this.excelUpData.depositDetail = jsonData[i].적요
          this.excelUpData.depositAmt = this.getDeCurrencyFormat(jsonData[i]['입금액(원)'])
          this.excelUpData.depositMemo = jsonData[i]['내 통장 표시']
          this.excelUpData.insId = this.user.handlerId;
          this.excelUpData.checkDate = null;
          this.excelUpData.insDate = todayDate;
          this.excelUpData.dUseFlag = 'Y';
          this.excelUpData.sellerCode = this.sellerCode
          this.excelUpData.delFlag = 'N'

          let insDepositInfo = await this.$api("/api/insDepositInfo", {param: [this.excelUpData]}); 
          
          if(insDepositInfo.affectedRows > 0)
          {
            res = "OK"
          }
          else
          {
            res = "Error"
            break
          }
          i++
        }
        if(res === "error")
        {
          this.$swal.fire('', '처리 중 문제가 발생했습니다.', 'error');
          return false;
        }
        else
        {
          this.$swal.fire('', '기록이 완료되었습니다.', 'success');
          location.reload(true);
        }
      }

      else        //부산은행
      {
        while(i < jsonData.length-1) 
        {
          this.excelUpData.exDepositNo = exDepositNo + i
          this.excelUpData.depositDate = this.getDateFormat(jsonData[i].거래일시)
          this.excelUpData.depositor = jsonData[i].기재내용
          this.excelUpData.finCd = this.bankCd
          this.excelUpData.depositDetail = null
          this.excelUpData.depositAmt = this.getDeCurrencyFormat(jsonData[i].입금금액)
          this.excelUpData.depositMemo = jsonData[i].메모내용
          this.excelUpData.insId = this.user.handlerId;
          this.excelUpData.checkDate = null;
          this.excelUpData.insDate = todayDate;
          this.excelUpData.dUseFlag = 'Y';
          this.excelUpData.sellerCode = this.sellerCode
          this.excelUpData.delFlag = 'N'

          let insDepositInfo = await this.$api("/api/insDepositInfo", {param: [this.excelUpData]}); 
          
          if(insDepositInfo.affectedRows > 0)
          {
            res = "OK"
          }
          else
          {
            res = "Error"
            break
          }
          i++
        }
        if(res === "error")
        {
          this.$swal.fire('', '처리 중 문제가 발생했습니다.', 'error');
          return false;
        }
        else
        {
          this.$swal.fire('', '기록이 완료되었습니다.', 'success');
          location.reload(true);
        }
      }
    },

    async getFinBankingInfo(){
      let finBankingInfo_tmp = await this.$api("/api/finBankingInfo", {param: [
        'Inst', 'Bank', 'Y'
      ]})

      if(finBankingInfo_tmp.length > 0)
      {
        this.finBankingInfo = finBankingInfo_tmp.filter((item) => {
          return item.inCdFlag === "Y"
        })
      }
    },

    async getPartnerList(){
      let partnerInfo_tmp = await this.$api("/api/partnerInfo", {})

      if(partnerInfo_tmp.length > 0)
      {
        this.partnerInfo = partnerInfo_tmp.filter((item) => {
          return item.inCdFlag === "Y"
        })
      }
      //console.log("partnerInfo:",this.partnerInfo)
    },

    //옵션컬러처리
    getOptionColor(pCode) {
      switch(pCode) 
      {
        case 'ND':  return 'blue';
        case 'NT':  return 'green';
        case 'SO':  return 'burlywood';
        case 'BKB': return 'burlywood';
        case 'BWO': return 'blue';
        case 'BBU': return 'red';
        default: return 'black';
      }
    },

    //sellerCode List
    async getSellerList() {
      let sellerList = await this.$api("/api/baseCategory", {param: ["seCd"]});
      this.sellerList = sellerList;
      //console.log("sellerList:",this.sellerList);
    },

    async showList(param){
      // if (param === "ND") {
      //   let promises = this.depositInfoList.map(item =>
      //     this.$api("/api/select_NoDiffCount", {param: [item.exDepositNo]})
      //   );

      //   const results = await Promise.all(promises);
      //   let depositInfoList_tmp = [];

      //   results.forEach((res, index) => {
      //     console.log("res_ExDepositInfo:", res[0].subCnt);
      //     if (res[0].subCnt === 0) {
      //       depositInfoList_tmp.push(this.depositInfoList[index]);
      //     }
      //   });

      //   this.depositInfoList = depositInfoList_tmp;
      //   console.log("this.depositInfoList:::::::::::::", this.depositInfoList);
      // }

      ////분할Batch + progress
      // if (param === "ND") {
      //   this.loading = true;  // 로딩 시작
      //   const batchSize = 200; // 한 번에 처리할 아이템 수
      //   let depositInfoList_tmp = [];
      //   this.totalBatches = Math.ceil(this.depositInfoList.length / batchSize);

      //   for (let i = 0; i < this.depositInfoList.length; i += batchSize) {
      //     const batchIndex = i / batchSize + 1;
      //     const batch = this.depositInfoList.slice(i, i + batchSize);
      //     const promises = batch.map(item =>
      //       this.$api("/api/select_NoDiffCount", {param: [item.exDepositNo]})
      //         .catch(error => {
      //           console.error("API Error for item", item.exDepositNo, error);
      //           return null;
      //         })
      //     );
      //     const results = await Promise.all(promises);

      //     results.forEach((res, index) => {
      //       if (res && res[0].subCnt === 0) {
      //         depositInfoList_tmp.push(batch[index]);
      //       }
      //     });

      //     this.progress = Math.floor((batchIndex / this.totalBatches) * 100); // 진행률 업데이트
      //   }

      //   this.depositInfoList = depositInfoList_tmp;
      //   this.loading = false;  // 로딩 완료
      // }
      // else if(param === "ID")         //분할 중 내역
      // {
      //   this.loading = true;  // 로딩 시작
      //   const batchSize = 200; // 한 번에 처리할 아이템 수
      //   let depositInfoList_tmp = [];
      //   this.totalBatches = Math.ceil(this.depositInfoList.length / batchSize);

      //   for (let i = 0; i < this.depositInfoList.length; i += batchSize) {
      //     const batchIndex = i / batchSize + 1;
      //     const batch = this.depositInfoList.slice(i, i + batchSize);
      //     const promises = batch.map(item =>
      //       this.$api("/api/select_NoDiffCount", {param: [item.exDepositNo]})
      //         .catch(error => {
      //           console.error("API Error for item", item.exDepositNo, error);
      //           return null;
      //         })
      //     );
      //     const results = await Promise.all(promises);

      //     results.forEach((res, index) => {
      //       if (res && res[0].subCnt === 0) {
      //         depositInfoList_tmp.push(batch[index]);
      //       }
      //     });

      //     this.progress = Math.floor((batchIndex / this.totalBatches) * 100); // 진행률 업데이트
      //   }

      //   this.depositInfoList = depositInfoList_tmp;
      //   this.loading = false;  // 로딩 완료
      // }
      if (param === "ND") {
        this.depositInfoList = this.depositInfoList_org.filter(item => {
          return item.diffValue === -1
        })
      }
      else if (param === "ID") {
        this.depositInfoList = this.depositInfoList_org.filter(item => {
          return item.diffValue === 1
        })
      }
    },

    //로컬스토리지 비우기
    cleanLocalStorage() {
        localStorage.removeItem('searchCondition');
        location.reload(true)

    },

    //검색조건 로컬스토리지 처리
    saveSearchCondition() {
        const searchCondition = {
            // sellerCode: this.sellerCode,
            // bankCd: this.bankCd
            partnerCode: this.partnerCode,
            finCd: this.finCd,
            dSearchStart: this.dSearchStart,
            dSearchEnd: this.dSearchEnd,
            depositorName: this.depositorName,
            minMoney: this.minMoney,
            maxMoney: this.maxMoney,
            depositD: this.depositD,
            depositMm: this.depositMm,
            exDepoCode: this.exDepoCode
        };
        localStorage.setItem('searchCondition', JSON.stringify(searchCondition));
    },


    //입금내역리스트 조건별 검색
    async searchSettleData(){

      //검색조건 저장
      this.saveSearchCondition();

      this.depositInfoList = []

      if(!this.dSearchStart || this.dSearchStart == "")
      {
        this.$refs.searchStart.focus()
        this.$swal.fire("","검색시작일을 입력하세요", "warning")
        return false;
      }

      //검색은 검색시작 3개월까지 가능
      let startDate = new Date(this.dSearchStart)
      startDate.setMonth(startDate.getMonth() + 3)
      let dSearchEnd_tmp = startDate.toISOString().slice(0, 10)      
      
      if(!this.dSearchEnd || this.dSearchEnd == "")
      {
        this.dSearchEnd = dSearchEnd_tmp
        this.$swal.fire("","검색은 최장 3개월까지 가능합니다.", "warning")
      }      

      // let dSearchStart = (this.dSearchStart == "") ? "NULL" : this.dSearchStart
      // let dSearchEnd = (this.dSearchEnd == "") ? "NULL" : this.dSearchEnd

      let minMoney = (this.minMoney == "") ? 0 : this.minMoney
      let maxMoney = (this.maxMoney == "") ? 999999999 : this.maxMoney

      if(this.dSearchStart > this.dSearchEnd)
      {
        this.$swal.fire("","시작일은 종료일보다 후일일 수 없습니다.","warning")
        return false;
      }
      else if(this.dSearchEnd > dSearchEnd_tmp)
      {
        this.dSearchEnd = dSearchEnd_tmp
        this.$swal.fire("","최장 3개월까지 검색이 가능합니다.","info")
      }      

      // this.dSearchStart = ""
      // this.dSearchEnd = ""

      if(minMoney > maxMoney)
      {
        this.$swal.fire("","최소액이 최대액보다 클 수 없습니다.","warning")
        return false;
      }

      // console.log(this.partnerCode, dSearchStart, dSearchEnd, this.depositorName, this.minMoney, this.maxMoney, this.depositD, this.depositMm)

      let depositInfoList = await this.$api("/api/proc_searchDepositHistroy", {param: [
        this.partnerCode, this.dSearchStart, this.dSearchEnd, this.depositorName, minMoney, maxMoney, this.depositD, this.depositMm, this.finCd
      ]})

      if(depositInfoList.length > 0)
      {
        this.depositInfoList = depositInfoList[1]

        let i = 0;
        while(i < depositInfoList[1].length)
        {
          this.depositInfoList[i].depositDate = this.getDateFormat(this.depositInfoList[i].depositDate);
          this.depositInfoList[i].depositAmt = this.getCurrencyFormat(this.depositInfoList[i].depositAmt);
          i++;
        }
      }

      if(this.exDepoCode != "")
      {
        this.depositInfoList = this.depositInfoList.filter(item => {
          return item.exDepositNo === this.exDepoCode.trim()
        })
      }

      console.log("depositInfoList:::::::", this.depositInfoList)

      //서치결과배열을 저장처리
      let depositInfoList_org = []
      depositInfoList_org = this.depositInfoList
      this.depositInfoList_org = depositInfoList_org
    },

    async getDepositInfo() {

      let delFlag = "Y"
      //let balanceFlag = "N"
      let depositInfoList = await this.$api("/api/proc_readDepositInfo", {param: [ delFlag ]});
      //console.log("depositInfoList111:", depositInfoList)
      
      if(depositInfoList.length > 0)
      {
        this.depositInfoList = depositInfoList[0];

        let i = 0;
        while(i < depositInfoList[0].length)
        {
          this.depositInfoList[i].depositDate = this.getDateFormat(this.depositInfoList[i].depositDate);
          this.depositInfoList[i].depositAmt = this.getCurrencyFormat(this.depositInfoList[i].depositAmt);
          i++;
        }
      }

      console.log("depositInfoList:::::::", this.depositInfoList)

      //서치결과배열을 저장처리
      let depositInfoList_org = []
      depositInfoList_org = this.depositInfoList
      this.depositInfoList_org = depositInfoList_org
    },

    //by linked excode
    async getDepositInfo_linked(lnk_exCode) {
      let depositInfoList_linked = await this.$api("/api/getDepositInfo_linked", {param: [lnk_exCode]});
      this.depositInfoList = depositInfoList_linked;
      //console.log("depositInfoList_linked:", this.depositInfoList)

      let i = 0;
      while(i < depositInfoList_linked.length)
      {
        this.depositInfoList[i].depositDate = this.getDateFormat(depositInfoList_linked[i].depositDate);
        this.depositInfoList[i].depositAmt = this.getCurrencyFormat(depositInfoList_linked[i].depositAmt);
        i++;
      }
    },

    //분할이력보기
    async showHistory(eCode, rowIndex){
      if(this.rowIndexToShow === rowIndex)
      {
        //같은 버튼 재클릭->행숨김
        this.rowIndexToShow = null
      }
      else
      {
        this.rowIndexToShow = rowIndex
      }
      
      this.exdepositSplitHistory = []   //단순분할이 있는 경우를 대비하여 초기화
      
      //입금분할내역
      let exdepositSplitHistory = await this.$api("/api/getExdepositSplitHistory", {param:[ eCode ]})

      if(exdepositSplitHistory.length > 0)
      {
        this.exdepositSplitHistory = exdepositSplitHistory
      }

      this.sel_depositInfoList = []
      this.sel_depositInfoList = this.depositInfoList[rowIndex]

      console.log("exdepositSplitHistory:", this.exdepositSplitHistory)
    },

    splitApply(fFlag,param,idx){
      
      this.idx = idx  //처리될 라인의 인덱스

      if(fFlag === "Y")
      {
        this.$swal.fire('', '회계담당자에 의해 확정된 내역입니다.<br>확정취소를 한 후 다시 적용해주세요', 'warning');
        return false;        
      }
      else
      {
        if(param === "N")
        {
          this.$swal.fire('', '이미 적용이 된 분할의 재적용은 불가능합니다.<br>삭제 후 새롭게 진행하세요', 'warning');
          return false;
        }
        else if(param === 'Y')
        {
          this.showSelDeal = (this.showSelDeal === "Y") ? "N" : "Y"
          this.$swal.fire('', '행사를 선택하여 분할적용하세요.', 'info');
        }
      }
    },

    async splitCxl(fFlag,param,exCd,exSNo,dCode){
      //console.log(fFlag,param,exCd,exSNo,dCode)
      if(fFlag === "Y")
      {
        this.$swal.fire('', '회계담당자에 의해 확정된 내역입니다.<br>확정취소를 한 후 진행하세요', 'warning');
        return false;
      }
      else
      {
        if(param === "Y")
        {
          let useFlag = "N"
          let updId = this.user.handlerId

          let cxlSplitInfo = await this.$api("/api/proc_cxlSplitInfo", {param: [exCd, useFlag, updId, exSNo, dCode]});

          if(cxlSplitInfo.affectedRows < 0 || cxlSplitInfo.affectedRows === undefined)
          {
            this.$swal.fire('', '내역삭제실패(cxlSplitInfo: '+ cxlSplitInfo.error.sqlMessage +')', 'error');
            return false;
          }
          else
          {
            this.$swal.fire('', '분할내역이 삭제되었습니다', 'success');
            location.reload(true);
          }
        }
        else if(param === "N")  //향후 분할여부에 따라 분기할 때 사용하자
        {
          this.$swal.fire('', '분할이 적용된 정산대상이 없습니다.', 'info');
          return false;
        }
      }

    },

    //입금분할내역확정
    async splitConfirm(param, exCd, exSNo, dCode)
    {
      let exFixFlag = ""
      
      if(dCode == "")
      {
        this.$swal.fire('', '단순분할은 확정대상이 아닙니다.', 'warning');
        return false;        
      }
      else
      {
        if(param === "CX")  //확정취소
        {
          exFixFlag = "N"
        }
        else if(param === "CO") //확정
        {
          exFixFlag = "Y"
        }
        else
        {
          this.$swal.fire('', '분할내역 속성설정에 문제가 있습니다(확정 또는 취소만 가능합니다)', 'question');
          return false;
        }
      }

      let exFixId = this.user.handlerId

      let confirmSplitInfo = await this.$api("/api/proc_confirmSplitInfo", {param: [exCd, exFixFlag, exFixId, exSNo, dCode]});
      if(confirmSplitInfo.affectedRows < 0 || confirmSplitInfo.affectedRows === undefined)
      {
        this.$swal.fire('', '선택된 분할내역 처리실패(confirmSplitInfo: '+ confirmSplitInfo.error.sqlMessage +')', 'error');
        return false;
      }
      else
      {
        this.$swal.fire('', '분할에 대한 확정 또는 취소가 설정되었습니다.<br>확정된 내역은 일반업무처리권환으로 수정되지 않습니다.', 'success');
        location.reload(true);
      }
    },    

    //행사명검색
    async getRequestConsultList() {
      let eUseFlag = "Y"
      let dealData = await this.$api("/api/getDealInfo", {param:[ eUseFlag ]})
      if(dealData.length > 0)
      {
        this.dealData = dealData
      }
      
      let i=0;
      let arrSearchData = [];
      if(dealData.length > 0) {
        this.dealData = dealData;
        while(i<dealData.length) 
        {
          arrSearchData.push([dealData[i].dealNameKor,dealData[i].dealCode]);
          i++;
        }
      }
      this.arrSearchData = arrSearchData;
    },
    //자동완성기능
    submitAutoComplete() {
      const autocomplete = document.querySelector(".autocomplete");
      let result_tmp = [];
      if (this.dataInput) {
        autocomplete.classList.remove("disabled");
        autocomplete.style.display = "block"
        result_tmp = this.arrSearchData.filter((e) => {
          return String(e).match(new RegExp(this.dataInput.toUpperCase(), "i"));
        });
        //결과값 중복제거
        this.result = Array.from(new Set(result_tmp));        
      }
      else {
        autocomplete.classList.add("disabled");
        autocomplete.style.display = "none";
        this.getRequestConsultList()
      }
    },

    //분할적용처리
    async depositSplit(param,exCode,dCode, exSubNo, spAmt, sMemo){
     //분할입금 메모입력
      //this.new_splitMemo = sMemno
      const { value: new_splitMemo } = await this.$swal.fire({
          html: '분할명(분할메모)입력(필수)',
          input: 'text',
          //inputLabel: '뱃지표시',
          inputPlaceholder: "최대 200자",
          inputValue: sMemo
        })
      
      this.new_splitMemo = new_splitMemo

      if(!this.new_splitMemo)
      {
        this.$swal.fire("","분할적용 시, 분할메모(분할명)는 필수값입니다.", "warnint")
        return false;
      }
      else
      {
        let updId = this.user.handlerId

        let dFlag = "D"
        let dCurCd = "KRW"
        let dExRate = 1
        let dPayMethod = "Bank"
        let dPayCode = this.exdepositSplitHistory[0].finCd
        let dFeeRate = 0
        let dUseFlag = "Y"
        let splitFlag = "Y"

        //분할적용처리(SA: 단순분할)
        let ins_splitInfo = await this.$api("/api/proc_applySimpleSplit", {param: [param,
            exCode, exSubNo, dCode, spAmt, this.new_splitMemo, updId,
            dFlag, dCurCd, dExRate, spAmt, dPayMethod, dPayCode, dFeeRate, this.new_splitMemo, this.sel_depositInfoList.depositor,
            this.getDateFormat(this.sel_depositInfoList.depositDate), updId, dUseFlag, 
            splitFlag
          ]})      
        if(Object.keys(ins_splitInfo)[0] === "error")
        {
          this.$swal.fire("","등록실패("+ ins_splitInfo.error.sqlMessage +")", "error")
          return false;
        }
        else {
          this.$swal.fire('','분할적용처리완료', 'success');
          location.reload();
        }
      }
    },

    //상품정보보기
    // goToBizinfo_detail(dCode, cFlag, exCode){
    //   this.$router.push({path: "/businessInfo_detail", query: {dCode: dCode, cFlag: cFlag, exCode: exCode}})
    // },
    goToBizinfo_detail(dCode){
      this.$router.push({path: "/businessInfo_detail", query: {dCode: dCode}})
    },    

    goToBack() {
      //this.$router.push({path: '/reserveInfoList'});
      history.back();
    },

    //일괄가격설정
    async dataBaseTransaction(p1, p2)
    {
      if(p1 === "C" && p2 ==="D")
      {
        let today = new Date();
        let yyyy = today.getFullYear();
        let mm_tmp = today.getMonth()+1;
        let dd_tmp = today.getDate();
        let hour = today.getHours();
        let min = today.getMinutes();
        let sec = today.getSeconds();

        let timestamp = "";

        let mm = "";
        if(mm_tmp < 10) {mm = "0" + mm_tmp}
        else mm = mm_tmp;

        let dd = "";
        if(dd_tmp < 10) {dd = "0" + dd_tmp}
        else dd = dd_tmp;

        hour = (hour > 9) ? hour : "0" + hour;
        min = (min > 9) ? min : "0" + min;
        sec = (sec > 9) ? sec : "0" + sec;

        let todayDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
        let ymd = `${yyyy}${mm}${dd}`;

        timestamp = today.getTime();
        let exDepositNo = "DS-"+ymd+"-"+timestamp; //외부입금번호

        this.depositInfo.exDepositNo = exDepositNo;
        //this.depositInfo.checkId = this.user.handlerId;
        this.depositInfo.insId = this.user.handlerId;
        this.depositInfo.checkDate = null;
        this.depositInfo.insDate = todayDate;
        this.depositInfo.dUseFlag = 'Y';
        this.depositInfo.sellerCode = (this.depositInfo.sellerCode == "") ? "NT" : this.depositInfo.sellerCode;

        let insDepositInfo = await this.$api("/api/insDepositInfo", {param: [this.depositInfo]});
        //console.log("errCheck:", Object.keys(insDepositInfo)[0]);
        // 인서트오류
        if(Object.keys(insDepositInfo)[0] === 'error')
        {
          this.$swal.fire('입금기록(insDepositInfo)오류', '', 'error')
        }
        else
        {
          this.$swal.fire('','입금기록 되었습니다.', 'success')
          location.reload(true);
        }
      }
      //입금내역변경
      else if(p1 === "U" && p2 ==="D")
      {
        console.log(this.checkedSeq);

        let i = 0;
        let seq = this.checkedSeq;
        let res = "";

        while(i < this.checkedSeq.length)
        {
          let updDepositInfo = await this.$api("/api/updDepositInfo", {param: [
            this.depositInfoList[seq[i]].depositDate,this.depositInfoList[seq[i]].sellerCode,
            this.depositInfoList[seq[i]].depositor,this.depositInfoList[seq[i]].depositDetail,
            this.getDeCurrencyFormat(this.depositInfoList[seq[i]].depositAmt),this.depositInfoList[seq[i]].depositMemo,"",
            this.user.handlerId,"Y","N",this.depositInfoList[seq[i]].finCd,this.depositInfoList[seq[i]].exDepositNo
          ]});
          if(updDepositInfo.affectedRows < 0 || updDepositInfo.affectedRows === undefined)
          {
            res = "error";
            break;

          }
          else
          {
            res = "ok";
          }
          i++;
        }
        if(res === "error")
        {
          this.$swal.fire('', '변경처리(updDepositInfo)에 문제가 발생했습니다.', 'error');
          return false;
        }
        else
        {
          this.$swal.fire('', '변경되었습니다.', 'success');
          location.reload(true);
        }
      }
    },

    //분할내역 입력,보기(모달처리)
    async splitDepositAmt(val,cFlag)
    {
      console.log("depositInfoList:",this.depositInfoList)
      if(cFlag === "Y")
      {
        this.$swal.fire('', '정산책임자에 의해 마감된 내역입니다.<br>마감취소 후 진행해 주세요.', 'info');
        return false;
      }
      else
      {
        //모달표시
        this.showModal = true;
        this.sel_dExdepositCode = val;
        //console.log("sel_dExdepositCode:",this.sel_dExdepositCode)
      }
    },
    closeModal(){
      this.showModal = false;
    },

    //분할계산저장
    async saveSplitLine(exSubNo, exDepositNo, i)
    {
      let calcRes = this.getDeCurrencyFormat(document.getElementById(exSubNo).value);

      let updExDepositDetail = await this.$api("/api/updExDepositDetail", {param: [
        this.user.handlerId,"Y",this.exDepositDetail[i].splitMemo,calcRes,exDepositNo,exSubNo
      ]});
      if(updExDepositDetail.affectedRows < 0 || updExDepositDetail.affectedRows === undefined)
      {
        this.$swal.fire('', '해당 분할내역변경(updExDepositDetail)에 문제가 발생했습니다.', 'error');
        return false;
      }
      else
      {
        this.$swal.fire('', '해당 분할내역이 변경되었습니다.', 'success');
        location.reload(true);
      }
    },

    //신규분할내역저장
    async newSaveSplitLine(exDepositNo, exSubNo)
    {
      let calcRes = this.getDeCurrencyFormat(document.getElementById(exSubNo).value);
      console.log("exDepositNo:",exDepositNo, "calcRes:",calcRes, "exSubNo:",exSubNo);

      let insExDepositDetail = await this.$api("/api/insExDepositDetail", {param:[
        exDepositNo, exSubNo, this.calcSplitDepositAmt, this.user.handlerId, 'Y', this.newSplitMemo, calcRes
      ]});
      if(Object.keys(insExDepositDetail)[0] === 'error')
      {
        this.$swal.fire('','신규분할내역기록(insExDepositDetail)에 오류가 발생했습니다', 'error')
      }
      else
      {
        let updSplitFlag = await this.$api("/api/updSplitFlag", {param: ["Y", exDepositNo]});
        if(updSplitFlag.affectedRows < 0 || updSplitFlag.affectedRows === undefined)
        {
          this.$swal.fire('','신규분할작업 마킹(updSplitFlag)에 오류가 발생했습니다', 'error')
          return false;
        }
        else
        {
          this.$swal.fire('', '신규 분할내역이 기록되었습니다.', 'success');
          location.reload(true);
        }
        //this.$swal.fire('','신규 분할내역이 기록되었습니다.', 'success')
        //location.reload(true);
      }
    },

    //입금항목체크(권한자-수정불가화)
    async checkDeposit(exCode, cFlag)
    {
      if(this.user.handlerGrade < 6)
      {
        let chkId = this.user.handlerId
        let chkFlag = cFlag
        
        let checkDeposit = await this.$api("/api/depositCheck", {param: [chkId, chkFlag, exCode]});

        if(checkDeposit.affectedRows < 0 || checkDeposit.affectedRows === undefined)
        {
          this.$swal.fire('', '마감에(checkDeposit)에 문제가 발생했습니다.', 'error');
          return false;
        }
        else
        {
          this.$swal.fire('', '마감상태가 변경되었습니다.<br>마감된 내역은 수정이 불가능합니다.', 'success');
          location.reload(true);
        }
      }
      else
      {
        this.$swal.fire('', '권한이 없습니다.', 'warning');
        return false;
      }

    },

    //외부입금체크(oldcode)
/*
    async checkDeposit(val, dMemo)
    {
      if(this.user.handlerGrade < 10)
      {
        let checkDeposit = await this.$api("/api/depositCheck", {param: [dMemo,this.user.handlerId,"Y", val]});
        if(checkDeposit.affectedRows < 0 || checkDeposit.affectedRows === undefined)
        {
          this.$swal.fire('', '확인체크(checkDeposit)에 문제가 발생했습니다.', 'error');
          return false;
        }
        else
        {
          this.$swal.fire('', '체크결과가 기록되었습니다.', 'success');
          location.reload(true);
        }
      }
      else
      {
        this.$swal.fire('', '권한이 없습니다.', 'warning');
        return false;
      }

    },
*/    
    //입금기록 삭제
    async delDeposit()
    {
      console.log("checkedSeq:",this.checkedSeq);
      //let delExDepositNo = [];

      let j=0;
      let res = "";

      while(j < this.checkedSeq.length)
      {
        console.log(this.depositInfoList[this.checkedSeq[j]].exDepositNo)

        //let delDepositInfo = await this.$api("/api/delDepositInfo", {param: ["Y", this.user.handlerId, this.checkedSeq[j]]});
        let delDepositInfo = await this.$api("/api/delDepositInfo", {param: [
          "Y", this.user.handlerId, this.depositInfoList[this.checkedSeq[j]].exDepositNo
        ]});
        if(delDepositInfo.affectedRows < 0 || delDepositInfo.affectedRows === undefined)
        {
          res = "error";
          break;

        }
        else
        {
          res = "ok";
        }
        j++;
      }
      if(res === "error")
      {
        this.$swal.fire('', '삭제처리(delDepositInfo)에 문제가 발생했습니다.', 'error');
        return false;
      }
      else
      {
        this.$swal.fire('', '삭제되었습니다.', 'success');
        location.reload(true);
      }
    },



    //분할계산항목 추가
    addSplitLine(val)
    {
      let tag = document.getElementsByClassName(val)
      //console.log(tag);
      tag.style.display = "block";
      // if(tag.class == val)
      // {
      //   tag.style.display = "block";
      // }
    },
    //분할계산항목 제거
    async removeSplitLine(exDepositNo, exSubNo) 
    {
      console.log("exDepositNo:",exDepositNo, "exSubNo:",exSubNo);

      let delSplitDepositDetail = await this.$api("/api/delSplitDepositDetail", {param: [
        exDepositNo, exSubNo
      ]});
      if(delSplitDepositDetail.affectedRows < 0 || delSplitDepositDetail.affectedRows === undefined)
      {
        this.$swal.fire('', '해당 분할내역삭제(delSplitDepositDetail)에 문제가 발생했습니다.', 'error');
        return false;
      }
      else
      {
        this.$swal.fire('', '해당 분할내역이 삭제되었습니다.', 'success');
        location.reload(true);
      }      
    }, 
  }
}
</script>

<style>
/*
.fixed-top {
  position: fixed;
  top: 300;
  left: 0;
  right: 0;
}
*/
.table_sup td {
  padding: 0;
}

/* 분할완료된 경우 */
.table_sup1 td {
  padding: 0;
  background-color:lightcyan;
}

/**테이블 안의 오브젝트 크기세팅 */
.resize-select {
    width: 100%;
    box-sizing: border-box;
    font-size: smaller;
  }

  .resize-input {
    width: 100%;
    box-sizing: border-box;
    font-size: smaller;
  }

/**Modal CSS */
  .modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 10px;
  border: 1px solid #888;
  width: 90%;
  height: 80%;
  overflow-y: auto;
}

.close {
  color: #aaa;
  float: center;
  font-size: 22px;
  font-weight: bold;
  text-align: end;
  cursor: pointer;
}

.tbody-sp {
  font-size: smaller;
  color:azure;
}

/**셀렉트 컬러컨트롤 */
/*
.blue-text{
  color: blue;
}

.green-text{
  color: green;
}

.brown-text{
  color: burlywood;
}

.black-text{
  color: black;
}

.green { color: green; }
.blue { color: blue; }
.burlywood { color: burlywood; }
.black { color: black; }
*/
</style>