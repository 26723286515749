<template>
  <main class="mt-3">
    <div class="reserdetail">
      <section class="py-1">
        <div class="container my-3">
          <div class="row justify-content-center">
            <div class="col-12 col-lg-11">
              <h2 class="d-flex justify-content-center mt-5" style="font-weight:600">나 의 예 약</h2>
  
              <!-- 상품안내 -->
              <div class="r-name container">
                <div class="row col-12">
                  <div class="col-lg-3">
                    <!-- <img src="../assets/img/bustour/hokkaido.jpg" class="img-fluid" alt="..."> -->
                    <img :src="`/download/${this.productId}/${this.productImage[0].imgName}`" class="d-block w-100" alt="...">
                  </div>
                  <div class="name col-lg-9 text-start">
                    <p class="code">{{ this.productInfo.pdtCode }}</p>
                    <p>{{ this.productInfo.pdtNameKor }}</p>
                    <p class="ndate">{{this.reserveInfo.reqDptDate}} ~ {{this.reserveInfo.reqRtnDate}}</p>
                    <!-- <p class="text-between">
                      <i class="bi bi-vector-pen text-primary" style="cursor: pointer;" @click="toReview(this.reqRsvCode)"> <small class="text-secondary">후기작성(여행 후 30일 이내 작성가능)</small></i>
                    </p> -->
                  </div>
                </div>
              </div>
  
              <!-- 여행자정보 -->
              <div class="traval">
                <div class="container">
                  <div class="title text-start">
                    <p>여행자정보</p> 
                  </div>
  
                  <div class="text-start">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="col-3">예약자</th>
                          <td>{{this.reserveInfo.reqName}}</td>
                        </tr>
                        <tr>
                          <th>연락처</th>
                          <td>{{this.reserveInfo.reqTelNo}} / {{this.reserveInfo.conEmail}}</td>
                        </tr>
                        <tr>
                          <th>여행지역</th>
                          <td>{{this.productInfo.ctName}} <i class="bi bi-arrow-right text-success"></i> {{this.productInfo.ntName}} / {{this.productInfo.arName}}</td>
                        </tr>
                        <tr>
                          <th>여행기간</th>
                          <td>{{this.reserveInfo.reqDptDate}} ~ {{this.reserveInfo.reqRtnDate}}</td>
                        </tr>
                        <tr>
                          <th>결제금액</th>
                          <td>
                            <div class="text-danger fs-6">
                              {{getCurrencyFormat(this.reserveInfo.cstPrice_new)}}원
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>선택옵션</th>
                          <td>
                            <div class="text-start" :key=i v-for="(sEtcOption, i) in this.selectedEtcOption">
                              -&nbsp;{{sEtcOption.etcOptName}}&nbsp;
                              <input class="form-check-input my-auto align-middle" type="checkbox" v-model="sEtcOption.selFlag" true-value="Y" false-value="N" :id=sEtcOption.reqSubCode checked onclick="return false;">
                            </div>
                            <div v-if="this.selectedEtcOption.length > 0" class="pt-2">
                              <div class="text-start text-success">
                                ------------ 특별할인<i class="bi bi-caret-down-fill"></i>
                              </div>
                              <div class="text-start p-1">
                                {{this.getCurrencyFormat(this.reserveInfo.optDcPrice)}}원
                              </div>
                            </div>                            
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
  
              <!-- 예약정보 -->
              <div class="reser">
                <div class="container">
                  <div class="title text-start">
                    <p>예약정보 <font class="recode">({{ this.reserveInfo.reqReserveCode }})</font></p>
                  </div>

                  <div class="text-start">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="col-3">상태</th>
                          <td>
                            <div class="col-md-9" v-if="this.reserveInfo.reqStatus === 'RP'">
                              {{ this.reserveInfo.procName }} &nbsp;
                              <div v-if="this.paidFlag == 'Y'">
                                <small class="text-muted">(확정진행 중입니다)</small>
                              </div>
                              <div v-else>
                              </div>
                            </div>
                            <div class="col-md-9" v-else>
                              {{ this.reserveInfo.procName }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>상품명</th>
                          <td>{{ this.productInfo.pdtNameKor }}</td>
                        </tr>
                        <tr>
                          <th>신청일</th>
                          <td>{{this.reserveInfo.reqDate}}</td>
                        </tr>
                        <tr>
                          <th>상품문의</th>
                          <td>
                            <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="reserveInfo.reqRemarks" maxlength="200" readonly></textarea>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            포인트
                          </th>
                          <td>
                            <div class="input-group" v-if="this.reserveInfo.payPoint > 0">
                              {{this.getCurrencyFormat(this.availPoint)}} /
                              ({{this.getCurrencyFormat(this.reserveInfo.payPoint)}})사용
                            </div>
                            <div class="text-start">
                              <small class="text-danger">사용한 포인트는 복구불가(단, 예약취소 예외)</small>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
  
              <!-- 교통정보 -->
              <div class="container vehicle">
                <div class="row col-12">
                  <div class="title text-start">
                    <p>교통정보</p>
                  </div>
  
                  <div class="col-lg-6 text-start arrive">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="col-3">출발&nbsp;<i class="bi bi-arrow-right-circle"></i>&nbsp;도착</th>
                          <td>
                            한국({{this.pdtTransInfo1.ddCodeName}}) → {{this.productInfo.ntName}}({{this.pdtTransInfo1.daCodeName}})
                          </td>
                        </tr>
                        <tr>
                          <th>출발편</th>
                          <td>
                            {{this.pdtTransInfo1.dmobileCodeName}}
                            <small>
                              ({{this.pdtTransInfo1.dmobileCode}}{{this.pdtTransInfo1.dpTpCode}},&nbsp;{{this.pdtTransInfo1.d_dpTime}} → {{this.pdtTransInfo1.d_arTime}})
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <th>추가사항</th>
                          <td>
                            <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="this.pdtTransInfo1.transRemarks_dp" maxlength="300" readonly></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
  
                  <div class="col-lg-6 text-start">
                    <table class="table">
                      <tbody>
                        <tr>
                          <th class="col-3">리턴&nbsp;<i class="bi bi-arrow-right-circle"></i>&nbsp;도착</th>
                          <td>
                            {{this.productInfo.ntName}}({{this.pdtTransInfo1.rdCodeName}}) → 한국({{this.pdtTransInfo1.raCodeName}})
                          </td>
                        </tr>
                        <tr>
                          <th>리턴편</th>
                          <td>
                            {{this.pdtTransInfo1.rmobileCodeName}}
                            <small>
                              ({{this.pdtTransInfo1.rmobileCode}}{{this.pdtTransInfo1.rtTpcode}},&nbsp;{{this.pdtTransInfo1.r_dpTime}} → {{this.pdtTransInfo1.r_arTime}})
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <th>추가사항</th>
                          <td>
                            <textarea class="form-control" placeholder="300자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" v-model="this.pdtTransInfo1.transRemarks_ar" maxlength="300" readonly></textarea>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <!-- 결제선택 -->
              <div class="reser" v-if="this.reserveInfo.reqStatus === 'RP' && this.paidFlag != 'Y'">
                <div class="container">
                  <div class="title text-start">
                    <p>예약결제 <font class="recode text-danger">({{getCurrencyFormat(this.reserveInfo.cstPrice_new)}}원)</font></p>
                  </div>

                  <div class="col d-grid">
                    <div>
                      <button type="button" class="paybtn btn mt-0" @click="kPay();">
                        <img src="../assets/img/payBtn/kakaopay.png">
                      </button>
                      <button type="button" class="paybtn btn mt-0" @click="tPay(this.reqRsvCode, 'T');">
                        <img src="../assets/img/payBtn/tosspay.png">
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 인적사항 -->
              <!-- <div class="personal">
                <div class="container">
                  <div class="title text-start">
                    <p>인적사항</p>
                  </div>
                  <div>
                    <table class="table text-start">
                      <tbody>
                        <tr>
                          <th class="col-3">인원정보</th>
                          <td>
                            <div class="mt-0 px-1 mb-2 text-start text-success">
                              ※ 여행자보험은 주민번호필요
                            </div>
                            <div class="input-group mb-1 passInfo" :key=i v-for="(pList, i) in this.pniList">
                              <input type="hidden" class="form-control text-success" ref="txt_pniIndex" name="pniIndex" :value=i readonly>
          
                              <input type="text" class="form-control text-success" ref="txt_passNameKor" v-model="pList.passNameKor" style="font-size: x-small;" placeholder="국문명" readonly>
                              
                              <input type="text" class="form-control" ref="txt_passNameEng" v-model="pList.passNameEng" placeholder="영문명" style="font-size: x-small;" readonly>
          
                              <input type="text" class="form-control" maxlength="11" style="font-size: x-small;" ref="txt_pniTel" v-model="pList.pniTel" placeholder="연락처" readonly>
          
                              <input type="text" class="form-control" ref="txt_passBirthDate" style="font-size: x-small;" v-model="pList.pBirthDate" placeholder="생년월일" readonly>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div> -->
              <div class="row justify-content-center">
                <div class="col-lg-3 d-grid">
                  <button type="button" class="btn go btn-md" @click="goToList()">메인페이지</button>
                </div>
              </div>              
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
//import { loadTossPayments } from '@tosspayments/payment-sdk'
//import moment from 'moment';
import axios from 'axios';
//import async from "async";

export default {
  // components: {
  //   DatePicker
  // },
  data() {
    return {
      reserveInfo: {}, //예약정보
      // copyFlag1: "", //정보복사여부
      reservePriceInfo: [], //예약권종정보
      productCode: "", //상품코드
      pdtFlag: "", //상품종류
      availPoint: 0, //가용포인트
      etcSelectedOption: {}, //선택된 기타옵션
      journeyDate: [], //여정일자
      //selJourneyDate: [], //옵션적용 여정일자(선택옵션용)
      sJDate: [], //옵션적용 여정일자(선택옵션용)
      selectedEtcOption: [], //선택된 기타옵션
      //optDcPrice: 0, //옵션특별할인액
      selectedProcCode: "", //예약처리상태
      productImage: [], //상품이미지      

      selectedEtcOpt: [], //선택된 옵션의 확인선택
      selectedEtcOptApplyDate: [], //선택된 옵션의 적용일자(기존)
      applyDateFlag: "N", //적용일자선택여부(기존)

      sEtcOpt: [], //선택된 기타옵션 중 이용할 옵션

      cstPrice: 0, //상담가격

      productInfo: {}, //상품정보

      personCnt: 0, //총인원(화면상)

      reqMarginRate: 0, //당해예약마진
      reqMarginMethod: "P", //당해예약마진계산방법(P/A)

      insComment: "", //예약상담내용

      paidPoint: 0, //사용포인트
      payPointFlag: "N", //포인트사용여부

      //////// PNI정보
      pTotCnt : 0, //참가인원수
      pniList: [], //pni리스트
      pniLength: 0, //pni리스트길이

      passNameKor: [], //pni성인
      passNameEng: [],
      //passBirthDate: [],
      pBirthDate: [],
      passLicenseNo: [], //주민번호(여행자보험용)
      passNo: [], //여권번호
      //passExpDate: [], //여권만료일
      pExpDate: [], //여권만료일
      passFlag: [], //단수복수구분(S단수, D복수)
      pniTel: [],
      imgFlag: [], //여권사본등록여부(Y/N)
      priceType: [], //권종유형(A,C.I.D)

      pniRemarks: "", //인적사항 특기사항(마스터 저장데이타)

      passUpFlag: "", //여권 업로드영역 호출여부
      enteredPassNo: "", //입력된 여권번호


      pdtPromoInfo_apply: {}, //적용가능 프로모션(출발일기준)
      pdtTermFeeInfo_dp_apply: {}, //적용터미널료(출발)
      pdtTermFeeInfo_rt_apply: {}, //적용터미널료(리턴)
      oilSCDp_apply: {}, //적용유류할증료(출발)
      oilSCRt_apply: {}, //적용유류할증료(리턴)


      productTrans: {},
      pdtTransInfo1: {}, //기존교통정보
      pdtTransInfo: {},
      pdtPromoInfo: {}, //프로모션정보
      pdtOilSCInfo1: {}, //기존유류할증
      pdtTermFeeInfo_dp: {}, //기존터미널료
      pdtTermFeeInfo_rt: {},
      salesVolumeInfo: {}, //판매수량관리

      dTerm: "", //출발터미널
      rTerm: "", //리턴터미널
      dCur: "", //터미널이용료화폐
      rCur: "",

      pdtAddOptExisted: {}, //기존추가옵션
      addOptInfo: {}, //화면표시용 추가옵션

      areaFlag: "",

      cityList: {},
      nationList: {},
      areaList: {},
      areaList_tmp: {},
      transList: {},
      //tranList_via: {},
      mobilityList_tmp: {},
      mobilityList_dp: {}, //출발편
      mobilityList_rt: {}, //리턴편
      termList_dpd: {}, //출발시작터미널
      termList_dpa: {}, //출발도착터미널
      termList_rtd: {}, //리턴시작터미널
      termList_rta: {}, //리턴도착터미널

      sRoute: {}, //상품구분

      termList_dp: {}, //출발터미널
      termList_rt: {}, //리턴국터미널
      oilSurcharge_dp: {}, //유류할증
      oilSurcharge_rt: {},
      termFee_dp: {}, //터미널이용료
      termFee_rt : {},
      termFeeNameList_dp: {}, //터미널이용료 명칭
      termFeeNameList_rt : {},

      promoWork: "", //프로모션 적용방식(C/U)
      cPromoFlag: "", //선택프로모션 진행?종료

      productPromotion: {},
      choicePromotion: {}, //선택된 프로모션정보
      promoList: [], //해당상품의 프로모션목록

      optFlag: "", //옵션선택플래그

      pdtcode: "",

      etcOptName: [],
      etcOptPrice: [],
      vCnt: 0,
      etcOptionInfo: {}, //기타옵션
      reqOptFlag: [], //기타옵션플래그

      productPrice: {},

      promoStart: "",
      promoEnd: "",

      rtnRPflag: "N" //결제요청여부
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.reqRsvCode = this.$route.query.seqReq;
    this.productId = this.$route.query.pCode;
    this.copyFlag = this.$route.query.cCode;

    //this.productId = this.$route.query.productId;
    this.salesRoute = this.$route.query.salesRoute;
    this.ntCd = this.$route.query.ntCd;
    //this.pdtFlag = 'MX';

    console.log("USER:",this.user);

    this.getCopyReservation(); //예약처리용 데이타복사
    this.getProductImage()

    this.getReqProcLevel();
    //this.getReserInfo();
    this.getProductInfo();
    //this.getAvailPoint();
    //this.getCalcPrice();
    this.getSalesRoute();
    
    this.getProductTransInfoExited(); //기존교통정보
    this.getProductTransInfo();
    this.getProductPrice();
    this.getPdtPromotion(); //상품프로모션
    // this.getPdtOilSurchargeExited(); //기존상품 유류할증료
    // this.getPdtTerminalFeeExited(); //기존상품 유류할증료
    this.getSalesVolumeInfo(); //판매수량관리 정보

    this.getProductPromotion(); //상품프로모션정보
    this.getPromotionList();

    this.getCityList();
    this.getNationList();
    this.getAreaList();
    this.getTransList();
    this.getMobilityList();
  },
  mounted() {
    //console.log("handler:",this.user.handlerId);
    // if(document.referrer == "") 
    // {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
  },
  methods: {
    /** 카카오페이 처리로직 */
    async kPay()
    {
       // 성공 URL(핸들러등록)
      // const successUrl = `${window.location.protocol}//${window.location.host}/success_kPay&${this.reserveInfo.reqReserveCode}`;
      const successUrl = `${window.location.protocol}//${window.location.host}/success_kPay`;      
       // 실패 or 취소 URL(핸들러등록)
      const failUrl = `${window.location.protocol}//${window.location.host}/fail_kPay`;
      
      let kakaoData = {
        'cid': '',
        //'cid': 'TC0ONETIME', // 테스트 코드
        //'cid': 'CTKMJX29XM', //실코드
        'partner_order_id': this.reserveInfo.reqReserveCode,
        'partner_user_id': 'ntabi',
        'item_name': this.productInfo.pdtNameKor,
        'item_code': this.productId,
        'quantity': 1,
        'total_amount': this.reserveInfo.cstPrice_new,
        'vat_amount': this.reserveInfo.reqVatPrice,
        'tax_free_amount': this.reserveInfo.cstPrice_new - this.reserveInfo.reqVatPrice,
        'approval_url': successUrl,
        'fail_url': failUrl,
        'cancel_url': failUrl
      }

      //결제승인처리를 Success_kPay로 이동(20230321)
      try 
      {
        //const response = await this.$api("api/kakaoPay", { param: kakaoData });
        const response = await axios.post('/api/kakaoPay', { param: kakaoData });
        const tid = response.data.tid;
        
        localStorage.setItem('tid', tid)
        localStorage.setItem('partner_order_id',kakaoData.partner_order_id)
        
        const nextUrl = response.data.next_redirect_pc_url;
        window.location.href = nextUrl;
      } 
      catch (error) 
      {
        console.log(error);
      }
    },

    /** 토스페이 처리로직 */
    async tPay(param, value)
    {
      if(value === "T") 
      {
        let tossData = {
          orderId: param,
          amount: this.reserveInfo.cstPrice_new,
          amountTaxFree: this.reserveInfo.cstPrice_new,
          //orderName: this.productInfo.pdtNameKor,
          productDesc: this.productInfo.pdtNameKor,
          autoExecute: true,
          //resultCallback: "http://localhost:8080/success?amount="+this.reserveInfo.cstPrice_new, //Todo: 콜백페이지 생성
          //resultCallback: "http://192.168.35.68:8080/callback", //Todo: 콜백페이지 생성
          // retUrl: "http://localhost:8080/success?amount="+this.reserveInfo.cstPrice_new,
          // retCancelUrl: "http://localhost:8080/fail",
          // resultCallback: "http://ntabi.co.kr/callback",
          // retUrl: "http://ntabi.co.kr/success?amount="+this.reserveInfo.cstPrice_new,
          // retCancelUrl: "http://ntabi.co.kr/fail"
          retUrl: "https://ntabi.co.kr/success?amount="+this.reserveInfo.cstPrice_new,
          retCancelUrl: "https://ntabi.co.kr/fail",
          resultCallback: "https://ntabi.co.kr/callback",
          
      }
        //console.log("tossData:",tossData)   
        
        // connect with api on backend
        this.$api("api/tossPay/start", 
        {
          param: tossData
        })
        .then((res) => {
          console.log(res);
          window.location.href = res.checkoutPage;
        })
        .catch((err) => {
          console.error(err);
        });
      }
    },

    /** 윈도우즈 계산기호출*/
    callWinCalc() 
    { 
      window.open('Calculator: ');
    },
    async getProductImage() 
    {
      let productImage = await this.$api("/api/productImage_new", {param: [this.productId,'T']});
      this.productImage = (productImage.length > 0) ? productImage : "";
      console.log("pdtImage:",this.productImage)
    },    
    //상태변화체크
    changeProcCode(rProcCode)
    {
      let selectedProcCode = this.reserveInfo.procCode;
      this.reserveInfo.procCode = (this.reserveInfo.procCode == rProcCode) ? selectedProcCode : rProcCode;
      //console.log("org:",selectedProcCode,"sP:",this.reserveInfo.procCode);
    },
    async getReserInfo()
    {
      //예약정보마스터
      //let reserveInfo = await this.$api("/api/reqResConsultDetail", {param: [this.reqRsvCode]});
      let reserveInfo = await this.$api("/api/resConsultDetail", {param: [this.reqRsvCode]});
      this.reserveInfo = reserveInfo[0];
      this.productCode = reserveInfo[0].reqPdtCode;
      //출발예정일-리턴예정일
      this.reserveInfo.reqDptDate = this.getDateFormat(this.reserveInfo.reqDptDate);
      this.reserveInfo.reqRtnDate = this.getDateFormat(this.reserveInfo.reqRtnDate);
      this.reserveInfo.reqDate = this.getDateFormat(this.reserveInfo.reqDate);
      //this.pdtFlag = this.pdtCode.substring(0,2)
      this.pdtFlag = (this.productId.substring(0,2) === 'MX') ? "MX" : "SG"

      this.reqMarginRate = (this.reserveInfo.reqMarginRate == null) ? this.productInfo.basicMargin : this.reserveInfo.reqMarginRate;
      this.reqMarginMethod = (this.reserveInfo.reqMarginMethod == null) ? this.productInfo.marginMethod : this.reserveInfo.reqMarginMethod;      

      //예약정보(권종)
      let reservePriceInfo = await this.$api("/api/reservePriceInfo", {param: [this.reqRsvCode]});
      this.reservePriceInfo = reservePriceInfo;
      //console.log("reservePriceInfo:",this.reservePriceInfo)

      //상품 여정일자 리스트
      let arr_journeyDate = [];
        arr_journeyDate = this.getDatesStartToLast(this.reserveInfo.reqDptDate, this.reserveInfo.reqRtnDate)
        this.journeyDate = arr_journeyDate;
        // console.log("journeyDate:",this.journeyDate);
        // console.log("reserveInfo:",this.reserveInfo,"productId:",this.productId,"pdtFlag:",this.pdtFlag);

      //가용포인트 체크
      this.getAvailPoint();
      //선택한 기타옵션 체크
      this.getSelectedEtcOption();
      //선택한 기타옵션의 적용일체크
      //this.getSelectedEtcOptionDate();
      //적용가능한 프로모션
      this.getPdtPromotion_apply();
      // PNI
      this.getPniList();

      //결제요청 여부
      this.getReqPayInfo(this.reqRsvCode);
      // if(this.reserveInfo.procCode === "RP" || this.reserveInfo.procCode === "CX")
      // {
      //   this.getReqPayInfo();
      // }

      // let rtnRPFlag = this.getReqPayInfo();
      // if(rtnRPFlag.length > 0)
      // {
      //   alert("Y");
      // }
      // else
      // {
      //   alert("N");
      // }
    },
    async getReqPayInfo(val)
    {
      //return await this.$api("/api/getReqPayInfo", {param: [this.reqRsvCode]});
      let rtnRPflag_tmp = await this.$api("/api/getReqPayInfo", {param: [val]});
      this.rtnRPflag = (rtnRPflag_tmp.length > 0) ? "Y" : "N";
      //console.log("rtnRPflag:",this.rtnRPflag);
    },    
    // async getPniList()
    // {
    //   let pniList = await this.$api("/api/getPniList", {param: [this.reqRsvCode]});
    //   this.pniList = pniList;
    //   // this.pniList[0].passBirthDate = this.getDateFormat(this.pniList[0].passBirthDate); 
    //   // this.pniList[1].passBirthDate = this.getDateFormat(this.pniList[1].passBirthDate); 
    //   this.pniList = (pniList.length > 0) ? pniList : [];
    //   this.pniLength = pniList.length;
      
    //   console.log("pniList1:",this.pniList);
    // },
    //개인정보 보호처리
    async getPniList()
    {
      let pniList = await this.$api("/api/getPniList", {param: [this.reqRsvCode]});
      this.pniList = pniList;
      if(this.pniList.length > 0)
      {
        let i = 0;
        while(i < this.pniList.length)
        {
          if(this.pniList[i].pniTel == null || this.pniList[i].pniTel == "")
          {
            this.pniList[i].pniTel = "";
            console.log("pniTel:", this.pniList.pniTel);
          }
          else
          {
            this.pniList[i].pniTel = pniList[i].pniTel.replace(pniList[i].pniTel.substring(7, 11), "****");
            console.log("pniTel:", this.pniList.pniTel);
          }

          if(this.pniList[i].pBirthDate == null || this.pniList[i].pBirthDate == "")
          {
            this.pniList[i].pBirthDate = "";
            console.log("pBirthDate:", this.pniList.pBirthDate);
          }
          else
          {
            this.pniList[i].pBirthDate = pniList[i].pBirthDate.replace(pniList[i].pBirthDate.substring(8, 10), "**");
            console.log("pBirthDate:", this.pniList.pBirthDate);
          }

          i++;
        }
      }

      this.pniLength = pniList.length;

      
      console.log("pniList1:",this.pniList);
    },    
    // 예약상담데이타를 예약데이타로 복사저장
    async getCopyReservation()
    {
      //this.getReserInfo();

      //Page reload시 중복발생 방지
      let checkCopyFlag = await this.$api("/api/checkCopyFlag", {param: [this.reqRsvCode]});
      this.copyFlag1 = checkCopyFlag[0].rCopyFlag;

      // let pTotCnt = (this.reserveInfo.reqAdultCnt + this.reserveInfo.reqChildCnt + this.reserveInfo.reqInfCnt + this.reserveInfo.reqDiscountCnt);
      let pTotCnt = (checkCopyFlag[0].reqAdultCnt + checkCopyFlag[0].reqChildCnt + checkCopyFlag[0].reqInfCnt + checkCopyFlag[0].reqDiscountCnt);      
      this.pTotCnt = pTotCnt;

      if(this.copyFlag == "Y" || this.copyFlag1 == "Y")
      {
        this.$swal.fire(
        {
          position: 'top-end',
          icon: 'success',
          text: 'Already marked',
          toast: true,
          showConfirmButton: false,
          timer: 1000
        })
        //return false;
      }
      else
      {
        let copyReserve_m = await this.$api("/api/copyReserve_m", {param: [this.reqRsvCode]});
        if(Object.keys(copyReserve_m)[0] === 'error')
        {
          this.$swal.fire('', '오류발생(ReserveMaster duplication)<br>시스템 관리자에게 문의바랍니다.', 'error')
          return false;
        }
        else
        {
          let copyReserve_d = await this.$api("/api/copyReserve_d", {param: [this.reqRsvCode]});
          if(Object.keys(copyReserve_d)[0] === 'error')
          {
            this.$swal.fire('', '오류발생(ReserveDetail duplication)<br>시스템 관리자에게 문의바랍니다.', 'error')
            return false;
          }
          else
          {
            let copyReserve_d_addOpt = await this.$api("/api/copyReserve_d_addOpt", {param: [this.reqRsvCode]});
            if(Object.keys(copyReserve_d_addOpt)[0] === 'error')
            {
              this.$swal.fire('', '오류발생(AddOption duplication)<br>시스템 관리자에게 문의바랍니다.', 'error')
              return false;
            }
            else
            {
              let copyReserve_d_etcOpt = await this.$api("/api/copyReserve_d_etcOpt", {param: [this.reqRsvCode]});
              if(Object.keys(copyReserve_d_etcOpt)[0] === 'error')
              {
                this.$swal.fire('', '오류발생(EtcOption duplication)<br>시스템 관리자에게 문의바랍니다.', 'error')
                return false;
              }
              else
              {
                let updResCopyFlag = await this.$api("/api/updResCopyFlag", {param: [this.reqRsvCode
                ]});
                if(updResCopyFlag.affectedRows < 0 || updResCopyFlag.affectedRows === undefined)
                {
                  this.$swal.fire('', '오류발생(Copy marking)<br>시스템 관리자에게 문의바랍니다.', 'error')
                  return false;
                }
                else
                {
                  //기본PNI 등록
                  let i = 0;
                  let res = "";
                  while(i<pTotCnt)
                  {
                    let insDefaultPNI = await this.$api("/api/insDefaultPNI", {param: [this.reqRsvCode,i,'D','Y']});
                    //console.log("insDefaultPNI:",insDefaultPNI);
                    if(Object.keys(insDefaultPNI)[0] === 'error')
                    {
                      res = "error";
                      break;
                    }
                    else
                    {
                      res = "ok";
                    }
                    i++;
                  }
                  if(res == "error")
                  {
                    this.$swal.fire('', '오류발생(Default PNI making)<br>시스템 관리자에게 문의바랍니다.', 'error')
                    return false;
                  }
                  else
                  {
                    this.$swal.fire(
                    {
                      position: 'top-end',
                      icon: 'success',
                      text: 'Success making',
                      toast: true,
                      showConfirmButton: false,
                      timer: 1000
                    })
                    this.copyFlag1 = "Y";
                  }
                }
              }
            }
          }
        }
      }
      this.getReserInfo();
    },

    /** 두날짜 사이의 날짜추출함수 */
    getDatesStartToLast(startDate, lastDate) {
      //var regex = RegExp(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
      //if(!(regex.test(startDate) && regex.test(lastDate))) return "Not Date Format";
      if(!(startDate && lastDate)) return "Not Date Format";

      var result = [];
      var curDate = new Date(startDate);
      while(curDate <= new Date(lastDate)) {
        result.push(curDate.toISOString().split("T")[0]);
        curDate.setDate(curDate.getDate() + 1);
      }
      return result;
    }, 

    async getReqProcLevel() {
      let rProc = await this.$api("/api/reqProcLevel", {});
      if(rProc.length > 0) {
        this.rProc = rProc
      }
      console.log("rProc:",this.rProc);
    },
    //가용포인트 확인
    async getAvailPoint() 
    {
      //alert("reserveInfo:"+ this.reserveInfo.reqEmail);
      let totalPoint = await this.$api("/api/userTotalPoint", {param: [this.reserveInfo.reqEmail]});
      let unavailPoint = await this.$api("/api/userUnavailablePoint", {param: [this.reserveInfo.reqEmail]});
      console.log("totalPoint:",totalPoint[0].totPoint*1, "unavailPoint:",unavailPoint[0].unavilpoint*1);

      if(totalPoint.length > 0) {
        this.availPoint = (totalPoint[0].totPoint*1) - (unavailPoint[0].unavilpoint*1);
      }
      else 
      {
        this.availPoint = 0;
      }
      //console.log("availPoint:",this.availPoint);
    },
    // 가용포인트 사용처리
    payByPoint()
    {
      if(!this.cstPrice || this.cstPrice == 0) 
      {
        this.$swal.fire("","결제할 금액이 없거나 0원입니다.","warning");
        return;
      }
      else
      {
        this.$swal.fire({
        icon: 'question',
        text: '포인트를 사용하시겠습니까?',
        showCancelButton: true,
        confirmButtonText: '사용',
        cancelButtonText: '나중에'
        }).then(async (result) => 
        {
          if (result.isConfirmed) {
            //포인트 사용처리
            let limitPoint = Math.floor((this.cstPrice*1)*0.7); //소숫점절사
            console.log("limitPoint:",limitPoint);
            if(this.availPoint > limitPoint) 
            {
              this.$swal.fire("","가용포인트가 사용한도를 초과하여 한도만큼 사용됩니다.","info");
              this.paidPoint = limitPoint;
              //this.availPoint = this.availPoint*1 - this.cstPrice*1;
              this.availPoint = this.availPoint*1 - limitPoint*1;
              this.cstPrice = this.cstPrice*1 - limitPoint*1;
            }
            else
            {
              this.paidPoint = this.availPoint*1;
              this.cstPrice = (this.cstPrice*1) - (this.availPoint*1);
              this.availPoint = 0;
            }
            //console.log("paidPoint:",this.paidPoint);
          }
        })
      }
    },
    //선택된 기타옵션
    async getSelectedEtcOption() {
      let selectedEtcOption = await this.$api("/api/selectedEtcOption", {param: [this.reqRsvCode]});
      let selectedEtcOptApplyDate = await this.$api("/api/selectedEtcOptApplyDate", {param: [this.reqRsvCode]});
      
      if(selectedEtcOptApplyDate.length > 0) 
      {
        this.selectedEtcOptApplyDate = selectedEtcOptApplyDate;
        //선택된 기타옵션의 적용일이 존재
        this.applyDateFlag = "Y";
      }
      this.selectedEtcOption = selectedEtcOption;

      if(selectedEtcOption.length > 0 && this.applyDateFlag != "Y") 
      {
        //선택된 기타옵션 존재->기본데이타구조 생성
        let j = 0;
        let res = "";
        while(j < this.journeyDate.length)
        {
          let insEtcOptApplyDate = await this.$api("/api/insEtcOptApplyDate", {param: [
            this.reqRsvCode, j, this.journeyDate[j]
          ]});
          if(Object.keys(insEtcOptApplyDate)[0] === 'error') 
          {
            res = "error";
            break;
          }
          j++;
        }
        if(res == "error")
        {
          this.$swal.fire("","선택사항 적용일자 등록중 오류가 발생하였습니다.<br>관리자에게 문의해주세요.","error");
          return false;
        }
        else
        {
          //this.selectedEtcOptApplyDate = selectedEtcOptApplyDate.map((item) => this.getDateFormat(item.applyDate));
          this.selectedEtcOptApplyDate = selectedEtcOptApplyDate
          this.applyDateFlag = "Y";
        }
      }
      console.log("selectedEtcOptApplyDate:",this.selectedEtcOptApplyDate,"applyDateFlag:",this.applyDateFlag);      
    },
    // //선택된 기타옵션 적용일(기존)
    // async getSelectedEtcOptionDate() {
    //   let selectedEtcOptApplyDate = await this.$api("/api/selectedEtcOptApplyDate", {param: [this.reqRsvCode]});
    //   if(selectedEtcOptApplyDate.length > 0) {
    //     this.selectedEtcOptApplyDate = selectedEtcOptApplyDate.map((item) => this.getDateFormat(item.applyDate));
    //     //선택된 기타옵션의 적용일이 존재
    //     this.applyDateFlag = "Y";
    //   }
    //   console.log("selectedEtcOptApplyDate:",this.selectedEtcOptApplyDate,"applyDateFlag:",this.applyDateFlag);
    // },
    //적용가능한 프로모션정보
    async getPdtPromotion_apply() {
      let pdtPromoInfo_apply = await this.$api("/api/pdtPromo_apply", {param: [this.productId, this.reserveInfo.reqDptDate]});
      if(pdtPromoInfo_apply.length > 0) {
        this.pdtPromoInfo_apply = pdtPromoInfo_apply[0];
        if(this.pdtPromoInfo_apply.promoStart != undefined){
          this.pdtPromoInfo_apply.promoStart = this.getDateFormat(this.pdtPromoInfo_apply.promoStart);
        }
        if(this.pdtPromoInfo_apply.promoEnd != undefined) {
          this.pdtPromoInfo_apply.promoEnd = this.getDateFormat(this.pdtPromoInfo_apply.promoEnd);
        }
      }
      //console.log("pdtPromoInfo_apply:",this.pdtPromoInfo_apply);
    },
    //적용 터미널이용료
    async getPdtTerminalFee_apply() {
      let pdtTermFeeInfo_dp_apply = await this.$api("/api/pdtTerminalFeeD_apply", {param: [this.productId, this.reserveInfo.reqDptDate]});
      if(pdtTermFeeInfo_dp_apply.length > 0) {
        this.pdtTermFeeInfo_dp_apply = pdtTermFeeInfo_dp_apply;
        this.dTerm = pdtTermFeeInfo_dp_apply[0].dTerm;
        this.dCur = pdtTermFeeInfo_dp_apply[0].dCur;
      }
      //console.log("pdtTermFeeInfo_dp_apply:",this.pdtTermFeeInfo_dp_apply);
      let pdtTermFeeInfo_rt_apply = await this.$api("/api/pdtTerminalFeeR_apply", {param: [this.productId, this.reserveInfo.reqRtnDate]});
      if(pdtTermFeeInfo_rt_apply.length > 0) {
        this.pdtTermFeeInfo_rt_apply = pdtTermFeeInfo_rt_apply;
        this.rTerm = pdtTermFeeInfo_rt_apply[0].rTerm;
        this.rCur = pdtTermFeeInfo_rt_apply[0].rCur;
      }
      //console.log("pdtTermFeeInfo_rt_apply:",this.pdtTermFeeInfo_rt_apply);
    },
    //적용 유류할증료
    async getPdtOilSurcharge_apply() {
      console.log("dpOil:",this.pdtOilSCInfo1.oilCd_dp, "rtOil:",this.pdtOilSCInfo1.oilCd_rt)
      //적용가능 유류비 체크
      let oilSCDp_apply = await this.$api("/api/pdtOilSurchage_apply", {param: [this.pdtOilSCInfo1.oilCd_dp, this.reserveInfo.reqDptDate]});
      if(oilSCDp_apply.length > 0) {
        this.oilSCDp_apply = oilSCDp_apply[0];
      }
      let oilSCRt_apply = await this.$api("/api/pdtOilSurchage_apply", {param: [this.pdtOilSCInfo1.oilCd_rt, this.reserveInfo.reqRtnDate]});
      if(oilSCRt_apply.length > 0) {
        this.oilSCRt_apply = oilSCRt_apply[0];
      }
    },    
  /////////////상담가격계산(터미널이용료/유류할증료 별도표시)
    /** 여정기간 중 상품가격합산식 */
    calcCstPrice() 
    {
      //alert("Calc!");
      let aduPrice = 0;
      let chiPrice = 0;
      let infPrice = 0;
      let disPrice = 0;
      let pdtPrice = 0;

      //여행참가인원수
      let personCnt = (this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1);
      this.personCnt = personCnt*1;
      console.log("personCnt:",personCnt);

      //1권종가격기준
      if(this.reserveInfo.tripProp === "D")
      {
        aduPrice = (this.reserveInfo.reqAdultPrice*1) * (this.reserveInfo.reqAdultCnt*1);
        chiPrice = (this.reserveInfo.reqChildPrice*1) * (this.reserveInfo.reqChildCnt*1);
        infPrice = (this.reserveInfo.reqInfPrice*1) * (this.reserveInfo.reqInfCnt*1);
        disPrice = (this.reserveInfo.reqDiscountPrice*1) * (this.reserveInfo.reqDiscountCnt*1);
        pdtPrice = (aduPrice*1) + (chiPrice*1) + (infPrice*1) + (disPrice*1);
      }
      else //F속성의 daily price를 배열로 취득
      {
        let fAduPrice = document.getElementsByName("fAduPrice[]");
        let fChiPrice = document.getElementsByName("fChiPrice[]");
        let fInfPrice = document.getElementsByName("fInfPrice[]");
        let fDisPrice = document.getElementsByName("fDisPrice[]");

        let i = 0;
        while(i<fAduPrice.length)
        {
          aduPrice += (fAduPrice[i].value)*1;
          chiPrice += (fChiPrice[i].value)*1;
          infPrice += (fInfPrice[i].value)*1;
          disPrice += (fDisPrice[i].value)*1;
          i++;
        }
        pdtPrice = aduPrice*1 + chiPrice*1 + infPrice*1 + disPrice*1;
        console.log("pdtPrice:",pdtPrice);
      }
      //2.마진적용
      //let margin = 0;
      let pdtPriceMargin = 0;
      if(this.reqMarginMethod ==="A")
      {
        //pdtPrice = (pdtPrice) + (this.productInfo.basicMargin*1);
        pdtPriceMargin = this.reqMarginRate*1
      }
      else
      {
        if(this.reqMarginRate*1 > 0)
        {
          //pdtPrice = pdtPrice + (pdtPrice * (this.productInfo.basicMargin/100));
          pdtPriceMargin = (pdtPrice*1) * ((this.reqMarginRate*1)/100);
          //this.reserveInfo.pdtMargin = pdtPriceMargin;
        }
        else
        {
          //pdtPrice = pdtPrice + 0;
          pdtPriceMargin = 0;
          //this.reserveInfo.pdtMargin = pdtPriceMargin;
        }
      }
      this.reserveInfo.pdtMargin = pdtPriceMargin*1;
      pdtPrice = pdtPrice*1 + pdtPriceMargin*1;

      //3.프로모션 적용
      let promoRateApply = this.pdtPromoInfo_apply.promoRate * 1;
      let promoMethodApply = this.pdtPromoInfo_apply.promoMethod;
      let pdtPromo = 0;
      //let pdtPrice_promo = 0;
      if(promoMethodApply === "A") {
        pdtPromo = promoRateApply*1;
        //pdtPrice = pdtPrice - promoRateApply;
      }
      else if(promoMethodApply === "P") {
        pdtPromo = (pdtPrice*1) * ((promoRateApply*1)/100);
        //pdtPrice = pdtPrice - (pdtPrice * (promoRateApply/100));
      }
      else {
        pdtPromo = 0;
      }
      this.reserveInfo.pdtPromo = pdtPromo*1;
      pdtPrice = pdtPrice*1 - pdtPromo*1;

      //4.선택된 기타옵션 적용
      //let sEtcOpt = this.selectedEtcOption;
      let sEtcOpt = this.selectedEtcOption.filter((item) => item.selFlag !== "N"); //선택옵션(reqOptFlag)
      //let sJDate = this.selJourneyDate; //적용일자
      let sJDate_tmp = this.selectedEtcOptApplyDate.filter(item => item.applyFlag === "Y"); //적용일자
      let sJDate = sJDate_tmp.map(item => this.getDateFormat(item.applyDate)); //적용일자
      this.sJDate = sJDate;
      this.sEtcOpt = sEtcOpt;
      //console.log("sEtcOpt:",sEtcOpt);
      //console.log("sJDate:",sJDate);

      let sEtcPrice = 0; //선택옵션 금액(적용)
      let i = 0;
      while(i < sEtcOpt.length)
      {
        sEtcOpt[i].etcOptPrice*1;
        console.log("sEtcPrice:",sEtcOpt[i].etcOptPrice*1);
        sEtcPrice = sEtcPrice*1 + ((sEtcOpt[i].etcOptPrice*1) * personCnt*1);
        i++;
      }
      //특별할인은 옵션에 대해서만 적용
      sEtcPrice = ((sEtcPrice*1) * sJDate.length) - (this.reserveInfo.optDcPrice*1);
      console.log("sEtcPrice:",sEtcPrice);

      pdtPrice = (pdtPrice*1) + (sEtcPrice*1);

      //5.payPoint처리
      if(this.reserveInfo.payPoint > 0)
      {
        this.payPointFlag = "Y";
        pdtPrice = pdtPrice*1 - (this.reserveInfo.payPoint*1);
      }
      console.log("pdtPricePPoint:",pdtPrice);      

      //6.부가세 적용
      let vatR = 0;
      //console.log("vat:R",vatR);
      if(this.reserveInfo.reqVatPrice > 0) 
      {
        vatR = (pdtPrice*1) * ((this.reserveInfo.reqVatPrice*1)/100);
      }
      else
      {
        vatR = 0;
      }
      this.reserveInfo.vatPrice = vatR;
      pdtPrice = pdtPrice*1 + vatR*1;
      
      //3.추가상품 적용 - 적용정책 확정 후 오픈    
      // console.log("pdtAddOptExisted:", this.pdtAddOptExisted);
      // //console.log("addOptCodes:", this.addOptCodes);
      // let addOptPrice = 0;
      // let addOptPriceT1 = 0;
      // let addOptPriceT2 = 0;
      // let k=0;
      // let aduAddPrc = [];
      // let chiAddPrc = [];
      // let infAddPrc = [];
      // let disAddPrc = [];
      // let aduAddOptPrc = 0;
      // let chiAddOptPrc = 0;
      // let infAddOptPrc = 0;
      // let disAddOptPrc = 0;
      // let totCnt = this.reserveInfo.reqAdultCnt + this.reserveInfo.reqChildCnt + this.reserveInfo.reqDiscountCnt;

      // for(k=0; k < this.pdtAddOptExisted.length; k++) {
      //   if(this.pdtAddOptExisted[k].length === 4) {
      //       aduAddPrc = this.pdtAddOptExisted[k][0].pdtPrice;
      //       chiAddPrc = this.pdtAddOptExisted[k][1].pdtPrice;
      //       infAddPrc = this.pdtAddOptExisted[k][2].pdtPrice;
      //       disAddPrc = this.pdtAddOptExisted[k][3].pdtPrice;

      //       // aduAddOptPrc = aduAddPrc * this.aduCnt;
      //       // chiAddOptPrc = chiAddPrc * this.chiCnt;
      //       // infAddOptPrc = infAddPrc * this.infCnt;
      //       // disAddOptPrc = disAddPrc * this.disCnt;
      //       aduAddOptPrc = aduAddPrc * this.reserveInfo.reqAdultCnt;
      //       chiAddOptPrc = chiAddPrc * this.reserveInfo.reqChildCnt;
      //       infAddOptPrc = infAddPrc * this.reserveInfo.reqInfCnt;
      //       disAddOptPrc = disAddPrc * this.reserveInfo.reqDiscountCnt;            

      //       let addOptPriceT1_tmp = aduAddOptPrc + chiAddOptPrc + infAddOptPrc + disAddOptPrc;
      //       addOptPriceT1 = addOptPriceT1 + addOptPriceT1_tmp;
      //       console.log("addOptPriceTP1:",addOptPriceT1);
      //   }
      //   else {
      //     aduAddPrc = this.pdtAddOptExisted[k][0].pdtPrice;
      //     //console.log(k,":", this.pdtAddOptExisted[k][0].pdtPrice);
      //     //let addOptPriceT2_tmp = aduAddPrc * this.totCnt;
      //     let addOptPriceT2_tmp = aduAddPrc * totCnt;
      //     addOptPriceT2 = addOptPriceT2 + addOptPriceT2_tmp;

      //     //addOptPriceT2 = addOptPriceT2 + addOptPriceT2_tmp;
      //     console.log("addOptPriceTP2:",addOptPriceT2);
      //   }
      //   addOptPrice = addOptPriceT1 + addOptPriceT2;
      // }
      // console.log("addOptPrice:", addOptPrice);

      
      // this.cstPrice = pdtPrice_promo;
      
      //7.소숫점아래 절사처리
      pdtPrice = Math.floor(pdtPrice);

      this.cstPrice = pdtPrice*1;
    },
    async getProductInfo() 
    {
      this.pdtFlag = (this.productId.substring(0,2) === 'MX') ? "MX" : "SG"
      let productInfo = {};
      if(this.pdtFlag === "SG") 
      {
        productInfo = await this.$api("/api/pdtInfo", {param: [this.productId]});
      }
      else if(this.pdtFlag === "MX") 
      {
        productInfo = await this.$api("/api/pdtInfo_mix", {param: [this.productId]});
      }
      else 
      {
        return this.$swal.fire("","상품이 검색되지 않습니다<br>재확인 후 시도해 주세요.","error");
      }
      this.productInfo = productInfo[0]
      this.productInfo.tripStart = this.getDateFormat(this.productInfo.tripStart);
      this.productInfo.tripEnd = this.getDateFormat(this.productInfo.tripEnd);
      this.productInfo.salesStart = this.getDateFormat(this.productInfo.salesStart);
      this.productInfo.salesEnd = this.getDateFormat(this.productInfo.salesEnd);
      console.log("productInfo:",this.productInfo);
    },
    // //여권사본 첨부오픈
    // passImgUp(pNo)
    // {
    //   // let result = this.checkCorporateRegiNumber(this.cDetail.licenseNumber)
    //   //alert("pNo:"+pNo);
    //   if(pNo == "" || pNo == undefined) 
    //   {
    //     //this.$refs.txt_cLicense.value = "";
    //     this.passUpFlag = "";
    //     console.log("passUpFlag:",this.passUpFlag);
    //     this.$swal.fire("","해당 여권번호를 입력하세요", "warning");
    //     return false;
    //   }
    //   else
    //   {
    //     //alert("pNo:" + pNo);
    //     this.passUpFlag = "Y";
    //     this.enteredPassNo = pNo;
    //     console.log("passUpFlag:",this.passUpFlag);
    //   }
    // },
    /** 이미지 사본등록 */
    async uploadFile(files, id, passNo) {
      let fileLength = 0
      let fileType_tmp = "";
      //let fileType = ""
      let licenseFile = "";
      //let passFile = "";
      let data = null;
      if (files) {
        fileLength = files[0].length;
        fileType_tmp = files[0].name.lastIndexOf(".");
        this.fileType = files[0].name.substring(fileType_tmp+1, fileLength).toLowerCase();
        //licenseNumber = files[0].name;
        //passFile = this.cDetail.licenseNumber+"."+this.fileType;
        licenseFile = passNo+"."+this.fileType;
        data = await this.$base64(files[0]);
      }
      console.log("files:",files, "id:",id, "File:",licenseFile,"licenseNo:",this.licenseNumber);
      //const { error } = await this.$api(`/upload/passport/${licenseFile}/${passNo}/${id}`, { data });
      const { error } = await this.$api(`/upload/passport/${id}/${licenseFile}`, { data });
      if (error) {
        return this.$swal("업로드(uploadFile) 오류발생, 재시도 필요.");
      }
      else{
        this.$swal("업로드 완료");
        location.reload();
      }


      // setTimeout(() => {
      //   this.getProductImage();
      // }, 1000);
    },      
    //기존상품 추가옵션정보(정책확정 후 오픈)
    // async getPdtAddOptionExited() {
    //   //대상상품에 적용된 추가옵션코드 추출
    //   //alert("pdtCode:"+this.pdtCode);
    //   this.pdtFlag = (this.productId.substring(0,2) === 'MX') ? "MX" : "SG"
    //   let addOptCodes = {};
    //   let addOptInfo = {};
    //   if(this.pdtFlag === "SG") {
    //     addOptCodes = await this.$api("/api/pdtAddOptionCodes_existed", {param: [this.productId]});
    //     addOptInfo = await this.$api("/api/pdtAddOption_existed", {param: [this.productId]});
    //   }
    //   else if(this.pdtFlag === "MX") {
    //     addOptCodes = await this.$api("/api/pdtAddOptionCodes_existed_mix", {param: [this.productId]});
    //     addOptInfo = await this.$api("/api/pdtAddOption_existed_mix", {param: [this.productId]});
    //   }
    //   else {
    //     return this.$swal.fire("","상품이 검색되지 않습니다<br>재확인 후 시도해 주세요.","error");
    //   }
    //   console.log("addOptInfo:",addOptInfo);
    //   let i=0;
    //   let pdtAddOptExisted = [];
    //   while(i<addOptCodes.length) {
    //     //alert(addOptCodes[i].pdtCode);
    //     pdtAddOptExisted.push(addOptInfo.filter(e => {return e.pdtCode === addOptCodes[i].pdtCode}));
    //     i++;
    //   }
    //   if(pdtAddOptExisted.length > 0){
    //     this.pdtAddOptExisted = pdtAddOptExisted;
    //   }
    //   //console.log("pdtAddOptExisted:",pdtAddOptExisted);
    // },
    //기존상품 프로모션정보
    async getPdtPromotion() {
      let pdtPromoInfo = await this.$api("/api/pdtPromo_existed", {param: [this.productId]});
      if(pdtPromoInfo.length > 0) {
        this.pdtPromoInfo = pdtPromoInfo[0];
        if(this.pdtPromoInfo.promoStart != undefined){
          this.pdtPromoInfo.promoStart = this.getDateFormat(this.pdtPromoInfo.promoStart);
        }
        if(this.pdtPromoInfo.promoEnd != undefined) {
          this.pdtPromoInfo.promoEnd = this.getDateFormat(this.pdtPromoInfo.promoEnd);
        }
      }
      console.log("pdtPromoInfo:",this.pdtPromoInfo);
    },
    //기존상품 유류할증료
    async getPdtOilSurchargeExited() {
      let pdtOilSCInfo1 = await this.$api("/api/pdtOilSurchage_existed", {param: [this.productId]});
      if(pdtOilSCInfo1.length > 0) {
        this.pdtOilSCInfo1 = pdtOilSCInfo1[0];
      }
      //console.log("pdtOilSCInfo1:",pdtOilSCInfo1)
    },
    //기존상품 터미널이용료
    async getPdtTerminalFeeExited() {
      let pdtTermFeeInfo_dp = await this.$api("/api/pdtTerminalFeeD_existed", {param: [this.productId]});
      if(pdtTermFeeInfo_dp.length > 0) {
        this.pdtTermFeeInfo_dp = pdtTermFeeInfo_dp;
        this.dTerm = pdtTermFeeInfo_dp[0].dTerm;
        this.dCur = pdtTermFeeInfo_dp[0].dCur;
      }
      //console.log("pdtTermFeeInfo_dp:",this.pdtTermFeeInfo_dp);
      let pdtTermFeeInfo_rt = await this.$api("/api/pdtTerminalFeeR_existed", {param: [this.productId]});
      if(pdtTermFeeInfo_rt.length > 0) {
        this.pdtTermFeeInfo_rt = pdtTermFeeInfo_rt;
        this.rTerm = pdtTermFeeInfo_rt[0].rTerm;
        this.rCur = pdtTermFeeInfo_rt[0].rCur;
        //console.log("pdtTermFeeInfo_rt:",this.pdtTermFeeInfo_rt);
      }
    },
    async getSalesRoute() {
      this.sRoute = await this.$api("/api/salesRoute", {});
      //console.log("sRoute",this.sRoute);
    },

    //기존상품 교통정보
    async getProductTransInfoExited() {
      let pdtTransInfo1 = await this.$api("/api/pdtTransInfo_existed", {param: [this.productId]});
      if(pdtTransInfo1.length > 0) {
        this.pdtTransInfo1 = pdtTransInfo1[0];
      }
      //console.log("pdtTransInfo1:",this.pdtTransInfo1);
    },
    async getProductTransInfo() {
      let pdtTransInfo = await this.$api("/api/pdtTransInfo", {param: [this.productId]});
      if(this.pdtTransInfo.length > 0){
        this.pdtTransInfo = pdtTransInfo[0];
      }
      //console.log("pdtTransInfo:",this.pdtTransInfo);
      this.pageOpen();
      //this.getAddRoute_dp()
    },

    /** 배열내 중복제거*/
    // func_Distinct(value, index, self) {
    //   return self.indexOf(value) === index;
    // },

    //상품별 수량관리정보
    async getSalesVolumeInfo() {
      let salesVolumeInfo = await this.$api("/api/salesVolumeInfo_mix", {param: [this.productId]});
      if(salesVolumeInfo.length > 0) {
        this.salesVolumeInfo = salesVolumeInfo[0];
      }
      console.log("salesVolumeInfo",this.salesVolumeInfo);
    },
    //상품프로모션
    async getProductPromotion() {
      let productPromotion = await this.$api("/api/productPromotion", {param: [this.productId]});
      //console.log("pPromo:",productPromotion);

      if(productPromotion.length > 0) {
        this.productPromotion = productPromotion[0]
      }
      else{
        return false;
      }
      //console.log("productPromotion: ",this.productPromotion.promoName);
    },
    async getCityList() {
      let cityList = await this.$api("/api/cityList", {});
      if(cityList.length > 0) {
        this.cityList = cityList;
      }
      //console.log("nationList",nationList);
    },    
    async getNationList() {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      //console.log("nationList",nationList);
    },
    //넘어온 파라메타로 교통편표시
    async pageOpen(){
      //alert("trans:"+this.productInfo.transFlag);
      this.getAreaList_pageOpen()
      if(this.salesRoute === "T" || this.salesRoute === "L") {
        this.viewDpMobilityList_pageOpen();
        this.viewRtMobilityList_pageOpen();
        this.getDpTermList_pageOpen();
        this.getRtTermList_pageOpen();
      }
    },
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    //페이지 오픈시 교통정보 호출
    async getAreaList_pageOpen() {
      //console.log("productInfo:",this.productInfo);
      let areaList1 = await this.$api("/api/areaList", {});
      this.areaList = areaList1.filter(e => {return e.categoryCode === this.ntCd});
    },
    async viewDpMobilityList_pageOpen(){
      let mobilityList_tmp1 = await this.$api("/api/mobilityList", {});
      this.mobilityList_dp = mobilityList_tmp1.filter(e => {return e.categoryCode === this.pdtTransInfo.trCd1});
    },
    async viewRtMobilityList_pageOpen(){
      let mobilityList_tmp1 = await this.$api("/api/mobilityList", {});
      this.mobilityList_rt = mobilityList_tmp1.filter(e => {return e.categoryCode === this.pdtTransInfo.trCd2});
    },
    async getDpTermList_pageOpen() {
      // let param4 = (this.pdtTransInfo.trCd1 === "SHP") ? "ptCd" : "apCd"
      let param4 = "";
      if(this.pdtTransInfo.trCd1 === "SHP") {
        param4 = "ptCd";
      }
      else if(this.pdtTransInfo.trCd1 === "FLT") {
        param4 = "apCd";
      }
      else {
        param4 = "";
      }
      if(param4 === "ptCd" || param4 === "apCd") {
        this.termList_dpd = await this.$api("/api/termList", {param: ["KR", param4]});
        //출발도착터미널
        this.termList_dpa = await this.$api("/api/termList1", {param: ["KR", this.ntCd, param4]});
      }
      else{
        this.termList_dpd = "";
        this.termList_dpa = "";
      }
      //console.log("TR:",param4,"/termListD:",this.termList_dpd,"/termListA:",this.termList_dpa);
    },
    async getRtTermList_pageOpen() {
      //let param5 = (this.pdtTransInfo.trCd2 === "SHP") ? "ptCd" : "apCd"
      let param5 = "";
      if(this.pdtTransInfo.trCd2 === "SHP") {
        param5 = "ptCd";
      }
      else if(this.pdtTransInfo.trCd2 === "FLT") {
        param5 = "apCd";
      }
      else {
        param5 = "";
      }
      //this.termList_rtd = await this.$api("/api/termList", {param: [this.productInfo.ntCd, param5]});
      if(param5 === "ptCd" || param5 === "apCd") {
        this.termList_rtd = await this.$api("/api/termList", {param: [this.ntCd, param5]});
        this.termList_rta = await this.$api("/api/termList1", {param: ["KR", this.ntCd, param5]});
      }
    },
    async getAreaList() {
      this.areaList_tmp = await this.$api("/api/areaList", {});
    },
    changeNationList() {
      //this.nationCode = this.productInfo.baseCode;
      this.viewAreaList(this.productInfo.ntCd);
    },
    viewAreaList(param1){
      //console.log("selectedNation:", param);
      this.areaList = this.areaList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("area:", this.areaList);
    },

    //교통수단, 교통편 정보
    async getTransList() {
      let transList = await this.$api("/api/transList", {});
      this.transList = transList;
      //console.log("transList",transList);
    },
    async getMobilityList() {
      this.mobilityList_tmp = await this.$api("/api/mobilityList", {});
      //console.log("mobilityList_tmp",this.mobilityList_tmp);
    },
    changeTransList() {
      this.viewMobilityList(this.productInfo.trCd);
      //console.log("trCd:",this.transCode);
    },
    viewMobilityList(param2){
      this.mobilityList = this.mobilityList_tmp.filter(e => {return e.categoryCode === param2});
      //console.log("mobility:", this.mobilityList);
    },
    changeMobilityList() {
      this.getTermList(this.productInfo.ntCd, this.productInfo.trCd);
    },
    async getTermList(param3, param4) {
      //console.log("prarms:",param3,"/",param4);
      let param5 = (param4 === "SHP") ? "ptCd" : "apCd"
      this.termList_dp = await this.$api("/api/termList", {param: ['KR', param5]});
      this.termList_rt = await this.$api("/api/termList", {param: [param3, param5]});
    },

    //교통수단변경->교통편획득
    changeDpTransList() {
      this.pdtTransInfo.mbCd1 = "";
      this.pdtTransInfo.dpdCd = "";
      this.pdtTransInfo.dpaCd = "";
      this.viewDpMobilityList(this.pdtTransInfo.trCd1);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    changeRtTransList() {
      this.pdtTransInfo.mbCd2 = "";
      this.pdtTransInfo.rtdCd = "";
      this.pdtTransInfo.rtaCd = "";
      this.viewRtMobilityList(this.pdtTransInfo.trCd2);
      //console.log("trCd:",this.pdtTransInfo.trCd1);
    },
    viewDpMobilityList(param1){
      //alert("categoryCode:" + param1);
      this.mobilityList_dp = this.mobilityList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("mobility:", this.mobilityList_dp);
    },
    viewRtMobilityList(param2){
      //alert("categoryCode:" + param2);
      this.mobilityList_rt = this.mobilityList_tmp.filter(e => {return e.categoryCode === param2});
      //console.log("mobility:", this.mobilityList_rt);
    },
    changeDpMobilityList() {
      this.getDpTermList(this.productInfo.ntCd, this.pdtTransInfo.trCd1);
    },
    changeRtMobilityList() {
      this.getRtTermList(this.productInfo.ntCd, this.pdtTransInfo.trCd2);
    },
    async getDpTermList(param2, param3) {
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      //alert(param4);
      this.termList_dpd = await this.$api("/api/termList", {param: ["KR", param4]});
      //출발도착터미널
      this.termList_dpa = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
      //console.log("termList:",this.termList_dp);
    },
    async getRtTermList(param2, param3) {
      let param4 = (param3 === "SHP") ? "ptCd" : "apCd"
      //alert(param4);
      this.termList_rtd = await this.$api("/api/termList", {param: [param2, param4]});
      this.termList_rta = await this.$api("/api/termList1", {param: ["KR", param2, param4]});
      //console.log("termList:",this.termList_rt);
    },
    //상품가격
    async getProductPrice() {
      let productPrice = await this.$api("/api/productPrice", {param: [this.pdtCode]});
      this.productPrice = productPrice;
      //console.log("productPrice:",this.productPrice);

      //this.addOptCheck(this.productInfo.salesRoute)
      //this.getAddOption();

      //추가옵션은 정책설정 후 오픈
      // if(this.productInfo.addOptionFlag === "Y"){
      //   this.getPdtAddOptionExited();
      //   // alert("추가옵션있다요");
      //   this.getAddOption();
      // }

      if(this.productInfo.etcOptionFlag === "Y") {
        this.getEtcOptionInfo();
      }
    },
    //수정영역 표시(비표시)
    showArea(param) {
      this.areaFlag = (this.areaFlag === param) ? "N"+param : param;
      if(this.areaFlag === "NT") {
        //alert(param+"의 반대: N"+param);
        this.pdtTransInfo.trCd1 = "";
        this.pdtTransInfo.mbCd1 = "";
        this.pdtTransInfo.dpdCd = "";
        this.pdtTransInfo.dpaCd = "";
        this.pdtTransInfo.transRemarks_dp = "";
        this.pdtTransInfo.oilCd_dp = "";
        this.pdtTransInfo.termCd_dp = "";
        
        this.pdtTransInfo.trCd2 = "";
        this.pdtTransInfo.mbCd2 = "";
        this.pdtTransInfo.rtdCd = "";
        this.pdtTransInfo.rtaCd = "";
        this.pdtTransInfo.transRemarks_ar = "";
        this.pdtTransInfo.oilCd_rt = "";
        this.pdtTransInfo.termCd_rt = "";
      }
      else if(this.areaFlag === "NP") {
        alert("NP/"+this.areaFlag);
      }
    },
    //addOption정보(정책설정 후 오픈)
    /** 추가옵션정보추출 */
    // async getAddOption() {
    //   let addOptionInfo_tmp = await this.$api("/api/addOptionInfo", {param: [this.productId]});
    //   //console.log("addOptionInfo_tmp:",addOptionInfo_tmp);
    //   let i = 0;
    //   //let j = 0;
    //   while(i < addOptionInfo_tmp.length){
    //     switch(addOptionInfo_tmp[i].prcCode.charAt(1)) {
    //       case "L": this.addOptionPrice_l = await this.$api("/api/addOptionPrice", {param: [addOptionInfo_tmp[i].prcCode]});
    //                 this.changeAddOptN(addOptionInfo_tmp[i].prcCode.charAt(1), this.addOptionPrice_l[0].pdtCode);
    //         //console.log("addOptionPrice_l:",this.addOptionPrice_l);
    //       break;
    //       case "H": this.addOptionPrice_h = await this.$api("/api/addOptionPrice", {param: [addOptionInfo_tmp[i].prcCode]});
    //                 this.changeAddOptN(addOptionInfo_tmp[i].prcCode.charAt(1), this.addOptionPrice_h[0].pdtCode);
    //         //console.log("addOptionPrice_h:",this.addOptionPrice_h);
    //       break;
    //       case "E": this.addOptionPrice_e = await this.$api("/api/addOptionPrice", {param: [addOptionInfo_tmp[i].prcCode]});
    //                 this.changeAddOptN(addOptionInfo_tmp[i].prcCode.charAt(1), this.addOptionPrice_e[0].pdtCode);
    //         //console.log("addOptionPrice_e:",this.addOptionPrice_e);
    //       break;
    //       case "S": this.addOptionPrice_s = await this.$api("/api/addOptionPrice", {param: [addOptionInfo_tmp[i].prcCode]});
    //                 this.changeAddOptN(addOptionInfo_tmp[i].prcCode.charAt(1), this.addOptionPrice_s[0].pdtCode);
    //         //console.log("addOptionPrice_s:",this.addOptionPrice_s);
    //       break;
    //       default: return this.$swal.fire("","CaseI: 오류가 발생했습니다./n관리자에 문의해주세요.","error");
    //     }

    //     let addOptionPrice_tmp = await this.$api("/api/addOptionPrice", {param: [addOptionInfo_tmp[i].prcCode]});
    //     //console.log("addOptionPrice_tmp:",addOptionPrice_tmp[i].pdtPrice);
    //     let key = addOptionInfo_tmp[i].prcCode;
    //     this.addOptionInfo[key] = addOptionPrice_tmp;
    //     i++;
    //   }
    // },
    //기타옵션획득
    async getEtcOptionInfo() {
      this.etcOptionInfo = await this.$api("/api/etcOptionInfo", {param: [this.productId]});
      //console.log("etcOptionInfo:",this.etcOptionInfo);
    },
    async getEtcFeeInfo(p1,p2,p3,p4) {
      let drFlag = p1;
      let liCd = (this.productInfo.ntCd === "KR") ? "L" : "G"
      let trCd = p2;
      let mbCd = p3;
      let tmCd = p4
      //console.log(tmCd)
      //유류할증료
      this.getOilFeeInfo(drFlag, liCd, trCd, mbCd);
      //터미널이용료
      this.getTermFeeInfo(drFlag, tmCd);
    },
    async getOilFeeInfo(f,o1,o2,o3){
      //console.log(f+"//"+o1+"//"+o2+"//"+o3);
      if(f === "D") {
        //츨발편 유류할증료
        let oilSurcharge_dp = await this.$api("/api/pdtEtcFee_oil", {param:[o1,o2,o3,'KR']});
        this.oilSurcharge_dp = oilSurcharge_dp;
        //console.log("oilSurcharge_dp",this.oilSurcharge_dp);
      }
      else if(f === "R") {
        //리턴편 유류할증료
        let oilSurcharge_rt = await this.$api("/api/pdtEtcFee_oil", {param:[o1,o2,o3,this.productInfo.ntCd]});
        this.oilSurcharge_rt = oilSurcharge_rt;
        //console.log("oilSurcharge_rt",this.oilSurcharge_rt);
      }
    },
    async getTermFeeInfo(f,t1){
      //console.log(f+"//"+t1);
      if(f === "D") {
        // let termFee_dp = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        // this.termFee_dp = termFee_dp;
        let termFeeNameList_dp = await this.$api("/api/pdtEtcFeeName_term", {param:[t1]});
        this.termFeeNameList_dp = termFeeNameList_dp;
        //console.log("termFeeNameList_dp",this.termFeeNameList_dp);
      }
      else if(f === "R") {
        let termFeeNameList_rt = await this.$api("/api/pdtEtcFeeName_term", {param:[t1]});
        this.termFeeNameList_rt = termFeeNameList_rt;
        //console.log("termFeeNameList_rt",this .termFeeNameList_rt);
      }
    },
    async changeTermFeeNameList(f,t1){
      //console.log(f+"//"+t1);
      if(f === "D") {
        let termFee_dp = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        this.termFee_dp = termFee_dp;
        //console.log("termFee_dp",this.termFee_dp);
      }
      else if(f === "R") {
        let termFee_rt = await this.$api("/api/pdtEtcFee_term", {param:[t1]});
        this.termFee_rt = termFee_rt;
        //console.log("termFee_rt",this .termFee_rt);
      }
    },
    // async changePdtPromotion() {
    //   let choicePromotion = this.promoList.filter(e => {return e.seqPromo === this.seqPromo});
    //   this.choicePromotion = choicePromotion[0];
    //   this.choicePromotion.promoStart = moment(this.choicePromotion.promoStart).format("YYYY-MM-DD");
    //   this.choicePromotion.promoEnd = moment(this.choicePromotion.promoEnd).format("YYYY-MM-DD");
    //   this.cPromoFlag = this.choicePromotion.useFlag;
    // },
    async getPromotionList() {
      let promotionList = await this.$api("/api/promotionList", {param: [this.productId]});
      this.promoList = promotionList;
      // console.log("promoList:",this.promoList, "pDetail:",this.productDetail.deliCategory);
    },
    // //추가옵션 리스트박스용 상품명 추출(중복제거)
    // async addOptCheck(val) {
    //   //let opt = val;
    //   let addOptName_tmp = await this.$api("/api/addOptionNameList", {param: [this.productInfo.ntCd]});
    //   //console.log("addOptName_tmp:",addOptName_tmp);
    //   //let summaryData = this.eOrderList.filter(e => {return e.orderStatus == "30"}); //배열로 반환
    //   if(val === "T") {
    //     this.addOptNameList_l = addOptName_tmp.filter(e => {return e.salesRoute === "L"});
    //     this.addOptNameList_h = addOptName_tmp.filter(e => {return e.salesRoute === "H"});
    //     this.addOptNameList_e = addOptName_tmp.filter(e => {return e.salesRoute === "E"});
    //     this.addOptNameList_s = addOptName_tmp.filter(e => {return e.salesRoute === "S"});
    //     this.addOptNameList = addOptName_tmp.filter(e => {return e.salesRoute != "T"});
    //     //console.log("addOptNameList_l:",this.addOptNameList_l);
    //   }
    //   else if(val === "L") {
    //     this.addOptNameList_e = addOptName_tmp.filter(e => {return e.salesRoute === "E"});
    //     this.addOptNameList_s = addOptName_tmp.filter(e => {return e.salesRoute === "S"});
    //     this.addOptNameList = addOptName_tmp.filter(e => {return e.salesRoute != "T" && e.salesRoute != "L"});
    //     //console.log("addOptNameList:",this.addOptNameList);
    //   }
    //   else if(val === "E") {
    //     this.addOptNameList_s = addOptName_tmp.filter(e => {return e.salesRoute === "S"});
    //   }
    //   //배열내부 중복제거
    //   //this.addOptName_local = Array.from(new Set(addOptName_local_tmp));
    // },
    // async changeAddOptN(param1, param2) {
    //   //alert("P1:"+param1+"/P2:"+param2+"ntCd:"+this.productInfo.ntCd);
    //   let addOptList_tmp = await this.$api("/api/addOptionList", {param: [this.productInfo.ntCd]});
    //   //let addOptList_filterd = [];
    //   let addOptList_filterd = addOptList_tmp.filter(e => {return e.salesRoute === param1 && e.pdtCode === param2});
    //   //console.log("addOptList_tmp:",addOptList_tmp);

    //   switch(param1) {
    //     case "L": this.addOptList_l = addOptList_filterd;
    //       //console.log("addOptList_l:",this.addOptList_l);
    //     break;
    //     case "H": this.addOptList_h = addOptList_filterd;
    //       //console.log("addOptList_h:",this.addOptList_h);
    //     break;
    //     case "E": this.addOptList_e = addOptList_filterd;
    //       //console.log("addOptList_e:",this.addOptList_e);
    //     break;
    //     case "S": this.addOptList_s = addOptList_filterd;
    //       //console.log("addOptList_s:",this.addOptList_s);
    //     break;
    //     default: return this.$swal.fire("","CaseII: 오류가 발생했습니다./n관리자에 문의해주세요.","error");
    //   }
    // },
    // addEtcOption() {
    //   this.vCnt++;
    //   if(this.vCnt > 5) {
    //     return this.$swal.fire("","경유지 추가는 5까지입니다.","error");
    //   }
    //},
    // //기타옵션제거
    // removeEtcOption(val) {
    //   this.vCnt--;
    //   this.etcOptName[val] = undefined;
    //   this.etcOptPrice[val] = undefined;
    //   document.getElementById(val).remove();
    // },

    goToList() {
      this.$router.push({path: '/'});
    },

    //PNI 특기사항처리
    async spRemarkSave() {
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm_tmp = today.getMonth()+1;
      let dd_tmp = today.getDate();
      let hour = today.getHours();
      let min = today.getMinutes();
      let sec = today.getSeconds();
      
      let mm = "";
      if(mm_tmp < 10) {mm = "0" + mm_tmp}
      else mm = mm_tmp;
      let dd = "";
      if(dd_tmp < 10) {dd = "0" + dd_tmp}
      else dd = dd_tmp;
      
      let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
      let oldSp = ""; 
      let newSp = "";
      oldSp = this.reserveInfo.pniRemarks;
      newSp = this.reserveInfo.nPniRemarks;
      let insSp = oldSp +'\n'+ "["+ this.user.handlerId +":"+ insDate +"]: "+ newSp;
      console.log("insSp:",insSp);

      let updPniRemarks = await this.$api("/api/updPniRemarks", {param: [insSp, this.reqRsvCode]});
      if(updPniRemarks.affectedRows < 0 || updPniRemarks.affectedRows === undefined)
      {
        this.$swal.fire('', '특기사항 저장(SP Saving) 중 오류가 발생하였습니다.', 'error');
        return false;
      }
      else
      {
        this.$swal.fire('', '저장이 완료되었습니다', 'success');
        location.reload();
      }
    },
    /** 두배열간 중복제거 */
    removeDubofArrays(arr1, arr2)
    {
      return arr1.concat(arr2).filter(item => !arr1.includes(item) || !arr2.includes(item));
    },
    dataBaseTransaction(p1,p2){
      if(p1 === "U" && p2 ==="R") 
      {
        if(this.reserveInfo.procCode === "CX" && this.reserveInfo.payPoint > 0)
        {
          this.$swal.fire({
            icon: 'question',
            text: '사용한 포인트를 복구합니까?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
          }).then(async (result) => 
          {
            if (result.isConfirmed) 
            {
              //포인트 복구처리
              let payByPoint = await this.$api("/api/payByPoint", {param: [
                this.reserveInfo.reqEmail,this.reserveInfo.payPoint,'A',this.reqRsvCode, '예약취소', 'Y'
              ]});
              if(Object.keys(payByPoint)[0] === 'error') 
              {
                this.$swal.fire('', '포인트 복구처리 중 오류가 발생하였습니다.<br>관리자에게 문의해 주세요.', 'error');
                return false;
              }
              else
              {
                if(this.rtnRPflag === "Y")
                {
                  alert("updReqPay");
                  this.updReqPay();
                }
                else
                {
                  alert("insReqPay");
                  this.insReqPay();
                }

                let today = new Date();
                let yyyy = today.getFullYear();
                let mm_tmp = today.getMonth()+1;
                let dd_tmp = today.getDate();
                let hour = today.getHours();
                let min = today.getMinutes();
                let sec = today.getSeconds();
                
                let mm = "";
                if(mm_tmp < 10) {mm = "0" + mm_tmp}
                else mm = mm_tmp;
                let dd = "";
                if(dd_tmp < 10) {dd = "0" + dd_tmp}
                else dd = dd_tmp;
                
                let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;
                let oldComment = ""; 
                let newComment = "";
                oldComment = this.reserveInfo.oldComment;
                newComment = this.reserveInfo.newComment;
                let insComment = oldComment +'\n'+ "["+ this.user.handlerId +":"+ insDate +"]: "+ newComment;

                let cxl_reserve_m = await this.$api("/api/cxl_reserve_m", {param: [
                  this.reserveInfo.procCode,this.user.handlerId,insComment,
                  this.reqRsvCode
                ]});
                if(cxl_reserve_m.affectedRows < 0 || cxl_reserve_m.affectedRows === undefined)
                {
                  this.$swal.fire('', '예약취소 중 중 오류가 발생하였습니다.', 'error');
                  return false;
                }   
                else
                {
                  this.$swal.fire('', '예약이 취소되었습니다.', 'success');
                  location.reload();
                }
              }
            }
          })
          //}
        }
        else
        {
          if(this.cstPrice =="0")
          {
            this.$swal.fire("","상품가격 재계산을 하십시오,","warning");
            return false;
          }
          let totPgr = this.reserveInfo.reqAdultCnt*1 + this.reserveInfo.reqChildCnt*1 + this.reserveInfo.reqInfCnt*1 + this.reserveInfo.reqDiscountCnt*1;

          //console.log("selJourneyDate:",this.selJourneyDate);

          //결제요청 또는 예약취소의 경우, 결제요청상태 체크
          if(this.reserveInfo.procCode === "CX" || this.reserveInfo.procCode === "RP")
          {
            if(this.rtnRPflag === "Y")
            {
              this.updReqPay();
            }
            else
            {
              this.insReqPay();
            }
          }
          
          let today = new Date();
          let yyyy = today.getFullYear();
          let mm_tmp = today.getMonth()+1;
          let dd_tmp = today.getDate();
          let hour = today.getHours();
          let min = today.getMinutes();
          let sec = today.getSeconds();
          
          let mm = "";
          if(mm_tmp < 10) {mm = "0" + mm_tmp}
          else mm = mm_tmp;

          let dd = "";
          if(dd_tmp < 10) {dd = "0" + dd_tmp}
          else dd = dd_tmp;
          
          let insDate = `${yyyy}-${mm}-${dd} ${hour}:${min}:${sec}`;

          let oldComment = ""; 
          let newComment = "";
          oldComment = this.reserveInfo.oldComment;
          newComment = this.reserveInfo.newComment;
          let insComment = oldComment +'\n'+ "["+ this.user.handlerId +":"+ insDate +"]: "+ newComment;

          this.$swal.fire({
          icon: 'question',
          text: '예약정보를 저장하겠습니까?',
          showCancelButton: true,
          confirmButtonText: '저장',
          cancelButtonText: '취소'
          }).then(async (result) => 
          {
            if (result.isConfirmed) 
            {
              //console.log("paidPoint:",this.paidPoint);

              //포인트 결제가 있으면 처리한다(>0)
              if(this.paidPoint > 0)
              {
                let payByPoint = await this.$api("/api/payByPoint", {param: [
                  this.reserveInfo.reqEmail,this.paidPoint,'U',this.reqRsvCode,'', 'Y'
                ]});
                if(Object.keys(payByPoint)[0] === 'error') 
                {
                  this.$swal.fire('', '포인트 결제처리 중 오류가 발생하였습니다.<br>관리자에게 문의해 주세요.', 'error');
                  return false;
                }
              }

              if(this.reserveInfo.payPoint > 0)
              {
                this.paidPoint = this.reserveInfo.payPoint;
              }

              let upd_reserve_m = await this.$api("/api/upd_reserve_m", {param: [
                this.reserveInfo.procCode,this.reserveInfo.reqName,this.reserveInfo.reqTelNo,this.productInfo.ctCd,this.reserveInfo.reqAdultCnt*1,this.reserveInfo.reqChildCnt*1,this.reserveInfo.reqInfCnt*1,this.reserveInfo.reqDiscountCnt*1,totPgr*1,this.reservePriceInfo[0].reqAdultPrice*1,this.reservePriceInfo[0].reqChildPrice*1,this.reservePriceInfo[0].reqInfPrice*1,this.reservePriceInfo[0].reqDiscountPrice*1,this.user.handlerId,this.reserveInfo.pdtMargin,this.reserveInfo.vatPrice*1,this.user.handlerId,this.reserveInfo.pdtPromo,this.cstPrice,insComment,this.reqMarginRate,this.reqMarginMethod,this.reserveInfo.optDcPrice,this.paidPoint,
                this.reqRsvCode
              ]});
              if(upd_reserve_m.affectedRows < 0 || upd_reserve_m.affectedRows === undefined)
              {
                this.$swal.fire('', '정보변경작업(R-Master) 중 오류가 발생하였습니다.', 'error');
                return false;
              }
              else
              {
                let i = 0;
                let res = ""; //처리상태
                while(i<this.reservePriceInfo.length)
                {
                  let upd_reserve_d = await this.$api("/api/upd_reserve_d", {param: [
                    this.reservePriceInfo[i].reqAdultPrice*1,this.reservePriceInfo[i].reqChildPrice*1,this.reservePriceInfo[i].reqInfPrice*1,this.reservePriceInfo[i].reqDiscountPrice*1,this.getDateFormat(this.reservePriceInfo[i].reqDate),this.user.handlerId,i,this.reqRsvCode
                  ]});
                  if(upd_reserve_d.affectedRows < 0 || upd_reserve_d.affectedRows === undefined)
                  {
                    res = "error";
                    break;
                  }
                  else
                  {
                    res = "ok";
                  }
                  i++;
                }
                if(res === "error")
                {
                  this.$swal.fire('', '정보변경작업(R-Detail) 중 오류가 발생하였습니다.', 'error');
                  return false;
                }
                else
                {
                  //선택사항 해지
                  if(this.sEtcOpt.length == '0')
                  {
                    //this.$swal.fire('', '선택한 기타옵션항목이 없습니다', 'info');
                    let updSelEtcOption = await this.$api("/api/updSelEtcOption1", {param: [
                      'N',this.reqRsvCode
                    ]});
                    console.log("updSelEtcOption1:",updSelEtcOption);
                    if(updSelEtcOption.affectedRows < 0 || updSelEtcOption.affectedRows === undefined)
                    {
                      this.$swal.fire('', '정보변경작업(EtcOption unchecking) 중 오류가 발생하였습니다.', 'error');
                      return false;
                    }
                  }
                  else
                  {
                    // console.log("sEtcOptTEST:",this.sEtcOpt,"/ selectedEtcOptionTEST:",this.selectedEtcOption);
                    let k = 0;
                    let res2 = ""; //처리상태
                    while(k < this.selectedEtcOption.length)
                    {
                      let updSelEtcOption = await this.$api("/api/updSelEtcOption2", {param: [
                        this.selectedEtcOption[k].selFlag,this.reqRsvCode, k
                      ]});
                      if(updSelEtcOption.affectedRows < 0 || updSelEtcOption.affectedRows === undefined)
                      {
                        res2 = "error";
                        break;
                      }
                      else
                      {
                        res2 = "ok";
                      }
                      k++;
                    }
                    if(res2 === "error")
                    {
                      this.$swal.fire('', '정보변경작업(EtcOption Selecting) 중 오류가 발생하였습니다.', 'error');
                      return false;
                    }
                    else
                    {
                      let updSelEtcOptionApplyDate1 = await this.$api("/api/updSelEtcOptionApplyDate1", {param: [
                        'N', this.reqRsvCode
                      ]});
                      
                      if(updSelEtcOptionApplyDate1.affectedRows < 0 || updSelEtcOptionApplyDate1.affectedRows === undefined) 
                      {
                        this.$swal.fire('', '정보변경작업(updSelEtcOptionApplyDate1) 중 오류가 발생하였습니다.', 'error');
                        return false;
                      }
                      else
                      {
                        console.log("sJDateLength:",this.sJDate.length);
                        let j = 0;
                        let res3 = ""; //처리상태
                        while(j < this.sJDate.length)
                        {
                          let updSelEtcOptionApplyDate2 = await this.$api("/api/updSelEtcOptionApplyDate2", {param: [
                            'Y', this.reqRsvCode, this.sJDate[j]
                          ]});
                          if(updSelEtcOptionApplyDate2.affectedRows < 0 || updSelEtcOptionApplyDate2.affectedRows === undefined) 
                          {
                            res3 = "error";
                            break;
                          }
                          else
                          {
                            res3 = "ok";
                          }
                          j++;
                        }

                        if(res3 === "error")
                        {
                          this.$swal.fire('', '정보변경작업(updSelEtcOptionApplyDate2) 중 오류가 발생하였습니다.', 'error');
                          return false;
                        }
                        else
                        {
                          this.$swal.fire('', '정보변경작업이 완료되었습니다.', 'success');
                          location.reload();
                        }
                      }
                    }
                  }
                }
              }
              //this.$swal.fire('', '저장(변경)이 완료되었습니다', 'success');
              //location.reload();
            }
          })
        }
      }
      //PNI등록
      else if(p1==="U" && p2==="P") 
      { 
        //console.log("인원수변경:",this.reserveInfo.reqAdultCnt,this.reserveInfo.reqChildCnt,this.reserveInfo.reqInfCnt,this.reserveInfo.reqDiscountCnt);

        this.$swal.fire({
        icon: 'question',
        text: '인적사항을 등록(변경)합니까?',
        showCancelButton: true,
        confirmButtonText: '등록',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) 
          {
            let pniIndex = document.getElementsByName("pniIndex");

            //권종별 인적사항 등록(변경update or 추가insert)
            let i=0;
            let res1 = "";
            //let updPniData = [];
            while(i < pniIndex.length)
            {
              if(i < this.pniList.length)
              {
                let updPniData = await this.$api("/api/updPniData", {param: [
                  this.pniList[i].passNameEng,this.pniList[i].passNameKor,this.pniList[i].pBirthDate,this.pniList[i].passLicenseNo,this.pniList[i].passNo,this.pniList[i].pExpDate,this.pniList[i].passFlag,'',this.pniList[i].imgFlag,this.user.handlerId,this.pniList[i].priceType,this.pniList[i].pniTel,this.pniList[i].pniUseFlag,this.reqRsvCode,pniIndex[i].value
                ]});
                if(updPniData.affectedRows < 0 || updPniData.affectedRows === undefined)
                {
                  res1 = "error";
                  break;
                }
                else
                {
                  res1 = "ok";
                }
              }
              else if(i >= this.pniList.length)
              {
                let insPniData = await this.$api("/api/insPniData", {param: [
                  this.reqRsvCode, pniIndex[i].value,this.passNameEng[i],this.passNameKor[i],this.pBirthDate[i],this.passLicenseNo[i],this.passNo[i],this.pExpDate[i],this.passFlag[i],'','N',this.user.handlerId,'Y',this.priceType[i],this.pniTel[i]
                ]});
                if(Object.keys(insPniData)[0] === 'error') 
                {
                  res1 = "error";
                  break;
                }
                else
                {
                  res1 = "ok";
                }
              }
              i++;
            }
            if(res1 == "error")
            {
              this.$swal.fire('', '인적사항 처리 중(PNI updating) 오류가 발생하였습니다.', 'error');
              return false;
            }
            else
            {
              //PNI 카운트변경 동기처리
              let updPniAndReserveM = await this.$api("/api/updPniAndReserveM", {param: [
                this.reserveInfo.reqAdultCnt,this.reserveInfo.reqChildCnt,this.reserveInfo.reqInfCnt,this.reserveInfo.reqDiscountCnt,(this.reserveInfo.reqAdultCnt*1+this.reserveInfo.reqChildCnt*1+this.reserveInfo.reqInfCnt*1+this.reserveInfo.reqDiscountCnt),this.user.handlerId,this.reqRsvCode
                ]});
                if(updPniAndReserveM.affectedRows < 0 || updPniAndReserveM.affectedRows === undefined)
                {
                  this.$swal.fire('', '처리 중 오류(Pnicount Updating)가 발생했습니다.<br>관리자에게 문의해 주세요.', 'error');
                }
                else
                {
                  this.$swal.fire('', '인적사항 저장완료', 'success')
                  location.reload();
                }
            }
          }

        })
      }      
      else if (p1==="M" && p2 ==="P")
      {
        // 상담가격 재계산 후 업데이트 진행
        this.calcCstPrice();
      }
      else if(p1==="E" && p2==="O") { //기타옵션
        this.$swal.fire({
        icon: 'question',
        text: '기타옵션 생성?',
        showCancelButton: true,
        confirmButtonText: '생성',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.InsertEtcOption();
          }
        })
      }
      else if(p1==="C" && p2==="O") { //기타옵션 필수선택정도
        this.$swal.fire({
        icon: 'question',
        text: '기타옵션 선택수준 변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소'
        }).then(async (result) => {
          if (result.isConfirmed) {
            let i = 0;
            while(i < this.etcOptionInfo.length) {
            let res_etcOptFlagUpdate = await this.$api("/api/etcOptFlagUpdate", 
              {param: [this.etcOptionInfo[i].reqOptFlag,this.user.handlerId,this.productId,this.etcOptionInfo[i].etcOptName]});
              i++;
              if(res_etcOptFlagUpdate.affectedRows < 0 || res_etcOptFlagUpdate.affectedRows === undefined){
                alert("변경작업 중 에러발생\n관리자에게 문의해주세요.");
                return false;
              }
            }
            this.$swal.fire('옵션선택수준 변경완료', '', 'success')
            location.reload();
          }
        })
      }      
      else {
        return this.$swal.fire("","Case-DBTransation: <br>오류가 발생했습니다.<br>관리자에 문의해주세요.","error");
      }
    },
    //기타옵션 처리공통함수
    async InsertEtcOption()
    {
      //기타옵션
      let objEtcOpt = new Object();
      for(let j=1; j<this.etcOptName.length; j++) {
        let key = this.etcOptName[j];
        objEtcOpt[key] = this.etcOptPrice [j];
      }
      let objEtcOptSize = Object.keys(objEtcOpt).length;
      console.log("objEtcOptSize:",objEtcOptSize);
      //if(this.productPrice.etcOptionFlag === "Y") {
        for(let j=0; j < objEtcOptSize; j++){
          if(this.reqOptFlag[j] == null) {
            this.reqOptFlag[j] = "N";
          }
          //pdtCode, etcOptName, etcOptPrice, useFlag, insId
          let resIns_etcOption = await this.$api("/api/etcOptInsert", {param: [this.productId, Object.keys(objEtcOpt)[j],
            Object.values(objEtcOpt)[j], 'Y', this.user.handlerId, this.reqOptFlag[j+1]]});
            //console.log(resIns_etcOption);
            // 인서트오류처리
          if(Object.keys(resIns_etcOption)[0] === 'error') {
            alert("기타옵션 저장오류");
            return false;
          }
        }
        let res_UpdPdtEtcOptFlag = await this.$api("/api/changePdtEtcOptFlag_mix", {param: [this.productId]});
        if(res_UpdPdtEtcOptFlag.affectedRows < 0 || res_UpdPdtEtcOptFlag.affectedRows === undefined){
          this.$swal.fire('','상품리스트에서 기타옵션 적용표시(Y)확인필요<br>미표시이면 관리자에게 문의하세요', 'warning')
        }
        else {
          this.$swal.fire('','기타옵션 생성완료', 'success')
          location.reload();
        }
      //}
    },

    //결제요청 정보처리
    async insReqPay()
    {
      let insReqPayInfo = await this.$api("/api/insReqPayInfo",{param: [
        this.reqRsvCode,this.reserveInfo.procCode,this.cstPrice,'N',this.user.handlerId
      ]});
      if(Object.keys(insReqPayInfo)[0] === 'error') 
      {
        this.$swal.fire('','처리 중 오류발생(insReqPayInfo)<br>관리자에게 문의하세요', 'error')
        return false;
      }
    },
    async updReqPay()
    {
      let updReqPayInfo = await this.$api("/api/updReqPayInfo",{param: [
        this.reserveInfo.procCode,this.cstPrice,this.user.handlerId,this.reqRsvCode
      ]});
      if(updReqPayInfo.affectedRows < 0 || updReqPayInfo.affectedRows === undefined)
      {
        this.$swal.fire('','처리 중 오류발생(updReqPayInfo)<br>관리자에게 문의하세요', 'error')
        return false;
      }
    }    
  }
}
</script>

<style>
.reserdetail .r-name{
  margin-top:3rem;
}
.reserdetail .r-name .name{
  font-size:1.3rem;
  font-weight: 600;
  color:#000;
  padding-top:10px
}
.reserdetail .r-name .code{
  font-size:.8rem;
  color:#aaa;
  font-weight: 300;
}
.reserdetail .r-name .ndate{
  font-size:1rem;
  font-weight: 300;
}

.reserdetail .table{
  border-color:transparent; 
}
.reserdetail .table{
  margin-top:10px
}
.reserdetail .table tbody tr{
  font-weight:600;
  font-size:.9rem;
  height:2.5rem;
}

.reserdetail .title{
  border-bottom: 2px solid #000;
}
.reserdetail .title p{
  font-size:1.4rem;
  letter-spacing: 3px;
  color:#000;
}
.reserdetail .recode{
  font-size:.8rem;
  letter-spacing: 0;
  color:#979797
}

.reserdetail .traval, .reserdetail .vehicle{
    margin:3.7rem 0
  }

.reserdetail .paybtn{
  background-color: transparent;
}
.reserdetail .paybtn img{
  height: 2rem;
}

.reserdetail .go{
  background-color: #000;
  color:#fff;
  padding:15px 5px
}
.reserdetail .go:hover{
  background-color: #333;
}

@media (min-width:992px){
  .reserdetail .r-name .name{
    padding-left:30px
  }
  .reserdetail .table{
    margin:10px 0 0 10px
  }
  .reserdetail .table tbody tr{
    height:3.2rem;
  }
  .reserdetail .traval, .reserdetail .vehicle{
    margin:4.5rem 0
  }
}
</style>
