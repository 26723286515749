<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="p-1 text-center">
        <div class="float-end mb-2">
          <button type="button" class="btn btn-primary mb-1" @click="goToInsert"><strong>행사생성</strong>&nbsp;</button>
          <input v-model="dataInput" @input="submitAutoComplete" type="text" class="form-control form-control-sm text-primary" placeholder="신청자 또는 상담번호" />
          <!-- <div class="autocomplete disabled"> -->
          <div class="autocomplete disabled">
            <div class="text-success" style="cursor: pointer" :key="i" v-for="(res,i) in result" @click="searchData(res)">
              <small>{{ res }}</small>
            </div>
          </div>          
        </div>
        <div class="float-start mb-1">
          <select class="form-select form-select-sm" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_procCode" v-model="i" @change="changeCondition()">
            <option :value="noVal" disabled>현황조회</option>
            <option value="all" >전체행사</option>
            <option value="IQ" >문의</option>
            <option value="PR" >진행</option>
            <option value="RP" >결제요청</option>
            <option value="CO" >확정</option>
            <option value="CX" >취소</option>
            <!-- <option value="DC" >입금</option> -->
            <option value="RF" >환불</option>
          </select>
          <!-- <select class="form-select form-select-sm" style="$form-select-bg:yellow" aria-label=".form-select-sm" ref="sel_procCode" v-model="rProcCode" @change="changeCondition()">
            <option :value="noVal" disabled>진행상태</option>
            <option value="all" >전체보기</option>
            <option :value="rProcCode.procCode" :key=i v-for="(rProcCode,i) in rProc">{{rProcCode.procName}}</option>
            <option value="DC" >결제</option>
            <option value="RF" >환불</option>            
          </select> -->
        </div>
        <table class="table table-bordered table-striped fs-6 w-100">
          <thead class = "small">
            <tr>
              <th>상태</th>
              <th>신청일</th>
              <th>신청자</th>
              <th>회원</th>
              <th>행사코드</th>
              <th>출발</th>
              <th>상품명</th>
              <th>국가</th>
              <th>교통</th>
              <th>PNI</th>
              <th>가격</th>
              <th>결제</th>
              <th>행사기간</th>
              <!-- <th>설정</th> -->
            </tr>
          </thead>
          <tbody class = "small">
            <tr :key="i" v-for="(product, i) in productMaList"> 
              <td><small>{{ product.reqStatus1 }}</small></td>
              <td>{{ getDateFormat(product.reqDate) }}</td>
              <td>{{ product.reqName }}</td>
              <td class="text-danger" v-if="product.evtMFlag === 'N'">X</td>
              <td v-else></td>
              <td class="text-primary">
                <a @click="goToInfo(product.eventCode,product.ntCd);" style="cursor:pointer;">
                  <small>{{ product.eventCode }}</small>
                </a>
              </td>
              <td>{{ product.dptCtCd }}</td>
              <td>{{ product.eventNameKor }}</td>
              <td>{{ product.ntCd1 }}</td>
              <td>{{ product.evtTransFlag }}</td>
              <td class="text-primary">{{ product.pniUseFlag }}</td>
              <td>
                <div v-if="product.cstPrice_new == undefined || product.cstPrice_new == '' || product.cstPrice_new == null ">
                  <small>
                    {{ getCurrencyFormat(product.cstPrice_org) }}
                  </small>
                </div>
                <div v-else>
                  <small>
                    {{ getCurrencyFormat(product.cstPrice_new) }}
                  </small>
                </div>
              </td>

              <!-- <td v-if="product.settleFlag === 'DC'"> 
                <small class="text-danger">결제</small>
              </td>
              <td v-else-if="product.settleFlag === 'RF'"> 
                <small class="text-success">환불</small>
              </td>
              <td v-else> 
              </td> -->
              <td v-if="product.paidFlag === 'Y'" class = "text-danger"><b>{{ product.paidFlag }}</b></td>
              <td v-else>N</td>

              <td>
                <small>
                  {{ getDateFormat(product.evtStartDate) }} ~ {{ getDateFormat(product.evtCloseDate) }}
                </small>
              </td>
              <!-- <td>
                <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="goToInfo(product.eventCode,product.ntCd);">
                    <font style="font-size:small">수정</font>
                  </button>                  
                <button type="button" class="btn btn-outline-warning btn-sm me-1" @click="changeStatus(product.saleStatus, product.pCode)">
                  <font style="font-size:small">상태</font>
                </button>
              </td> -->
            </tr>
          </tbody>
        </table>
        <!-- pagenation area -->
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" aria-label="Previous" @click="paging('p')">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li class="page-item" :key="j" v-for="j in this.cntPage">
              <a class="page-link" @click="paging(pg = j)">{{j}}</a>
            </li>
            <li class="page-item">
              <a class="page-link" aria-label="Next" @click="paging('n')">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      // 검색처리
      reqList: [],
      skillInput: null,
      result: null,
      arrSearchData: [],      

      rProc: [], //상태리스트
      productMaList: [],
      productMaList_tmp: {},
      cntProdList: 0,
      pageSize: 12, //페이지당 데이타수
      page: 1, //offset기준
      cntPage: 1, //페이지네이션 표시페이지수


      pdtPromoInfo: {} //프로모션 정보
    };
  },
  created() {
    this.productId = this.$route.query.productId;
    this.getSalesList("all");
    

    //this.getReqProcLevel();
    console.log("AccessInfo:",this.$store.state.user);
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    ////document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    // async getReqProcLevel() {
    //   let rProc = await this.$api("/api/reqProcLevel", {});
    //   if(rProc.length > 0) {
    //     this.rProc = rProc
    //   }
    //   //console.log("rProc:",this.rProc);
    // },
    async getSalesList(pNum) {
      // this.pdtPriceMxMn = await this.$api("/api/pdtPriceMxMn", {param:[]});
      // console.log("pdtPriceMxMn:",this.pdtPriceMxMn);
      //alert(pNum);
      this.productMaList_tmp = {};
      if(pNum === "all"){
        this.productMaList_tmp = await this.$api("/api/eventList", {});
      }
      else if(pNum === "DC" || pNum === "RF")
      {
        this.productMaList_tmp = await this.$api("/api/eventList1", {param:[pNum]});
      }
      else if(pNum === "IQ" || pNum === "RP"  || pNum === "PR" || pNum === "CO" || pNum === "CX"){
        this.productMaList_tmp = await this.$api("/api/eventList2", {param:[pNum]});
      }
      else {
        this.productMaList_tmp = await this.$api("/api/eventList", {});
      }
      this.productMaList = this.productMaList_tmp
      this.cntProdList = this.productMaList_tmp.length;
      this.cntPage = Math.ceil(this.cntProdList / this.pageSize); //올림처리
      
      this.productMaList = this.productMaList_tmp.slice(0, this.pageSize); //시작idx, 지정번째idx-1
      if(this.productMaList.length < 1) {
        this.$swal.fire('', '데이타가 없습니다', 'info');
        return false;
      }
      console.log("productMaList:",this.productMaList);
    },
    paging(val){
        this.cntProdList = this.productMaList_tmp.length;

        this.cntPage = Math.ceil(this.cntProdList / this.pageSize); //올림처리

        let offSet = 0;
        console.log("page:",this.page);

        //페이지 초기
        if(val == undefined) {
          this.page = 1;
        }
        else {
          if(val === "p") {
            this.page--;
            if(this.page < 1) {
              this.page = 1;
              this.$swal.fire('', '시작페이지입니다', 'info')
            }
          }
          else if(val === "n") {
            this.page++;
            if(this.page > this.cntPage) {
              this.page = this.cntPage;
              this.$swal.fire('', '마지막페이지입니다', 'info')
            }
          }
          else{
            this.page = val;
          }
        }
        offSet = (this.page-1)*this.pageSize;
        this.productMaList = this.productMaList_tmp.slice(offSet, offSet+this.pageSize); //시작idx, 지정번째idx-1

        if(this.productMaList.length < 1) {
          this.$swal.fire('', '데이타가 없습니다', 'info');
          return false;
        }
    },
    async changeCondition() {
      this.getSalesList(this.i);
    },
    goToInsert() //행사생성
    {
      //this.$router.push({path: '/create'});
      this.$router.push({path: '/createEvent'});
    },

    //자동완성기능
    submitAutoComplete() 
    {
      const autocomplete = document.querySelector(".autocomplete");
      let result_tmp = [];
      if (this.dataInput) 
      {
        autocomplete.classList.remove("disabled");
        autocomplete.style.display = "block"
        result_tmp = this.arrSearchData.filter((e) => {
          return String(e).match(new RegExp("^" + this.dataInput.toUpperCase(), "i"));
        });
        //결과값 중복제거
        this.result = Array.from(new Set(result_tmp));        
      }
      else
      {
        autocomplete.classList.add("disabled");
        autocomplete.style.display = "none";
        this.getRequestConsultList()
      }
    },
    async getRequestConsultList() {
      let productMaList = await this.$api("/api/reqEvtConsultList", {});
      
      let i=0;
      let arrSearchData = [];
      if(productMaList.length > 0) {
        this.productMaList = productMaList;
        while(i<productMaList.length) {
          arrSearchData.push(productMaList[i].eventCode, productMaList[i].reqName);
          i++;
        }
      }
      this.arrSearchData = arrSearchData;
    },    
    searchData(res) 
    {
      //let reqList_tmp = this.reqList;
      let productMaList_tmp = this.productMaList;
      console.log("productMaList_tmp:",productMaList_tmp);
      if(res.substr(0,2) === "EV") 
      {
        this.productMaList = productMaList_tmp.filter(e => {return e.eventCode === res});
      }
      else 
      {
        this.productMaList = productMaList_tmp.filter(e => {return e.reqName === res});
      }
    },
    // mixins에 있는 공통함수 호출샘플
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    goToInfo(eventCode, ntCd) 
    {
      this.$router.push({path: '/eventInfo', query: {eCode: eventCode, ntCd: ntCd}});
    },
    changeStatus(saleStatus, pdtCode) {
      // 변경전 확인
      this.$swal.fire({
        title: '판매상태(판매<->중지)변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let sStatus = (saleStatus == "Y") ? "N" : "Y"
          // console.log(sStatus, pdtCode);
          await this.$api("/api/changePdtSalesStatus", {param: [sStatus, pdtCode]});
          // 상태변경 후, 갱신
          this.getSalesList("all");
          this.$swal.fire('변경완료!', '', 'success')
        // deny 버튼클릭 시 처리루틴
        // } else if (result.isDenied) {
        //   this.$swal.fire('Changes are not saved', '', 'info')
        }
      })
    },
    changeMixProduct(pdtCode) {
      // 변경전 확인
      this.$swal.fire({
        title: '결합상품 생성전환?',
        showCancelButton: true,
        confirmButtonText: '전환',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          // alert(pdtCode);
          // this.$router.push({path: '/salesMix', query: {}});
          //Mix상품코드생성
          let timeStamp = "";
          let today = new Date();   
          let yyyy = today.getFullYear();
          let mm = today.getMonth()+1;
          let dd = today.getDate();
          let ymd = `${yyyy}${mm}${dd}`
          //console.log(`ymd: ${ymd}`);
          timeStamp = today.getTime(); // 상품코드에 들어갈 타임스탬프
          let catCode = pdtCode.charAt(0);
          let mxPdtCode = catCode+"-"+ymd+"-"+timeStamp;
          console.log("mxPdtCode:",mxPdtCode);

          let resIns_product_mix = await this.$api("/api/makeMixProduct", {param: [pdtCode]});
          if(Object.keys(resIns_product_mix)[0] === 'error') {
            this.$swal.fire('', '결합상품 기본정보 생성오류<br>관리자 문의요망', 'alert')
          }
          else {
            //let resIns_product_mix_img = await this.$api("/api/productTmpImgInsert",{param: "MX-"+pdtCode});
            let resIns_product_mix_img = await this.$api("/api/productTmpImgInsert",{param: "MX-"+mxPdtCode});
            if(Object.keys(resIns_product_mix_img)[0] === 'error') {
              this.$swal.fire('', '결합상품 기초이미지 생성오류<br>관리자 문의요망', 'alert') 
            }
            else {
              let resUpd_product_mix_code = await this.$api("/api/updateMixPdtCode", {param: [mxPdtCode, this.user.handlerId,pdtCode]});
              if(resUpd_product_mix_code.affectedRows < 0 || resUpd_product_mix_code.affectedRows === undefined){
                this.$swal.fire('', '결합상품 기본이미지 생성오류<br>관리자 문의요망', 'alert')
              }
              else {
                //let resIns_pdtQty = await this.$api("/api/productQtyInsert",{param: ["MX-"+pdtCode,this.user.handlerId]});
                let resIns_pdtQty = await this.$api("/api/productQtyInsert",{param: ["MX-"+mxPdtCode,this.user.handlerId]});
                if(Object.keys(resIns_pdtQty)[0] === 'error') {
                  this.$swal.fire('', '결합상품 기본상품수량 생성오류<br>관리자 문의요망', 'alert')
                }
                else {
                  //this.getSalesList("all");
                  this.$swal.fire('생성완료!', '', 'success')
                  this.$router.push({path: '/salesMix', query: {}});
                }
              }
            }
          }
        }
      })
    },
    changeAuotSales(autoSales, pdtCode) {
      // 변경전 확인
      this.$swal.fire({
        title: '컨펌없는 자동판매(실행<->중지)상태',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let aStatus = (autoSales == "Y") ? "N" : "Y"
          // console.log(sStatus, pdtCode);
          await this.$api("/api/changeAutoSalesStatus", {param: [aStatus, pdtCode]});
          // 상태변경 후, 갱신
          this.getSalesList("all");
          this.$swal.fire('변경완료!', '', 'success')
        // deny 버튼클릭 시 처리루틴
        // } else if (result.isDenied) {
        //   this.$swal.fire('Changes are not saved', '', 'info')
        }
      })
    }
  }
}
</script>