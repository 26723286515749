<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-0">
      <div class="main p-0 text-center">
        <div class="alert alert-success" role="alert">
          <b class="fs-3">행 사 상 세 정 보</b>
        </div>

        <div class="text-success text-start fs-6 mb-2">
          <b>[ &nbsp; 기본정보 ( {{ this.dealInfoDetail.dealCode }} )&nbsp; ]</b>
        </div>
        <table class="table table-bordered table-striped fs-6">
          <thead class="small">
            <tr>
              <th style="width:60px;"><span class="text-danger">*</span>상태</th>
              <th style="width:120px;">행사명(<span class="text-danger">*</span>국문/영문)</th>
              <th style="width:70px;">판매금액</th>
              <th style="width:70px;"><span class="text-danger">*</span>예약자</th>
              <th style="width:90px;"><span class="text-danger">*</span>전화/이메일</th>
              <th style="width:50px;">인원</th>
              <th style="width:100px;"><span class="text-danger">*</span>행사기간</th>
              <th style="width:70px;">출발도시</th>
              <th style="width:70px;">여행지</th>
              <th style="width:80px;"><span class="text-danger">*</span>진행담당</th>
              <th style="width:150px;">비고사항</th>
              <th style="width:50px;"></th>
            </tr>
          </thead>
          <tbody class="small">
            <tr>
              <td>
                <select class="form-select form-select-sm resize-select" v-model="this.dealInfoDetail.reqStatus" ref="reqStatus">
                  <option :value="r.procCode" :key="i" v-for="(r,i) in this.reqStatusInfo">{{ r.procName }}</option>
                </select>
                <p mt-3 class="text-danger" v-if="this.dealInfoDetail.reqStatus === 'SE'">
                  {{ this.getDateFormat(this.dealInfoDetail.dSetDate) }}
                </p>
              </td>
              <td>
                <input type="text"  class="text-success mb-1 resize-input" v-model="this.dealInfoDetail.dealNameKor" maxlength="70" ref="dealNameKor" placeholder="국문,최대70자">
                <input type="text" class="mb-1 resize-input" v-model="this.dealInfoDetail.dealNameEng" maxlength="100" ref="dealNameEng" placeholder="영문,최대100자">
              </td>
              <td>
                <input type="text" class="resize-input" v-model="this.dealInfoDetail.dealPrice_tot" maxlength="10" ref="dealPrice_tot" placeholder="숫자만">
                <!-- <p class="mt-2 text-danger text-center fw-bold">
                  {{ this.getCurrencyFormat(this.dealInfoDetail.dealPrice_tot) }} 원
                </p> -->
              </td>
              <td>
                <input type="text" v-model="this.dealInfoDetail.reqName" maxlength="30" ref="reqName" class="resize-input">
              </td>
              <td>
                <div class="mb-1">
                  <input type="text" v-model="this.dealInfoDetail.reqTelNo" size="10" maxlength="15" ref="reqTelNo" placeholder="전화(숫자만)" class="resize-input">
                </div>
                <div>
                  <input type="text" v-model="this.dealInfoDetail.reqEmail" size="16" maxlength="50" ref="reqEmail" placeholder="이메일" class="resize-input">
                </div>
              </td>
              <td>
                <input type="text" v-model="this.dealInfoDetail.paxCnt" size="5" maxlength="10" ref="paxCnt" placeholder="숫자만" class="resize-input">
                <!-- {{ this.getCurrencyFormat(this.dealInfoDetail.paxCnt) }} -->
              </td>
              <td>
                <div class="input-group mb-1">
                  <span class="input-group-text input-group-sm" id="basic-addon1" style="font-size:smaller">시작</span>
                  <input type="date" class="form-control form-control-sm" ref="evtStartDate" v-model="this.dealInfoDetail.evtStartDate" style="font-size:smaller">
                </div>
                <div class="input-group">
                  <span class="input-group-text input-group-sm" id="basic-addon1" style="font-size:smaller">종료</span>
                  <input type="date" class="form-control form-controm-sm" ref="evtCloseDate" v-model="this.dealInfoDetail.evtCloseDate" style="font-size:smaller">
                </div>
              </td>
              <td>
                <select class="form-select form-select-sm" v-model="this.dealInfoDetail.dptCtCode" style="font-size: smaller;" ref="dptCtCode">
                  <option :value="d.baseCode" :key="i" v-for="(d,i) in this.dptCtList">{{ d.codeNameKor }}</option>
                </select>
              </td>
              <td>
                <div class="mb-1">
                  <select class="form-select form-select-sm" v-model="this.dealInfoDetail.ntCd" style="font-size: smaller;" @change="changeNationList(this.dealInfoDetail.ntCd)" ref="ntCd">
                    <option :value="c.baseCode" :key="i" v-for="(c,i) in this.tripNtList">{{ c.codeNameKor }}</option>
                  </select>
                </div>
                <div>
                  <select class="form-select form-select-sm" v-model="this.dealInfoDetail.arCd" style="font-size: smaller;" ref="arCd">
                    <option :value="a.baseCode" :key="i" v-for="(a,i) in this.tripArList">{{ a.codeNameKor }}</option>
                  </select>
                </div>
              </td>
              <td>
                <select class="form-select form-select-sm" v-model="this.dealInfoDetail.dealChargeId" style="font-size: smaller;" ref="dealChargeId">
                  <option :value="p.handlerId" :key="i" v-for="(p,i) in this.chargePList">{{ p.handlerNameKor }}</option>
                </select>
              </td>
              <td>
                <textarea class="form-control resize-input" placeholder="1,000자 이내" ref="evRemarks" v-model="this.dealInfoDetail.evRemarks" maxlength="1000" style="height: 80px;"></textarea>
              </td>
              <td v-if="this.changePermission === 'N'">
                <button type="button" class="btn btn-outline-secondary btn-sm" @click="updateEvent(this.dealInfoDetail.dealCode)" disabled>변경</button>
                <p class="mt-1">
                  <button type="button" class="btn btn-outline-secondary btn-sm" @click="cancelEvent(this.dealInfoDetail.dealCode)" disabled>취소</button>
                </p>
                <p v-if="this.user.handlerGrade < 6">
                  <button type="button" class="btn btn-outline-warning btn-sm" @click="cancelSE(this.dealInfoDetail.dealCode)" >정산취소</button>
                </p>
              </td>
              <td v-else>
                <button type="button" class="btn btn-outline-primary btn-sm" @click="updateEvent(this.dealInfoDetail.dealCode)">변경</button>
                <p class="mt-1">
                  <button type="button" class="btn btn-outline-danger btn-sm" @click="cancelEvent(this.dealInfoDetail.dealCode)">취소</button>
                </p>
                <!-- <p v-if="this.user.handlerGrade < 6">
                  <button type="button" class="btn btn-outline-warning btn-sm" @click="cancelSE(this.dealInfoDetail.dealCode)" >정산취소</button>
                </p> -->
              </td>
            </tr>
          </tbody>
          <br>
          <br>
        </table>

        <!-- 미수금 입력처리 -->
        <div class="text-end mb-1">
          <button type="button" class="btn btn-secondary btn-sm" @click="showRegitDeposit('D');">
            <i class="bi bi-caret-down"></i> 미수내역입력
          </button>
        </div>

        <div class="text-primary text-start fs-6" v-if="this.createDFlag == 'Y'">
          <b>[ &nbsp; 미수금 정보등록 &nbsp; <span style="font-size: smaller; color:red">※ 환율과 수수율을 입력하지 않는 경우, 기본율이 적용되며, 기초정보가 없는 경우 1로 계산됩니다</span> ]</b>
        </div>
        <table class="table table-bordered table-striped fs-6" v-if="this.createDFlag == 'Y'">
          <thead class="small">
            <tr>
              <th style="width:120px;"><span class="text-danger">*</span>미수내역</th>
              <th style="width:90px;"><span class="text-danger">*</span>미수액</th>
              <th style="width:50px;"><span class="text-danger">*</span>통화</th>
              <th style="width:50px;"><span class="text-danger">*</span>환율</th>
              <th style="width:80px;"><span class="text-danger">*</span>결제수단</th>
              <th style="width:90px;"><span class="text-danger">*</span>결제루트</th>
              <th style="width:50px;"><span class="text-danger">*</span>수수율</th>
              <th style="width:70px;"><span class="text-danger">*</span>미입금자/거래처</th>
              <th style="width:70px;"><span class="text-danger">*</span>미수일자</th>
              <th style="width:150px;">비고사항</th>
              <th style="width:50px;">-</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr>
              <td>
                <input type="text"  class="text-success mb-1 resize-input" v-model="this.dDealDetail" maxlength="200" ref="reg_dDealDetail" placeholder="최대200자">
              </td>
              <td>
                <input type="text" v-model="this.dPrice" maxlength="10" ref="reg_dPrice" placeholder="숫자만" class="resize-input">
                <p class="mt-2 text-secondary text-sm-end">
                  {{ this.getCurrencyFormat(this.dPrice) }}
                </p>
              </td>
              <td>
                <select class="form-select form-select-sm resize-select" v-model="this.dCurCd" style="font-size: smaller;" ref="reg_dCurCd" @change="getExRate('D',this.dCurCd)">
                  <option :value="r.fCodeNo" :key="i" v-for="(r,i) in this.finCode_curr">{{ r.fName }}</option>
                </select>
              </td>
              <td>
                <input type="text" v-model="this.dExRate" maxlength="10" ref="reg_dExRate" placeholder="소숫점2자리까지" class="resize-input">
              </td>
              <td>
                <select class="form-select form-select-sm" v-model="this.dPayMethod" style="font-size: smaller;" ref="reg_dPayMethod" @change="changePaymethod('C',this.dPayMethod,'')">
                  <option :value="r.baseCode" :key="i" v-for="(r,i) in this.payMethodList">{{ r.codeNameKor }}</option>
                </select>
              </td>
              <td>
                <select class="form-select form-select-sm" v-model="this.dPayCode" style="font-size: smaller;" ref="reg_dPayCode" @change="getFeeRate(this.dPayCode,'D')">
                  <option :value="p.fCodeNo" :key="i" v-for="(p,i) in this.payCodeList">{{ p.fName }}</option>
                </select>
              </td>
              <td>
                <input type="text" v-model="this.dFeeRate" maxlength="10" ref="reg_dFeeRate" placeholder="소숫점2자리까지" class="resize-input">
              </td>
              <td>
                <input type="text" v-model="this.dDepositor" maxlength="15" ref="reg_dDepositor" class="resize-input">
                <p class="mt-1">
                  <select class="form-select form-select-sm" v-model="this.dPartnerCd" style="font-size: smaller;">
                    <option value="not" selected>해당없음</option>
                    <option :value="p.pName" :key="i" v-for="(p,i) in this.partnerInfo">{{ p.pName }}</option>
                  </select>
                </p>
              </td>
              <td>
                <input type="date" class="form-control form-control-sm" ref="reg_dDate" v-model="this.dDate" style="font-size:smaller">
              </td>
              <td>
                <textarea class="form-control resize-input" placeholder="300자 이내" ref="reg_dDetailRemarks" v-model="this.dDetailRemarks" maxlength="300"></textarea>
              </td>
              <td>
                <button type="button" class="btn btn-outline-primary btn-sm" @click="registDeposit(this.dealInfoDetail.dealCode, 'O')">등록</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="align-middle fs-4 mb-2">
          <i class="bi bi-arrow-down-square-fill text-secondary"  v-if="this.createDFlag == 'Y'"></i>
        </div>


        <!-- 판매내역 입력처리 -->
        <div class="text-end mb-1">
          <button type="button" class="btn btn-success btn-sm" @click="showRegitDeposit('S');">
            <i class="bi bi-caret-down"></i> 판매내역입력
          </button>
        </div>

        <div class="text-primary text-start fs-6" v-if="this.createSFlag == 'Y'">
          <b>[ &nbsp; 판매정보등록 &nbsp;]</b>
          <span class="text-dark">&nbsp;총액: 0원 &nbsp;/&nbsp; 미수: 0원 &nbsp;/&nbsp; 과입: 0원</span>
        </div>
        <table class="table table-bordered table-striped fs-6" v-if="this.createSFlag == 'Y'">
          <thead class="small">
            <tr>
              <th style="width:3%;">번호</th>
              <th style="width:10%;"><span class="text-danger">*</span>고객명</th>
              <th style="width:4%;"><span class="text-danger">*</span>인원</th>
              <th style="width:8%;"><span class="text-danger">*</span>①판매가</th>
              <th style="width:8%;"><span class="text-danger">*</span>②판매수수료</th>
              <th style="width:9%;"><span class="text-danger">*</span>③(①-②)총판매가</th>
              <th style="width:9%;">④입금액</th>
              <th style="width:8%;">③-④미수/과입</th>
              <th style="width:20%;">메모사항</th>
              <th style="width:5%;">-</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr>
              <td>1</td>
              <td>
                <input type="text" v-model="this.cusName" maxlength="50" ref="cusName" placeholder="50자까지" class="resize-input">
              </td>
              <td>
                <input type="number" v-model="this.cusCnt" min="0" max="9999" ref="cusCnt" class="resize-input">
              </td>
              <td>
                <input type="number" v-model="this.dSalePrice" min="0" ref="dSalePrice" class="resize-input">
                <p class="mt-2 text-secondary text-sm-end">
                  {{ this.getCurrencyFormat(this.dSalePrice) }}
                </p>
              </td>
              <td>
                <input type="number" v-model="this.dSaleFee" min="0" ref="dSaleFee" class="resize-input">
                <p class="mt-2 text-secondary text-sm-end">
                  {{ this.getCurrencyFormat(this.dSaleFee) }}
                </p>
              </td>
              <td class="text-danger fw-bold">
                {{ this.getCurrencyFormat(this.dSalePrice-this.dSaleFee) }}
              </td>

              <td></td>
              <td></td>
              
              <td>
                <textarea class="form-control resize-input" placeholder="300자 이내" ref="dSaleMemo" v-model="this.dSaleMemo" maxlength="300"></textarea>
              </td>
              <td>
                <button type="button" class="btn btn-outline-success btn-sm" @click="registDeposit(this.dealInfoDetail.dealCode, 'S')">등록</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="align-middle fs-4 mb-2">
          <i class="bi bi-arrow-down-square-fill text-success"  v-if="this.createSFlag == 'Y'"></i>
        </div>



        <div class="text-primary text-start fs-6 mb-2">
          <b>[ &nbsp; 입금내역 &nbsp; ]</b>&nbsp;&nbsp;&nbsp; <i style="font-size: small; color:gray">총액: <b>{{ this.getCurrencyFormat( this.sumOfConvMoney) }}</b> 원</i>
        </div>
        <table class="table table-bordered table-striped fs-6">
          <thead class="small">
            <tr>
              <th style="width:8px;"><span class="text-danger"></span></th>
              <th style="width:120px;"><span class="text-danger">*</span>거래내역</th>
              <th style="width:90px;"><span class="text-danger">*</span>입금액</th>
              <th style="width:50px;"><span class="text-danger">*</span>통화/환율</th>
              <!-- <th style="width:50px;"><span class="text-danger">*</span>환율</th> -->
              <th style="width:80px;"><span class="text-danger">*</span>결제수단</th>
              <!-- <th style="width:90px;"><span class="text-danger">*</span>결제루트</th> -->
              <th style="width:50px;"><span class="text-danger">*</span>수수율</th>
              <th style="width:70px;"><span class="text-danger">*</span>입금자/거래처</th>
              <th style="width:70px;"><span class="text-danger">*</span>입금일자</th>
              <th style="width:50px;">환산(원)</th>
              <th style="width:50px;">미수(원)</th>
              <th style="width:50px;"><span class="text-danger">*</span>처리자</th>
              <th style="width:150px;">비고사항</th>
              <th style="width:50px;">-</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr :key="j" v-for="(d, j) in this.dDepositInfo">
              <td>{{ j+1 }}</td>
              <td>
                <input type="text"  class="text-success mb-1 resize-input" v-model="d.dDealDetail" maxlength="200" ref="dDealDetail" placeholder="최대200자">
                <p class="mt-1" style="font-size: smaller; color:blue; cursor: pointer;">
                  <a @click="goToSuppliment_detail(d.exDepositNo)">{{ d.exDepositNo }}</a>
                </p>
                <p v-if="d.outStFlag === 'Y'" style="color:red; font-weight:600;">
                  미수금액
                </p>
              </td>
              <td>
                <input type="text" v-model="d.dPrice" maxlength="10" ref="dPrice" placeholder="숫자만" class="resize-input">
                <p class="mt-2 text-secondary text-sm-end">
                  {{ this.getCurrencyFormat(d.dPrice) }}
                </p>
              </td>
              <td>
                <select class="form-select form-select-sm resize-select" v-model="d.dCurCd" style="font-size: smaller;" ref="dCurCd" @change="getExRate1(d.dCurCd, j, 'D')">
                  <option :value="r.fCodeNo" :key="i" v-for="(r,i) in this.finCode_currB">{{ r.fName }}</option>
                </select>
                <p class="mt-1">
                  <input type="text" v-model="d.dExRate" maxlength="10" ref="dExRate" placeholder="소숫점2자리까지" class="resize-input">
                </p>
              </td>
              <td>
                <select class="form-select form-select-sm" v-model="d.dPayMethod" style="font-size: smaller;" ref="dPayMethod" @change="changePaymethod('R',d.dPayMethod,j)">
                  <option :value="r.baseCode" :key="i" v-for="(r,i) in this.payMethodListB">{{ r.codeNameKor }}</option>
                </select>
                <p class="mt-1">
                  <select class="form-select form-select-sm" v-model="d.dPayCode" style="font-size: smaller;" ref="dPayCode" @change="getFeeRate1(d.dPayCode, j, 'D')">
                    <option :value="d.dPayCode" disabled v-if="this.payCodeListB[j] == undefined">{{ d.finName }}</option>
                    <option :value="p.fCodeNo" :key="i" v-for="(p,i) in this.payCodeListB[j]">{{ p.fName }}</option>
                  </select>
                </p>
              </td>
              <td>
                <input type="text" v-model="d.dFeeRate" maxlength="10" ref="dFeeRate" placeholder="소숫점2자리까지" class="resize-input">
              </td>
              <td>
                <input type="text" v-model="d.dDepositor" maxlength="15" ref="dDepositor" class="resize-input">
                <p class="mt-1">
                  <select class="form-select form-select-sm" v-model="d.dPartnerCd" style="font-size: smaller;">
                    <option value="not" selected>해당없음</option>
                    <option v-show="p.pCode != 'ZETW'" :value="p.pCode" :key="i" v-for="(p,i) in this.partnerInfo">{{ p.pName }}</option>
                  </select>
                </p>
              </td>
              <td>
                <input type="date" class="form-control form-control-sm" ref="dDate" v-model="d.dDate" style="font-size:smaller">
              </td>
              <td>
                <!-- <input type="text" v-model="d.dConvMoney" maxlength="15" ref="dConvMoney" class="resize-input"> -->
                <div>
                  <b v-if="d.outStFlag === 'Y'" class="text-danger">{{ this.getCurrencyFormat(d.dConvMoney) }}</b>
                  <b v-else class="text-primary">{{ this.getCurrencyFormat(d.dConvMoney) }}</b>
                  <p style="font-size: smaller; color:gray">(수수율 불포함)</p>
                </div>
              </td>
              <td>
                <!-- <input type="text" v-model="d.dBalance" maxlength="15" ref="dBalance" class="resize-input"> -->
                <div style="color:grey">
                  {{ this.getCurrencyFormat(d.dCalBalance) }}
                </div>
              </td>
              <td>
                {{ d.handler }}
              </td>
              <td>
                <textarea class="form-control resize-input" placeholder="300자 이내" ref="dDetailRemarks" v-model="d.dDetailRemarks" maxlength="300" style="height: 95px;"></textarea>
              </td>

              <td v-if="d.cFlag === 'Y'">
                <div class="text-danger">마감</div>
              </td>
              <td v-else>
                <div v-if="d.dFixFlag === 'Y'">
                  <!-- <button type="button" class="btn btn-outline-secondary btn-sm" @click="nAlert()">확정</button> -->
                  <button type="button" class="btn btn-outline-secondary btn-sm m-1" @click="nAlert()">변경</button>
                  <button type="button" class="btn btn-outline-secondary btn-sm m-1" @click="nAlert()">삭제</button>
                  <button type="button" class="btn btn-outline-danger btn-sm m-1" @click="excludeDeposit(this.dealInfoDetail.dealCode, d.dSubNo,j, 'D', d.exDepositNo)" v-if="this.dealInfoDetail.reqStatus != 'SE'">삭제{{ d.reqStatus }}</button>
                  <button type="button" class="btn btn-outline-secondary btn-sm m-1" v-if="d.outStFlag === 'Y'"  @click="nAlert()">미수삭제</button>
                </div>
                <div v-else>
                  <!-- <button type="button" class="btn btn-outline-primary btn-sm" @click="confirmDeposit(this.dealInfoDetail.dealCode, d.dSubNo)">확정</button> -->
                  <button type="button" class="btn btn-outline-success btn-sm m-1" @click="changeDeposit(this.dealInfoDetail.dealCode, d.dSubNo, j, 'D')" v-if="this.user.handlerGrade < 6">변경</button>
                  <button type="button" class="btn btn-outline-danger btn-sm m-1" @click="excludeDeposit(this.dealInfoDetail.dealCode, d.dSubNo,j, 'D', d.exDepositNo)" v-if="this.dealInfoDetail.reqStatus != 'SE'">삭제{{ d.reqStatus }}</button>
                  <button type="button" class="btn btn-secondary btn-sm m-1" v-if="d.outStFlag === 'Y'"  @click="excludeOutSt(d.dSubNo)">미수삭제</button>
                </div>
              </td>
            </tr>
          </tbody>
          <br>
          <br>
        </table>


        <div class="text-end mb-1" v-if="this.dealInfoDetail.reqStatus != 'SE'">
          <button type="button" class="btn btn-secondary btn-sm" @click="showRegitDeposit('W');">
            <i class="bi bi-caret-down"></i> 지출처리
          </button>
        </div>
        <div class="text-primary text-start fs-6" v-if="this.createWFlag == 'Y'">
          <b>[ &nbsp; 지출정보등록 &nbsp; <span style="font-size: smaller; color:red">※ 환율과 수수율을 입력하지 않는 경우, 기본율이 적용되며, 기초정보가 없는 경우 1로 계산됩니다</span> ]</b>
        </div>
        <!-- 지출항목	항목명	거래처	지출액	통화	환율	환산액	지출일자	수량(건)	합계액	처리자	버튼 -->
        <table class="table table-bordered table-striped fs-6" v-if="this.createWFlag == 'Y'">
          <thead class="small">
            <tr>
              <th style="width:6%;"><span class="text-danger">*</span>지출분류</th>
              <th style="width:12%;"><span class="text-danger">*</span>지출항목</th>
              <th style="width:10%;"><span class="text-danger">*</span>거래처</th>
              <th style="width:5%;"><span class="text-danger">*</span>지출일자</th>
              <th style="width:6%;"><span class="text-danger">*</span>지출액</th>
              <th style="width:6%;"><span class="text-danger">*</span>통화/환율</th>
              <th style="width:6%;"><span class="text-danger">*</span>지출수단/수수율</th>
              <th style="width:10%;">환산액</th>
              <th style="width:5%;"><span class="text-danger">*</span>수량</th>
              <th style="width:10%;">합계액</th>
              <th style="width:10%;">계좌정보</th>
              <th style="width:10%;">비고사항</th>
              <th style="width:5%;">-</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr>
              <td>
                <select class="form-select form-select-sm resize-select" v-model="this.expCatCode" style="font-size: smaller;" ref="reg_expCatCode" @change="changeExpCatCode(this.expCatCode)">
                  <option :value="ep.procCode" :key="i" v-for="(ep,i) in this.expCatList">{{ ep.procName }}</option>
                </select>
              </td>
              <td>
                <select class="form-select form-select-sm resize-select" v-model="this.expCode" style="font-size: smaller;" ref="reg_expCode">
                  <option :value="ei.expCode" :key="i" v-for="(ei,i) in this.expInfo">{{ ei.expName }}</option>
                </select>
              </td>
              <td>
                <select class="form-select form-select-sm" v-model="this.expPartnerCd" style="font-size: smaller;" @change="getBankInfo(expPartnerCd, 'C')">
                  <option v-show="p.pCode != 'ZETD'" :value="p.pCode" :key="i" v-for="(p,i) in this.partnerInfo">{{ p.pName }}</option>
                </select>
                <p class="mt-1"  v-if="this.expPartnerCd === 'ZETW'">
                  <input type="text" v-model="this.exPartnerName" maxlength="15" ref="reg_exPartnerName" class="resize-input" placeholder="명칭">
                  <input type="text" v-model="this.exPartnerTel" maxlength="15" ref="reg_exPartnerTel" class="resize-input" placeholder="연락처(숫자만)">
                </p>
              </td>
              <td>
                <input type="date" class="form-control form-control-sm" ref="reg_expDate" v-model="this.expDate" style="font-size:smaller">
              </td>
              <td>
                <input type="text" v-model="this.expAmt" maxlength="10" ref="reg_expAmt" placeholder="숫자만" class="resize-input">
                <p class="mt-2 text-secondary text-sm-end">
                  {{ this.getCurrencyFormat(this.expAmt) }}
                </p>
              </td>
              <td>
                <select class="form-select form-select-sm" v-model="this.expCurCd" style="font-size: smaller;" ref="reg_expCurCd" @change="getExRate('W',this.expCurCd)">
                  <option :value="c.fCodeNo" :key="i" v-for="(c,i) in this.finCode_curr">{{ c.fName }}</option>
                </select>
                <p class="mt-1">
                  <input type="text" v-model="this.expExRate" maxlength="10" ref="reg_expExRate" placeholder="소숫점2자리까지" class="resize-input">
                </p>
              </td>

              <td>
                <select class="form-select form-select-sm" v-model="this.expPayMethod" style="font-size: smaller;" ref="reg_expPayMethod" @change="changePaymethod('C',this.expPayMethod,'')">
                  <option :value="r.baseCode" :key="i" v-for="(r,i) in this.payMethodList">{{ r.codeNameKor }}</option>
                </select>
                <p class="mt-1 mb-1">
                  <select class="form-select form-select-sm" v-model="this.expPayCode" style="font-size: smaller;" ref="reg_expPayCode" @change="getFeeRate(this.expPayCode, 'W')">
                    <option :value="p.fCodeNo" :key="i" v-for="(p,i) in this.payCodeList">{{ p.fName }}</option>
                  </select>
                </p>
                <p class="mt-0 mb-0">
                  <input type="text" v-model="this.expFeeRate" maxlength="10" ref="reg_expFeeRate" placeholder="소숫점2자리까지" class="resize-input">
                </p>
              </td>
              
              <td>
                <input type="hidden" v-model="this.expConvMoney" maxlength="10" ref="reg_expConvMoney" class="resize-input">
                <p class="mt-1 text-secondary text-end">
                  {{ this.getCurrencyFormat(this.expConvMoney) }}원
                  <br>
                  <span style="font-size: smaller; color:gray">(수수율 불포함)</span>
                </p>
              </td>
              <td>
                <input type="text" v-model="this.expQty" maxlength="10" ref="reg_expQty" class="resize-input">
              </td>
              <td>
                <b class="text-danger">{{ this.getCurrencyFormat((this.expConvMoney*1)*(this.expQty*1))}}</b> 원
              </td>

              <!-- 거래처계좌 -->
              <td>
                <select class="form-select form-select-sm" v-model="bankCode" style="font-size: smaller;" ref="bankCode">
                  <option :value="c.fCodeNo" :key="i" v-for="(c,i) in this.bankInfo">{{ c.fName }}</option>
                </select>
                <p class="mt-1 mb-1">
                  <input type="text" v-model="bAccount" maxlength="30" ref="bAccount" placeholder="계좌번호" class="resize-input">
                </p>
                <p class="mt-0 mb-0">
                  <input type="text" v-model="accHolder" maxlength="50" ref="accHolder" placeholder="예금주" class="resize-input" style="background-color: blanchedalmond;">
                </p>
              </td>

              <td>
                <textarea class="form-control resize-input" placeholder="300자 이내" ref="reg_dDetailRemarks" v-model="this.expRemarks" maxlength="300" style="height: 80px;"></textarea>
              </td>
              <td>
                <button type="button" class="btn btn-outline-primary btn-sm" @click="registDeposit(this.dealInfoDetail.dealCode, 'W')">지출</button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="align-middle fs-4 mb-2">
          <i class="bi bi-arrow-down-square-fill text-secondary"  v-if="this.createWFlag == 'Y'"></i>
        </div>

        <!-- 모달영역 -->
        <div v-if="showModal" class="modal" style="height:100%;">
          <div class="modal-content">
            <span @click="showModal = false" class="close">&times;</span>
            <Modal_ExpReceipt :showModal="showModal" :modalContent="arr_expenseInfo" @close="closeModal"/>
          </div>
        </div>

        <div class="text-success text-start fs-6">
          <b>[ &nbsp; 지출내역 &nbsp; ]</b>&nbsp;&nbsp;&nbsp; <i style="font-size: small; color:gray">총액: <b>{{ this.getCurrencyFormat( this.expTotal) }}</b> 원</i>

          <span style="padding-left:2rem;">
            <button class="btn btn-success btn-sm" @click="makeExpenditure()">선택항목 지출품의</button>
          </span>
        </div>

        <table class="table table-bordered table-striped fs-6">
          <thead class="small">
            <tr>
              <th style="width:3%;">
                <input class="form-check-input" type="checkbox" v-model="allSelected" @change="allChoice()" />
              </th>
              <th style="width:7%;"><span class="text-danger">*</span>항목</th>
              <th style="width:10%;"><span class="text-danger">*</span>항목명</th>
              <th style="width:8%;"><span class="text-danger">*</span>거래처</th>
              <th style="width:5%;"><span class="text-danger">*</span>지출일자</th>
              <th style="width:6%;"><span class="text-danger">*</span>지출액</th>
              <th style="width:5%;"><span class="text-danger">*</span>통화/환율</th>
              <th style="width:6%;"><span class="text-danger">*</span>지출수단/수수율</th>
              <th style="width:7%;">환산액</th>
              <th style="width:3%;"><span class="text-danger">*</span>수량</th>
              <th style="width:8%;">합계액</th>
              <th style="width:10%;">계좌정보</th>
              <th style="width:10%;">비고사항</th>
              <th style="width:5%;">-</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr :key="i" v-for="(w,i) in dWithdrawalInfo">
              <td>
                {{ i+1 }}
                <p>
                  <input type="checkbox" v-model="w.checkFlag"  ref="chb_Seq" class="align-baseline mt-3" :id=i name="checkedBox" :disabled="w.reqStat == 'lv1' || w.reqStat == 'lv5' || w.reqStat == 'lv9' || w.reqStat == 'rj5' || w.reqStat == 'rj9'">
                </p>
              </td>
              <td>
                {{ w.expCodeName }}
              </td>
              <td>
                <p class="text-secondary" style="font-size:small">{{ w.exName }}</p>
                <p class="text-secondary" style="font-size:small">{{ w.expCode }}</p>
              </td>
              <td>
                <select class="form-select form-select-sm" v-model="w.dPartnerCd" style="font-size: smaller;" @change="getBankInfo(w.dPartnerCd, i)">
                  <option v-show="p.pCode != 'ZETD'" :value="p.pCode" :key="i" v-for="(p,i) in this.partnerInfo">{{ p.pName }}</option>
                </select>
                <p class="mt-1"  v-if="w.dPartnerCd === 'ZETW'">
                  <input type="text" v-model="w.etcPaName" maxlength="15" ref="w_exPartnerName" class="resize-input" placeholder="명칭">
                  <input type="text" v-model="w.etcPaTel" maxlength="15" ref="w_exPartnerTel" class="resize-input" placeholder="연락처(숫자만)">
                </p>
              </td>
              <td>
                <input type="date" class="form-control form-control-sm" ref="w_expDate" v-model="w.dDate" style="font-size:smaller">
              </td>
              <td>
                <input type="text" v-model="w.dPrice" maxlength="10" ref="w_expAmt" placeholder="숫자만" class="resize-input">
                <p class="mt-2 text-secondary text-sm-end">
                  {{ this.getCurrencyFormat(w.dPrice) }}
                </p>
              </td>
              <td>
                <select class="form-select form-select-sm" v-model="w.dCurCd" style="font-size: smaller;" ref="w_expCurCd" @change="getExRate1(w.dCurCd, i, 'W')">
                  <option :value="c.fCodeNo" :key="k" v-for="(c,k) in this.finCode_currB">{{ c.fName }}</option>
                </select>
                <p class="mt-1">
                  <input type="text" v-model="w.dExRate" maxlength="10" ref="w_expExRate" placeholder="소숫점2자리까지" class="resize-input">
                </p>
              </td>

              <td>
                <select class="form-select form-select-sm" v-model="w.dPayMethod" style="font-size: smaller;" ref="w_expPayMethod" @change="changePaymethod('R',w.dPayMethod,i)">
                  <option :value="r.baseCode" :key="k" v-for="(r,k) in this.payMethodListB">{{ r.codeNameKor }}</option>
                </select>
                <p class="mt-1 mb-1">
                  <select class="form-select form-select-sm" v-model="w.dPayCode" style="font-size: smaller;" ref="w_expPayCode" @change="getFeeRate1(w.dPayCode, i, 'W')">
                    <!-- <option :value="p.fCodeNo" :key="i" v-for="(p,i) in this.payCodeListB">{{ p.fName }}</option> -->
                    <option :value="w.dPayCode" disabled v-if="this.payCodeListB[i] == undefined">{{ w.finName }}</option>
                    <option :value="p.fCodeNo" :key="i" v-for="(p,i) in this.payCodeListB[i]">{{ p.fName }}</option>                    
                  </select>
                </p>
                <p class="mt-0 mb-0">
                  <input type="text" v-model="w.dFeeRate" maxlength="10" ref="w_expFeeRate" placeholder="소숫점2자리까지" class="resize-input">
                </p>
              </td>              
              
              <td>
                <!-- <input type="hidden" v-model="" maxlength="10" ref="reg_expConvMoney" class="resize-input"> -->
                <p class="mt-1 text-secondary text-end">
                  {{ this.getCurrencyFormat(w.dPrice * w.dExRate) }}원
                  <br>
                  <span style="font-size: smaller; color:gray">(수수율 불포함)</span>
                </p>
              </td>
              <td>
                <input type="text" v-model="w.expQty" maxlength="10" ref="reg_expQty" class="resize-input">
              </td>
              <td>
                <b class="text-success">{{ this.getCurrencyFormat((w.dPrice * w.dExRate)*(w.expQty*1))}}</b> 원
              </td>

              <!-- 거래처계좌 -->
              <td>
                <select class="form-select form-select-sm" v-model="w.bankCode" style="font-size: smaller;" ref="w_bankCode">
                  <option :value="c.fCodeNo" :key="i" v-for="(c,i) in this.bankInfo">{{ c.fName }}</option>
                </select>
                <p class="mt-1 mb-1">
                  <input type="text" v-model="w.bankAccount" maxlength="30" ref="w_bAccount" placeholder="계좌번호" class="resize-input">
                </p>
                <p class="mt-0 mb-0">
                  <input type="text" v-model="w.accHolder" maxlength="50" ref="w_accHolder" placeholder="예금주" class="resize-input" style="background-color: blanchedalmond;">
                </p>
              </td>

              <td>
                <textarea class="form-control resize-input" placeholder="300자 이내" ref="reg_dDetailRemarks" v-model="w.dDetailRemarks" maxlength="300" style="height: 80px;"></textarea>
              </td>
              
              <td v-if="this.user.handlerGrade <= 9">
                <div v-if="w.reqStat === 'lv0' || w.reqStat == null || w.reqStat == ''">
                  <button type="button" class="btn btn-outline-success btn-sm m-1" @click="changeDeposit(w.dealCode, w.dSubNo, i, 'W')" >
                    변경
                  </button>
                  <button type="button" class="btn btn-outline-danger btn-sm m-1" @click="excludeDeposit(w.dealCode, w.dSubNo, i , 'W', w.expCode)">
                    삭제
                  </button> 
                </div>
                <div v-else class="text-danger">
                  <div v-if="w.reqStat === 'lv9'">
                    품의완료
                  </div>
                  <div v-else-if="w.reqStat === 'rj5' || w.reqStat === 'rj9'">
                    품의반려
                  </div>
                  <div v-else>
                    품의중
                  </div>
                  <p @click="goToReqReqView(w.reqCode);" style="cursor:pointer; color:blue;">{{ w.reqCode }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </main>
  </div>
</template>

<script>
import Modal_ExpReceipt from "./Modal_ExpReceipt"

export default {
  components: {
    Modal_ExpReceipt
  },
  data() {
    return {
      //판매내역정보
      cusName:    "",             //고객명
      cusCnt:      0,             //인원수
      dSalePrice:  0,             //판매가 
      dSaleFee:    0,             //판매수수료
      dSaleMemo:  "",             //판매메모사항



      // c_exCode: "",             //개별입금코드(마감)
      // nc_exCode: "",            //개별입금코드(비마감)

      dealInfoDetail: [],       //거래상세정보(마스터)
      dDepositInfo: [],         //입금내역정보
      dWithdrawalInfo: [],      //출금내역정보
      changePermission: "",     //수정취소권한
      finCodeList: [],          //금융코드리스트(입금처리)
      finCode_curr: [],         //통화코드리스트(입금처리)
      payMethodList: [],        //결제수단리스트(입금처리)
      payCodeList: [],          //결제코드리스트(입금처리)
      chargePList: [],          //담당자리스트
      partnerInfo: [],          //거래처리스트

      finCodeListB: [],          //금융코드리스트(입금내역)
      finCode_currB: [],         //통화코드리스트(입금내역)
      payMethodListB: [],        //결제수단리스트(입금내역)
      payCodeListB: [],          //결제코드리스트(입금내역)

      //여정지역데이타
      dptCtList: [],            //출발도시리스트
      tripNtList: [],           //여행국가리스트
      tripArList: [],           //국가하위 지역리스트
      tripArListforSearch: [],  //국가하위 지역리스트(검색용)
      tripArList_tmp: [],       //임시데이타

      reqStatusInfo: [],        //상태리스트

      createDFlag: "N",         //미수생성란 표시여부
      createWFlag: "N",         //출금생성란 표시여부

      //입금정보생성
      dDealDetail: "",          //거래내용
      dPrice: 0,                //거래금액
      dCurCd: "",               //통화코드
      dExRate: 1.00,             //환율(1:원화)
      dPayMethod:"",            //결제수단
      dPayCode: "",             //결제루트
      dFeeRate: 0.00,           //결제수수료(%)
      dDate: "",                //입금일자
      dDepositor: "",           //입금자
      dPartnerCd: "",           //거래처코드
      dDetailRemarks: "",       //상세비고

      dBalance: 0,              //잔액
      sumOfConvMoney: 0,        //입금총액

      searchDuration: "G",   //검색기간기준
      searchStart: "",       //검색기준일
      searchEnd: "",
      dealName: "",          //검색용 거래명
      chargePerson: "",      //검색명 담당자명

      searchNtCd: "",        //검색용 국가코드
      searchArCd: "",        //검색용 지역코드
      resName: "",           //검색용 예약자
      searchCon: "",         //검색용 선택조건(SAG,SAE,STG,STE,OSB)

      expCatList: [],       //지출분류
      expInfo: [],          //지출분류에 종속된 지출항목리스트
      expInfo1: [],          //지출분류에 종속된 지출항목리스트
      expenditureInfo: [],  //지출항목

      expCatCode: "",       //지출항목코드(생성)
      //지출항목생성
      expCode: "",          //지출항목코드
      expName: "",          //지출항목명
      expPartnerCd: "",     //지출거래처코드
      exPartnerName: "",    //기타출금거래처명
      exPartnerTel: "",     //기타출금거래처연락처
      expDate: "",          //지출일자
      expAmt: 0,            //지출금액
      expCurCd: "",         //지출통화코드
      expExRate: 1.00,      //지출통화환율
      expConvMoney: 0,      //지출환산액
      expQty: 1,            //지출수량
      //expTotAmt: 0,         //합산액
      expHandler: "",       //지출처리자
      expRemarks: "",       //지출비고사항
      expPayMethod: "",     //지출수단
      expPayCode: "",
      expFeeRate: 0.00,     //지출수수율

      expTotal: 0,          //지출총액

      //지출거래처 계좌정보
      bankInfo: [],     //은행정보
      bankCode: "",     //거래처 은행코드
      bAccount: "",     //거래처 은행계좌
      accHolder: "",    //예금주
      
      partnerInfoByCode: [],    //특정거래처 정보

      //modal컨트롤(증빙서류 업로드)
      showModal: false,
      modalContent: "",

      createSFlag: "N",         //판매생성란 표시여부
    };
  },
  created() {
    //this.productId = this.$route.query.productId;
    this.user = this.$store.state.user;
    this.dealCode = this.$route.query.dCode;
    // this.checkFlag = this.$route.query.cFlag;
    // let exCode = this.$route.query.exCode;
    // if(this.checkFlag === 'Y')
    // {
    //   this.c_exCode = exCode
    // }
    // else
    // {
    //   this.nc_exCode = exCode
    // }

    this.getDealInfoDetail(this.dealCode)
    this.getCategoryList();
    this.getDepositInfo(this.dealCode)
    this.getBasicCode();

    this.getCategoryList_exp()            //지춢분류리스트
    this.getExpenditureInfo()             //지출항목리스트

    this.getPartnerList();

  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    this.dealChargeId = this.user.handlerId;
    ////document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    
    //확정고지
    nAlert(){
      this.$swal.fire('', '확정된 내역입니다.<br>확정취소 권한자에게 문의하세요.', 'info')
      return false;
    },
    
    // 전체일괄선택
    allChoice() 
    {
      if(this.allSelected)
      {
        this.dWithdrawalInfo.forEach((i) => {
          if(i.reqStat != "lv1" && i.reqStat != "lv5" && i.reqStat != "lv9" && i.reqStat != "rj5" && i.reqStat != "rj9")
          {
            i.checkFlag = true;
          }
        })
      }
      else
      {
        this.dWithdrawalInfo.forEach((i) => {
          i.checkFlag = false;
        })
      }
    },

    //품의서 바로가기
    goToReqReqView(rCode) {
      this.$router.push({path: '/expenseReportView', query: {rCode: rCode}});
    },

    // 선택항목 품의서 생성
    makeExpenditure(){
      let selectedIndex = []

      this.dWithdrawalInfo.forEach((i) => {
        if(i.checkFlag)
        {
          selectedIndex.push(i)
        }
      })
      console.log("selectedIndex:", selectedIndex)

      if(selectedIndex.length > 0)
      {
        //품의서 작성 전, 첨부문서 요구
        this.$swal.fire({
          html: '선택된 지출내역별 증빙자료가 있습니까?',
          showCancelButton: true,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          icon: "question"
        }).then((result) => {
          if(result.isConfirmed)
          {
            //모달표시
            this.showModal = true;
            this.arr_expenseInfo = selectedIndex;
          }
        })
      }
      else
      {
        this.$swal.fire("", "선택된 항목이 없습니다.", "warning")
        return false;
      }

    },

    closeModal(){
      this.showModal = false;
    },


    //거래상세정보(마스터)
    async getDealInfoDetail(dCode) {
      let dealInfoDetail = await this.$api("/api/dealInfoDetail", {param: [dCode]});

      if(dealInfoDetail.length > 0)
      {
        this.dealInfoDetail = dealInfoDetail[0]
        this.dealInfoDetail.evtStartDate = this.getDateFormat(this.dealInfoDetail.evtStartDate)
        this.dealInfoDetail.evtCloseDate = this.getDateFormat(this.dealInfoDetail.evtCloseDate)
        if(this.dealInfoDetail.reqStatus === 'SE' || this.dealInfoDetail.reqStatus === 'SG')
        {
          this.changePermission = 'N'
        }
      }
      console.log("dealInfoDetail:",this.dealInfoDetail);
    },

    //여행지역정보
    async getCategoryList()
    {
      //상태리스트
      let reqStatusInfo = await this.$api("/api/reqStatusInfo", {param: ['SET', 'Y']});
      if(reqStatusInfo.length > 0)
      {
        this.reqStatusInfo = reqStatusInfo
      }
      //console.log("reqStatusInfo:", this.reqStatusInfo)

      //출발도시
      let dptCtList = await this.$api("/api/searchBasecode", {param: ['ctCd']});
      if(dptCtList.length > 0)
      {
        this.dptCtList = dptCtList
      }
      //console.log("dptCtList:", this.dptCtList);

      //여행국가리스트
      let tripNtList = await this.$api("/api/searchBasecode", {param: ['ntCd']});
      if(tripNtList.length > 0)
      {
        this.tripNtList = tripNtList
      }

      //여행지역리스트
      let tripArList = await this.$api("/api/searchBasecode", {param: ['arCd']});
      if(tripArList.length > 0)
      {
        this.tripArList = tripArList
      }

      //담당자정보
      let chargePList = await this.$api("/api/handlerInfo", {})
      if(chargePList.length > 0)
      {
        this.chargePList = chargePList;
      }
      //console.log("chargePList:", this.chargePList)
    },
    changeNationList(param) {
      //this.nationCode = this.productInfo.baseCode;
      this.viewAreaList(param);
    },
    async viewAreaList(param1){
      let tripArList = await this.$api("/api/searchBasecode", {param: ['arCd']});

      if(tripArList.length > 0)
      {
        this.tripArList_tmp = tripArList
      }

      this.tripArList = this.tripArList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("area:", this.areaList);
    },

    //카테고리정보(지출분류)
    async getCategoryList_exp() 
    {
      let expCatList = await this.$api("/api/reqStatusInfo2", {param: ['EXP', '%']});
      if(expCatList.length > 0)
      {
        this.expCatList = expCatList
      }
      //console.log("expCatList:", this.expCatList)
    },

    changeExpCatCode(expCatCode){
      //console.log("expCatCode:",expCatCode)
      let expInfo = []
      expInfo = this.expenditureInfo.filter(e => {return e.expCategory === expCatCode})
      
      if(expInfo.length > 0)
      {
        this.expInfo = expInfo
      }
      else
      {
        this.expInfo = ""
      }
      //console.log("expInfo:", this.expInfo)
    },

    //지출항목정보(기초정보)
    async getExpenditureInfo(){
      let expenditureInfo = await this.$api("/api/expenditureInfo", {param: ["Y"]})

      if(expenditureInfo.length > 0)
      {
        this.expenditureInfo = expenditureInfo
      }
      //console.log("expenditure1:", this.expInfo1)
    },

    //행사기본정보변경
    updateEvent(dealCode)
    {
      //변경전 확인
      this.$swal.fire({
        title: '기본정보를 변경합니까?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let updDealInfoDetail = await this.$api("/api/updDealInfoDetail", {param: [
            this.dealInfoDetail.dealNameKor,this.dealInfoDetail.dealNameEng,this.dealInfoDetail.reqName,this.dealInfoDetail.reqTelNo,this.dealInfoDetail.reqEmail,this.dealInfoDetail.dptCtCode,this.dealInfoDetail.ntCd,this.dealInfoDetail.arCd,this.dealInfoDetail.evtStartDate,this.dealInfoDetail.evtCloseDate,this.dealInfoDetail.dealPrice_tot,this.dealInfoDetail.evRemarks,this.dealInfoDetail.reqStatus,this.user.handlerId,this.dealInfoDetail.dealChargeId,this.dealInfoDetail.paxCnt, dealCode
          ]});
          //console.log("updDealInfoDetail:",updDealInfoDetail)
          // 상태변경 후, 갱신
          if(updDealInfoDetail.affectedRows >= 0)
          {
            let upd_dealHistory_m = await this.$api("/api/upd_dealHistory_m", {param:[
              this.dealInfoDetail.dealPrice_tot, this.user.handlerId, dealCode
            ]})
            if(upd_dealHistory_m.affectedRows > 0)
            {
              this.$swal.fire('', '변경완료', 'success')
              location.reload();
            }
          }
          else
          {
            this.$swal.fire('', '오류가 발생했습니다(코드:updDealInfoDetail)', 'warning')
            return false;
          }
        }
      })
    },

    //행사취소
    cancelEvent(dCode)
    {
      //변경전 확인
      this.$swal.fire({
        html: '행사를 취소합니까?',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let cxl_dealInfo = await this.$api("/api/cxl_dealInfo", {param: ['EC',this.user.handlerId, dCode]});
          // 상태변경 후, 갱신
          if(cxl_dealInfo.affectedRows > 0)
          {
            this.$swal.fire('', '취소완료', 'success')
            location.reload();
          }
          else
          {
            this.$swal.fire('', '오류가 발생했습니다(코드:cxl_dealInfo)', 'warning')
            return false;
          }
        }
      })
    },

    //정산취소
    cancelSE(dCode)
    {
      let reqStatus = "EE"
      //변경전 확인
      this.$swal.fire({
        html: '정산완료에서 행사마감으로 상태를 변경합니까?',
        showCancelButton: true,
        confirmButtonText: '예',
        cancelButtonText: '아니오',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let cxl_dealSE = await this.$api("/api/cxl_dealSE", {param: [reqStatus,this.user.handlerId, dCode]});
          // 상태변경 후, 갱신
          if(cxl_dealSE.affectedRows > 0)
          {
            this.$swal.fire('', '정산완료취소', 'success')
            location.reload();
          }
          else
          {
            this.$swal.fire('', '오류가 발생했습니다(코드:cxl_dealSE)', 'warning')
            return false;
          }
        }
      })
    },

    async showRegitDeposit(param)
    {
      let finCodeList = await this.$api("/api/finCodeList", {});
      if(finCodeList.length > 0)
      {
        this.finCodeList = finCodeList;
        this.finCode_curr = this.finCodeList.filter((item) => {
          return item.fCategory === 'Currency';
        })
      }
      let payMethodList = await this.$api("/api/baseCategory", {param: ['fnCd']});
      if(payMethodList.length > 0)
      {
        this.payMethodList = payMethodList
      }
      this.getPartnerList();

      //미수내역
      if(param === "D")
      {
        this.createDFlag = (this.createDFlag === "Y") ? "N" : "Y";
      }
      //지출내역
      else if(param === "W")
      {
        this.createWFlag = (this.createWFlag === "Y") ? "N" : "Y";
      }
      //판매내역
      else if(param === "S")
      {
        this.createSFlag = (this.createSFlag === "Y") ? "N" : "Y";
      }
    },

    async getBasicCode()
    {
      let finCodeListB = await this.$api("/api/finCodeList", {});
      if(finCodeListB.length > 0)
      {
        this.finCodeListB = finCodeListB;
        this.finCode_currB = this.finCodeListB.filter((item) => {
          return item.fCategory === 'Currency';
        })

        this.bankInfo = this.finCodeListB.filter((item) => {
          return (item.fCategory === 'Inst' && item.fCategory2 === 'Bank') ;
        })
      }
      let payMethodListB = await this.$api("/api/baseCategory", {param: ['fnCd']});
      if(payMethodListB.length > 0)
      {
        this.payMethodListB = payMethodListB
      }
      console.log("bankInfo:", this.bankInfo)
      //console.log("payMethodListB:", this.payMethodListB)

      let expInfo1 = await this.$api("/api/expenditureInfo", {param: ["Y"]})

      if(expInfo1.length > 0)
      {
        this.expInfo1 = expInfo1
      }

      this.getPartnerList();
    },

    //거래처리스트
    async getPartnerList() {
      let partnerInfo_tmp = await this.$api("/api/partnerInfo", {});

      //거래중지된 파트너 제외
      let partnerInfo = partnerInfo_tmp.filter((item) => {
        return item.pUseFlag === 'Y'
      })

      if(partnerInfo.length > 0)
      {
        this.partnerInfo = partnerInfo
      }
      console.log("partnerInfo:",this.partnerInfo);
    },

    //지출분류 변경에 따른 항목변경(지출내역)
    changeExpCatCode1(expCatCode1, idx){
      console.log("expCatCode:",expCatCode1, idx)
      //console.log("expInfo1:", this.expInfo1)

      //let expInfo1 = []
      let expInfo1 = this.expInfo1.filter(e => {return e.expCategory === expCatCode1})
      console.log("expInfo1:", expInfo1)


      //expInfo1 = this.expInfo1.filter(e => {return e.expCategory === expCatCode1})
      
      if(expInfo1.length > 0)
      {
        this.expInfo1[idx] = expInfo1
      }
      // else
      // {
      //   this.expInfo1[idx] = ""
      // }
      console.log("expInfo1:", this.expInfo1)
    },


    //결제수단에 따른 결제루트
    changePaymethod(param,pM,idx){
      if(param === 'C' && idx === '')
      {
        let payCodeList = this.finCodeList.filter((item) => {
          return item.fCategory2 == pM
        })
        if(payCodeList.length > 0)
        {
          this.payCodeList = payCodeList
        }
      }
      if(param === 'R' && idx >= 0)
      {
        let payCodeListB = this.finCodeListB.filter((item) => {
          return item.fCategory2 == pM
        })
        if(payCodeListB.length > 0)
        {
          this.payCodeListB[idx] = payCodeListB
        }
        console.log("payCodeListB:", this.payCodeListB)
      }
    },

    //선택된 거래처의 계좌정보
    async getBankInfo(pCode, wFlag){    //wFlag: 작업코드(C/index)
      let partnerInfoByCode = await this.$api("/api/getPartnerInfoByCode", { param: [pCode] });
      console.log("partnerInfoByCode:>>>>>>>>>>>>>>>>>>>>", partnerInfoByCode)
      
      if(partnerInfoByCode.length > 0)
      {
        if(wFlag === "C")
        {
          this.partnerInfoByCode = partnerInfoByCode
          this.bankCode = partnerInfoByCode[0].bankCode
          this.bAccount = partnerInfoByCode[0].bankAccount
          this.accHolder = partnerInfoByCode[0].accHolder
        }
        else if(wFlag != "C")
        {
          this.dWithdrawalInfo[wFlag].bankCode = partnerInfoByCode[0].bankCode
          this.dWithdrawalInfo[wFlag].bankAccount = partnerInfoByCode[0].bankAccount
          this.dWithdrawalInfo[wFlag].accHolder = partnerInfoByCode[0].accHolder
        }
      }
    },

    //통화별 환율호출
    getExRate(param,cCode){
      //console.log("finCodeListB:", this.finCodeListB)
      if(param === "D")
      {
        let dExRate = this.finCodeListB.filter((item) => {
          return item.fCodeNo == cCode
        })
        this.dExRate = (dExRate[0].feeRate != null) ? dExRate[0].feeRate : '1.00'
      }
      else if(param === "W")
      {
        let expExRate = this.finCodeListB.filter((item) => {
          return item.fCodeNo == cCode
        })
        this.expExRate = (expExRate[0].feeRate != null) ? expExRate[0].feeRate : '1.00'

        this.expConvMoney = Math.trunc((this.expAmt*1) * (this.expExRate*1))    //원화환산(소숫점절사)
      }
      //this.dExRate = (dExRate[0].feeRate != null) ? dExRate[0].feeRate : dExRate.feeRate
    },

    //변경이 시도된 순번의 통화환율로 변경
    getExRate1(cCode, idx, param){
      //console.log(cCode, idx)

      let dExRate_tmp = this.finCodeListB.filter((item) => {
        return item.fCodeNo == cCode
      })

      //변경된 순번내역에 맞게 변경
      if(param === "D")
      {
        this.dDepositInfo[idx].dExRate = (dExRate_tmp[0].feeRate != null) ? dExRate_tmp[0].feeRate : '1.00'
      }
      else if(param === "W")
      {
        this.dWithdrawalInfo[idx].dExRate = (dExRate_tmp[0].feeRate != null) ? dExRate_tmp[0].feeRate : '1.00'
      }
    },

    //결제루트별 수수료율호출
    getFeeRate(cCode, dFlag){
      let dFeeRate = this.finCodeList.filter((item) => {
        return item.fCodeNo == cCode
      })

      if(dFlag === "D") //입금내역
      {
        this.dFeeRate = (dFeeRate[0].feeRate != null) ? dFeeRate[0].feeRate : '0'
      }
      else if(dFlag === "W") //출금내역
      {
        this.expFeeRate = (dFeeRate[0].feeRate != null) ? dFeeRate[0].feeRate : '0'
      }
      
    },
    //변경된 내역에 적용되는 수수율 표시
    getFeeRate1(cCode, idx, param){
      //console.log("finCodeListB:", this.finCodeListB)
      
      let dFeeRate_tmp = this.finCodeListB.filter((item) => {
        return item.fCodeNo == cCode
      })
      //console.log("dFeeRate_tmp:", dFeeRate_tmp)

      //입금내역&출금내역
      if(param === "D")
      {
        this.dDepositInfo[idx].dFeeRate = (dFeeRate_tmp[0].feeRate != null) ? dFeeRate_tmp[0].feeRate : '0'
      }
      else if(param === "W")
      {
        this.dWithdrawalInfo[idx].dFeeRate = (dFeeRate_tmp[0].feeRate != null) ? dFeeRate_tmp[0].feeRate : '0'
      }      
    },

    //입출금내역
    async getDepositInfo(dealCode){
      //let dFlag = 'D'
      let dUseFlag = 'Y'

      //입금내역리스트
      let dDepositInfo = await this.$api("/api/proc_readDealHistoryDetail", {param:[
        dealCode, 'D', dUseFlag
      ]})
      if(dDepositInfo.length > 0)
      {
        this.dDepositInfo = dDepositInfo[0];

        let i = 0;
        let sumOfConvMoney = 0;

        while(i < dDepositInfo[0].length)
        {
          this.dDepositInfo[i].dDate = this.getDateFormat(this.dDepositInfo[i].dDate)

          //원화표시이므로 소숫점 이하 절사
          this.dDepositInfo[i].dConvMoney = Math.trunc((this.dDepositInfo[i].dPrice *1) * (this.dDepositInfo[i].dExRate*1))
          
          sumOfConvMoney += this.dDepositInfo[i].dConvMoney
          this.dDepositInfo[i].dCalBalance = (this.dealInfoDetail.dealPrice_tot*1) - sumOfConvMoney*1
          
          if(this.dDepositInfo[i].upd)
          {
            this.dDepositInfo[i].handler = this.dDepositInfo[i].upd
          }
          else
          {
            this.dDepositInfo[i].handler = this.dDepositInfo[i].ins
          }

          i++;
        }
        console.log("dDepositInfo:", this.dDepositInfo)
        
        sumOfConvMoney = this.dDepositInfo.reduce((accumulator, currentValue) => accumulator + (currentValue.dPrice * currentValue.dExRate), 0);
        this.sumOfConvMoney = sumOfConvMoney
        //console.log("Total sum of ConvMoney: ", sumOfConvMoney);
      }

      //출금내역리스트
      let dWithdrawalInfo = await this.$api("/api/proc_readDealHistoryDetail", {param:[
        dealCode, 'W', dUseFlag
      ]})

      if(dWithdrawalInfo.length > 0)
      {
        this.dWithdrawalInfo = dWithdrawalInfo[0];

        let j=0
        let expTotal = 0
        while(j<dWithdrawalInfo[0].length)
        {
          this.dWithdrawalInfo[j].dDate = this.getDateFormat(this.dWithdrawalInfo[j].dDate)
          //누적값 연산
          expTotal += this.dWithdrawalInfo[j].dPrice * this.dWithdrawalInfo[j].dExRate * this.dWithdrawalInfo[j].expQty
          this.expTotal = expTotal
          j++
        }
      }
      console.log("dWithdrawalInfo:", this.dWithdrawalInfo)
    },

    //입금내역등록
    async registDeposit(dCode, dFlag) {

      //let dFlag = 'O'; //미수처리
      if(dFlag === "O")
      {
        if(!this.dDealDetail)
        {
          this.$refs.reg_dDealDetail.focus();
          return this.$swal.fire("","미수내역을 입력하세요.","warning");
        }
        if(!this.dPrice)
        {
          this.$refs.reg_dPrice.focus();
          return this.$swal.fire("","미수액을 입력하세요.","warning");
        }
        if(!this.dCurCd)
        {
          this.$refs.reg_dCurCd.focus();
          return this.$swal.fire("","통화를 선택하세요.","warning");
        }
        if(!this.dExRate)
        {
          this.$refs.reg_dExRate.focus();
          return this.$swal.fire("","환율을 입력하세요.<br>소숫점 두자리까지 입력가능합니다.","warning");
        }
        if(!this.dPayMethod)
        {
          this.$refs.reg_dPayMethod.focus();
          return this.$swal.fire("","결제수단을 선택하세요.","warning");
        }
        if(!this.dPayCode)
        {
          this.$refs.reg_dPayCode.focus();
          return this.$swal.fire("","결제루트를 선택하세요.","warning");
        }
        if(!this.dFeeRate)
        {
          this.$refs.reg_dFeeRate.focus();
          return this.$swal.fire("","수수율을 입력하세요.<br>소숫점 두자리까지 입력가능합니다.","warning");
        }
        if(!this.dDate)
        {
          this.$refs.reg_dDate.focus();
          return this.$swal.fire("","미수일자를 입력하세요.","warning");
        }
      }
      else if(dFlag === "W")      //지출validate
      {
        if(!this.expCatCode)
        {
          this.$refs.reg_expCatCode.focus();
          return this.$swal.fire("","지출분류를 선택하세요.","warning");
        }

        if(!this.expCode)
        {
          this.$refs.reg_expCode.focus();
          return this.$swal.fire("","지출항목을 선택하세요.","warning");
        }

        // if(!this.expName)
        // {
        //   this.$refs.reg_expName.focus();
        //   return this.$swal.fire("","지출항목명을 입력하세요.","warning");
        // }

        if(!this.expPartnerCd)
        {
          //this.$refs.reg_expPartnerCd.focus();
          this.$swal.fire("","기타지출거래처명을 입력하세요.","warning")
          return false
          
        }      
        else if(this.expPartnerCd === "ZETW")
        {
          if(!this.exPartnerName)
          {
            this.$refs.reg_exPartnerName.focus();
            return this.$swal.fire("","기타지출거래처명을 입력하세요.","warning");
          }
          if(!this.exPartnerTel)
          {
            this.$refs.reg_exPartnerTel.focus();
            return this.$swal.fire("","기타지출거래처 연락처를 입력하세요.","warning");
          }
        }
        
        if(!this.expDate)
        {
          this.$refs.reg_expDate.focus();
          return this.$swal.fire("","지출일자를 입력하세요.","warning");
        }

        if(!this.expAmt)
        {
          this.$refs.reg_expAmt.focus();
          return this.$swal.fire("","지출액을 입력하세요.","warning");
        }

        if(!this.expCurCd)
        {
          this.$refs.reg_expCurCd.focus();
          return this.$swal.fire("","결제통화를 선택하세요.","warning");
        }
        if(!this.expExRate)
        {
          this.$refs.reg_expExRate.focus();
          return this.$swal.fire("","환율을 입력하세요.<br>소숫점 두자리까지 입력가능합니다.","warning");
        }

        if(!this.expPayMethod)
        {
          this.$refs.reg_expPayMethod.focus();
          return this.$swal.fire("","지출수단1을 선택하세요.","warning");
        }

        if(!this.expPayCode)
        {
          this.$refs.reg_expPayCode.focus();
          return this.$swal.fire("","지출수단2를 선택하세요.","warning");
        }

        if(!this.expQty)
        {
          this.$refs.reg_expQty.focus();
          return this.$swal.fire("","지출수량(건)을 입력하세요.","warning");
        }
      }
      //입금내역 valid check
      else if(dFlag === "S")
      {
        if(!this.cusName)
        {
          this.$refs.cusName.focus();
          return this.$swal.fire("","고객명을 입력하세요","warning");
        }
        if(!this.cusCnt)
        {
          this.$refs.cusCnt.focus();
          return this.$swal.fire("","인원수를 입력하세요","warning");
        }
        if(!this.dSalePrice)
        {
          this.$refs.dSalePrice.focus();
          return this.$swal.fire("","판매액을 입력하세요","warning");
        }
        if(!this.dSaleFee)
        {
          this.$refs.dSaleFee.focus();
          return this.$swal.fire("","판매수수료를 입력하세요","warning");
        }        
      }


      let insId = this.user.handlerId;
      let dUseFlag = "Y"
      //let reqStatus = 'EM'

      let ins_dealHistory = []

      if(dFlag === "O")         //미수생성등록
      {
        let dFlag = "D"         //미수도 입금의 한종류
        let exPartnerName = ""
        let exPartnerTel = ""
        let outStFlag = "Y"

        ins_dealHistory = await this.$api("/api/proc_createDealHistory", {param: [
          //dealHistory_m
          dCode, this.dPrice, insId,
          //dealHistory_d
          dFlag, this.dCurCd, this.dExRate, this.dPrice, this.dPayMethod, this.dPayCode, this.dFeeRate, this.dDealDetail, this.dPartnerCd, this.dDepositor, this.dDate, this.dDetailRemarks, dUseFlag, this.dealInfoDetail.dealPrice_tot, exPartnerName, exPartnerTel,'','0','', outStFlag, '', '', ''
        ]});
      }
      else if(dFlag === "W")        //지출기록생성
      {
        let dDepositor = ""

        ins_dealHistory = await this.$api("/api/proc_createDealHistory", {param: [
          //dealHistory_m
          dCode, this.expAmt, insId,
          //dealHistory_d
          dFlag, this.expCurCd, this.expExRate, this.expAmt, this.expPayMethod, this.expPayCode, this.expFeeRate, this.expName, this.expPartnerCd, dDepositor, this.expDate, this.expRemarks, dUseFlag, this.dealInfoDetail.dealPrice_tot, this.exPartnerName, this.exPartnerTel, this.expCode,this.expQty, this.expCatCode, '', this.bankCode, this.bAccount, this.accHolder
        ]});
      }

      else if(dFlag === "S")        //판매기록처리
      {
        let tot_dSalePrice = this.dSalePrice - this.dSaleFee

        ins_dealHistory = await this.$api("/api/proc_createDealHistory_sale", {param: [
          //dealHistory_m
          dCode, tot_dSalePrice,
          //dealHistory_s
          this.cusName, this.cusCnt, this.dSalePrice, this.dSaleFee, this.dSaleMemo, dUseFlag, dFlag, insId
        ]});
      }

      console.log("ins_dealHistory:",ins_dealHistory)
      if(ins_dealHistory.affectedRows < 0) {
        this.$swal.fire('','처리도중 에러가 발생했습니다.('+ ins_dealHistory.error.sqlMessage +')', 'error')
      }
      else {
        this.$swal.fire('','내역을 저장하였습니다.', 'success');
        //location.reload(true);
      }
    },

    //입금내역 확정
    async confirmDeposit(dCode, sb)
    {
      let confirm_dealHistory_d = await this.$api("/api/confirm_dealHistory_d", {param: [
        "Y", this.user.handlerId, dCode, sb
      ]})

      if(confirm_dealHistory_d.affectedRows < 0)
      {
        this.$swal.fire('', '확정 중 오류가 발생했습니다(confirm_dealHistory_d)', 'error');
        return false;
      }
      else
      {
        this.$swal.fire('', '당해 내역은 확정되었습니다.<br>수정을 위해서는 유권한자에게 확정취소를 요청하세요', 'success');
        location.reload(true);
      }
    },


    //입출금내역 내용변경
    async changeDeposit(dCode, sb, idx, param)
    {
      let change_dealHistory_d = []
      if(param === "D") //입금내역변경
      {
        let dPartnerCd = (this.dDepositInfo[idx].dPartnerCd == 'not') ? "" : this.dDepositInfo[idx].dPartnerCd;
        let dConvMoney = Math.trunc((this.dDepositInfo[idx].dPrice *1) * (this.dDepositInfo[idx].dExRate*1))
        let expCode = ""
        let etcPaName = ""
        let etcPaTel = ""
        let expQty = 0
        let expCatCode = ""
        let bankCode = ""
        let bankAccount = ""
        let accHolder = ""

        change_dealHistory_d = await this.$api("/api/proc_changeDealhistory", {param: [ param, expCode,
          this.dDepositInfo[idx].dDealDetail,this.dDepositInfo[idx].dPrice,this.dDepositInfo[idx].dCurCd,this.dDepositInfo[idx].dExRate,this.dDepositInfo[idx].dPayMethod,this.dDepositInfo[idx].dPayCode,this.dDepositInfo[idx].dFeeRate,this.dDepositInfo[idx].dDepositor,dPartnerCd,this.dDepositInfo[idx].dDate,this.dDepositInfo[idx].dDetailRemarks,this.user.handlerId,dCode,sb,
          this.dDepositInfo[idx].exDepositNo, this.dDepositInfo[idx].exSubNo, dConvMoney,
          etcPaName, etcPaTel, expQty, expCatCode, bankCode, bankAccount, accHolder
        ]})
        //console.log("change_dealHistory_d:",change_dealHistory_d)
      }
      else if(param === "W")  //출금내역변경
      {
        console.log(dCode, sb, idx, param)
        //let dPartnerCd = (this.dWithdrawalInfo[idx].dPartnerCd == 'not') ? "" : this.dWithdrawalInfo[idx].dPartnerCd;
        //let dConvMoney = Math.trunc((this.dWithdrawalInfo[idx].dPrice *1) * (this.dWithdrawalInfo[idx].dExRate*1))

        let dDepositor = ""
        let exDepositNo = ""
        let exSubNo = 0
        let dConvMoney = 0
        
        change_dealHistory_d = await this.$api("/api/proc_changeDealhistory", {param: [ param, this.dWithdrawalInfo[idx].expCode,
          this.dWithdrawalInfo[idx].dDealDetail,this.dWithdrawalInfo[idx].dPrice,this.dWithdrawalInfo[idx].dCurCd,this.dWithdrawalInfo[idx].dExRate,this.dWithdrawalInfo[idx].dPayMethod,this.dWithdrawalInfo[idx].dPayCode,this.dWithdrawalInfo[idx].dFeeRate,dDepositor,this.dWithdrawalInfo[idx].dPartnerCd,this.dWithdrawalInfo[idx].dDate,this.dWithdrawalInfo[idx].dDetailRemarks,this.user.handlerId,dCode,sb,
          exDepositNo, exSubNo, dConvMoney,
          this.dWithdrawalInfo[idx].etcPaName, this.dWithdrawalInfo[idx].etcPaTel, this.dWithdrawalInfo[idx].expQty, this.dWithdrawalInfo[idx].expCatCode,
          this.dWithdrawalInfo[idx].bankCode, this.dWithdrawalInfo[idx].bankAccount, this.dWithdrawalInfo[idx].accHolder
        ]})
      }

      if(change_dealHistory_d.affectedRows < 0)
      {
        this.$swal.fire('','변경오류('+ change_dealHistory_d.error.sqlMessage +')', 'error')
        return false;
      }
      else
      {
        this.$swal.fire('', '내역이 변경되었습니다.', 'success');
        location.reload(true);
      }
    },

    //미수내역삭제
    async excludeOutSt(val){
      //alert("dSubNo:" + val)
      let dSubNo = val
      let dUseFlag = "N"
      let dFlag = "D"
      let delId = this.user.handlerId

      let exclude_outStFlag = await this.$api("/api/changeOutStFlag", {param: [
        dUseFlag, delId, dSubNo, dFlag
      ]})

      if(exclude_outStFlag.affectedRows > 0)
      {
        this.$swal.fire('', '미수내역이 삭제되었습니다', 'success');
        location.reload(true);
      }
      else
      {
        this.$swal.fire('', '미수삭제 중 오류가 발생했습니다.<br>오류가 반복되는 경우, 관리자에게 문의해 주세요.', 'warninig');
        location.reload(true);
      }
    },


    //입출금내역에서 제외
    async excludeDeposit(dCode, sb, idx, param, expCode)
    {
      //console.log(dCode, sb, idx, param)

      let useFlag = "N"
      let delId = this.user.handlerId
      // let exclude_dealHistory_d = await this.$api("/api/exclude_dealHistory_d", {param: [
      //   "N", this.user.handlerId, dCode, sb
      // ]})
      let exclude_dealHistory_d = []
      if(param === "D")         //입금제외 + 분할제외
      {
        //let expCode = ""
        exclude_dealHistory_d = await this.$api("/api/proc_excludeDealHistory", {param: [param,
          useFlag, delId, dCode, sb,
          this.dDepositInfo[idx].exDepositNo, this.dDepositInfo[idx].exSubNo
        ]})
      }
      else if(param === "W")    //지출제외
      {
        //let exDepositNo = ""
        let exSubNo = 0
        exclude_dealHistory_d = await this.$api("/api/proc_excludeDealHistory", {param: [param,
          useFlag, delId, dCode, sb,
          expCode, exSubNo
        ]})
      }

      if(exclude_dealHistory_d.affectedRows < 0)
      {
        this.$swal.fire('','삭제오류('+ exclude_dealHistory_d.error.sqlMessage +')', 'error')
        return false;
      }
      else
      {
        this.$swal.fire('', '당해 내역은 제외되었습니다.', 'success');
        location.reload(true);
      }
    },

    //입금내역가기
    goToSuppliment_detail(lnk_exCode){
      this.$router.push({path: "/supExSettlement", query: {lnk_exCode: lnk_exCode}})
    }    
  }
}
</script>

<style scoped>
.ag-header-cell-label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ag-theme-balham ::ng-deep .ag-header-cell .ag-header-cell-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

/**테이블 안의 오브젝트 크기세팅 */
.resize-select {
    width: 100%;
    box-sizing: border-box;
    font-size: smaller;
  }

  .resize-input {
    width: 100%;
    box-sizing: border-box;
    font-size: smaller;
  }
</style>