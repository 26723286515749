<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="main p-2">
        <div class="alert alert-success print-hide" role="alert">
          <b class="fs-3">수 지 계 산 서</b>
        </div>

        <div class="text-success text-start fs-6 mb-2 print-hide">
          <b>[ &nbsp; 수지계산서 검색 &nbsp; ]</b>
        </div>
        <table class="table table-bordered border-success align-middle w-100 h-auto print-hide text-center">
          <tbody>
            <tr>
              <th class="table-secondary col-1">
                <select class="form-select form-select-sm" v-model="this.searchDuration">
                  <option value="G" selected>행사월</option>
                  <!-- <option value="E">정산월</option> -->
                </select>
              </th>
              <td class="col-2">
                <div class="input-group">
                  <span class="input-group-text input-group-sm" style="font-size:smaller">선택월</span>
                  <input type="month" class="form-control form-control-sm" ref="searchMonth" v-model="this.searchMonth" style="font-size:smaller">
                </div>
              </td>
              <th class="table-secondary col-1">
                여행지
              </th>
              <td class="col-2">
                <div class="row justify-content-center">
                  <select class="form-select form-select-sm" v-model="this.searchNtCd" style="font-size: smaller; width: 150px" @change="changeNationListforSearch(this.searchNtCd)" ref="searchNtCd">
                    <option value="ntAll">-- 국가전체 --</option>
                    <option :value="c.baseCode" :key="i" v-for="(c,i) in this.tripNtList">{{ c.codeNameKor }}</option>
                  </select>&nbsp;&nbsp;
                  <select class="form-select form-select-sm" v-model="this.searchArCd" style="font-size: smaller; width:150px" ref="searchArCd">
                    <option value="arAll">-- 지역전체 --</option>
                    <option :value="a.baseCode" :key="i" v-for="(a,i) in this.tripArListforSearch">{{ a.codeNameKor }}</option>
                  </select>
                </div>
              </td>
              <th class="table-secondary col-1">
                담당자
              </th>
              <td class="col-2">
                <select class="form-select form-select-sm" v-model="this.chargePerson" style="font-size: smaller;" ref="dealChargeId">
                  <option :value="p.handlerId" :key="i" v-for="(p,i) in this.chargePList">{{ p.handlerNameKor }}</option>
                </select>
              </td>
            </tr>
          </tbody> 
        </table>
        <div style="display: flex; justify-content: space-between;">
          <div class="text-success fs-6 mb-2 print-hide">
            <b><button type="button" class="btn btn-success btn-sm" @click="makeProfitAndLoss(searchDuration, searchMonth, searchNtCd, searchArCd, chargePerson)">수지계산서생성</button></b>
            <span style="font-size:smaller; padding-left: 5px; color: red; vertical-align: bottom;">※ 담당자 기준으로 생성(국가/지역무관)</span>
          </div>
          <div class="text-success text-end fs-6 mb-2 print-hide">
            <b><button type="button" class="btn btn-outline-secondary btn-sm" @click="searchDefault">검색조건초기화</button></b>
            &nbsp;&nbsp;
            <b><button type="button" class="btn btn-outline-danger btn-sm" @click="searchSettleData">생성검색하기</button></b>
          </div>
        </div>

        <!-- 모달영역 -->
        <div v-if="showModal" class="modal">
          <div class="modal-content">
            <span @click="showModal = false" class="close print-hide">&times;</span>
            <Modal_BusinessInvoice :showModal="showModal" :modalContent="this.sel_dealCode" @close="closeModal"/>
          </div>
        </div>

        <!-- 정산정보 조회결과 -->
        <div class="mt-3 print-hide" style="height: auto;">
          <ag-grid-vue3 
            style="width: 100%; height: 800px;" 
            class="ag-theme-balham" 
            :columnDefs="columnDefs"
            :rowData="rowData"
            :gridOptions="gridOptions"
            @grid-ready="onGridReady"
            @selection-changed="onSelectionChanged"
            @cell-clicked="onCellClicked"
            >
          </ag-grid-vue3>
        </div>

      </div>
    </main>
  </div>
</template>

<script>
import Modal_BusinessInvoice from "./Modal_BusinessInvoice.vue";
import { AgGridVue } from 'ag-grid-vue3';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import {genCode} from '../assets/js/genCode'
//import moment from 'moment';

export default {
  components: {
    Modal_BusinessInvoice,
    'ag-grid-vue3': AgGridVue
  },  
  data() {
    return {
      //modal컨트롤
      showModal: false,
      modalContent: "",
      sel_dealCode: "",      //선택된 딜코드

      searchDuration: "G",   //검색기간기준
      searchMonth: "",       //검색월
      searchStart: "",       //검색기준일
      searchEnd: "",
      dealName: "",          //검색용 거래명
      chargePerson: "",      //검색명 담당자명
      searchPartner: "",     //검색용 거래처
      searchNtCd: "",        //검색용 국가코드
      searchArCd: "",        //검색용 지역코드
      resName: "",           //검색용 예약자
      searchCon: "",         //검색용 선택조건(SAG,SAE,STG,STE,OSB)
      dCode: "",             //검색용 행사코드

      partnerInfo: [],       //검색용 거래처정보

      //여정지역데이타
      dptCtList: [],            //출발도시리스트
      tripNtList: [],           //여행국가리스트
      tripArList: [],           //국가하위 지역리스트
      tripArListforSearch: [],  //국가하위 지역리스트(검색용)

      //담당자데이타
      chargePList: [],    //담당자리스트
      settleDataInfo: [], //검색된 정산데이타

      //agGrid
      columnDefs: [
        { headerCheckboxSelection: true, checkboxSelection: true, resizable: true, width: 50 },
        // { 
        //   headerName: '',     field: '',   sortable: true, resizable: false, width: 70,
        //   cellRenderer: (params) => {
        //     if (params.data.reqStatus === 'SE') {
        //       return '<button type="button" class="btn btn-success btn-sm justify-content-center" style="font-size: smaller">정산서</button>';
        //     }
        //     return params.value;
        //   }
        // },
        { headerName: '상품명',     field: 'dealNameKor',   sortable: true, resizable: true },
        { 
          headerName: '상태',       field: 'procName',      sortable: true, resizable: true, width: 100,
          cellStyle: (params) => {
            if(params.data.reqStatus === "SE")
            {
              return { color: 'red', fontWeight:'bold'}
              //return 'red-font-bold'
            }
            else if(params.data.reqStatus === "EE")
            {
              return { color: 'blue', fontWeight: 'bold'}
            }
            return null;
          }
        },
        { headerName: '행사시작',   field: 'startDate',     sortable: true, resizable: true, width: 110 },
        { headerName: '행사종료',   field: 'closeDate',     sortable: true, resizable: true, width: 110 },
        { headerName: '예약자',     field: 'reqName',       sortable: true, resizable: true, width: 120 },
        { headerName: '인원',       field: 'paxCnt',        sortable: true, resizable: true, width: 80 },
        { headerName: '연락처',     field: 'reqTelNo',      sortable: true, resizable: true, width: 120 },
        { headerName: '담당자',     field: 'handlerNameKor',sortable: true, resizable: true, width: 120 },
        { headerName: '정산일',     field: 'seDate',        sortable: true, resizable: true, width: 110 },
        { headerName: '여행지',     field: 'codeNameKor',   sortable: true, resizable: true, width: 100 },

        { headerName: '판매가(KWD)',     field: 'priceTot',      sortable: true, resizable: true, width: 100,
          cellStyle: () => {
            return { color: 'red'}
          }
        },

        { headerName: '지출금액(KWD)',     field: 'dPrice_curKRW_sum',      sortable: true, resizable: true, width: 100,
          cellStyle: () => {
            return { color: 'blue'}
          }
        },
        { headerName: '지출금액(JPY)',     field: 'dPrice_curJPY_sum',      sortable: true, resizable: true, width: 100,
          cellStyle: () => {
            return { color: 'red'}
          }
        },
        { headerName: '지출금액(USD)',     field: 'dPrice_curUSD_sum',      sortable: true, resizable: true, width: 100,
          cellStyle: () => {
            return { color: 'darkgrey'}
          }
        },
        { headerName: '지출금액(TWD)',     field: 'dPrice_curTWD_sum',      sortable: true, resizable: true, width: 100,
          cellStyle: () => {
            return { color: 'green'}
          }
        },

        { headerName: '알선수수료',  field: 'searchersFee',     sortable: true, resizable: true, width: 100 },
        { headerName: '공급가액',    field: 'providePrice',     sortable: true, resizable: true, width: 100 },
        { headerName: '부가가치세',  field: 'vatPrice',     sortable: true, resizable: true, width: 100 },
        { headerName: '미수금',      field: 'accountRecievalbe',     sortable: true, resizable: true, width: 100 },
        { headerName: '상품판매수익', field: 'profit',     sortable: true, resizable: true, width: 100 },
        { headerName: '비고',        field: 'evRemarks',     sortable: true, resizable: true, width: 300 }, 
      ],
      rowData: [],  //정산검색결과 초기값
      gridOptions: {
        rowSelection: "multiple"
        //onGridReady: 'onGridReady'
      },
    };
  },
  created() {
    //this.productId = this.$route.query.productId;
    this.user = this.$store.state.user;
    this.getPartnerList();
    this.getCategoryList();
  }, 
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    if(document.referrer == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
      location.replace("/")
    }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    this.dealChargeId = this.user.handlerId
    ////document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
  },
  methods: {
    //수지계산서 코드생성
    async genCode(param) {
      let chkFlag = "N";
      let rtnCode = "";

      for (let attempts = 0; attempts < 500 && chkFlag === "N"; attempts++) {
        let today = new Date();
        let yy = today.getFullYear().toString().slice(-2);
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let ymd = `${yy}${mm}${dd}`;
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);
        console.log("rtnCode:",rtnCode)

        let chk_genCode = await this.$api("/api/checkDub_profitLossCode", { param: [rtnCode] });

        if (chk_genCode.length > 0) {
          chkFlag = "N";
        } else {
          chkFlag = "Y";
        }
      }
      //console.log("chkFlag:", chkFlag)
      if (chkFlag === "N") 
      {
        this.$swal.fire("","코드의 생성횟수 한계에 도달하였습니다.<br>관리자에게 문의하여 주세요.","warning")
        return false;
      } 
      else 
      {
        return rtnCode
      }
    },

    //수지계산서 생성
    async makeProfitAndLoss(sD, sM, sNt, sAr, sCp){
      if(!sD)
      {
        this.$swal.fire("","분류조건(행사월 또는 정산월)을 선택하세요", "warning")
        return false
      }

      if(!sM)
      {
        this.$swal.fire("","검색월을 입력하세요", "warning")
        return false
      }

      if(!sCp)
      {
        this.$swal.fire("","담당자를 선택하세요", "warning")
        return false
      }

      let mCode = "PNL-"
      let mmCode = await this.genCode(mCode)
      //this.mmCode = mmCode                       //일괄전송구분코드
      console.log("mmCode:", mmCode)


      const width = window.innerWidth * 0.90; // 현재 창의 너비의 90%
      const height = window.innerHeight * 1; // 현재 창의 높이의 100%
      const left = (window.innerWidth - width) / 2; // 가운데 정렬을 위한 왼쪽 위치 계산
      const top = (window.innerHeight - height) / 2; // 가운데 정렬을 위한 위쪽 위치 계산

      const url = `/businessInvoice_profit_loss?sD=${sD}&sM=${sM}&sNt=${sNt}&sAr=${sAr}&sCp=${sCp}&pCode=${mmCode}`;
      window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    },

    openModal(sel_dealCode){
      this.showModal = true

      // let selectedRows = this.gridOptions.api.getSelectedNodes();
      // let sel_rowData = selectedRows.map(node => node.data);
      // let sel_dealCode = sel_rowData[0].dealCode
      this.sel_dealCode = sel_dealCode

      //console.log("sel_dealCode:", this.sel_dealCode);
    },

    //검색조건 초기화
    searchDefault(){
      this.searchMonth = ""
      this.searchNtCd = ""
      this.searchArCd = ""
      this.chargePerson = ""
    },

    closeModal(){
      this.showModal = false;
    },

    //그리드에서 선택된 로우정보취득
    onGridReady(params) { 
      this.gridOptions.api = params.api;    //할당을 해야 함(실서버용)
      const gridApi = params.api;

      //gridApi에 이벤트리스너 등록
      gridApi.addEventListener("selectionChanged:", this.onSelectionChanged)

      // 체크된 노드
      let selectedNodes = gridApi.getSelectedNodes();

      // 선택된 로우의 데이터를 배열로 저장
      let selectedData = selectedNodes.map(node => node.data);

      console.log("selectedData:", selectedData);
    },

    // //그리드에서 선택된 로우정보취득
    // onGridReady(params) {
    //   this.gridOptions.api = params.api;    //할당을 해야 함(실서버용)
    //   const gridApi = params.api;

    //   //gridApi에 이벤트리스너 등록
    //   gridApi.addEventListener("selectionChanged:", this.onSelectionChanged)

    //   // 체크된 노드
    //   let selectedNodes = gridApi.getSelectedNodes();

    //   // 선택된 로우의 데이터를 배열로 저장
    //   let selectedData = selectedNodes.map(node => node.data);

    //   console.log("selectedData:", selectedData);
    // },

    //선택된 그리드값 취득
    onSelectionChanged(){
      let selectedRows = this.gridOptions.api.getSelectedNodes();
      let selectedData = selectedRows.map(node => node.data);
      console.log("selectedData:", selectedData);
    },

    //클릭된 그리드의 dealCode취득
    onCellClicked(event){
      let selectedRows = this.gridOptions.api.getSelectedNodes();
      let sel_rowData = selectedRows.map(node => node.data);
      //console.log("sel_rowData:",sel_rowData)
      
      let sel_dealCode = sel_rowData[0].dealCode
      //console.log("sel_dealCode:", sel_dealCode);

      //정산서 클릭
      if(event.event.target.innerText === "정산서")
      {
        //this.openModal(sel_dealCode)
        this.goToBizInvoice(sel_dealCode);
      }
      else
      {
        //상세페이지로 이동
        this.goToBizInfoDetail(sel_dealCode);
      }
    },
    //상세정보로 이동(새탭에서 열기)
    goToBizInfoDetail(dCode){
      const url = `/businessInfo_detail?dCode=${dCode}`;
      window.open(url, '_blank');      
    },
    
    goToBizInvoice(dCode) {
      const width = window.innerWidth * 0.65; // 현재 창의 너비의 65%
      const height = window.innerHeight * 1; // 현재 창의 높이의 100%
      const left = (window.innerWidth - width) / 2; // 가운데 정렬을 위한 왼쪽 위치 계산
      const top = (window.innerHeight - height) / 2; // 가운데 정렬을 위한 위쪽 위치 계산

      const url = `/businessInvoice?dCode=${dCode}`;
      window.open(url, '_blank', `width=${width},height=${height},left=${left},top=${top}`);
    },


    //memberInfo: 회원정보리스트용 sql
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    // mixins에 있는 공통함수 호출샘플
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    //거래처리스트
    async getPartnerList() {
      let partnerInfo_tmp = await this.$api("/api/partnerInfo", {});
      
      //거래중지된 파트너 제외
      let partnerInfo = partnerInfo_tmp.filter((item) => {
        return item.pUseFlag === 'Y'
      })

      if(partnerInfo.length > 0)
      {
        this.partnerInfo = partnerInfo
      }
      //console.log("partnerInfo:",this.partnerInfo);
    },

    //여행지역정보
    async getCategoryList() 
    {
      //출발도시
      let dptCtList = await this.$api("/api/searchBasecode", {param: ['ctCd']});
      if(dptCtList.length > 0)
      {
        this.dptCtList = dptCtList
      }
      //console.log("dptCtList:", this.dptCtList);

      //여행국가리스트
      let tripNtList = await this.$api("/api/searchBasecode", {param: ['ntCd']});
      if(tripNtList.length > 0)
      {
        this.tripNtList = tripNtList
      }
      
      //여행지역리스트
      let tripArList = await this.$api("/api/searchBasecode", {param: ['arCd']});
      if(tripArList.length > 0)
      {
        this.tripArList_tmp = tripArList
      }

      //담당자정보
      let chargePList = await this.$api("/api/handlerInfo", {})
      if(chargePList.length > 0)
      {
        this.chargePList = chargePList;
      }
      //console.log("chargePList:", this.chargePList)
    },
    changeNationListforSearch(param){
      //this.nationCode = this.productInfo.baseCode;
      this.viewAreaListforSearch(param);
    },   
    viewAreaListforSearch(param1){
      this.tripArListforSearch = this.tripArList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("area:", this.areaList);
    },
    changeNationList(param) {
      //this.nationCode = this.productInfo.baseCode;
      this.viewAreaList(param);
    },
    viewAreaList(param1){
      this.tripArList = this.tripArList_tmp.filter(e => {return e.categoryCode === param1});
      //console.log("area:", this.areaList);
    },

    //정산데이타 검색(프로시저이용)
    async searchSettleData(){
      if(!this.searchMonth)
      {
        this.$swal.fire("", "검색월을 지정하세요", "question")
        return false
      }
      let searchNtCd = (this.searchNtCd === "ntAll") ? "" : this.searchNtCd
      let searchArCd = (this.searchArCd === "arAll") ? "" : this.searchArCd

      let plUseFlag = "Y";
      let plFix = "Y";
      let res_chkFixedLProfitLoss = await this.$api("/api/chk_fixedProfitLoss", {param: [ this.chargePerson, this.searchMonth, plUseFlag, plFix ]});
      if (res_chkFixedLProfitLoss.length > 0) {
        this.$swal.fire("", "이미 확정된 수지계산서가 존재합니다.<br>인쇄나 확정은 기존 확정된 수지계산서의 파기 후 진행해 주세요.", "info");
        // return false;
      }

      //alert(this.searchMonth)
      // let settleDataInfo_tmp = await this.$api("/api/proc_searchSettleData", {param: [
      //   this.searchDuration, this.searchStart, this.searchEnd, this.dealName, this.chargePerson, searchNtCd, searchArCd, this.resName, this.searchCon,
      //   this.searchMonth
      // ]});
      let settleDataInfo_tmp = await this.$api("/api/proc_searchSettleDataForProfitLoss", {param: [
        this.searchDuration, this.searchStart, this.searchEnd, this.dealName, this.chargePerson, searchNtCd, searchArCd, this.resName, this.searchCon,
        this.searchMonth
      ]});
      console.log("settleDataInfo_tmp:",settleDataInfo_tmp)
      
      if(settleDataInfo_tmp.length > 0)
      {
        if (settleDataInfo_tmp.length > 0) {
          this.settleDataInfo = settleDataInfo_tmp[1];
          //this.rowData = this.settleDataInfo;
          //console.log("settleDataInfo:", this.settleDataInfo);

          let pNlStatement = []         //지출금액정보
          let pNlStatement_d = []       //입금금액정보
          let dPrice_cur_sum = []
          let dPrice_exKRW_sum_tmp = []

          let incomeTot = 0                  //입금총액

          for (let i = 0; i < this.settleDataInfo.length; i++) {
            // API 호출로 수지계산서 데이터 가져오기
            let dFlag_w = "W"         //지출정보
            let dFlag_d = "D"         //입금정보
            
            let pNlStatement_tmp = await this.$api("/api/proc_profitAndlossStatment", {
              param: [this.settleDataInfo[i].dealCode, "Y", dFlag_w]
            });
            pNlStatement = pNlStatement_tmp[0]

            let pNlStatement_d_tmp = await this.$api("/api/proc_profitAndlossStatment", {
              param: [this.settleDataInfo[i].dealCode, "Y", dFlag_d]
            });
            pNlStatement_d = pNlStatement_d_tmp[0]
            if(pNlStatement_d.length > 0)
            {
              incomeTot = pNlStatement_d[0].dPrice_exKR_sum*1
            }
            else   //입금액 정보가 없는 경우 대응
            {
              incomeTot = 0
            }
            //pNlStatement_d = pNlStatement_tmp_d[0]

            // 입금정보는 있으므로 지출정보는 이미 추출
            dPrice_cur_sum = pNlStatement
            //.filter(item => item.dFlag === "W")
            .reduce((acc, item) => {
              acc[item.dCurCd] = (acc[item.dCurCd] || 0) + item.dPrice_cur_sum
              return acc
            }, {})        //객체의 초기값 {}

            dPrice_exKRW_sum_tmp = pNlStatement
            .reduce((acc, item) => {
              // acc[item.dCurCd] = (acc[item.dCurCd] || 0) + item.dPrice_exKR_sum
              acc = acc + item.dPrice_exKR_sum
              return acc
            }, 0)           //초기값 0

            // 통화별 계산
            let dPrice_curKRW_sum = (dPrice_cur_sum.KRW == undefined) ? 0 : dPrice_cur_sum.KRW || 0;
            let dPrice_curJPY_sum = (dPrice_cur_sum.JPY == undefined) ? 0 : dPrice_cur_sum.JPY || 0;
            let dPrice_curUSD_sum = (dPrice_cur_sum.USD == undefined) ? 0 : dPrice_cur_sum.USD || 0;
            let dPrice_curTWD_sum = (dPrice_cur_sum.TWD == undefined) ? 0 : dPrice_cur_sum.TWD || 0;

            // 원화환산
            //let dPrice_exKRW_sum  = dPrice_curKRW_sum*1 + dPrice_exKRW_sum_tmp*1
            let dPrice_exKRW_sum  = dPrice_exKRW_sum_tmp*1
            //console.log("dPrice_exKRW_sum:>>>>>>",dPrice_exKRW_sum)

            //알선수수료
            let searchersFee = 0
            searchersFee = this.settleDataInfo[i].dealPrice_tot - dPrice_exKRW_sum

            //공급가액
            let providePrice = 0
            providePrice = (searchersFee > 0) ? (searchersFee/1.1) : searchersFee

            //부가가치세
            let vatPrice = 0
            vatPrice = searchersFee - providePrice

            //미수금
            let accountRecievalbe = 0
            accountRecievalbe = this.settleDataInfo[i].dealPrice_tot - incomeTot
            console.log(this.settleDataInfo[i].dealPrice_tot, "////", incomeTot)

            //상품판매수익
            let profit = 0
            profit = providePrice

            //소숫점 이하는 절사처리 + 자릿수표시
            dPrice_curKRW_sum = this.getCurrencyFormat(Math.trunc(dPrice_curKRW_sum))
            dPrice_curJPY_sum = this.getCurrencyFormat(Math.trunc(dPrice_curJPY_sum))
            dPrice_curUSD_sum = this.getCurrencyFormat(Math.trunc(dPrice_curUSD_sum))
            dPrice_curTWD_sum = this.getCurrencyFormat(Math.trunc(dPrice_curTWD_sum))

            dPrice_exKRW_sum = this.getCurrencyFormat(Math.trunc(dPrice_exKRW_sum))
            searchersFee = this.getCurrencyFormat(Math.trunc(searchersFee))
            providePrice = this.getCurrencyFormat(Math.trunc(providePrice))
            vatPrice = this.getCurrencyFormat(Math.trunc(vatPrice))
            profit = this.getCurrencyFormat(Math.trunc(profit))
            accountRecievalbe = this.getCurrencyFormat(Math.trunc(accountRecievalbe))

            // settleDataInfo 배열 객체에 수지계산 지출합산결과 추가
            this.settleDataInfo[i] = {
              ...this.settleDataInfo[i],
              dPrice_curKRW_sum,
              dPrice_curJPY_sum,
              dPrice_curUSD_sum,
              dPrice_curTWD_sum,
              dPrice_exKRW_sum,
              searchersFee,
              providePrice,
              vatPrice,
              profit,
              accountRecievalbe
            };
          }
          this.rowData = this.settleDataInfo;
        }
        //console.log("rowData:::::::::::::::::::::::", this.rowData)
      }

      if(this.dCode != "")
      {
        this.rowData = this.settleDataInfo.filter(item => {
          return item.dealCode === this.dCode.trim()
        })
      }
    },

  }
}
</script>

<style scoped>
.ag-header-cell-label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.ag-theme-balham ::ng-deep .ag-header-cell .ag-header-cell-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

/** 그리드 컬럼값 컨트롤 */
.red-font-bold {
  color:red;
  font-weight: bold;
}

/**Modal CSS */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;;
  /*z-index: 1;*/
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 5px;
  border: 1px solid #888;
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
  /*box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2);*/
}

/*
.close {
  color: #aaa;
  float: center;
  font-size: 22px;
  font-weight: bold;
  text-align: end;
  cursor: pointer;
}
*/
</style>