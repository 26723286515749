<template>
  <div ref="tableContainer" id="invFrame">

    <div class="text-end print-hide">
      <button type="button" class="btn btn-outline-primary btn-sm m-1" style="font-size: smaller;" @click="exportToExcel()">
        Excel <i class="bi bi-filetype-xlsx"></i></button>
      <button type="button" class="btn btn-outline-success btn-sm m-1" style="font-size: smaller;" @click="printScreen()">
        Print <i class="bi bi-printer"></i></button>
    </div>

    <table class="table table-bordered border-secondary w-100 h-auto text-center">
      <tr>
        <td colspan="7">
          <div>
            <b class="fs-3">&nbsp;&nbsp;정&nbsp;&nbsp;산&nbsp;&nbsp;서&nbsp;&nbsp;</b>
            <div class="mt-1 text-end">출력일자: {{ today }}</div>
            <div class="mt-1 text-end" v-if="dealInfoDetail.dSetDate != null || dealInfoDetail.dSetDate != ''">정산일자: {{ this.getDateFormat(dealInfoDetail.dSetDate) }}</div>
          </div>
        </td>
      </tr>
      
      <tr>
        <td style="background-color:lightgray; vertical-align: middle; width:16%;">행사명</td>
        <td colspan="6" style="vertical-align: middle;">
          <b id="subTitle">{{ this.dealInfoDetail.dealNameKor }}</b>
          <p class="mt-0 mb-0" style="color:gray">
            <small>({{ this.dealCode }})</small>
          </p>
        </td>
      </tr>
      <tr>
        <td style="background-color:lightgray;">행사기간</td>
        <td colspan="6">
          {{ this.dealInfoDetail.evtStartDate }} ~ {{ this.dealInfoDetail.evtCloseDate }}
        </td>
      </tr>
      <tr>
        <td style="background-color:lightgray; width:16%;">예약자</td>
        <td style="width: 14%">
          {{ this.dealInfoDetail.reqName }}
        </td>            
        <td style="background-color:lightgray; width:14%;">인원</td>
        <td style="width: 14%">
          {{ this.dealInfoDetail.paxCnt }}
        </td>
        <td style="background-color:lightgray; width:14%;">담당자</td>
        <td style="width:14%">
          {{ this.dealInfoDetail.handlerNameKor }}
        </td>
        <td style="background-color:lightgray; width:14%;"></td>
      </tr>

      <tr>
        <td colspan="7"></td>
      </tr>
      <tr  class="border-secondary">
        <td class="text-center" id="subTitle" colspan="7"><b> 수 지 계 산 서 </b></td>
      </tr>
      <tr>
        <td style="background-color:lightgray;">① 총판매액</td>  
        <td>
          ₩ {{ this.getCurrencyFormat(this.dealInfoDetail.dealPrice_tot) }}
        </td>
        <td style="background-color:lightgray;">② 총입금액</td>
        <td>
          ₩ {{ this.getCurrencyFormat(this.sumOfConvMoney) }}
        </td>
        <td style="background-color:lightgray;">
          ① - ② 미수금
        </td>
        <td class="text-danger">
          <!-- ₩ {{ this.getCurrencyFormat(this.dealInfoDetail.dealPrice_tot-this.sumOfConvMoney) }} -->
          ₩ {{ this.getCurrencyFormat(this.sumOfConvOutStMoney) }}
        </td>
        <td style="background-color:lightgray;"></td>
      </tr>
      <tr>
        <td style="background-color:lightgray;">③ 총지출액</td>  
        <td>
          ₩ {{ this.getCurrencyFormat(this.expTotal) }}
        </td>
        <td style="background-color:lightgray;">① - ③ 차액</td>
        <td>
          ₩ {{ this.getCurrencyFormat(this.dealInfoDetail.dealPrice_tot - this.expTotal) }}
        </td>
        <td style="background-color:lightgray;">부가가치세</td>
        <td>
          ₩ {{ this.getCurrencyFormat(this.vatPrice) }}
        </td>
        <td style="background-color:lightgray;"></td>
      </tr>
      <tr>
        <td style="background-color:lightgray;">알선수수료</td>  
        <td>
          ₩ {{ this.getCurrencyFormat(Math.trunc((this.dealInfoDetail.dealPrice_tot - this.expTotal)-this.vatPrice)) }}
        </td>
        <td style="background-color:lightgray;">1인당 수수료</td>
        <td>
          ₩ {{ this.getCurrencyFormat(Math.trunc(((this.dealInfoDetail.dealPrice_tot - this.expTotal)-this.vatPrice)/(this.dealInfoDetail.paxCnt*1))) }}
        </td>
        <td style="background-color:lightgray;">행사수수료율</td>
        <td>
          {{ ((((this.dealInfoDetail.dealPrice_tot - this.expTotal)-this.vatPrice)/(this.dealInfoDetail.dealPrice_tot))*100).toFixed(2) }}%
        </td>
        <td style="background-color:lightgray;"></td>          
      </tr>
      <tr>
        <td colspan="7"></td>
      </tr>
      <tr  class="border-secondary">
        <td class="text-start" id="subTitle" colspan="7"><b>판매 / 미수내역</b></td>
      </tr>
      <tr class="bgColor">
        <th>요금구분</th>
        <th>판 매 가</th>
        <th>인원/수량</th>
        <th>합    계</th>
        <th>입 금 액</th>
        <th>입금일자</th>
        <th>미 수 금</th>
      </tr>
      <tr  class="border-secondary" :key="j" v-for="(d,j) in this.dDepositInfo">
        <td>
          {{ d.dDealDetail }}
        </td>

        <!-- 판매가 -->
        <td v-if="d.outStFlag === 'Y'">
          ₩ {{ this.getCurrencyFormat(d.dConvOutStMoney) }}
        </td>
        <td v-else>
          ₩ {{ this.getCurrencyFormat(d.dConvMoney) }}
        </td>

        <td>
          -
        </td>

        <!-- 합계 -->
        <td v-if="d.outStFlag === 'Y'">
          ₩ {{ this.getCurrencyFormat(d.dConvOutStMoney * 1) }}
        </td>
        <td v-else>
          ₩ {{ this.getCurrencyFormat(d.dConvMoney * 1) }}
        </td>

        <!-- 입금액 -->
        <td v-if="d.outStFlag === 'Y'">
          ₩ 0
        </td>
        <td v-else>
          ₩ {{ this.getCurrencyFormat(d.dConvMoney) }}
        </td>

        <!-- 입금일자 / 미수일자 -->
        <td v-if="d.outStFlag === 'Y'">
        </td>
        <td v-else>
          {{ d.dDate }}
        </td>

        <!-- 미수금 -->
        <td class="text-danger" v-if="d.outStFlag === 'Y'">
          ₩ {{ this.getCurrencyFormat(d.dConvOutStMoney) }}
        </td>
        <td class="text-danger" v-else>
          ₩ {{ this.getCurrencyFormat(d.dConvMoney - d.dConvMoney) }}
        </td>

      </tr>
      <tr  class="border-secondary">
        <td  style="background-color:lightgray;">합 계</td>
        <td>
          ₩ {{ this.getCurrencyFormat(this.dealInfoDetail.dealPrice_tot) }}
        </td>
        <td  style="background-color:lightgray;"></td>
        <td>
          ₩ {{ this.getCurrencyFormat(this.sumOfConvMoney + this.sumOfConvOutStMoney) }}
        </td>
        <td>
          ₩ {{ this.getCurrencyFormat(this.sumOfConvMoney) }}
        </td>
        <td  style="background-color:lightgray;"></td>
        <td class="text-danger">
          ₩ {{ this.getCurrencyFormat(this.dealInfoDetail.dealPrice_tot - this.sumOfConvMoney) }}
        </td>
      </tr>
    </table>
    
    <table class="table table-bordered border-secondary w-100 h-auto text-center table-padding-1">
      <thead :key="curName" v-for="(wcList, curName) in this.dWInfoByCurrency">
        <tr>
          <td class="text-start" id="subTitle" colspan="7">&nbsp; <b>지상비 지출내역 ({{ curName }})</b></td>
        </tr>
        <tr>
          <td style="background-color:lightgray; width:16%;">지출거래처</td>
          <td style="width:14%;">지출항목</td>
          <td style="background-color:lightgray; width:14%;">지출금액</td>
          <td style="width:14%;">환율</td>
          <td style="background-color:lightgray; width:14%;">지출일자</td>
          <td style="width:14%;">인원/수량</td>
          <td style="background-color:lightgray; width:14%;">합계</td>
        </tr>
    
        <template v-for="wc in wcList" :key="wc.id">
          <tr class="border-secondary">
            <td>{{ wc.paName }}</td>
            <td>{{ wc.exName }}</td>
            <td>{{ wc.curSimbol }} {{ this.getCurrencyFormat(wc.dPrice) }}</td>
            <td>{{ wc.dExRate }}</td>
            <td>{{ wc.dDate }}</td>
            <td>{{ wc.expQty }}</td>
            <td>₩ {{ this.getCurrencyFormat(Math.trunc((wc.dPrice * wc.dExRate) * wc.expQty)) }}</td>
          </tr>
          <tr v-if="wc.dDetailRemarks" class="border-secondary">
            <td style="background-color: lightgrey;" colspan="1">비고사항</td>
            <td colspan="6" class="text-start">{{ wc.dDetailRemarks }}</td>
          </tr>
        </template>

        <tr class="border-secondary">
          <td style="background-color:gray; color:white" colspan="6" id="subTitle">총합계</td>
          <td colspan="1">
            <b>₩ {{ this.tot_dWPriceByCur.find((e) => e.curName == curName) ? this.getCurrencyFormat(this.tot_dWPriceByCur.find((e) => e.curName == curName).tot_dPrice) : 'N/A' }}</b>
          </td>
        </tr>
      </thead>
    </table>  
  </div>
  <!-- <div class="text-center">
    <button type="button" class="btn btn-secondary btn-lg print-hide" style="font-size: medium;" @click="goBack()"><b>뒤로가기</b></button>
  </div> -->
</template>

<script>
import * as XLSX from "xlsx"
import moment from 'moment';

export default {
  data() {
    return {
      dealCode: "",               //행사코드
      dealInfoDetail: [],         //거래상세정보(마스터)
      dDepositInfo: [],           //입금내역정보
      sumOfConvMoney: 0,          //입금총액(원화환산)
      sumOfConvOutStMoney: 0,     //미수금총액(원화환산)
      expTotal: 0,                //지출총액(원화환산)
      vatPrice: 0,                //부가가치세

      dWInfoByCurrency: [],       //지출된 통화코드
      tot_dWPriceByCur: [],       //지출통화별총액
    }
  },
  created() {
    this.user = this.$store.state.user
    console.log("user:",this.user)

    this.dealCode = this.$route.query.dCode;

    this.getDealInfoDetail(this.dealCode)   //행사정보
    this.getDepositInfo(this.dealCode)      //입출금내역

    const today_tmp = moment();
    let today1 = moment([today_tmp.year(), today_tmp.month(), today_tmp.date()]);
    this.today = today1.format("YYYY-MM-DD")

  },
  watch: {
    sumOfConvMoney: "calVatPrice",
    expTotal: "calVatPrice"
  },
  mounted() {
    ////document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";
    document.getElementById("top").style.display = "none";
  },
  methods: {
    //mixins 공통함수
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value)
    {
      return this.$currencyFormat(value);
    },
    getDeCurrencyFormat(value)
    {
      return this.$deCurrencyFormat(value);
    },
    getDayOfWeekFormat(text)
    {
      return this.$dayOfWeekFormat(text);
    },
    getNumberOfWeekFormat(text)
    {
      return this.$numberOfWeekFormat(text);
    },

    // rgbToHex(rgb){
    //   let rgbVal = rgb.match(/\d+/g)
    //   let hexColor = rgbVal ? '#' + rgbVal.map((x) => parseInt(x).toString(16).padStart(2, '0')).join('') : rgb
    //   return hexColor;
    // },

    //엑셀다운로드
    exportToExcel() {
      // Get the table element
      let tableContainer = this.$refs.tableContainer;
      let tables = tableContainer.querySelectorAll('table');

      // Transfer many tables to one table
      const tempTable = document.createElement('table');
      tables.forEach((table) => {
        const rows = table.querySelectorAll('tr');
        rows.forEach((row) => {
          tempTable.appendChild(row.cloneNode(true));
        });
      });

      // Convert tempTable to workbook
      const workbook = XLSX.utils.table_to_book(tempTable);

      // Get the first worksheet (assuming only one)
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // Function to convert RGB color to Hex
      function rgbToHex(rgb) {
        const rgbValues = rgb.match(/\d+/g);
        const hexColor = rgbValues
          ? '#' + rgbValues.map((x) => parseInt(x).toString(16).padStart(2, '0')).join('')
          : rgb;
        return hexColor.replace('#', ''); // Hex format without #
      }

      // Iterate through the rows of the tempTable
      tempTable.querySelectorAll('tr').forEach((row, rowIndex) => {
        row.querySelectorAll('td').forEach((cell, colIndex) => {
          // Get the background color of the HTML cell
          const bgColor = window.getComputedStyle(cell).backgroundColor;

          // Convert the color to a format that can be used in Excel
          const excelColor = rgbToHex(bgColor);

          // Get the corresponding Excel cell reference
          const cellRef = XLSX.utils.encode_cell({c: colIndex, r: rowIndex});
          const excelCell = worksheet[cellRef];

          if (excelCell) { // Check if Excel cell is defined
            // Set the background color in Excel
            excelCell.s = {
              ...excelCell.s, // Keep existing styles
              fill: {
                fgColor: { rgb: excelColor }
              },
              font: {
                sz: 14 // Font size in points
              },
              border: {
                top: {style: "thin"},
                bottom: {style: "thin"},
                left: {style: "thin"},
                right: {style: "thin"},
              }
            };
          }
        });
      });

      // Apply styles to header (assuming header is in the first row)
      const headerRange = XLSX.utils.decode_range(worksheet['!ref']);
      for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
        const cellRef = XLSX.utils.encode_cell({c: col, r: headerRange.s.r});
        const cell = worksheet[cellRef];

        if (cell) {
          cell.s = {
            font: {
              bold: true,
              sz: 12, // Font size in points
            },
            border: {
              top: {style: "thin"},
              bottom: {style: "thin"},
              left: {style: "thin"},
              right: {style: "thin"},
            }
          };
        }
      }


      // Create Excel file
      const excelFile = XLSX.write(workbook, { bookType: 'xlsx', bookSST: true, type: 'binary' });

      // Create a Blob with the Excel file
      const blob = new Blob([this.s2ab(excelFile)], { type: 'application/octet-stream' });

      // Create a download link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      //a.download = '정산서.xlsx'; // Filename
      a.download = this.dealCode +'.xlsx'; // Filename
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    s2ab(s) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    },

    // 지정영역 화면인쇄
    printScreen()
    {
      //화면전체
      window.print()
    },

    //화면인쇄(정산서 영역)
    printScreen_excel(){
      //인쇄화면에서 버튼들 삭제
      // Get the table container
      const tableContainer = this.$refs.tableContainer;

      // Clone the table container to avoid altering the original content
      const clonedTableContainer = tableContainer.cloneNode(true);

      // Remove the buttons from the cloned table container
      const buttons = clonedTableContainer.querySelectorAll('button');
      buttons.forEach(button => button.remove());

      // Get the HTML content of the cloned table container without buttons
      const printContent = clonedTableContainer.innerHTML;

      // Define styles for the table border and background color
      const printStyles = `
          <style>
            table {
              width: 100%;
              border: 1px solid black;
              border-collapse: collapse;
            }
            th, td {
              border: 1px solid black;
            }
            td[style*="background-color:lightgray"] {
              background-color: lightgray !important;
            }
          </style>`;

      // Print the content with styles
      const printWindow = window.open('', '_blank');
      printWindow.document.open();
      printWindow.document.write('<html><head><title>Print</title>' + printStyles + '</head><body>' + printContent + '</body></html>');
      printWindow.document.close();
      printWindow.print();
    
      //지정영역 인쇄
      // const printContent = this.$refs.tableContainer.innerHTML;
      // const printWindow = window.open('', '_blank');
      // printWindow.document.open();
      // printWindow.document.write('<html><head><title>Print</title></head><body>' + printContent + '</body></html>');
      // printWindow.document.close();
      // printWindow.print();

      //화면전체
      //window.print()
    },

    //거래상세정보(마스터)
    async getDealInfoDetail(dCode) {
      let dealInfoDetail = await this.$api("/api/dealInfoDetail", {param: [dCode]});

      if(dealInfoDetail.length > 0)
      {
        this.dealInfoDetail = dealInfoDetail[0]
        this.dealInfoDetail.evtStartDate = this.getDateFormat(this.dealInfoDetail.evtStartDate)
        this.dealInfoDetail.evtCloseDate = this.getDateFormat(this.dealInfoDetail.evtCloseDate)
        if(this.dealInfoDetail.reqStatus === 'SE' || this.dealInfoDetail.reqStatus === 'SG')
        {
          this.changePermission = 'N'
        }
      }
      console.log("dealInfoDetail:",this.dealInfoDetail);
    },
    //입출금내역
    async getDepositInfo(dealCode){
      //let dFlag = 'D'
      let dUseFlag = 'Y'

      //입금내역리스트
      let dDepositInfo = await this.$api("/api/proc_readDealHistoryDetail", {param:[
        dealCode, 'D', dUseFlag
      ]})
      if(dDepositInfo.length > 0)
      {
        this.dDepositInfo = dDepositInfo[0];

        let i = 0;
        let sumOfConvMoney = 0;
        let sumOfConvOutStMoney = 0             //미수금액합산

        while(i < this.dDepositInfo.length)
        {
          this.dDepositInfo[i].dDate = this.getDateFormat(this.dDepositInfo[i].dDate)

          //입금액에서 미수입금 분리처리
          if(this.dDepositInfo[i].outStFlag === 'Y')
          {
            this.dDepositInfo[i].dConvMoney = 0
            this.dDepositInfo[i].dConvOutStMoney = Math.trunc((this.dDepositInfo[i].dPrice *1) * (this.dDepositInfo[i].dExRate*1))
          }
          else
          {
            //원화표시이므로 소숫점 이하 절사
            this.dDepositInfo[i].dConvMoney = Math.trunc((this.dDepositInfo[i].dPrice *1) * (this.dDepositInfo[i].dExRate*1))
            this.dDepositInfo[i].dConvOutStMoney = 0
          }
          
          sumOfConvMoney += this.dDepositInfo[i].dConvMoney                     //누적입금(미수포함)환산액
          sumOfConvOutStMoney += this.dDepositInfo[i].dConvOutStMoney           //누적미수환산액 

          this.dDepositInfo[i].dCalBalance = (this.dealInfoDetail.dealPrice_tot*1) - (sumOfConvMoney*1 - sumOfConvOutStMoney*1)
          
          if(this.dDepositInfo[i].upd)
          {
            this.dDepositInfo[i].handler = this.dDepositInfo[i].upd
          }
          else
          {
            this.dDepositInfo[i].handler = this.dDepositInfo[i].ins
          }

          i++;
        }
        //console.log("dDepositInfo:", this.dDepositInfo)
        this.sumOfConvMoney = sumOfConvMoney
        this.sumOfConvOutStMoney = sumOfConvOutStMoney
        console.log("sumOfConvMoney:", this.sumOfConvMoney, "sumOfConvOutStMoney:", this.sumOfConvOutStMoney)
        
        // sumOfConvMoney = this.dDepositInfo.reduce((accumulator, currentValue) => accumulator + (currentValue.dPrice * currentValue.dExRate), 0);
        // this.sumOfConvMoney = sumOfConvMoney*1
        //console.log("Total sum of ConvMoney: ", sumOfConvMoney);
      }

      //출금내역리스트
      let dWithdrawalInfo = await this.$api("/api/proc_readDealHistoryDetail", {param:[
        dealCode, 'W', dUseFlag
      ]})

      if(dWithdrawalInfo.length > 0)
      {
        this.dWithdrawalInfo = dWithdrawalInfo[0];

        let j=0
        let expTotal = 0
        while(j<dWithdrawalInfo[0].length)
        {
          this.dWithdrawalInfo[j].dDate = this.getDateFormat(this.dWithdrawalInfo[j].dDate)
          //누적값 연산
          expTotal += this.dWithdrawalInfo[j].dPrice * this.dWithdrawalInfo[j].dExRate * this.dWithdrawalInfo[j].expQty
          this.expTotal = expTotal
          j++
        }
      }
      console.log("dWithdrawalInfo:",dWithdrawalInfo)

      //지출통화별 내역분리
      let dWInfoByCurrency = {}; // 통화별로 정보를 저장할 객체

      this.dWithdrawalInfo.forEach((row) => {
        let colName = row.curName;

        if (!dWInfoByCurrency[colName]) {
          dWInfoByCurrency[colName] = []; // 해당 통화의 배열이 없으면 새로 생성
        }

        dWInfoByCurrency[colName].push(row); // 해당 통화의 배열에 정보 추가

      });

      this.dWInfoByCurrency = dWInfoByCurrency
      console.log("dWInfoByCurrency:", this.dWInfoByCurrency);

      let tot_dWPriceByCur = await this.$api("/api/getTotaldPriceByCur", {param:[dealCode, "W"]})
      if(tot_dWPriceByCur.length > 0)
      {
        this.tot_dWPriceByCur = tot_dWPriceByCur
      }

      console.log("tot_dWPriceByCur:", this.tot_dWPriceByCur)

      // let totalExpenditureByCurrency = {};  //지출통화별 지출액합산

      // for (let currency in dWInfoByCurrency) {
      //   let totalExpenditure = 0;
      //   dWInfoByCurrency[currency].forEach(row => {
      //     totalExpenditure += parseFloat(row.dPrice); // dPrice를 숫자로 변환하고 합계에 추가
      //   });
      //   totalExpenditureByCurrency[currency] = totalExpenditure;
      // }
      // this.dWSumOfPriceByCurrency = totalExpenditureByCurrency



      //지출에 사용된 통화와 건수
      // let obj_colName = {}

      // this.dWithdrawalInfo.forEach((row) => {
      //   let colName = row.curName

      //   if(colName in obj_colName)
      //   {
      //     obj_colName[colName]++
      //   }
      //   else
      //   {
      //     obj_colName[colName] = 1
      //   }
      // })

      // //this.obj_curCd = obj_colName

      // let k=0
      // let arr_dWInfo = []
      // while(k < this.arr_curCd.length)
      // {
      //   arr_dWInfo[k] =  this.dWithdrawalInfo.filter((item) => {
      //     return item.curName == this.arr_curCd[k]
      //   })
      //   console.log("dWInfo:", arr_dWInfo[k])
      //   k++
      // }
    },

    //부가세추적계산(watch용: 총금액기준)
    calVatPrice(){
      let vatPrice_tmp = 0
      //vatPrice_tmp = Math.trunc((this.sumOfConvMoney - this.expTotal) - ((this.sumOfConvMoney - this.expTotal)/1.1))
      vatPrice_tmp = Math.trunc((this.dealInfoDetail.dealPrice_tot - this.expTotal) - ((this.dealInfoDetail.dealPrice_tot - this.expTotal)/1.1))

      if(vatPrice_tmp > 0)
      {
        this.vatPrice = vatPrice_tmp
      }
      else
      {
        this.vatPrice = 0
      }
    },

    goBack(){
      //history.back(-1)
      //this.$router.go(-1);
      this.$router.push({path:'/businessInfo', query: {bFlag: 'B'}})
    }
  }
}
</script>

<style>
.table_sup td {
  padding: 0
}

/* 컬럼 외곽선 스타일을 지정합니다. */
.table-bordered th,
.table-bordered td {
  border: 1px solid #000;
}

/* 테이블의 패딩을 설정합니다. */
.table-padding-1 td,
.table-padding-1 th {
  padding: 0.2rem;
}

/** 폰트기본사이즈 */
#invFrame {
  font-size: 0.7rem;
}

#subTitle {
  font-size: 0.9rem;
}

/**테이블 안의 오브젝트 크기세팅 */
.resize-select {
    width: 100%;
    box-sizing: border-box;
    font-size: small;
  }

.resize-input {
  width: 100%;
  box-sizing: border-box;
  font-size: small;
}

/**스위치버튼 컬러처리 */
.custom-switch .form-check-input:checked {
    background-color: #007bff;
  }
  
  .custom-switch .form-check-input:checked+.form-check-label::before {
    background-color: #fff;
  }

  .bgColor {
    background-color: lightgray;
  }

  /* .sCol {
    width:100px;
  } */

/*인쇄화면에서 엑셀과 프린트 버튼은 보이지 않도록*/
@media print {
  .print-hide {
    display:none;
  }
  .bgColor {
    background-color: lightgray;
  }
}
</style>