<template>
  <div id="sub">

    <!-- 연계상품리스트 -->
    <section id="pack" class="packages">
			<div class="container">
				<div class="packages-content">
					<div class="row gx-4">
            <!-- PC -->
            <div class="sub-title" id="asso_main">
              <div class="assoText pc_display">
                <img src="../assets/img/asso/jin.png" class="assoLogo_jin">
                <span class="text-content assoMainText">&nbsp;와&nbsp;&nbsp;함께하는&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <img src="../assets/img/asso/nd.png" class="assoLogo_nd">
                <span class="text-content assoMainText">&nbsp;추천 일본 버스투어 특가!</span>
              </div>
              <div class="assoText mb_display">
                <img src="../assets/img/asso/jin.png" class="assoLogo_jin">
                <span class="text-content assoMainText">&nbsp;와&nbsp;&nbsp;함께하는&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <br>
                <img src="../assets/img/asso/nd.png" class="assoLogo_nd">
                <span class="text-content assoMainText">&nbsp;추천 일본 버스투어 특가!</span>
              </div>              
            </div>

            <div class="1step mt-5 mb-5">
              
              <!-- 삿포로 -->
              <div class="pCat">
                <div>
                  <div class="grTitle">
                    <i class="bi bi-circle-fill circle"></i> 삿포로
                  </div>
                </div>
                <div class="package-container" style="display: flex; flex-wrap: wrap; gap: 15px;">
                  <div :key="j" v-for="(nd, j) in this.pG_sap">
                    <a class="text-decoration-none link-dark" href="javascript: void(0)"  @click='goToDetail(nd.pdtCode,nd.salesRoute,nd.assoFlag)' style="cursor: pointer">
                      <div class="package-item grImg">
                        <div class="pic">  
                          <div class="packages-hover">
                            <div class="packages-hover-content"></div>
                          </div>
                          <img v-if="nd.imgName != 'noImage'" :src="`/download/${nd.pdtCode}/${nd.imgName}`" class="card-img-top pdImg" alt="..."/>
                          <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top pdImg"/>
                        </div>

                        <div class="package-item-txt">
                          <h3>
                            {{ nd.pdtNameKor }}
                          </h3>
                          <div class="packages-para">
                            <p>
                              <i class="bi bi-bus-front-fill"></i> {{ nd.pdtRemarks }}
                            </p>
                          </div>

                          <hr>

                          <div class="price">

                            <!-- 비로그인과 일반회원 멤버십표시 -->
                            <div>
                              <!-- original가격(default) -->
                              <div v-if="nd.promoStatus === 'Y'" class="my-1">
                                <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(nd.sMinPrice)}}</b>
                                  <small><i class="bi bi-caret-right-fill"></i></small>
                                <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                                <small><span>원~</span></small>
                              </div>

                              <div v-else-if="nd.promoStatus === 'E'" class="my-1">
                                <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                                &nbsp;
                                <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.pPrice)}}</b>
                                <small><span>원~</span></small>
                              </div>
                              
                              <div v-else-if="nd.promoStatus === 'N'" class="my-1">
                                <div class="my-1">
                                  <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                                &nbsp;
                                <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.orgPrice)}}</b>
                                  <small><span>원~</span></small>
                                </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>


              <!-- 후쿠오카 -->
              <div class="pCat">
                <div>
                  <div class="grTitle">
                    <i class="bi bi-circle-fill circle"></i> 후쿠오카
                  </div>
                </div>
                <div class="package-container" style="display: flex; flex-wrap: wrap; gap: 15px;">
                  <div :key="j" v-for="(nd, j) in this.pG_fuk">
                    <a class="text-decoration-none link-dark" href="javascript: void(0)"  @click='goToDetail(nd.pdtCode,nd.salesRoute,nd.assoFlag)' style="cursor: pointer">
                      <div class="package-item grImg">
                        <div class="pic">  
                          <div class="packages-hover">
                            <div class="packages-hover-content"></div>
                          </div>
                          <img v-if="nd.imgName != 'noImage'" :src="`/download/${nd.pdtCode}/${nd.imgName}`" class="card-img-top pdImg" alt="..."/>
                          <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top pdImg"/>
                        </div>

                        <div class="package-item-txt">
                          <h3>
                            {{ nd.pdtNameKor }}
                          </h3>
                          <div class="packages-para">
                            <p>
                              <i class="bi bi-bus-front-fill"></i> {{ nd.pdtRemarks }}
                            </p>
                          </div>

                          <hr>

                          <div class="price">

                            <!-- 비로그인과 일반회원 멤버십표시 -->
                            <div>
                              <!-- original가격(default) -->
                              <div v-if="nd.promoStatus === 'Y'" class="my-1">
                                <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(nd.sMinPrice)}}</b>
                                  <small><i class="bi bi-caret-right-fill"></i></small>
                                <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                                <small><span>원~</span></small>
                              </div>

                              <div v-else-if="nd.promoStatus === 'E'" class="my-1">
                                <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                                &nbsp;
                                <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.pPrice)}}</b>
                                <small><span>원~</span></small>
                              </div>
                              
                              <div v-else-if="nd.promoStatus === 'N'" class="my-1">
                                <div class="my-1">
                                  <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                                &nbsp;
                                <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.orgPrice)}}</b>
                                  <small><span>원~</span></small>
                                </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>


              <!-- 오사카 -->
              <div class="pCat">
                <div>
                  <div class="grTitle">
                    <i class="bi bi-circle-fill circle"></i> 오사카
                  </div>
                </div>
                <div class="package-container" style="display: flex; flex-wrap: wrap; gap: 15px;">
                  <div :key="j" v-for="(nd, j) in this.pG_osa">
                    <a class="text-decoration-none link-dark" href="javascript: void(0)"  @click='goToDetail(nd.pdtCode,nd.salesRoute,nd.assoFlag)' style="cursor: pointer">
                      <div class="package-item grImg">
                        <div class="pic">  
                          <div class="packages-hover">
                            <div class="packages-hover-content"></div>
                          </div>
                          <img v-if="nd.imgName != 'noImage'" :src="`/download/${nd.pdtCode}/${nd.imgName}`" class="card-img-top pdImg" alt="..."/>
                          <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top pdImg"/>
                        </div>

                        <div class="package-item-txt">
                          <h3>
                            {{ nd.pdtNameKor }}
                          </h3>
                          <div class="packages-para">
                            <p>
                              <i class="bi bi-bus-front-fill"></i> {{ nd.pdtRemarks }}
                            </p>
                          </div>

                          <hr>

                          <div class="price">

                            <!-- 비로그인과 일반회원 멤버십표시 -->
                            <div>
                              <!-- original가격(default) -->
                              <div v-if="nd.promoStatus === 'Y'" class="my-1">
                                <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(nd.sMinPrice)}}</b>
                                  <small><i class="bi bi-caret-right-fill"></i></small>
                                <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                                <small><span>원~</span></small>
                              </div>

                              <div v-else-if="nd.promoStatus === 'E'" class="my-1">
                                <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                                &nbsp;
                                <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.pPrice)}}</b>
                                <small><span>원~</span></small>
                              </div>
                              
                              <div v-else-if="nd.promoStatus === 'N'" class="my-1">
                                <div class="my-1">
                                  <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                                &nbsp;
                                <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.orgPrice)}}</b>
                                  <small><span>원~</span></small>
                                </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>


              <!-- 나고야 -->
              <div class="pCat">
                <div>
                  <div class="grTitle">
                    <i class="bi bi-circle-fill circle"></i> 나고야
                  </div>
                </div>
                <div class="package-container" style="display: flex; flex-wrap: wrap; gap: 15px;">
                  <div :key="j" v-for="(nd, j) in this.pG_nag">
                    <a class="text-decoration-none link-dark" href="javascript: void(0)"  @click='goToDetail(nd.pdtCode,nd.salesRoute,nd.assoFlag)' style="cursor: pointer">
                      <div class="package-item grImg">
                        <div class="pic">  
                          <div class="packages-hover">
                            <div class="packages-hover-content"></div>
                          </div>
                          <img v-if="nd.imgName != 'noImage'" :src="`/download/${nd.pdtCode}/${nd.imgName}`" class="card-img-top pdImg" alt="..."/>
                          <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top pdImg"/>
                        </div>

                        <div class="package-item-txt">
                          <h3>
                            {{ nd.pdtNameKor }}
                          </h3>
                          <div class="packages-para">
                            <p>
                              <i class="bi bi-bus-front-fill"></i> {{ nd.pdtRemarks }}
                            </p>
                          </div>

                          <hr>

                          <div class="price">

                            <!-- 비로그인과 일반회원 멤버십표시 -->
                            <div>
                              <!-- original가격(default) -->
                              <div v-if="nd.promoStatus === 'Y'" class="my-1">
                                <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(nd.sMinPrice)}}</b>
                                  <small><i class="bi bi-caret-right-fill"></i></small>
                                <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                                <small><span>원~</span></small>
                              </div>

                              <div v-else-if="nd.promoStatus === 'E'" class="my-1">
                                <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                                &nbsp;
                                <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.pPrice)}}</b>
                                <small><span>원~</span></small>
                              </div>
                              
                              <div v-else-if="nd.promoStatus === 'N'" class="my-1">
                                <div class="my-1">
                                  <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                                &nbsp;
                                <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.orgPrice)}}</b>
                                  <small><span>원~</span></small>
                                </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <!-- 도쿄 -->
              <div class="pCat">
                <div>
                  <div class="grTitle">
                    <i class="bi bi-circle-fill circle"></i> 도쿄
                  </div>
                </div>
                <div class="package-container" style="display: flex; flex-wrap: wrap; gap: 15px;">
                  <div :key="j" v-for="(nd, j) in this.pG_tok">
                    <a class="text-decoration-none link-dark" href="javascript: void(0)"  @click='goToDetail(nd.pdtCode,nd.salesRoute,nd.assoFlag)' style="cursor: pointer">
                      <div class="package-item grImg">
                        <div class="pic">  
                          <div class="packages-hover">
                            <div class="packages-hover-content"></div>
                          </div>
                          <img v-if="nd.imgName != 'noImage'" :src="`/download/${nd.pdtCode}/${nd.imgName}`" class="card-img-top pdImg" alt="..."/>
                          <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top pdImg"/>
                        </div>

                        <div class="package-item-txt">
                          <h3>
                            {{ nd.pdtNameKor }}
                          </h3>
                          <div class="packages-para">
                            <p>
                              <i class="bi bi-bus-front-fill"></i> {{ nd.pdtRemarks }}
                            </p>
                          </div>

                          <hr>

                          <div class="price">

                            <!-- 비로그인과 일반회원 멤버십표시 -->
                            <div>
                              <!-- original가격(default) -->
                              <div v-if="nd.promoStatus === 'Y'" class="my-1">
                                <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(nd.sMinPrice)}}</b>
                                  <small><i class="bi bi-caret-right-fill"></i></small>
                                <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                                <small><span>원~</span></small>
                              </div>

                              <div v-else-if="nd.promoStatus === 'E'" class="my-1">
                                <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                                &nbsp;
                                <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.pPrice)}}</b>
                                <small><span>원~</span></small>
                              </div>
                              
                              <div v-else-if="nd.promoStatus === 'N'" class="my-1">
                                <div class="my-1">
                                  <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                                &nbsp;
                                <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.orgPrice)}}</b>
                                  <small><span>원~</span></small>
                                </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <!-- 기타큐슈 -->
              <div class="pCat">
                <div>
                  <div class="grTitle">
                    <i class="bi bi-circle-fill circle"></i> 기타큐슈
                  </div>
                </div>
                <div class="package-container" style="display: flex; flex-wrap: wrap; gap: 15px;">
                  <div :key="j" v-for="(nd, j) in this.pG_kit">
                    <a class="text-decoration-none link-dark" href="javascript: void(0)"  @click='goToDetail(nd.pdtCode,nd.salesRoute,nd.assoFlag)' style="cursor: pointer">
                      <div class="package-item grImg">
                        <div class="pic">  
                          <div class="packages-hover">
                            <div class="packages-hover-content"></div>
                          </div>
                          <img v-if="nd.imgName != 'noImage'" :src="`/download/${nd.pdtCode}/${nd.imgName}`" class="card-img-top pdImg" alt="..."/>
                          <img v-else :src="`/download/noImage/noImage.jpg`" class="card-img-top pdImg"/>
                        </div>

                        <div class="package-item-txt">
                          <h3>
                            {{ nd.pdtNameKor }}
                          </h3>
                          <div class="packages-para">
                            <p>
                              <i class="bi bi-bus-front-fill"></i> {{ nd.pdtRemarks }}
                            </p>
                          </div>

                          <hr>

                          <div class="price">

                            <!-- 비로그인과 일반회원 멤버십표시 -->
                            <div>
                              <!-- original가격(default) -->
                              <div v-if="nd.promoStatus === 'Y'" class="my-1">
                                <b class="text-muted text-decoration-line-through mx-1">{{ getCurrencyFormat(nd.sMinPrice)}}</b>
                                  <small><i class="bi bi-caret-right-fill"></i></small>
                                <b class="mx-1">{{ getCurrencyFormat(nd.pPrice)}}</b>
                                <small><span>원~</span></small>
                              </div>

                              <div v-else-if="nd.promoStatus === 'E'" class="my-1">
                                <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                                &nbsp;
                                <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.pPrice)}}</b>
                                <small><span>원~</span></small>
                              </div>
                              
                              <div v-else-if="nd.promoStatus === 'N'" class="my-1">
                                <div class="my-1">
                                  <b class="mx-1" style="text-decoration: line-through; text-decoration-color: red; text-decoration-thickness: 2px; font-weight: normal;">{{ getCurrencyFormat(nd.orgPrice)}}</b><i class="bi bi-caret-right-fill text-secondary"></i>
                                &nbsp;
                                <b class="mx-1 fs-4">{{ getCurrencyFormat(nd.orgPrice)}}</b>
                                  <small><span>원~</span></small>
                                </div> 
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

            </div>
					</div>
				</div>
			</div>

      <!-- 배너 -->
      <div class="banner2 d-flex justify-content-center">
        <!-- <a href="/membershipPage" onclick="window.open('/membershipPage', '_blank', 'top=100')"> -->
        <a href="/membershipPage" target="_blank"> 
          <img src="../assets/img/new-test/banner3.jpg" class="img-fluid pc">
          <img src="../assets/img/new-test/banner3-2.jpg" class="img-fluid pad">
          <img src="../assets/img/new-test/banner3-3.jpg" class="img-fluid mobile">
        </a>
      </div>
    </section>
    

    
  </div>
</template>

<script>
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      ntToAr: {},                   //버스투어가능국가지역
      ntToAr_jp: [],                //일본내 버스투어가능지역
      ntToAr_etc: [],               //버스투어가능 국가와 지역
      nt: "",                       //상품카테고리(국가기준)
      ar: "",                       //상품카테고리(지역기준)

      sellerCode: "ND",             //판매자코드

      productListForSales_org: [],  //로컬전체상품
      productListForSales: [],      //판매상품리스트
      etc_more: "N",                //일본외 더보기

      //showLayer: false,           //지역(국가)선택레이어

      natName: "",                      //표시페이지 국가명
      selectableNation: [],             //선택가능 국가

      nationList: {},                   //여행지역리스트
      pdtCatList: {},                   //상품카테고리
      
      productListForSales_recomend: {}, //추천상품리스트
      pPriceY: 0,
      pPriceN: 0,
      pPriceE: 0,
      
      dispPrice: [],        //가격표시방법(mem / promo)

      promoPrice: 0,        //프로모션할인액

      //상품그룹(지역별: 진에어 요구사항)
      pG_sap: [],           //삿포로   (5~19) 
      pG_fuk: [],           //후쿠오카 (20~24)     
      pG_osa: [],           //오사카   (25~29)     
      pG_nag: [],           //나고야   (30~34)     
      pG_tok: [],           //도쿄     (35~39)     
      pG_kit: [],           //기타큐슈 (40~44)     

    };
  },
  created() {
    // 페이지생성과 동시에 리스트정보를 get
		this.nt = (this.$route.query.nt == undefined) ? "%" : this.$route.query.nt;
    this.ar = (this.$route.query.ar == undefined) ? "%" : this.$route.query.ar;
		this.sellerCode = (this.$route.query.sc === undefined) ? "ND" : this.$route.query.sc;
    this.assoFlag = this.$route.query.assoFlag
    //console.log("assoFlag: ", this.assoFlag)

    this.getProductList();
    this.getNationList();
    this.getBustourArea();
  },
  mounted() {
    if(this.assoFlag == "") {
      this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'warning').then(() => {
        location.replace("https://ntabi.co.kr");
      })
    }

    //상세정보와 무관한 내용 비표시처리
    document.getElementById("user").style.display = "none";
    document.getElementById("userM").style.display = "none";
    ////document.getElementById("notice").style.display = "none";

    //페이지뷰 카운팅
    this.countPageView(this.assoFlag, this.assoFlag)
  },
  computed: {
    user() {
      // 리턴정보가 바뀔 때마다 user()안에 넣어준다
      // 로그인전: bId정보없음, 로그인후: bId정보있음
      return this.$store.state.user;
    }
  },  
  methods: {
    async countPageView(pdtCode, fAssoFlag){
      let countPageView = await this.$api("/api/proc_countPageView", {param:[ pdtCode, fAssoFlag ]})
      if(countPageView.affectedRows > 0)
      {
        console.log("Thank you for your visit!")
      }
    },
        
    getImageUrl(flag) {
      try {
        // require를 사용하여 동적으로 이미지 로드
        return require(`@/assets/img/asso/${flag}.png`);
      } catch (error) {
        console.error("이미지를 찾을 수 없습니다:", error);
        return ''; // 이미지가 없을 경우 대체 이미지 경로 또는 빈 문자열 반환
      }
    },

    async getNationList() 
    {
      let nationList = await this.$api("/api/nationList", {});
      this.nationList = nationList;
      
      let natName = nationList.filter((item) => {
        return item.baseCode == this.nt
      })
      this.natName = natName.codeNameKor
      //console.log("nationList:",this.nationList,",natName:",this.natName)
    },

    //선택가능한 버스투어 지역정보
    async getBustourArea() {
      let bustourArea = await this.$api("/api/getBustourArea_asso", {param:[this.assoFlag]})

      //console.log("bustourArea:", bustourArea)

      let ntToAr = {}

      if(bustourArea.length > 0)
      {
        this.bustourArea = bustourArea
        //국가별 지역으로 정리
        bustourArea.forEach(item => {
          if(!ntToAr[item.ntCd])
          {
            ntToAr[item.ntCd] = [];
          }
          ntToAr[item.ntCd].push({
            ntCd: item.ntCd,
            arCd: item.arCd,
            NT: item.NT,
            AR: item.AR
          })
        })
      }
      this.ntToAr = ntToAr
    },


    // mixins에 있는 공통함수 호출샘플
    /** 자릿수 표시 */
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    /** 개행처리 */
    getStringFormat(text) {
      return this.$stringFormat(text);
    },
		/** 일자처리 */
    getDateFormat(date)
    {
      return this.$dateFormat(date);
    },

    async getProductList() 
    {
      let ntCd = this.nt
      //let arCd = this.ar
      let sRoute = "L";
      let sMinPrice = 0;
      let sMargin = 0;

      //console.log("ntCd:",ntCd,"arCd:",arCd);

      //전체상품추출
      let productListForSales_tmp = await this.$api("/api/productListForSales_asso", {param: ['%', sRoute, this.assoFlag]});
      // let productListForSales_tmp = productListForSales_tmptmp.filter((item) => {
      //   return (item.assoFlag == null || item.assoFlag == "" || item.assoFlag == undefined)
      // })
      
      let i = 0;
      while(i < productListForSales_tmp.length)
      {
        if(productListForSales_tmp[i].marginMethod === "P")
        {
          sMargin = productListForSales_tmp[i].minPrice * (productListForSales_tmp[i].basicMargin / 100);
        }
        else
        {
          sMargin = productListForSales_tmp[i].basicMargin;
        }
        sMinPrice = productListForSales_tmp[i].minPrice + sMargin;
        productListForSales_tmp[i].sMinPrice = sMinPrice;
        i++;
      }

      this.productListForSales_org = productListForSales_tmp    //로컬전체상품(org)
      //console.log("productListForSales_org: ", this.productListForSales_org)

			//엔데트립 상품만 추출
			if(this.sellerCode === 'ND')
			{
				let productListForSales_ndaytrip1 = this.productListForSales_org.filter((item) => 
				{
					return item.sellerCode_nd == "Y";
				});

        let productListForSales_ndaytrip = productListForSales_ndaytrip1.sort((a, b) => {
          if (a.ntCd == 'JP' && b.ntCd != 'JP') {
            return -1;
          } else if (a.ntCd != 'JP' && b.ntCd === 'JP') {
            return 1;
          } else if (a.ntCd < b.ntCd) {
            return -1;
          } else if (a.ntCd > b.ntCd) {
            return 1;
          } else {
            return 0;
          }
        });
        
				this.productListForSales = productListForSales_ndaytrip;
			}
      //console.log("productListForSales:>>> ", this.productListForSales) 

      //상품가격계산 최저가기준 표시 + 마진적용
      let marginPrice = 0;
      let promoPrice = 0;

      //상품가격계산 최저가기준 표시 + 마진적용(성인기준)
      // let maxDC_P = 0;        //프리미엄 최대환급율
      // let maxDC_S = 0;        //스탠다드 최대환급율
      // let dcForMem_P = 0      //이외상품 프리미엄 할인율
      // let dcForMem_S = 0      //이외상품 스탠다드 할인율
      // let dcForStd = 0        //프리이엄전용을 스탠다드가 구매시 할인율

      // let price_memP = 0      //프리미엄 환급액
      // let price_memS = 0      //스탠다드 환급액

      // let price_dcForStd = 0  //프리이엄전용을 스탠다드가 구매시 할인액

      // let price_dcForMem_P = 0  //일반상품을 프리미엄이 구매시 할인액
      // let price_dcForMem_S = 0  //일반상품을 스탠다드가 구매시 할인액

      for (let i = 0; i < this.productListForSales.length; i++) 
      {
        if(this.productListForSales[i].marginMethod == "P")
        {
          marginPrice = this.productListForSales[i].minPrice * (this.productListForSales[i].basicMargin / 100);
        }
        else
        {
          marginPrice = this.productListForSales[i].basicMargin;
        }
        //console.log("marginPrice:",marginPrice);

        ////프로모션 상태에 따른 프로모션가격
        if (this.productListForSales[i].promoStatus === "Y") 
        {
          if (this.productListForSales[i].promoMethod === "P") 
          {
            promoPrice = (this.productListForSales[i].minPrice+marginPrice)*(this.productListForSales[i].promoRate / 100);
          }
          else
          {
            promoPrice = this.productListForSales[i].promoRate;
          }
          this.promoPrice = promoPrice.toFixed(0)*1  //프로모션 할인액
        }
        
        //프로모션이 있으면 할인처리
        if (this.productListForSales[i].promoStatus === "Y")
        {
          this.pPriceY = (this.productListForSales[i].minPrice + marginPrice) - promoPrice;
          //console.log(`pPrice${i}: ${this.pPriceY}`);
          this.productListForSales[i].pPrice = this.pPriceY;
        } 
        else if (this.productListForSales[i].promoStatus == "E") 
        {
          this.pPriceE = this.productListForSales[i].minPrice + marginPrice;
          this.productListForSales[i].pPrice = this.pPriceE;
        } 
        else if (this.productListForSales[i].promoStatus == "N") 
        {
          this.pPriceN = this.productListForSales[i].minPrice + marginPrice;
          this.productListForSales[i].pPrice = this.pPriceN;
        }
      }

      this.productListForSales_more = this.productListForSales_org

      //console.log("productListForSales::::::", this.productListForSales)

      this.pG_sap = this.productListForSales.filter((item) => {
        return item.sortNo >= 5 && item.sortNo < 20
      })

      this.pG_fuk = this.productListForSales.filter((item) => {
        return item.sortNo >= 20 && item.sortNo < 25
      })

      this.pG_osa = this.productListForSales.filter((item) => {
        return item.sortNo >= 25 && item.sortNo < 30
      })

      this.pG_nag = this.productListForSales.filter((item) => {
        return item.sortNo >= 30 && item.sortNo < 35
      })

      this.pG_tok = this.productListForSales.filter((item) => {
        return item.sortNo >= 35 && item.sortNo < 40
      })

      this.pG_kit = this.productListForSales.filter((item) => {
        return item.sortNo >= 40 && item.sortNo < 45
      })

      //console.log("pG_sap:>>>", this.pG_sap, "//pG_nag:::::", this.pG_nag)

      //엔데트 전체상품
      if((ntCd == "%" || ntCd == ""))
      {
        this.productListForSales_org.slice(0,6)
        this.productListForSales.slice(0,6)
        // this.productListForSales = ndaytirpList_jp.slice(0,6)
        // this.ndaytirpList_etc = ndaytirpList_etc.slice(0,6)
        return; //이후 조건에 따라 값이 배열값이 덮어써지는 것을 막는다
      }
    },


    showEtcSelArea(param) {
      if(param === 'all') 
      {
        //document.querySelector('.more-etc').style.display = 'block'
        // document.querySelector('.more-etc').classList.add('initial-state');
        this.productListForSales = this.productListForSales_org.slice(0,6)
      }
      else
      {
        //document.querySelector('.more-etc').style.display = "none"
        let productListForSales_tmp = this.productListForSales_org.filter(item => {
          return item.ntCd === param
        })

        this.productListForSales = productListForSales_tmp.slice(0,6)
      }
    },

    goToDetail(productId, salesRoute, assoFlag) {
      this.$router.push({path: '/productDetail_asso', query: {productId: productId, salesRoute:salesRoute, asso:assoFlag}});
    },
    // 숫자콤마표시
    // getCurrencyFormat(value) {
    //   // console.log('value' + value)
    //   return (value+"").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&display=swap');

/* @media screen and (min-width: 1000px) {
  #pack .packages {
    margin-top: -50px;
  }
} */

#pack .sub-title .pc_display {
    display: block;
  }
  #pack .sub-title .mb_display {
    display: none;
  }


@media screen and (max-width: 991px) {
  #pack .sub-title .pc_display {
    display: none;
  }
  #pack .sub-title .mb_display {
    display: block;
    margin-top: -70px;
  }
}


@media screen and (max-width: 800px) {
  #pack .sub-title .pc_display {
    display: none;
  }
  #pack .sub-title .mb_display {
    display: block;
  }

  #pack .sub-title .mb_display .assoLogo_jin {
    width: 180px;
  }
  #pack .sub-title .mb_display .assoLogo_nd {
    width: 150px;
    margin-top: 15px;
  }
  #pack .sub-title .mb_display .assoMainText {
    font-size: 1.5rem;
  }  
}


@media screen and (max-width: 500px) {
  #pack .sub-title .mb_display .assoLogo_jin {
    width: 150px;
  }
  #pack .sub-title .mb_display .assoLogo_nd {
    width: 120px;
    margin-top: 12px;
  }
  #pack .sub-title .mb_display .assoMainText {
    font-size: 1.2rem;
  }  
}

/******************************* 
  # assoText
*******************************/
#asso_main .assoText {
  font-size: 2rem;
  display: flex;
  align-items: flex-end;
  font-weight: 800;
}

#asso_main .assoLogo_jin {
  width: 27%;
  vertical-align: bottom;
}

#asso_main .assoLogo_nd {
  width: 15%;
  vertical-align: bottom;
}

#asso_main .text-content {
  position: relative;
  bottom: -7px;
}

#asso_main div:not(.assoText) {
  display: flex;
  align-items: flex-end;
}


/***********************************
  # Product List
************************************/
.grTitle {
  font-size: 2rem;
  font-weight: 800;
  padding-left: 0px;
  padding-bottom: 3px;
}

.pCat {
  display: inline-block;
  vertical-align: top;
  padding: 8px;
  padding-bottom: 30px;
}

.grImg {
  max-width: 475px;
  width: 100%;
  border-radius: 5%;
}

.pdImg {
  border-radius: 5%;
}

.circle {
  font-size: 1rem; 
  vertical-align: middle;
  position: relative;
  top: -2px;
}


/*--------------------------------------------------------------
	# 연계상품 표시
--------------------------------------------------------------*/
.packages{
  margin: 5rem 0;
}


/* 내용 */
#sub .packages .package-item{
  -webkit-transition: .5s; 
  -moz-transition:.5s; 
  -ms-transition:.5s; 
  -o-transition:.5s;
  transition: .5s;
  height:auto;
}
@media (max-width: 991px){
	#sub .packages .package-item{
		margin-bottom:3.5rem;
	}
  #sub .packages .sub-etc{
    margin-bottom:1.5rem
  }
}

#sub .package-item .pic{
  position:relative;
}
#sub .package-item .card-img-top {
  width: 100%;
  min-height:350px;
  max-height:350px;
  object-fit: cover;
}

#sub .package-item .pic .packages-hover {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;
  opacity:0;
  transition: opacity ease-in-out 0.5s;
}
#sub .package-item:hover .pic .packages-hover {
  opacity:.7;
}
#sub .package-item-txt{
  padding: 0 5px;
}
#sub .package-item h3{
  text-align:start;
  position: relative;
  font-size: 1rem;
  line-height:23px;
  color: #000;
  font-weight:600;
  padding-top: 15px;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
#sub .package-item-txt h3{
  height:60px;
}
#sub .packages-para{
  text-align:start;
  text-transform: capitalize;
	font-size: .75rem;
  color: #777;
}

/* #sub .packages-para-etc{
  text-align:center;
  text-transform: capitalize;
	font-size: .75rem;
  color: #777;
}

#sub .packages-para,.packages-para-etc p{
  margin-bottom:0;
  width: auto;
  height:20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
} */

#sub .packages-content .price {
	float:left;
  font-size: 1.2rem;
  font-weight:600;
  color:#111;
}

/* .fingerPointer {
  cursor: pointer;
} */


/*--------------------------------------------------------------
	# 연계상품 정보
--------------------------------------------------------------*/
/* #sub .sub-etc{
  margin-bottom:2rem
}
#sub .sub-etc img{
  border-radius: 15px;
}
#sub .sub-etc h3{
  text-align: center;
  -webkit-line-clamp: 1;
  height:auto
} */

#sub .sub-etc .price{
  text-align: start;
  padding-left: 1rem;
  float:none;
  font-size:1.3rem
}
#sub .sub-etc .pic .packages-hover{
  border-radius: 15px;
}


/*--------------------------------------------------------------
	#배너
--------------------------------------------------------------*/
#sub .banner2{
  background-color: #49b8f8;
  width: 100%;
  height: auto;
  margin: 1rem 0;
}
#sub .banner2 .pc{
  display: block;
}
#sub .banner2 .pad{
  display: none;
}
#sub .banner2 .mobile{
  display: none;
}
@media (max-width:699px){
  #sub .banner2{
    margin:1rem 0;
  }
  #sub .banner2 .pc{
    display: none;
  }
  #sub .banner2 .pad{
    display: none;
  }
  #sub .banner2 .mobile{
    display: block;
  }
}
@media (min-width:700px) and (max-width:1800px){
  #sub .banner2 .pc{
    display: none;
  }
  #sub .banner2 .pad{
    display: block;
  }
  #sub .banner2 .mobile{
    display: none;
  }
}

</style>

