<template>
  <div :class="{'hidden-banner': isHidden, 'not-hidden-banner': !isHidden}">
    <div class="remote-control banner1">
      <button @click="goLink('product','','L-2023220-1676865317720','L')">
        <img src="../assets/img/remote/remote01.jpg" alt="">
      </button>
    </div>

    <div class="remote-control banner2 mt-2">
      <button @click="goLink('page', 'membershippage', '', '')">
        <img src="../assets/img/remote/mss.jpg" alt="">
      </button>
    </div>

    <div class="remote-control banner3 mt-2">
      <button @click="goLink('page', 'nbus', '', '')">
        <img src="../assets/img/remote/nbremote2.jpg" alt="">
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isHidden: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.updateBannerPosition();
    window.addEventListener('resize', this.updateBannerPosition);
    //const element = document.querySelector('.not-hidden-banner'); 
    
    // let winHeight = window.innerHeight
    // console.log("winHeight:", winHeight)
    // if(winHeight > 780)
    // {
    //   element.style.display = "block"
    // }

    // 성운씨 제안(스크롤과정에서 배너 등장 or 퇴장)
    // document.addEventListener('DOMContentLoaded', function() {
    //   window.addEventListener('scroll', function() {
    //     //winHeight = 699;
    //     let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    //     //console.log("scrollTop:", scrollTop)

    //     if (element) 
    //     {
    //       if (scrollTop < 700) {
    //         element.style.display = 'none';
    //       } 
    //       else
    //       {
    //         element.style.display = 'block';
    //       }
    //     }
    //   });
    // });
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.updateBannerPosition);
  },

  methods: {
    updateBannerPosition() {
      const topBanner = document.querySelector('.topBanner');
      if (topBanner) 
      {
        document.documentElement.style.setProperty('--top-banner-height', `${topBanner.offsetHeight}px`);
      }
    },

    goLink(type, link, pId, sR) {
      if(type === "page") 
      {
        this.$router.push({path: `/${link}`});
      } 
      else if(type === "product") {
        this.$router.push({path: '/detail', query: {productId: pId, salesRoute: sR}});
      }
    },
  }
};
</script>

<style scoped>
.remote-control button {
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 310px;
  height: 105px;
  overflow: hidden;
  padding: 0;
  transition: all 0.5s;
}

button img {
  width: 100%;
  transition: all 0.5s;
}

.banner1 button { background-color: #161302; }
.banner2 button { background-color: #000; }
.banner3 button { background-color: #000; }

.remote-control button:hover {
  transform: translate(-10px);
} 

.hidden-banner {
  display: none;
}

/* .not-hidden-banner {
  position: fixed;
  left: calc(var(--left-width) + 100px) !important;
  top: calc(100px) !important;
  transition: top  0.3s ease;
  z-index: 1000;
  display: none;
} */


/* .hidden-banner {
  top: calc(100vh + 20px);
} */

</style>
