<template>
  <main class="mt-3">
    <div class="p-1 text-center">
      <h2 class="text-center"><u>상품가격등록</u></h2>
      <div class="mb-3 row">
        <!-- 수정불가 -->
        <label class="col-md-3 col-form-label">상품코드</label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="this.productId" readonly>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품구분</label>
        <div class="col-md-9">
          <div class="form-check-inline" :key=i v-for="(baseCode,i) in this.sRoute">
            <div v-if="this.sRoute[i].baseCode === this.salesRoute">
              <input class="form-check-input" type="radio" v-model="productInfo.salesRoute" value=this.sRoute[i].baseCode ref="rbt_salesRoute" checked disabled>
              <label class="form-check-label text-primary" for="flexRadioDefault2">
                {{ this.sRoute[i].codeNameKor }}
              </label>
            </div>
            <div v-else>
              <input class="form-check-input" type="radio" v-model="productInfo.salesRoute" value=this.sRoute[i].baseCode ref="rbt_salesRoute" disabled>
              <label class="form-check-label text-secondary" for="flexRadioDefault2">
                {{ this.sRoute[i].codeNameKor }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품명<font class="text-secondary" style="font-size: small;">(국문)</font></label>
        <div class="col-md-9">
          <input type="text" class="form-control" ref="txt_pdtNameKor" v-model="productInfo.pdtNameKor" disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">상품명<font class="text-secondary" style="font-size: small;">(영문)</font></label>
        <div class="col-md-9">
          <input type="text" class="form-control" v-model="productInfo.pdtNameEng" disabled>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">국가 / 지역</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <input type="text" class="form-control" v-model="this.productInfo.ntName" disabled>
            <input type="text" class="form-control" v-model="this.productInfo.arName" disabled>
          </div>
        </div>
      </div>

      <div class="mt-3 mb-3 row">
        <label class="col-md-3 col-form-label">여행/유효기간</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.tripStart" disabled>
            <span class="input-group-text" id="basic-addon1">종료</span>
            <input type="date" class="form-control" v-model="productInfo.tripEnd" disabled>
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">기본마진</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <input type="number" class="form-control text-end" ref="txt_basicMargin" v-model="productInfo.basicMargin" disabled>
            <select class="form-select form-select-md text-end" aria-label=".form-select-md" v-model="productInfo.marginMethod" disabled>
              <option value="A" id="promoMethod" selected>원</option>
              <option value="P" id="promoMethod">%</option>
            </select>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">판매기간</label>
        <div class="col-md-9">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">개시</span>
            <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.salesStart" disabled>
            <span class="input-group-text" id="basic-addon1">종료</span>
            <input type="date" class="form-control" ref="txt_salesStart" v-model="productInfo.salesEnd" disabled>
          </div>
        </div>
      </div>
      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">특기사항</label>
        <div class="col-md-9">
          <textarea class="form-control" placeholder="200자 이내" ref="txt_nContent" id="floatingTextarea" style="height:80px" 
            v-model="productInfo.pdtRemarks" maxlength="210" disabled></textarea>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-md-3 col-form-label">요금설정<font class="text-danger">*</font>
          <p v-if="productInfo.salesRoute == 'T' || productInfo.salesRoute == 'L' || productInfo.salesRoute == 'F'">
            <font class="text-danger" style="font-size:small;">1:성인 / 2:소아 / 3:인펀트 / 4:기타할인</font></p>
          <p v-else-if ="productInfo.salesRoute === 'H'">
            <font class="text-danger" style="font-size:small;">기준(성인+싱글룸)</font></p>
          <p><font class="text-secondary" style="font-size:small;">(미입력시 0원)</font></p>
        </label>
        <div class="col-md-9">
          <table class="table table-bordered border-success align-middle">
            <tr>
              <td class="text-center text-sm" style="width:50%;">
                기본가격 / 적용기간
              </td>
              <td class="text-primary text-sm" colspan="1">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1" style="font-size: small">개시</span>
                    <input type="month" class="form-control" ref="txt_pdtPriceStart" style="font-size: small" v-model="productPrice.pdtPriceStart">
                    <span class="input-group-text" id="basic-addon1" style="font-size: small">종료</span>
                    <input type="month" class="form-control" ref="txt_pdtPriceEnd" style="font-size: small" v-model="productPrice.pdtPriceEnd">
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div v-if="!productInfo.salesRoute">
                </div>
                <div v-else-if="productInfo.salesRoute === 'T' || productInfo.salesRoute === 'L' || productInfo.salesRoute === 'F' || productInfo.salesRoute === 'E'">
                  <div class="input-group mb-3" :key="i" v-for="i in 4">
                    <span class="input-group-text">{{i}}</span>
                    <input type="text" class="form-control-sm text-end" ref="txt_pdtPriceName[i]" v-model="productPrice.pdtPriceName[i]" style="font-size: small; width:20%" disabled>
                    <input type="number" class="form-control-sm text-end" ref="txt_pdtPrice[i]" v-model="productPrice.pdtPrice[i]" style="font-size: small; width:40%">
                    <!-- <span class="input-group-text">원</span> -->
                  </div>
                </div>
                <div v-else-if="productInfo.salesRoute === 'S' || productInfo.salesRoute === 'H'">
                  <div class="input-group mb-3">
                    <span class="input-group-text">1</span>
                    <input type="test" class="form-control-sm text-end" ref="txt_pdtPriceName1" v-model="productPrice.pdtPriceName1" style="font-size: small;" disabled>
                    <input type="number" class="form-control-sm text-end" ref="txt_pdtPrice1" v-model="productPrice.pdtPrice1" style="font-size: small;">
                    <!-- <span class="input-group-text">원</span> -->
                  </div>
                </div>
              </td>
              <td>
                <div class="col-auto d-grid p-1">
                  <button type="button" class="btn btn-sm btn-success" @click="goToPriceSetPage(this.productId);">상품가격 관리페이지<small>(기본가격 등록우선)</small></button>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="col-sm-3 col-form-label">결제방법<span class="text-danger">*</span></label>
        <div class="col-sm-9">
          <table class="table table-bordered border-success align-middle">
            <tr>
              <td class="text-center text-sm">
                1차 결제 <span class="text-danger">(잔여액은 2차 결제액으로 설정됩니다. 100%로 입력하면 전액결제로 처리됩니다)</span>
              </td>
              <!-- <td class="text-center text-sm">
                2차 결제(잔액)
              </td> -->
            </tr>
            <tr>
              <td>
                <div v-if="!productInfo.salesRoute">
                </div>
                <div class="input-group mt-1">
                  <input type="number" class="form-control text-end" min="0" ref="firstPayment" style="width:70%;" v-model="productInfo.firstPayment">
                  <select class="form-select form-select-md" aria-label=".form-select-md" style="width:30%;" v-model="productInfo.paymentMethod">
                    <option value="P" id="paymentMethod">%</option>
                    <!-- <option value="A" id="paymentMethod">원</option> -->
                  </select>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>


      <div class="mb-3 row">
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-primary" @click="priceInsert(this.productPrice.pdtPriceStart, this.productPrice.pdtPriceEnd);">저장</button>
        </div>
        <div class="col-auto d-grid p-1">
          <button type="button" class="btn btn-lg btn-danger" @click="goToList()">취소</button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
//import {genCode} from '../assets/js/genCode'
export default {
  data() { 
    return {
      productInfo: {},              //상품기본정보
      // firstPayment:0,               //1차결제액
      // paymentMethod:"P",            //결제방법  
      sRoute: {},                   //상품구분
      seasonFlag: "G",              //시즌정보

      // etcOptions: [], //추가옵션정보-new로직

      // etcOptName: [],
      // etcOptPrice: [],
      // reqOptFlag: [], //필수기타옵션플래그
      // vCnt: 0,

      productPrice: {
        // TB_Product_prc
        pdtCode: "",
        prcCode: "",                      //가격코드
        ageGroup: "",

        pdtPrice1: 0,                     //평시
        pdtPriceName1: "",                //권종이명
        pdtPrice: [0,0,0,0,0],
        pdtPriceName: ['','','',''],
        
        useFlag: "",                      //0:Y, 1:N
        addOptionFlag: "",                //추가옵션유무Y0/N1
        etcOptionFlag: "",                //기타옵션유무Y/N
        insDate: "",
        insId: "",
        priceClass: "",
        pdtPriceStart: "",                //금액적용기간
        pdtPriceEnd: ""
      },
      seasonG: "Y",                       //시즌관리폐지로 Y고정
      //addOption: {},                    //추가옵션
      etcOption: {},                      //기타옵션
      dInterval: 0,                       //가격등록기간
      startDay: "",                       //시작일
      lastDay: "",                        //마지막날짜

      pdtPriceInfo: []                    //상품가격이 세팅된 경우-해당정보
    };
  },
    // 로그인된 사용자만 접근가능페이지
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  // 생성 시, 상품카테고리 가져온다
  created() {
    this.productId = this.$route.query.productId;
    this.salesRoute = this.$route.query.salesRoute;

    this.getProductInfo();
    this.getSalesRoute();
    //this.checkPriceSet();
    //this.getSeasonInfo();
  },
  mounted() {
    console.log("handler:",this.user.handlerId);
    // 로그인한 사람이 아니라면 메인화면으로
    if(this.user.handlerGrade > 9) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '',
        text: '로그인 권한이 없습니다.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }
    //this.getSalesRoute();
    //document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    getDayOfWeekFormat(text) {
      return this.$dayOfWeekFormat(text);
    },
    getNumberOfWeekFormat(text) {
      return this.$numberOfWeekFormat(text);
    },
    async getNationList() {
      let nationList_via = await this.$api("/api/nationList", {});
      this.nationList_via = nationList_via;
      //console.log("nationList_via",nationList_via);
    },
    async getSalesRoute() {
      this.sRoute = await this.$api("/api/salesRoute", {});
      //console.log("sRoute",this.sRoute);
    },
    async getProductInfo() {
      let productInfo = await this.$api("/api/pdtInfo", {param: [this.productId]});
      this.productInfo = productInfo[0]
      this.productInfo.tripStart = this.getDateFormat(this.productInfo.tripStart);
      this.productInfo.tripEnd = this.getDateFormat(this.productInfo.tripEnd);
      this.productInfo.salesStart = this.getDateFormat(this.productInfo.salesStart);
      this.productInfo.salesEnd = this.getDateFormat(this.productInfo.salesEnd);
      console.log("productInfo",this.productInfo);
    },

    goToList() {
      if(this.pdtFlag === 'MIX') {
        this.$router.push({path: '/salesMix'});
      }
      else {
        this.$router.push({path: '/sales'});
      }
    },

    async priceInsert(sDate, eDate)
    {
      let timeStamp = "";
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm = today.getMonth()+1;
      let dd = today.getDate();
      let ymd = `${yyyy}${mm}${dd}`
      //console.log(`ymd: ${ymd}`);
      timeStamp = today.getTime(); // 상품코드에 들어갈 타임스탬프
      let catCode = "P"+this.productInfo.salesRoute;

      if(!this.productPrice.pdtPriceStart) 
      {
        return this.$swal.fire("","가격등록 시작일을 입력하세요","warning");
      }
      if(!this.productPrice.pdtPriceEnd) 
      {
        return this.$swal.fire("","가격등록 종료일을 입력하세요","warning");
      }

      // 가격입력 종료일은 선택월의 말일로 자동설정
      let lastDay_tmp = new Date(eDate);
      let year = lastDay_tmp.getFullYear();
      let month = lastDay_tmp.getMonth()+1;
      let lastDay = new Date(year,month,0);
      this.lastDay = this.getDateFormat(lastDay);

      let diffDate = sDate.replace(/-/g, "") - eDate.replace(/-/g,"")
      if(diffDate > 0 )
      {
        return this.$swal.fire('', '시작일은 종료일보다 이전이어야 합니다.', 'error')
      }

      if(!this.productInfo.firstPayment || this.productInfo.firstPayment < 0)
      {
        this.$refs.firstPayment.focus()
        this.$swal.fire("","1차 결제액을 체크해주세요", "warninig")
        return false
      }

      //비용등록기간
      let dpDate = new Date(this.getDateFormat(sDate));
      let rtDate = lastDay;
      let dateDiff = dpDate.getTime() - rtDate.getTime();        
      this.dInterval = Math.abs(dateDiff / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일

      this.productPrice.pdtCode = this.productId;
      this.productPrice.prcCode = catCode+"-"+ymd+"-"+timeStamp;
      this.productPrice.insDate = `${yyyy}-${mm}-${dd}`;
      this.productPrice.insId = this.user.handlerId;
      this.startDay = sDate+'-01'

      // //기타옵션(선택옵션) 유무
      // if(this.etcOptions.length > 0)
      // {
      //   this.productPrice.etcOptionFlag = "Y"
      // }
      // else {
      //   this.productPrice.etcOptionFlag = "N"
      // }
      this.productPrice.priceClass = this.salesRoute;

      //1차결제금액 저장
      let ins_firstPayInfo = await this.$api("/api/ins_firstPayInfo", {param: [
        this.productInfo.firstPayment, "P", this.productId
      ]})
      if(ins_firstPayInfo.affectedRows < 0)
      {
        this.$swal.fire("","결제방법 저장 중 에러가 발생했습니다. 재시도해주세요", "warning")
        return false;
      }

      // 등록전 확인
      this.$swal.fire({
        title: "",
        text: '가격등록 중, 중복된 기간이 있는 경우 신규등록내용으로 변경됩니다.',
        showCancelButton: true,
        confirmButtonText: '등록',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          let funcType = "";
          let sFlag = "";
          let sStat = "";

          //기존 세팅값 정리(중복된 월의 세팅값 무효처리)
          let nullifyDubDate = await this.$api("/api/nullifyDubDate", {param: [
            this.productId, sDate+'-01', eDate+'-01'
          ]});
          
          if(nullifyDubDate.affectedRows > 0)
          {
            this.$swal.fire('','중복된 날짜가 있어 기존 상품가격을 무효처리한 후 새로운 상품가격으로 변경합니다', 'info')
          }


          if(['T', 'L', 'F', 'E'].includes(this.productInfo.salesRoute))
          {
            funcType = "M";     //멀티가격

            //시즌구분폐지
            sFlag = "G"
            sStat = "0"
            await this.insertseasonPrice(sFlag, sStat, funcType);            
          }
          else if(['H', 'S'].includes(this.productInfo.salesRoute))
          {
            funcType = "S";     //싱글가격

            //시즌제 폐지
            sFlag = "G"
            sStat = "0"
            await this.insertseasonPrice(sFlag, sStat, funcType);
          }

          //기타옵션(선택옵션)처리
          if(this.etcOptions.length > 0)
          {
            await this.InsertEtcOption();
          }
          //등록 후 라우터를 통해 상품관리리스트로
          // this.$router.push({path: '/sales'});
          //입력완료
          //this.$swal.fire('','지정된 기간의 기본가격등록이 완료되었습니다', 'success')
        }
      })

    },

    async insertseasonPrice(sFlag, sStat, funcType) {         //시즌속성, 적용상태
      //console.log(sFlag, sStat, funcType)
      //let seasonFlag = sFlag;                               //시즌속성
      let spUseFlag = sStat;                                  //시즌가격 이용여부(0:Y 1:N)
      let sfuncType = funcType;                               //M
      
      let resIns_product_prc = [];

      //상세가격용 prcCode
      let timeStamp = "";
      let today = new Date();
      let yyyy = today.getFullYear();
      let mm = today.getMonth()+1;
      let dd = today.getDate();
      let ymd = `${yyyy}${mm}${dd}`
      timeStamp = today.getTime(); //가격코드에 들어갈 타임스탬프
      
      let prcCode = "P"+this.productId.charAt(0)+"-"+ymd+timeStamp; //기본가격과는 다른 가격을  고유코드

      let priceClass = this.productInfo.salesRoute

      let arr_p = [];
      if(this.productInfo.salesRoute === "S") 
      {
        arr_p = [this.productPrice.pdtPrice1];
      }
      else 
      {
        arr_p = [this.productPrice.pdtPrice[1],this.productPrice.pdtPrice[2],this.productPrice.pdtPrice[3],this.productPrice.pdtPrice[4]];
      }

      //Multi Price
      if(sfuncType === "M") 
      {
        if(sFlag === "G") 
        {
          resIns_product_prc = await this.$api("/api/proc_setPdtPrice", {param: [
            //this.pdtCode, this.optCode, i, this.optPrice[i], spUseFlag, this.insId, this.getDateFormat(this.optPriceStart), this.lastDay
            sfuncType,
            this.productPrice.pdtCode, prcCode, arr_p[0], arr_p[1], arr_p[2], arr_p[3], 
            spUseFlag, priceClass, this.productPrice.insId, this.startDay, this.lastDay
          ]});
              console.log("resIns_product_prc:", resIns_product_prc);
              // 인서트오류처리
            if (resIns_product_prc.error) 
            {
              this.$swal.fire(
                "",
                "상품가격생성 중 오류발생: " + resIns_product_prc.error.message,
                "error"
              );
              // 에러 로깅 (옵션)
              console.error("resIns_product_prc 호출 에러:", resIns_product_prc.error);
              return false;
            }
            else
            {
              this.$swal.fire('','지정된 기간의 기본가격등록이 완료되었습니다', 'success')
              location.reload(true)
            }
        }
      }
      //Single Price
      else if(sfuncType === "S") {
        if(sFlag === "G") 
        {
          resIns_product_prc = await this.$api("/api/proc_setPdtPrice", {param: [
              sfuncType,
              this.productPrice.pdtCode, prcCode, arr_p[0], '0', '0', '0', 
              spUseFlag, priceClass, this.productPrice.insId, this.startDay, this.lastDay
            ]});

            // 인서트오류처리
            if (resIns_product_prc.error) 
            {
              this.$swal.fire(
                "",
                "상품 가격생성 중 오류발생: " + resIns_product_prc.error.message,
                "error"
              );
              // 에러 로깅 (옵션)
              console.error("resIns_product_prc 호출 에러:", resIns_product_prc.error);
              return false;
            }
            else
            {
              this.$swal.fire('','지정된 기간의 기본가격등록이 완료되었습니다', 'success')
              location.reload(true)
            }
          }
      }
    },
    async InsertEtcOption()
    {
      for(let j=0; j < this.etcOptions.length; j++)
      {
        let resIns_etcOption = await this.$api("/api/etcOptInsert", {param: [
          this.productPrice.pdtCode, this.etcOptions[j].etcOptName, this.etcOptions[j].etcOptPrice, 'Y', this.user.handlerId, this.etcOptions[j].reqOptFlag, 
          this.etcOptions[j].etcChild, this.etcOptions[j].etcInf, this.etcOptions[j].etcDis,
          this.etcOptions[j].etcOptCode
        ]});
          //console.log(resIns_etcOption);
          // 인서트오류처리
        if(Object.keys(resIns_etcOption)[0] === 'error') {
          alert("기타옵션 저장오류");
        }
      }
      let res_UpdPdtEtcOptFlag = await this.$api("/api/changePdtEtcOptFlag", {param: [this.productId]});
      if(res_UpdPdtEtcOptFlag.affectedRows < 0 || res_UpdPdtEtcOptFlag.affectedRows === undefined){
        this.$swal.fire('','상품리스트에서 기타옵션 적용표시(Y)확인필요/n미표시이면 관리자에게 문의하세요', 'warning')
      }
    },
    //가격관리페이지로 이동
    goToPriceSetPage(productId) {
      this.$router.push({path: '/productPriceSet', query: {productId: productId, sRoute: this.salesRoute}});
    }
  }
}
</script>