export default {
  bind(el, binding) {
    let touchStartX = 0;
    let touchEndX = 0;
    const threshold = 10; // 최소 스와이프 거리

    el.addEventListener('touchstart', (event) => {
      touchStartX = event.changedTouches[0].screenX;
    });

    el.addEventListener('touchend', (event) => {
      touchEndX = event.changedTouches[0].screenX;
      handleSwipe();
    });

    function handleSwipe() {
      const distance = touchEndX - touchStartX;
      if (Math.abs(distance) > threshold) {
        if (distance < 0) {
          binding.value.left(); // 왼쪽으로 스와이프
        } else {
          binding.value.right(); // 오른쪽으로 스와이프
        }
      }
    }
  },
};
  