<template>
  <div>
    <!----------------- Main contents Area --------------------->
    <!-- mt-3: margin top-3 -->
    <main class="mt-3">
      <div class="p-1">
        <div class="top-container d-flex align-items-center justify-content-between">
          <div class="flex-item input-group" style="width:20%;">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode" v-model="i" @change="changeCondition(i)">
              <option :value="noVal" disabled>상품조회</option>
              <option value="all" >전체상품</option>
              <option value="T" >여행상품(T)</option>
              <option value="L" >현지투어(L)</option>
              <option value="F" >선박상품(F)</option>
              <option value="E" >기타상품(E)</option>
              <option value="S" >단일가상품(S)</option>
              <option value="Y" >판매중</option>
              <option value="N" >판매중지</option>
              <option value="g1" >그룹1</option>
              <option value="g2" >그룹2</option>
              <option value="g3" >그룹3</option>
              <!-- <option value="D" >리턴지정</option>
              <option value="F" >리턴자유</option> -->
            </select>
            <button type="button" class="btn btn-outline-danger btn-sm" @click="saveSortNo();">
              <span style="font-size:small">게시순서설정</span>
            </button>
          </div>

          <div class="flex-item" style="width:10%;">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="sel_procCode" v-model="j" @change="showAssoPdt(j)">
              <option :value="noVal" disabled>연계상품조회</option>
              <option value="assoAll" >연계전체</option>
              <option :value="ac.assoCode" :key=i v-for="(ac,i) in assCodeList">{{ac.assoName}} ({{ ac.assoCode }})</option>
            </select>
          </div>

          <div class="flex-item" style="width:10%;">
            <select class="form-select form-select-sm" aria-label=".form-select-sm" ref="pdtManager" v-model="m" @change="showPdtMan(m)">
              <option :value="noVal" disabled>담당매니저</option>
              <option value="manAll" >매니저전체</option>
              <option :value="pm.handlerId" :key=i v-for="(pm,i) in pdtManList">{{pm.handlerNameKor}}</option>
            </select>
          </div>

          <div class="input-group flex-item" style="width: 20%;">
            <input type="text" class="form-control" style="font-size:smaller;" v-model="sKeyword" placeholder="상품명 (일부가능)">
            <button type="button" class="btn btn-sm btn-dark" @click="getSalesList(sKeyword, 'sk');">상품검색</button>
          </div>

          <div class="flex-item">
            <button type="button" class="btn btn-secondary" @click="goToInsert"><strong>단일상품등록</strong>&nbsp;(기본사항)</button>
          </div>
        </div>


        <table class="table table-bordered table-striped fs-6 w-100">
          <thead class="small">
            <tr class="text-center">
              <th>판매처</th>
              <th>상품코드</th>
              <th>구분</th>
              <th>속성</th>
              <th>출발</th>
              <th>상품명</th>
              <th>연걔코드</th>
              <th>멤버</th>
              <th>국가</th>
              <th>교통</th>
              <th>1차</th>
              <th>
                <span>가격</span>
                <br>
                <span class="text-primary">(할인)</span>
              </th>
              <th>
                상태
                <br>
                <span class="text-danger">적용</span>
              </th>
              <th>판매기간</th>
              <th>상세설정</th>
              <th>기타작업</th>
              <th>비고사항</th>
            </tr>
          </thead>
          <tbody class="small">
            <tr :key="i" v-for="(product, i) in productMaList">
              <td>
                <div class="text-success" v-if="product.sellerCode_nt === 'Y' || product.sellerCode_nt == undefined">
                  NT
                </div>
                <div class="text-primary" v-if="product.sellerCode_nd === 'Y' || product.sellerCode_nd == undefined">
                  ND
                </div>
                <div>
                  <input type="number" v-model="product.sortNo" class="form-sm-control text-danger" style="width:40px" min="0" max="999" maxlength="4">
                </div>
              </td>

              <td class="text-primary "><small>{{ product.pCode }}</small></td>
              <td>{{ product.salesRoute }}</td>
              <!-- <td v-if="product.salesRoute == 'T'" class="text-primary">여행상품</td>
              <td v-else-if="product.salesRoute == 'L'" class="text-danger">현지투어</td>
              <td v-else>{{product.salesRoute}}</td> -->
              
              <td  v-if="product.tripProp === 'D'" class="text-info"><i><b>{{ product.tripProp }}</b></i>
                <p>{{product.stayCnt}}</p>
              </td>
              <td v-else-if="product.tripProp === 'F'" class="text-warning"><i><b>{{ product.tripProp }}</b></i></td>
              <td v-else></td>

              <td>{{ product.dptCtName }}</td>
              <td>{{ product.pdtNameKor }}</td>
              <td class="text-center">{{ product.assoFlag }}</td>

              <td>
                <div style="color:goldenrod">{{ product.memLevel_P }}</div>
                <div class="mt-4" style="color:slategray">{{ product.memLevel_S }}</div>
              </td>
              
              <td>{{ product.ntCd }}</td>
              <td v-if="product.transFlag === '0'" class="text-danger">Y</td>
              <td v-else></td>
              <td style="font-size:smaller; text-align:center;">
                {{ product.firstPayment }}
                <span v-if="product.paymentMethod === 'P'">%</span>
                <span v-else-if="product.paymentMethod === 'A'">원</span>
              </td>
              <td>
                <!-- <div v-if="product.pdtPrice != undefined">
                  {{ getCurrencyFormat(product.pdtPrice) }}
                </div> -->
                <div>
                  <small>
                    {{ getCurrencyFormat(product.maxPrice)}} ~ {{ getCurrencyFormat(product.minPrice)}}
                  </small>
                </div>
                <br>
                <div :key=i v-for="(seqPromo,i) in pdtPromoInfo">
                  <div v-if="pdtPromoInfo[i].pdtCode === product.pCode">
                    <div class="text-primary" v-if = "pdtPromoInfo[i].promoMethod === 'P'">
                      ({{getCurrencyFormat(this.pdtPromoInfo[i].promoRate)}}%)
                    </div>
                    <div class="text-primary" v-else-if = "pdtPromoInfo[i].promoMethod === 'A'">
                      ({{getCurrencyFormat(this.pdtPromoInfo[i].promoRate)}}원)
                    </div>
                  </div>
                </div>
              </td>
              <td> 
                <small>{{ product.saleStatus }}</small>
                <br><br>
                <!-- 판매기간과 설정이 모두 만족해야 판매중 -->                
                <span class="text-danger">{{ product.curStatus }}</span>
              </td>
              <td>
                <small>
                  {{ getDateFormat(product.salesStart) }} ~ {{ getDateFormat(product.salesEnd) }}
                </small>
              </td>
              <td>
                <flag v-if="product.transFlag == '0'">
                  <button type="button" class="btn btn-primary btn-sm me-1" disabled>
                  <span style="font-size:small">교통</span>
                  </button>
                </flag>
                <flag v-else>
                  <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="goToPdtTrans(product.pCode, product.salesRoute);" v-if="product.salesRoute === 'T' || product.salesRoute === 'L' || product.salesRoute === 'F'">
                  <span style="font-size:small">교통</span>
                  </button>
                </flag>

                <button type="button" class="btn btn-outline-warning btn-sm me-1" @click="goToPrcTrans(product.pCode, product.salesRoute);">
                  <span style="font-size:small">가격</span>
                </button>
                
                <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="goToImgInsert(product.pCode);">
                  <span style="font-size:small">사진</span>
                </button>
                
                <button type="button" class="btn btn-outline-success btn-sm me-1" @click="changeStatus(product.saleStatus, product.pCode)">
                  <span style="font-size:small">상태</span>
                </button>
                <button type="button" class="btn btn-outline-danger btn-sm me-1" @click="goToInfo(product.pCode, product.salesRoute, product.ntCd);">
                  <span style="font-size:small">수정</span>
                </button>
                <!-- <button type="button" class="btn btn-outline-success btn-sm me-1" @click="goToPrcOption(product.pCode, product.salesRoute);" v-if="product.salesRoute === 'F'">
                  <span style="font-size:small">옵션</span>
                </button> -->
                <!-- 선박 외 상품에도 적용(2024-04-25) -->
                <button type="button" class="btn btn-outline-success btn-sm me-1" @click="goToPrcOption(product.pCode, product.salesRoute);">
                  <span style="font-size:small">옵션</span>
                </button>
              </td>
              <td>
                <button type="button" class="btn btn-outline-danger btn-sm me-1" @click="previewProduct(product.pCode)">
                  <span style="font-size:small">보기</span>
                </button>
                <button type="button" class="btn btn-success btn-sm me-1" @click="changeChoiceFlag(product.mdChoice, product.pCode)" v-if="product.mdChoice === 'Y'">
                  <span style="font-size:small">추천</span>
                </button>
                <button type="button" class="btn btn-outline-success btn-sm me-1" @click="changeChoiceFlag(product.mdChoice, product.pCode)" v-else>
                  <span style="font-size:small">추천</span>
                </button>

                <button type="button" class="btn btn-warning btn-sm me-1" @click="changeNthereFlag(product.nrFlag, product.pCode)" v-if="product.nrFlag === 'Y'">
                  <span style="font-size:small">엔데</span>
                </button>
                <button type="button" class="btn btn-outline-warning btn-sm me-1" @click="changeNthereFlag(product.nrFlag, product.pCode)" v-else>
                  <span style="font-size:small">엔데</span>
                </button>

                <button type="button" class="btn btn-primary btn-sm me-1" @click="changeVarietyFlag(product.vrFlag, product.pCode)" v-if="product.vrFlag === 'Y'">
                  <span style="font-size:small">버라</span>
                </button>
                <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="changeVarietyFlag(product.vrFlag, product.pCode)" v-else>
                  <span style="font-size:small">버라</span>
                </button>
                <button type="button" class="btn btn-danger btn-sm me-1" @click="changeFlag('B',product.bestFlag, product.pCode)" v-if="product.bestFlag === 'Y'">
                  <span style="font-size:small">베스</span>
                </button>
                <button type="button" class="btn btn-outline-danger btn-sm me-1" @click="changeFlag('B',product.bestFlag, product.pCode)" v-else>
                  <span style="font-size:small">베스</span>
                </button>                                
                <button type="button" class="btn btn-outline-info btn-sm me-1" @click="goToCopy(product.pCode, product.pdtNameKor)">
                  <span style="font-size:small">복제</span>
                </button>
                <br>
                <button type="button" class="btn btn-secondary btn-sm me-1" @click="makeBadge('1',product.badgeFlag,product.pCode,product.badge)" v-if="product.badgeFlag === 'Y'">
                  <span style="font-size:small">뱃지1</span>
                </button>
                <button type="button" class="btn btn-outline-secondary btn-sm me-1" @click="makeBadge('1',product.badgeFlag,product.pCode,product.badge)" v-else>
                  <span style="font-size:small">뱃지1</span>
                </button>
                <button type="button" class="btn btn-secondary btn-sm me-1" @click="makeBadge('2',product.badge2Flag,product.pCode,product.badge2)" v-if="product.badge2Flag === 'Y'">
                  <span style="font-size:small">뱃지2</span>
                </button>
                <button type="button" class="btn btn-outline-secondary btn-sm me-1" @click="makeBadge('2',product.badg2Flag,product.pCode,product.badge2)" v-else>
                  <span style="font-size:small">뱃지2</span>
                </button>

                <button type="button" class="btn btn-primary btn-sm me-1" @click="makeGroup('1','M',product.pCode, '1')" v-if="product.grNo == 'Y'">
                  <span style="font-size:small">G1</span>
                </button>
                <button type="button" class="btn btn-outline-primary btn-sm me-1" @click="makeGroup('1','P',product.pCode, '1')" v-else>
                  <span style="font-size:small">G1</span>
                </button>
                <button type="button" class="btn btn-info btn-sm me-1" @click="makeGroup('2','M',product.pCode, '2')" v-if="product.grNo2 == 'Y'">
                  <span style="font-size:small">G2</span>
                </button>
                <button type="button" class="btn btn-outline-info btn-sm me-1" @click="makeGroup('2','P',product.pCode, '2')" v-else>
                  <span style="font-size:small">G2</span>
                </button>
                <button type="button" class="btn btn-success btn-sm me-1" @click="makeGroup('3','M',product.pCode, '3')" v-if="product.grNo3 == 'Y'">
                  <span style="font-size:small">G3</span>
                </button>
                <button type="button" class="btn btn-outline-success btn-sm me-1" @click="makeGroup('3','P',product.pCode, '3')" v-else>
                  <span style="font-size:small">G3</span>
                </button>

                <button type="button" class="btn btn-info btn-sm me-1" @click="changeNewFlag(product.newFlag, product.pCode)" v-if="product.newFlag === 'Y'">
                  <span style="font-size:small">월천({{ product.newDate.substring(0, 7) }})</span>
                </button>
                <button type="button" class="btn btn-outline-info btn-sm me-1" @click="changeNewFlag(product.newFlag, product.pCode)" v-else>
                  <span style="font-size:small">월천</span>
                </button>
                <!-- <button type="button" class="btn btn-outline-danger btn-sm me-1" @click="changeMixProduct(product.pCode)">
                  <span style="font-size:small">결합</span>
                </button> -->
                <!-- <button type="button" class="btn btn-warning btn-sm me-1" @click="changeAuotSales(product.autoSales, product.pCode)" v-if="product.autoSales === 'Y'">
                  <span style="font-size:small">자판</span>
                </button>
                <button type="button" class="btn btn-outline-warning btn-sm me-1" @click="changeAuotSales(product.autoSales, product.pCode)" v-else>
                  <span style="font-size:small">자판</span>
                </button> -->
              </td>
              <td class="col-1 text-sm-start text-truncate" style="max-width: 35px"> 
                <!-- 상품소개 -->
                <small>{{ product.pdtRemarks }}</small>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- pagenation area -->
        <nav aria-label="Page navigation">
          <ul class="pagination justify-content-center">
            <li class="page-item">
              <a class="page-link" aria-label="Previous" @click="paging('p')">
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li class="page-item" :key="j" v-for="j in this.cntPage">
              <a class="page-link" @click="paging(pg = j)">{{j}}</a>
            </li>
            <li class="page-item">
              <a class="page-link" aria-label="Next" @click="paging('n')">
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>

      </div>
    </main>
  </div>
</template>

<script>
import {genCode} from '../assets/js/genCode'
export default {
  // DB에서 가져온 데이타
  data() {
    return {
      productMaList: [],
      productMaList_tmp: [],
      cntProdList: 0,
      pageSize: 12,             //페이지당 데이타수
      page: 1,                  //offset기준
      cntPage: 1,               //페이지네이션 표시페이지수
      pdtPromoInfo: [],         //프로모션 정보
      pdtSortNo: 999,           //상품게시순서

      assoCode: "",             //연계코드(모아보기)
      assCodeList: [],          //연계상품코드리스트

      pdtManList: [],           //상품매니저리스트

      i: "",
      j: "",                    //검색조건별 초기화 변수
      m: "",
      sKeyword: ""
    };
  },
  created() {
    this.productId = this.$route.query.productId;
    // 데이타베이스 쿼링테스트
    this.getSalesList("all");
    this.getPdtPrmomoInfo();
    //this.getProductPrice();
    console.log("AccessInfo:",this.$store.state.user);
    //this.getProductPromotionPrice();
    this.getAssoCodeList()
    this.getPdtManList()            //상품관리매니저
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  mounted() {
    // if(document.referrer == "") {
    //   this.$swal.fire('', '적절하지 않은 페이지 접근입니다.', 'error');
    //   location.replace("/")
    // }
    //권한없는 로그인 차단
    if(this.user.handlerGrade >= 20 || this.user.handlerGrade == undefined) {
      var swal = require('sweetalert2');
      swal.fire({
        title: '로그인 필요',
        text: '접근권한 확인불가.',
        icon: 'error'
      });
      this.$router.push({path: '/'});
    }

    //document.getElementById("notice").style.display = "none";
    document.getElementById("user").style.display = "none";    
  },
  methods: {
     async getSalesList(pNum, sFlag) {     //sFlag => 검색키워드 검색플래그
      // this.pdtPriceMxMn = await this.$api("/api/pdtPriceMxMn", {param:[]});
      // console.log("pdtPriceMxMn:",this.pdtPriceMxMn);
      //alert(pNum + "/" + sFlag);

      this.productMaList_tmp = [];
      if(sFlag == undefined )
      {
        if(pNum === "all")
        {
          this.productMaList_tmp = await this.$api("/api/salesList", {});
        }
        else if(pNum === "T" || pNum === "L" || pNum === "F" || pNum === "E" || pNum === "S")
        {
          this.productMaList_tmp = await this.$api("/api/salesList1", {param:[pNum, "%"]});
        }
        else if(pNum === "Y" || pNum === "N")
        {
          this.productMaList_tmp = await this.$api("/api/salesList1", {param:["%", pNum]});
        }
        else if(pNum === "g1")
        {
          this.productMaList_tmp = await this.$api("/api/salesListForGroup1", {});
        }
        else if(pNum === "g2")
        {
          this.productMaList_tmp = await this.$api("/api/salesListForGroup2", {});
        }
        else if(pNum === "g3")
        {
          this.productMaList_tmp = await this.$api("/api/salesListForGroup3", {});
        }
        else
        {
          this.productMaList_tmp = await this.$api("/api/salesList", {});
        }
      }
      else if(sFlag === "sk")
      {
        this.productMaList_tmp = await this.$api("/api/salesListByKeyword", {param:["%" + pNum + "%"]});
      }

      else if(sFlag === "asso")
      {
        if(pNum === "assoAll")
        {
          this.productMaList_tmp = await this.$api("/api/asso_salesList", {});
        }
        else
        {
          this.productMaList_tmp = await this.$api("/api/asso_salesList1", {param: [ pNum ]});
        }
      }
      else if(sFlag === "manager")
      {
        if(pNum === "manAll")
        {
          this.productMaList_tmp = await this.$api("/api/salesList", {});
        }
        else
        {
          this.productMaList_tmp = await this.$api("/api/salesListbyMan", {param: [ pNum ]});
        }
      }

      //console.log("productMaList_tmp>>>>>>", this.productMaList_tmp)

      this.productMaList = this.productMaList_tmp
      this.cntProdList = this.productMaList_tmp.length;
      this.cntPage = Math.ceil(this.cntProdList / this.pageSize); //올림처리
      
      this.productMaList = this.productMaList_tmp.slice(0, this.pageSize); //시작idx, 지정번째idx-1
      if(this.productMaList.length < 1) {
        this.$swal.fire('', '데이타가 없습니다', 'info');
        return false;
      }
      console.log("productMaList:",this.productMaList);
    },
    paging(val){
        this.cntProdList = this.productMaList_tmp.length;

        this.cntPage = Math.ceil(this.cntProdList / this.pageSize); //올림처리

        let offSet = 0;
        //console.log("page:",this.page);

        //페이지 초기
        if(val == undefined) {
          this.page = 1;
        }
        else {
          if(val === "p") {
            this.page--;
            if(this.page < 1) {
              this.page = 1;
              this.$swal.fire('', '시작페이지입니다', 'info')
            }
          }
          else if(val === "n") {
            this.page++;
            if(this.page > this.cntPage) {
              this.page = this.cntPage;
              this.$swal.fire('', '마지막페이지입니다', 'info')
            }
          }
          else{
            this.page = val;
          }
        }
        offSet = (this.page-1)*this.pageSize;
        this.productMaList = this.productMaList_tmp.slice(offSet, offSet+this.pageSize); //시작idx, 지정번째idx-1

        if(this.productMaList.length < 1) {
          this.$swal.fire('', '데이타가 없습니다', 'info');
          return false;
        }
    },
    async changeCondition(i) {
      this.j = "noVal"
      this.m = "noVal"
      this.sKeyword = ""
      this.getSalesList(i);
    },
    async showAssoPdt(j) {
      this.i = "noVal"
      this.m = "noVal"
      this.sKeyword = ""      
      this.getSalesList(j, "asso")
    },

    //담당매니저별 모아보기
    async showPdtMan(m) {
      this.i = "noVal"
      this.j = "noVal"
      this.sKeyword = ""      
      this.getSalesList(m, "manager")
    },

    goToInsert() {
      this.$router.push({path: '/create'});
    },
    goToDetail(pdtCode) {
      //console.log(pdtCode);
      this.$router.push({path: '/detail', query: {productId: pdtCode}});
    },

    async getPdtManList(){
      let pdtManList = await this.$api("/api/pdtManagerInfo", {param: [ "Y", "Y"]})
      if(pdtManList.length > 0)
      {
        this.pdtManList = pdtManList
      }
      console.log("pdtManList:", pdtManList)
    },

    //복제상품의 상품코드생성
    async genCode(param) {
      let rtnCode = "";

      let today = new Date();
        let yy = today.getFullYear().toString().slice(-2);
        let mm = today.getMonth() + 1;
        let dd = today.getDate();
        let ymd = `${yy}${mm}${dd}`;
        let dCd = param + ymd;

        rtnCode = await genCode(this.$api, dCd);

        return rtnCode
    }, 

    //상품 복제기능
    async goToCopy(pCode, pName) {
      //console.log(pCode, pName)
      if(pName.length > 64)
      {
        this.$swal.fire("","원본상품의 제목은 64자로 제한됩니다.","warning")
        return false;
      }


      let hCd = ""
      hCd = pCode.charAt(0) + "-"
      
      let newCode = ""
      newCode = await this.genCode(hCd) + "C"

      let chk_pdtCode = await this.$api("/api/checkDub_pdtCode", { param: [newCode] });
      
      if (chk_pdtCode.length > 0) 
      {
        this.$swal.fire("","상품코드가 중복되었습니다. 복제를 다시 해주십시오.","warning")
        return false;
      }
      else
      {
        let pCode_c = newCode
        let pName_c = "(복제_)"+pName
        let saleStat = 'N'
        let handlerId = this.user.handlerId

        let copyPdtInfo = await this.$api("/api/proc_copyPdtInfo", {param: [
          pCode, pName, pCode_c, pName_c, saleStat, handlerId
        ]});

        if(copyPdtInfo.affectedRows >= 0)
        {
          try
          {
            let copyRes = await this.$api("/api/copyFolder", {
              source: pCode,
              destination: newCode 
            })
            //alert(copyRes.data.message)
            alert(copyRes.message)
            location.reload(true)
          }
          catch(error)
          {
            console.error(error)
            this.$swal.fire("","원본상품에 이미지가 없어 복제되지 않았습니다. 복제한 상품의 이미지를 등록해주세요.", "warning")
          }
        }
        else
        {
          this.$swal.fire("","원본상품이 미완성인 관계로 미완성 상태로 복제가 되었습니다. 복제한 상품을 완성해주세요.", "warning")
          return false
        }
      }
    },
    
    // mixins에 있는 공통함수 호출샘플
    getDateFormat(date) {
      return this.$dateFormat(date);
    },
    getCurrencyFormat(value) {
      return this.$currencyFormat(value);
    },
    goToPdtTrans(pdtCode, salesRoute) {
      this.$router.push({path: '/productTransInsert', query: {productId: pdtCode, salesRoute: salesRoute}});
    },
    goToPrcTrans(pdtCode, salesRoute) {
      this.$router.push({path: '/productPriceInsert', query: {productId: pdtCode, salesRoute: salesRoute}});
    },
    goToPrcOption(pdtCode, salesRoute) {
      this.$router.push({path: '/productOptionInsert', query: {productId: pdtCode, salesRoute: salesRoute}});
    },

    goToImgInsert(pdtCode) 
    {
      // 이미지 저장패스
      this.$router.push({path: '/image_insert', query: {productId: pdtCode}});
    },
    goToInfo(pdtCode, salesRoute, ntCd) 
    {
      // 이미지 저장패스
      this.$router.push({path: '/productInfo', query: {productId: pdtCode, salesRoute: salesRoute, ntCd: ntCd}});
    },
    previewProduct(pdtCode) 
    {
      // 상품미리보기
      //this.$router.push({path: '/productDetail_preview', query: {productId: pdtCode}});
      window.open('/detail?productId=' + pdtCode,'PreView', 'height=1000,width=1200,top=100,left=100,resizable=yes,scrollbars=yes')
      // window.open('/productDetail_preview?productId=' + pdtCode,'PreView', 'height=1000,width=1000,top=100,left=100,resizable=yes,scrollbars=yes')
    },

    //연계상품코드리스트
    async getAssoCodeList() {
      let assCodeList = await this.$api("/api/assCodeList", {});
      
      if(assCodeList.length > 0)
      {
        this.assCodeList = assCodeList
      }
      console.log("assCodeList:",this.assCodeList);
    },

    getProductPrice() {
      let productPrice = this.$api("/api/productPrice", {param: [this.productId]});
      // console.log(typeof(productPrice));
      console.log("productPrice",productPrice);
    },

    //상품별 프로모션 정보
    async getPdtPrmomoInfo() {
      let pdtPromoInfo = await this.$api("/api/pdtPromotionInfo", {});
      if(pdtPromoInfo.length > 0) {
        this.pdtPromoInfo = pdtPromoInfo;
      }
      console.log("pdtPromoInfo",this.pdtPromoInfo);
    },

    //상품그루핑
    // makeGroup('1','M',product.pCode, product.grNo)
    async makeGroup(val, gFlag, pCode, grNo)    //생성그룹번호, 작업(P:생성/M:제거), 상품코드, 기존그룹번호
    {
      if(gFlag === "M")
      {
        this.$swal.fire({
          html: grNo + '그룹에서 제거하시겠습니까?',
          showCancelButton: true,
          confirmButtonText: '네',
          cancelButtonText: '아니오',
        }).then(async (res) => {
          if(res.isConfirmed)
          {
            await this.updatePdtGroup("N", pCode, grNo)     //그루핑해제
          }
        })
      }
      else if(gFlag === "P")
      {
        if(val)
        {
          this.$swal.fire({
            html: val+'그루핑하시겠습니까?<br>(※그룹상품은 최신순으로 4개까지 표시됩니다.)',
            showCancelButton: true,
            confirmButtonText: '네',
            cancelButtonText: '아니오',
          }).then(async (res) => {
            if(res.isConfirmed)
            {
              await this.updatePdtGroup(val, pCode, "")     //그루핑처리
            }
          })
        }
      }
    },

    //상품 그룹핑 or 언그루핑
    async updatePdtGroup(val, pCode, grNo){
      // alert("val:" + val + "/ pCode:" + pCode)
      
      let res_updPdtGrouping = []

      if(val === "N")        //그루핑 해제
      {
        if(grNo === "1")
        {
          res_updPdtGrouping = await this.$api("/api/upd_pdtGrouping1", { param: [val, pCode] })
        }
        else if(grNo === "2")
        {
          res_updPdtGrouping = await this.$api("/api/upd_pdtGrouping2", { param: [val, pCode] })
        }
        else if(grNo === "3")
        {
          res_updPdtGrouping = await this.$api("/api/upd_pdtGrouping3", { param: [val, pCode] })
        }
      }
      else if(val != "N")        //그루핑 적용
      {
        if(val === "1")
        {
          res_updPdtGrouping = await this.$api("/api/upd_pdtGrouping1", { param: ["Y", pCode] })
        }
        else if(val === "2")
        {
          res_updPdtGrouping = await this.$api("/api/upd_pdtGrouping2", { param: ["Y", pCode] })
        }
        else if(val === "3")
        {
          res_updPdtGrouping = await this.$api("/api/upd_pdtGrouping3", { param: ["Y", pCode] })
        }
      }

      if(res_updPdtGrouping.affectedRows < 0)
      {
        this.$swal.fire("","업데이트에 실패하였습니다. 재시도 해주세요", "warning")
        return false;
      }
      else
      {
        this.$swal.fire("","상품그룹정보가 업데이트되었습니다.", "info")
        location.reload(true)
      }


      // let res_updPdtGrouping = await this.$api("/api/upd_pdtGrouping", { param: [val, pCode, grNo] })
      // if(res_updPdtGrouping.affectedRows < 0)
      // {
      //   this.$swal.fire("","업데이트에 실패하였습니다. 재시도 해주세요", "warning")
      //   return false;
      // }
      // else
      // {
      //   this.$swal.fire("","상품그룹정보가 업데이트되었습니다.", "info")
      //   location.reload(true)
      // }
    },

    async makeBadge(bFlag,badgeFlag,pdtCode,badge)     //bFlag=>뱃지1,2
    {
      let badgeFlag1 = ""
      let badgeFlag2 = ""

      if(bFlag === "1")       //Badge1
      {
        if(badgeFlag != 'Y' && badgeFlag != 'N')
        {
          badgeFlag1 = "N"
        }
        else
        {
          badgeFlag1 = badgeFlag
        }
        if(badgeFlag1 =="Y")
        {
          this.$swal.fire({
            html: '상품의 뱃지1을 탈착합니까?',
            showCancelButton: true,
            confirmButtonText: '네',
            cancelButtonText: '아니오',
          }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              //let cStatus = (badgeFlag1 === "Y") ? "N" : "Y"
              let cStatus = 'N'
              //console.log("param:",cStatus, pdtCode);
              await this.$api("/api/changePdtBadgeStatus", {param: [cStatus, badge, pdtCode ]});
              // 상태변경 후, 갱신
              this.getSalesList("all");
              this.$swal.fire('', '뱃지1탈착완료', 'success')
            }
          })
        }
        else
        {
          const { value: badgeTxt } = await this.$swal.fire({
            html: '상품에 뱃지1을 부착합니다(20자 이내)',
            input: 'text',
            //inputLabel: '뱃지표시',
            inputPlaceholder: badge
          })

          console.log("badgeTxt:",badgeTxt,"//badge:",badge)

          if (badgeTxt) {
            this.$swal.fire('',`새로 등록된 뱃지1: ${badgeTxt}`)
            let cStatus = 'Y'
            await this.$api("/api/changePdtBadgeStatus", {param: [cStatus, badgeTxt, pdtCode ]});
            // 상태변경 후, 갱신
            this.getSalesList("all");
            this.$swal.fire('', '부착완료', 'success')
          }
        }
      }
      else if(bFlag === "2")       //Badge2
      {
        //alert("Badge2")
        if(badgeFlag != 'Y' && badgeFlag != 'N')
        {
          badgeFlag2 = "N"
        }
        else
        {
          badgeFlag2 = badgeFlag
        }

        if(badgeFlag2 =="Y")
        {
          this.$swal.fire({
            html: '상품의 뱃지2를 탈착합니까?',
            showCancelButton: true,
            confirmButtonText: '네',
            cancelButtonText: '아니오',
          }).then(async (result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              //let cStatus = (badgeFlag1 === "Y") ? "N" : "Y"
              let cStatus = 'N'
              //console.log("param:",cStatus, pdtCode);
              await this.$api("/api/changePdtBadgeStatus2", {param: [cStatus, badge, pdtCode ]});
              // 상태변경 후, 갱신
              this.getSalesList("all");
              this.$swal.fire('', '뱃지2탈착완료', 'success')
            }
          })
        }
        else
        {
          const { value: badgeTxt } = await this.$swal.fire({
            html: '상품에 뱃지2를 부착합니다(20자 이내)',
            input: 'text',
            //inputLabel: '뱃지표시',
            inputPlaceholder: badge
          })

          console.log("badgeTxt:",badgeTxt,"//badge:",badge)

          if (badgeTxt) {
            this.$swal.fire('',`새로 등록된 뱃지2: ${badgeTxt}`)
            let cStatus = 'Y'
            await this.$api("/api/changePdtBadgeStatus2", {param: [cStatus, badgeTxt, pdtCode ]});
            // 상태변경 후, 갱신
            this.getSalesList("all");
            this.$swal.fire('', '뱃지2부착완료', 'success')
          }
        }
      }
    },    
    changeChoiceFlag(mdChoice, pdtCode) 
    {
      // 변경전 확인
      this.$swal.fire({
        title: '추천상태(추천Yes<->추천No)변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let cStatus = (mdChoice === "Y") ? "N" : "Y"
          console.log("param:",cStatus, pdtCode);
          await this.$api("/api/changePdtChoiceStatus", {param: [cStatus, pdtCode]});
          // 상태변경 후, 갱신
          this.getSalesList("all");
          this.$swal.fire('', '변경완료', 'success')
        // deny 버튼클릭 시 처리루틴
        // } else if (result.isDenied) {
        //   this.$swal.fire('Changes are not saved', '', 'info')
        }
      })
    },
    changeNthereFlag(nrFlag, pdtCode) 
    {
      // 변경전 확인
      this.$swal.fire({
        title: 'Nthere(지정Yes<->지정No)지정?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let cStatus = (nrFlag === "Y") ? "N" : "Y"
          console.log("param:",cStatus, pdtCode);
          await this.$api("/api/changePdtNthereStatus", {param: [cStatus, pdtCode]});
          // 상태변경 후, 갱신
          this.getSalesList("all");
          this.$swal.fire('', '변경완료', 'success')
        // deny 버튼클릭 시 처리루틴
        // } else if (result.isDenied) {
        //   this.$swal.fire('Changes are not saved', '', 'info')
        }
      })
    },
    changeVarietyFlag(vrFlag, pdtCode) 
    {
      // 변경전 확인
      this.$swal.fire({
        title: 'Variety(지정Yes<->지정No)지정?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let cStatus = (vrFlag === "Y") ? "N" : "Y"
          console.log("param:",cStatus, pdtCode);
          await this.$api("/api/changePdtVarietyStatus", {param: [cStatus, pdtCode]});
          // 상태변경 후, 갱신
          this.getSalesList("all");
          this.$swal.fire('', '변경완료', 'success')
        // deny 버튼클릭 시 처리루틴
        // } else if (result.isDenied) {
        //   this.$swal.fire('Changes are not saved', '', 'info')
        }
      })
    },

    changeFlag(param, flag, pdtCode) 
    {
      if(param === "B")
      {
        this.$swal.fire({
          title: 'Best(지정Yes<->지정No)지정?',
          showCancelButton: true,
          confirmButtonText: '변경',
          cancelButtonText: '취소',
        }).then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            let cStatus = (flag === "Y") ? "N" : "Y"
            //console.log("param:",cStatus, pdtCode);
            await this.$api("/api/changePdtBestStatus", {param: [cStatus, pdtCode]});
            // 상태변경 후, 갱신
            this.getSalesList("all");
            this.$swal.fire('', '변경완료', 'success')
          // deny 버튼클릭 시 처리루틴
          // } else if (result.isDenied) {
          //   this.$swal.fire('Changes are not saved', '', 'info')
          }
        })
      }
    },

    changeStatus(saleStatus, pdtCode) 
    {
      // 변경전 확인
      this.$swal.fire({
        title: '판매상태(판매<->중지)변경?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let sStatus = (saleStatus == "Y") ? "N" : "Y"
          // console.log(sStatus, pdtCode);
          await this.$api("/api/changePdtSalesStatus", {param: [sStatus, pdtCode]});
          // 상태변경 후, 갱신
          this.getSalesList("all");
          this.$swal.fire('변경완료!', '', 'success')
        // deny 버튼클릭 시 처리루틴
        // } else if (result.isDenied) {
        //   this.$swal.fire('Changes are not saved', '', 'info')
        }
      })
    },

    //신규상품 플래깅
    changeNewFlag(newFlag, pdtCode) 
    {
      // 변경전 확인
      this.$swal.fire({
        title: '월추천상품(지정Yes<->지정No)지정?',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let cStatus = (newFlag === "Y") ? "N" : "Y"
          console.log("param:",cStatus, pdtCode);
          await this.$api("/api/changePdtNewStatus", {param: [cStatus, pdtCode]});
          // 상태변경 후, 갱신
          this.getSalesList("all");
          this.$swal.fire('', '변경완료', 'success')
        // deny 버튼클릭 시 처리루틴
        // } else if (result.isDenied) {
        //   this.$swal.fire('Changes are not saved', '', 'info')
        }
      })
    },

    changeMixProduct(pdtCode) 
    {
      // 변경전 확인
      this.$swal.fire({
        title: '결합상품 생성전환?',
        showCancelButton: true,
        confirmButtonText: '전환',
        cancelButtonText: '취소',
      }).then(async (result) => {
        if (result.isConfirmed) {
          // alert(pdtCode);
          // this.$router.push({path: '/salesMix', query: {}});
          //Mix상품코드생성
          let timeStamp = "";
          let today = new Date();   
          let yyyy = today.getFullYear();
          let mm = today.getMonth()+1;
          let dd = today.getDate();
          let ymd = `${yyyy}${mm}${dd}`
          //console.log(`ymd: ${ymd}`);
          timeStamp = today.getTime(); // 상품코드에 들어갈 타임스탬프
          let catCode = pdtCode.charAt(0);
          let mxPdtCode = catCode+"-"+ymd+"-"+timeStamp;
          console.log("mxPdtCode:",mxPdtCode);

          let resIns_product_mix = await this.$api("/api/makeMixProduct", {param: [pdtCode]});
          if(Object.keys(resIns_product_mix)[0] === 'error') {
            this.$swal.fire('', '결합상품 기본정보 생성오류<br>관리자 문의요망', 'warning')
          }
          else {
            //let resIns_product_mix_img = await this.$api("/api/productTmpImgInsert",{param: "MX-"+pdtCode});
            let resIns_product_mix_img = await this.$api("/api/productTmpImgInsert",{param: "MX-"+mxPdtCode});
            if(Object.keys(resIns_product_mix_img)[0] === 'error') {
              this.$swal.fire('', '결합상품 기초이미지 생성오류<br>관리자 문의요망', 'warning') 
            }
            else {
              let resUpd_product_mix_code = await this.$api("/api/updateMixPdtCode", {param: [mxPdtCode, this.user.handlerId,pdtCode]});
              if(resUpd_product_mix_code.affectedRows < 0 || resUpd_product_mix_code.affectedRows === undefined){
                this.$swal.fire('', '결합상품 기본이미지 생성오류<br>관리자 문의요망', 'warning')
              }
              else {
                //let resIns_pdtQty = await this.$api("/api/productQtyInsert",{param: ["MX-"+pdtCode,this.user.handlerId]});
                let resIns_pdtQty = await this.$api("/api/productQtyInsert",{param: ["MX-"+mxPdtCode,this.user.handlerId]});
                if(Object.keys(resIns_pdtQty)[0] === 'error') {
                  this.$swal.fire('', '결합상품 기본상품수량 생성오류<br>관리자 문의요망', 'warning')
                }
                else {
                  //this.getSalesList("all");
                  this.$swal.fire('생성완료!', '', 'success')
                  this.$router.push({path: '/salesMix', query: {}});
                }
              }
            }
          }
        }
      })
    },
    changeAuotSales(autoSales, pdtCode) 
    {
      // 변경전 확인
      this.$swal.fire({
        title: '컨펌없는 자동판매(실행<->중지)상태',
        showCancelButton: true,
        confirmButtonText: '변경',
        cancelButtonText: '취소',
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let aStatus = (autoSales == "Y") ? "N" : "Y"
          // console.log(sStatus, pdtCode);
          await this.$api("/api/changeAutoSalesStatus", {param: [aStatus, pdtCode]});
          // 상태변경 후, 갱신
          this.getSalesList("all");
          this.$swal.fire('변경완료!', '', 'success')
        // deny 버튼클릭 시 처리루틴
        // } else if (result.isDenied) {
        //   this.$swal.fire('Changes are not saved', '', 'info')
        }
      })
    },
    async saveSortNo()
    {
      let i=0
      let resUpd = "";
      while(i<this.productMaList.length)
      {
        let sortNo = (this.productMaList[i].sortNo != null) ? this.productMaList[i].sortNo : 999;
        //console.log("pdtCode:",this.productMaList[i].pdtCode,"sortNo:",sortNo)
        let pdtSortNo_update = await this.$api("/api/pdtSortNo_update", {param: [
          sortNo, this.productMaList[i].pdtCode
        ]});
        // 인서트오류
        if(pdtSortNo_update.affectedRows < 0 || pdtSortNo_update.affectedRows === undefined)
        {
          //alert("비수기 권종금액 변경 중 오류가 발생했습니다\n확인 후 관리자에게 문의바랍니다.");
          resUpd = "error"
          break;
        }
        else
        {
          resUpd = "ok"
        }
        i++;
      }
      if(resUpd == "error")
      {
        this.$swal.fire('', '오류발생(pdtSortNo_update)<br>시스템 관리자에게 문의바랍니다.', 'error')
        return false;
      }
      else
      {
        this.$swal.fire('', '설정처리가 왼료되었습니다', 'success')
        location.reload(true);

      }      
    }
  }, 
}
</script>

<style>
.top-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* .flex-item {
  margin: 5px;
} */
</style>